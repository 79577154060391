import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../../../common/store/ConfigureStore';
import { LocationReportsFilterComponent } from '../reports/locationStatisticsReports/LocationReportsFilterComponent';
import { updateInvoiceFilterValues } from '../../actions/billing/slice-actions';

const mapStateToProps = (state: RootState) => ({
  filterValues: state.BillingReducer.invoiceFilterValues,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updateFilterValues: updateInvoiceFilterValues,
    },
    dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationReportsFilterComponent);

import * as React from "react";
import { isEqual } from "lodash";
import SaveCharityFormComponent from "./SaveCharityFormComponent";
import AppUtilityService from "../../../common/services/AppUtilityService";
import WebAccessService from "../../../common/services/WebAccessService";
import { hashHistory } from "react-router";

import { Card, Button, Row, Col, Avatar, Spin, Modal } from 'antd';
const confirm = Modal.confirm;

export class CharityDetailsComponent extends React.Component<ICharityBasicDetailsCardProps, ICharityBasicDetailsCardState> {
    private saveContactFormRef: any;

    constructor(props: ICharityBasicDetailsCardProps) {
        super(props);
        this.state = {
            charityDetails: null,
            isLoading: false,
            isBasicDetailsEditMode: false,
        }
    }


    componentDidMount() {
        if (this.props.charityId) {
            this.getCharityDetails();
        }
    }

    componentWillReceiveProps(nextProps: ICharityBasicDetailsCardProps) {
        if (nextProps.loadCharityDetailsSuccess && !isEqual(this.props.loadCharityDetailsSuccess, nextProps.loadCharityDetailsSuccess)) {
            var responseData: any = nextProps.loadCharityDetailsSuccess;
            this.setState({ charityDetails: responseData, isLoading: false })
        }

        if (nextProps.loadCharityDetailsError && !isEqual(this.props.loadCharityDetailsError, nextProps.loadCharityDetailsError)) {
            this.setState({ isLoading: false })
            AppUtilityService.handleApiError(nextProps.loadCharityDetailsError);
        }

        /**
         * Update charity success/error handle
         */
        if (nextProps.updateCharityDetailsSuccess && !isEqual(this.props.updateCharityDetailsSuccess, nextProps.updateCharityDetailsSuccess)) {
            var responseData: any = nextProps.updateCharityDetailsSuccess;
            this.setState({ charityDetails: responseData, isBasicDetailsEditMode: false, isLoading: false });
            AppUtilityService.showMessageToast("Updated charity details", "success");
        }

        if (nextProps.updateCharityDetailsError && !isEqual(this.props.updateCharityDetailsError, nextProps.updateCharityDetailsError)) {
            this.setState({ isLoading: false })
            AppUtilityService.handleApiError(nextProps.updateCharityDetailsError);
        }

        /**
         * Delete charity success/error handle
         */
        if (nextProps.deleteCharitySuccess !== null && !isEqual(this.props.deleteCharitySuccess, nextProps.deleteCharitySuccess)) {
            this.setState({ isLoading: false })
            if (nextProps.deleteCharitySuccess) {
                AppUtilityService.showMessageToast("Selected charity has been deleted", "success");
                hashHistory.push("/charities");
            }
        }

        if (nextProps.deleteCharityError && !isEqual(this.props.deleteCharityError, nextProps.deleteCharityError)) {
            this.setState({ isLoading: false })
            AppUtilityService.handleApiError(nextProps.deleteCharityError);
        }
    }

    render() {
        var cardActions = <div>
            {
                WebAccessService.hasPermissionToAccess("Charity", "Modify") &&
                <Button className="ml-8" type="primary" onClick={this.editDetails}>Edit</Button>
            }
            {
                WebAccessService.hasPermissionToAccess("Charity", "Delete") &&
                <Button className="ml-8" type="danger" onClick={this.deleteCharity}>Delete</Button>
            }
        </div>;

        return (
            <Spin spinning={this.state.isLoading}>
                <Card className="content-card no-header-border" bordered={false}>
                    <div style={{ paddingTop: "16px" }}>
                        {
                            this.state.charityDetails && !this.state.isBasicDetailsEditMode &&
                            <Row gutter={24} >
                                <Col span={24}>
                                    <Row gutter={24}>
                                        <Col span={16}>
                                            <div>
                                                <div style={{ display: "inline-block", verticalAlign: "top", marginTop: "10px" }}>
                                                    <Avatar shape="square" className="mr-8 avatar-square">
                                                        <i className="icon-charity" />
                                                    </Avatar>
                                                </div>
                                                <div style={{ display: "inline-block" }}>
                                                    <h1 style={{ marginBottom: 0 }}>
                                                        {this.state.charityDetails && this.state.charityDetails.name}
                                                    </h1>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col span={8} className="text-right">
                                            {cardActions}
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row gutter={24}>
                                        <Col md={12} lg={6} style={{ paddingLeft: "50px" }}>
                                            <div style={{ marginBottom: "12px" }}>
                                                <span className="t-black bold">Address: </span>
                                                <span className="t-black-light">
                                                    {
                                                        this.state.charityDetails && AppUtilityService.displayStringFromArray([
                                                            this.state.charityDetails.address1,
                                                            this.state.charityDetails.address2,
                                                            this.state.charityDetails.city,
                                                            this.state.charityDetails.addressState,
                                                            this.state.charityDetails.zipcode,
                                                        ])
                                                    }
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={12} lg={6}>
                                            <div style={{ marginBottom: "12px" }}>
                                                <span className="t-black bold">License no: </span>
                                                <span className="t-black-light">{this.state.charityDetails.licenseNumber}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        }
                        {
                            this.state.charityDetails && this.state.isBasicDetailsEditMode &&
                            <Row>
                                <Col span={24}>
                                    <SaveCharityFormComponent ref={(ref) => this.saveContactFormRef = ref}
                                        charityDetails={this.state.charityDetails} />
                                    <Row>
                                        <Col span={24}>
                                            <Button className="mr-8" type="primary" htmlType="submit" onClick={this.handleUpdateCharitySubmit}>Save</Button>
                                            <Button onClick={this.cancelEditDetails}> Cancel </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        }
                    </div>
                </Card>
            </Spin>
        )
    }
    getCharityDetails = () => {
        var charityId = this.props.charityId;
        this.setState({ isLoading: true })
        this.props.actions.loadCharityDetails(charityId);
    }

    editDetails = () => {
        this.setState({ isBasicDetailsEditMode: true })
    }
    handleUpdateCharitySubmit = (e) => {
        e.preventDefault();
        this.saveContactFormRef.validateFields((err, values) => {
            if (!err) {
                this.setState({ isLoading: true })
                var formData = values;
                formData.addressId = this.state.charityDetails.addressId;
                this.props.actions.updateCharityDetails(this.state.charityDetails.id, formData)
            }
        })
    }
    cancelEditDetails = () => {
        this.setState({ isBasicDetailsEditMode: false })
    }
    deleteCharity = () => {
        var { charityDetails } = this.state;
        confirm({
            title: 'Confirm dialog',
            content: 'Are you sure you want to delete the selected charity?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: () => {
                this.setState({ isLoading: true })
                this.props.actions.deleteCharityDetails(charityDetails.id)
            },
        });
    }
}

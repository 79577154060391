import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ContactsActions from '../../actions/contacts/ContactsActions';
import { ContactsListTableComponent } from '../../components/contacts/ContactsListTableComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    contactListFilters: state.ContactsReducer.contactListFilters,

    contactsListSuccess: state.ContactsReducer.loadListDataSuccess,
    contactsListError: state.ContactsReducer.loadListDataError,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ContactsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactsListTableComponent)

import React, {useEffect, useState} from 'react';
import { Button, Card, Icon, message } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import AppUtilityService from '../../../common/services/AppUtilityService';
import { updateBreadcrumbs } from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import DateRangeFilter from '../../../common/components/filters/DateRangeFilter';
import moment from 'moment';
import { zoneIds } from '../../../common/zones';
import { FilterValue } from '../reports/locationStatisticsReports/LocationReportsFilterComponent';
import LocationsAutocompleteContainer from '../../../common/containers/dropdown/LocationsAutocompleteContainer';
import CharitiesAutocompleteContainer from '../../../common/containers/dropdown/CharitiesAutocompleteContainer';
import CharitiesSessionsDropdownContainer from '../../../common/containers/dropdown/CharitiesSessionsDropdownContainer';
import { LoadState } from '../../../common/store/fetched';
import { downloadKYWS06bReport } from '../../actions/invoicingReports/downloadKYWS06bReport';

const styles = require('../../../common/styles/ReportStyles.css');

export type Props = {};

interface SessionData {
  locationId: number,
  charityId: number | null,
  sessionId: number | null,
  dateFrom: Date,
  dateTo: Date,
  sessionName: string
}

const KYWS06bReportPage = (props: Props) => {

  const isDownloadingInProgress = useAppSelector(state =>
    state.InvoicingReportsReducer.downloadKYWS06bReportOperation.loadState === LoadState.InProgress);
  const hasDownloadingFailed = useAppSelector(state =>
    state.InvoicingReportsReducer.downloadKYWS06bReportOperation.loadState === LoadState.Failed);

  const [locationId, setLocationId] = useState<number | null>(null);
  const [charityId, setCharityId] = useState<number | null>(null);
  const [dateRange, setDateRange] = useState<FilterValue>([
    moment().subtract(6, 'day').startOf('day'),
    moment().subtract(0, 'day').endOf('day'),
  ]);
  const [sessionId, setSessionId] = useState<number | null>(null);  

  const locationsData = useAppSelector(
    state => state.DropdownReducer.loadLocationsDropdownDataSuccess);

  const charitiesData = useAppSelector(
    state => state.AutocompleteReducer.loadCharitiesAutocompleteDataSuccess);  

  const charitiesSessionsData = useAppSelector(
    state => state.DropdownReducer.loadCharitiesSessionsDropdownDataSuccess);


  const dispatch = useAppDispatch();

  useEffect(
    () => {
      const breadcrumbsList = [
        { name: "Reports", link: "/Reports" },
        { name: 'KY WS-06b Report', link: null },
      ];
      const breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
      dispatch(updateBreadcrumbs(breadcrumbs));
    },
    [dispatch]);

    useEffect(
      () => {
        if (hasDownloadingFailed) {
          message.error('Failed to download KY WS06b Report');
        }
      },
      [hasDownloadingFailed]);

    useEffect(
      () => {
      if (locationId == null) {
        setCharityId(null);
        setSessionId(null);
      }
    },
    [locationId]);

    const resetFilters = () => {
      window.location.reload();
    };
  
    const downloadExcel = () => {
      dispatch(downloadKYWS06bReport(sessionId, locationId));
    };

  return (
    <div className="pg-container page-container">
            <Card
        className="no-header-border"
        bordered={false}
        title="KY WS-06b Report"
      >
        <LocationsAutocompleteContainer
          placeholder="Select location"
          allowClear={true}
          value={locationId}
          customParams={{ zoneId: zoneIds.KY, prodDemoTestCsvString: 'P,DC' }}
          onChange={setLocationId}
          className="mr-8"
        />
        <CharitiesAutocompleteContainer
          className="selectionCharity mr-8"
          placeholder="Select charity"
          customParams={{
              zoneId: zoneIds.KY,
              locationId: locationId,
              loadIdRelated: true,
              prodDemoTestCsvString: 'P,DC'
          }}
          value={charityId}
          onChange={setCharityId}
        />
        <span className="mr-8">
          <DateRangeFilter
            filterValue={dateRange}
            onFilterValueChange={range => setDateRange(range)}
          />
        </span>
        <CharitiesSessionsDropdownContainer
          className="selectionSessionDateRange mr-8"
          placeholder="Select session"
          allowClear={false}
          value={sessionId}
          onChange={setSessionId} 
          disableSelect={locationId == null ? true : false}
          customParams={{
            zoneId: zoneIds.KY,
            locationId: locationId,
            charityId: charityId,
            dateFrom: dateRange[0],
            dateTo: dateRange[1],
            prodDemoTestCsvString: 'P,DC'
          }}
        />
        <Button
          onClick={resetFilters}
          type="default"
          className="mr-8"
        >
          ↻
        </Button>
        <br />
        <Button
          style={{marginTop:"8px"}}
          type="primary"
          onClick={downloadExcel}
          loading={isDownloadingInProgress}
          disabled={sessionId == null}
        >
          <Icon type="download" style={{ fontSize: "16px" }}/> Download as Excel
        </Button>
      </Card>
    </div>
  );
}

export default KYWS06bReportPage;

import ApiService from "../../../common/services/ApiService";

/**
 * All contacts autocomplete
 */

export function loadContactsAutocompleteActionHandler(payload = null, TYPE) {
  return { type: `LOAD_CONTACTS_AUTOCOMPLETE_DATA_${TYPE}`, payload }
}


export function loadContactsAutocomplete(params) {
  return function (dispatch) {
    dispatch(loadContactsAutocompleteActionHandler(null, 'REQUEST'));
    return ApiService.getData("/lookups/contacts", params)
      .then((successResult) => {
        dispatch(loadContactsAutocompleteActionHandler(successResult.data, 'SUCCESS'));
      })
      .catch((errorResult) => {
        dispatch(loadContactsAutocompleteActionHandler(errorResult, 'FAILURE'));
      });
  }
}

/**
 * All charities autocomplete
 */

export function loadCharitiesAutocompleteActionHandler(payload = null, TYPE) {
  return { type: `LOAD_CHARITIES_AUTOCOMPLETE_DATA_${TYPE}`, payload }
}


export function loadCharitiesAutocomplete(params) {
  return function (dispatch) {
    dispatch(loadCharitiesAutocompleteActionHandler(null, 'REQUEST'));
    return ApiService.getData("/lookups/charities", params)
      .then((successResult) => {
        dispatch(loadCharitiesAutocompleteActionHandler(successResult.data, 'SUCCESS'));
      })
      .catch((errorResult) => {
        dispatch(loadCharitiesAutocompleteActionHandler(errorResult, 'FAILURE'));
      });
  }
}

/**
 * All countries autocomplete
 */

export function loadStatesAutocompleteActionHandler(payload = null, TYPE) {
  return { type: `LOAD_STATES_AUTOCOMPLETE_DATA_${TYPE}`, payload }
}


export function loadStatesAutocomplete(params) {
  return function (dispatch) {
    dispatch(loadStatesAutocompleteActionHandler(null, 'REQUEST'));
    return ApiService.getData("/lookups/states", params)
      .then((successResult) => {
        dispatch(loadStatesAutocompleteActionHandler(successResult.data, 'SUCCESS'));
      })
      .catch((errorResult) => {
        dispatch(loadStatesAutocompleteActionHandler(errorResult, 'FAILURE'));
      });
  }
}

/**
 * All charities autocomplete
 */

export function loadLocalCharitiesAutocompleteActionHandler(payload = null, TYPE) {
  return { type: `LOAD_LOCALCHARITIES_AUTOCOMPLETE_DATA_${TYPE}`, payload }
}


export function loadLocalCharitiesAutocomplete(params, locationId) {
  return function (dispatch) {
    if (locationId == null) {
      dispatch(loadLocalCharitiesAutocompleteActionHandler(new Error('locationId is required'), 'FAILURE'));
      return;
    }
    dispatch(loadLocalCharitiesAutocompleteActionHandler(null, 'REQUEST'));
    return ApiService.getData(`/locations/${locationId}`, params)
      .then((successResult) => {
        dispatch(loadLocalCharitiesAutocompleteActionHandler(successResult.data?.charities ?? [],
          'SUCCESS'));
      })
      .catch((errorResult) => {
        dispatch(loadLocalCharitiesAutocompleteActionHandler(errorResult, 'FAILURE'));
      });
  }
}

/**
 * All sales tax autocomplete
 */

export function loadSalesTaxesAutocompleteActionHandler(payload = null, TYPE) {
  return { type: `LOAD_SALESTAXES_AUTOCOMPLETE_DATA_${TYPE}`, payload }
}

export function loadSalesTaxesAutocomplete(params, invoicingSystemId) {
  return function (dispatch) {
    if (invoicingSystemId == null) {
      dispatch(loadSalesTaxesAutocompleteActionHandler(new Error('invoicingSystemId is required'), 'FAILURE'));
      return;
    }
    dispatch(loadSalesTaxesAutocompleteActionHandler(null, 'REQUEST'));
    return ApiService.getData(`/invoicingsystem/${invoicingSystemId}/salesTaxes`, params)
      .then((successResult) => {
        dispatch(loadSalesTaxesAutocompleteActionHandler(successResult.data ?? [],
          'SUCCESS'));
      })
      .catch((errorResult) => {
        dispatch(loadSalesTaxesAutocompleteActionHandler(errorResult, 'FAILURE'));
      });
  }
}

// ================================================================================

import * as React from "react";
import { isEqual, filter, isEmpty } from "lodash";

import { Spin} from 'antd';
import { IReconSummaryIPRReportComponentProps, IReconSummaryIPRReportComponentState } from '../Reports';
import { DataGridComponent } from "../../../../common/components/table/DataGridComponent";
import AppConstants from "../../../../common/AppConstants";
import AppUtilityService from "../../../../common/services/AppUtilityService";
import ReportsUtilityService from "../../../../common/services/ReportsUtilityService";

export class ReconSummaryIPRReportComponent extends React.Component<IReconSummaryIPRReportComponentProps, IReconSummaryIPRReportComponentState> {

  private gridContainerRef: any;

  constructor(props: IReconSummaryIPRReportComponentProps) {
      super(props);
      this.state = {
          reconSummaryIPRList: null,
          reconSummaryByDateIPRChildList: null,
          isLoading: false,
          isLoadingList: false,
          isLoadingChildList: false,
          downloadType: "pdf",
          apiParams: this.props.apiParams
      }
  }

  componentDidMount() {
    if (this.props.onRef) {
        this.props.onRef(this);
    }
  }

  componentWillReceiveProps(nextProps: IReconSummaryIPRReportComponentProps) {
    if (nextProps.apiParams && !isEqual(this.props.apiParams, nextProps.apiParams)) {
      this.gridContainerRef && this.gridContainerRef.resetDataGrid();
      this.loadReconSummaryIPRList(nextProps.apiParams);
      this.setState({ apiParams: nextProps.apiParams })
    }

    /**
     * Recon summary list
     */
    if (nextProps.loadReconSummaryIPRListSuccess && !isEqual(this.props.loadReconSummaryIPRListSuccess, nextProps.loadReconSummaryIPRListSuccess)) {
      this.setState({ reconSummaryIPRList: nextProps.loadReconSummaryIPRListSuccess, isLoadingList: false})
    }
    if (nextProps.loadReconSummaryIPRListError && !isEqual(this.props.loadReconSummaryIPRListError, nextProps.loadReconSummaryIPRListError)) {
        this.setState({ isLoadingList: false, reconSummaryIPRList: null })
        AppUtilityService.handleApiError(nextProps.loadReconSummaryIPRListError);
    }
    /**
     * Recon summary list by date child list
     */
    if (nextProps.loadReconSummaryIPRChildListSuccess && !isEqual(this.props.loadReconSummaryIPRChildListSuccess, nextProps.loadReconSummaryIPRChildListSuccess)) {
      this.setState({ reconSummaryByDateIPRChildList: nextProps.loadReconSummaryIPRChildListSuccess, isLoadingChildList: false })
    }
    if (nextProps.loadReconSummaryIPRChildListError && !isEqual(this.props.loadReconSummaryIPRChildListError, nextProps.loadReconSummaryIPRChildListError)) {
        this.setState({ isLoadingChildList: false, reconSummaryByDateIPRChildList: null })
        AppUtilityService.showNotificationToast("Error", nextProps.loadReconSummaryIPRChildListError.response.data, "error");
    }

      /**
       * Report download excel
       */
        if (nextProps.exportIPRDataToExcelSuccess && !isEqual(this.props.exportIPRDataToExcelSuccess, nextProps.exportIPRDataToExcelSuccess)) {
        this.setState({ isLoading: false })
        if (!isEmpty(nextProps.exportIPRDataToExcelSuccess)) {
            if (this.state.downloadType === "pdf") {
                this.downloadPDF(nextProps.exportIPRDataToExcelSuccess)
            } else {
                this.downloadExcel(nextProps.exportIPRDataToExcelSuccess)
            }
        }
      }
      if (nextProps.exportIPRDataToExcelError && !isEqual(this.props.exportIPRDataToExcelError, nextProps.exportIPRDataToExcelError)) {
          this.setState({ isLoading: false })
          AppUtilityService.handleApiError(nextProps.exportIPRDataToExcelError);
      }


  }

  render() {

    const devExColumnData: any = filter(AppConstants.reconSummaryIPRReportColumns)[0];

    let devExSummaryAvgCols = devExColumnData && filter(devExColumnData.columns, (o) => { return o.dataField !== 'ppw' });  //hide ppw for now - broken
    let devExSummarySumCols = devExColumnData  && filter(devExColumnData.columns, (o) => { return o.dataField !== 'ppw' });  //never show ppw for Total

    let devExChildSummaryAvgCols = devExColumnData && filter(devExColumnData.childColumns, (o) => { return o.dataField !== 'ppw' });  //hide ppw for now - broken
    let devExChildSummarySumCols = devExColumnData && filter(devExColumnData.childColumns, (o) => { return o.dataField !== 'ppw' });  //never show ppw for Total

    return (
      <div style={{ padding: "24px" }}>
          <Spin spinning={this.state.isLoadingList || this.state.isLoadingChildList}>
              <div>
                  <DataGridComponent
                      ref={ref => (this.gridContainerRef = ref)}
                      toolbarContent={<h2>Detailed view of selected time period:</h2>}
                      dataSource={this.state.reconSummaryIPRList && this.state.reconSummaryIPRList.summaryList}
                      childDataSource={this.state.reconSummaryByDateIPRChildList && this.state.reconSummaryByDateIPRChildList.summaryList}
                      dataSrcColumns={devExColumnData && !isEmpty(devExColumnData.columns) && devExColumnData.columns}
                      childDataSrcColumns={devExColumnData && !isEmpty(devExColumnData.childColumns) && devExColumnData.childColumns}
                      summaryAvgColumns={devExColumnData && !isEmpty(devExSummaryAvgCols) && devExSummaryAvgCols}
                      summarySumColumns={devExColumnData && !isEmpty(devExSummarySumCols) && devExSummarySumCols}
                      childSummaryAvgColumns={devExColumnData && !isEmpty(devExChildSummaryAvgCols) && devExChildSummaryAvgCols}
                      childSummarySumColumns={devExColumnData && !isEmpty(devExChildSummarySumCols) && devExChildSummarySumCols}
                      isLoading={this.state.isLoadingList}
                      isLoadingChild={this.state.isLoadingChildList}
                      onRowExpandingCallback={this.handleOnRowExpanding}
                      storeIdentifier={`ReconSummaryIPR-${devExColumnData.reportType}`}
                  />
              </div>
          </Spin>
      </div>
    )
  }

  loadReconSummaryIPRList = (apiParams) => {
    this.setState({ isLoadingList: true, reconSummaryIPRList: null })
    apiParams = { ...apiParams }
    this.props.actions.loadReconSummaryIPRList(apiParams)
  }

  getByDateChildData = (summaryId, dateFrom, dateTo) => {
    this.setState({ isLoadingChildList: true, reconSummaryByDateIPRChildList: null })
    var apiParams = { ...this.state.apiParams };
    apiParams.locationId = summaryId;
    apiParams.dateFrom = dateFrom;
    apiParams.dateTo = dateTo;
    apiParams.summaryType = "ByDate";
    this.props.actions.loadReconSummaryIPRChildList(apiParams);
  }

  handleOnRowExpanding = (selectedRowsData) => {
    this.setState({ reconSummaryByDateIPRChildList: null })
    this.getByDateChildData(selectedRowsData.key.lId, selectedRowsData.key.dateFrom, selectedRowsData.key.dateTo);
  }

  getDownloadData = (downloadType) => {
    this.setState({ isLoading: true, downloadType })
    var apiParams = { ...this.state.apiParams };
    apiParams.isExcel = downloadType === "excel" ? true : false;
    this.props.actions.exportIPRDataToExcel(apiParams);
  }
  downloadExcel = (exportListData) => {
    const devExColumnData: any = filter(AppConstants.reconSummaryReportColumns)[0];
    let reconSummaryInfo = { ...this.state.reconSummaryIPRList}
    ReportsUtilityService.downloadReconSummarySinceLastIPRExcel(exportListData, devExColumnData, reconSummaryInfo)
  }

  downloadPDF = (exportListData) => {
    const devExColumnData: any = filter(AppConstants.reconSummaryReportColumns)[0];
    let reconSummaryInfo = { ...this.state.reconSummaryIPRList}
    ReportsUtilityService.downloadReconSummarySinceLastIPRPDF(exportListData, devExColumnData, reconSummaryInfo)
  }
}
import React from 'react';
import { TicketSalesByDateReportItem } from '../../reducers/reporting/reporting.models';
import { DonutChartComponent } from '../../../common/components/charts/DonutChartComponent';
import AppUtilityService from '../../../common/services/AppUtilityService';
import { ChartTabularLegendsComponent } from '../../../common/components/charts/ChartTabularLegendsComponent';

export type Props = {
  data: TicketSalesByDateReportItem[];
};

const TicketSalesRevenueChart = ({ data }: Props) => {
  const totals = data.reduce(
    (acc, curr) => {
      acc.currentNetRevenue += curr.currentNetRevenue;
      acc.theoreticalNetRevenue += curr.theoreticalNetRevenue;
      acc.remainingRevenue += curr.theoreticalNetRevenue - curr.currentNetRevenue;
      return acc;
    },
    { currentNetRevenue: 0, theoreticalNetRevenue: 0, remainingRevenue: 0 });
  const percentage = totals.currentNetRevenue / totals.theoreticalNetRevenue * 100;
  const percentageFormatted = isNaN(percentage) ? null : AppUtilityService.shortenLargeNumber(percentage, 2) + '%';
  const chartData: IDonutChartProps['chartData'] = {
    title: {
      html: `<div class="bold text-center font-18 t-grey-dark">${percentageFormatted ?? '-'}</div>`,
      css: {
        color: '#A6ADC9',
        fontSize: 18,
      },
    },
    subTitle: {
      html: `<div class="font-12">Current  / Theoretical Net Revenue</div>`,
      css: {
        color: '#4572A7',
        fontSize: 12,
      },
    },
    data: [
      {
        name: "Theoretical Net Revenue",
        y: totals.theoreticalNetRevenue,
      },
      {
        name: "Current Net Revenue",
        y: totals.currentNetRevenue,
      },
      {
        name: "Remaining Net Revenue",
        y: totals.remainingRevenue,
      },
    ],
    units: 'currency',
  }
  return (
    <>
      <DonutChartComponent
        id="theoreticalPayoutChart"
        height={400}
        responsiveHeight={400}
        chartData={chartData}
        showLegend
        isVerticalLegend={false}
      />
      <ChartTabularLegendsComponent
        id="theoreticalPayoutChartLegend"
        chartData={chartData}
      />
    </>
  );
}

export default TicketSalesRevenueChart;

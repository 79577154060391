import { createApiCaller } from '../../../common/services/ApiService';
import { fetchDataWithActionCreator } from '../../../common/store/fetched';
import { AppDispatch } from '../../../common/store/ConfigureStore';
import { processFailureReason } from '../../../common/store/failure';
import { TaxRate } from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';
import { taxRatesUpdated } from './slice-actions';

export const getTaxRates = (params: { zoneId?: number, locationId?: number }) =>
  async (dispatch: AppDispatch) =>
    await fetchDataWithActionCreator(
      taxRatesUpdated,
      dispatch,
      createApiCaller(api => api.getData<TaxRate[]>('/taxRates', params)),
      processFailureReason,
      true,
    )


import * as React from "react";
import { isEqual } from "lodash";
import AppUtilityService from "../../../common/services/AppUtilityService";

import { Modal, Button } from 'antd';
import UserRoleDetailsFormComponent from "./UserRoleDetailsFormComponent";

export class SaveUserRoleModal extends React.Component<ISaveUserRoleProps, {}> {

    private userRoleFormRef: any;

    componentWillReceiveProps(nextProps: ISaveUserRoleProps) {
        /**
         * Add new role success/error handle
         */
        if (nextProps.addNewUserRoleSuccess && !isEqual(this.props.addNewUserRoleSuccess, nextProps.addNewUserRoleSuccess)) {
            AppUtilityService.showMessageToast("User role has been added successfully", "success");
            this.props.roleAddSuccessCallback();
        }
        if (nextProps.addNewUserRoleError && !isEqual(this.props.addNewUserRoleError, nextProps.addNewUserRoleError)) {
            AppUtilityService.handleApiError(nextProps.addNewUserRoleError);
        }
        /**
         * Update role success/error handle
         */
        if (nextProps.updateSelectedUserRoleSuccess && !isEqual(this.props.updateSelectedUserRoleSuccess, nextProps.updateSelectedUserRoleSuccess)) {
            AppUtilityService.showMessageToast("User role has been updated successfully", "success");
            this.props.roleAddSuccessCallback();
        }
        if (nextProps.updateSelectedUserRoleError && !isEqual(this.props.updateSelectedUserRoleError, nextProps.updateSelectedUserRoleError)) {
            AppUtilityService.handleApiError(nextProps.updateSelectedUserRoleError);
        }
    }

    render() {

        return (
            <Modal
                visible={true}
                title="Create New Role"
                onOk={this.handleFormSubmit}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>Cancel</Button>,
                    <Button key="submit" type="primary" htmlType="submit" onClick={this.handleFormSubmit}>Create New Role</Button>
                ]}
            >
                <UserRoleDetailsFormComponent ref={(ref) => this.userRoleFormRef = ref} roleDetails={null} />
            </Modal>
        )
    }

    /**
     * Handle role details add form submit using ref
     */
    handleFormSubmit = (e) => {
        e.preventDefault();
        this.userRoleFormRef.validateFields((err, values) => {
            if (!err) {
                var formData = values;
                formData.roleId = 0;
                this.props.actions.addNewUserRole(formData)
            }
        })
    }

    handleCancel = () => {
        this.props.closeSaveRoleModalCallback();
    }

}

import { locationUninstallAlertReportUpdated } from './slice-actions';
import apiService from '../../../common/services/ApiService';
import { createStandardFetchedAction } from '../../../common/store/fetched.slices';
import { Moment } from 'moment';
import AppConstants from '../../../common/AppConstants';

export const getLocationUninstallAlertReport = (
  dateFrom: Moment,
  dateTo: Moment,
  zoneId?: number,
  installed?: boolean,
) => createStandardFetchedAction(
  locationUninstallAlertReportUpdated,
  async () => {
    const result = await apiService.getData(
      `/reports/location-un-install-alert-report/${dateFrom.format(AppConstants.apiDateFormat)}/${dateTo.format(AppConstants.apiDateFormat)}`,
      { zoneId, installed });
    return result.data;
  },
  false,
);

import * as React from "react";
import { isEqual, isEmpty } from "lodash";
import DataGrid, {
  Column,
  Selection,
  Summary,
  TotalItem,
  MasterDetail,
  Paging,
  Pager,
  IDataGridOptions } from 'devextreme-react/data-grid';
import { Button } from "antd";


export interface IDataGridComponentProps {
  dataSource: any;
  childDataSource?: any;
  dataSrcColumns: any;
  childDataSrcColumns?: any;
  summaryAvgColumns: any;
  summarySumColumns: any;
  childSummaryAvgColumns?: any;
  childSummarySumColumns?: any;
  onRowExpandingCallback?(selectedRowsData): void;
  isLoading: boolean
  isLoadingChild?: boolean
  showPageSizeSelector?: boolean
  /** Used for saving configuration to localStorage */
  storeIdentifier?: string;
  toolbarContent?: any;
  dataGridOptions?: IDataGridOptions;
}

export interface IDataGridComponentState {
  dataSource: any;
  childDataSource?: any;
  dataSrcColumns: any;
  childDataSrcColumns: any;
  summaryAvgColumns: any;
  summarySumColumns: any;
  childSummaryAvgColumns: any;
  childSummarySumColumns: any;
  isLoading: boolean
  isLoadingChild: boolean;
  showPageSizeSelector: boolean;
  isDrilldownExpanded: boolean;
}


const allowedPageSizes = [25, 50, 100, 1000];
export class DataGridComponent extends React.Component<IDataGridComponentProps, IDataGridComponentState> {
  private gridContainerRef : DataGrid;
  private childGridRef = React.createRef<DataGrid>();

  constructor(props: IDataGridComponentProps) {
    super(props);
    this.state = {
      dataSource: this.props.dataSource || null,
      childDataSource: this.props.childDataSource || null,
      dataSrcColumns: this.props.dataSrcColumns || null,
      childDataSrcColumns: this.props.childDataSrcColumns || null,
      summaryAvgColumns: this.props.summaryAvgColumns || null,
      summarySumColumns: this.props.summarySumColumns || null,
      childSummaryAvgColumns: this.props.childSummaryAvgColumns || null,
      childSummarySumColumns: this.props.childSummarySumColumns || null,
      isLoading: this.props.isLoading || null,
      isLoadingChild: this.props.isLoadingChild || null,
      showPageSizeSelector: this.props.showPageSizeSelector || false,
      isDrilldownExpanded: false
    };
  }

  componentWillReceiveProps(nextProps: IDataGridComponentProps) {
    if (nextProps.dataSource && !isEqual(this.props.dataSource, nextProps.dataSource)) {
      this.setState({
        dataSource: nextProps.dataSource,
        childDataSource: null,
        isDrilldownExpanded: false
      });
    }
    if (nextProps.childDataSource && !isEqual(this.props.childDataSource, nextProps.childDataSource)) {
      this.setState({ childDataSource: nextProps.childDataSource });
    }
    if (nextProps.dataSrcColumns && !isEqual(this.props.dataSrcColumns, nextProps.dataSrcColumns)) {
      this.setState({ dataSrcColumns: nextProps.dataSrcColumns });
    }
    if (nextProps.childDataSrcColumns && !isEqual(this.props.childDataSrcColumns, nextProps.childDataSrcColumns)) {
      this.setState({ childDataSrcColumns: nextProps.childDataSrcColumns });
    }
    if (nextProps.summaryAvgColumns && !isEqual(this.props.summaryAvgColumns, nextProps.summaryAvgColumns)) {
      this.setState({ summaryAvgColumns: nextProps.summaryAvgColumns });
    }
    if (nextProps.summarySumColumns && !isEqual(this.props.summarySumColumns, nextProps.summarySumColumns)) {
      this.setState({ summarySumColumns: nextProps.summarySumColumns });
    }
    if (nextProps.childSummaryAvgColumns && !isEqual(this.props.childSummaryAvgColumns, nextProps.childSummaryAvgColumns)) {
      this.setState({ childSummaryAvgColumns: nextProps.childSummaryAvgColumns });
    }
    if (nextProps.childSummarySumColumns && !isEqual(this.props.childSummarySumColumns, nextProps.childSummarySumColumns)) {
      this.setState({ childSummarySumColumns: nextProps.childSummarySumColumns });
    }
    if (!isEqual(this.props.isLoading, nextProps.isLoading)) {
      this.setState({ isLoading: nextProps.isLoading });
    }
    if (!isEqual(this.props.isLoadingChild, nextProps.isLoadingChild)) {
      this.setState({ isLoadingChild: nextProps.isLoadingChild });
    }
    if (!isEqual(this.props.showPageSizeSelector, nextProps.showPageSizeSelector)) {
      this.setState({ showPageSizeSelector: nextProps.showPageSizeSelector });
    }
  }

  shouldComponentUpdate(nextProps: IDataGridComponentProps, nextState: IDataGridComponentState): boolean {
    return (!isEqual(this.props.dataSource, nextProps.dataSource) ||
      !isEqual(this.props.isLoading, nextProps.isLoading) ||
      !isEqual(this.props.childDataSource, nextProps.childDataSource) ||
      !isEqual(this.props.isLoadingChild, nextProps.isLoadingChild) ||
      !isEqual(this.props.showPageSizeSelector, nextProps.showPageSizeSelector)) ||
      !isEqual(this.state.isDrilldownExpanded, nextState.isDrilldownExpanded) ||
      !isEqual(this.props.toolbarContent, nextProps.toolbarContent);
  }

  hasDrilldownColumns(): boolean {
    return this.state.childDataSrcColumns &&
      !isEmpty(this.state.childDataSrcColumns);
  }

  render() {
    const dataGridButtons = <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", paddingBottom: "5px" }}>
      <div style={{ display: "flex" }}>{this.props.toolbarContent}</div>
      <div style={{ display: "flex", marginTop: "5px" }}>
        <Button
          onClick={this.resetGridState}
          type="default"
        >
          Reset
        </Button>
        <Button
          onClick={() => this.gridContainerRef.instance.showColumnChooser()}
          type="default"
          style={{ marginLeft: "5px" }}
        >
          Table Columns
        </Button>

        {this.hasDrilldownColumns() && <Button
          onClick={() => this.childGridRef.current?.instance.showColumnChooser()}
          type="default"
          style={{ marginLeft: "5px" }}
          disabled={!(this.state.isDrilldownExpanded && !isEmpty(this.state.childDataSource))}
        >
          Details Columns
        </Button>}
      </div>
    </div>

    return (
      <>
        {dataGridButtons}
        <DataGrid
          {...(this.props.dataGridOptions ?? {})}
          ref={ref => (this.gridContainerRef = ref)}
          id='gridContainer'
          dataSource={this.state.dataSource}
          showBorders={false}
          onRowExpanding={this.onRowExpanding}
          onRowCollapsed={this.onRowCollapsed}
          onRowExpanded={this.onRowExpanded}
          onRowClick={this.state.childDataSrcColumns && this.onRowClick}
          noDataText={!this.state.isLoading && this.state.dataSource && isEmpty(this.state.dataSource) ? "No data" : ""}
          loadPanel={{
            enabled: false
          }}
          onContentReady={(e: any) => {
            if (this.state.dataSource && this.state.dataSource.length === 1 && this.state.childDataSrcColumns) {
              e.component.expandRow(e.component.getKeyByRowIndex(0));
            }
          }}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnChooser={{ enabled: true, mode: 'select', title: 'Table Columns' }}
          stateStoring={{
            enabled: this.props.storeIdentifier != null,
            storageKey: this.getDataGridId('parent'),
            type: 'localStorage',
          }}
        >
          <Paging defaultPageSize={25} />
          <Pager visible={true}
            allowedPageSizes={allowedPageSizes}
            showPageSizeSelector={this.state.showPageSizeSelector}
            showInfo={true}
            showNavigationButtons={true} />

          <Selection mode={'single'} />
          {
            this.state.dataSrcColumns && !isEmpty(this.state.dataSrcColumns) &&
            this.state.dataSrcColumns.map((col, index) => {
              return (
                <Column key={"Column" + index} {...col} />
              );
            })
          }

          <MasterDetail enabled={(this.state.childDataSrcColumns && this.state.dataSource && this.state.dataSource.length > 1) ? true : false} render={this.detailRender} />

          <Summary calculateCustomSummary={this.calculateSelectedRow}>
            {
              this.state.summaryAvgColumns && !isEmpty(this.state.summaryAvgColumns) &&
              this.state.summaryAvgColumns.map((col, index) => {
                return (
                  <TotalItem
                    name={index === 0 ? 'totalavg' : null}
                    key={"AvgItem" + index}
                    column={col.column}
                    valueFormat={col.valueFormat}
                    summaryType={index === 0 ? 'custom' : 'avg'}
                    displayFormat={index === 0 ? 'Average' : (col.displayFormat || '-')}
                  />
                );
              })
            }
            {
              this.state.summarySumColumns && !isEmpty(this.state.summarySumColumns) &&
              this.state.summarySumColumns.map((col, index) => {
                return (
                  <TotalItem
                    name={index === 0 ? 'totalsum' : null}
                    key={"SumItem" + index}
                    column={col.column}
                    valueFormat={col.valueFormat}
                    summaryType={index === 0 ? 'custom' : 'sum'}
                    displayFormat={index === 0 ? 'Total' : (col.displayFormat || '-')}
                  />
                );
              })
            }
          </Summary>
        </DataGrid >
      </>
    );
  }

  resetDataGrid = () => {
    this.gridContainerRef.instance.refresh();
    this.gridContainerRef.instance.pageIndex(0);
    this.gridContainerRef.instance.collapseAll(-1);
  };

  calculateSelectedRow(options) {
    if (options.name === 'totalsum') {
      return "Total";
    }
    if (options.name === 'totalavg') {
      return "Average";
    }
  }

  onRowExpanding = (selectedRowsData) => {
    if (selectedRowsData && selectedRowsData.key) {
      if (this.props.onRowExpandingCallback) {
        this.props.onRowExpandingCallback(selectedRowsData);
      }
      selectedRowsData.component.collapseAll(-1);
      selectedRowsData.component.selectRows(selectedRowsData.key);
    }
  };

  onRowExpanded = () => {
    this.setState({
      isDrilldownExpanded: true
    });
  }

  onRowCollapsed = () => {
    this.setState({
      isDrilldownExpanded: false
    });
  }

  onRowClick = (e) => {
    if (e.rowType === 'data' && e.handled !== true && this.state.dataSource.length > 1) {
      var key = e.component.getKeyByRowIndex(e.rowIndex);
      var expanded = e.component.isRowExpanded(key);
      if (expanded) {
        e.component.collapseRow(key);
      } else {
        e.component.expandRow(key);
      }
    }
  };

  resetGridState = () => {
    const deleteFromStore = (level: 'parent' | 'child') => {
      const id = this.getDataGridId(level);
      if (id != null) localStorage.removeItem(id);
    }
    deleteFromStore('parent');
    deleteFromStore('child');
    this.gridContainerRef.instance.state({});
    this.childGridRef.current?.instance.state({});
  }

  getDataGridId = (level: 'parent' | 'child') => {
    if (this.props.storeIdentifier == null) return null;
    return `DataGridComponent-${this.props.storeIdentifier}-${level}`;
  }

  detailRender = (detail) => {
    return (
      <DataGrid
        ref={this.childGridRef}
        dataSource={this.state.childDataSource}
        showBorders={false}
        showColumnHeaders={true}
        noDataText={!this.state.isLoadingChild && this.state.childDataSource && isEmpty(this.state.childDataSource) ? "No data" : ""}
        loadPanel={{
          enabled: false
        }}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnChooser={{ enabled: true, mode: 'select', title: 'Details Columns' }}
        stateStoring={{
          enabled: this.props.storeIdentifier != null,
          storageKey: this.getDataGridId('child'),
          type: 'localStorage',
        }}
      >
        <Column
          cssClass="dx-command-expand dx-datagrid-group-space dx-datagrid-expand dx-selection-disabled dx-cell-focus-disabled"
          showInColumnChooser={false}
          width={20}
        />
        {this.state.childDataSrcColumns &&
          !isEmpty(this.state.childDataSrcColumns) &&
          this.state.childDataSrcColumns.map((col, index) => {
            return (<Column
              key={"Column" + index}
              dataField={col.dataField}
              caption={col.caption}
              format={col.format}
              dataType={col.dataType}
              width={col.width}
              visible={col.visible} 
              />);
          })}

        <Summary calculateCustomSummary={this.calculateSelectedRow}>
          {this.state.childSummaryAvgColumns &&
            !isEmpty(this.state.childSummaryAvgColumns) &&
            this.state.childSummaryAvgColumns.map((col, index) => {
              return (<TotalItem
                name={index === 0 ? 'totalavg' : null}
                key={"ChildAvgItem" + index}
                column={col.column}
                valueFormat={col.valueFormat}
                summaryType={index === 0 ? 'custom' : 'avg'}
                displayFormat={index === 0 ? 'Average' : (col.displayFormat || '-')} />);
            })}
          {this.state.childSummarySumColumns &&
            !isEmpty(this.state.childSummarySumColumns) &&
            this.state.childSummarySumColumns.map((col, index) => {
              return (<TotalItem
                name={index === 0 ? 'totalsum' : null}
                key={"ChildSumItem" + index}
                column={col.column}
                valueFormat={col.valueFormat}
                summaryType={index === 0 ? 'custom' : 'sum'}
                displayFormat={index === 0 ? 'Total' : (col.displayFormat || '-')} />);
            })}
        </Summary>
        <Paging defaultPageSize={25} />
        <Pager visible={true}
            allowedPageSizes={allowedPageSizes}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true} />
      </DataGrid >
    );
  };
}

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as LocationsActions from '../../actions/locations/LocationsActions';
import { LocationUserStampsComponent} from '../../components/locations/LocationUserStampsComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {

    userStampSuccess: state.LocationsReducer.loadUserStampsSuccess,
    userStampError: state.LocationsReducer.loadUserStampsError,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(LocationsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationUserStampsComponent)
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { loadLocalCharitiesAutocomplete } from '../../actions/dropdown/AutocompleteActions';
import { DropdownComponent } from '../../components/dropdown/DropdownComponent';
import { RootState } from '../../store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    datasource: state.AutocompleteReducer.loadLocalCharitiesAutocompleteDataSuccess,
    datasourceError: state.AutocompleteReducer.loadLocalCharitiesAutocompleteDataError,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadData: bindActionCreators(loadLocalCharitiesAutocomplete, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DropdownComponent)


import * as React from "react";
import { isEqual } from "lodash";
import { hashHistory } from "react-router";
import UserBasicDetailsFormComponent from "./UserBasicDetailsFormComponent";
import AppUtilityService from "../../../common/services/AppUtilityService";

import { Card, Button, Modal, Row, Col, Avatar, Upload, Spin } from 'antd';
const confirm = Modal.confirm;

export class UserDetailsDescriptionCardComponent extends React.Component<IAdminUserDetailsDescriptionCardProps, IUserDetailsDescriptionCardState> {

  private userBasicDetailsFormRef: any;
  constructor(props: IAdminUserDetailsDescriptionCardProps) {
    super(props);
    this.state = {
      userDetails: this.props.userDetails || null,
      isBasicDetailsEditMode: false,
      isLoading: this.props.userDetails ? false : true
    }
  }

  componentWillReceiveProps(nextProps: IAdminUserDetailsDescriptionCardProps) {
    if (nextProps.userDetails && !isEqual(this.props.userDetails, nextProps.userDetails)) {
      this.setState({ userDetails: nextProps.userDetails, isLoading: false })
    }

    /**
     * Update user details success/error handle
     */
    if (nextProps.updateUserDetailsSuccess && !isEqual(this.props.updateUserDetailsSuccess, nextProps.updateUserDetailsSuccess)) {
      AppUtilityService.showMessageToast("User has been updated successfully", "success");
      this.props.updateUserDetailsSuccessCallback();
      this.setState({ isBasicDetailsEditMode: false, isLoading: false })
    }
    if (nextProps.updateUserDetailsError && !isEqual(this.props.updateUserDetailsError, nextProps.updateUserDetailsError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.updateUserDetailsError);
    }

    /**
     * Delete user details success/error handle
     */
    if (nextProps.deleteUserSuccess !== null && !isEqual(this.props.deleteUserSuccess, nextProps.deleteUserSuccess)) {
      this.setState({ isLoading: false })
      if (nextProps.deleteUserSuccess) {
        AppUtilityService.showMessageToast("Selected user has been disabled", "success");
        hashHistory.push("/admin/users");
      }
    }
    if (nextProps.deleteUserError && !isEqual(this.props.deleteUserError, nextProps.deleteUserError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.deleteUserError);
    }

    /**
     * Enable user success/error handle
     */
    if (nextProps.enableUserSuccess !== null && !isEqual(this.props.enableUserSuccess, nextProps.enableUserSuccess)) {
      this.setState({ isLoading: false })
      if (nextProps.enableUserSuccess) {
        AppUtilityService.showMessageToast("Selected user has been enabled", "success");
        this.props.updateUserDetailsSuccessCallback();
      }
    }
    if (nextProps.enableUserError && !isEqual(this.props.enableUserError, nextProps.enableUserError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.enableUserError);
    }

    /**
     * Upload user image success/error handle
     */
    if (nextProps.uploadUserImageSuccess && !isEqual(this.props.uploadUserImageSuccess, nextProps.uploadUserImageSuccess)) {
      var userDetails = { ...this.state.userDetails };
      var responseData = nextProps.uploadUserImageSuccess;
      userDetails.image = responseData.blob;
      userDetails.pictureId = responseData.documentId;
      this.setState({ userDetails, isLoading: false });
      AppUtilityService.showMessageToast("User image updated successfully", "success");
    }
    if (nextProps.uploadUserImageError && !isEqual(this.props.uploadUserImageError, nextProps.uploadUserImageError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.uploadUserImageError);
    }

    /**
     * Remove user image success/error handle
     */
    if (nextProps.removeUserImageSuccess !== null && !isEqual(this.props.removeUserImageSuccess, nextProps.removeUserImageSuccess)) {
      this.setState({ isLoading: false })
      if (nextProps.removeUserImageSuccess) {
        var userDetails = { ...this.state.userDetails };
        userDetails.image = null;
        this.setState({ userDetails });
      }
    }
    if (nextProps.removeUserImageError && !isEqual(this.props.removeUserImageError, nextProps.removeUserImageError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.removeUserImageError);
    }
  }

  render() {
    var cardActions = <span>
      <Button className="mr-8" type="primary" onClick={this.showEditDetailsForm}>Edit</Button>
      {this.state.userDetails && this.state.userDetails.active 
        ? <Button onClick={this.deleteUser}>Disable</Button> 
        : <Button onClick={this.enableUser}>Enable</Button>
      }
    </span>

    return (
      <Card className="no-header-border" bordered={false} title={""} extra={""}>
        <Spin spinning={this.state.isLoading}>
          {
            this.state.userDetails && !this.state.isBasicDetailsEditMode &&
            <Row gutter={24} style={{ paddingTop: '12px' }}>
              <Col xl={3} lg={5}>
                <div className="image-upload-container">
                  {
                    this.state.userDetails && this.state.userDetails.role !== 'Admin' &&
                    <Upload
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={this.beforeUploadHandler}
                      onChange={this.handleFileUploadChange}
                    >
                      <div className="image-upload">
                        <i className="icon-upload-photo" />
                      </div>
                    </Upload>
                  }
                  <Avatar size={130}
                    src={this.state.userDetails.image ? AppUtilityService.getImageFromByteArray(this.state.userDetails.image) : null}
                  />
                  {
                    this.state.userDetails && this.state.userDetails.role !== 'Admin' && this.state.userDetails.image &&
                    <div className="action-btn">
                      <span className="link-danger bold font-12" onClick={this.removeUserImage}>Remove</span>
                    </div>
                  }
                </div>
              </Col>
              <Col xl={21} lg={19}>
                <Row gutter={24}>
                  <Col span={16}>
                    <h1>{this.state.userDetails.firstName + " " + (this.state.userDetails.lastName ? this.state.userDetails.lastName : '')}</h1>
                  </Col>
                  <Col span={8} className="text-right">
                        {this.state.userDetails && cardActions}
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col md={12} xl={10} xxl={6}>
                    <p><span className="bold">Email: </span><span>{this.state.userDetails.userName}</span></p>
                  </Col>
                  <Col md={12} xl={10} xxl={6}>
                    <p><span className="bold">Phone: </span><span>{this.state.userDetails.contactNo}</span></p>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col md={12} xl={10} xxl={6}>
                    <p><span className="bold">User Type: </span><span>{this.state.userDetails.role}</span></p>
                  </Col>
                  <Col md={12} xl={10} xxl={6}>
                    <p><span className="bold">Date created: </span><span>{AppUtilityService.formatDate(this.state.userDetails.created)}</span></p>
                  </Col>
                </Row>
              </Col>
            </Row>
          }
          {
            this.state.userDetails && this.state.isBasicDetailsEditMode &&
            <Row style={{ paddingTop: '12px' }}>
              <Col span={24}>
                <UserBasicDetailsFormComponent
                  ref={(ref) => this.userBasicDetailsFormRef = ref}
                  userDetails={this.state.userDetails} />
                <Row>
                  <Col span={21} offset={3}>
                    <Button className="mr-8" type="primary" htmlType="submit" onClick={this.handleUpdateUserSubmit}>Save</Button>
                    <Button onClick={this.cancelEditDetails}> Cancel </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          }
        </Spin>
      </Card>
    )
  }
  /**
   * Toggle between details view and edit form
   */
  showEditDetailsForm = () => {
    this.setState({ isBasicDetailsEditMode: true })
  }
  cancelEditDetails = () => {
    this.setState({ isBasicDetailsEditMode: false })
  }

  /**
   * Handle user details form submit using ref
   */
  handleUpdateUserSubmit = (e) => {
    e.preventDefault();
    this.userBasicDetailsFormRef.validateFields((err, values) => {
      if (!err) {
        this.setState({ isLoading: true })
        let { userDetails } = this.state;
        var formData = values;
        formData.ContactId = userDetails.contactId;
        if (!formData.lastName) {
          formData.lastName = "";
        }
        this.props.actions.updateUserDetails(userDetails.userId, formData);
      }
    })
  }

  /**
   * Handle delete user on confirming confirm prompt
   */
  deleteUser = () => {
    var { userDetails } = this.state;
    confirm({
      title: 'Confirm dialog',
      content: 'Are you sure you want to disable the selected user?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        this.setState({ isLoading: true })
        this.props.actions.deleteUser(userDetails.userId)
      },
    });
  }

  enableUser = () => {
    var { userDetails } = this.state;
    confirm({
      title: 'Confirm dialog',
      content: 'Are you sure you want to enable the selected user?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        this.setState({ isLoading: true })
        this.props.actions.enableUser(userDetails.userId)
      },
    });
  }

  /**
   * Image upload / remove
   */
  beforeUploadHandler = file => {
    return false;
  };

  handleFileUploadChange = info => {
    var { userDetails } = this.state;
    if (info.file.status !== "removed") {
      this.setState({ isLoading: true })
      let files = new FormData();
      files.append(info.file.name, info.file);
      this.props.actions.uploadUserImage(userDetails.userId, files);
    }
  };

  removeUserImage = () => {
    var { userDetails } = this.state;
    confirm({
      title: 'Confirm dialog',
      content: 'Are you sure you want to remove user image?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        this.setState({ isLoading: true })
        this.props.actions.removeUserImage(userDetails.userId, userDetails.pictureId);
      },
    });
  }
} 

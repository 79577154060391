import * as React from "react";
import { isEqual, isEmpty, filter, cloneDeep } from "lodash";

import AppUtilityService from "../../../../common/services/AppUtilityService";
import { DataGridComponent } from "../../../../common/components/table/DataGridComponent";
import ReportsUtilityService from "../../../../common/services/ReportsUtilityService";
import AppConstants from "../../../../common/AppConstants";

import { Row, Col, Spin } from 'antd';
import { IGameTerminalStatusComponentProps, IGameTerminalStatusComponentState } from '../Reports';

export class GameTerminalStatusComponent extends React.Component<IGameTerminalStatusComponentProps, IGameTerminalStatusComponentState> {

    private gridContainerRef: any;

    constructor(props: IGameTerminalStatusComponentProps) {
        super(props);
        this.state = {
            gameTerminalStatusStats: null,
            gameTerminalStatusList: null,
            isLoading: false,
            isLoadingCharts: false,
            isLoadingList: false,
            downloadType: "pdf",
            apiParams: this.props.apiParams,
        }
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillReceiveProps(nextProps: IGameTerminalStatusComponentProps) {
        if (nextProps.apiParams && !isEqual(this.props.apiParams, nextProps.apiParams)) {
            this.gridContainerRef && this.gridContainerRef.resetDataGrid();
            this.loadGameTerminalStatusChart(nextProps.apiParams);
            this.loadGameTerminalStatusList(nextProps.apiParams);
            this.setState({ apiParams: nextProps.apiParams })
        }
        /**
         * Recon summary by date
         */
        if (nextProps.loadGameTerminalStatusChartSuccess && !isEqual(this.props.loadGameTerminalStatusChartSuccess, nextProps.loadGameTerminalStatusChartSuccess)) {
            this.setState({ gameTerminalStatusStats: nextProps.loadGameTerminalStatusChartSuccess, isLoadingCharts: false })
        }

        if (nextProps.loadGameTerminalStatusChartError && !isEqual(this.props.loadGameTerminalStatusChartError, nextProps.loadGameTerminalStatusChartError)) {
            this.setState({ isLoadingCharts: false })
            AppUtilityService.handleApiError(nextProps.loadGameTerminalStatusChartError);
        }
        if (nextProps.loadGameTerminalStatusListSuccess && !isEqual(this.props.loadGameTerminalStatusListSuccess, nextProps.loadGameTerminalStatusListSuccess)) {
            this.setState({ gameTerminalStatusList: nextProps.loadGameTerminalStatusListSuccess, isLoadingList: false })
        }

        if (nextProps.loadGameTerminalStatusListError && !isEqual(this.props.loadGameTerminalStatusListError, nextProps.loadGameTerminalStatusListError)) {
            this.setState({ isLoadingList: false })
            AppUtilityService.handleApiError(nextProps.loadGameTerminalStatusListError);
        }

        /**
         * Report download excel
         */
        if (nextProps.exportDataToExcelSuccess && !isEqual(this.props.exportDataToExcelSuccess, nextProps.exportDataToExcelSuccess)) {
            this.setState({ isLoading: false })
            if (!isEmpty(nextProps.exportDataToExcelSuccess.exportList)) {
                if (this.state.downloadType === "pdf") {
                    this.downloadPDF(nextProps.exportDataToExcelSuccess.exportList)
                } else {
                    this.downloadExcel(nextProps.exportDataToExcelSuccess.exportList)
                }
            }
        }

        if (nextProps.exportDataToExcelError && !isEqual(this.props.exportDataToExcelError, nextProps.exportDataToExcelError)) {
            this.setState({ isLoading: false })
            AppUtilityService.handleApiError(nextProps.exportDataToExcelError);
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
    }
    render() {
        const devExColumnData: any = filter(AppConstants.gameTerminalStatusColumns, { "reportType": "gameTerminalStatus" })[0];
        let devExSummaryAvgCols = devExColumnData && devExColumnData.columns;
        let devExSummarySumCols = devExColumnData && filter(devExColumnData.columns, (o) => { return (o.dataField !== 'fpPercent' && o.dataField !== 'tpPercent') });

        return (
            <Spin spinning={this.state.isLoading}>
                <div style={{ padding: "24px" }}>
                    <h2>Status report</h2>
                    <Spin spinning={this.state.isLoadingCharts}>
                        <Row gutter={24}>
                            <Col span={24}>
                                {
                                    this.state.gameTerminalStatusStats &&
                                    <div id="stat-cards">
                                        <Row gutter={24}>
                                            <Col lg={{ span: 8 }}>
                                                <div className="game-terminal-stat-card danger">
                                                    <h2>{this.state.gameTerminalStatusStats.down}</h2>
                                                    <p>DOWN</p>
                                                </div>
                                            </Col>
                                            <Col lg={{ span: 8 }}>
                                                <div className="game-terminal-stat-card success">
                                                    <h2>{this.state.gameTerminalStatusStats.workingUpdated}</h2>
                                                    <p>WORKING & UPDATED</p>
                                                </div>
                                            </Col>
                                            <Col lg={{ span: 8 }}>
                                                <div className="game-terminal-stat-card warning">
                                                    <h2>{this.state.gameTerminalStatusStats.workingNotUpdated}</h2>
                                                    <p>WORKING & NOT UPDATED</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                <br />
                                <br />
                            </Col>
                        </Row>
                    </Spin>
                </div>
                <div style={{ padding: "24px" }}>
                    <Spin spinning={this.state.isLoadingList}>
                        <div>
                            <DataGridComponent
                                ref={ref => (this.gridContainerRef = ref)}
                                toolbarContent={<h2>Detailed view of selected time period:</h2>}
                                dataSource={this.state.gameTerminalStatusList && this.state.gameTerminalStatusList.summaryList}
                                dataSrcColumns={devExColumnData && !isEmpty(devExColumnData.columns) && devExColumnData.columns}
                                summaryAvgColumns={devExColumnData && !isEmpty(devExSummaryAvgCols) && devExSummaryAvgCols}
                                summarySumColumns={devExColumnData && !isEmpty(devExSummarySumCols) && devExSummarySumCols}
                                isLoading={this.state.isLoadingList}
                                storeIdentifier={`GameTerminalStatus-${devExColumnData.reportType}`}
                            />
                        </div>
                    </Spin>
                </div>
            </Spin>
        )
    }


    loadGameTerminalStatusChart = (apiParams) => {
        this.setState({ isLoadingCharts: true })
        apiParams.summaryType = apiParams.reportType;
        this.props.actions.loadGameTerminalStatusChart(apiParams)
    }

    loadGameTerminalStatusList = (apiParams) => {
        this.setState({ isLoadingList: true, gameTerminalStatusList: null })
        apiParams.summaryType = apiParams.reportType;
        this.props.actions.loadGameTerminalStatusList(apiParams)
    }

    getDownloadData = (downloadType) => {
        this.setState({ isLoading: true, downloadType })
        var apiParams = { ...this.state.apiParams };
        apiParams.excelType = "ByGTStatus";
        apiParams.isExcel = downloadType === "excel" ? true : false;
        this.props.actions.exportDataToExcel(apiParams);
    }

    downloadExcel = (exportListData) => {
        const devExColumnData: any = filter(AppConstants.gameTerminalStatusColumns, { "reportType": "gameTerminalStatus" })[0];
        var { gameTerminalStatusList } = cloneDeep(this.state);
        var dateRange = [this.state.apiParams.dateFrom, this.state.apiParams.dateTo]
        ReportsUtilityService.downloadGameTerminalStatusExcel(dateRange, exportListData, devExColumnData)
    }

    downloadPDF = (dataset) => {
        const devExColumnData: any = filter(AppConstants.gameTerminalStatusColumns, { "reportType": "gameTerminalStatus" })[0];
        ReportsUtilityService.downloadGameTerminalStatusPDF(dataset, devExColumnData)
    }

}
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as UserRoleActions from '../../actions/userRole/UserRoleActions';
import { SaveUserRoleModal } from '../../components/userRole/SaveUserRoleModal';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    addNewUserRoleSuccess: state.UserRoleReducer.addNewUserRoleSuccess,
    addNewUserRoleError: state.UserRoleReducer.addNewUserRoleError,

    updateSelectedUserRoleSuccess: state.UserRoleReducer.updateSelectedUserRoleSuccess,
    updateSelectedUserRoleError: state.UserRoleReducer.updateSelectedUserRoleError,

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(UserRoleActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveUserRoleModal)

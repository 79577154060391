import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ReportsActions from '../../../actions/reports/ReportsActions';
import {
  ReconSummaryIPRReportComponent,
} from '../../../components/reports/locationStatisticsReports/ReconSummaryIPRReportComponent';
import { RootState } from '../../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadReconSummaryIPRListSuccess: state.ReportsReducer.loadReconSummaryIPRListSuccess,
    loadReconSummaryIPRListError: state.ReportsReducer.loadReconSummaryIPRListError,

    loadReconSummaryIPRChildListSuccess: state.ReportsReducer.loadReconSummaryIPRChildListSuccess,
    loadReconSummaryIPRChildListError: state.ReportsReducer.loadReconSummaryIPRChildListError,

    exportIPRDataToExcelSuccess: state.ReportsReducer.exportDataToExcelIPRSuccess,
    exportIPRDataToExcelError: state.ReportsReducer.exportDataToExcelIPRError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ReportsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReconSummaryIPRReportComponent)

import * as React from "react";
import moment from "moment"

import { Card, Button, Row, Col, DatePicker } from 'antd'
import { get, isEqual } from "lodash";
import { CSVLink } from "react-csv";

export class GetInvoicesComponent extends React.Component<IGetInvoicesProps, IGetInvoicesState> {

    csvLinkElement: React.RefObject<CSVLink>;
    constructor(props: IGetInvoicesProps) {
        super(props);
        this.download = this.download.bind(this);

        this.state = {
            invoice: null,
            searchParams: defaultParams,
            invoiceToDownload: []
        }

        this.csvLinkElement = React.createRef();
    }

    componentWillReceiveProps(nextProps: IGetInvoicesProps) {
        if (nextProps.getInvoiceSuccess && !isEqual(this.props.getInvoiceSuccess, nextProps.getInvoiceSuccess)) {
            var responseData: Array<GetInvoiceDetail> = nextProps.getInvoiceSuccess;
            this.setState({ invoice: responseData })
        }
    }

    isValidDate(date): boolean {
        const momentWeekEndDate = moment(date)
        return momentWeekEndDate.isValid()
    }

    download = async (event) => {
        var selectedDate = moment(this.state.searchParams.weekEndDate).format('L');
        this.state.searchParams.weekEndDateString = selectedDate;

        const data = await this.getInvoiceData();

        this.setState({ searchParams: { weekEndDate: this.state.searchParams.weekEndDate, weekEndDateString: selectedDate }, invoiceToDownload: data }, () => {
            setTimeout(() => {
                this.csvLinkElement.current.link.click();
            }, 1000);
        });
    }

    getInvoiceData = async () => {

        await this.props.actions.getInvoicesData(this.state.searchParams);
        return this.props.getInvoiceSuccess;
    }


    render() {
        const dateFormat = 'MM/DD/YYYY'

        return (
             <Card>
                <Row>
                    <Col span={18}>
                        <Col span={6}>
                            <DatePicker onChange={(date, dateString) => {
                                const updated = this.state.searchParams
                                updated.weekEndDate = date.toDate();
                                updated.weekEndDateString = date.toDate().toLocaleDateString();
                                this.setState({ searchParams: updated })
                            }}
                                placeholder={'Select Week End Date'}
                                value={moment(this.state.searchParams.weekEndDate)}
                                format={dateFormat}
                                style={{ paddingLeft: '24pt' }}
                            />
                        </Col>
                        <Col span={6}>
                            <Button
                                disabled={!this.isValidDate(this.state.searchParams.weekEndDate)}
                                onClick={this.download}
                                type={'primary'}> Generate Invoice</Button>

                            <CSVLink
                                data={this.state.invoiceToDownload}
                                filename={"Ohio_Invoice" + this.state.searchParams.weekEndDateString + ".csv"}
                                ref={this.csvLinkElement}
                            />
                        </Col>
                    </Col>
                </Row>
            </Card>
            )
    }
}

const defaultParams: GetInvoiceParams = {
    weekEndDate: moment().toDate(),
    weekEndDateString: moment().toDate().toLocaleDateString()
}

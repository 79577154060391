export interface TicketSalesByGameReportItem {
  gameName: string;
  soldTicketCount: number;
  grossRevenue: number;
  payout: number;
  ticketNetRevenue: number;
  progressivePayout: number;
  currentNetRevenue: number;
  theoreticalNetRevenue: number;
}

export interface TicketSalesByGameReportChildItem {
  gameName: string;
  formCode: string;
  gamePrice: number;
  soldTicketCount: number;
  grossRevenue: number;
  payout: number;
  ticketNetRevenue: number;
  progressivePayout: number;
  currentNetRevenue: number;
  theoreticalNetRevenue: number;
}

export interface TicketSalesByDateReportItem {
  gameName: string;
  soldTicketCount: number;
  grossRevenue: number;
  payout: number;
  ticketNetRevenue: number;
  progressivePayout: number;
  currentNetRevenue: number;
  theoreticalNetRevenue: number;
}

export interface TicketSalesByDateReportChildItem {
  gameName: string;
  date: string;
  soldTicketCount: number;
  grossRevenue: number;
  payout: number;
  ticketNetRevenue: number;
  progressivePayout: number;
  currentNetRevenue: number;
  theoreticalNetRevenue: number;
}

interface IdName
{
  id: number;
  name: string;
}
export interface ArrowJurisdictionSitesOrganizations {
  jurisdictions: IdName[];
  locations: IdName[];
  organizations: IdName[];
}

export interface LocationUninstallAlertReportItem {
  locationId: number;
  locationName: string;
  firstPlayDate: string | null;
  lastPlayDate: string | null;
}

export enum TerminalType {
  Cabinet = 1,
  Tablet = 2,
  Computer = 3,
  None = 4,
  Unclassified = 5
}

export interface CharitiesSessions {
  locationId: number;
  charityId: number | null;
  sessionId: number | null;
  dateFrom: Date | null;
  dateTo: Date | null;
}
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as LocationsActions from '../../actions/locations/LocationsActions';
import LocationLinkedZonesComponent from '../../components/locations/LocationLinkedZonesComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadBasicDetailsSuccess: state.LocationsReducer.loadBasicDetailsSuccess,

    addLocationZoneSuccess: state.LocationsReducer.addLocationZoneSuccess,
    addLocationZoneError: state.LocationsReducer.addLocationZoneError,

    deleteLocationZoneSuccess: state.LocationsReducer.deleteLocationZoneSuccess,
    deleteLocationZoneError: state.LocationsReducer.deleteLocationZoneError,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(LocationsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationLinkedZonesComponent)

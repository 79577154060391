import * as React from "react";
import SummaryChartCardContainer from "../../containers/locations/SummaryChartCardContainer";
import LocationLinkedContactsContainer from "../../containers/locations/LocationLinkedContactsContainer";
import LocationLinkedCharitiesContainer from "../../containers/locations/LocationLinkedCharitiesContainer";
import LocationLinkedZonesContainer from "../../containers/locations/LocationLinkedZonesContainer";
import WebAccessService from "../../../common/services/WebAccessService";

import { Layout, Row, Col } from 'antd';

export class LocationDetailsOverviewTabComponent extends React.Component<ILocationDetailsOverviewTabProps, {}> {

  constructor(props: ILocationDetailsOverviewTabProps) {
    super(props);

    this.state = {

    }
  }

  render() {
    return (
      <div className="container">
        <Layout>
          <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}} type="flex" align="top">
            <Col className="sm-row-b-margin md-row-b-margin lg-row-b-margin" xl={12} md={24} sm={24} xs={24}>
              <SummaryChartCardContainer locationId={this.props.locationId} cardType="ByGame" />
            </Col>
            <Col className="sm-row-b-margin" xl={12} md={24} sm={24} xs={24}>
              <SummaryChartCardContainer locationId={this.props.locationId} cardType="ByDate" />
            </Col>
          </Row>
          <div>
            <br />
          </div>
          <Row type="flex" align="top" gutter={24}>
            {
              WebAccessService.hasPermissionToAccess("Location", "ViewContacts") &&
              <Col xl={8} lg={12} md={12} sm={24} xs={24} className="sm-row-b-margin md-row-b-margin lg-row-b-margin">
                <LocationLinkedContactsContainer />
              </Col>
            }
            {
              WebAccessService.hasPermissionToAccess("Location", "ViewCharities") &&
              <Col xl={8} lg={12} md={12} sm={24} xs={24} className="sm-row-b-margin md-row-b-margin lg-row-b-margin">
                <LocationLinkedCharitiesContainer />
              </Col>
            }
            {
              WebAccessService.hasPermissionToAccess("Location", "ViewZones") &&
              <Col xl={8} lg={12} md={12} sm={24} xs={24} className="sm-row-b-margin md-row-b-margin lg-row-b-margin">
                <LocationLinkedZonesContainer />
              </Col>
            }
          </Row>
        </Layout>
      </div>
    )
  }

}

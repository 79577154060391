
import * as React from "react"
import { Row, Col, Table, List } from "antd"
import { hashHistory } from "react-router"
import { ILocationSimpleDetail } from "../../reducers/quarterClose/QuarterCloseReducer"
import { isEqual } from "lodash"
import "./QuarterCloseListStyles.css";
const yesButton = require("../../../images/Button_Denom_Yes.png")
const noButton = require("../../../images/Button_Denom_No.png")
const styles = require('../../../common/styles/GameSelectStyles.css')


export interface IQuarterCloseEnableTitoPageProps {
  locationListSuccess?: Array<ILocationSimpleDetail>;
  actions?: {
    getLocationsSimpleDetails(params): void;
    updateBulkLocationsSettings(params): void;
  }
  locationsListSimple: Array<ILocationSimpleDetail>;
  locationIdsWithoutSettings: number[];
}

export interface IQuarterCloseEnableTitoPageState {
  locationsList: Array<ILocationSimpleDetail>,
  locationIdsWithoutSettings: number[]
}

export class QuarterCloseEnableTitoPage extends React.Component<IQuarterCloseEnableTitoPageProps, IQuarterCloseEnableTitoPageState> {

  constructor(props: IQuarterCloseEnableTitoPageProps) {
    super(props);
    this.state = {
      locationsList: [],
      locationIdsWithoutSettings: this.props.locationIdsWithoutSettings
    }
  }

  componentDidMount() {
    this.loadLocationList();
  }

  componentWillReceiveProps(nextProps: IQuarterCloseEnableTitoPageProps) {
    if(nextProps.locationListSuccess && !isEqual(this.props.locationListSuccess, nextProps.locationListSuccess)){
      this.setState({ locationsList: nextProps.locationListSuccess })
    }
  }

  render() {

      const onEnableTitoForLocationsWithoutSettings = (enable: boolean) => {
          if (enable)
          {
            // update
            this.updateLocationsSettings(enable);
            hashHistory.push('/quarterClose/enableW2G');
          }
          else
          {
            // update with false
            this.updateLocationsSettings(enable);
            hashHistory.push('/quarterClose/payoutOptions');
          }
      };

    return (
        <div className="pg-container page-container gameSelectBackground">
            <Row type="flex" justify="center" align="middle">
                <Col >
                    <span style={{ textAlign: 'center' }} className="gameSelectHeader">ENABLE TITO (Ticket In/Ticket Out)</span>
                </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
                <Col>
                    <span className="payoutText">Would you like to enable TITO at the following locations?</span>
                </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
              <Col>
              <div className="listing">
                <List
                  dataSource={this.state.locationsList}
                  renderItem={item => (
                    <List.Item>
                      {item.name} - [{item.id}]
                    </List.Item>
                  )}
                />
                </div>                
              </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
                <Col>
                    <span className="payoutText">Enabling TITO will allow players to insert vouchers at the Game Terminals for redemption of credits.</span>
                </Col>
            </Row>    
            <Row type="flex" justify="center" align="middle">
                <Col>
                    <span className="payoutText">By Choosing "YES", the system will be configured to ALLOW redemption of vouchers.</span>
                </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
                <Col>
                    <span className="payoutText">By Choosing "NO", the system will be configured to REJECT redemption of vouchers.</span>
                </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
                <Col>
                    <img className="payoutButtons" onClick={() => { onEnableTitoForLocationsWithoutSettings(true); }} src={yesButton} alt="Enable TITO Redemptions" />
                    <img className="payoutButtons" onClick={() => { onEnableTitoForLocationsWithoutSettings(false); }} src={noButton} alt="Do not enable TITO Redemptions" />
                </Col>
            </Row>
      </div>
    )
  }

  loadLocationList = () => {
    this.props.actions.getLocationsSimpleDetails(this.props.locationIdsWithoutSettings);
  }

  updateLocationsSettings = (enable) => {
    let params = {
      locationIds: this.state.locationIdsWithoutSettings,
      enableTito: enable,
      enableW2G: false
    }

    this.props.actions.updateBulkLocationsSettings(params);
  }
}

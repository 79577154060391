import React, { useCallback, useEffect } from 'react';
import { IInvoiceAdjustment, InvoiceAdjustmentType, IAddUpdateInvoiceAdjustmentRequest } from '../../reducers/billing/billing.models';
import { Button, Col, Form, Input, Modal, Radio, Row } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { LoadState } from '../../../common/store/fetched';
import { usePrevious } from '../../../common/hooks/usePrevious';
import { FormComponentProps } from 'antd/lib/form';
import { addInvoiceAdjustment } from '../../actions/billing/addInvoiceAdjustment';
import { updateInvoiceAdjustment } from '../../actions/billing/updateInvoiceAdjustment';

function hasErrors(fieldsError: Record<string, string[]>) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

function parseAmount(value: string) {
  if (value.length === 0) {
    return null;
  }
  return /^-?[\d,]+(\.\d{0,2})?$/.test(value) ? parseFloat(value) : NaN;
}

async function validateMaxAmount(_rule: unknown, value: number | null) {
  if (value == null || isNaN(value)) return;

  if (Math.abs(value) > 99999.99) {
    throw new Error('Max amount exceeded.');
  }
}

type Props = FormComponentProps & {
  invoiceId: number;
  adjustment: IInvoiceAdjustment | null;
  onClose: () => void;
};

const EditInvoiceAdjustmentModalComponent = ({ invoiceId, adjustment, onClose, form }: Props) => {
  const isAdd = adjustment == null;
  const { getFieldDecorator, getFieldsError } = form;

  const dispatch = useAppDispatch();
  const saveState = useAppSelector(state => isAdd
    ? state.BillingReducer.addAdjustmentOperation.loadState
    : state.BillingReducer.updateAdjustmentOperation.loadState);
  const isSaving = saveState === LoadState.InProgress;
  const prevSaveState = usePrevious(saveState);

  useEffect(() => {
    if (prevSaveState === LoadState.InProgress && saveState === LoadState.Success) {
      onClose();
    }
  }, [saveState, prevSaveState, onClose]);

  const handleSave = useCallback(() => {
    form.validateFields(async (err, values) => {
      if (err) return;

      const request: IAddUpdateInvoiceAdjustmentRequest = {
        adjustmentAmount: parseAmount(values.adjustmentAmount),
        invoiceAdjustmentTypeId: values.invoiceAdjustmentTypeId,
        comment: values.comment || null,
      }

      if (isAdd) {
        await dispatch(addInvoiceAdjustment(invoiceId, request));
      } else {
        await dispatch(updateInvoiceAdjustment(adjustment, request));
      }
    });
  }, [adjustment, dispatch, form, invoiceId, isAdd]);

  const handleCancel = useCallback(() => {
    if (!isSaving) {
      onClose();
    }
  }, [isSaving, onClose]);

  return (
    <Modal
      visible
      title={isAdd ? 'Add adjustment' : 'Edit adjustment'}
      onCancel={handleCancel}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={onClose} disabled={isSaving}>Cancel</Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          onClick={handleSave}
          loading={isSaving}
          disabled={hasErrors(getFieldsError())}
        >
          {isAdd ? 'Add' : 'Save'}
        </Button>,
      ]}
    >
      <Form>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Adjustment amount">
              {getFieldDecorator('adjustmentAmount', {
                initialValue: adjustment?.adjustmentAmount.toFixed(2) ?? '',
                rules: [
                  { required: true, message: 'Field is required.' },
                  { type: 'number', message: 'Invalid amount.', transform: parseAmount },
                  { validator: validateMaxAmount, message: 'Max amount is $99,999.99.' }
                ],
              })(
                <Input placeholder="" disabled={isSaving} />
              )}
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item label="Adjustment type">
              {getFieldDecorator('invoiceAdjustmentTypeId', {
                initialValue: adjustment?.invoiceAdjustmentTypeId ?? null,
                rules: [
                  { required: true, message: 'Field is required.' },
                ],
              })(
                <Radio.Group disabled={isSaving}>
                  <Radio.Button value={InvoiceAdjustmentType.Credit}>Credit</Radio.Button>
                  <Radio.Button value={InvoiceAdjustmentType.Promo}>Promo</Radio.Button>
                  <Radio.Button value={InvoiceAdjustmentType.Contractual}>Contractual</Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Name">
              {getFieldDecorator('comment', {
                initialValue: adjustment?.comment,
                rules: [
                  { max: 100, message: 'Maximum length is 100.' }
                ]
              })(
                <Input placeholder="" disabled={isSaving} />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

const EditInvoiceAdjustmentModal =
  Form.create<Props>()(EditInvoiceAdjustmentModalComponent);

export default EditInvoiceAdjustmentModal;

import { createStandardFetchedAction } from '../../../common/store/fetched.slices';
import { downloadInvoiceAttachmentOperationUpdated } from './slice-actions';
import apiService from '../../../common/services/ApiService';
import { DownloadedFile } from '../../../common/store/fileDownload';
import { InvoiceAttachmentType } from '../../reducers/billing/billing.models';

export const downloadInvoiceAttachment = (
  invoiceId: number, type: InvoiceAttachmentType, invoiceAttachmentId?: number) => createStandardFetchedAction(
  downloadInvoiceAttachmentOperationUpdated,
  async () => {
    const optionalId = invoiceAttachmentId ?? '';
    const result = await apiService.downloadFile(`/invoices/${invoiceId.toFixed(0)}/attachments/${type}/${optionalId}`);
    const fileName = result.headers['x-filename'];
    return {
      data: result.data,
      fileName,
    } as DownloadedFile;
  },
  false,
);

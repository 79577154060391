import React, { useEffect, useState } from 'react';
import useBreadcrumbs from '../../../common/hooks/useBreadcrumbs';
import { Card, Dropdown, Icon, Layout, Menu, message } from 'antd';
import LocationUninstallAlertReportFilters, { Filters } from './LocationUninstallAlertReportFilters';
import LocationUninstallAlertReport from './LocationUninstallAlertReport';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { getLocationUninstallAlertReport } from '../../actions/reporting/getLocationUninstallAlertReport';
import WebAccessService from '../../../common/services/WebAccessService';
import { LoadState } from '../../../common/store/fetched';
import {
  downloadExcelLocationUninstallAlertReport,
} from '../../actions/reporting/downloadExcelLocationUninstallAlertReport';
import { getCurrentWeekPeriod } from '../../../common/components/filters/WeeklyDateRangeFilter';


const LocationUninstallAlertReportPage = () => {
  const dispatch = useAppDispatch();
  const reportUpdated = useAppSelector(state => state.ReportingReducer.locationUninstallAlertReportUpdated);
  const downloadReportUpdated = useAppSelector(state => state.ReportingReducer.downloadExcelLocationUninstallAlertReportUpdated);

  const [filters, setFilters] = useState<Filters>(() => {
    const currentWeek = getCurrentWeekPeriod();
    return ({
      startDate: currentWeek.start,
      endDate: currentWeek.end,
      install: true,
    });
  });

  useBreadcrumbs([
    { name: 'Reports', link: '/Reports' },
    { name: 'Location (Un)install Alert', link: null },
  ]);

  useEffect(
    () => {
      dispatch(getLocationUninstallAlertReport(
        filters.startDate,
        filters.endDate,
        filters.zoneId,
        filters.install,
      ));
    },
    [dispatch, filters]);

  useEffect(
    () => {
      if (downloadReportUpdated.loadState === LoadState.Failed) {
        message.error('Failed to download report');
      }
    },
    [downloadReportUpdated]);

  const downloadExcel = () => {
    dispatch(downloadExcelLocationUninstallAlertReport(
      filters.startDate,
      filters.endDate,
      filters.zoneId,
      filters.install,
    ));
  };

  const extraActions = (
    <div>
      <Dropdown.Button
        type="primary"
        onClick={downloadExcel}
        disabled={
          reportUpdated.loadState === LoadState.InProgress
          ||
          downloadReportUpdated.loadState === LoadState.InProgress
        }
        overlay={
          <Menu>
            <Menu.Item onClick={downloadExcel}>Excel</Menu.Item>
          </Menu>}>
        <Icon type="download" style={{ fontSize: "16px" }}/> Download as Excel
      </Dropdown.Button>
    </div>
  );

  return (
    <>
      <Card
        title="Location (Un)install Alert"
        style={{ paddingTop: "12px" }}
        className="reports-filter-card content-card no-header-border"
        bordered={false}
        extra={WebAccessService.hasPermissionToAccess("Reports", "Download") ? extraActions : null}
      >
        <LocationUninstallAlertReportFilters filters={filters} applyFilters={setFilters}/>
      </Card>
      <div className="pg-reports-container">
        <Layout>
          <div className="container" style={{ background: '#fff' }}>
            <LocationUninstallAlertReport/>
          </div>
        </Layout>
      </div>
    </>
  );
}

export default LocationUninstallAlertReportPage;

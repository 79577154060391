import { Icon, notification } from 'antd';

export const errorNotification = (message: string, description: string) => {
  notification.open({
    message,
    description,
    icon: Icon({ type: 'exclamation-circle', style: { color: '#f5222d' } }),
    duration: 0,
  });
};

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import * as QuarterCloseActions from '../../actions/quarterClose/QuarterCloseActions';
import { QuarterCloseEnableTitoPage } from '../../components/quarterClose/QuarterCloseEnableTitoPage'
import { RootState } from '../../../common/store/ConfigureStore';


const mapStateToProps = (state: RootState) => {
    return {
      locationListSuccess: state.QuarterCloseReducer.locationListSimple,
      locationIdsWithoutSettings: state.QuarterCloseReducer.locationIdsWithoutSettings,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      actions: bindActionCreators(QuarterCloseActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(QuarterCloseEnableTitoPage)

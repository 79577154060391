
import { useState } from "react"
import { usePrevious } from "../../../common/hooks/usePrevious"
import { Row, Col, Radio, Modal, Button } from "antd"
import { ISelectedAndAvailableGame, IAddGameSelectionWindow, IGameSelectionWindow, IWindowGame } from '../../reducers/gameSelectionWindow/GameSelectionWindowReducer'
import { hashHistory } from "react-router";
import copyRichTextToClipboard from "../../../common/helpers/copyRichTextToClipboard";
const styles = require('../../../common/styles/GameSelectStyles.css')
const checkmarkImage = require("../../../images/Checkmark.png")
const buttonCheckout = require("../../../images/Button_GamesCheckout.png");
const buttonCheckoutDisabled = require("../../../images/Button_GamesCheckout_Disabled.png");
const missingIcon = require("../../../images/Missing_Icon.png")
const copyGames = require("../../../images/Button_CopyGames.png")
const backToWindows = require("../../../images/Button_BackWindows.png");


export interface IGameSelectionWindowSelectGamesPageState {
  modalVisible: boolean,
  window: IGameSelectionWindow | IAddGameSelectionWindow,
  selectedAndAvailableGames: ISelectedAndAvailableGame[],
  loadGameSelectionWindowGamesSuccess: boolean,
}

const GameSelectionWindowSelectGamesPage = (props) => {
  const [ window, setWindow ] = useState<IGameSelectionWindow | IAddGameSelectionWindow>(props.location.state.isNewWindow === true ? {...props.location.state.newWindow} : {...props.location.state.editGameSelectionWindow})
  const [isViewOnly, setIsViewOnly] = useState<boolean>(props.location.state.isViewOnly);
  const isViewOnlyLocations = (): string => {
    if (!isViewOnly) {return '';}
    if (window.allLocations) {return 'All locations';}
    const locations =  window.locations.map((item) => {return `${item.name}`});
    return locations.join(', ');
  }
  let viewOnlySelectedGames = window.selectedAndAvailableGames.filter(g => g.selected);
  const gameSelectionWindowId = props.location.state.gameSelectionWindowId;
  const isNewWindow = props.location.state.isNewWindow;

  const updateGame = (event, game: ISelectedAndAvailableGame) => {    
    if (!isViewOnly) {
    const {value, checked, className} = event.target;
    let updatedWindow: IGameSelectionWindow | IAddGameSelectionWindow = {...window}
    let selectedAndAvailableGameIndex = updatedWindow.selectedAndAvailableGames.findIndex(g => g.gameID === game.gameID)
    let windowGameIndex = updatedWindow.windowGames.findIndex(g => g.gameID === game.gameID)

    if (className === "ant-radio-button-input") {
      //SELECTED LEFT/NOT/RIGHT
      if (checked) {
        //if value is left or right, set any existing left or right to not
        if (value === "left") {
          updatedWindow.selectedAndAvailableGames.forEach((g) => {
            if (g.gameID !== game.gameID && g.featured === "left") {
              g.featured = "not"
            }
          })
          updatedWindow.windowGames.forEach((g) => {
            if (g.gameID !== game.gameID && g.featured === "left") {
              g.featured = "not"
            }
          })
        } else if (value === "right") {
          updatedWindow.selectedAndAvailableGames.forEach((g) => {
            if (g.gameID !== game.gameID && g.featured === "right") {
              g.featured = "not"
            }
          })
          updatedWindow.windowGames.forEach((g) => {
            if (g.gameID !== game.gameID && g.featured === "right") {
              g.featured = "not"
            }
          })
        }
      }
        updatedWindow.selectedAndAvailableGames[selectedAndAvailableGameIndex].selected = true
        updatedWindow.selectedAndAvailableGames[selectedAndAvailableGameIndex].featured = value
        updatedWindow.windowGames[windowGameIndex].featured = value

      //GAME SELECTED/DE-SELECTED
      } else if (className === "gameIcon") {        
        updatedWindow.selectedAndAvailableGames[selectedAndAvailableGameIndex].selected = !updatedWindow.selectedAndAvailableGames[selectedAndAvailableGameIndex].selected
        //set default featured value & update windowGames    
        if (updatedWindow.selectedAndAvailableGames[selectedAndAvailableGameIndex].selected && updatedWindow.selectedAndAvailableGames[selectedAndAvailableGameIndex].featured === "") {
          updatedWindow.selectedAndAvailableGames[selectedAndAvailableGameIndex].featured = "not"
          let addGame: IWindowGame = {
            id: null,
            gameSelectionWindowId: updatedWindow.selectedAndAvailableGames[selectedAndAvailableGameIndex].gameSelectionWindowId,
            gameID: updatedWindow.selectedAndAvailableGames[selectedAndAvailableGameIndex].gameID,
            featured: updatedWindow.selectedAndAvailableGames[selectedAndAvailableGameIndex].featured
          }
          updatedWindow.windowGames.push(addGame)
        } else if (!updatedWindow.selectedAndAvailableGames[selectedAndAvailableGameIndex].selected && updatedWindow.selectedAndAvailableGames[selectedAndAvailableGameIndex].featured !== "") {
          updatedWindow.selectedAndAvailableGames[selectedAndAvailableGameIndex].featured = ""
          updatedWindow.windowGames.splice(windowGameIndex, 1)
        }     
    }
    setWindow(updatedWindow)
    }
  }

  const checkoutGameSelections = () => {
    hashHistory.push({
      pathname: `/admin/gameSelectionWindow/gameSelectionWindowSelectGamesCheckout`,
      state: {window, gameSelectionWindowId, isNewWindow, isViewOnly}
    });
  }

  const showConfirm = () => {
    if (isViewOnly) {
      return false;
    }
    let show = window.selectedAndAvailableGames.filter(g => g.selected === true).length > 0 && 
      window.selectedAndAvailableGames.filter(g => g.featured === "left" || g.featured === "right").length === 2
      return show
  }

  const goBackToWindows = () => {
    hashHistory.push({
      pathname: `/admin/gameSelectionWindow`
    });
  }

  return (
      <div className="pg-container page-container gameSelectBackground">
          <Row type="flex" justify="center" align="middle">
              <Col>
                  <span style={{ textAlign: 'center' }} className="gameSelectHeader">SELECT GAMES FOR WINDOW</span>
              </Col>
          </Row>
          <Row type="flex" justify="center" align="middle" style={{paddingBottom: "20px"}}>
              <Col>
                  <span className="gameSelectText" style={{ paddingBottom: "20px" }}>SELECT 1 LEFT AND 1 RIGHT FEATURE GAME</span>
              </Col>
          </Row>
          <Row type="flex" justify="center" align="middle" style={{paddingBottom: "20px"}}>
              <Col hidden={window.gameSelectionWindowId === null || window.gameSelectionWindowId === undefined}>
                <span className="gameSelectInfo" style={{paddingBottom: "20px"}}>Game Selection Window ID: {window.gameSelectionWindowId} / Window Start Date: {window.windowStartDate.toString().substring(0, 10)}</span>
              </Col>
          </Row>
          <Row type="flex" justify="center" align="middle" style={{paddingBottom: "20px", paddingLeft: "60px"}}>
              <Col>
                  <div id="editOrNew" hidden={isViewOnly} style={{ display: "flex", flexWrap: "wrap" }}>
                      {window.selectedAndAvailableGames !== undefined }
                      {window.selectedAndAvailableGames.map((game) => (
                          <section 
                          hidden={window.selectedAndAvailableGames == undefined || window.selectedAndAvailableGames.length == 0}
                          onClick={(event) => updateGame(event, game)} 
                          style={{ position: "relative"}} 
                          key={game.gameID}>
                                                                                                      
                              <img className={"gameIcon"} src={game.iconUrl}/>
                            <>
                              {
                                game.selected === true &&
                                <img className="gameSelectCheckMark" src={checkmarkImage}  />
                              }
                            </>
                          <div 
                              hidden={window.selectedAndAvailableGames == undefined || window.selectedAndAvailableGames.length == 0}>
                              <Radio.Group className="radioStyle" disabled={!game.selected} value={game.featured} buttonStyle="solid"
                                  style={{border: "slategrey", borderBottomStyle: "double", color: "black", paddingRight:"20px"}}>
                                  <Radio.Button style={{backgroundColor: game.featured === "left" && game.selected === true ? "green" : game.featured === "" && game.selected === false ? "silver" : "white",                                  
                                      color: game.selected === true ? "black" : "gray"}} 
                                      value="left">left</Radio.Button>
                                  <Radio.Button style={{backgroundColor: game.featured === "not" && game.selected === true ? "yellow" : game.featured === "" && game.selected === false ? "silver" : "white", 
                                      color: game.selected === true ? "black" : "gray"}} 
                                      value="not">not</Radio.Button>
                                  <Radio.Button style={{backgroundColor: game.featured === "right" && game.selected === true ? "green": game.featured === "" && game.selected === false ? "silver" : "white", 
                                      color: game.selected === true ? "black" : "gray"}} 
                                      value="right">right</Radio.Button>                                
                              </Radio.Group> 
                          </div>
                          </section>                        
                      ))}
                  </div>
                  <div id="viewGamesOnly" hidden={!isViewOnly}>
                    <Row hidden={isViewOnly} type="flex" justify="center" align="middle" style={{paddingBottom: "20px"}}>
                        <Col hidden={window.gameSelectionWindowId === null || window.gameSelectionWindowId === undefined}>
                          <span className="gameSelectInfo" style={{paddingBottom: "20px"}}>Game Selection Window ID: {window.gameSelectionWindowId} / Window Start Date: {window.windowStartDate.toString().substring(0, 10)}</span>
                        </Col>
                    </Row>
                    <Row type="flex" justify="center" align="middle" style={{paddingBottom: "20px", paddingLeft: "80px", paddingRight: "80px"}}>
                        <Col>
                          <span className="gameSelectInfo" style={{paddingBottom: "20px"}}>Locations: {isViewOnlyLocations()}</span>
                        </Col>
                    </Row>
                    <div hidden={!isViewOnly} style={{ display: "flex", justifyItems:"center", flexWrap: "wrap" }}>
                        {viewOnlySelectedGames !== undefined }
                        {viewOnlySelectedGames.map((game) => (
                            <section 
                            hidden={viewOnlySelectedGames == undefined || viewOnlySelectedGames.length == 0}
                            onClick={(event) => updateGame(event, game)} 
                            style={{ position: "relative"}} 
                            key={game.gameID}>
                                                                                                        
                                <img width={180} className={"gameIcon"} src={game.iconUrl}/>
                            <div>
                                <Radio.Group className="radioStyle" disabled={!game.selected} value={game.featured} buttonStyle="solid"
                                    style={{border: "slategrey", borderBottomStyle: "double", color: "black", paddingRight:"20px"}}>
                                    <button style={{backgroundColor: game.featured === "left" && game.selected === true ? "green" : game.featured === "" && game.selected === false ? "silver" : "white",                                  
                                        color: game.selected === true ? "black" : "gray"}}>
                                    <Radio.Button style={{backgroundColor: game.featured === "left" && game.selected === true ? "green" : game.featured === "" && game.selected === false ? "silver" : "white",                                  
                                        color: game.selected === true ? "black" : "gray"}} 
                                        value="left">left</Radio.Button>
                                    </button>
                                    <button style={{backgroundColor: game.featured === "not" && game.selected === true ? "yellow" : game.featured === "" && game.selected === false ? "silver" : "white", 
                                        color: game.selected === true ? "black" : "gray"}}>
                                    <Radio.Button style={{backgroundColor: game.featured === "not" && game.selected === true ? "yellow" : game.featured === "" && game.selected === false ? "silver" : "white", 
                                        color: game.selected === true ? "black" : "gray"}} 
                                        value="not">not</Radio.Button>
                                    </button>
                                    <button style={{backgroundColor: game.featured === "right" && game.selected === true ? "green": game.featured === "" && game.selected === false ? "silver" : "white", 
                                        color: game.selected === true ? "black" : "gray"}}>
                                    <Radio.Button style={{backgroundColor: game.featured === "right" && game.selected === true ? "green": game.featured === "" && game.selected === false ? "silver" : "white", 
                                        color: game.selected === true ? "black" : "gray"}} 
                                        value="right">right</Radio.Button>
                                    </button>                              
                                </Radio.Group>
                            </div>
                            </section>                        
                        ))}
                    </div>
                  </div>
              </Col>
            </Row>
          <Row justify="center" align="middle" style={{paddingBottom: "20px"}}>
              <Col> 
                  {
                  <>
                  <img className="navImageButton" src={backToWindows} onClick={() => goBackToWindows()} style={{paddingRight: "10px"}} alt="Back To Windows"/>
                  </>
                  }                 
                  {!isViewOnly && showConfirm()
                    &&
                  <>
                  <img className="navImageButton floatRight" src={buttonCheckout} onClick={checkoutGameSelections} />
                  </>
                  } 
                  {!isViewOnly && !showConfirm()
                    &&
                    <img className="navImageButton floatRight" src={buttonCheckoutDisabled} alt="Checkout" />
                  }
                  {isViewOnly &&
                  <>
                  <img className="navImageButton floatRight" src={copyGames} alt="Copy Games" onClick={() => copyRichTextToClipboard(document.getElementById('viewGamesOnly').innerHTML)}/>
                  </>
                  }              
              </Col>
          </Row>
      </div>
  )
}
export default GameSelectionWindowSelectGamesPage;
import * as React from "react";
import AppUtilityService from "../../../common/services/AppUtilityService";
import { Button, Card, Icon, Layout, Spin } from 'antd';
import { DataGridComponent } from '../../../common/components/table/DataGridComponent';
import AppConstants from '../../../common/AppConstants';
import { downloadSaturdaySales, loadSaturdaySales } from '../../actions/reports/ReportsActions';
import LocationReportsFilterContainer from '../../containers/reports/locationStatisticsReports/LocationReportsFilterContainer';
import { filters } from '../reports/locationStatisticsReports/commonFiltersConfiguration';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { updateBreadcrumbs } from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import { Fetched, LoadState } from '../../../common/store/fetched';
import { ISaturdaySalesDTO, ISaturdaySalesParams } from "../../reducers/reports/SaturdayReportTypes";

type ChildItem = Omit<ISaturdaySalesDTO, 'dates'> | { [date: string]: number | undefined };

export const SaturdaySalesPage = () => {
  const dispatch = useAppDispatch();
  const saturdaySales = useAppSelector(state => state.ReportsReducer.saturdaySales);
  const saturdaySalesDownload = useAppSelector(state => state.ReportsReducer.saturdaySalesDownload);
  const [filterParams, setFilterParams] = useState<ISaturdaySalesParams | null>(null);
  const [expandedJurisdiction, setExpandedJurisdiction] = useState(null);

  const isLoading = saturdaySales.loadState === LoadState.InProgress;

  useEffect(() => {
    let breadcrumbsList = [
      { name: "Reports", link: '/reports' },
      { name: "Saturday Sales", link: null },
    ];
    var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList, null);
    dispatch(updateBreadcrumbs(breadcrumbs));
  });

  const handleError = <T,>(fetched: Fetched<T>) => {
    if (fetched.loadState === LoadState.Failed) AppUtilityService.handleApiError(fetched.failureReason.error);
  };

  useEffect(() => handleError(saturdaySalesDownload), [saturdaySalesDownload]);
  useEffect(() => handleError(saturdaySales), [saturdaySales]);

  const applyFilters = ({ filterValues: { dateRange, ...filterValues }, ...apiParams }) => {
    const params = {
      ...apiParams,
      ...filterValues,
      dateFrom: AppUtilityService.formatAPIDateFilter(dateRange?.[0]),
      dateTo: AppUtilityService.formatAPIDateFilter(dateRange?.[1]),
    };
    setFilterParams(params);
    dispatch(loadSaturdaySales(params));
  }

  const downloadExcel = () => dispatch(downloadSaturdaySales(filterParams));

  // process data; input must be sorted by jurisdiction
  const { jurisdictions, columns } = useMemo(() => {
    const jurisdictions: { jurisdiction: string, items: ChildItem[] }[] = [];
    const data = saturdaySales.data?.data;
    const dates = new Set<string>();
    if (data) {
      let currentJurisdiction = null;
      for (const row of data) {
        if (currentJurisdiction == null || currentJurisdiction.jurisdiction != row.jurisdiction) {
          currentJurisdiction = {
            jurisdiction: row.jurisdiction,
            items: [],
          };
          jurisdictions.push(currentJurisdiction);
        }
        const item: ChildItem = {
          locationId: row.locationId,
          name: row.name,
          jurisdiction: row.jurisdiction,
          city: row.city,
          salesRepresentative: row.salesRepresentative
        };
        row.dates.forEach(({d, v}) => {
          dates.add(d);
          item[d] = v;
        });
        currentJurisdiction.items.push(item);
      }
    }
    const dateColumns: (typeof AppConstants['saturdayReportColumns']['childColumns'][0])[] = [];
    dates.forEach(date => dateColumns.push({
      dataField: date,
      caption: AppUtilityService.formatDate(date),
      dataType: 'number',
      format: '$#,##0.00',
      visible: true,
    }));
    dateColumns.sort((a, b) => a.dataField.localeCompare(b.dataField));

    setExpandedJurisdiction(null);

    return {
      jurisdictions,
      columns: [...AppConstants.saturdayReportColumns.childColumns, ...dateColumns],
    };
  }, [saturdaySales.data]);

  return (
    <>
    <Card
      style={{ paddingTop: "12px" }}
      className="reports-filter-card content-card no-header-border"
      bordered={false}
      title="Saturday Sales"
      extra={
        <Button
          type="primary"
          loading={saturdaySalesDownload.loadState === LoadState.InProgress}
          onClick={downloadExcel}
        >
          <Icon type="download" style={{ fontSize: "16px" }}/> Download as Excel
        </Button>
      }
    >
      <LocationReportsFilterContainer
        disableCharityFilter={true}
        disableDistributorFilter={true}
        applyFiltersCallback={applyFilters}
        filters={filters}
      />
    </Card>
    <div className="pg-reports-container">
      <Layout>
        <div className="container" style={{ background: '#fff' }}>
          <Spin spinning={isLoading}>
            <div style={{ padding: "24px" }}>
              <div>
                <DataGridComponent
                  toolbarContent={<h2>Net Play on Saturdays</h2>}
                  dataSource={jurisdictions}
                  childDataSource={expandedJurisdiction?.items}
                  dataSrcColumns={AppConstants.saturdayReportColumns.columns}
                  childDataSrcColumns={columns}
                  isLoading={isLoading}
                  onRowExpandingCallback={row => setExpandedJurisdiction(row.key)}
                  summaryAvgColumns={null}
                  summarySumColumns={null}
                  showPageSizeSelector={false}
                  storeIdentifier="SaturdayReport"
                />
              </div>
            </div>
          </Spin>
        </div>
      </Layout>
    </div>
  </>
  )
}

import { AppDispatch } from '../../../common/store/ConfigureStore';
import { fetchDataWithActionCreator } from '../../../common/store/fetched';
import { otpSetupUpdated } from './slice-actions';
import { processFailureReason } from '../../../common/store/failure';
import { setUpTOTP } from 'aws-amplify/auth';
import SessionService from '../../../common/services/SessionService';

const getTotopConfig = () => async (dispatch: AppDispatch) =>
  await fetchDataWithActionCreator(
    otpSetupUpdated,
    dispatch,
    async () => {
      const config = await setUpTOTP();
      const url = config.getSetupUri('Powertab');
      url.href = url.href.replace(`%22${SessionService.getUserId()}%22`, SessionService.UserName);
      return url;
    },
    processFailureReason,
    false
  );

export default getTotopConfig;

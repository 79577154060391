import { createApiCaller } from '../../../common/services/ApiService';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { fetchDataWithActionCreator, LoadState } from '../../../common/store/fetched';
import { IUpdateInvoiceStatusContext, IUpdateInvoiceStatusRequest, InvoiceStatus, IInvoice } from '../../reducers/billing/billing.models';
import { updateStatusOperationUpdated } from './slice-actions';
import { processFailureReason } from '../../../common/store/failure';
import { message, Modal } from 'antd';
import { getInvoice } from './getInvoice';

export const updateInvoiceStatus = (invoice: IInvoice, targetStatus: InvoiceStatus) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { invoiceId, invoiceStatusId: currentStatus, hasOpenValidationIssues } = invoice;
    if (currentStatus === InvoiceStatus.PendingReview && hasOpenValidationIssues) {
      Modal.warning({
        title: 'The invoice has open validation issues',
        content: 'Before approving the invoice, please mark all validation issues as resolved.'
      });
      return;
    }

    const request: IUpdateInvoiceStatusRequest = { currentStatus: invoice.invoiceStatusId, targetStatus };
    const context: IUpdateInvoiceStatusContext = { invoiceId, request };
    await fetchDataWithActionCreator(
      updateStatusOperationUpdated,
      dispatch,
      createApiCaller(api => api.updateData<void>(
        `/invoices/${invoiceId.toFixed(0)}/status`,
        undefined,
        request)),
      processFailureReason,
      false,
      context,
    );

    const operation = getState().BillingReducer.updateStatusOperation;
    const targetStatusName = getStatusDisplayName(request.targetStatus);
    if (operation.loadState === LoadState.Failed) {
      if (operation.failureReason.statusCode === 409) {
        Modal.warning({
          title: 'Change conflict',
          content: 'Invoice state has been modified in the meanwhile. We’ll refresh the invoice data and please retry the action.'
        });
        dispatch(getInvoice(invoiceId, true));
      } else if (operation.failureReason.statusCode === 400) {
        const errorCode = operation.failureReason.response?.data?.errorCode;
        if (errorCode === 'HasOpenValidationIssues') {
          Modal.warning({
            title: 'The invoice has open validation issues',
            content: 'Before approving the invoice, please mark all validation issues as resolved.'
          });
          dispatch(getInvoice(invoiceId, true));
        } else if (errorCode === 'EarlierInvoiceInPendingState') {
          Modal.warning({
            title: 'This location has an earlier pending invoice',
            content: 'Before approving the invoice, please make sure that all earlier invoices are approved or sent.'
          });
        } else if (errorCode === 'LaterInvoiceInApprovedState') {
          Modal.warning({
            title: 'This location has a later approved (or sent) invoice',
            content: 'Before un-approving the invoice, please make sure that all later invoices are in pending status.'
          });
        } else {
          message.error(
            `There was a problem with changing invoice status to ${targetStatusName}.
           Please try again or refresh the page.`,
            5,
          );
        }
      }
    } else if (operation.loadState === LoadState.Success) {
      message.success(`The invoice status was changed to ${targetStatusName}.`);
      dispatch(getInvoice(invoiceId, true));
    }
  };
}

function getStatusDisplayName(status: InvoiceStatus) {
  switch (status) {
    case InvoiceStatus.PendingReview: return 'pending review';
    case InvoiceStatus.Approved: return 'approved';
    case InvoiceStatus.Sent: return 'sent';
    default: return '';
  }
}
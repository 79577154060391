import { addSummaryFieldsToColumnProps } from '../../../common/helpers/dataGridComponentHelpers';
import appUtilityService from '../../../common/services/AppUtilityService';
import { IColumnProps } from 'devextreme-react/data-grid';

const byDateColumns: IColumnProps[] = [
  { dataField: 'siteName', caption: 'Site', dataType: 'string', width: '15%' },
  { dataField: 'soldTicketCount', caption: 'Sold Ticket Count', format: '#,##0', dataType: 'number' },
  { dataField: 'grossRevenue', caption: 'Gross Revenue', format: '$#,##0.00', dataType: 'number' },
  { dataField: 'payout', caption: 'Prizes Awarded ', format: '$#,##0.00', dataType: 'number', visible: false, showInColumnChooser: true },
  { dataField: 'ticketNetRevenue', caption: 'Ticket Net Revenue', format: '$#,##0.00', dataType: 'number' },
  { dataField: 'progressivePayout', caption: 'Progressive Payout', format: '$#,##0.00', dataType: 'number' },
  { dataField: 'currentNetRevenue', caption: 'Current Net Revenue', format: '$#,##0.00', dataType: 'number' },
  { dataField: 'theoreticalNetRevenue', caption: 'Theoretical Net Revenue', format: '$#,##0.00', dataType: 'number' },
];

export const columns = byDateColumns.map(addSummaryFieldsToColumnProps);

export const childColumns = [
  { dataField: 'organizationName', caption: 'Organization', dataType: 'string', width: '10%' },
  { dataField: 'date', caption: 'Date', format: appUtilityService.formatDevexColumnDate, dataType: 'date', width: '6%' },
  ...byDateColumns.slice(1),
].map(addSummaryFieldsToColumnProps);

import ApiService from "../../../common/services/ApiService";
import {
    ADD_CHARITY_LOCATION_APIKEY_FAILURE,
    ADD_CHARITY_LOCATION_APIKEY_RESPONSE,
    ADD_CHARITY_LOCATION_APIKEY_REQUEST,
    REVOKE_CHARITY_LOCATION_APIKEY_REQUEST,
    REVOKE_CHARITY_LOCATION_APIKEY_RESPONSE,
    REVOKE_CHARITY_LOCATION_APIKEY_FAILURE,
} from "../../reducers/charities/CharityReducer";

export function updateCharityListFilters(params) {
  return {
    type: 'CHARITY_LIST_FILTER_UPDATE',
    payload: params
  }
}
// ================================================================================
// Contacts List
// ================================================================================
export function loadCharityListData(params) {
  return function (dispatch) {
    dispatch({ type: `LOAD_CHARITY_LIST_DATA_REQUEST` });
    return ApiService.getData("/charities", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_CHARITY_LIST_DATA_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_CHARITY_LIST_DATA_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
// Charity Details
// ================================================================================
export function addNewCharity(formData) {
  return function (dispatch) {
    dispatch({ type: `ADD_NEW_CHARITY_REQUEST` });
    return ApiService.postData("/charities", formData)
      .then((successResult) => {
        dispatch({ type: `ADD_NEW_CHARITY_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `ADD_NEW_CHARITY_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================

export function addApiKey(data : ICharityLocationAPIKeyRequest) {
    return function (dispatch) {
        dispatch({ type: ADD_CHARITY_LOCATION_APIKEY_REQUEST });
        return ApiService.postData(`/keys/location/${data.locationId}/charity/${data.charityId}`)
            .then((successResult) => {
                dispatch({ type: ADD_CHARITY_LOCATION_APIKEY_RESPONSE, payload: { ...successResult.data, locationId: data.locationId } });
            })
            .catch((errorResult) => {
                dispatch({ type: ADD_CHARITY_LOCATION_APIKEY_FAILURE, payload: errorResult });
            });
    }
}

export function revokeApiKey(data: ICharityLocationAPIKeyRequest) {
    return function (dispatch) {
        dispatch({ type: REVOKE_CHARITY_LOCATION_APIKEY_REQUEST });
        return ApiService.postData(`/keys/revoke/location/${data.locationId}/charity/${data.charityId}`)
            .then((successResult) => {
                dispatch({ type: REVOKE_CHARITY_LOCATION_APIKEY_RESPONSE, payload: data });
            })
            .catch((errorResult) => {
                dispatch({ type: REVOKE_CHARITY_LOCATION_APIKEY_FAILURE, payload: errorResult });
            });
    }
}

export function loadCharityDetails(charityId) {
  return function (dispatch) {
    dispatch({ type: `LOAD_CHARITY_DETAILS_REQUEST` });
    return ApiService.getData("/charities/" + charityId)
      .then((successResult) => {
        dispatch({ type: `LOAD_CHARITY_DETAILS_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_CHARITY_DETAILS_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================

export function updateCharityDetails(charityId, formData) {
  return function (dispatch) {
    dispatch({ type: `UPDATE_CHARITY_DETAILS_REQUEST` });
    return ApiService.updateData("/charities/" + charityId, null, formData)
      .then((successResult) => {
        dispatch({ type: `UPDATE_CHARITY_DETAILS_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `UPDATE_CHARITY_DETAILS_FAILURE`, payload: errorResult });
      });
  }
}

/**
 * Delete charity
 */
export function deleteCharityDetails(charityId) {
  return function (dispatch) {
    dispatch({ type: `DELETE_CHARITY_REQUEST` });
    return ApiService.deleteData("/charities/" + charityId, null, null)
      .then((successResult) => {
        dispatch({ type: `DELETE_CHARITY_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `DELETE_CHARITY_FAILURE`, payload: errorResult });
      });
  }
}
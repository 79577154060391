import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as UserActions from '../../actions/user/UserActions';
import UsersLinkedZonesComponent from '../../components/user/UsersLinkedZonesComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    assignZoneToUsersSuccess: state.UserReducer.assignZoneToUsersSuccess,
    assignZoneToUsersError: state.UserReducer.assignZoneToUsersError,

    unassignZoneFromUsersSuccess: state.UserReducer.unassignZoneFromUsersSuccess,
    unassignZoneFromUsersError: state.UserReducer.unassignZoneFromUsersError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(UserActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersLinkedZonesComponent)

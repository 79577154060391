import * as React from "react";
import { isEqual } from "lodash";
import AppUtilityService from "../../../common/services/AppUtilityService";
import WebAccessService from "../../../common/services/WebAccessService";
import SaveLocationContactModalContainer from "../../containers/locations/SaveLocationContactModalContainer";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";
import { Link } from "react-router";

import { Card, List, Tag, Modal, Avatar } from 'antd';
const confirm = Modal.confirm;

export class LocationLinkedContactsComponent extends React.Component<ILocationContactsProps, ILocationContactsState> {

  constructor(props: ILocationContactsProps) {
    super(props);

    this.state = {
      locationDetails: null,
      isLoading: true,
      showSaveContactModal: false,
      contactDetails: null,
    }
  }

  componentWillReceiveProps(nextProps: ILocationContactsProps) {

    if (nextProps.loadBasicDetailsSuccess && !isEqual(this.props.loadBasicDetailsSuccess, nextProps.loadBasicDetailsSuccess)) {
      var responseData: LocationDetail = nextProps.loadBasicDetailsSuccess;
      this.setState({ locationDetails: responseData, isLoading: false })
    }

    if (nextProps.deleteLocationContactSuccess && !isEqual(this.props.deleteLocationContactSuccess, nextProps.deleteLocationContactSuccess)) {
      AppUtilityService.showMessageToast("Selected contact has been removed", "success");
      let locationDetails = { ...this.state.locationDetails };
      locationDetails.contacts = nextProps.deleteLocationContactSuccess;
      this.setState({ locationDetails, isLoading: false })
    }

    if (nextProps.deleteLocationContactError && !isEqual(this.props.deleteLocationContactError, nextProps.deleteLocationContactError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.deleteLocationContactError);
    }
  }
  render() {
    var extraActions = WebAccessService.hasPermissionToAccess("Location", "AddContact") &&
      <span className="link-primary" onClick={this.addContact}><i className="icon-add" /></span>;

    return (
      <Card className="content-card no-body-padding" bordered={false}
        title="Contacts" extra={extraActions}>
        <List
          loading={this.state.isLoading}
          className="multiline-list"
          itemLayout="horizontal"
          dataSource={this.state.locationDetails ? this.state.locationDetails.contacts : []}
          locale={
            {
              emptyText: <EmptyStateComponent title="No Linked Contacts" />
            }
          }
          renderItem={(item: Contacts) => (
            <List.Item
              actions={this.generateActions(item)}
            >
              <List.Item.Meta
                avatar={AppUtilityService.getContactAvatar(item.image)}
                title={<Link to={"/contacts/" + item.id} className="link-primary">{item.name}</Link>}
                description={
                  <div>
                    <ul className="mini-card-details">
                      {
                        item.email &&
                        <li>{item.email}</li>
                      }
                      {
                        item.phone &&
                        <li>{item.phone}</li>
                      }
                      {
                        (item.address1 || item.address2 || item.city || item.zipcode || item.addressState) &&
                        <li>
                          {
                            AppUtilityService.displayStringFromArray([item.address1, item.address2, item.city, item.addressState, item.zipcode])
                          }
                        </li>
                      }
                    </ul>
                  </div>
                }
              />
            </List.Item>
          )}
        />
        {
          this.state.showSaveContactModal &&
          <SaveLocationContactModalContainer
            locationId={this.state.locationDetails.id}
            contactDetails={this.state.contactDetails}
            cancelSaveContactCallback={this.handleCancelSaveContact}
          />
        }
      </Card>
    )
  }

  generateActions = (item: Contacts): Array<any> => {
    var actions = [];
    if (WebAccessService.hasPermissionToAccess("Location", "DeleteContact")) {
      actions.push(<i className="icon-remove link-danger"
        onClick={() => this.deleteContact(item.id)}
      />)
    }
    if (WebAccessService.hasPermissionToAccess("Location", "EditContact")) {
      actions.push(<i className="icon-edit link-primary"
        onClick={() => this.editContact(item)}
      />)
    }
    return actions;
  }

  addContact = () => {
    this.setState({ showSaveContactModal: true, contactDetails: null });
  }

  editContact = (item: Contacts) => {
    this.setState({ showSaveContactModal: true, contactDetails: item })
  }

  handleCancelSaveContact = (contacts) => {
    var locationDetails = { ...this.state.locationDetails };
    if (contacts) {
      locationDetails.contacts = contacts;
    }
    this.setState({ showSaveContactModal: false, contactDetails: null, locationDetails })
  }

  deleteContact = (id) => {
    confirm({
      title: 'Confirm dialog',
      content: 'Are you sure you want to remove the selected contact linked to this location?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        var params = {
          locationId: this.state.locationDetails.id,
          contactId: id
        }
        this.setState({ isLoading: true })
        this.props.actions.deleteLocationLinkedContact(params);
      },
    });
  }

}

import { FailureData } from './failure';
import { Action, Dispatch } from 'redux';

export enum LoadState {
  NotRequested = 'NotRequested',
  InProgress = 'InProgress',
  Success = 'Success',
  Failed = 'Failed'
}

/**
 * Stores the result and the state (not started, in progress, successful, complete) of an async operation.
 * To be used in Redux state.
 * See docs/architecture/redux/fetched-with-thunk.md and docs/architecture/redux/fetched-with-slices.md
 * for more details.
 */
export type Fetched<TData, TFailureReason = FailureData, TContext = void>
  = IFetchedNotRequested
  | IFetchedInProgress<TData, TContext>
  | IFetchedSuccess<TData, TContext>
  | IFetchedFailed<TData, TFailureReason, TContext>;

export interface IFetchedNotRequested {
  loadState: LoadState.NotRequested;
  data?: undefined;
  failureReason?: undefined;
  lastUpdate: Date;
}

export interface IFetchedInProgress<TData, TContext = void> {
  loadState: LoadState.InProgress;
  data?: TData;
  failureReason?: undefined;
  lastUpdate: Date;
  context?: TContext;
}

export interface IFetchedSuccess<TData, TContext = void> {
  loadState: LoadState.Success;
  data: TData;
  failureReason?: undefined;
  lastUpdate: Date;
  context?: TContext;
}

export interface IFetchedFailed<TData, TFailureReason, TContext = void> {
  loadState: LoadState.Failed;
  data?: TData;
  failureReason: TFailureReason;
  lastUpdate: Date;
  context?: TContext;
}

export interface IFetchedUpdatedPayload<TData, TFailureReason = FailureData, TContext = void> {
  fetched: Fetched<TData, TFailureReason, TContext>;
  previousLastUpdate?: Date;
  silentReload: boolean;
  context?: TContext;
}

export type IFetchedUpdated<TActionType, TData, TFailureReason = FailureData, TContext = void> = {
  type: TActionType;
} & IFetchedUpdatedPayload<TData, TFailureReason, TContext>;

export type ExtractFetchedDataType<T> =
  NonNullable<T extends Fetched<infer TInferred, unknown, unknown> ? TInferred : never>;

export type ExtractFetchedFailureReasonType<T> =
  NonNullable<T extends Fetched<unknown, infer TInferred, unknown> ? TInferred : never>;

type UnknownToVoid<T> = unknown extends T ? void : T;

export type ExtractFetchedContextType<T> =
  T extends Fetched<unknown, unknown, infer TInferred> ? UnknownToVoid<TInferred> : never;

export const createFetchedInProgress = <TData>(): IFetchedInProgress<TData> => ({
  loadState: LoadState.InProgress,
  data: undefined,
  failureReason: undefined,
  lastUpdate: new Date()
});

export const createFetchedFailed = <TData, TFailureReason = FailureData>(failureReason: TFailureReason): IFetchedFailed<TData, TFailureReason> => ({
  loadState: LoadState.Failed,
  data: undefined,
  failureReason,
  lastUpdate: new Date()
});

export const createFetched = (): IFetchedNotRequested => ({
  loadState: LoadState.NotRequested,
  lastUpdate: new Date(),
});

export const createFetchedSuccess = <TData>(data: TData): IFetchedSuccess<TData> => ({
  loadState: LoadState.Success,
  data,
  lastUpdate: new Date(),
});

/**
 * Wraps an async function ({@link loadData}) and tracks progress/result with Fetched flow.
 * To be used with thunk and not with slices. For slices see {@link fetchDataWithActionCreator}.
 * See docs/architecture/redux/fetched-with-thunk.md for more details.
 * @param actionType Type of action to dispatch.
 * @param dispatch Dispatcher.
 * @param loadData Async function running the operation.
 * @param processFailure Function to process error from operation
 * @param silentReload Keep the previous data while new data is being loaded.
 * @param processFailure Function processing error from {@link operation}.
 * @returns Promise with data returned by function if call was successfull or undefined.
 */
export async function fetchData<TActionType, TData, TFailureReason, TContext = void>(
  actionType: TActionType,
  dispatch: Dispatch<IFetchedUpdated<TActionType, TData, TFailureReason, TContext>>,
  loadData: () => Promise<TData> | TData,
  processFailure: (error: unknown) => Promise<TFailureReason> | TFailureReason,
  silentReload = true,
  context?: TContext,
) {
  return await fetchDataWithActionCreator(
    payload => ({ type: actionType, ...payload }),
    dispatch,
    loadData,
    processFailure,
    silentReload,
    context,
  );
}

/**
 * Wraps an async function ({@link loadData}) and tracks progress/result with Fetched flow.
 * See docs/architecture/redux/fetched-with-slices.md for more details.
 * @param actionCreator Creates a redux action update appropriate fetched field.
 * @param dispatch Dispatcher.
 * @param loadData Async function running the operation.
 * @param processFailure Function to process error from operation
 * @param silentReload Keep the previous data while new data is being loaded.
 * @param processFailure Function processing error from {@link operation}.
 * @returns Promise with data returned by function if call was successfull or undefined.
 */
export async function fetchDataWithActionCreator<TPayload extends Action, TData, TFailureReason, TContext = void>(
  actionCreator: (updated: IFetchedUpdatedPayload<TData, TFailureReason, TContext>) => TPayload,
  dispatch: Dispatch<TPayload>,
  loadData: () => Promise<TData> | TData,
  processFailure: (error: unknown) => Promise<TFailureReason> | TFailureReason,
  silentReload = true,
  context?: TContext,
) {

  const previousLastUpdate = new Date();
  dispatch(actionCreator({
    fetched: { loadState: LoadState.InProgress, lastUpdate: previousLastUpdate },
    silentReload,
    context,
  }));

  try {

    const data = await Promise.resolve(loadData());

    dispatch(actionCreator({
      fetched: { loadState: LoadState.Success, data, lastUpdate: new Date() },
      previousLastUpdate,
      silentReload,
      context,
    }));

    return data;

  } catch (error) {

    const failureReason = await Promise.resolve(processFailure(error));

    dispatch(actionCreator({
      fetched: { loadState: LoadState.Failed, failureReason, lastUpdate: new Date() },
      previousLastUpdate,
      silentReload,
      context,
    }));

    return undefined;
  }
}

export function resetFetched<TActionType, TData = void, TFailureReason = FailureData, TContext = void>(
  actionType: TActionType,
  dispatch: Dispatch<IFetchedUpdated<TActionType, TData, TFailureReason, TContext>>,
) {
  dispatch({
    type: actionType,
    fetched: createFetched(),
    silentReload: false,
  });
}

export function updateFetched<TData, TFailureReason, TContext>(
  current: Fetched<TData, TFailureReason, TContext>,
  action: IFetchedUpdatedPayload<TData, TFailureReason, TContext>
): Fetched<TData, TFailureReason, TContext> {

  if (action.previousLastUpdate != null && current.lastUpdate.getTime() !== action.previousLastUpdate.getTime()) {
    return current;
  }

  const result = { ...action.fetched, context: action.context };
  if (action.silentReload
    && (result.loadState === LoadState.InProgress || result.loadState === LoadState.Failed)) {
    result.data = current.data !== undefined ? current.data : result.data;
  }
  return result;
}

import { LocationReportsFilter } from './LocationReportsFilterComponent';
import DateRangeFilter from '../../../../common/components/filters/DateRangeFilter';
import moment from 'moment';

export const filters: LocationReportsFilter[] = [
  {
    name: 'dateRange',
    component: DateRangeFilter,
    defaultValue: [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
  },
];

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Fetched, createFetched } from '../../../common/store/fetched';
import {
  ILocationSettings,
} from './LocationSettings.models';
import { createFetchedSetter } from '../../../common/store/fetched.slices';
import { FailureData } from '../../../common/store/failure';

export interface ILocationSettingsState {
  locationSettingsData: Fetched<ILocationSettings>;
}

const initialState: ILocationSettingsState = {
  locationSettingsData: createFetched()
}

export const locationSettingsSlice = createSlice({
  name: 'locationSettings',
  initialState,
  reducers: {
    locationSettingsUpdated: createFetchedSetter('locationSettingsData')
  }
});

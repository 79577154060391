
import * as React from "react";
import { debounce, isEqual, isEmpty } from "lodash";
import { Link } from "react-router";
import AppUtilityService from "../../../common/services/AppUtilityService";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";

import { Table } from "antd";

export class CharityListTableComponent extends React.Component<ICharityListTableProps, ICharityListTableState> {

  constructor(props: ICharityListTableProps) {
    super(props);
    this.state = {
      pagination: {
        page: 1,
        pageSize: 30,
        total: 5
      },
      sortInfo: null,
      searchText: null,
      isLoading: true,
      charityList: [],
      zoneId: null
    }
    this.loadCharityList = debounce(this.loadCharityList, 500);
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }

    if (this.props.charityListFilters) {
      this.handlePresetFilters(this.props.charityListFilters, true)
    } else {
      this.loadCharityList();
    }
  }

  componentWillReceiveProps(nextProps: ICharityListTableProps) {
    if (nextProps.loadCharityListSuccess && !isEqual(this.props.loadCharityListSuccess, nextProps.loadCharityListSuccess)) {
      let pagination = { ...this.state.pagination };
      var responseData: any = nextProps.loadCharityListSuccess;
      pagination.total = responseData.total;
      this.props.getTotalCharitiesCallback(pagination.total);
      this.setState({ charityList: responseData.charities, pagination, isLoading: false })
    }

    if (nextProps.loadCharityListError && !isEqual(this.props.loadCharityListError, nextProps.loadCharityListError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.loadCharityListError);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }


  render() {
    const { isLoading, pagination } = this.state;

    const tableColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        sortOrder: AppUtilityService.getTableColumnSortOrder(this.state.sortInfo, 'name'),
        width: "33.33%",
        render: (text, record) => {
          return (
            <div>
              <Link to={"/charities/" + record.id}>{text}</Link>
              <div className="font-12 t-grey-darker">{record.email}</div>
            </div>
          )
        }
      }, {
        title: 'License no',
        dataIndex: 'licenseNumber',
        key: 'licenseNumber',
        sorter: true,
        sortOrder: AppUtilityService.getTableColumnSortOrder(this.state.sortInfo, 'licenseNumber'),
        className: "hidden-sm",
        width: "33.33%",
      }, {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        sorter: true,
        sortOrder: AppUtilityService.getTableColumnSortOrder(this.state.sortInfo, 'address'),
        className: "hidden-sm",
        width: "33.33%",
        render: (text, record) => {
          return (
            <div>
              {
                AppUtilityService.displayStringFromArray([record.address1, record.address2, record.city, record.addressState, record.zipcode])
              }
            </div>
          )
        }
      }
    ];
    return (
      <Table
        rowKey={(record, index) => { return (record.id).toString() }}
        loading={isLoading}
        dataSource={this.state.charityList}
        columns={tableColumns}
        pagination={{ current: this.state.pagination.page, pageSize: pagination.pageSize, total: pagination.total }}
        onChange={this.handleTableChange}
        locale={
          {
            emptyText: <EmptyStateComponent title="No Charities" />
          }
        }
      />
    )
  }


  handleSearchChange = (value) => {
    let pagination = { ...this.state.pagination };
    pagination.page = 1;
    this.setState({ pagination, searchText: value }, () => {
      this.loadCharityList();
    })
  }

  handleZoneChange = (value) => {
    let pagination = { ...this.state.pagination };
    pagination.page = 1;
    this.setState({ pagination, zoneId: value }, () => {
      this.loadCharityList();
    })
  }

  /**
   * Triggered when table pagination, sortInfo or filter changes
   */
  handleTableChange = (updatedPagination, filters, sorter) => {
    let pagination = { ...this.state.pagination };
    let sortInfo = { ...this.state.sortInfo };
    pagination.page = updatedPagination.current
    if (sorter && !isEmpty(sorter)) {
      sortInfo = {
        sortDir: sorter.order,
        propertyName: sorter.field
      }
    } else {
      sortInfo = null;
    }
    this.setState({ pagination, sortInfo }, () => {
      this.loadCharityList()
    })
  }

  loadCharityList = () => {
    this.setState({ isLoading: true });
    let { pagination, sortInfo, searchText, zoneId } = this.state;
    let params = {
      page: pagination.page,
      pageSize: pagination.pageSize,
      sortDir: sortInfo && sortInfo.sortDir || null,
      propertyName: sortInfo && sortInfo.propertyName || null,
      searchText: searchText,
      zoneId: zoneId
    }
    this.props.actions.loadCharityListData(params);
    this.props.actions.updateCharityListFilters(params);
  }

  handlePresetFilters = (filterData, isFirstMount) => {
    let pagination = { ...this.state.pagination };
    pagination.page = filterData.page;
    pagination.pageSize = filterData.pageSize;
    var sortInfo = null
    if (filterData.sortDir && filterData.propertyName) {
      sortInfo = {
        sortDir: filterData.sortDir,
        propertyName: filterData.propertyName,
      }
    }
    var searchText = filterData.searchText;
    var zoneId = filterData.zoneId;
    this.setState({ pagination, sortInfo, searchText, zoneId }, () => {
      if (isFirstMount) {
        this.loadCharityList()
      }
    })
  }

  resetPageFilters = () => {
    let pagination = { ...this.state.pagination };
    pagination.page = 1;
    this.setState({ pagination, searchText: "", zoneId: null }, () => {
      this.loadCharityList()
    })
  }
}

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { loadUsersLinkedZonesAutocomplete } from '../../actions/autocomplete/AutocompleteActions';
import { ZonesDropdownComponent } from '../../../common/components/dropdown/ZonesDropdownComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
    return {
        dataSource: state.AdminAutocompleteReducer.loadUsersLinkedZonesSuccess,
        dataSourceError: state.AdminAutocompleteReducer.loadUsersLinkedZonesError,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadData: bindActionCreators(loadUsersLinkedZonesAutocomplete, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ZonesDropdownComponent)

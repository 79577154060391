
import * as React from "react";
import { isEqual } from "lodash"
import AppUtilityService from "../../../common/services/AppUtilityService";

import { Modal, Button } from 'antd';
import SaveLocationFormComponent from "./SaveLocationFormComponent";


export class AddNewLocationModal extends React.Component<IAddNewLocationModalProps, IAddNewLocationModalState> {

    private saveLocationFormRef: any;

    constructor(props: IAddNewLocationModalProps) {
        super(props);
        this.state = {

        }
    }

    componentWillReceiveProps(nextProps: IAddNewLocationModalProps) {
        if (nextProps.addNewLocationSuccess && !isEqual(this.props.addNewLocationSuccess, nextProps.addNewLocationSuccess)) {
            this.props.cancelSaveLocationCallback(nextProps.addNewLocationSuccess);
            AppUtilityService.showMessageToast("New location has been added", "success");
        }
        if (nextProps.addNewLocationError && !isEqual(this.props.addNewLocationError, nextProps.addNewLocationError)) {
            AppUtilityService.handleApiError(nextProps.addNewLocationError);
        }
    }

    render() {
        return (
            <Modal
                visible={true}
                title="Add New Location"
                onOk={this.handleFormSubmit}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>Cancel</Button>,
                    <Button key="submit" type="primary" htmlType="submit" onClick={this.handleFormSubmit}>Add New Location</Button>
                ]}
            >
                <SaveLocationFormComponent ref={(ref) => this.saveLocationFormRef = ref} locationDetails={null} />
            </Modal>
        )
    }


    handleFormSubmit = (e) => {
        e.preventDefault();
        this.saveLocationFormRef.validateFields((err, values) => {
            if (!err) {
                var formData = values;
                this.handleAdd(formData)
            }
        })
    }

    handleAdd = (data) => {
        this.props.actions.addNewLocation(data);
    }

    handleCancel = () => {
        this.props.cancelSaveLocationCallback();
    }

} 

import { createApiCaller } from '../../../common/services/ApiService';
import { locationInvoicingConfigListUpdated } from './slice-actions';
import { fetchDataWithActionCreator } from '../../../common/store/fetched';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { processFailureReason } from '../../../common/store/failure';
import { IPagedLocationInvoicingConfig } from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';

export const getLocationInvoicingConfigs = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const params = getState().LocationInvoicingConfigReducer.locationInvoicingConfigSearchParams;
    return await fetchDataWithActionCreator(
      locationInvoicingConfigListUpdated,
      dispatch,
      createApiCaller(api => api.getData<IPagedLocationInvoicingConfig>('/locationInvoicingConfig/search', params)),
      processFailureReason,
      true,
    );
  };
}

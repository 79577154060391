
import * as React from "react";
import { isEqual, isEmpty } from "lodash";
import { FormComponentProps } from 'antd/lib/form/Form';
import StateAutocompleteContainer from "../../../common/containers/dropdown/StateAutocompleteContainer";
import { ChromePicker } from 'react-color';

import { Form, Input, Row, Col } from 'antd';
const FormItem = Form.Item;
const { TextArea } = Input;

class ZoneDetailsFormComponent extends React.Component<IZoneDescriptionFormProps & FormComponentProps, IZoneDescriptionFormState> {

    constructor(props: IZoneDescriptionFormProps & FormComponentProps) {
        super(props);
        this.state = {
            selectedZone: this.props.selectedZone || null,
        }
    }

    componentWillReceiveProps(nextProps: IZoneDescriptionFormProps) {
        if (nextProps.selectedZone && !isEmpty(nextProps.selectedZone) && !isEqual(this.props.selectedZone, nextProps.selectedZone)) {
            var selectedZone = nextProps.selectedZone;
            if (!selectedZone.zoneId) {
                this.props.form.resetFields();
            } else {
                this.props.form.setFieldsValue({ name: selectedZone.name })
            }
            this.setState({ selectedZone: nextProps.selectedZone })
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Row>
                <Col xxl={12} xl={16}>
                    <Form>
                        <FormItem label={<span className="bold">Zone name</span>}>
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Please input zone name!' }],
                                initialValue: this.state.selectedZone && this.state.selectedZone.name || ""
                            })(
                                <Input type="text"
                                    placeholder="Zone name"
                                />
                            )}
                        </FormItem>
                        <FormItem label={<span className="bold">Zone description</span>}>
                            {getFieldDecorator('description', {
                                initialValue: this.state.selectedZone && this.state.selectedZone.description || ""
                            })(

                                <TextArea rows={4} placeholder="" />
                            )}
                        </FormItem>
                        {
                            this.state.selectedZone && !this.state.selectedZone.parentZoneId &&
                            <FormItem label={<span className="bold">State</span>}>
                                {getFieldDecorator('state', {
                                    rules: [{ required: true, message: 'Please input state!' }],
                                    initialValue: this.state.selectedZone && this.state.selectedZone.state || ""
                                })(
                                    <StateAutocompleteContainer
                                        placeholder="Select State"
                                        ignoreId={true}
                                        preselectedSearchTerm={this.state.selectedZone && this.state.selectedZone.state}
                                    />
                                )}
                            </FormItem>
                        }
                        <FormItem label={<span className="bold">Zone color</span>}>
                            {getFieldDecorator('color', {
                                initialValue: this.state.selectedZone && this.state.selectedZone.color || "#22194D"
                            })(
                                <ChromePicker
                                    disableAlpha={true}
                                    color={this.state.selectedZone && this.state.selectedZone.color || "#22194D"}
                                    onChangeComplete={color => this.handleChangeComplete(color)}
                                />
                            )}
                        </FormItem>
                    </Form>
                </Col>
            </Row>
        )
    }

    /**
     * Handle colour picker change
     */
    handleChangeComplete = (colorPalette) => {
        if (colorPalette) {
            this.props.form.setFieldsValue({ color: colorPalette.hex })
            let selectedZone = { ...this.state.selectedZone };
            selectedZone.color = colorPalette.hex;
            this.setState({ selectedZone });
        }
    };

} export default Form.create<IZoneDescriptionFormProps & FormComponentProps>()(ZoneDetailsFormComponent);

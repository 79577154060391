import * as React from "react";
import { isEqual, isEmpty, get } from "lodash";
import { Link } from "react-router";
import AppUtilityService from "../../../common/services/AppUtilityService";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";

import { Table } from "antd";

export class DistributorsListTableComponent extends React.Component<IDistributorsListProps, IDistributorsListState> {

  constructor(props: IDistributorsListProps) {
    super(props);
    this.state = {
      isLoading: true,
      distributorsList: [],
      sortInfo: null
    }
  }
  componentDidMount() {
    this.loadDistributorsList();
  }

  componentWillReceiveProps(nextProps: IDistributorsListProps) {
    if (nextProps.distributorsListSuccess && !isEqual(this.props.distributorsListSuccess, nextProps.distributorsListSuccess)) {
      var responseData: any = nextProps.distributorsListSuccess;
      this.props.getTotalDistributorsCallback(responseData.total);
      this.setState({ distributorsList: responseData.distributors, isLoading: false })
    }
  }

  render() {
    const { isLoading } = this.state;

    const tableColumns = [
      {
        title: 'Distributor ID',
        dataIndex: 'distributorId',
        key: 'distributorId',
        sorter: true,
        sortOrder: AppUtilityService.getTableColumnSortOrder(this.state.sortInfo, 'distributorId'),
      }, {
        title: 'Distributor',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        sortOrder: AppUtilityService.getTableColumnSortOrder(this.state.sortInfo, 'name'),
        render: (text, record) => {
          return (
            <Link to={"/distributors/" + record.distributorId}>{text}</Link>
          )
        }
      }, {
        title: 'License',
        dataIndex: 'license',
        key: 'licecnse',
        sorter: false,
        className: "hidden-sm",
      }
    ];

    return (
      <Table
        rowKey={(record, index) => { return (record.distributorId).toString() }}
        loading={isLoading}
        dataSource={this.state.distributorsList}
        columns={tableColumns}
        //pagination={{ current: this.state.pagination.page, pageSize: pagination.pageSize, total: pagination.total }}
        onChange={this.handleTableChange}
        locale={
          {
            emptyText: <EmptyStateComponent title="No Distributors" />
          }
        }
      />
    )
  }

  handleTableChange = (updatedPagination, filters, sorter) => {
    let sortInfo = { ...this.state.sortInfo };
    if (sorter && !isEmpty(sorter)) {
      sortInfo = {
        sortDir: sorter.order,
        propertyName: sorter.field
      }
    } else {
      sortInfo = null;
    }
    this.setState({ sortInfo }, () => {
      this.loadDistributorsList()
    })
  }

  loadDistributorsList = () => {
    this.setState({ isLoading: true });
    let { sortInfo } = this.state;
    let params = {
      sortDir: sortInfo && sortInfo.sortDir || null,
      propertyName: sortInfo && sortInfo.propertyName || null
    }

    this.props.actions.loadDistributorsListData(params);
  }

}
import { Button, Card, Icon, Select, message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { updateBreadcrumbs } from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import { loadLocationInvoicingDistributorsDropdown } from '../../../common/actions/dropdown/DropdownActions';
import InvoicingDistributorsAutocompleteContainer from '../../../common/containers/dropdown/InvoicingDistributorsAutocompleteContainer';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import AppUtilityService from '../../../common/services/AppUtilityService';
import { downloadDistributorDealsDeliveredReport } from '../../actions/invoicingReports/downloadDistributorDealsDeliveredReport';
import { LoadState } from '../../../common/store/fetched';
import { lastYears } from '../../../common/components/monthYearPicker/MonthYearPicker';

function getPreviousQuarter() {
  return moment().subtract(1, 'quarter').quarter() + 1;
}

function getYearOfPreviousMonth() {
  return moment().subtract(1, 'month').year();
}

function DistributorDealsDeliveredPage() {
  const dispatch = useAppDispatch();

  const [quarter, setQuarter] = useState(getPreviousQuarter());
  const [year, setYear] = useState(getYearOfPreviousMonth());
  const [invoicingDistributorId, setInvoicingDistributorId] = useState<number | null>(null);

  const invoicingDistributorsData = useAppSelector(
    (state) => state.DropdownReducer.loadLocationInvoicingDistributorsDropdownDataSuccess
  );
  const isDownloadingInProgress = useAppSelector(
    (state) =>
      state.InvoicingReportsReducer.downloadDistributorDealsDeliveredReportOperation.loadState === LoadState.InProgress
  );
  const hasDownloadingFailed = useAppSelector(
    (state) =>
      state.InvoicingReportsReducer.downloadDistributorDealsDeliveredReportOperation.loadState === LoadState.Failed
  );

  useEffect(() => {
    const breadcrumbsList = [
      { name: 'Reports', link: '/Reports' },
      { name: 'Distributor Deals Delivered', link: null },
    ];
    const breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
    dispatch(updateBreadcrumbs(breadcrumbs));
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadLocationInvoicingDistributorsDropdown({}));
  }, [dispatch]);

  useEffect(() => {
    if (invoicingDistributorId == null && invoicingDistributorsData?.length > 0) {
      setInvoicingDistributorId(invoicingDistributorsData[0].id);
    }
  }, [invoicingDistributorId, invoicingDistributorsData]);

  useEffect(() => {
    if (hasDownloadingFailed) {
      message.error('Failed to download report');
    }
  }, [hasDownloadingFailed]);

  const resetFilters = () => {
    setYear(getYearOfPreviousMonth());
    setQuarter(getPreviousQuarter());
    setInvoicingDistributorId(invoicingDistributorsData?.[0]?.id || null);
  };

  const downloadExcel = () => {
    dispatch(downloadDistributorDealsDeliveredReport(year, quarter, invoicingDistributorId));
  };

  return (
    <div className="pg-container page-container">
      <Card className="no-header-border" bordered={false} title="Distributor Deals Delivered Report">
        <Select
          style={{ minWidth: '180px' }}
          className="mr-8"
          filterOption={false}
          onChange={setQuarter}
          placeholder="Select quarter"
          value={quarter}
          mode="default"
        >
          <Select.Option value={1}>Q1</Select.Option>
          <Select.Option value={2}>Q2</Select.Option>
          <Select.Option value={3}>Q3</Select.Option>
          <Select.Option value={4}>Q4</Select.Option>
        </Select>
        <Select
          style={{ minWidth: '180px' }}
          className="mr-8"
          filterOption={false}
          onChange={setYear}
          placeholder="Select year"
          value={year}
          mode="default"
        >
          {lastYears(5).map((y) => (
            <Select.Option key={y} value={y}>
              {y}
            </Select.Option>
          ))}
        </Select>
        <InvoicingDistributorsAutocompleteContainer
          className="mr-8"
          placeholder="Select invoicing distributor"
          allowClear={false}
          value={invoicingDistributorId}
          onChange={setInvoicingDistributorId}
        />
        <Button onClick={resetFilters} type="default" className="mr-8">
          ↻
        </Button>
        <Button type="primary" className="ml-8" onClick={downloadExcel} loading={isDownloadingInProgress}>
          <Icon type="download" style={{ fontSize: '16px' }} /> Download as Excel
        </Button>
      </Card>
    </div>
  );
}

export default DistributorDealsDeliveredPage;

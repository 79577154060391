import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { LocationCharity } from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';
import { useAppSelector } from '../../../common/hooks/storeHooks';
import { Fetched, LoadState } from '../../../common/store/fetched';

export type Props = {
  takenLocalCharityIds: (number | null)[];
  onAddTab: (localCharityId: number | null) => void;
};

function getMissingLocalCharities(
  locationCharities: Fetched<LocationCharity[]>,
  takenLocalCharityIds: (number | null)[],
): LocationCharity[] {
  if (locationCharities.loadState !== LoadState.Success) {
    return [];
  }

  const items = locationCharities.data
      ?.filter(c => c.localCharityId != null && !takenLocalCharityIds.includes(c.localCharityId))
    ?? [];

  const groupedByLocalCharityId = items.reduce((acc, c) => {
    if (!acc[c.localCharityId]) {
      acc[c.localCharityId] = [];
    }
    acc[c.localCharityId].push(c);
    return acc;
  }, {} as { [localCharityId: number]: LocationCharity[] });

  return Object.values(groupedByLocalCharityId)
    .map(c => c.sort((a, b) => a.id - b.id)[0]);
}

const AddLocationCharityConfigButton = ({ takenLocalCharityIds, onAddTab }: Props) => {
  const locationCharities = useAppSelector(state => state.LocationInvoicingConfigReducer.locationCharities);
  const missingLocalCharities = getMissingLocalCharities(locationCharities, takenLocalCharityIds);

  const handleAddTab = (localCharityId: number | null) => {
    onAddTab(localCharityId);
  };

  if (locationCharities.loadState !== LoadState.Success || missingLocalCharities.length === 0) {
    return null;
  }

  const menu = (
    <Menu onClick={e => handleAddTab(e.key === 'NONE' ? null : Number(e.key))}>
      {missingLocalCharities.map(c => <Menu.Item key={c.localCharityId}>{c.localCharityId}. {c.name}</Menu.Item>)}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button type="primary" shape="circle">
        +
      </Button>
    </Dropdown>
  );
}

export default AddLocationCharityConfigButton;

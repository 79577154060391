import React, { forwardRef, useState } from 'react';
import { DatePicker, Input, Button, Row, Col } from 'antd';
import moment, { Moment } from 'moment';
import { DeleteOutlined } from '@ant-design/icons';

interface Entry {
  startDate: Moment;
  discount: number;
}
interface IProps {
  value?: Entry[];
  onChange?: (value: Entry[]) => void;
}

const parseEntries = (value: Entry[]) => {
  return value.map(e => ({ ...e, startDate: moment(e.startDate) }));
}

const DiscountList = forwardRef((props: IProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const [entries, setEntries] = useState<Entry[]>(parseEntries(props.value ?? []));

  const handleListChange = (entries: Entry[]) => {
    setEntries(entries);
    if (props.onChange) props.onChange(entries);
  }

  const handleStartDateChange = (index: number, date: Moment | null) => {
    if (date) {
      const newEntries = [...entries];
      newEntries[index].startDate = date.startOf('month');
      handleListChange(newEntries);
    }
  };

  const handleDiscountChange = (index: number, value: string) => {
    const newEntries = [...entries];
    newEntries[index].discount = parseFloat(value);
    handleListChange(newEntries);
  };

  const handleAddEntry = () => {
    let newStartDate;

    if (entries.length > 0) {
      const lastEntryStartDate = entries[entries.length - 1].startDate;
      newStartDate = moment(lastEntryStartDate).add(1, 'months').startOf('month');
    } else {
      newStartDate = moment().startOf('month');
    }

    handleListChange([...entries, { startDate: newStartDate, discount: 0 }]);
  };

  const handleDeleteEntry = (index: number) => {
    const newEntries = [...entries];
    newEntries.splice(index, 1);
    handleListChange(newEntries);
  };

  const disabledDate = (index: number, current: Moment) => {
    if (index < 1) return false;
    return entries.filter((e, i) => i < index).find(e => e.startDate < current) === undefined;
  };

  return (
    <div style={{ textAlign: 'right' }} ref={ref}>
      {entries.map((entry, index) => (
        <Row key={index} gutter={16} align="middle" style={{ marginBottom: 0 }}>
          <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'inline-block', marginLeft: 16, marginRight: 8 }}>
              <strong>&bull;</strong>
            </div>
            <div>
              <span style={{ marginRight: 8 }}>Start date:</span>
              <DatePicker.MonthPicker
                value={entry.startDate}
                onChange={(date) => handleStartDateChange(index, date)}
                disabledDate={(current: Moment) => disabledDate(index, current)}
                format="MM-DD-YYYY"
                style={{ width: 120 }}
                allowClear={false}
              />
            </div>
            <div>
              <span style={{ marginLeft: 16, marginRight: 8 }}>Discount:</span>
              <Input
                value={entry.discount.toString()}
                onChange={(e) => handleDiscountChange(index, e.target.value)}
                type="number"
                min={0}
                max={100}
                style={{ width: 80, textAlign: 'right' }}
              />
              <span style={{ marginLeft: 2, marginRight: 16 }}>%</span>
            </div>
            <Button
              onClick={() => handleDeleteEntry(index)}
              style={{ padding: 0, border: 'none', marginTop: 4 }}
              className='text-negative'
            >
              <DeleteOutlined />
            </Button>
          </Col>
        </Row>
      ))}
      <Button onClick={handleAddEntry} style={{ marginTop: 10 }}>
        + Add
      </Button>
    </div>
  );
});

export default DiscountList;


import * as React from "react";
import { Layout } from 'antd';
import ErrorBoundaryWrapperContainer from "../../containers/layouts/ErrorBoundaryWrapperContainer";
import { PropsWithChildren } from 'react';
const { Content } = Layout;

export const LoginLayout = ({ children }: PropsWithChildren<any>) => {
  return (
    <Layout style={{ minHeight: '100vh' }} className="login-layout">
      <ErrorBoundaryWrapperContainer>
        <Content>
          {children}
        </Content>
      </ErrorBoundaryWrapperContainer>
    </Layout>
  )
}

import { downloadKYWS06bReportOperationUpdated } from './slice-actions';
import { DownloadedFile, saveFile } from '../../../common/store/fileDownload';
import apiService from '../../../common/services/ApiService';
import { createStandardFetchedAction } from '../../../common/store/fetched.slices';

export const downloadKYWS06bReport =
  (sessionId: number, locationId: number) => createStandardFetchedAction(
    downloadKYWS06bReportOperationUpdated,
    async () => {
      const result = await apiService.downloadFile(
        `/reports/ky-ws06b-report/excel/${sessionId}/${locationId}`);

      const fileName = result.headers['x-filename'];
      const file: DownloadedFile = {
        data: result.data,
        fileName,
      };
      saveFile(file);
    },
    false,
  );

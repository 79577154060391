
import * as React from "react";
import { isEqual, isEmpty } from "lodash";
import UserRoleListTableContainer from "../../containers/userRole/UserRoleListTableContainer";
import SaveUserRoleModalContainer from "../../containers/userRole/SaveUserRoleModalContainer";
import AppUtilityService from "../../../common/services/AppUtilityService";

import { Card, Input, Icon, Button, Modal, Badge, Switch } from 'antd';
const confirm = Modal.confirm;

export class UserRoleListPage extends React.Component<IUserRoleProps, IUserRoleState> {

  private usersRolesListRef: any;

  constructor(props: IUserRoleProps) {
    super(props);
    this.state = {
      selectedRoleKeys: null,
      showSaveRoleModal: false,
      searchText: "",
      totalRoles: null,
    }
  }

  componentWillMount() {
    this.breadcrumbsBuild();
    if (this.props.userRoleListFilters) {
      this.handlePresetFilters(this.props.userRoleListFilters)
    }
  }

  componentWillReceiveProps(nextProps: IUserRoleProps) {
    /**
     * Handle delete role success/error
     */
    if (nextProps.deleteMultipleUserRolesSuccess !== null && !isEqual(this.props.deleteMultipleUserRolesSuccess, nextProps.deleteMultipleUserRolesSuccess)) {
      if (nextProps.deleteMultipleUserRolesSuccess) {
        AppUtilityService.showMessageToast("Selected roles have been deleted", "success");
      } else {
        AppUtilityService.showMessageToast("Some of the selected roles cannot be deleted since they are users assigned to these roles", "warning");
      }
      this.usersRolesListRef.loadUserRoles();
    }
    if (nextProps.deleteMultipleUserRolesError && !isEqual(this.props.deleteMultipleUserRolesError, nextProps.deleteMultipleUserRolesError)) {
      AppUtilityService.handleApiError(nextProps.deleteMultipleUserRolesError);
    }

  }



  render() {
    const title = <Input type="text"
      suffix={<Icon type="search" theme="outlined" />}
      placeholder="Search"
      onChange={this.searchChange}
      style={{ width: 200 }}
      value={this.state.searchText}
    />;

    const extraActions = <div className="user-role-list-filters">
      <span>
        <Button type="danger" disabled={isEmpty(this.state.selectedRoleKeys) ? true : false} onClick={this.deleteSelectedRoles}>Delete</Button>
      </span>
      <span>
        <Button className="ml-8" type="primary" onClick={this.openSaveRoleModal}>Create New Role</Button>
      </span>
      <span>
        <Button className="ml-8" onClick={this.refreshList}>Refresh</Button>
      </span>
    </div>;
    return (
      <div className="pg-container page-container">
        <Card bordered={false}
          title={title}
          extra={extraActions}
        >
          <div className="text-right" style={{ marginBottom: '12px' }}>
            Show Archived <Switch checkedChildren="On" unCheckedChildren="Off" onChange={this.viewArchivedFlagChange} />
          </div>
          <UserRoleListTableContainer
            onRef={ref => (this.usersRolesListRef = ref)}
            selectRowCallback={this.selectRowHandler}
            getTotalRolesCallback={this.getTotalRolesHandler}
          />
          {
            this.state.showSaveRoleModal &&
            <SaveUserRoleModalContainer closeSaveRoleModalCallback={this.closeSaveRoleModalHandler}
              roleAddSuccessCallback={this.roleAddSuccessHandler} />
          }
        </Card>
      </div>
    )
  }

  /**
   * Get total roles on load list success callback from UserRoleListTableComponent
   * (required to show total in breadcrumbs)
   */
  getTotalRolesHandler = (totalCount: number) => {
    this.setState({ totalRoles: totalCount }, () => {
      this.breadcrumbsBuild()
    })
  }

  breadcrumbsBuild = () => {
    let breadcrumbsList = [
      { name: "User Role", link: null }
    ]
    var extraContent = this.state.totalRoles &&
      <span className="font-12 t-grey-dark ml-8">
        <Badge dot className="mr-8" style={{ top: '-1px', background: '#A6ADC9' }} /> {this.state.totalRoles + " Total"}
      </span>;
    var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList, extraContent);
    this.props.actions.updateBreadcrumbs(breadcrumbs);
  }

  /**
   * Handle search change in UserRoleListTableComponent using ref
   */
  searchChange = (e: any) => {
    this.setState({ searchText: e.target.value })
    this.usersRolesListRef.handleSearchChange(e.target.value);
  }

  /**
   * Call loadUserRoles using ref onClick of refresh button
   */
  refreshList = () => {
    this.setState({ searchText: "" })
    this.usersRolesListRef.resetTable();
  }

  /**
   * deleteSelectedRoles  make API call to delete multiple roles & pass array of selected keys, on success reload list using usersRolesListRef
   */
  deleteSelectedRoles = () => {
    confirm({
      title: 'Confirm dialog',
      content: 'Are you sure you want to delete the selected roles?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        this.props.actions.deleteMultipleUserRoles(this.state.selectedRoleKeys)
      },
      onCancel() {

      },
    });
  }


  /**
   * Set selectedRoleKeys in state through callback from UserRoleListTableComponent once a row is selected
   */
  selectRowHandler = (selectedRoleKeys: Array<number>) => {
    this.setState({ selectedRoleKeys })
  }
  /**
   * openSaveRoleModal: Open save role modal on button click
   * editRowHandler: Open save role with selected role passed through callback function from UserRoleListTableComponent on row edit
   * closeSaveRoleModalHandler: Close save role modal through callback function onClick cancel from modal
   * roleAddSuccessHandler: Close save role modal and reload roles list through callback function on save success from modal
   */
  openSaveRoleModal = () => {
    this.setState({ showSaveRoleModal: true })
  }
  closeSaveRoleModalHandler = () => {
    this.setState({ showSaveRoleModal: false })
  }
  roleAddSuccessHandler = () => {
    this.usersRolesListRef.loadUserRoles();
    this.setState({ showSaveRoleModal: false })
  }

  handlePresetFilters = (filterData) => {
    var searchText = filterData.searchText;
    this.setState({ searchText })
  }

  viewArchivedFlagChange = (isChecked) => {
    this.usersRolesListRef.onSelectChange([]);
    this.usersRolesListRef.handleViewArchivedFlagChange(isChecked);
  }
}

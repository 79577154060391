import { useState, useEffect} from "react";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/storeHooks";
import { usePrevious } from "../../../common/hooks/usePrevious";
import { LoadState } from '../../../common/store/fetched';
import { updateBreadcrumbs } from '../../../common/actions/breadcrumbs/BreadcrumbActions';

import AppUtilityService from "../../../common/services/AppUtilityService";
import { Card } from 'antd';
import LocationSettingsListTableComponent from "../../components/locationSettings/LocationSettingsListTableComponent";
import LocationFilterDropDownComponent from "../../../common/components/dropdown/LocationFilterDropDownComponent"
import WebAccessService from "../../../common/services/WebAccessService";
import { getLocationsAndSettingsListData } from '../../actions/locationSettings/LocationSettingsActions';

export const LocationSettingsPage = (props) => {
  const dispatch = useAppDispatch();
  const locationSettingsData = useAppSelector(state => state.LocationSettingsReducer.locationSettingsData || null);
  const locationSettingsListLoadState = useAppSelector(state => state.LocationSettingsReducer.locationSettingsData.loadState);
  const prevLocationSettingsListLoadState = usePrevious(locationSettingsListLoadState);
  const [tableDataSource, setTableDataSource] = useState([] || null);
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [locationFilterDataSource, setLocationFilterDataSource] = useState([] || null);
  const [demoOnlyChecked, setdemoOnlyChecked] = useState<boolean>(false);
  
  useEffect(() => {
    setIsLoading(true)
    dispatch(getLocationsAndSettingsListData());
  }, [dispatch]);

  useEffect(
    () => {
      const breadcrumbsList = [
        { name: 'Location Settings', link: null }
      ]
      const breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
      dispatch(updateBreadcrumbs(breadcrumbs));
    });

  useEffect(() => {
    if (prevLocationSettingsListLoadState === LoadState.InProgress && locationSettingsListLoadState === LoadState.Success)
    {
      setTableDataSource([...locationSettingsData.data.locationsAndSettings]);
      setLocationFilterDataSource([...locationSettingsData.data.locationFilterList]);
      setIsLoading(false)
    }
  }, [dispatch, prevLocationSettingsListLoadState, locationSettingsListLoadState]);

  useEffect(() => {    
  }, [tableDataSource, locationFilterDataSource])

  const extraActions = null;

  const demoOnlyChange = (e) => {
    setdemoOnlyChecked(e);
    if (e === true) {
      //filter ZD locations in table
      setTableDataSource([...locationSettingsData.data.locationsAndSettings.filter(item => item.locationName.includes('[ZD]'))])
      //filter ZD location filter drop down
      setLocationFilterDataSource([...locationSettingsData.data.locationFilterList.filter(item => item.name.includes('- ZD'))])
    }
    if (e === false) {
      //reset locations in table
      setTableDataSource([...locationSettingsData.data.locationsAndSettings]);
      //reset filter drop down
      setLocationFilterDataSource([...locationSettingsData.data.locationFilterList])
    }
  };
  
  const locationSelected = (locationId) => {
    if (locationId === undefined) {
      setTableDataSource([...locationSettingsData.data.locationsAndSettings])
    } else {
      setTableDataSource([...locationSettingsData.data.locationsAndSettings.filter(i => i.id === parseInt(locationId))])
    }
  }

  const breadcrumbsBuild = () => {
    let breadcrumbsList = [
      { name: "Location Settings", link: null }
    ]

    var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
    props.actions.updateBreadcrumbs(breadcrumbs);
  }

 

    return (
      <div className="pg-container page-container">
        { 
        <Card
              style={{ paddingTop: '12px' }}
              className="reports-filter-card content-card no-header-border"
              bordered={false}
              extra={ WebAccessService.hasPermissionToAccess("LocationSettings", "View") ? extraActions : null }>
            
          <LocationFilterDropDownComponent 
              dataSource = {[...locationFilterDataSource]}
              demoOnlyChange = {(e) => demoOnlyChange(e)}
              demoOnlyChecked = {demoOnlyChecked}
              demoOnlyVisible = {true}
              locationsPlaceHolder = {'Select a location to filter'}
              showSearch = {true}
              selectMode={null}
              locationSelected = {(locationId) => locationSelected(locationId)}
              allowClear={true}              
          />

            <LocationSettingsListTableComponent
              dataSource = {[...tableDataSource]}
              isLoading={isLoading}
            />              
        </Card>
        }
      </div>
        );
}
export default LocationSettingsPage;
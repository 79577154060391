import { message } from 'antd';
import { createApiCaller } from '../../../common/services/ApiService';
import { AppDispatch } from '../../../common/store/ConfigureStore';
import { FailureData, FailureReason, processFailureReason } from '../../../common/store/failure';
import { fetchDataWithActionCreator } from '../../../common/store/fetched';
import { IInvoice } from '../../reducers/billing/billing.models';
import { invoiceUpdated } from './slice-actions';
import AppUtilityService from '../../../common/services/AppUtilityService';

export const getInvoice = (invoiceId: number, silentReload = false) => {
  return async (dispatch: AppDispatch) => {
    var result = await fetchDataWithActionCreator(
      invoiceUpdated,
      dispatch,
      createApiCaller((api) => api.getData<IInvoice>(`/invoices/${invoiceId.toFixed(0)}`)),
      (error: any): FailureData => {
        var processedError = processFailureReason(error);
        if ((processedError.reason === FailureReason.ServerError)&&(processedError.statusCode === 400)){
          if (processedError.response?.data?.errorCode === 'LocationInvoicingConfigNotFound') {
            const data = processedError.response?.data;
            message.error(`We could not find billing configuration for this invoice. Configuration is missing for charity id = ${data?.charityId} and location id = ${data?.locationId}`, 5);
          }
        }
        return processedError;
      },
      silentReload
    );
    return result;
  };
};


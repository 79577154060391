
import * as React from "react";
import { isEqual } from "lodash";
import { FormComponentProps } from 'antd/lib/form/Form'

import { Form, Input, Row, Col } from 'antd';
const FormItem = Form.Item;

class SaveCharityFormComponent extends React.Component<ISaveCharityFormProps & FormComponentProps, ISaveCharityFormState> {

    constructor(props: ISaveCharityFormProps & FormComponentProps) {
        super(props);
        this.state = {
            charityDetails: this.props.charityDetails || null,
        }
    }

    componentWillReceiveProps(nextProps: ISaveCharityFormProps) {
        if (!isEqual(this.props.charityDetails, nextProps.charityDetails)) {
            this.setState({ charityDetails: nextProps.charityDetails })
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form>
                <Row gutter={16}>
                    <Col span={24}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <FormItem label="Name">
                                    {getFieldDecorator('name', {
                                        initialValue: this.state.charityDetails && this.state.charityDetails.name,
                                        rules: [{ required: true, message: 'Please enter a charity name.' }],
                                    })(
                                        <Input placeholder="" />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="License no">
                                    {getFieldDecorator('licenseNumber', {
                                        initialValue: this.state.charityDetails && this.state.charityDetails.licenseNumber,
                                    })(
                                        <Input placeholder="" />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <FormItem label="Address line 1">
                                    {getFieldDecorator('address1', {
                                        initialValue: this.state.charityDetails && this.state.charityDetails.address1,
                                    })(
                                        <Input placeholder="" />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="Address line 2">
                                    {getFieldDecorator('address2', {
                                        initialValue: this.state.charityDetails && this.state.charityDetails.address2,
                                    })(
                                        <Input placeholder="" />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <FormItem label="City">
                                    {getFieldDecorator('city', {
                                        initialValue: this.state.charityDetails && this.state.charityDetails.city,
                                    })(
                                        <Input placeholder="" />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="State">
                                    {getFieldDecorator('addressState', {
                                        initialValue: this.state.charityDetails && this.state.charityDetails.addressState,
                                    })(
                                        <Input placeholder="" />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <FormItem label="Zipcode">
                                    {getFieldDecorator('zipcode', {
                                        initialValue: this.state.charityDetails && this.state.charityDetails.zipcode,
                                    })(
                                        <Input placeholder="" />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                            <FormItem label="Email">
                                {getFieldDecorator('email', {
                                    initialValue: this.state.charityDetails && this.state.charityDetails.email,
                                })(
                                    <Input placeholder="" />
                                )}
                            </FormItem>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        )
    }
} export default Form.create<ISaveCharityFormProps & FormComponentProps>()(SaveCharityFormComponent);

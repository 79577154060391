import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as UserRoleActions from '../../actions/userRole/UserRoleActions';
import RoleUsersListCardComponent from '../../components/userRole/RoleUsersListCardComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    assignRoleToUserSuccess: state.UserRoleReducer.assignRoleToUserSuccess,
    assignRoleToUserError: state.UserRoleReducer.assignRoleToUserError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(UserRoleActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleUsersListCardComponent)

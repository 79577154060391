import { billingSlice } from '../../reducers/billing/BillingReducer';

export const {
  setInvoiceListParams,
  invoiceListUpdated,
  dismissInvoiceList,
  invoiceUpdated,
  dismissInvoice,
  downloadInvoiceAttachmentOperationUpdated,
  dismissDownloadInvoiceAttachmentOperation,
  updateValidationIssueOperationUpdated,
  dismissUpdateValidationIssueOperation,
  updateStatusOperationUpdated,
  dismissUpdateStatusOperation,
  updateBulkStatusOperationUpdated,
  dismissBulkUpdateStatusOperation,
  updateInvoiceNotesOperationUpdated,
  dismissUpdateInvoiceNotesOperation,
  addAdjustmentOperationUpdated,
  dismissAddAdjustmentOperation,
  updateAdjustmentOperationUpdated,
  dismissUpdateAdjustmentOperation,
  deleteAdjustmentOperationUpdated,
  dismissDeleteAdjustmentOperation,
  updateInvoiceFilterValues,
  resendInvoiceOperationUpdated,
  dismissResendInvoiceOperation,
  regenerateInvoiceOperationUpdated,
  dismissRegenerateInvoiceOperation,
  regenerateSelectedInvoicesOperationUpdated,
  dismissRegenerateSelectedInvoicesOperation,
  updateInvoiceCustomItemOperationUpdated,
  dismissUpdateInvoiceCustomItemOperation,
  addInvoiceCustomItemOperationUpdated,
  dismissAddInvoiceCustomItemOperation,
  deleteInvoiceItemOperationUpdated,
  dismissDeleteInvoiceItemOperation,
  generateInvoicesOperationUpdated,
  dismissGenerateInvoicesOperationUpdated,
  updateInvoiceEmailTemplateOperationUpdated,
  dismissUpdateInvoiceEmailTemplateOperation
} = billingSlice.actions;

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  LocationReportsFilterComponent,
} from '../../../components/reports/locationStatisticsReports/LocationReportsFilterComponent';
import { RootState } from '../../../../common/store/ConfigureStore';
import { updateLocationReportFilters } from '../../../actions/reports/ReportsActions';

const mapStateToProps = (state: RootState) => ({
  filterValues: state.ReportsReducer.locationReportFilters,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updateFilterValues: updateLocationReportFilters,
    },
    dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationReportsFilterComponent);

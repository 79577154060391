import * as React from "react";

import { Drawer } from 'antd';

export class NotificationDrawerComponent extends React.Component<INotificationProps, {}>{
    constructor(props: INotificationProps) {
        super(props);

    }

    render() {
        return (
            <Drawer
                title="Notifications"
                placement="right"
                closable={false}
                onClose={this.onClose}
                visible={true}
            >
                <p>Notification content...</p>
            </Drawer>
        );
    }

    onClose = () => {
        this.props.closeNotificationsCallback()
    }

}

import * as React from "react";
import { debounce, isEqual, isEmpty, map, includes, each, pullAll } from "lodash";
import AppUtilityService from "../../../common/services/AppUtilityService";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";

import { Tree, Spin } from 'antd';
import ApiService from "../../../common/services/ApiService";
const TreeNode = Tree.TreeNode;

export class ZoneTreeComponent extends React.Component<IZoneTreeProps, IZoneTreeState> {
  private changedDataSource = [];
  constructor(props: IZoneTreeProps) {
    super(props);
    this.state = {
      allZones: [],
      isLoading: true,
      searchText: "",
      selectedZoneId: null,
      autoExpandParent: true,
      expandedKeys: []
    }
    this.loadAllZones = debounce(this.loadAllZones, 500);
  }

  componentWillMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentDidMount() {
    this.loadAllZones();
  }


  componentWillReceiveProps(nextProps: IZoneTreeProps) {
    /**
     * Handle zone list load success/error
     * (If a zone is not selected, select first zone in the list and send as zoneSelectedCallback to parent component)
     */
    if (nextProps.loadZoneListDataSuccess && !isEqual(this.props.loadZoneListDataSuccess, nextProps.loadZoneListDataSuccess)) {
      var { selectedZoneId } = this.state;
      var responseData: any = nextProps.loadZoneListDataSuccess;
      if (!isEmpty(responseData)) {
        if (!selectedZoneId) {
          selectedZoneId = responseData[0].zoneId;
        }
        if (!this.state.searchText) {
          this.setState({ expandedKeys: [] })
        } else {
          this.setState({ expandedKeys: map(responseData, (obj) => { return obj.zoneId.toString() }) })
        }
      } else {
        selectedZoneId = null;
      }
      this.props.zoneSelectedCallback(selectedZoneId);
      this.setState({ allZones: responseData, selectedZoneId, isLoading: false })
    }
    if (nextProps.loadZoneListDataError && !isEqual(this.props.loadZoneListDataError, nextProps.loadZoneListDataError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.loadZoneListDataError);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  render() {
    var selectedKeys: Array<string> = this.state.selectedZoneId && [this.state.selectedZoneId.toString()];
    return (
      <Spin spinning={this.state.isLoading}>
        {
          this.state.allZones && !isEmpty(this.state.allZones) ?
            <Tree
              showIcon
              autoExpandParent={this.state.autoExpandParent}
              onSelect={this.onSelect}
              onExpand={this.onExpand}
              selectedKeys={selectedKeys}
              expandedKeys={this.state.expandedKeys}
            // loadData={this.loadChildData}
            >
              {this.renderTreeNodes(this.state.allZones)}
            </Tree>
            :
            <EmptyStateComponent title="No Data" />
        }
      </Spin>
    )
  }

  renderTreeNodes = (allZones: Array<BasicZoneDetails>) => {
    return allZones.map((item: BasicZoneDetails) => {
      return (
        <TreeNode
          disabled={this.state.selectedZoneId === item.zoneId ? true : false}
          icon={<i className="icon-zone t-grey-dark" />}
          title={item.name}
          key={item.zoneId.toString()}
          isLeaf={!item.hasChildren}
        >
          {
            item.childZones && !isEmpty(item.childZones) &&
            this.renderTreeNodes(item.childZones)
          }
        </TreeNode>
      );
    });
  }

  handleSearchChange = (value: string) => {
    this.setState({ searchText: value }, () => {
      this.loadAllZones();
    })
  }
  /**
   * Load all zones 
   * - if no zone is selected, set selectedZoneId to the first zone
   * - callback with new selectedZoneId to parent component
   */
  loadAllZones = () => {
    this.setState({ isLoading: true })
    let params = {
      searchText: this.state.searchText
    }
    this.props.actions.loadZonesListData(params);
  }

  onSelect = (selectedKeys: Array<string>, selectedDetails: any) => {
    var expandedKeys = [...this.state.expandedKeys];
    var selectedZoneId = null;
    if (selectedKeys && !isEmpty(selectedKeys)) {
      selectedZoneId = parseInt(selectedKeys[0])
      if (expandedKeys.indexOf(selectedZoneId.toString()) === -1) {
        expandedKeys.push(selectedZoneId.toString())
        this.onExpand(expandedKeys, selectedDetails);
      }
      if (selectedDetails && selectedDetails.node && selectedDetails.node.props && selectedDetails.node.props.children && !isEmpty(selectedDetails.node.props.children)) {
        let expandedChildren = map(selectedDetails.node.props.children, 'key');
        expandedKeys = pullAll(expandedKeys, expandedChildren)
      }
    }
    this.props.zoneSelectedCallback(selectedZoneId);
    this.setState({ selectedZoneId, expandedKeys });
  }

  onExpand = (expandedKeys, expandedDetails) => {
    this.loadChildData(expandedDetails.node)
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  }

  /**
   * If a new zone is added get selectedZoneId (i.e. parent of newly added zone) and push to expandedNodes if it doesn't already exist
   * Assigned selectedZoneId to the newly added zone and reload all zones
   */
  addNewZoneHandler = (addedZone: any) => {
    var expandedKeys = [...this.state.expandedKeys];
    if (addedZone) {
      if (!includes(expandedKeys, addedZone.zoneId.toString())) {
        expandedKeys.push(addedZone.zoneId.toString())
      }

      if (addedZone.parentZoneId) {
        var treeNode = {
          props: {
            eventKey: addedZone.parentZoneId
          }
        }
        this.loadChildData(treeNode);
      } else {

        this.loadAllZones();
      }
      this.setState({ selectedZoneId: addedZone.zoneId, expandedKeys, autoExpandParent: false });
    }
  }

  /**
   * Set selectedZoneId to null and call loadAllZones, selectedZoneId to null will reinitialise it in loadAllZones 
   */
  deleteZoneSuccessHandler = () => {
    this.setState({ selectedZoneId: null, expandedKeys: [] }, () => {
      this.loadAllZones();
    })
  }

  // Load child data asynchronously
  loadChildData = (treeNode) => {
    return new Promise((resolve) => {
      ApiService.getData('/admin/zones', { parentId: treeNode.props.eventKey })
        .then((response: any) => {
          var responseData = each(response.data, (obj) => {
            obj.isLeaf = !obj.hasChildren
          })
          this.changedDataSource = [...this.state.allZones];
          if (responseData && !isEmpty(responseData)) {
            this.traverseTreeAndUpdateNode(this.changedDataSource, responseData, resolve);
          }
        })
        .catch(err => {
          AppUtilityService.handleApiError(err);
        })
    });
  }

  traverseTreeAndUpdateNode = (allZones, dataObj, resolve) => {
    allZones.forEach((item, index, arr) => {
      if (item.zoneId === dataObj[0].parentZoneId) {
        item.childZones = dataObj;
        this.setState({ allZones: this.changedDataSource }, () => {
          this.props.zoneSelectedCallback(this.state.selectedZoneId);
          resolve()
        });
      }
      if (item.childZones) {
        return this.traverseTreeAndUpdateNode(item.childZones, dataObj, resolve);
      }
    });
  };

}

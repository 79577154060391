import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { loadCharitiesAutocomplete } from '../../actions/dropdown/AutocompleteActions';
import { DropdownComponent } from '../../components/dropdown/DropdownComponent';
import { RootState } from '../../store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
    return {
        datasource: state.AutocompleteReducer.loadCharitiesAutocompleteDataSuccess,
        datasourceError: state.AutocompleteReducer.loadCharitiesAutocompleteDataError,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadData: bindActionCreators(loadCharitiesAutocomplete, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DropdownComponent)

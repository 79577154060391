import ApiService from "../../../common/services/ApiService";
import { createApiCaller } from '../../../common/services/ApiService';
import { fetchDataWithActionCreator } from '../../../common/store/fetched';
import { locationSettingsUpdated } from '../../actions/locationSettings/slice-actions';
import { ILocationSettings } from '../../reducers/locationSettings/LocationSettings.models';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { processFailureReason } from '../../../common/store/failure';

export const getLocationsAndSettingsListData = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const params = getState().GamesReducer.liveGamesParam;
    return await fetchDataWithActionCreator(
      locationSettingsUpdated,
      dispatch,
      createApiCaller(api => api.getData<ILocationSettings>('/locations/getAllLocationsAndSettings')),
      processFailureReason,
      true,
    );
  };
}

/*
export function getLocationsAndSettingsListData() {
  return function (dispatch) {
      dispatch({ type: 'GET_LOCATIONSETTINGS_REQUEST' });
      return ApiService.getData("/locations/getAllLocationsAndSettings")
          .then((successResult) => {
              dispatch({ type: 'GET_LOCATIONSETTINGS_SUCCESS', payload: successResult.data })
          })
          .catch((errorResult) => {
              dispatch({ type: 'GET_LOCATIONSETTINGS_FAILURE', payload: errorResult })
          });
  }
}
*/

export function updateLocationSetting(params) {
  return function (dispatch) {
    dispatch({ type: 'UPDATE_LOCATIONSETTING_REQUEST'});
    return ApiService.updateData("/locations/updateLocationSetting", params)
        .then((successResult) => {
            dispatch({ type: 'UPDATE_LOCATIONSETTING_SUCCESS', payload: successResult.data })
        })
        .catch((errorResult) => {
            dispatch({ type: 'UPDATE_LOCATIONSETTING_FAILURE', payload: errorResult})
        });
  }
}

export function getLocationsWithoutSettingsList(locationIds: Array<number>) {
  return function (dispatch) {
      dispatch({ type: 'GET_LOCATIONSWITHOUTSETTINGSGROUP_REQUEST' });
      return ApiService.updateData("/locations/getLocationsWithoutSettings/", null, locationIds)
          .then((successResult) => {
              dispatch({ type: 'GET_LOCATIONSWITHOUTSETTINGSGROUP_SUCCESS', payload: successResult.data })
          })
          .catch((errorResult) => {
              dispatch({ type: 'GET_LOCATIONSWITHOUTSETTINGSGROUP_FAILURE', payload: errorResult })
          });
  }
}

export function updateLocationsAndSettings(params) {
  return function (dispatch) {
    dispatch({ type: 'UPDATE_LOCATIONSETTINGS_REQUEST'});
    return ApiService.updateData("/locations/updateLocationsAndSettings", params)
        .then((successResult) => {
            dispatch({ type: 'UPDATE_LOCATIONSETTINGS_SUCCESS', payload: successResult.data })
        })
        .catch((errorResult) => {
            dispatch({ type: 'UPDATE_LOCATIONSETTINGS_FAILURE', payload: errorResult})
        });
  }
}

export function updateBulkLocationsAndSettings(params) {
    return function (dispatch) {
        dispatch({ type: 'UPDATE_BULKLOCATIONSETTINGS_REQUEST' });
        return ApiService.updateData("/locations/updateBulkLocationsAndSettings", params)
            .then((successResult) => {
                dispatch({ type: 'UPDATE_BULKLOCATIONSETTINGS_SUCCESS', payload: successResult.data })
            })
            .catch((errorResult) => {
                dispatch({ type: 'UPDATE_BULKLOCATIONSETTINGS_FAILURE', payload: errorResult })
            });
    }
}


import { isEmpty, map, startCase, omit, filter, cloneDeep, pick } from "lodash";
import AppUtilityService from "./AppUtilityService";
import html2canvas from 'html2canvas';
import appConstants from "../AppConstants";

var jsPDF = require('jspdf');
require('jspdf-autotable');
var canvg = require('canvg');

var fileDownload = require('js-file-download');

var Highcharts = require('highcharts');
var exporting = require('highcharts/modules/exporting.src');
exporting(Highcharts);

var XLSX = require('xlsx');

class ReportsUtilityService {

    getWorksheetColumn = (index) => {
        var str = "abcdefghijklmnopqrstuvwxyz";
        var alphaArray = str.toUpperCase().split("");
        return alphaArray[index];
    };

    formatWorksheetColumns = (worksheet, columnArr, dataArray, counter) => {
        columnArr.forEach((keyObj, index) => {
            var excelCol = this.getWorksheetColumn(index);
            var count = counter;
            dataArray.forEach((dataObj) => {
                switch (keyObj) {
                    case "cIn":
                    case "cOut":
                    case "cNet":
                    case "won":
                    case "pwNet":
                    case "played":
                    case "liability":
                    case "prchPrice":
                    case "avgDailyNetWin":
                    case "cipupd":
                    case "wipupd":
                    case "averageBet":
                        worksheet[excelCol + count.toString()] = {
                            v: dataObj[keyObj],
                            z: '[$$-409]#,##0.00;[RED]-[$$-409]#,##0.00',
                            t: 'n'
                        };
                        break;
                    case "playCnt":
                    case "gameSize":
                    case "tabsRem":
                        worksheet[excelCol + count.toString()] = {
                            v: dataObj[keyObj],
                            z: "#,##0;[RED]-#,##0",
                            t: 'n'
                        };
                        break;
                    case "ppw":
                        worksheet[excelCol + count.toString()] = {
                            v: Math.floor(dataObj[keyObj] * 100) / 100 / 100, //stop rounding
                            z: "0.00%;[RED]-0.00%",
                            t: 'n'
                        };
                        break;
                    case "tpPercent":
                        worksheet[excelCol + count.toString()] = {
                            v: dataObj[keyObj] / 100,
                            z: "0.00%;[RED]-0.00%",
                            t: 'n'
                        };
                        break;
                    default: break;
                }
                count++;
            });
        });
    };

    formatPDFSheetColumns = (columnArr, dataArr) =>{
        columnArr.forEach((keyObj, index) => {
            dataArr.forEach((dataObj, index1) => {
                switch (keyObj) {
                    case "prchPrice":
                        var value = AppUtilityService.formatAmountAndShorten(parseFloat(dataObj[keyObj]), 2);
                        dataObj[keyObj] = value;

                        break;
                    default: break;
                }
            });
        });

    };

    downloadReconSummaryPDF = (reportType, exportListData, devExColumnData, summaryData, chartsList, additionTabChart?) => {
        var margin = 30;
        var doc = new jsPDF('l', 'pt');  //l- landscape orientation of first page, pt-points measurement unit to be used when coordinates are specified
        doc.setFontSize(16);
        doc.setTextColor("#656C8A");
        doc.setFontStyle('normal');

        var verticalPosition = 50;

        var pageWidth = doc.internal.pageSize.getWidth();
        var horizontalPosition = 30;
        var pageCenterPoint = pageWidth / 2; // Returns center point of page to plot second chart on a page
        if (reportType === "ByDate" && additionTabChart) {
          doc.text(`Recon By Date Report`, margin, margin);
            doc.text("Data Compare", margin, margin+16);  //add font size to margin to move down a line
            let compareChartDOM = document.getElementById("chart_container");
            let chart = Highcharts.charts[Highcharts.attr(compareChartDOM, 'data-highcharts-chart')];
            var chartSVG = chart.getSVG({
                chart: {
                    width: pageWidth
                }
            });
            var chartCanvas = document.createElement('canvas');
            canvg(chartCanvas, chartSVG);
            var chartImgData = chartCanvas.toDataURL('image/png');
            doc.addImage(chartImgData, 'PNG', horizontalPosition, verticalPosition);
            doc.addPage();
            var categoryDataLength = filter(map(additionTabChart, (obj) => { return obj.category.length > 0 }), (o) => { return o === true });
            if (!isEmpty(categoryDataLength)) {
                additionTabChart.forEach((obj, index) => {
                    var category = cloneDeep(obj.category);
                    var metricTypeHeader = filter(appConstants.reportDataCompareMetrics, (o) => { return obj.metricType === o.value })[0].key;
                    switch (obj.metricType) {
                        case "cashIn":
                        case "cashNet":
                        case "played":
                            category = filter(category, (o) => { return o.value = AppUtilityService.formatAmount(o.value) });
                            break;
                        case "holdPercentage":
                            category = filter(category, (o) => { return o.value = (AppUtilityService.shortenLargeNumber(o.value, 2) + "%") });
                            break;
                        default: break;
                    }
                    if (category && !isEmpty(category)) {
                        doc.autoTable({
                            columnStyles: {
                                'key': { cellWidth: 200 },
                                'value': { cellWidth: 200 },
                            },
                            tableWidth: 400,
                            body: category,
                            columns: [{ header: 'Date', dataKey: 'key' }, { header: startCase(metricTypeHeader), dataKey: 'value' }]
                        });
                    }
                });
                doc.addPage();
            }
        }
        if (reportType === "ByGame" && additionTabChart) {
            doc.text(`Recon By Game Report`, margin, margin);
            doc.text(`Graphical representation of Play count gross play`, margin, margin+16);  //add font size to margin to move down a line
            let compareChartDOM = document.getElementById("breakdownByGame");
            if (compareChartDOM) {
                let chart = Highcharts.charts[Highcharts.attr(compareChartDOM, 'data-highcharts-chart')];
                var chartSVG = chart.getSVG();
                var chartCanvas = document.createElement('canvas');
                canvg(chartCanvas, chartSVG);
                var chartImgData = chartCanvas.toDataURL('image/svg');

                doc.addImage(chartImgData, 'PNG', horizontalPosition, verticalPosition);
                doc.addPage();

                additionTabChart.data = filter(additionTabChart.data, (o) => { return o.y = additionTabChart.gameType === "played" ? AppUtilityService.formatAmount(o.y) : o.y });

                if (additionTabChart.data && !isEmpty(additionTabChart.data)) {
                    doc.autoTable({
                        columnStyles: {
                            'name': { cellWidth: 200 },
                            'y': { cellWidth: 200 },
                        },
                        tableWidth: 400,
                        body: additionTabChart.data,
                        columns: [{ header: 'Game', dataKey: 'name' }, { header: startCase(additionTabChart.gameType), dataKey: "y" }]
                    });
                }
                doc.addPage();
            }
        }

        if (chartsList && !isEmpty(chartsList)) {
            chartsList.forEach((obj, index) => {
                // Getting SVG of the chart
                let rptType = reportType === 'ByGT' ? 'By GT' : 'By Date';
                doc.text(`Recon ${rptType} Report`, margin, margin);
                doc.text("Graphical representation of selected time period", margin, margin+16);  //add fontsize to move it down a line
                if (chartsList.length === 1) {
                    // If there is one chart, plot it at the center
                    horizontalPosition = pageCenterPoint - 210;
                }
                let chartDOM = document.getElementById("chart" + index);
                let chart = Highcharts.charts[Highcharts.attr(chartDOM, 'data-highcharts-chart')];
                var chartSVG = chart.getSVG();
                var chartCanvas = document.createElement('canvas');
                canvg(chartCanvas, chartSVG);
                var chartImgData = chartCanvas.toDataURL('image/png');
                doc.addImage(chartImgData, 'PNG', horizontalPosition, verticalPosition);
                //Chart legend autotable
                doc.autoTable({
                    html: '#chartTabularLegend' + index,
                    margin: { horizontal: horizontalPosition + 70 },
                    startY: (verticalPosition + chartCanvas.height) - 80,
                    theme: "plain",
                    tableWidth: 300,
                    bodyStyles: { valign: 'top' },
                    didParseCell: function (cell, opts) {
                        cell.cell.styles.textColor = "#A6ADC9";
                    },
                });
                if ((pageWidth - horizontalPosition) <= 420.945) {
                    // If most space of the page is utilised horizontally, add a new page
                    horizontalPosition = 30;
                    doc.addPage();
                } else {
                    // Else, plot the next chart item at the center point of the page
                    horizontalPosition = pageCenterPoint;
                }
            });

            if (doc.previousAutoTable.pageStartX < 420.945) {
                // If the vertical space isn't sufficient to plot new items, add a page
                doc.addPage();
            }
        }

        doc.text("Summary", margin, margin);
        var summaryTableColumnData = [
            { header: "", dataKey: 'name' },
            { header: "", dataKey: 'y' }
        ];
        var summaryTableData = map(summaryData, (obj: any) => { return { name: obj.key, y: obj.value } });
        doc.autoTable({
            columns: summaryTableColumnData,
            body: summaryTableData,
            margin: margin,
            startY: verticalPosition,
            theme: "plain",
            bodyStyles: { valign: 'top' },
            columnStyles: {
                'name': { cellWidth: 100 },
            }

        });
        doc.addPage();

        // ALL PAGES RELATED
        doc.text("Detailed view of selected time period", margin, margin);
        var tableColumnData = [];
        devExColumnData.columns.forEach((obj) => {
            tableColumnData.push({ header: obj.caption, dataKey: obj.dataField });
        });
        doc.autoTable({
            columns: tableColumnData,
            body: exportListData.exportList,
            theme: "striped",
            margin: { left: margin, right: margin, bottom: margin },
            styles: { overflow: 'linebreak' },
            bodyStyles: { valign: 'top' },

            didDrawPage: function (data) {
                if (data.pageNumber !== 0) {
                    data.settings.margin = margin;
                }
            },
        });
        if (exportListData.exportList && !isEmpty(exportListData.exportList)) {
            var tableChildColumnData = [];
            devExColumnData.childColumns.forEach((obj) => {
                tableChildColumnData.push({ header: obj.caption, dataKey: obj.dataField });
            });
            exportListData.exportList.forEach((obj, index) => {
                if (obj.summaryList && !isEmpty(obj.summaryList)) {
                    doc.addPage();
                    switch (reportType) {
                        case "ByDate":
                            doc.text("Location: " + obj.lName, margin, margin);
                            break;
                        case "ByGame":
                            doc.text("Game: " + obj.game, margin, margin);
                            break;
                        case "ByGT":
                            doc.text("Game Terminal: " + obj.cName, margin, margin);
                            break;
                        default: break;
                    }
                    doc.autoTable({
                        columns: tableChildColumnData,
                        body: obj.summaryList,
                        theme: "striped",
                        margin: { left: margin, right: margin, bottom: margin },
                        styles: { overflow: 'linebreak' },
                        bodyStyles: { valign: 'top' },
                        didDrawPage: function (data) {
                            if (data.pageNumber !== 0) {
                                data.settings.margin = margin;
                            }
                        },
                    });
                }
            });
        }
        var filename = "Recon Summary - " + startCase(reportType) + '.pdf';
        doc.save(filename);
    };

    downloadReconSummarySinceLastIPRPDF = (exportListData, devExColumnData, summaryData) => {
      var margin = 30;
      var doc = new jsPDF('l', 'pt');
      doc.setFontSize(16);
      doc.setTextColor("#656C8A");
      doc.setFontStyle('normal');

      var verticalPosition = 50;

      var pageWidth = doc.internal.pageSize.getWidth();
      var horizontalPosition = 30;
      var pageCenterPoint = pageWidth / 2; // Returns center point of page to plot second chart on a page

      // ALL PAGES RELATED
      doc.text("Detailed view of selected time period", margin, margin);
      var tableColumnData = [];
      devExColumnData.columns.forEach((obj) => {
          tableColumnData.push({ header: obj.caption, dataKey: obj.dataField });
      });
      doc.autoTable({
          columns: tableColumnData,
          body: exportListData.exportList,
          theme: "striped",
          margin: { left: margin, right: margin, bottom: margin },
          styles: { overflow: 'linebreak' },
          bodyStyles: { valign: 'top' },
          didDrawPage: function (data) {
              if (data.pageNumber !== 0) {
                  data.settings.margin = margin;
              }
          },
      });
      if (exportListData.exportList && !isEmpty(exportListData.exportList)) {
          var tableChildColumnData = [];
          devExColumnData.childColumns.forEach((obj) => {
              tableChildColumnData.push({ header: obj.caption, dataKey: obj.dataField });
          });
          exportListData.exportList.forEach((obj, index) => {
              if (obj.summaryList && !isEmpty(obj.summaryList)) {
                  doc.addPage();
                  doc.text("Location: " + obj.lName, margin, margin);

                  doc.autoTable({
                      columns: tableChildColumnData,
                      body: obj.summaryList,
                      theme: "striped",
                      margin: { left: margin, right: margin, bottom: margin },
                      styles: { overflow: 'linebreak' },
                      bodyStyles: { valign: 'top' },
                      didDrawPage: function (data) {
                          if (data.pageNumber !== 0) {
                              data.settings.margin = margin;
                          }
                      },
                  });
              }
          });
      }
      var filename = "Recon Summary - By Date Since Last IPR.pdf";
      doc.save(filename);
  };

    downloadReconSummaryExcel = (reportType, exportListData, devExColumnData, reconSummaryInfo, dateRange) => {
        var workbook = XLSX.utils.book_new();

        var childSheetColKeys = map(devExColumnData.childColumns, 'dataField');
        var childSheetColNames = map(devExColumnData.childColumns, 'caption');

        var sheet1ColKeys = map(devExColumnData.columns, 'dataField');
        var sheet1DataSet = map(exportListData.exportList, o => pick(o, sheet1ColKeys));
        var sheet1ColNames = map(devExColumnData.columns, 'caption');

        if (sheet1DataSet && !isEmpty(sheet1DataSet)) {
            var worksheet = XLSX.utils.sheet_add_aoa(worksheet, [["Report: ", "Recon Summary - " + startCase(reportType)]], { origin: "A1" });
            XLSX.utils.sheet_add_aoa(worksheet, [["Distributor: ", reconSummaryInfo && reconSummaryInfo.distributors]], { origin: "A2" });
            XLSX.utils.sheet_add_aoa(worksheet, [["Charity: ", reconSummaryInfo && reconSummaryInfo.charities]], { origin: "A3" });
            // Get location length and subtract 2 since total and avg rows are included
            XLSX.utils.sheet_add_aoa(worksheet, [["Location: ", (sheet1DataSet.length - 2)]], { origin: "A4" });
            XLSX.utils.sheet_add_aoa(worksheet, [["From: ", AppUtilityService.formatDate(dateRange[0])]], { origin: "F1" });
            XLSX.utils.sheet_add_aoa(worksheet, [["To: ", AppUtilityService.formatDate(dateRange[1])]], { origin: "F2" });
            if (reportType === "ByDate" && exportListData.summary) {
                var sheet1SummaryColsKeys = ["date", "playCnt", "cIn", "cOut", "cNet", "played", "won", "ppw", "pwNet", "liability"];
                XLSX.utils.sheet_add_aoa(worksheet, [["Date", "Play Count", "Cash In", "Cash Out", "Cash Net", "Played", "Won", "PW %", "PW Net", "Liability"]], { origin: "A6" });
                XLSX.utils.sheet_add_json(worksheet, exportListData.summary, {
                    header: sheet1SummaryColsKeys,
                    skipHeader: true,
                    origin: "A7",
                });

                this.formatWorksheetColumns(worksheet, sheet1SummaryColsKeys, exportListData.summary, 7);

                XLSX.utils.sheet_add_aoa(worksheet, [sheet1ColNames], { origin: "A" + (7 + exportListData.summary.length + 2) });

                var wsColWidths = [];
                sheet1ColNames.forEach((obj) => {
                    wsColWidths.push({ wpx: 80 });
                });
                worksheet['!cols'] = wsColWidths;

                XLSX.utils.sheet_add_json(worksheet, sheet1DataSet, {
                    header: sheet1ColKeys,
                    skipHeader: true,
                    origin: "A" + (7 + exportListData.summary.length + 3),
                });
                this.formatWorksheetColumns(worksheet, sheet1ColKeys, sheet1DataSet, (7 + exportListData.summary.length + 3));
            } else {
                XLSX.utils.sheet_add_aoa(worksheet, [sheet1ColNames], { origin: "A6" });

                var wsColWidths = [];
                sheet1ColNames.forEach((obj) => {
                    wsColWidths.push({ wpx: 80 });
                });
                worksheet['!cols'] = wsColWidths;


                XLSX.utils.sheet_add_json(worksheet, sheet1DataSet, {
                    header: sheet1ColKeys,
                    skipHeader: true,
                    origin: "A7",
                });
                this.formatWorksheetColumns(worksheet, sheet1ColKeys, sheet1DataSet, 7);
            }
            XLSX.utils.book_append_sheet(workbook, worksheet, "Summary");
        }


        if (exportListData.exportList && !isEmpty(exportListData.exportList)) {
            exportListData.exportList.forEach((obj, index) => {
                if (obj.summaryList && !isEmpty(obj.summaryList)) {
                    var worksheet_child = XLSX.utils.sheet_add_aoa(worksheet_child, [["Report: ", "Recon Summary - " + startCase(reportType)]], { origin: "A1" });
                    XLSX.utils.sheet_add_aoa(worksheet_child, [["Distributor: ", obj.distributors]], { origin: "A2" });
                    XLSX.utils.sheet_add_aoa(worksheet_child, [["Charity: ", obj.charities]], { origin: "A3" });
                    XLSX.utils.sheet_add_aoa(worksheet_child, [["From: ", AppUtilityService.formatDate(dateRange[0])]], { origin: "F1" });
                    XLSX.utils.sheet_add_aoa(worksheet_child, [["To: ", AppUtilityService.formatDate(dateRange[1])]], { origin: "F2" });
                    XLSX.utils.sheet_add_aoa(worksheet_child, [childSheetColNames], { origin: "A6" });

                    var wsColWidths = [];
                    childSheetColNames.forEach((obj) => {
                        wsColWidths.push({ wpx: 80 });
                    });
                    worksheet_child['!cols'] = wsColWidths;
                    let sheetName = "";
                    switch (reportType) {
                        case "ByDate":
                            XLSX.utils.sheet_add_aoa(worksheet_child, [["Average Daily Cash Net: ", obj.adCashNet]], { origin: "K1" });
                            worksheet_child["L1"] = { v: obj.adCashNet, z: "$#,##0.00", t: 'n' }
                            XLSX.utils.sheet_add_aoa(worksheet_child, [["Location: ", obj.lName]], { origin: "A4" });
                            obj.summaryList = map(obj.summaryList, o => omit(o, ['lName', 'lId'])); // Remove columns that shouldn't show in sheets
                            let sheetNameLength = 31 - obj.lId.toString().length - 1;
                            sheetName = obj.lName.substring(0, sheetNameLength) + "-" + obj.lId.toString();
                            break;
                        case "ByGame":
                            XLSX.utils.sheet_add_aoa(worksheet_child, [["Game: ", obj.game]], { origin: "A4" });
                            obj.summaryList = map(obj.summaryList, o => omit(o, ['gId', 'game', 'liability'])); // Remove columns that shouldn't show in sheets
                            sheetName = obj.game.substring(0, 31);
                            break;
                        case "ByGT":
                            obj.summaryList = map(obj.summaryList, o => omit(o, ['cName', 'dailyNet'])); // Remove columns that shouldn't show in sheets
                            sheetName = obj.cName.substring(0, 31);
                            break;
                        default: break;
                    }
                    XLSX.utils.sheet_add_json(worksheet_child, obj.summaryList, {
                        header: childSheetColKeys,
                        skipHeader: true,
                        origin: "A7"
                    });
                    this.formatWorksheetColumns(worksheet_child, childSheetColKeys, obj.summaryList, 7);
                    XLSX.utils.book_append_sheet(workbook, worksheet_child, sheetName);
                }
            });
        }
        var filename = "Recon Summary - " + startCase(reportType) + '.xlsx';
        var wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array', cellStyles: true });
        fileDownload(new Blob([wbout], { type: "application/octet-stream" }), filename);
    };

    downloadReconSummarySinceLastIPRExcel = (exportListData, devExColumnData, reconSummaryInfo) => {
      var workbook = XLSX.utils.book_new();

      var childSheetColKeys = map(devExColumnData.childColumns, 'dataField');
      var childSheetColNames = map(devExColumnData.childColumns, 'caption');

      var sheet1DataSet = map(exportListData.exportList, o => omit(o, ['adCashNet', 'adnWperGT', 'charities', 'distributors', 'summaryList', 'qtygts', 'totalCount', 'gId', 'dailyNet', 'numberTerminals'])); // Remove columns that shouldn't show in sheets
      var sheet1ColKeys = map(devExColumnData.columns, 'dataField');
      var sheet1ColNames = map(devExColumnData.columns, 'caption');

      if (sheet1DataSet && !isEmpty(sheet1DataSet)) {
          var worksheet = XLSX.utils.sheet_add_aoa(worksheet, [["Report: ", "Recon Summary - By Date Since Last IPR" ]], { origin: "A1" });
          // Get location length and subtract 2 since total and avg rows are included
            XLSX.utils.sheet_add_aoa(worksheet, [["Location: ", (sheet1DataSet.length - 2)]], { origin: "A4" });

          if (exportListData && exportListData.summary) {
              var sheet1SummaryColsKeys = ["date", "playCnt", "cIn", "cOut", "cNet", "played", "won", "ppw", "pwNet", "liability"];
              XLSX.utils.sheet_add_aoa(worksheet, [["Date", "Play Count", "Cash In", "Cash Out", "Cash Net", "Played", "Won", "PW %", "PW Net", "Liability"]], { origin: "A6" });
              XLSX.utils.sheet_add_json(worksheet, exportListData.summary, {
                  header: sheet1SummaryColsKeys,
                  skipHeader: true,
                  origin: "A7",
              });

              this.formatWorksheetColumns(worksheet, sheet1SummaryColsKeys, exportListData.summary, 7);

              XLSX.utils.sheet_add_aoa(worksheet, [sheet1ColNames], { origin: "A" + (7 + exportListData.summary.length + 2) });

              var wsColWidths = [];
              sheet1ColNames.forEach((obj) => {
                  wsColWidths.push({ wpx: 80 });
              });
              worksheet['!cols'] = wsColWidths;

              XLSX.utils.sheet_add_json(worksheet, sheet1DataSet, {
                  header: sheet1ColKeys,
                  skipHeader: true,
                  origin: "A" + (7 + exportListData.summary.length + 3),
              });
              this.formatWorksheetColumns(worksheet, sheet1ColKeys, sheet1DataSet, (7 + exportListData.summary.length + 3));
          } else {
              XLSX.utils.sheet_add_aoa(worksheet, [sheet1ColNames], { origin: "A6" });

              var wsColWidths = [];
              sheet1ColNames.forEach((obj) => {
                  wsColWidths.push({ wpx: 80 });
              });
              worksheet['!cols'] = wsColWidths;


              XLSX.utils.sheet_add_json(worksheet, sheet1DataSet, {
                  header: sheet1ColKeys,
                  skipHeader: true,
                  origin: "A7",
              });
              this.formatWorksheetColumns(worksheet, sheet1ColKeys, sheet1DataSet, 7);
          }
          XLSX.utils.book_append_sheet(workbook, worksheet, "Summary");
      }

      if (exportListData.exportList && !isEmpty(exportListData.exportList)) {
          exportListData.exportList.forEach((obj, index) => {
              if (obj.summaryList && !isEmpty(obj.summaryList)) {
                  var worksheet_child = XLSX.utils.sheet_add_aoa(worksheet_child, [["Report: ", "Recon Summary - By Date Since Last IPR"]], { origin: "A1" });
                  XLSX.utils.sheet_add_aoa(worksheet_child, [["Distributor: ", obj.distributors]], { origin: "A2" });
                  XLSX.utils.sheet_add_aoa(worksheet_child, [["Charity: ", obj.charities]], { origin: "A3" });
                  XLSX.utils.sheet_add_aoa(worksheet_child, [childSheetColNames], { origin: "A6" });

                  var wsColWidths = [];
                  childSheetColNames.forEach((obj) => {
                      wsColWidths.push({ wpx: 80 });
                  });
                  worksheet_child['!cols'] = wsColWidths;
                  let sheetName = "";

                  XLSX.utils.sheet_add_aoa(worksheet_child, [["Average Daily Cash Net: ", obj.adCashNet]], { origin: "K1" });
                  worksheet_child["L1"] = { v: obj.adCashNet, z: "$#,##0.00", t: 'n' }
                  XLSX.utils.sheet_add_aoa(worksheet_child, [["ADNW per GT: ", obj.adnWperGT]], { origin: "K2" });
                  worksheet_child["L2"] = { v: obj.adnWperGT, z: "$#,##0.00", t: 'n' }
                  XLSX.utils.sheet_add_aoa(worksheet_child, [["QTY of GTs: ", obj.qtygts]], { origin: "K3" });
                  worksheet_child["L3"] = { v: obj.qtygts, z: "#,##0", t: 'n' }
                  XLSX.utils.sheet_add_aoa(worksheet_child, [["Location: ", obj.lName]], { origin: "A4" });
                  obj.summaryList = map(obj.summaryList, o => omit(o, ['lName', 'lId'])); // Remove columns that shouldn't show in sheets
                  let sheetNameLength = 31 - obj.lId.toString().length - 1;
                  sheetName = obj.lName.substring(0, sheetNameLength) + "-" + obj.lId.toString();

                  XLSX.utils.sheet_add_json(worksheet_child, obj.summaryList, {
                      header: childSheetColKeys,
                      skipHeader: true,
                      origin: "A7"
                  });
                  this.formatWorksheetColumns(worksheet_child, childSheetColKeys, obj.summaryList, 7);
                  XLSX.utils.book_append_sheet(workbook, worksheet_child, sheetName);
              }
          });
      }
      var filename = "Recon Summary - By Date Since Last IPR" + '.xlsx';
      var wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array', cellStyles: true });
      fileDownload(new Blob([wbout], { type: "application/octet-stream" }), filename);
  };
    downloadDealsPDF = (reportType, exportListData, devExColumnData) => {
        var margin = 30;
        var doc = new jsPDF('l', 'pt');
        doc.setFontSize(16);
        doc.setTextColor("#656C8A");
        doc.setFontStyle('normal');

        var verticalPosition = 50;

        var pageWidth = doc.internal.pageSize.getWidth();
        var horizontalPosition = 30;

        let rptType = reportType === 'DealsCompleted' ? 'Deals Completed' : reportType === 'DealsInPlay' ? 'Deals In Play' : 'Deals Available';
        doc.text(`${rptType} Report`, margin, margin);
        doc.text("Graphical representation of selected time period", margin, margin+16);
        // Getting SVG of the chart
        let chartDOM = document.getElementById("deals-chart");
        let chart = Highcharts.charts[Highcharts.attr(chartDOM, 'data-highcharts-chart')];
        chart.options.plotOptions.series.dataLabels.enabled = true;
        var chartSVG = chart.getSVG({
            chart: {
                width: pageWidth
            }
        });
        var chartCanvas = document.createElement('canvas');
        canvg(chartCanvas, chartSVG);
        var chartImgData = chartCanvas.toDataURL('image/png');
        doc.addImage(chartImgData, 'PNG', horizontalPosition, verticalPosition);
        doc.addPage();


        // ALL PAGES RELATED
        doc.text("Detailed view of selected time period", margin, margin);
        var tableColumnData = [];
        devExColumnData.columns.forEach((obj) => {
            tableColumnData.push({ header: obj.caption, dataKey: obj.dataField });
        });

        var sheet1ColKeys = map(devExColumnData.columns, 'dataField');
        this.formatPDFSheetColumns(sheet1ColKeys, exportListData);
        doc.autoTable({
            columns: tableColumnData,
            body: exportListData,
            theme: "striped",
            margin: { left: margin, right: margin, bottom: margin },
            styles: { overflow: 'linebreak' },
            bodyStyles: { valign: 'top' },
            didDrawPage: function (data) {
                if (data.pageNumber !== 0) {
                    data.settings.margin = margin;
                }
            },
        });
        var filename = "Deals - " + startCase(reportType) + '.pdf';
        doc.save(filename);
    };

    downloadDealsExcel = (reportType, dateRange, exportListData, devExColumnData) => {
        var workbook = XLSX.utils.book_new();
        var sheet1DataSet = exportListData.summary;
        var sheet1ColKeys = map(devExColumnData.columns, 'dataField');
        var sheet1ColNames = map(devExColumnData.columns, 'caption');

        if (sheet1DataSet && !isEmpty(sheet1DataSet)) {
            var worksheet = XLSX.utils.sheet_add_aoa(worksheet, [["Report: ", "Deals - " + startCase(reportType)]], { origin: "A1" });
            XLSX.utils.sheet_add_aoa(worksheet, [["From: ", AppUtilityService.formatDate(dateRange[0])]], { origin: "A2" });
            XLSX.utils.sheet_add_aoa(worksheet, [["To: ", AppUtilityService.formatDate(dateRange[1])]], { origin: "A3" });

            if (exportListData) {
                XLSX.utils.sheet_add_aoa(worksheet, [sheet1ColNames], { origin: "A6" });
                var wsColWidths = [];
                sheet1ColNames.forEach((obj) => {
                    wsColWidths.push({ wpx: 100 });
                });
                worksheet['!cols'] = wsColWidths;

                XLSX.utils.sheet_add_json(worksheet, sheet1DataSet, {
                    header: sheet1ColKeys,
                    skipHeader: true,
                    origin: "A7",
                });
                this.formatWorksheetColumns(worksheet, sheet1ColKeys, sheet1DataSet, 7);
            }
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        }

        var filename = "Deals - " + startCase(reportType) + '.xlsx';
        var wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array', cellStyles: true });
        fileDownload(new Blob([wbout], { type: "application/octet-stream" }), filename);
    };

    downloadGameTerminalStatusPDF = (dataset, devExColumnData) => {
        var margin = 30;
        var doc = new jsPDF('l', 'pt');
        doc.setFontSize(16);
        doc.setTextColor("#656C8A");
        doc.setFontStyle('normal');

        var verticalPosition = 50;

        /*---------------------------
        Only in landscape
        */
        var pageWidth = doc.internal.pageSize.getWidth();
        var horizontalPosition = 30;

        doc.text("Status Report", margin, margin);

        html2canvas(document.getElementById("stat-cards"), {
            scale: 1,
        }).then(canvas => {
            var img = canvas.toDataURL("image/jpeg", 1.0);
            doc.addImage(img, 'JPEG', horizontalPosition, verticalPosition, (pageWidth - 60), 150);
            // ALL PAGES RELATED
            var tableColumnData = [];
            devExColumnData.columns.forEach((obj) => {
                tableColumnData.push({ title: obj.caption, dataKey: obj.dataField });
            });
            doc.autoTable(tableColumnData, dataset, {
                startY: (canvas.height + 50),
                theme: "striped",
                margin: { horizontal: margin },
                styles: { overflow: 'linebreak' },
                bodyStyles: { valign: 'top' },
            });
            var filename = "Game Terminal Status.pdf";
            doc.save(filename);
        });
    };

    downloadGameTerminalStatusExcel = (dateRange, exportListData, devExColumnData) => {
        var workbook = XLSX.utils.book_new();
        var sheet1DataSet = exportListData;
        var sheet1ColKeys = map(devExColumnData.columns, 'dataField');
        var sheet1ColNames = map(devExColumnData.columns, 'caption');

        if (sheet1DataSet && !isEmpty(sheet1DataSet)) {
            var worksheet = XLSX.utils.sheet_add_aoa(worksheet, [["Report: ", "Game Terminal Status"]], { origin: "A1" });
            XLSX.utils.sheet_add_aoa(worksheet, [["From: ", AppUtilityService.formatDate(dateRange[0])]], { origin: "A2" });
            XLSX.utils.sheet_add_aoa(worksheet, [["To: ", AppUtilityService.formatDate(dateRange[1])]], { origin: "A3" });

            if (exportListData) {
                XLSX.utils.sheet_add_aoa(worksheet, [sheet1ColNames], { origin: "A6" });
                var wsColWidths = [];
                sheet1ColNames.forEach((obj) => {
                    wsColWidths.push({ wpx: 100 });
                });
                worksheet['!cols'] = wsColWidths;

                XLSX.utils.sheet_add_json(worksheet, sheet1DataSet, {
                    header: sheet1ColKeys,
                    skipHeader: true,
                    origin: "A7",
                });
                this.formatWorksheetColumns(worksheet, sheet1ColKeys, sheet1DataSet, 7);
            }
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        }

        var filename = "Game Terminal Status.xlsx";
        var wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array', cellStyles: true });
        fileDownload(new Blob([wbout], { type: "application/octet-stream" }), filename);
    };
}

const reportsUtilityService = new ReportsUtilityService();
export default reportsUtilityService;

import { IColumnProps } from "devextreme-react/data-grid";

/**
 * Summary rows require additional fields in column definitions to work.
 * This function maps a column to an extended version to include the
 * extra fields.
 */
export const addSummaryFieldsToColumnProps = (x: IColumnProps) => ({
  ...x,
  column: x.dataField,
  displayFormat: x.dataType === 'number' ? '{0}' : '',
  valueFormat: x.format,
});

import { downloadExcelLocationUninstallAlertReportUpdated } from './slice-actions';
import apiService from '../../../common/services/ApiService';
import { createStandardFetchedAction } from '../../../common/store/fetched.slices';
import { Moment } from 'moment';
import AppConstants from '../../../common/AppConstants';
import { DownloadedFile, saveFile } from '../../../common/store/fileDownload';

export const downloadExcelLocationUninstallAlertReport = (
  dateFrom: Moment,
  dateTo: Moment,
  zoneId?: number,
  installed?: boolean,
) => createStandardFetchedAction(
  downloadExcelLocationUninstallAlertReportUpdated,
  async () => {
    const result = await apiService.downloadFile(
      `/reports/location-un-install-alert-report/export/excel/${dateFrom.format(AppConstants.apiDateFormat)}/${dateTo.format(AppConstants.apiDateFormat)}`,
      { zoneId, installed });

    const fileName = result.headers['x-filename'];
    const file: DownloadedFile = {
      data: result.data,
      fileName,
    };
    saveFile(file)
  },
  false,
);

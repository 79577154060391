import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as BreadcrumbActions from '../../../../common/actions/breadcrumbs/BreadcrumbActions';
import { SSRSReportPage } from '../../../components/reports/ssrs/SSRSReportPage';
import { RootState } from '../../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(BreadcrumbActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SSRSReportPage)

import React from 'react';
import { Row, Col, Typography } from 'antd';
import appUtilityService from '../../../common/services/AppUtilityService';
import { useAppSelector } from '../../../common/hooks/storeHooks';
import { LocationInvoicingConfigItem } from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';

const { Text } = Typography;

export type Props = {
  item: LocationInvoicingConfigItem;
  localCharityId?: number | 'NONE';
};

const BillingAddress = (
  { item, localCharityId }: Props,
) => {
  const location = useAppSelector(state => state.LocationsReducer.loadBasicDetailsSuccess);
  const locationCharity = useAppSelector(state =>
    state.LocationInvoicingConfigReducer.locationCharities?.data
      ?.find(c => c.localCharityId === localCharityId));

  const locationName = localCharityId == 'NONE' ? item.locationName : locationCharity?.name;
  const {
    address1,
    address2,
    city,
    addressState,
    zipcode,
  } = (localCharityId == 'NONE' ? location : locationCharity) ?? {};

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Text strong>Billing Address (displayed on invoice):</Text>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {
            [
              locationName,
              address1,
              address2,
              appUtilityService.displayStringFromArray([city, addressState, zipcode]),
            ]
              .filter(line => !!line?.trim())
              .map((line, i) => (<div key={`address${i}`}>{line}</div>))
          }
        </Col>
      </Row>
    </>
  );
};

export default BillingAddress;

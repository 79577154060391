import { downloadNorthDakotaGameSuggestionsReportUpdated } from "./slice-actions";
import { DownloadedFile, saveFile } from '../../../common/store/fileDownload';
import apiService from '../../../common/services/ApiService';
import { createStandardFetchedAction } from '../../../common/store/fetched.slices';

export const downloadNorthDakotaGameSuggestionsReport = () =>
  createStandardFetchedAction(
    downloadNorthDakotaGameSuggestionsReportUpdated,
    async () => {
      const result = await apiService.downloadFile('/reports/gameSuggestions/excel');

      const fileName = result.headers['x-filename'];
      const file: DownloadedFile = {
        data: result.data,
        fileName,
      };
      saveFile(file);
    },
    false
  );

import * as React from "react"
import { Row, Col, Collapse, Typography } from "antd"
import { IGame, IQuarterCloseSelectedBet } from "../../reducers/quarterClose/QuarterCloseReducer"
import { hashHistory } from "react-router";
const syles = require('../../../common/styles/GameSelectStyles.css')
const removeButton = require("../../../images/Button_Remove.png")
const missingIcon = require("../../../images/Missing_Icon.png")

const { Panel } = Collapse;
const { Text } = Typography;

export interface IQuarterCloseReviewGameComponentProps {
    game?: IGame,
    editable: boolean,
    onRemoveGame: (gameID: number) => void,
    updateSelectedGameID: (gameID: number) => void,
    displayRemoveOnLeft: boolean,
    quarterCloseSelectedBet?: IQuarterCloseSelectedBet,
}


export default class QuarterCloseReviewGameComponent extends React.Component<IQuarterCloseReviewGameComponentProps> {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <Row>
                <Col span={4}>
                  {
                    <Col span={5} style={{ height: "140px", alignItems: "center", alignContent: "center", display: "flex", paddingRight: "40px" }}>
                        <div style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
                            {this.props.game && this.props.editable &&
                            <img className="selectionReviewRemoveButton" src={removeButton} onClick={() => { this.props.onRemoveGame(this.props.game.gameID) }} />
                            }
                        </div>
                    </Col>
                }
                    <div style={{ display: "flex", flexWrap: "wrap", paddingLeft: "25px", paddingRight: "10px" }}>
                        {this.props.game &&
                            <section style={{ position: "absolute" }}>
                                {this.props.editable &&
                                    <div className={"selectionReviewEditText"} style={{ position: "absolute", bottom: "18px", left: "40px" }} onClick={() => {
                                        this.props.updateSelectedGameID(this.props.game.gameID)
                                        hashHistory.push('/quarterClose/gameDetails')
                                    }} >
                                        EDIT
                                </div>
                                }
                                <img className="selectionReviewIcon" src={this.props.game.iconUrl} onClick={() => {
                                    this.props.updateSelectedGameID(this.props.game.gameID)
                                    hashHistory.push('/quarterClose/gameDetails')
                                }} />
                            </section>
                        }
                        {!this.props.game &&
                            <img className="selectionReviewIcon" src={missingIcon} onClick={() => {                                
                                hashHistory.push('/quarterClose/selectGames')
                            }}/>
                        }
                    </div>
                </Col>
                <Col span={15}>
                    <Row style={{ marginTop: "8px", marginLeft: "110px", width: "330px"}} className={"textBackground"}>
                        <Col span={9}>
                            <Text className={"selectionReviewGameLabel"}>Game Title:</Text>
                        </Col>
                        <Col span={15}>
                            <Text className={"selectionReviewGameText"}>{this.props.game && this.props.game.name}</Text>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "8px", marginLeft: "110px", width: "330px"}} className={"textBackground"}>
                        <Col span={9}>
                            <Text className={"selectionReviewGameLabel"}></Text>
                        </Col>
                        <Col span={15}>
                            <Text className={"selectionReviewGameText"}>{this.props.game && this.props.quarterCloseSelectedBet.formType}</Text>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "8px", marginLeft: "110px", width: "330px" }} className={"textBackground"}>
                        <Col span={9}>
                            <Text className={"selectionReviewGameLabel"}>Payout Options:</Text>
                        </Col>
                        <Col span={15}>
                            <Text className={"selectionReviewGameText"}>{this.props.quarterCloseSelectedBet &&  this.props.quarterCloseSelectedBet.payoutOptions}</Text>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "8px", marginLeft: "110px", width: "330px"}} className={"textBackground"}>
                        <Col span={9}>
                            <Text className={"selectionReviewGameLabel"}>Payout:</Text>
                        </Col>
                        <Col span={15}>
                            <Text className={"selectionReviewGameText"}>{this.props.quarterCloseSelectedBet && `${this.props.quarterCloseSelectedBet.payout}%`}</Text>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "8px", marginLeft: "110px", width: "330px"}} className={"textBackground"}>
                        <Col span={9}>
                            <Text className={"selectionReviewGameLabel"}>Jackpot:</Text>
                        </Col>
                        <Col span={15}>
                            <Text className={"selectionReviewGameText"}>{this.props.quarterCloseSelectedBet && this.props.quarterCloseSelectedBet.jackpot}</Text>
                        </Col>
                    </Row>
                </Col>                
            </Row>
        )
    }
}

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as LocationsActions from '../../actions/locations/LocationsActions';
import LocationLinkedCharitiesComponent from '../../components/locations/LocationLinkedCharitiesComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadBasicDetailsSuccess: state.LocationsReducer.loadBasicDetailsSuccess,

    addLocationCharitySuccess: state.LocationsReducer.addLocationCharitySuccess,
    addLocationCharityError: state.LocationsReducer.addLocationCharityError,

    deleteLocationCharitySuccess: state.LocationsReducer.deleteLocationCharitySuccess,
    deleteLocationCharityError: state.LocationsReducer.deleteLocationCharityError,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(LocationsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationLinkedCharitiesComponent)

export const NHv1 = 1;
export const OHv1 = 2;
export const VAv1 = 3;
export const KYv1 = 4;
export const NDv1 = 5;

export type InvoicingSystemID = typeof NHv1 | typeof OHv1 | typeof VAv1 | typeof KYv1 | typeof NDv1;
export type InvoicingSystemCode = 'NH' | 'OH' | 'VA' | 'KY' | 'ND';

const idToCodeMap: Record<InvoicingSystemID, InvoicingSystemCode> = {
  [NHv1]: 'NH',
  [OHv1]: 'OH',
  [VAv1]: 'VA',
  [KYv1]: 'KY',
  [NDv1]: 'ND',
};

export function getInvoicingSystemCode(id: InvoicingSystemID): InvoicingSystemCode {
  return idToCodeMap[id];
}

const codeToIdMap: Record<InvoicingSystemCode, InvoicingSystemID> = {
  'NH': NHv1,
  'OH': OHv1,
  'VA': VAv1,
  'KY': KYv1,
  'ND': NDv1,
};

export function getInvoicingSystemId(id: InvoicingSystemCode): InvoicingSystemID {
  return codeToIdMap[id];
}

export const invoicingSystems: {
  id: InvoicingSystemID;
  name: string;
  code: InvoicingSystemCode;
}[] = [
    { id: NHv1, name: "New Hampshire", code: getInvoicingSystemCode(NHv1) },
    { id: OHv1, name: "Ohio", code: getInvoicingSystemCode(OHv1) },
    { id: VAv1, name: "Virginia", code: getInvoicingSystemCode(VAv1) },
    { id: KYv1, name: "Kentucky", code: getInvoicingSystemCode(KYv1) },
    { id: NDv1, name: "North Dakota", code: getInvoicingSystemCode(NDv1) },
  ];
import React, { useCallback, useEffect } from 'react';
import {
  INDLocationInvoicingConfigItem,
  IUpdateNDLocationInvoicingConfigRequest,
} from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';
import { Col, Form, Row, Spin } from 'antd';
import LocalCharityIDAutocompleteContainer
  from '../../../common/containers/dropdown/LocalCharityIDAutocompleteContainer';
import CharitiesAutocompleteContainer from '../../../common/containers/dropdown/CharitiesAutocompleteContainer';
import InvoicingDistributorsAutocompleteContainer
  from '../../../common/containers/dropdown/InvoicingDistributorsAutocompleteContainer';
import DiscountList from './DiscountList';
import { LoadState } from '../../../common/store/fetched';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { FormComponentProps } from 'antd/lib/form/Form';
import { LocationInvoicingConfigEditFormFields } from './locationInvoicingConfigEditFormFields';
import {
  getLocationCharityDistributorDiscounts,
} from '../../actions/locationInvoicingConfig/getLocationCharityDistributorDiscounts';
import { dismissLocationCharityDistributorDiscounts } from '../../actions/locationInvoicingConfig/slice-actions';

export type Props = FormComponentProps<LocationInvoicingConfigEditFormFields> & {
  item: INDLocationInvoicingConfigItem;
  localCharities: Charity[];
};

const NdInvoicingConfigEditForm = (
  {
    item,
    localCharities,
    form: {
      getFieldDecorator,
      getFieldValue,
      setFieldsValue,
    },
  }: Props,
) => {
  const dispatch = useAppDispatch();
  const distributorDiscounts = useAppSelector(state => state.LocationInvoicingConfigReducer.locationCharityDistributorDiscounts);

  useEffect(
    () => {
      const configId = item.locationCharityDistributorInvoicingConfigsId;
      if (configId != null) {
        dispatch(getLocationCharityDistributorDiscounts(configId));
      }
      return () => {
        dispatch(dismissLocationCharityDistributorDiscounts());
      }
    },
    [item, dispatch]);

  const onLocalCharityIdChange = useCallback((value: number): void => {
    const charityId = localCharities.find(c => c.localCharityId === value)?.id;
    const currentCharityId = getFieldValue('charityId');
    setFieldsValue({
      localCharityId: value,
      charityId: currentCharityId ?? charityId,
    });
  }, [getFieldValue, localCharities, setFieldsValue]);

  const validateDiscountEntries = (
    discounts: IUpdateNDLocationInvoicingConfigRequest['discounts'],
    callback: (error?: string) => any,
  ) => {
    if (!discounts || discounts.length === 0) {
      return callback();
    }
    for (let i = 1; i < discounts.length; i++) {
      if (discounts[i].startDate <= discounts[i - 1].startDate) {
        return callback('Start date must be later than the previous entry');
      }
    }
    for (let i = 0; i < discounts.length; i++) {
      if (discounts[i].discount < 0 || discounts[i].discount > 100) {
        return callback('Discount must be between 0 and 100');
      }
    }
    return callback();
  };

  if (distributorDiscounts.loadState === LoadState.InProgress)
    return <Spin size="large"/>;
  return (
    <>
      <Row gutter={16}>
        <Col>
          <Form.Item label="Local Charity ID">
            {getFieldDecorator('localCharityId', {
              initialValue: item.localCharityId,
              rules: [{ required: true, message: 'Please select local charity ID' }],
            })(
              <LocalCharityIDAutocompleteContainer
                placeholder="Select local charity ID"
                apiUrlId={item.locationId}
                onChange={onLocalCharityIdChange}
                idSelector={c => c.localCharityId}
                textSelector={c => `${c.localCharityId} - ${c.name}`}
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Form.Item label="Charity">
            {getFieldDecorator('charityId', {
              initialValue: item.charityId,
              rules: [{ required: true, message: 'Please select charity' }],
            })(
              <CharitiesAutocompleteContainer
                placeholder="Select charity"
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Form.Item label="Invoicing distributor">
            {getFieldDecorator('invoicingDistributorId', {
              initialValue: item.invoicingDistributorId,
              rules: [{ required: true, message: 'Please select invoicing distributor' }],
            })(
              <InvoicingDistributorsAutocompleteContainer
                placeholder="Select invoicing distributor"
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Form.Item
            label="Discount configuration"
          >
            <div className="ant-form-extra">
              The start date of each entry must be the first day of a month, later than the date above it.
            </div>
            {getFieldDecorator('discounts', {
              initialValue: distributorDiscounts.data,
              rules: [{
                required: false, validator: (_, value, callback) => validateDiscountEntries(value, callback),
              }],
            })(
              <DiscountList/>,
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default NdInvoicingConfigEditForm;

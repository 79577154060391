import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ZoneActions from '../../actions/zones/ZoneActions';
import { ZoneDescriptionCardComponent } from '../../components/zones/ZoneDescriptionCardComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    addNewZoneSuccess: state.ZoneReducer.addNewZoneSuccess,
    addNewZoneError: state.ZoneReducer.addNewZoneError,

    updateZoneDetailsSuccess: state.ZoneReducer.updateZoneDetailsSuccess,
    updateZoneDetailsError: state.ZoneReducer.updateZoneDetailsError,

    deleteSelectedZoneSuccess: state.ZoneReducer.deleteSelectedZoneSuccess,
    deleteSelectedZoneError: state.ZoneReducer.deleteSelectedZoneError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ZoneActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ZoneDescriptionCardComponent)

import { useState } from 'react';
import { useAppDispatch } from '../../../common/hooks/storeHooks';
import { Link } from "react-router";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";
import { Table, Button, Switch } from "antd";
import { ILocationsAndSettings } from '../../reducers/locationSettings/LocationSettings.models';
import { updateLocationSetting } from '../../actions/locationSettings/LocationSettingsActions';

export default function LocationSettingsListTableComponent(children) {
  const dispatch = useAppDispatch();
  let tableDataSource :ILocationsAndSettings[] = children.dataSource || null;
  const [editRecord, setEditRecord] = useState({id: 0, locationName: '', enableTITO: false, enableW2GRedemption: false});
  
  const onEditRecord =(record) => {
    if(record === undefined) {
      setEditRecord(null)
    } else {
      setEditRecord(record)
    }    
  }

  const isInEditMode =(locationId: number) => {
     return editRecord.id === locationId;
  }

  const editRecordTitoData =(enableTito) => {
    let edit = {...editRecord};
    edit.enableTITO = enableTito;
    setEditRecord(edit);
    if(!enableTito && edit.enableW2GRedemption) {
      edit.enableW2GRedemption = false;
    }
  }

  const editRecordW2GData =(enableW2G) => {
    let edit = {...editRecord};
    edit.enableW2GRedemption = enableW2G;
    setEditRecord(edit);
  }

  const saveRecord =() => {
      //update table list with the updated data
      let recordToUpdate = tableDataSource.find(i => i.id === editRecord.id && i.locationName === editRecord.locationName)
      let indexOfUpdate = tableDataSource.indexOf(recordToUpdate)
      tableDataSource[indexOfUpdate] = editRecord
      let updateItem = tableDataSource.find(r => r.id === editRecord.id && r.locationName === editRecord.locationName)      
      //remove the item from edit
      cancelEdit();    
      //call the api to save the record
      dispatch(updateLocationSetting(updateItem));
  }

  const cancelEdit = () => {
    setEditRecord({id: 0, locationName: '', enableTITO: false, enableW2GRedemption: false})
  }

  const tableColumns = [
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) =>
      {
        return (<>
        {!isInEditMode(record.id) && editRecord.id === 0 &&
          <Button type="primary" onClick={() => {onEditRecord(record)} }>Edit</Button>
        }
        {isInEditMode(record.id) && editRecord.id === record.id &&
          <Button type="primary" onClick={() => saveRecord() }>Save</Button>
        } 
        {isInEditMode(record.id) && editRecord.id === record.id &&
          <Button type="primary" onClick={() => cancelEdit()} style={{ marginLeft:  '6px' }}>Cancel</Button>
        } 
        </>)
      }
    },{
      title: 'Location ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    }, {
      title: 'Location',
      dataIndex: 'locationName',
      key: 'locationName',
      sorter: true,
      render: (text, record) => {
        return (
          <Link to={"/locations/" + record.id}>{text}</Link>
        )
      }
    }, {
      title: 'TITO Enabled',
      dataIndex: 'enableTITO',
      key: 'enableTITO',
      sorter: false,
      render: (text, record) => {
        return (<>
        {!isInEditMode(record.id) &&
            <div>{record.enableTITO ? <span className="text-positive">Yes</span> : <span className="text-negative">No</span>}</div>}
          {isInEditMode(record.id) &&
            <div>{<Switch checked={editRecord.enableTITO} onChange={(checked) => editRecordTitoData(checked)}>Enable TITO</Switch>}</div>
          }
          </>)
      }   
    }, {
      title: 'W2G Redemption Enabled',
      dataIndex: 'enableW2GRedemption',
      key: 'enableW2GRedemption',
      sorter: false,
      render: (text, record) => {
        return (<>
          {!isInEditMode(record.id) &&
          <div>{record.enableW2GRedemption ? <span className="text-positive">Yes</span> : <span className="text-negative">No</span>}</div>}
          {isInEditMode(record.id) &&
          <div>{<Switch checked={editRecord.enableW2GRedemption} disabled={!editRecord.enableTITO} onChange={(checked) => editRecordW2GData(checked)}>Enable W2G</Switch>}</div>}
          </>)
      }
    }
  ];

  return (
    <Table
    rowKey={record => record.id.toString()}
    loading={children.isLoading}
    dataSource={tableDataSource}
    columns={tableColumns}
    pagination={{ pageSize: 10 }}
    locale={
      {
        emptyText: <EmptyStateComponent title='No Location Settings' />
      }
    }
    />
  );
}
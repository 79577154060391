export type LocationTypesState = Partial<{
  addLocationTypeSuccess: any;
  deleteLocationTypeError: any;
  loadLocationTypesListError: any;
  updateLocationTypeError: any;
  addLocationTypeError: any;
  deleteLocationTypeSuccess: any;
  loadLocationTypesListSuccess: any;
  locationTypesListFilters: any;
  updateLocationTypeSuccess: any;
}>;

const initialState: LocationTypesState = {};

const LocationTypesReducer = (state = initialState, action): LocationTypesState => {
  switch (action.type) {
    case "LOCATION_TYPE_LIST_FILTER_UPDATE":
      return { ...state, locationTypesListFilters: action.payload };

    case "LOAD_LOCATION_TYPES_LIST_REQUEST":
      return { ...state, loadLocationTypesListSuccess: null, loadLocationTypesListError: null };
    case "LOAD_LOCATION_TYPES_LIST_SUCCESS":
      return { ...state, loadLocationTypesListSuccess: action.payload, loadLocationTypesListError: null };
    case "LOAD_LOCATION_TYPES_LIST_FAILURE":
      return { ...state, loadLocationTypesListSuccess: null, loadLocationTypesListError: action.payload };

    case "ADD_LOCATION_TYPE_REQUEST":
      return { ...state, addLocationTypeSuccess: null, addLocationTypeError: null };
    case "ADD_LOCATION_TYPE_SUCCESS":
      return { ...state, addLocationTypeSuccess: action.payload, addLocationTypeError: null };
    case "ADD_LOCATION_TYPE_FAILURE":
      return { ...state, addLocationTypeSuccess: null, addLocationTypeError: action.payload };

    case "UPDATE_LOCATION_TYPE_REQUEST":
      return { ...state, updateLocationTypeSuccess: null, updateLocationTypeError: null };
    case "UPDATE_LOCATION_TYPE_SUCCESS":
      return { ...state, updateLocationTypeSuccess: action.payload, updateLocationTypeError: null };
    case "UPDATE_LOCATION_TYPE_FAILURE":
      return { ...state, updateLocationTypeSuccess: null, updateLocationTypeError: action.payload };

    case "DELETE_LOCATION_TYPE_REQUEST":
      return { ...state, deleteLocationTypeSuccess: null, deleteLocationTypeError: null };
    case "DELETE_LOCATION_TYPE_SUCCESS":
      return { ...state, deleteLocationTypeSuccess: action.payload, deleteLocationTypeError: null };
    case "DELETE_LOCATION_TYPE_FAILURE":
      return { ...state, deleteLocationTypeSuccess: null, deleteLocationTypeError: action.payload };

    default:
      return state;
  }
}

export default LocationTypesReducer;

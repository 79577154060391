import * as React from "react";
import { isEqual, isEmpty, each } from "lodash";
import AppUtilityService from "../../services/AppUtilityService";

import { Spin, TreeSelect } from 'antd';
import SessionService from "../../services/SessionService";
import ApiService from "../../services/ApiService";
export class ZonesDropdownComponent extends React.Component<ITreeDropdownProps, ITreeDropdownState> {
    private changedDataSource = []
    constructor(props: ITreeDropdownProps) {
        super(props);
        this.state = {
            width: this.props.width || null,
            dataSource: [],
            isLoading: false,
            autoselectFirstZone: this.props.autoselectFirstZone || false,
            expandedKeys: [],
            customParams: this.props.customParams || null,
        }
    }

    componentDidMount() {
        this.handleOptimalDataLoad();
        var value = this.props.hasOwnProperty('value') && this.props.value;
        if (value) {
            this.setState({ value })
        }
        if (this.state.autoselectFirstZone) {
            this.autoselectZone()
        }
        if (this.props.onRef) {
            this.props.onRef(this);
        }

    }

    componentWillReceiveProps(nextProps: ITreeDropdownProps) {
        if (!isEqual(this.props.customParams, nextProps.customParams)) {
            this.setState({ customParams: nextProps.customParams, dataSource: [] }, () => {
                this.handleOptimalDataLoad();
            })
        }

        if (!isEqual(this.props.autoselectFirstZone, nextProps.autoselectFirstZone)) {
            this.setState({ autoselectFirstZone: nextProps.autoselectFirstZone }, () => {
                this.autoselectZone()
            })
        }
        if (nextProps.hasOwnProperty('value') && nextProps.value !== this.props.value) {
            var value = nextProps.value || undefined;
            this.setState({ value })
            // if (!value) {
            //     this.resetDatasource();
            // }
        }
        if (nextProps.dataSource && !isEqual(this.props.dataSource, nextProps.dataSource)) {
            /*
            Needed for asynchronous child loading
            var dataSource = each(nextProps.dataSource, (obj) => {
                obj.isLeaf = !obj.hasChildren
            })
            */
            this.setState({ dataSource: nextProps.dataSource, isLoading: false }, () => {
                if (this.state.autoselectFirstZone && !isEmpty(nextProps.dataSource)) {
                    this.autoselectZone()
                }
            })
        }

        if (nextProps.dataSourceError && !isEqual(this.props.dataSourceError, nextProps.dataSourceError)) {
            this.setState({ isLoading: false })
            AppUtilityService.handleApiError(nextProps.dataSourceError);
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
    }

    render() {
        var shouldFilterTreeNode: any = false;
        return (
            <TreeSelect
                onDropdownVisibleChange={this.handleOptimalDataLoad}
                allowClear
                filterTreeNode={shouldFilterTreeNode}
                treeData={this.state.dataSource}
                value={this.state.value}
                treeExpandedKeys={this.state.expandedKeys}
                treeDefaultExpandedKeys={[this.state.value && this.state.value.toString()]}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                placeholder={this.props.placeholder || null}
                onTreeExpand={this.treeExpanded}
                style={{ minWidth: "180px", width: this.state.width && this.state.width + 'px' }}
                // loadData={this.loadChildData}
                notFoundContent={this.state.isLoading ? <Spin size="small" /> : null}
            />
        );
    }

    handleOptimalDataLoad = () => {
        var { dataSource } = this.state;
        if (isEmpty(dataSource)) {
            this.getZones();
        }
    }

    treeExpanded = (expandedKeys) => {
        this.setState({ expandedKeys })
    }

    getZones = () => {
        this.setState({ isLoading: true });
        var params = {};
        params = { ...params, ...this.state.customParams };
        this.props.loadData(params, this.props.apiUrlId || null);
    }

    handleChange = (value: any, label) => {
        var label = !isEmpty(label) ? label[0] : null;
        if (this.props.getSelectedLabel) {
            this.props.getSelectedLabel(label);
        }
        if (!value) {
            this.props.onChange(value);
        }
    }

    handleSelect = (value: any) => {
        this.props.onChange(value);
    }

    resetDatasource = () => {
        this.setState({ dataSource: [], value: null, expandedKeys: [] })
    }

    autoselectZone = () => {
        if (!isEmpty(this.state.dataSource)) {
            this.handleChange(this.state.dataSource[0].value, [this.state.dataSource[0].title])
            this.handleSelect(this.state.dataSource[0].value)
        } else {
            this.handleSelect(null)
        }
    }

    // Load child data asynchronously
    /*
       loadChildData = (treeNode) => {
           return new Promise((resolve) => {
               ApiService.getData('/lookups/hierarchicalzones', { filterByUser: false, parentZoneId: treeNode.props.value })
                   .then((response: any) => {
                       var responseData = each(response.data, (obj) => {
                           obj.isLeaf = !obj.hasChildren
                       })
                       this.changedDataSource = [...this.state.dataSource];
                       if (responseData && !isEmpty(responseData)) {
                           this.traverseTreeAndUpdateNode(this.changedDataSource, responseData, resolve);
                       }
                   })
                   .catch(err => {
                       AppUtilityService.handleApiError(err);
                   })
           });
       }
   
       traverseTreeAndUpdateNode = (dataSource, dataObj, resolve) => {
           dataSource.forEach((item, index, arr) => {
               if (item.key === dataObj[0].parentId) {
                   item.children = dataObj;
                   this.setState({ dataSource: this.changedDataSource }, () => {
                       resolve()
                   });
               }
               if (item.children) {
                   return this.traverseTreeAndUpdateNode(item.children, dataObj, resolve);
               }
           });
       };
    */
}
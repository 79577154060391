import ApiService from "../../../common/services/ApiService";

export function updateLocationTypeListFilters(params) {
    return {
        type: 'LOCATION_TYPE_LIST_FILTER_UPDATE',
        payload: params
    }
}

/**
 * Load lookup-types list
 */
export function loadLocationTypes(params) {
    return function (dispatch) {
        dispatch({ type: `LOAD_LOCATION_TYPES_LIST_REQUEST` });
        return ApiService.getData("/admin/lookup-types", params)
            .then((successResult) => {
                dispatch({ type: `LOAD_LOCATION_TYPES_LIST_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `LOAD_LOCATION_TYPES_LIST_FAILURE`, payload: errorResult });
            });
    }
}

/**
 * add new lookup-type
 */
export function addNewLocationType(formData) {
    return function (dispatch) {
        dispatch({ type: `ADD_LOCATION_TYPE_REQUEST` });
        return ApiService.postData("/admin/lookup-types", formData)
            .then((successResult) => {
                dispatch({ type: `ADD_LOCATION_TYPE_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `ADD_LOCATION_TYPE_FAILURE`, payload: errorResult });
            });
    }
}
// ================================================================================

export function updateLocationType(userId, formData) {
    return function (dispatch) {
        dispatch({ type: `UPDATE_LOCATION_TYPE_REQUEST` });
        return ApiService.updateData("/admin/lookup-types/" + userId, null, formData)
            .then((successResult) => {
                dispatch({ type: `UPDATE_LOCATION_TYPE_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `UPDATE_LOCATION_TYPE_FAILURE`, payload: errorResult });
            });
    }
}
// ================================================================================

export function deleteLocationType(userId) {
    return function (dispatch) {
        dispatch({ type: `DELETE_LOCATION_TYPE_REQUEST` });
        return ApiService.deleteData('/admin/lookup-types/' + userId, null, null)
            .then((successResult) => {
                dispatch({ type: `DELETE_LOCATION_TYPE_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `DELETE_LOCATION_TYPE_FAILURE`, payload: errorResult });
            });
    }
}
import { Alert, Button, Modal, Progress } from "antd";
import { useCallback, useEffect, useState } from "react";
import { delay } from '../../../common/helpers/delay';
import { plurarize } from '../../../common/helpers/pluralize';
import { useAppDispatch } from "../../../common/hooks/storeHooks";
import useIsMounted from '../../../common/hooks/useIsMounted';
import { saveFile } from '../../../common/store/fileDownload';
import { downloadInvoiceAttachment } from '../../actions/billing/downloadInvoiceAttachment';
import { IInvoiceListItem, InvoiceAttachmentType } from "../../reducers/billing/billing.models";

interface InvoiceBulkDownloadModalProps {
  items: IInvoiceListItem[],
  onClose?: () => void,
}

const InvoiceBulkDownloadModal = (props: InvoiceBulkDownloadModalProps) => {

  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isCancelled, setIsCancelled] = useState<boolean>(false);
  const [failedItems, setFailedItems] = useState<IInvoiceListItem[]>([]);

  const onClose = useCallback(() => {
    setIsVisible(false);
    if (props.onClose != null) props.onClose();
  }, [props]);

  const onSuccess = useCallback(async () => {
    await delay(500);
    onClose();
  }, [onClose]);

  const onCancel = () => {
    setIsCancelled(true);
    onClose();
  }

  const onRetry = () => {
    downloadItems(failedItems, () => !isMounted() || isCancelled)
      .then(result => { if (result) onSuccess(); });
  }

  const downloadItem = useCallback(async (item: IInvoiceListItem, isCanceled: () => boolean): Promise<boolean> => {
    if (isCanceled()) return false;
    const file = await dispatch(downloadInvoiceAttachment(item.invoiceId, InvoiceAttachmentType.InvoicePdf));
    if (!file) {
      return false;
    } else {
      if (isCanceled()) return false;
      saveFile(file);
      return true;
    }
  }, [dispatch]);

  const downloadItems = useCallback(async (items: IInvoiceListItem[], isCanceled: () => boolean): Promise<boolean> => {
    let downloaded = 0;
    let failed = []
    setIsDownloading(true);
    setProgress(items.length < 20 ? 5 : 1);
    for (const item of items) {
      if (isCanceled()) return false;
      const isSuccess = await downloadItem(item, isCanceled);
      if (!isSuccess) {
        failed.push(item);
      }
      downloaded++;
      if (isCanceled()) return false;
      const currentProgress = (1 + downloaded) / (1 + items.length) * 100;
      setProgress(currentProgress < 1 ? 1 : currentProgress);
    }
    if (isCanceled()) return false;
    setIsDownloading(false);
    setFailedItems(failed);
    return failed.length === 0;
  }, [downloadItem]);

  useEffect(() => {
    downloadItems(props.items, () => !isMounted() || isCancelled)
      .then(result => { if (result) onSuccess(); });
  }, [dispatch, downloadItems, isCancelled, isMounted, onSuccess, props.items]);


  if (!isVisible) return null;

  const progressStatus = isDownloading ? 'active'
    : (failedItems.length > 0 ? 'exception'
      : (progress > 99 ? 'success'
        : 'normal'));

  const isSuccess = !isDownloading && progress > 99 && failedItems.length === 0;

  return (<Modal
    visible={isVisible}
    title={`Downloading ${props.items.length} ${plurarize('invoice', props.items.length)}`}
    closable={false}
    footer={[
      failedItems.length > 0 && <Button type="primary" onClick={onRetry} disabled={isDownloading}>
        Retry failed downloads
      </Button>,
      <Button key="back" onClick={onCancel} disabled={isSuccess}>
        Cancel
      </Button>,
    ]}
  >
    <Progress percent={progress} showInfo={false} status={progressStatus} />
    {failedItems.length > 0 && !isDownloading && <Alert
      message={`Failed to download ${failedItems.length} ${plurarize('invoice', failedItems.length)}`}
      showIcon={false}
      banner
      type="error"
    />}
  </Modal>);
}

export default InvoiceBulkDownloadModal;
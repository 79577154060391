export type DistributorsState = Partial<{
  loadDistributorListDataSuccess: any;
  loadDistributorListDataError: any;
  loadDistributorDataSuccess: any;
  loadDistributorDataError: any;
  loadDistributorUserDetailsSuccess: any;
  loadDistributorUserDetailsError: any;
  addDistributorUserSuccess: any;
  addDistributorUserError: any;
  deleteDistributorUserSuccess: any;
  deleteDistributorUserError: any;
}>;

const initialState: DistributorsState = {};

const DistributorsReducer = (state = initialState, action): DistributorsState => {
  switch (action.type) {
    // Distributors List
    case "LOAD_DISTRIBUTOR_LIST_DATA_REQUEST":
      return { ...state, loadDistributorListDataSuccess: null, loadDistributorListDataError: null };
    case "LOAD_DISTRIBUTOR_LIST_DATA_SUCCESS":
      return { ...state, loadDistributorListDataSuccess: action.payload, loadDistributorListDataError: null };
    case "LOAD_DISTRIBUTOR_LIST_DATA_FAILURE":
      return { ...state, loadDistributorListDataSuccess: null, loadDistributorListDataError: action.payload };

    // Distributors List
    case "LOAD_DISTRIBUTOR_DATA_REQUEST":
      return { ...state, loadDistributorDataSuccess: null, loadDistributorDataError: null };
    case "LOAD_DISTRIBUTOR_DATA_SUCCESS":
      return { ...state, loadDistributorDataSuccess: action.payload, loadDistributorDataError: null };
    case "LOAD_DISTRIBUTOR_DATA_FAILURE":
      return { ...state, loadDistributorDataSuccess: null, loadDistributorDataError: action.payload };

    case "LOAD_DISTRIBUTOR_USERS_DATA_REQUEST":
      return { ...state, loadDistributorUserDetailsSuccess: null, loadDistributorUserDetailsError: null };
    case "LOAD_DISTRIBUTOR_USERS_DATA_SUCCESS":
      return { ...state, loadDistributorUserDetailsSuccess: action.payload, loadDistributorUserDetailsError: null };
    case "LOAD_DISTRIBUTOR_USERS_DATA_FAILURE":
      return { ...state, loadDistributorUserDetailsSuccess: null, loadDistributorUserDetailsError: action.payload };
  
    case "DISTRIBUTOR_LINKED_USER_ADD_REQUEST":
      return { ...state, addDistributorUserSuccess: null, addDistributorUserError: null };
    case "DISTRIBUTOR_LINKED_USER_ADD_SUCCESS":
      return { ...state, addDistributorUserSuccess: action.payload, addDistributorUserError: null };
    case "DISTRIBUTOR_LINKED_USER_ADD_FAILURE":
      return { ...state, addDistributorUserSuccess: null, addDistributorUserError: action.payload };
    
    case "DISTRIBUTOR_LINKED_USER_DELETE_REQUEST":
      return { ...state, deleteDistributorUserSuccess: null, deleteDistributorUserError: null };
    case "DISTRIBUTOR_LINKED_USER_DELETE_SUCCESS":
      return { ...state, deleteDistributorUserSuccess: action.payload, deleteDistributorUserError: null };
    case "DISTRIBUTOR_LINKED_USER_DELETE_FAILURE":
      return { ...state, deleteDistributorUserSuccess: null, deleteDistributorUserError: action.payload };
  

    default:
      return state;

  }
}

export default DistributorsReducer;
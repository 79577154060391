import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as DistributorsActions from '../../actions/distributors/DistributorsActions';
import DistributorLinkedUsersComponent from '../../components/distributors/DistributorLinkedUsersComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadBasicDetailsSuccess: state.DistributorsReducer.loadDistributorUserDetailsSuccess,
    loadBasicDetailsError: state.DistributorsReducer.loadDistributorUserDetailsError,

    addDistributorUserSuccess: state.DistributorsReducer.addDistributorUserSuccess,
    addDistributorUserError: state.DistributorsReducer.addDistributorUserError,

    deleteDistributorUserSuccess: state.DistributorsReducer.deleteDistributorUserSuccess,
    deleteDistributorUserError: state.DistributorsReducer.deleteDistributorUserError,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(DistributorsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DistributorLinkedUsersComponent)

import * as React from "react";
import WebAccessService from "../../../common/services/WebAccessService";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";

import { Card, Button, List, Modal } from 'antd';
import AddContactNoteModalContainer from "../../containers/contacts/AddContactNoteModalContainer";

export class ContactNotesCardComponent extends React.Component<IContactNotesCardProps, IContactNotesCardState> {

 

  constructor (props: IContactNotesCardProps) {
    super(props);
    this.state = {
      contactId: this.props.contactId,
      contactNotes: this.props.contactNotes,
      contactNote: null,
      showAddContactNoteModal: false,
      addNoteModalMode: ""
    }
  }

  render() {    
    var newNote = {noteID: 0, contactId: this.props.contactId, note: "", deleted: false};
    const notes = this.props.contactNotes;
   var actions = WebAccessService.hasPermissionToAccess("Contact", "AddNotes") ?
      <Button type="primary" onClick={() => this.showAddContactNoteModal(newNote, "new")}>Add Notes</Button>
      : null
    

    if(notes?.length > 0) {
    return (      
      <div>
      <Card className="content-card no-body-padding" bordered={true}
        title="Notes" extra={actions}>
        {this.state.showAddContactNoteModal && <AddContactNoteModalContainer 
        contactId={this.props.contactId}
        contactNote={this.state.contactNote} 
        cancelSaveContactNoteCallback={this.handleCancelSaveContactNote}
        addNoteModalMode={this.state.addNoteModalMode} />}
        {
        <List dataSource = {notes}
              renderItem = {(Item) => (
              <List.Item 
              actions={[<Button icon="edit" size="small" color="Red" onClick={() => this.showAddContactNoteModal(Item, "edit")}></Button>,
                <Button type="danger" icon="delete" size="small" onClick={() => this.handleDeleteNote(Item.noteID)}></Button>]}>                
                {Item.note}
              </List.Item>
              )}
              >
        </List>
      }
      </Card>
      </div>
    );
  } else {
    return (
      <Card className="content-card no-body-padding" bordered={true}
        title="Notes" extra={actions}>
        {this.state.showAddContactNoteModal && <AddContactNoteModalContainer contactNote={this.state.contactNote} contactId={this.props.contactId} cancelSaveContactNoteCallback={this.handleCancelSaveContactNote} addNoteModalMode={this.state.addNoteModalMode} />}
        <EmptyStateComponent title="No Notes" />
        </Card>
    )}
  }

  showAddContactNoteModal = (note, modalMode) => {
      if (modalMode === "new")
      {
        this.setState({ contactNote: note, addNoteModalMode: "new", showAddContactNoteModal: true})  
      } else {
          this.setState({ contactNote: note, addNoteModalMode: "edit", showAddContactNoteModal: true})
      }              
    }
  handleCancelSaveContactNote = () => {
        this.setState({ showAddContactNoteModal: false })
    }
  handleDeleteNote = (noteId) => {
        Modal.confirm({
          title: 'Are you sure you want to delete this note?',
          okText: 'Yes',
          cancelText: 'No',
          onOk: () => {
            this.props.actions.deleteContactNote(this.state.contactId, noteId)
          }
        });
        this.setState({ showAddContactNoteModal: false})
    }
}

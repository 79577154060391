import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { loadLocationsWithDemoToggleDropdown } from '../../actions/dropdown/DropdownActions';
import LocationDropdownWithDemoOnlyToggleComponent from '../../components/dropdown/LocationDropdownWithDemoOnlyToggleComponent';
import { RootState } from '../../store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
      datasource: state.DropdownReducer.loadLocationsDropdownDataSuccess,
      datasourceError: state.DropdownReducer.loadLocationsDropdownDataError,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
      loadData: bindActionCreators(loadLocationsWithDemoToggleDropdown, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(LocationDropdownWithDemoOnlyToggleComponent)

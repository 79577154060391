import * as React from "react";
import { isEqual, startCase } from "lodash";

import AppUtilityService from "../../../../common/services/AppUtilityService";
import LocationReconReportsFilterContainer from "../../../containers/reports/locationStatisticsReports/LocationReconReportsFilterContainer";
import ReconSummaryReportContainer from "../../../containers/reports/locationStatisticsReports/ReconSummaryReportContainer";
import WebAccessService from "../../../../common/services/WebAccessService";

import { Card, Layout, Menu, Dropdown, Icon } from 'antd';
import { hashHistory } from "react-router";
import { IReconSummaryReportPageProps, IReconSummaryReportPageState } from '../Reports';
import { filters } from './commonFiltersConfiguration';

export class ReconSummaryReportPage extends React.Component<IReconSummaryReportPageProps, IReconSummaryReportPageState> {

    private reconSummaryReportRef: any;

    constructor(props: IReconSummaryReportPageProps) {
        super(props);
        this.state = {
            reportType: this.props.params && this.props.params.type || null,
            zoneName: null,
            locationName: null,
            charityName: null,
            distributorName: null,
            isLoadingCharts: false,
            isLoadingList: false,
            isLoadingSummaryInfo: false,
            apiParams: null
        }
    }

    componentDidMount() {
        this.breadcrumbsBuild();
        this.checkPermissions(this.state.reportType)
    }

    componentWillReceiveProps(nextProps: IReconSummaryReportPageProps) {
        if (nextProps.loadReconSummaryChartSuccess && !isEqual(this.props.loadReconSummaryChartSuccess, nextProps.loadReconSummaryChartSuccess)) {
            this.setState({ isLoadingCharts: false })
        }

        if (nextProps.loadReconSummaryListSuccess && !isEqual(this.props.loadReconSummaryListSuccess, nextProps.loadReconSummaryListSuccess)) {
            this.setState({ isLoadingList: false })
        }

        if (nextProps.loadReconSummaryInfoSuccess && !isEqual(this.props.loadReconSummaryInfoSuccess, nextProps.loadReconSummaryInfoSuccess)) {
            this.setState({ isLoadingSummaryInfo: false })
        }
    }

    render() {
        let { zoneName, locationName, charityName, distributorName } = this.state;
        let filterString = AppUtilityService.displayStringFromArray([zoneName, locationName, charityName, distributorName]);

        let cardTitle = "Recon Summary - " +
            startCase(this.state.reportType) +
            (filterString && (" | " + filterString));

        const extraActions = <div>
            <Dropdown.Button type="primary" onClick={this.downloadData}
                disabled={this.state.isLoadingCharts || this.state.isLoadingList || this.state.isLoadingSummaryInfo}
                overlay={
                    <Menu onClick={this.downloadList}>
                        <Menu.Item key="1">PDF</Menu.Item>
                        <Menu.Item key="2">Excel</Menu.Item>
                    </Menu>
                }>
                <Icon type="download" style={{ fontSize: "16px" }} /> Download as PDF
        </Dropdown.Button></div>;
        return (
            <>
                <Card style={{ paddingTop: "12px" }} className="reports-filter-card content-card no-header-border" bordered={false} title={cardTitle}
                    extra={WebAccessService.hasPermissionToAccess("Reports", "Download") ? extraActions : null}
                >
                    <LocationReconReportsFilterContainer
                        filters={filters}
                        getSelectedZoneNameCallback={this.handleSelectedZone}
                        getSelectedLocationNameCallback={this.handleSelectedLocation}
                        getSelectedCharityNameCallback={this.handleSelectedCharity}
                        getSelectedDistributorNameCallback={this.handleSelectedDistributor}
                        applyFiltersCallback={this.applyFilters}
                    />
                </Card>
                <div className="pg-reports-container">
                    <Layout>
                        <div className="container" style={{ background: '#fff' }}>
                            <ReconSummaryReportContainer
                                showChart={true}
                                showSummary={true}
                                apiParams={this.state.apiParams}
                                reportType={this.state.reportType}
                                onRef={ref => (this.reconSummaryReportRef = ref)}
                            />
                        </div>
                    </Layout>
                </div>
            </ >
        )
    }

    breadcrumbsBuild = () => {
        let breadcrumbsList = [
            { name: "Reports", link: '/reports' },
            { name: "Recon Summary - " + startCase(this.state.reportType), link: null }
        ]
        var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList, null);
        this.props.actions.updateBreadcrumbs(breadcrumbs);
    }

    /**
     * Load location basic details success/error handle
     */

    handleSelectedZone = (e) => {
        this.setState({ zoneName: e })
    }
    //-----------------------------------------------------------------------

    handleSelectedLocation = (e) => {
        this.setState({ locationName: e })
    }
    //-----------------------------------------------------------------------

    handleSelectedCharity = (e) => {
        this.setState({ charityName: e })
    }
    //-----------------------------------------------------------------------

    handleSelectedDistributor = (e) => {
        this.setState({ distributorName: e })
    }
    //-----------------------------------------------------------------------

    applyFilters = (apiParams) => {
        apiParams.reportType = this.state.reportType;
        apiParams.dateFrom = AppUtilityService.formatAPIDateFilter(apiParams.filterValues.dateRange?.[0]);
        apiParams.dateTo = AppUtilityService.formatAPIDateFilter(apiParams.filterValues.dateRange?.[1]);
        this.setState({
            apiParams,
            isLoadingCharts: true,
            isLoadingList: true,
            isLoadingSummaryInfo: true,
        })
    }

    downloadData = (e) => {
        e.key = "1";
        this.downloadList(e)
    }

    downloadList = (e) => {
        var downloadType = "pdf";
        switch (e.key) {
            case "1":
                downloadType = "pdf";
                break;
            case "2":
                downloadType = "excel";
                break;
            default: break;
        }
        this.reconSummaryReportRef.getDownloadData(downloadType);
    }

    checkPermissions = (reportType) => {
        let hasPermissions = false;
        switch (reportType) {
            case "ByDate":
                hasPermissions = WebAccessService.hasPermissionToAccess("Reports", "ByDate");
                break;
            case "ByGame":
                hasPermissions = WebAccessService.hasPermissionToAccess("Reports", "ByGame");
                break;
            case "ByGT":
                hasPermissions = WebAccessService.hasPermissionToAccess("Reports", "ByGT");
                break;
            default: break;
        }
        if (!hasPermissions) {
            hashHistory.push("/unauthorized-access");
        }
    }
}

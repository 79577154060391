
import * as React from "react";
import { debounce, isEmpty, isEqual } from "lodash";
import { FormComponentProps } from 'antd/lib/form/Form';

import UserRoleDropdownContainer from "../../../common/containers/dropdown/UserRoleDropdownContainer";
import ApiService from "../../../common/services/ApiService";
import AppUtilityService from "../../../common/services/AppUtilityService";

import { Form, Input, Row, Col, Avatar, Checkbox } from 'antd';
import SessionService from "../../../common/services/SessionService";
const FormItem = Form.Item;

class UserBasicDetailsFormComponent extends React.Component<IUserBasicDetailsFormProps & FormComponentProps, IUserBasicDetailsFormState> {

    constructor(props: IUserBasicDetailsFormProps & FormComponentProps) {
        super(props);
        this.state = {
            userDetails: this.props.userDetails || null,
            contactDetails: this.props.contactDetails || null,
            confirmDirty: false
        }
        this.validateEmail = debounce(this.validateEmail, 500);
    }

    componentWillReceiveProps(nextProps: IUserBasicDetailsFormProps) {
        if (nextProps.userDetails && !isEmpty(nextProps.userDetails) && !isEqual(this.props.userDetails, nextProps.userDetails)) {
            this.setState({ userDetails: nextProps.userDetails })
        }
        if (nextProps.contactDetails && !isEmpty(nextProps.contactDetails) && !isEqual(this.props.contactDetails, nextProps.contactDetails)) {
            this.setState({ contactDetails: nextProps.contactDetails })
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form>
                <Row gutter={16}>
                    {
                        this.state.userDetails &&
                        <Col xl={3} lg={5}>
                            <Avatar size={130}
                                src={this.state.userDetails.image ? AppUtilityService.getImageFromByteArray(this.state.userDetails.image) : null}
                            />
                        </Col>
                    }
                    <Col xl={this.state.userDetails ? 21 : 24} lg={this.state.userDetails ? 19 : 24}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <FormItem label="First name">
                                    {getFieldDecorator('firstName', {
                                        initialValue: (
                                            (this.state.userDetails && this.state.userDetails.firstName) ||
                                            (this.state.contactDetails && this.state.contactDetails.firstName)
                                        ),
                                        rules: [{ required: true, message: 'Please input your first name!' }],
                                    })(
                                        <Input placeholder="First name" disabled={this.state.contactDetails ? true : false} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="Last name">
                                    {getFieldDecorator('lastName', {
                                        initialValue: (
                                            (this.state.userDetails && this.state.userDetails.lastName) ||
                                            (this.state.contactDetails && this.state.contactDetails.lastName)
                                        )
                                    })(
                                        <Input placeholder="Last name" disabled={this.state.contactDetails ? true : false} />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <FormItem label="Email (Username)">
                                    {getFieldDecorator('userName', {
                                        initialValue: (
                                            (this.state.userDetails && this.state.userDetails.userName) ||
                                            (this.state.contactDetails && this.state.contactDetails.email)
                                        ),
                                        rules: [
                                            { required: true, message: 'Please input an email!' },
                                            { type: 'email', message: 'The input is not valid email!' },
                                            { validator: this.validateEmail }
                                        ],
                                    })(
                                        <Input type="email" placeholder="Email" disabled={this.state.userDetails?.userId != null}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem label="Phone">
                                    {getFieldDecorator('contactNo', {
                                        initialValue: (
                                            (this.state.userDetails && this.state.userDetails.contactNo) ||
                                            (this.state.contactDetails && this.state.contactDetails.phone)
                                        ),
                                    })(
                                        <Input placeholder="Phone" disabled={this.state.contactDetails ? true : false} />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <FormItem label="Role">
                                    {getFieldDecorator('roleId', {
                                        initialValue: this.state.userDetails && this.state.userDetails.roleId,
                                        rules: [{ required: true, message: 'Please select a user role!' }],
                                    })(
                                        <UserRoleDropdownContainer placeholder="Select role" />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        {
                            this.state.contactDetails &&
                            < Row gutter={16}>
                                <Col span={12}>
                                    <FormItem label="">
                                        {getFieldDecorator('assignLocation', {
                                            initialValue: true,
                                            valuePropName: 'checked',
                                        })(
                                            <Checkbox >Assign user to the Location</Checkbox>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </Form >
        )
    }

    /**
     * Email & Password validation
     */
    validateEmail = (rule, value, callback) => {
        var apiUrl = "/admin/users/validate";
        if (!SessionService.getToken()) {
            apiUrl = "/account/validate"
        }
        if (value && (
            (!this.state.userDetails) ||
            (this.state.userDetails && this.state.userDetails.userName !== value) ||
            (this.state.contactDetails && this.state.contactDetails.email !== value)
        )) {
            ApiService.getData(apiUrl, { email: value })
                .then((response: any) => {
                    var emailExists = response.data
                    if (emailExists) {
                        callback('Email address exists');
                    } else {
                        callback();
                    }
                })
                .catch(err => {
                    AppUtilityService.handleApiError(err);
                })
        } else {
            callback();
        }
    }
} export default Form.create<IUserBasicDetailsFormProps & FormComponentProps>()(UserBasicDetailsFormComponent);

import ApiService from "../../../common/services/ApiService";

/**
 * All users available to sasign to a role
 */

export function loadRoleUsersAvailableAutocomplete(params, roleId) {
    return function (dispatch) {
        dispatch({ type: `LOAD_ROLE_USERS_AVAILABLE_AUTOCOMPLETE_DATA_REQUEST` });
        return ApiService.getData("/lookups/roleUsers/" + roleId, params)
            .then((successResult) => {
                dispatch({ type: `LOAD_ROLE_USERS_AVAILABLE_AUTOCOMPLETE_DATA_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `LOAD_ROLE_USERS_AVAILABLE_AUTOCOMPLETE_DATA_FAILURE`, payload: errorResult });
            });
    }
}
/**
 * All users linked charity autocomplete
 */

export function loadUsersLinkedCharityAutocomplete(params, userId) {
    return function (dispatch) {
        dispatch({ type: `LOAD_USERS_LINKED_CHARITY_AUTOCOMPLETE_DATA_REQUEST` });
        return ApiService.getData("/lookups/users/linkedCharities/" + userId, params)
            .then((successResult) => {
                dispatch({ type: `LOAD_USERS_LINKED_CHARITY_AUTOCOMPLETE_DATA_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `LOAD_USERS_LINKED_CHARITY_AUTOCOMPLETE_DATA_FAILURE`, payload: errorResult });
            });
    }
}
/**
 * All users linked locations autocomplete
 */

export function loadUsersLinkedLocationsAutocomplete(params, userId) {
    return function (dispatch) {
        dispatch({ type: `LOAD_USERS_LINKED_LOCATIONS_AUTOCOMPLETE_DATA_REQUEST` });
        return ApiService.getData("/lookups/users/linkedLocations/" + userId, params)
            .then((successResult) => {
                dispatch({ type: `LOAD_USERS_LINKED_LOCATIONS_AUTOCOMPLETE_DATA_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `LOAD_USERS_LINKED_LOCATIONS_AUTOCOMPLETE_DATA_FAILURE`, payload: errorResult });
            });
    }
}
/**
 * All users linked zones autocomplete
 */

export function loadUsersLinkedZonesAutocomplete(params, userId) {
    return function (dispatch) {
        dispatch({ type: `LOAD_USERS_LINKED_ZONES_AUTOCOMPLETE_DATA_REQUEST` });
        return ApiService.getData("/lookups/users/linkedZones/" + userId, params)
            .then((successResult) => {
                dispatch({ type: `LOAD_USERS_LINKED_ZONES_AUTOCOMPLETE_DATA_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `LOAD_USERS_LINKED_ZONES_AUTOCOMPLETE_DATA_FAILURE`, payload: errorResult });
            });
    }
}
/**
 * All zone linked locations autocomplete
 */

export function loadZoneLinkedLocationsAutocomplete(params, zoneId) {
    return function (dispatch) {
        dispatch({ type: `LOAD_ZONE_LINKED_LOCATIONS_AUTOCOMPLETE_DATA_REQUEST` });
        return ApiService.getData("/lookups/zones/linkedLocations/" + zoneId, params)
            .then((successResult) => {
                dispatch({ type: `LOAD_ZONE_LINKED_LOCATIONS_AUTOCOMPLETE_DATA_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `LOAD_ZONE_LINKED_LOCATIONS_AUTOCOMPLETE_DATA_FAILURE`, payload: errorResult });
            });
    }
}
/**
 * All zone linked locations autocomplete
 */

export function loadZoneLinkedUsersAutocomplete(params, zoneId) {
    return function (dispatch) {
        dispatch({ type: `LOAD_ZONE_LINKED_USERS_AUTOCOMPLETE_DATA_REQUEST` });
        return ApiService.getData("/lookups/zones/linkedUsers/" + zoneId, params)
            .then((successResult) => {
                dispatch({ type: `LOAD_ZONE_LINKED_USERS_AUTOCOMPLETE_DATA_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `LOAD_ZONE_LINKED_USERS_AUTOCOMPLETE_DATA_FAILURE`, payload: errorResult });
            });
    }
}
/**
 * All locations that can be selected for game selection windows autocomplete
 */

export function loadSelectLocationsAutocomplete() {
  return function (dispatch) {
      dispatch({ type: `LOAD_GAMESELECTIONWINDOW_SELECT_LOCATIONS_AUTOCOMPLETE_DATA_REQUEST` });
      return ApiService.getData("/gameSelectionWindow/selectLocations/")
          .then((successResult) => {
              dispatch({ type: `LOAD_GAMESELECTIONWINDOW_SELECT_LOCATIONS_AUTOCOMPLETE_DATA_SUCCESS`, payload: successResult.data });
          })
          .catch((errorResult) => {
              dispatch({ type: `LOAD_GAMESELECTIONWINDOW_SELECT_LOCATIONS_AUTOCOMPLETE_DATA_FAILURE`, payload: errorResult });
          });
  }
}

/**
 * All users available to sasign to a role
 */

export function loadDistributorUsersAvailableAutocomplete(params) {
  return function (dispatch) {
      dispatch({ type: `LOAD_DISTRIBUTOR_USERS_AVAILABLE_AUTOCOMPLETE_DATA_REQUEST` });
      return ApiService.getData("/lookups/distributorUsers/" + params.distributorId, params)
          .then((successResult) => {
              dispatch({ type: `LOAD_DISTRIBUTOR_USERS_AVAILABLE_AUTOCOMPLETE_DATA_SUCCESS`, payload: successResult.data });
          })
          .catch((errorResult) => {
              dispatch({ type: `LOAD_DISTRIBUTOR_USERS_AVAILABLE_AUTOCOMPLETE_DATA_FAILURE`, payload: errorResult });
          });
  }
}

import * as React from "react";
import { debounce, isEqual, isEmpty, map } from "lodash";
import { Link } from "react-router";
import AppUtilityService from "../../../common/services/AppUtilityService";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";

import { Table, Modal, Tag } from "antd";
const confirm = Modal.confirm;

export class UserRoleListTableComponent extends React.Component<IUserRoleListProps, IUserRoleListState> {

  constructor(props: IUserRoleListProps) {
    super(props);
    this.state = {
      pagination: {
        page: 1,
        pageSize: 10,
        total: 5
      },
      sortInfo: null,
      searchText: null,
      isLoading: true,
      userRoles: [],
      showArchived: false,
      selectedRowKeys: []
    }
    this.loadUserRoles = debounce(this.loadUserRoles, 500);
  }


  componentWillMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    if (this.props.userRoleListFilters) {
      this.handlePresetFilters(this.props.userRoleListFilters, true)
    } else {
      this.loadUserRoles();
    }
  }

  componentWillReceiveProps(nextProps: IUserRoleListProps) {
    /**
     * User role load list success/error handle
     */
    if (nextProps.loadAllUserRolesSuccess && !isEqual(this.props.loadAllUserRolesSuccess, nextProps.loadAllUserRolesSuccess)) {
      let pagination = { ...this.state.pagination };
      var responseData = nextProps.loadAllUserRolesSuccess;
      pagination.total = responseData.total;
      this.props.getTotalRolesCallback(pagination.total);
      this.setState({ userRoles: responseData.data, pagination, isLoading: false })
    }

    if (nextProps.loadAllUserRolesError && !isEqual(this.props.loadAllUserRolesError, nextProps.loadAllUserRolesError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.loadAllUserRolesError);
    }
    /**
 * Handle unarchive role success/error
 */
    if (nextProps.unarchiveRolesSuccess !== null && !isEqual(this.props.unarchiveRolesSuccess, nextProps.unarchiveRolesSuccess)) {
      AppUtilityService.showMessageToast("Selected roles have been unarchived", "success");
      this.loadUserRoles();
    }
    if (nextProps.unarchiveRolesError && !isEqual(this.props.unarchiveRolesError, nextProps.unarchiveRolesError)) {
      AppUtilityService.handleApiError(nextProps.unarchiveRolesError);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  render() {
    const { isLoading, pagination, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: record => ({
        disabled: record.roleId === 1,
      })
    };
    const tableColumns = [
      {
        title: 'Role Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        sortOrder: AppUtilityService.getTableColumnSortOrder(this.state.sortInfo, 'name'),
        render: (text, record) => {
          return (
            <div>
              <Link to={"/admin/user-role/" + record.roleId}>{text}</Link>
              {
                !record.isActive &&
                <div>
                  <Tag>Archived</Tag>
                </div>
              }
            </div>
          )
        }
      }, {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: true,
        sortOrder: AppUtilityService.getTableColumnSortOrder(this.state.sortInfo, 'description'),
        className: "hidden-sm",
      }, {
        title: 'Users',
        dataIndex: 'userCount',
        key: 'userCount',
        className: "hidden-sm",
      }, {
        title: '',
        dataIndex: 'options',
        key: 'options',
        width: "10%",
        render: (text, record) => {
          return (
            !record.isActive && <div className="text-right"><span className="link-danger" onClick={() => this.unarchiveRoles(record.roleId)}>Unarchive</span></div>
          )
        }
      },
    ];

    return (
      <Table
        rowKey={(record, index) => { return (record.roleId).toString() }}
        loading={isLoading}
        rowSelection={rowSelection}
        dataSource={this.state.userRoles}
        columns={tableColumns}
        pagination={{ current: this.state.pagination.page, pageSize: pagination.pageSize, total: pagination.total }}
        onChange={this.handleTableChange}
        locale={
          {
            emptyText: <EmptyStateComponent title="No Roles" />
          }
        }
      />
    )
  }

  /**
   * Handling of search text change from parent component using ref to current component
   */
  handleSearchChange = (value) => {
    var pagination = { ...this.state.pagination };
    pagination.page = 1;
    this.setState({ pagination, searchText: value }, () => {
      this.loadUserRoles();
    })
  }


  /**
   * Triggered when switch is toggled to view/hide archived roles
   */
  handleViewArchivedFlagChange = (isChecked) => {
    var pagination = { ...this.state.pagination };
    pagination.page = 1;
    this.setState({ pagination, showArchived: isChecked }, () => {
      this.loadUserRoles();
    })
  }

  /**
   * Triggered when table pagination, sortInfo or filter changes
   */
  handleTableChange = (updatedPagination, filters, sorter) => {
    var pagination = { ...this.state.pagination };
    var sortInfo = { ...this.state.sortInfo };
    pagination.page = updatedPagination.current
    if (sorter && !isEmpty(sorter)) {
      sortInfo = {
        sortDir: sorter.order,
        propertyName: sorter.field
      }
    } else {
      sortInfo = null;
    }
    this.setState({ pagination, sortInfo }, () => {
      this.loadUserRoles()
    })
  }

  /**
   * Reset table from parent component using ref to current component
   */
  resetTable = () => {
    var pagination = { ...this.state.pagination };
    pagination.page = 1;
    this.setState({ pagination, sortInfo: null, searchText: "" }, () => {
      this.loadUserRoles()
    })
  }

  /**
   * Load table data
   */
  loadUserRoles = () => {
    this.setState({ isLoading: true });
    let { pagination, sortInfo, searchText, showArchived } = this.state;
    let params = {
      page: pagination.page,
      pageSize: pagination.pageSize,
      sortDir: sortInfo && sortInfo.sortDir || null,
      propertyName: sortInfo && sortInfo.propertyName || null,
      searchText: searchText,
      showArchived: showArchived
    }
    this.props.actions.loadAllUserRoles(params);
    this.props.actions.updateUserRoleListFilters(params);
  }


  /**
   * Send selected user role keys on checkbox selection to parent component using callback
   */
  onSelectChange = (selectedRoleKeys) => {
    this.setState({ selectedRowKeys: selectedRoleKeys })
    selectedRoleKeys = map(selectedRoleKeys, Number);
    this.props.selectRowCallback(selectedRoleKeys)
  }

  handlePresetFilters = (filterData, isFirstMount) => {
    var pagination = { ...this.state.pagination };
    pagination.page = filterData.page;
    pagination.pageSize = filterData.pageSize;
    var sortInfo = null
    if (filterData.sortDir && filterData.propertyName) {
      sortInfo = {
        sortDir: filterData.sortDir,
        propertyName: filterData.propertyName,
      }
    }
    var searchText = filterData.searchText;
    this.setState({ pagination, sortInfo, searchText }, () => {
      if (isFirstMount) {
        this.loadUserRoles()
      }
    })
  }


  /**
   * deleteSelectedRoles  make API call to delete multiple roles & pass array of selected keys, on success reload list using usersRolesListRef
   */
  unarchiveRoles = (roleId) => {
    confirm({
      title: 'Confirm dialog',
      content: 'Are you sure you want to unarchive the selected role?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        this.props.actions.unarchiveRoles(roleId)
      },
      onCancel() {

      },
    });
  }
}

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import * as QuarterCloseActions from '../../actions/quarterClose/QuarterCloseActions';
import { QuarterCloseEnableW2GPage } from '../../components/quarterClose/QuarterCloseEnableW2GPage'
import { RootState } from '../../../common/store/ConfigureStore';


const mapStateToProps = (state: RootState) => {
    return {
      locationsListSimple: state.QuarterCloseReducer.locationListSimple,
      locationIdsWithoutSettings: state.QuarterCloseReducer.locationIdsWithoutSettings,
      updateLocationSettingsSuccess: state.QuarterCloseReducer.updateLocationSettingsSuccess
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(QuarterCloseActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuarterCloseEnableW2GPage)

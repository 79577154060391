
import * as React from "react";
import { isEqual, isEmpty, filter, map } from "lodash";
import moment from "moment";
import { FormComponentProps } from 'antd/lib/form/Form'
import AppUtilityService from "../../../common/services/AppUtilityService";

import { Card, Row, Col, Spin, Button, Form, Input, Icon, Select } from 'antd';
import { RangepickerComponent } from "../../../common/components/datepicker/RangepickerComponent";
import appConstants from "../../../common/AppConstants";
import { DualAxesChartComponent } from "../../../common/components/charts/DualAxesChartComponent";
import { IDataCompareProps, IDataCompareState } from './Reports';
const FormItem = Form.Item;
const Option = Select.Option;

let id = 1;

class DataCompareComponent extends React.Component<IDataCompareProps & FormComponentProps, IDataCompareState> {

  private responseDataset: any;

  constructor(props: IDataCompareProps & FormComponentProps) {
    super(props);
    this.state = {
      chartData: null,
      isLoading: false
    }
  }

  componentWillReceiveProps(nextProps: IDataCompareProps & FormComponentProps) {
    if (nextProps.reportMetricsDataCompareSuccess && !isEqual(this.props.reportMetricsDataCompareSuccess, nextProps.reportMetricsDataCompareSuccess)) {
      this.responseDataset = nextProps.reportMetricsDataCompareSuccess;
      this.props.getDataCompareCallback(nextProps.reportMetricsDataCompareSuccess);
      this.setChartData(nextProps.reportMetricsDataCompareSuccess)
    }
    if (nextProps.reportMetricsDataCompareError && !isEqual(this.props.reportMetricsDataCompareError, nextProps.reportMetricsDataCompareError)) {
      AppUtilityService.handleApiError(nextProps.reportMetricsDataCompareError);
    }



  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItems = this.getFormElements();
    const keys = this.props.form.getFieldValue('keys');

    return (
      <Card className="no-header-border no-body-padding" bordered={false} title={null} extra={null}>
        <div className="report-data-compare">
          <Form onSubmit={this.handleSubmit}>
            <Row gutter={16}>
              <Col lg={10}>
                <FormItem label="">
                  {getFieldDecorator(`names[${0}]`, {
                    rules: [{ required: true, message: 'Please select a metric type!' }],
                  })(
                    <Select placeholder="Select metric type">
                      {
                        appConstants.reportDataCompareMetrics.map((obj) => {
                          return (<Option value={obj.value} key={obj.key}>{obj.key}</Option>)
                        })
                      }
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col lg={10}>
                <FormItem label="">
                  {getFieldDecorator(`daterange[${0}]`, {
                    initialValue: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                  })(
                    <RangepickerComponent />
                  )}
                </FormItem>
              </Col>
              <Col lg={4}>
                <FormItem label="">
                  <Button key="back" onClick={this.add} disabled={keys.length === 1 ? true : false}>Add metric</Button>
                </FormItem>
              </Col>
            </Row>
            {formItems}
            <Form.Item>
              <Button type="primary" htmlType="submit">View</Button>
            </Form.Item>
          </Form>
        </div>
        <Spin spinning={this.state.isLoading}>
          {
            this.state.chartData &&
            <DualAxesChartComponent chartData={this.state.chartData} height={350} />
          }
        </Spin>
      </Card>
    )
  }
  remove = (k) => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');

    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
    this.setChartData(this.responseDataset)
  }

  add = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(id++);
    form.setFieldsValue({
      keys: nextKeys,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ isLoading: true })
        var params = [];
        values && !isEmpty(values.names) && values.names.forEach((obj, index) => {
          var dateRange = values.daterange[index];
          var value = values.names[index];
          params.push({
            dateFrom: dateRange && dateRange[0] ? AppUtilityService.formatAPIDateFilter(dateRange[0]) : null,
            dateTo: dateRange && dateRange[1] ? AppUtilityService.formatAPIDateFilter(dateRange[1]) : null,
            metricType: value
          })
        })
        this.props.actions.reportMetricsDataCompare(params);
      }
    });
  }

  getFormElements = () => {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');
    const formItems = keys.map((k, index) => (
      <Row gutter={16} key={index}>
        <Col lg={10}>
          <FormItem label="">
            {getFieldDecorator(`names[${k}]`, {
              rules: [{ required: true, message: 'Please input a first name!' }],
            })(
              <Select>
                {
                  appConstants.reportDataCompareMetrics.map((obj) => {
                    return (<Option value={obj.value} key={obj.key}>{obj.key}</Option>)
                  })
                }
              </Select>
            )}
          </FormItem>
        </Col>
        <Col lg={10}>
          <FormItem label="">
            {getFieldDecorator(`daterange[${k}]`, {
              initialValue: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            })(
              <RangepickerComponent />
            )}
          </FormItem>
        </Col>
        <Col lg={2}>
          <FormItem label="">
            <Icon className="dynamic-delete-button ml-8"
              type="minus-circle-o"
              onClick={() => this.remove(k)}
            />
          </FormItem>
        </Col>
      </Row>
    ));
    return formItems;
  }

  setChartData = (dataSet) => {
    const keys = this.props.form.getFieldValue('keys');
    var unitsArr = map(dataSet, (obj) => { return obj.metricType })
    var xAxisArr = map(dataSet, (obj) => { return map(obj.category, (dataSet) => { return dataSet.key }) })
    var yAxisArr = map(dataSet, (obj) => { return map(obj.category, (dataSet) => { return dataSet.value }) })
    let chartData = null;
    var yAxis1DataSet = filter(appConstants.reportDataCompareMetrics, (obj) => { return unitsArr[0] === obj.value })[0];
    chartData = {
      xAxis1: {
        categories: xAxisArr[0]
      },
      yAxis1: {
        title: yAxis1DataSet && yAxis1DataSet.key,
        units: yAxis1DataSet && yAxis1DataSet.type
      },
      series: [
        {
          name: yAxis1DataSet && yAxis1DataSet.key,
          xAxis: 0,
          yAxis: 0,
          data: yAxisArr[0]
        }
      ]
    }
    if (yAxisArr.length > 1 && !isEmpty(keys)) {
      chartData.xAxis2 = {
        categories: xAxisArr[1]
      };
      chartData.yAxis2 = {
        title: filter(appConstants.reportDataCompareMetrics, (obj) => { return unitsArr[1] === obj.value })[0].key,
        units: filter(appConstants.reportDataCompareMetrics, (obj) => { return unitsArr[1] === obj.value })[0].type
      };
      chartData.series.push({
        name: filter(appConstants.reportDataCompareMetrics, (obj) => { return unitsArr[1] === obj.value })[0].key,
        xAxis: 1,
        yAxis: 1,
        data: yAxisArr[1]
      });
    }
    this.setState({ chartData, isLoading: false })
  }
} export default Form.create<IDataCompareProps & FormComponentProps>()(DataCompareComponent);

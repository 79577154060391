import { createApiCaller } from '../../../common/services/ApiService';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { fetchDataWithActionCreator, LoadState } from '../../../common/store/fetched';
import { IAddUpdateInvoiceAdjustmentRequest, IInvoiceAdjustment } from '../../reducers/billing/billing.models';
import { updateAdjustmentOperationUpdated } from './slice-actions';
import { processFailureReason } from '../../../common/store/failure';
import { message } from 'antd';
import { getInvoice } from './getInvoice';

export const updateInvoiceAdjustment = (
  { invoiceId, invoiceAdjustmentId }: IInvoiceAdjustment, request: IAddUpdateInvoiceAdjustmentRequest,
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    await fetchDataWithActionCreator(
      updateAdjustmentOperationUpdated,
      dispatch,
      createApiCaller(api => api.updateData<void>(
        `/invoices/${invoiceId.toFixed(0)}/adjustments/${invoiceAdjustmentId.toFixed(0)}`,
        undefined,
        request,
      )),
      processFailureReason,
      false,
    );

    const operation = getState().BillingReducer.updateAdjustmentOperation;
    if (operation.loadState === LoadState.Failed) {
      if (
        operation.failureReason.statusCode === 400
        && operation.failureReason.response?.data?.errorCode === 'InvalidStatus'
      ) {
        message.warning('The invoice is already approved and cannot be modified.');
        dispatch(getInvoice(invoiceId, true));
      } else {
        message.error(
          `There was a problem with updating the invoice adjustment. Please try again or refresh the page.`,
          5,
        );
      }
    } else if (operation.loadState === LoadState.Success) {
      message.success(`Successfully updated invoice adjustment.`);
      dispatch(getInvoice(invoiceId, true));
    }
  };
}

import { createApiCaller } from '../../../common/services/ApiService';
import { locationInvoicingConfigDetailsUpdated } from './slice-actions';
import { fetchDataWithActionCreator } from '../../../common/store/fetched';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { processFailureReason } from '../../../common/store/failure';
import { LocationInvoicingConfigItem } from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';

export const getLocationInvoicingConfigsById = (locationId: number) =>
  async (dispatch: AppDispatch, getState: () => RootState) =>
    await fetchDataWithActionCreator(
      locationInvoicingConfigDetailsUpdated,
      dispatch,
      createApiCaller(api => api.getData<LocationInvoicingConfigItem[]>(`/locationInvoicingConfig/details/${locationId}`)),
      processFailureReason,
      true,
    )

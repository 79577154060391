import React, { useEffect, useState } from 'react';
import { Button, Card, DatePicker, Icon, message, Row, Spin } from 'antd';
import { DataGridComponent } from '../../../common/components/table/DataGridComponent';
import appConstants from '../../../common/AppConstants';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { LoadState } from '../../../common/store/fetched';
import {
  getOhioElectronicInstantBingoReport,
} from '../../actions/invoicingReports/getOhioElectronicInstantBingoReport';
import moment, { Moment } from 'moment';
import AppUtilityService from '../../../common/services/AppUtilityService';
import { updateBreadcrumbs } from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import {
  downloadOhioElectronicInstantBingoReport
} from '../../actions/invoicingReports/downloadOhioElectronicInstantBingoReport';
import LocationsAutocompleteContainer from '../../../common/containers/dropdown/LocationsAutocompleteContainer';
import { zoneIds } from '../../../common/zones';

export type Props = {};

const OhioElectronicInstantBingoPage = (props: Props) => {
  const reportsData = useAppSelector(state =>
    state.InvoicingReportsReducer.ohioElectronicInstantBingoReport.data ?? []);
  const fetchState = useAppSelector(state =>
    state.InvoicingReportsReducer.ohioElectronicInstantBingoReport.loadState);
  const downloadState = useAppSelector(state =>
    state.InvoicingReportsReducer.downloadOhioElectronicInstantBingoReportOperation.loadState);

  const isFetchingDataInProgress = fetchState === LoadState.InProgress;
  
  const [dateRange, setDateRange] = useState<[Moment | null, Moment | null] | null>(([
    moment().startOf('month').startOf('day'),
    moment().startOf('day')
  ]));
  const [locationId, setLocationId] = useState<number | null>(null);

  const handleDateChange = (dates: [Moment | null, Moment | null] | null) => {
    setDateRange(dates?.map(date => date?.startOf('day')) as [Moment, Moment] || null);
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    const breadcrumbsList = [
      { name: "Reports", link: "/Reports" },
      { name: 'Ohio Electronic Instant Bingo', link: null },
    ];
    const breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
    dispatch(updateBreadcrumbs(breadcrumbs));
  }, [dispatch]);

  useEffect(() => {
    if (downloadState === LoadState.Failed) {
      message.error('Failed to download report');
    }
  }, [downloadState]);

  useEffect(() => {
    if (fetchState === LoadState.Failed) {
      message.error('Failed to fetch results');
    }
  }, [fetchState]);

  const resetRange = async () => {
    setLocationId(null);
    setDateRange([
      moment().startOf('month').startOf('day'),
      moment().startOf('day')
    ]);
  };

  const downloadExcel = () => {
    dispatch(downloadOhioElectronicInstantBingoReport(dateRange[0], dateRange[1], locationId));
  };

  const fetchData = async () => {
    await dispatch(getOhioElectronicInstantBingoReport(dateRange[0], dateRange[1], locationId));
  };

  const validateAction =
    dateRange == null
    || dateRange.length !== 2
    || dateRange[0] == null
    || !dateRange[0].isValid()
    || dateRange[1] == null
    || !dateRange[1].isValid();

  const extraActions = (
    <div>
      <Button
        type="primary"
        onClick={downloadExcel}
        disabled={validateAction || downloadState === LoadState.InProgress}
      >
        <Icon type="download" style={{ fontSize: "16px" }}/> Download as Excel
      </Button>
    </div>
  );

  const filteringPanel = (
    <div style={{ marginTop: 5 }}>
      <LocationsAutocompleteContainer
        placeholder="Select location"
        value={locationId}
        customParams={{ zoneId: zoneIds.OH }}
        onChange={setLocationId}
        className="mr-8"
      />
      <DatePicker.RangePicker
        value={dateRange}
        onChange={handleDateChange}
        format="MM/DD/YYYY"
        className="mr-8"
      />
      <Button
        onClick={resetRange}
        type="default"
        className="mr-8"
        style={{ lineHeight: 'normal' }}
      >
        ↻
      </Button>
      <Button
        disabled={validateAction}
        loading={isFetchingDataInProgress}
        onClick={fetchData}
        type="primary"
        className="ml-8"
        style={{ lineHeight: 'normal' }}
      >
        Refresh
      </Button>
    </div>
  );

  return (
    <div className="pg-container page-container">
      <Card
        className="no-header-border"
        bordered={false}
        extra={extraActions}
        title="Ohio Electronic Instant Bingo">
        <Row>
          <Spin spinning={isFetchingDataInProgress}>
            <DataGridComponent
              toolbarContent={filteringPanel}
              dataSource={reportsData}
              dataSrcColumns={appConstants.ohElectronicInstantBingoReportColumns}
              summaryAvgColumns={null}
              summarySumColumns={null}
              isLoading={isFetchingDataInProgress}
              storeIdentifier="OhioElectronicInstantBingoReport"
              dataGridOptions={{
                wordWrapEnabled: true,
              }}
              showPageSizeSelector={true}
            />
          </Spin>
        </Row>
      </Card>
    </div>
  );
}

export default OhioElectronicInstantBingoPage;

export type UserState = Partial<{
  addNewUserError: any;
  addNewUserSuccess: any;
  assignCharityToUsersError: any;
  assignCharityToUsersSuccess: any;
  assignLocationToUsersError: any;
  assignLocationToUsersSuccess: any;
  assignZoneToUsersError: any;
  assignZoneToUsersSuccess: any;
  deleteUserError: any;
  deleteUserSuccess: any;
  enableUserError: any;
  enableUserSuccess: any;
  loadUserDetailsError: any;
  loadUserDetailsSuccess: any;
  loadUsersListError: any;
  loadUsersListSuccess: any;
  removeUserImageError: any;
  removeUserImageSuccess: any;
  unassignCharityFromUsersError: any;
  unassignCharityFromUsersSuccess: any;
  unassignLocationFromUsersError: any;
  unassignLocationFromUsersSuccess: any;
  unassignZoneFromUsersError: any;
  unassignZoneFromUsersSuccess: any;
  updateUserDetailsError: any;
  updateUserDetailsSuccess: any;
  uploadUserImageError: any;
  uploadUserImageSuccess: any;
  userListFilters: any;
}>;

const initialState: UserState = {};

const UserReducer = (state = initialState, action): UserState => {
  switch (action.type) {
    case "USER_LIST_FILTER_UPDATE":
      return { ...state, userListFilters: action.payload };

    case "LOAD_USERS_LIST_REQUEST":
      return { ...state, loadUsersListSuccess: null, loadUsersListError: null };
    case "LOAD_USERS_LIST_SUCCESS":
      return { ...state, loadUsersListSuccess: action.payload, loadUsersListError: null };
    case "LOAD_USERS_LIST_FAILURE":
      return { ...state, loadUsersListSuccess: null, loadUsersListError: action.payload };

    case "GET_USER_DETAILS_REQUEST":
      return { ...state, loadUserDetailsSuccess: null, loadUserDetailsError: null };
    case "GET_USER_DETAILS_SUCCESS":
      return { ...state, loadUserDetailsSuccess: action.payload, loadUserDetailsError: null };
    case "GET_USER_DETAILS_FAILURE":
      return { ...state, loadUserDetailsSuccess: null, loadUserDetailsError: action.payload };

    case "ADD_NEW_USER_REQUEST":
      return { ...state, addNewUserSuccess: null, addNewUserError: null };
    case "ADD_NEW_USER_SUCCESS":
      return { ...state, addNewUserSuccess: action.payload, addNewUserError: null };
    case "ADD_NEW_USER_FAILURE":
      return { ...state, addNewUserSuccess: null, addNewUserError: action.payload };

    case "UPDATE_USER_DETAILS_REQUEST":
      return { ...state, updateUserDetailsSuccess: null, updateUserDetailsError: null };
    case "UPDATE_USER_DETAILS_SUCCESS":
      return { ...state, updateUserDetailsSuccess: action.payload, updateUserDetailsError: null };
    case "UPDATE_USER_DETAILS_FAILURE":
      return { ...state, updateUserDetailsSuccess: null, updateUserDetailsError: action.payload };

    case "DELETE_USER_REQUEST":
      return { ...state, deleteUserSuccess: null, deleteUserError: null };
    case "DELETE_USER_SUCCESS":
      return { ...state, deleteUserSuccess: action.payload, deleteUserError: null };
    case "DELETE_USER_FAILURE":
      return { ...state, deleteUserSuccess: null, deleteUserError: action.payload };

    case "ENABLE_USER_REQUEST":
      return { ...state, enableUserSuccess: null, enableUserError: null };
    case "ENABLE_USER_SUCCESS":
      return { ...state, enableUserSuccess: action.payload, enableUserError: null };
    case "ENABLE_USER_FAILURE":
      return { ...state, enableUserSuccess: null, enableUserError: action.payload };

    case "UPLOAD_USER_IMAGE_REQUEST":
      return { ...state, uploadUserImageSuccess: null, uploadUserImageError: null };
    case "UPLOAD_USER_IMAGE_SUCCESS":
      return { ...state, uploadUserImageSuccess: action.payload, uploadUserImageError: null };
    case "UPLOAD_USER_IMAGE_FAILURE":
      return { ...state, uploadUserImageSuccess: null, uploadUserImageError: action.payload };

    case "REMOVE_USER_IMAGE_REQUEST":
      return { ...state, removeUserImageSuccess: null, removeUserImageError: null };
    case "REMOVE_USER_IMAGE_SUCCESS":
      return { ...state, removeUserImageSuccess: action.payload, removeUserImageError: null };
    case "REMOVE_USER_IMAGE_FAILURE":
      return { ...state, removeUserImageSuccess: null, removeUserImageError: action.payload };

    case "ASSIGN_CHARITY_TO_USERS_REQUEST":
      return { ...state, assignCharityToUsersSuccess: null, assignCharityToUsersError: null };
    case "ASSIGN_CHARITY_TO_USERS_SUCCESS":
      return { ...state, assignCharityToUsersSuccess: action.payload, assignCharityToUsersError: null };
    case "ASSIGN_CHARITY_TO_USERS_FAILURE":
      return { ...state, assignCharityToUsersSuccess: null, assignCharityToUsersError: action.payload };

    case "UNASSIGN_CHARITY_FROM_USERS_REQUEST":
      return { ...state, unassignCharityFromUsersSuccess: null, unassignCharityFromUsersError: null };
    case "UNASSIGN_CHARITY_FROM_USERS_SUCCESS":
      return { ...state, unassignCharityFromUsersSuccess: action.payload, unassignCharityFromUsersError: null };
    case "UNASSIGN_CHARITY_FROM_USERS_FAILURE":
      return { ...state, unassignCharityFromUsersSuccess: null, unassignCharityFromUsersError: action.payload };

    case "ASSIGN_LOCATION_TO_USERS_REQUEST":
      return { ...state, assignLocationToUsersSuccess: null, assignLocationToUsersError: null };
    case "ASSIGN_LOCATION_TO_USERS_SUCCESS":
      return { ...state, assignLocationToUsersSuccess: action.payload, assignLocationToUsersError: null };
    case "ASSIGN_LOCATION_TO_USERS_FAILURE":
      return { ...state, assignLocationToUsersSuccess: null, assignLocationToUsersError: action.payload };

    case "UNASSIGN_LOCATION_FROM_USERS_REQUEST":
      return { ...state, unassignLocationFromUsersSuccess: null, unassignLocationFromUsersError: null };
    case "UNASSIGN_LOCATION_FROM_USERS_SUCCESS":
      return { ...state, unassignLocationFromUsersSuccess: action.payload, unassignLocationFromUsersError: null };
    case "UNASSIGN_LOCATION_FROM_USERS_FAILURE":
      return { ...state, unassignLocationFromUsersSuccess: null, unassignLocationFromUsersError: action.payload };

    case "ASSIGN_ZONE_TO_USERS_REQUEST":
      return { ...state, assignZoneToUsersSuccess: null, assignZoneToUsersError: null };
    case "ASSIGN_ZONE_TO_USERS_SUCCESS":
      return { ...state, assignZoneToUsersSuccess: action.payload, assignZoneToUsersError: null };
    case "ASSIGN_ZONE_TO_USERS_FAILURE":
      return { ...state, assignZoneToUsersSuccess: null, assignZoneToUsersError: action.payload };

    case "UNASSIGN_ZONE_FROM_USERS_REQUEST":
      return { ...state, unassignZoneFromUsersSuccess: null, unassignZoneFromUsersError: null };
    case "UNASSIGN_ZONE_FROM_USERS_SUCCESS":
      return { ...state, unassignZoneFromUsersSuccess: action.payload, unassignZoneFromUsersError: null };
    case "UNASSIGN_ZONE_FROM_USERS_FAILURE":
      return { ...state, unassignZoneFromUsersSuccess: null, unassignZoneFromUsersError: action.payload };

    default:
      return state;
  }
}

export default UserReducer;

import { Select } from "antd"
import { useAppDispatch, useAppSelector } from "../../../common/hooks/storeHooks"
import { forwardRef, useEffect, useMemo } from "react";
import { round } from "lodash";
import { LoadState } from "../../../common/store/fetched";
import { getTaxRates } from "../../actions/locationInvoicingConfig/getTaxRates";
import appUtilityService from "../../../common/services/AppUtilityService";

const TaxRatesSelector = (
  { value, locationId, onChange }: {
    value?: number[],
    locationId: number,
    onChange?: (value: number[]) => void,
  },
  ref: React.Ref<Select>,
) => {
  const taxRates = useAppSelector(state => state.LocationInvoicingConfigReducer.taxRates);
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(getTaxRates({ locationId }));
  }, [dispatch, locationId]);

  useEffect(() => {
    if (taxRates.loadState === LoadState.Failed) {
      appUtilityService.handleApiError(taxRates.failureReason);
    }
  }, [taxRates]);

  const options = useMemo(() => taxRates.data?.map(x => (
    <Select.Option value={x.taxRateId}>
      {x.name} ({round(x.value * 100, 2)}%)
    </Select.Option>
  )) ?? [], [taxRates]);

  return (
    <Select
      ref={ref}
      mode="multiple"
      value={value}
      onChange={onChange}
      children={options}
      loading={taxRates.loadState === LoadState.InProgress}
    />
  );
}

export default forwardRef(TaxRatesSelector);


import * as React from "react";
import { isEqual } from "lodash";
import { FormComponentProps } from 'antd/lib/form/Form'
import ContactsAutocompleteContainer from "../../../common/containers/dropdown/ContactsAutocompleteContainer";
import AppUtilityService from "../../../common/services/AppUtilityService";
import LocationsAutocompleteContainer from "../../../common/containers/dropdown/LocationsAutocompleteContainer";

import { Form, Input, Row, Col, Avatar } from 'antd';
import ContactTypesDropdownContainer from '../../../common/containers/dropdown/ContactTypesDropdownContainer';
import { Hide, Label } from "devextreme-react/data-grid";
import { visibility } from "html2canvas/dist/types/css/property-descriptors/visibility";
const FormItem = Form.Item;

class SaveContactNoteFormComponent extends React.Component<ISaveContactNoteFormProps & FormComponentProps, ISaveContactNoteFormState> {

    constructor(props: ISaveContactNoteFormProps & FormComponentProps) {
        super(props);
        this.state = {
            contactNote: this.props.contactNote,
            contactNoteSaveApproach: this.props.contactNoteSaveApproach,
            isEditContactNote: this.props.isEditContactNote || false
        }
    }

    componentWillReceiveProps(nextProps: ISaveContactNoteFormProps) {
        if (!isEqual(this.props.contactNote, nextProps.contactNote)) {
            this.setState({ contactNote: nextProps.contactNote })
        }

        if (!isEqual(this.props.contactNoteSaveApproach, nextProps.contactNoteSaveApproach)) {
            this.setState({ contactNoteSaveApproach: nextProps.contactNoteSaveApproach })
        }

        if (!isEqual(this.props.isEditContactNote, nextProps.isEditContactNote)) {
            this.setState({ isEditContactNote: nextProps.isEditContactNote })
        }
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form>
                {
                    this.state.contactNoteSaveApproach === "edit" ? 
                    <div style={{ paddingTop: this.state.isEditContactNote ? "20px" : "0" }}>
                            <FormItem label="Note">
                                {getFieldDecorator('Note', {
                                    initialValue: this.state.contactNote && this.state.contactNote.note,
                                    rules: [{ required: true, message: 'Please edit note here!' }],
                                })(
                                    <Input autoFocus placeholder={this.state.contactNote.note} />
                                )}
                            </FormItem>
                        </div>
                    :                     
                        <div style={{ paddingTop: !this.state.isEditContactNote ? "20px" : "0" }}>
                            <FormItem label="Note">
                                {getFieldDecorator('Note', {
                                    initialValue: this.state.contactNote && this.state.contactNote.note,
                                    rules: [{ required: true, message: 'Please input note here!' }],
                                })(
                                    <Input autoFocus placeholder="Please input note here" />
                                )}
                            </FormItem>
                        </div>
                }
            </Form>
        )
    }
} export default Form.create<ISaveContactNoteFormProps & FormComponentProps>()(SaveContactNoteFormComponent);

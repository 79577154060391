import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ContactsActions from '../../actions/contacts/ContactsActions';
import { ContactNotesCardComponent } from '../../components/contacts/ContactNotesCardComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    contactDetailsSuccess: state.ContactsReducer.loadDetailsSuccess,
    contactDetailsError: state.ContactsReducer.loadDetailsError,

    addContactNoteSuccess: state.ContactsReducer.addContactNoteSuccess,
    addContactNoteError: state.ContactsReducer.addContactNoteError,

    updateContactNoteSuccess: state.ContactsReducer.updateContactNoteSuccess,
    updateContactNoteError: state.ContactsReducer.updateContactNoteError,

    deleteContactNoteSuccess: state.ContactsReducer.deleteContactNoteSuccess,
    deleteContactNoteError: state.ContactsReducer.deleteContactNoteError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ContactsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactNotesCardComponent)
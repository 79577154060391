import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as DashboardActions from '../../actions/dashboard/DashboardActions';
import * as BreadcrumbActions from '../../../common/actions/breadcrumbs/BreadcrumbActions';

import { DashboardPage } from '../../components/dashboard/DashboardPage';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    dashboardFilters: state.DashboardReducer.dashboardFilters,

    loadDashboardMiniStatsSuccess: state.DashboardReducer.loadDashboardMiniStatsSuccess,
    loadDashboardChartStatsSuccess: state.DashboardReducer.loadDashboardChartStatsSuccess,
    loadDashboardMapStatsSuccess: state.DashboardReducer.loadDashboardMapStatsSuccess,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...DashboardActions, ...BreadcrumbActions }, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage)

import * as React from 'react';
import { hashHistory } from 'react-router';
import BreadcrumbContainer from '../../containers/breadcrumbs/BreadcrumbContainer';
import { NotificationDrawerComponent } from './NotificationDrawerComponent';
import SessionService from '../../services/SessionService';
import { signOut } from 'aws-amplify/auth';
import { Layout, Row, Col, Avatar, Dropdown, Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const { Header } = Layout;

export class HeaderComponent extends React.Component<IHeaderProps, IHeaderState> {
  constructor(props: IHeaderProps) {
    super(props);
    this.state = {
      isNotificationsVisible: false,
    };
  }

  render() {
    const menu = (
      <Menu>
        <Menu.Item onClick={this.logout}>
          <a target="_blank">Logout</a>
        </Menu.Item>
        <Menu.Item onClick={this.changePassword}>
          <a target="_blank">Change Password</a>
        </Menu.Item>
        <Menu.Item onClick={this.setupMfa}>
          <a target="_blank">Setup MFA</a>
        </Menu.Item>
      </Menu>
    );
    return (
      <Header className="pg-header" style={{paddingRight:0}}>
        <Row>
          <Col lg={9} md={10} sm={10} xs={8}>
            <BreadcrumbContainer />
          </Col>
          <Col lg={14} md={11} sm={11} xs={13} style={{ textAlign: 'right' }}>
            {SessionService.UserName}
          </Col>

          <Col lg={1} md={3} sm={3} xs={3} style={{ textAlign: 'center' }}>
            <Dropdown trigger={['click']} overlay={menu}>
              <Avatar size="large" icon={<UserOutlined />} />
            </Dropdown>
          </Col>
        </Row>
        {this.state.isNotificationsVisible && <NotificationDrawerComponent closeNotificationsCallback={this.handleNotificationsClose} />}
      </Header>
    );
  }

  showNotifications = () => {
    this.setState({ isNotificationsVisible: true });
  };
  handleNotificationsClose = () => {
    this.setState({ isNotificationsVisible: false });
  };
  logout = async () => {
    if (SessionService.loggedIn()) {
      await signOut();
      this.props.actions.clearStore();
      hashHistory.push('/login');
    }
  };
  changePassword = () => {
    hashHistory.push('/changePassword');
  };
  setupMfa = () => {
    hashHistory.push('/otp-setup');
  };
}

export interface IPagedInvoice {
  total: number;
  invoices: IInvoiceListItem[];
}

export interface IInvoiceListItem {
  invoiceId: number;
  invoiceNo: string;
  invoicePeriodFrom: string;
  invoicePeriodTo: string;
  locationId: number;
  locationName: string;
  itemsTotalCashIn: number | null;
  itemsTotalCashOut: number | null;
  itemsTotalCashNet: number | null;
  totalAdjustmentsAmount: number;
  invoiceAmountDue: number;
  invoiceStatusId: InvoiceStatus;
  hasOpenValidationIssues: boolean;
}

export enum InvoiceStatus {
  PendingReview = 1,
  Approved = 2,
  Sent = 3,
}

export enum InvoiceUpdateStatusResult {
  Success = 1,
  Skipped = 2,
  NotFound = 3,
  Forbidden = 4,
  HasOpenValidationIssues = 5,
  InvalidStatus = 7,
}

export type InvoiceSortField =
  'invoiceNo' |
  'invoicePeriodFrom' |
  'locationId' |
  'locationName' |
  'itemsTotalCashIn' |
  'itemsTotalCashOut' |
  'itemsTotalCashNet' |
  'totalAdjustmentsAmount' |
  'invoiceAmountDue' |
  'invoiceStatusId';

export interface IGetInvoicesParams {
  zoneId?: number;
  locationId?: number;
  invoiceNo?: string;
  status?: InvoiceStatus;
  withValidationIssuesOnly?: boolean;
  dateFrom?: string;
  dateTo?: string;
  pageNo?: number;
  pageSize?: number;
  sortBy: InvoiceSortField;
  sortAsc: boolean;
}

export interface IInvoice {
  invoiceId: number;
  locationId: number;
  invoicingSystemId: number;
  invoiceStatusId: InvoiceStatus;
  invoiceDate: string;
  invoiceNo: string;
  distributorId: number | null;
  invoicingContactId: number | null;
  invoicePeriodFrom: string;
  invoicePeriodTo: string;
  itemsTotalCashIn: number | null;
  itemsTotalCashOut: number | null;
  itemsTotalCashNet: number | null;
  itemsTotalAmountDue: number;
  itemsTotalPrizesWon: number | null;
  itemsTotalTicketsPlayed: number | null;
  itemsTotalTicketsPlayedCount: number | null;
  totalAdjustmentsAmount: number;
  invoiceAmountDue: number;
  stateFeeDue: number;
  forwardCreditAmount: number;
  totalAmountDue: number;
  locationIncome: number | null;
  dueInDays: number;
  dueDate: string | null;
  amountDuePercentage: number;
  hasOpenValidationIssues: boolean;
  comment: string;
  created: string;
  createdByUserId: number | null;
  modified: string;
  modifiedByUserId: number | null;
  location: IInvoiceLocation;
  distributor: IDistributor | null;
  invoicingContact: IInvoiceContact | null;
  items: IInvoiceItem[];
  customItems: IInvoiceCustomItem[];
  invoiceAdjustments: IInvoiceAdjustment[];
  invoiceValidationIssues: IInvoiceValidationIssue[];
  deliveredDeals: IInvoiceDeliveredDeal[];
  distributorContact: IInvoiceContact | null;
  emailRecipients: IInvoiceEmailRecipient[];
  emailBodyTemplate: string;
  emailSubjectTemplate: string;
  totalCashNetDeduction: number | null;
  ticketsSoldFee: number;
  ticketsSoldFeeSalesTax: number;
  feeDiscount: number;
  invoiceAtachmentPreviews: InvoiceAtachmentPreview[];
  billingName: string;
  billingAddress: IAddress | null;
  license: string | null;
  licenseExpiryDate: string | null;
  totalNetTaxRate: number | null;
  totalGrossTaxRate: number | null;
  totalGrossTaxRateValue: number | null;
  totalNetTaxRateValue: number | null;
}

export interface InvoiceAtachmentPreview {
  fileName: string;
  type: InvoiceAttachmentType;
  invoiceAttachmentId: number;
}
export interface IInvoiceLocation {
  locationId: number;
  name: string;
  license: string;
  address: IAddress | null;
  licenseExpiryDate: string | null;
}

export interface IDistributor {
  distributorId: number;
  name: string;
  zoneId: number | null;
  addressId: number | null;
  active: boolean | null;
  license: string | null;
  email: string | null;
  address: IAddress | null;
}

export interface IInvoiceContact {
  contactId: number;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  jobTitle: string | null;
}

export interface IInvoiceItem {
  invoiceItemId: number;
  invoiceId: number;
  invoicingSystemId: number;
  itemDate: string;
  cashIn: number | null;
  cashOut: number | null;
  cashNet: number | null;
  amountDue: number;
  created: string;
  createdByUserId: number | null;
  modified: string;
  modifiedByUserId: number | null;
  ticketsPlayed: number | null;
  ticketsPlayedCount: number | null;
  prizesWon: number | null;
  isCustom: boolean;
}

export interface IInvoiceCustomItem {
  invoiceCustomItemId: number;
  invoiceId: number;
  name: string;
  value: number;
  amountDue: number;
  created: string;
  createdByUserId: number | null;
  modified: string;
  modifiedByUserId: number | null;
}

export interface IInvoiceAdjustment {
  invoiceAdjustmentId: number;
  invoiceId: number;
  invoiceAdjustmentTypeId: InvoiceAdjustmentType;
  adjustmentAmount: number;
  isPendingCreditApplication: boolean;
  comment: string;
  created: string;
  createdByUserId: number | null;
  modified: string;
  modifiedByUserId: number | null;
}

export interface IInvoiceDeliveredDeal {
  invoiceDeliveredDealId: number;
  invoiceId: number;
  sourceId: number;
  serialNo: string;
  gameName: string;
  tabPrice: number | null;
  dateDelivered: string;
  charityName: string;
}

export interface IInvoiceEmailRecipient {
  recipientName: string | null;
  email: string;
  sendStatus: InvoiceEmailSendStatus | null;
  sentDate: string | null;
  recipientFirstName: string | null;
  recipientLastName: string | null;
}

export enum InvoiceEmailSendStatus {
  Pending = 1,
  Success = 2,
  Failed = 3,
  Cancelled = 4,
}

export interface IAddress {
  id: number;
  addressType: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  addressState: string | null;
  zipcode: string | null;
}

export enum InvoiceAdjustmentType {
  Credit = 1,
  Promo = 2,
  Contractual = 3,
}

export interface IInvoiceValidationIssue {
  invoiceValidationIssueId: number;
  invoiceId: number;
  invoiceValidationTypeId: InvoiceValidationType;
  message: string;
  isResolved: boolean;
  created: string;
  createdByUserId: number | null;
  modified: string;
  modifiedByUserId: number | null;
}

export enum InvoiceValidationType {
  EnoughSalesData = 1,
  CashNetNotBelowLowerThreshold = 2,
  CashNetNotAboveUpperThreshold = 3,
}

export enum InvoiceAttachmentType {
  InvoicePdf = 1,
  DeliveredDeals = 2,
  Custom = 3
}

export interface IUpdateValidationIssueRequest {
  isResolved: boolean;
}

export interface IUpdateValidationIssueContext {
  invoiceId: number;
  invoiceValidationIssueId?: number;
  request: IUpdateValidationIssueRequest;
}

export interface IUpdateInvoiceStatusRequest {
  currentStatus: InvoiceStatus;
  targetStatus: InvoiceStatus;
}

export interface IUpdateInvoiceStatusContext {
  invoiceId: number;
  request: IUpdateInvoiceStatusRequest;
}

export interface IAddUpdateInvoiceAdjustmentRequest {
  invoiceAdjustmentTypeId: InvoiceAdjustmentType,
  adjustmentAmount: number,
  comment: string | null;
}

export interface IDeleteInvoiceAdjustmentContext {
  invoiceId: number;
  invoiceAdjustmentId: number;
}

export interface IUpdateBulkInvoicesStatusRequest {
  invoiceIds: number[];
  targetStatus: InvoiceStatus;
}

export interface IUpdateBulkInvoiceStatusContext {
  invoiceIds: number[];
  request: IUpdateBulkInvoicesStatusRequest;
}

export interface IUpdateBulkInvoiceResult {
  invoiceId: number;
  result: InvoiceUpdateStatusResult;
}

export interface IInvoiceRegenerationRequest {
  invoiceIds: number[];
}

export interface IInvoicesGenerationRequest {
  invoiceIds: number[];
}

export interface IInvoiceGenerationResult {
  locationId: number | null;
  locationName: string | null;
  invoiceId: number | null;
  periodStart: string | null;
  periodEnd: string | null;
  errorMessage: string | null;
  errorDetails: string | null;
}

export interface IInvoicesGenerationResponse {
  invoices: IInvoiceGenerationResult[];
  errorMessage: string | null;
  errorDetails: string | null;
}

export interface IAddUpdateInvoiceCustomItemRequest {
  value: number,
  name: string;
}

export interface IDeleteInvoiceItemContext {
  invoiceId: number;
  invoiceItemId: number;
}

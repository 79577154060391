import { Avatar, Card, Col, Row } from 'antd';
import { IInvoice, IInvoiceContact, InvoiceStatus } from '../../reducers/billing/billing.models';
import appUtilityService from '../../../common/services/AppUtilityService';
import WebAccessService from '../../../common/services/WebAccessService';
import { Link } from 'react-router';
import { InvoiceItems } from './InvoiceItems';
import { InvoiceAdjustments } from './InvoiceAdjustments';
import { InvoiceNotes } from './InvoiceNotes';
import { InvoiceSummary } from './InvoiceSummary';
import { InvoiceValidationIssues } from './InvoiceValidationIssues';
import { InvoiceActions } from './InvoiceActions';
import AppUtilityService from '../../../common/services/AppUtilityService';
import { InvoiceEmailDetails } from './InvoiceEmailDetails';

interface IInvoiceDetailsProps {
  invoice: IInvoice;
}

export function InvoiceDetails({ invoice }: IInvoiceDetailsProps) {

  const { invoiceStatusId, distributor, location, invoicingContact, distributorContact, billingName, billingAddress } = invoice;

  const distributorLines = distributor != null
    ? [
      distributor.license ? `License ${distributor.license}` : null,
      distributor.email,
      appUtilityService.displayStringFromArray([distributor.address?.address1, distributor.address?.address2]),
      appUtilityService.displayStringFromArray([distributor.address?.city, distributor.address?.addressState, distributor.address?.zipcode]),
    ].filter(line => !!line?.trim())
    : [];

  const billingLines = [
    appUtilityService.displayStringFromArray([billingAddress?.address1, billingAddress?.address2]),
    appUtilityService.displayStringFromArray([billingAddress?.city, billingAddress?.addressState, billingAddress?.zipcode]),
    invoice.license ? `License #${invoice.license} ${invoice.licenseExpiryDate ? `(valid to: ${AppUtilityService.formatDate(invoice.licenseExpiryDate)})` : ''}` : null
  ].filter(line => !!line?.trim());

  const contactName = (contact: IInvoiceContact) =>
    [contact?.firstName, contact?.lastName].map(x => x?.trim()).filter(x => !!x).join(' ');

  const contactLines = (contact: IInvoiceContact) => {
    return contact != null
      ? [
        contact.jobTitle,
        contact.phone,
        contact.email
      ].filter(line => !!line?.trim())
      : []
  };

  const canViewLocation = WebAccessService.hasPermissionToAccess("Location", "View");
  const canViewContact = WebAccessService.hasPermissionToAccess("Contact", "View");

  return (
    <Card className="content-card no-header-border" bordered={false}>
      <Row gutter={[24, 24]}>
        <Col span={10}>
          <h1>
            Invoice {invoice.invoiceNo}
          </h1>
        </Col>
        <Col span={14} className="text-right">
          <InvoiceActions invoice={invoice} />
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={14}>
          <div>
            <span className="t-black bold">Status: </span>
            <span className="t-black-light">
              {formatInvoiceStatus(invoice)}
            </span>
          </div>
          <div>
            <span className="t-black bold">Invoice Period: </span>
            <span className="t-black-light">
              {appUtilityService.formatDate(invoice.invoicePeriodFrom, 'full')} - {appUtilityService.formatDate(invoice.invoicePeriodTo, 'full')}
            </span>
          </div>
          <div>
            <span className="t-black bold">Invoice Date: </span>
            <span className="t-black-light">
              {invoiceStatusId === InvoiceStatus.PendingReview
                ? (<span className="t-grey-dark">set once invoice is approved</span>)
                : appUtilityService.formatDate(invoice.invoiceDate, 'full')}
            </span>
          </div>
          <div>
            <span className="t-black bold">Due In: </span>
            <span className="t-black-light">
              {invoice.dueInDays.toFixed(0)} {invoice.dueInDays !== 1 ? 'days' : 'day'}
              <span className="t-grey-dark"> after invoice date</span>
            </span>
          </div>
          <div>
            <span className="t-black bold">Due Date: </span>
            <span className="t-black-light">
              {invoiceStatusId === InvoiceStatus.PendingReview
                ? (<span className="t-grey-dark">set once invoice is approved</span>)
                : appUtilityService.formatDate(invoice.dueDate, 'full')}
            </span>
          </div>
          <Row gutter={[24, 24]} className="mt-8">
            <Col span={12}>
              <div style={{ display: "inline-block", verticalAlign: "top", marginTop: "2px" }}>
                <Avatar size="small" shape="square" className="mr-8 avatar-square" style={{ backgroundColor: '#F8CA1C' }}>
                  <i className="icon-location" />
                </Avatar>
              </div>
              <div style={{ display: "inline-block" }}>
                {
                  canViewLocation ? (
                    <Link to={`/locations/${location.locationId}`}>
                      <div className="link-primary font-18 bold" style={{ marginBottom: 0, lineHeight: "20px" }}>{billingName}</div>
                    </Link>
                  ) : (
                    <div className="font-18 bold" style={{ marginBottom: 0, lineHeight: "20px" }}>{billingName}</div>
                  )
                }
                <div>
                  {billingLines.map((line, i) => (<div key={i}>{line}</div>))}
                </div>
              </div>
            </Col>
            <Col span={12}>
              {invoicingContact && (<>
                <div style={{ display: "inline-block", verticalAlign: "top", marginTop: "2px" }}>
                  <Avatar size="small" shape="square" className="mr-8 avatar-square" style={{ backgroundColor: '#F8CA1C' }}>
                    <i className="icon-user" />
                  </Avatar>
                </div>
                <div style={{ display: "inline-block" }}>
                  {
                    canViewContact ? (
                      <Link to={`/contacts/${invoicingContact.contactId}`}>
                        <div className="link-primary font-18 bold" style={{ marginBottom: 0, lineHeight: "20px" }}>{contactName(invoicingContact)}</div>
                      </Link>
                    ) : (
                      <div className="font-18 bold" style={{ marginBottom: 0, lineHeight: "20px" }}>{contactName(invoicingContact)}</div>
                    )
                  }
                  <div>
                    {contactLines(invoicingContact).map((line, i) => (<div key={i}>{line}</div>))}
                  </div>
                </div>
              </>)}
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          {distributor != null && (<>
            {distributor.name && (<h4>{distributor.name}</h4>)}
            {distributorLines.map((line, i) => (<div key={i}>{line}</div>))}
            {distributorContact && (<>
              <div style={{ display: "inline-block", paddingTop: "0.5rem" }}>
                {
                  canViewContact ? (
                    <Link to={`/contacts/${distributorContact.contactId}`}>
                      <div className="link-primary" style={{ marginBottom: 0, lineHeight: "20px" }}>{contactName(distributorContact)}</div>
                    </Link>
                  ) : (
                    <div style={{ marginBottom: 0, lineHeight: "20px" }}>{contactName(distributorContact)}</div>
                  )
                }
                <div>
                  {contactLines(distributorContact).map((line, i) => (<div key={i}>{line}</div>))}
                </div>
              </div>
            </>)} </>)}
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={14}>
          <InvoiceItems invoice={invoice} />
          <InvoiceAdjustments invoice={invoice} />
        </Col>
        <Col span={10}>
          <InvoiceSummary invoice={invoice} />
          <InvoiceValidationIssues invoice={invoice} />
          <InvoiceNotes invoice={invoice} />
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={14}>
          <InvoiceEmailDetails invoice={invoice} />
        </Col>
      </Row>
    </Card>
  );
}

function formatInvoiceStatus({ invoiceStatusId, hasOpenValidationIssues }: IInvoice) {
  switch (invoiceStatusId) {
    case InvoiceStatus.PendingReview:
      return hasOpenValidationIssues
        ? <span className='text-negative'>Pending, with validation issues</span>
        : 'Pending';
    case InvoiceStatus.Approved:
      return <span className='text-positive'>Approved</span>;
    case InvoiceStatus.Sent:
      return <span className='text-positive'>Sent</span>;
    default:
      return '';
  }
}

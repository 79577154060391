
import * as React from "react";
import { isEqual } from "lodash";
import { hashHistory } from "react-router";
import AppUtilityService from "../../../common/services/AppUtilityService";
import UserRoleDetailsFormComponent from "./UserRoleDetailsFormComponent";

import { Card, Button, Modal, Row, Col, Avatar, Spin } from 'antd';
const confirm = Modal.confirm;

export class RoleDetailsDescriptionCardComponent extends React.Component<IRoleDetailsDescriptionProps, IRoleDetailsDescriptionState> {

  private userRolesFormRef: any;

  constructor(props: IRoleDetailsDescriptionProps) {
    super(props);
    this.state = {
      roleDetails: this.props.roleDetails || null,
      isBasicDetailsEditMode: false,
      isLoading: this.props.roleDetails ? false : true
    }
  }

  componentWillReceiveProps(nextProps: IRoleDetailsDescriptionProps) {
    if (nextProps.roleDetails && !isEqual(this.props.roleDetails, nextProps.roleDetails)) {
      this.setState({ roleDetails: nextProps.roleDetails, isLoading: false })
    }

    /**
     * Update selected user role success/error handle
     */
    if (nextProps.updateSelectedUserRoleSuccess && !isEqual(this.props.updateSelectedUserRoleSuccess, nextProps.updateSelectedUserRoleSuccess)) {
      var roleDetails = { ...this.state.roleDetails };
      let responseData = nextProps.updateSelectedUserRoleSuccess;
      roleDetails.name = responseData.name;
      roleDetails.description = responseData.description;
      this.setState({ roleDetails, isLoading: false });
      this.cancelEditDetails();
      AppUtilityService.showMessageToast("User role has been updated successfully", "success");
    }
    if (nextProps.updateSelectedUserRoleError && !isEqual(this.props.updateSelectedUserRoleError, nextProps.updateSelectedUserRoleError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.updateSelectedUserRoleError);
    }
    /**
     * Delete selected user role success/error handle
     */
    if (nextProps.deleteSelectedUserRoleSuccess !== null && !isEqual(this.props.deleteSelectedUserRoleSuccess, nextProps.deleteSelectedUserRoleSuccess)) {
      this.setState({ isLoading: false })
      if (nextProps.deleteSelectedUserRoleSuccess) {
        AppUtilityService.showMessageToast("Selected role has been deleted", "success");
        hashHistory.push("/admin/user-role");
      } else {
        AppUtilityService.showMessageToast("Selected role cannot be deleted since there are users assigned to this role", "warning");
      }
    }
    if (nextProps.deleteSelectedUserRoleError && !isEqual(this.props.deleteSelectedUserRoleError, nextProps.deleteSelectedUserRoleError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.deleteSelectedUserRoleError);
    }
  }

  render() {
    var cardTitle = <div>
      <h2 style={{ marginBottom: 0 }}>
        <Avatar shape="square" className="mr-8 avatar-square">
          <i className="icon-user-role-filled" />
        </Avatar>
        {this.state.roleDetails && this.state.roleDetails.name}
      </h2>
    </div>

    var cardActions = <span className="user-role-detail-filters">
      <Button type="primary" onClick={this.showEditDetailsForm}>Edit</Button>
      <Button className="ml-8" onClick={this.deleteRole}>Delete</Button>
    </span>

    return (
      <Spin spinning={this.state.isLoading}>
        <Card className="no-header-border" bordered={false}
          title={this.state.roleDetails && cardTitle}
          extra={this.state.roleDetails && !this.state.roleDetails.isDefault ? cardActions : null}
        >
          {
            this.state.roleDetails && !this.state.isBasicDetailsEditMode && this.state.roleDetails.description
          }
          {
            this.state.roleDetails && this.state.isBasicDetailsEditMode &&
            <Row>
              <Col span={12}>
                <UserRoleDetailsFormComponent ref={(ref) => this.userRolesFormRef = ref} roleDetails={this.state.roleDetails} />
                <Button className="mr-8" type="primary" onClick={this.handleFormSubmit}>Save</Button>
                <Button onClick={this.cancelEditDetails}> Cancel </Button>
              </Col>
            </Row>
          }
        </Card>
      </Spin>
    )
  }

  /**
   * Handle role details form submit using ref
   */
  handleFormSubmit = (e) => {
    e.preventDefault();
    this.userRolesFormRef.validateFields((err, values) => {
      if (!err) {
        this.setState({ isLoading: true })
        var { roleDetails } = this.state;
        var formData = values;
        formData.roleId = roleDetails.roleId;
        this.props.actions.updateSelectedRoleDetails(roleDetails.roleId, formData)
      }
    });
  }

  /**
   * Toggle between details view and edit form
   */
  showEditDetailsForm = () => {
    this.setState({ isBasicDetailsEditMode: true })
  }
  cancelEditDetails = () => {
    this.setState({ isBasicDetailsEditMode: false })
  }

  /**
   * Handle delete role on confirming confirm prompt
   */
  deleteRole = () => {
    var { roleDetails } = this.state;
    confirm({
      title: 'Confirm dialog',
      content: 'Are you sure you want to delete the selected role?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        this.setState({ isLoading: true })
        this.props.actions.deleteSelectedUserRole(roleDetails.roleId)
      },
    });
  }

}

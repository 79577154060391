import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ContactsActions from '../../actions/contacts/ContactsActions';
import { ContactDetailsComponent } from '../../components/contacts/ContactDetailsComponent';
import { RootState } from '../../../common/store/ConfigureStore';
import { stat } from 'fs';

const mapStateToProps = (state: RootState) => {
  return {
    contactDetailsSuccess: state.ContactsReducer.loadDetailsSuccess,
    contactDetailsError: state.ContactsReducer.loadDetailsError,

    updateContactBasicDetailsSuccess: state.ContactsReducer.updateContactBasicDetailsSuccess,
    updateContactBasicDetailsError: state.ContactsReducer.updateContactBasicDetailsError,

    deleteContactSuccess: state.ContactsReducer.deleteContactSuccess,
    deleteContactError: state.ContactsReducer.deleteContactError,

    updateContactImageSuccess: state.ContactsReducer.updateContactImageSuccess,
    updateContactImageError: state.ContactsReducer.updateContactImageError,

    removeContactImageSuccess: state.ContactsReducer.removeContactImageSuccess,
    removeContactImageError: state.ContactsReducer.removeContactImageError,
    
    addContactNoteSuccess: state.ContactsReducer.addContactNoteSuccess,
    addContactNoteError: state.ContactsReducer.addContactNoteError,

    updateContactNoteSuccess: state.ContactsReducer.updateContactNoteSuccess,
    updateContactNoteError: state.ContactsReducer.updateContactNoteError,

    deleteContactNoteSuccess: state.ContactsReducer.deleteContactNoteSuccess,
    deleteContactNoteError: state.ContactsReducer.deleteContactNoteError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ContactsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetailsComponent)

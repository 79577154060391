import React from 'react';
import { TicketSalesByDateReportItem } from '../../reducers/reporting/reporting.models';
import { DonutChartComponent } from '../../../common/components/charts/DonutChartComponent';
import AppUtilityService from '../../../common/services/AppUtilityService';
import { ChartTabularLegendsComponent } from '../../../common/components/charts/ChartTabularLegendsComponent';

export type Props = {
  data: TicketSalesByDateReportItem[];
};

const TicketSalesPayoutPercentageChart = ({ data }: Props) => {
  const totals = data.reduce(
    (acc, curr) => {
      acc.grossRevenue += curr.grossRevenue;
      acc.currentNetRevenue += curr.currentNetRevenue;
      acc.payout += curr.payout;
      return acc;
    },
    { currentNetRevenue: 0, grossRevenue: 0, payout: 0 });
  const percentage = totals.payout / totals.grossRevenue * 100;
  const percentageFormatted = isNaN(percentage) ? null : AppUtilityService.shortenLargeNumber(percentage, 2) + '%';
  const chartData: IDonutChartProps['chartData'] = {
    title: {
      html: `<div class="bold text-center font-18 t-grey-dark">${percentageFormatted ?? '-'}</div>`,
      css: {
        color: '#A6ADC9',
        fontSize: 18,
      },
    },
    subTitle: {
      html: `<div class="font-12">Payout Percentage</div>`,
      css: {
        color: '#4572A7',
        fontSize: 12,
      },
    },
    data: [
      {
        name: "Gross Revenue",
        y: totals.grossRevenue,
      },
      {
        name: "Prizes Awarded",
        y: totals.payout,
      },
      {
        name: "Current Net Revenue",
        y: Math.abs(totals.currentNetRevenue),
      },
    ],
    units: 'currency',
  }
  return (
    <>
      <DonutChartComponent
        id="payoutPercentageChart"
        height={400}
        responsiveHeight={400}
        chartData={chartData}
        showLegend
        isVerticalLegend={false}
      />
      <ChartTabularLegendsComponent
        id="payoutPercentageLegend"
        chartData={chartData}
      />
    </>
  );
}

export default TicketSalesPayoutPercentageChart;

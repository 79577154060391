import * as React from "react";
import { isEqual, filter, isEmpty, startCase } from "lodash";

import AppUtilityService from "../../../../common/services/AppUtilityService";
import { DonutChartComponent } from "../../../../common/components/charts/DonutChartComponent";
import { ChartTabularLegendsComponent } from "../../../../common/components/charts/ChartTabularLegendsComponent";
import { ReportSummaryCardComponent } from "../ReportSummaryCardComponent";
import DataCompareContainer from "../../../containers/reports/DataCompareContainer";
import { DataGridComponent } from "../../../../common/components/table/DataGridComponent";
import ReportsUtilityService from "../../../../common/services/ReportsUtilityService";
import AppConstants from "../../../../common/AppConstants";

import { Row, Col, Spin, Tabs, Radio } from 'antd';
import { IReconSummaryReportComponentProps, IReconSummaryReportComponentState } from '../Reports';
const TabPane = Tabs.TabPane;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

export class ReconSummaryReportComponent extends React.Component<IReconSummaryReportComponentProps, IReconSummaryReportComponentState> {

  private gridContainerRef: any;

  constructor(props: IReconSummaryReportComponentProps) {
    super(props);
    this.state = {
      reconSummaryChart: null,
      reconSummaryList: null,
      reconSummaryInfo: null,
      reconSummaryByDateChildList: null,
      isLoading: false,
      isLoadingCharts: false,
      isLoadingList: false,
      isLoadingChildList: false,
      isLoadingSummaryInfo: false,
      downloadType: "pdf",
      isLoadingReconSummaryByDate: false,
      apiParams: this.props.apiParams,
      reportType: this.props.reportType || null,
      dataCompare: null,
      gameLocationSummaryChart: null,
      gameType: AppConstants.locationSummaryByGameMetrics[0].value,
      terminalType: null
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillReceiveProps(nextProps: IReconSummaryReportComponentProps) {
    if (nextProps.apiParams && !isEqual(this.props.apiParams, nextProps.apiParams)) {
      this.gridContainerRef && this.gridContainerRef.resetDataGrid();
      if (this.props.showChart) {
        this.loadReconSummaryChart(nextProps.apiParams);
      }
      this.loadReconSummaryInfo(nextProps.apiParams);
      this.loadReconSummaryList(nextProps.apiParams);
      this.setState({ apiParams: nextProps.apiParams })
    }
    if (nextProps.reportType && !isEqual(this.props.reportType, nextProps.reportType)) {
      this.setState({ reportType: nextProps.reportType })
    }
    /**
     * Recon summary chart
     */
    if (nextProps.loadReconSummaryChartSuccess && !isEqual(this.props.loadReconSummaryChartSuccess, nextProps.loadReconSummaryChartSuccess)) {
      var reconSummaryChart = { ...this.state.reconSummaryChart, ...nextProps.loadReconSummaryChartSuccess }
      this.setState({ reconSummaryChart, isLoadingCharts: false })
    }
    if (nextProps.loadReconSummaryChartError && !isEqual(this.props.loadReconSummaryChartError, nextProps.loadReconSummaryChartError)) {
      this.setState({ isLoadingCharts: false, reconSummaryChart: null })
      AppUtilityService.handleApiError(nextProps.loadReconSummaryChartError);
    }


    if (nextProps.loadReconSummaryLocationChartSuccess && !isEqual(this.props.loadReconSummaryLocationChartSuccess, nextProps.loadReconSummaryLocationChartSuccess)) {
      var resultData = {
        locData: nextProps.loadReconSummaryLocationChartSuccess.gameData || null,
        locTotal: nextProps.loadReconSummaryLocationChartSuccess.total || 0,
      };

      var gameLocationSummaryChart = this.buildChartData(
        "currency",
        resultData.locData,
        `Total ${startCase(this.state.gameType)}`,
        (isEqual(this.state.gameType, 'played') ? '$' : '') + AppUtilityService.shortenLargeNumber(resultData.locTotal || 0, 0)
      );
      gameLocationSummaryChart.gameType = this.state.gameType;
      this.setState({ gameLocationSummaryChart, isLoadingCharts: false })
    }
    if (nextProps.loadReconSummaryLocationChartError && !isEqual(this.props.loadReconSummaryLocationChartError, nextProps.loadReconSummaryLocationChartError)) {
      this.setState({ isLoadingCharts: false, reconSummaryChart: null })
      AppUtilityService.handleApiError(nextProps.loadReconSummaryLocationChartError);
    }
    /**
     * Recon summary list
     */
    if (nextProps.loadReconSummaryListSuccess && !isEqual(this.props.loadReconSummaryListSuccess, nextProps.loadReconSummaryListSuccess)) {
      this.setState({ reconSummaryList: nextProps.loadReconSummaryListSuccess, isLoadingList: false })
    }
    if (nextProps.loadReconSummaryListError && !isEqual(this.props.loadReconSummaryListError, nextProps.loadReconSummaryListError)) {
      this.setState({ isLoadingList: false, reconSummaryList: null })
      AppUtilityService.handleApiError(nextProps.loadReconSummaryListError);
    }
    /**
     * Recon summary info
     */
    if (nextProps.loadReconSummaryInfoSuccess && !isEqual(this.props.loadReconSummaryInfoSuccess, nextProps.loadReconSummaryInfoSuccess)) {
      this.setState({ reconSummaryInfo: nextProps.loadReconSummaryInfoSuccess, isLoadingSummaryInfo: false })
    }
    if (nextProps.loadReconSummaryInfoError && !isEqual(this.props.loadReconSummaryInfoError, nextProps.loadReconSummaryInfoError)) {
      this.setState({ isLoadingSummaryInfo: false, reconSummaryInfo: null })
      AppUtilityService.handleApiError(nextProps.loadReconSummaryInfoError);
    }
    /**
     * Recon summary list by date child list
     */
    if (nextProps.loadReconSummaryChildListSuccess && !isEqual(this.props.loadReconSummaryChildListSuccess, nextProps.loadReconSummaryChildListSuccess)) {
      this.setState({ reconSummaryByDateChildList: nextProps.loadReconSummaryChildListSuccess, isLoadingChildList: false })
    }
    if (nextProps.loadReconSummaryChildListError && !isEqual(this.props.loadReconSummaryChildListError, nextProps.loadReconSummaryChildListError)) {
      this.setState({ isLoadingChildList: false, reconSummaryByDateChildList: null })
      AppUtilityService.showNotificationToast("Error", nextProps.loadReconSummaryChildListError.response.data, "error");
    }

    /**
     * Report download excel
     */
    if (nextProps.exportDataToExcelSuccess && !isEqual(this.props.exportDataToExcelSuccess, nextProps.exportDataToExcelSuccess)) {
      this.setState({ isLoading: false })
      if (!isEmpty(nextProps.exportDataToExcelSuccess)) {
        if (this.state.downloadType === "pdf") {
          this.downloadPDF(nextProps.exportDataToExcelSuccess)
        } else {
          this.downloadExcel(nextProps.exportDataToExcelSuccess)
        }
      }
    }
    if (nextProps.exportDataToExcelError && !isEqual(this.props.exportDataToExcelError, nextProps.exportDataToExcelError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.exportDataToExcelError);
    }
  }


  exludedColumnsPredicate(dataItem) {
    return dataItem.excludeFromSummaryTotal === undefined || dataItem.excludeFromSummaryTotal === false;
  }

  render() {
    const chartsList = this.renderCharts();
    const summaryData = this.renderSummary();
    const radioFilter = AppConstants.locationSummaryByGameMetrics;
    const { gameLocationSummaryChart } = this.state;
    const devExColumnData: any = filter(AppConstants.reconSummaryReportColumns, { "reportType": this.state.reportType })[0];
    const excludedSummaryColumns = devExColumnData && filter(devExColumnData.columns, this.exludedColumnsPredicate);
    const excludedChildSummaryColumns = devExColumnData && filter(devExColumnData.childColumns, this.exludedColumnsPredicate);

    return (
      <Spin spinning={this.state.isLoading}>
        {
          this.props.showChart &&
          <Tabs className="card-tabs" defaultActiveKey="1">
            {
              this.state.reportType !== "ByGame" &&
              <TabPane tab={"Data graph"} key="1">
                <div>
                  <h2>Graphical representation of selected time period</h2>
                  <Spin spinning={this.state.isLoadingCharts}>
                    <Row gutter={24}>
                      {
                        chartsList && !isEmpty(chartsList) &&
                        chartsList.map((chart, index) => {
                          return (
                            <Col xl={{ span: 8, offset: this.getChartColOffset(chartsList, index) }} key={"chart" + index}>
                              <DonutChartComponent id={"chart" + index}
                                height={400}
                                responsiveHeight={400}
                                chartData={chart}
                                showLegend={true}
                                isVerticalLegend={false}
                              />
                              <ChartTabularLegendsComponent chartData={chart} id={"chartTabularLegend" + index} />
                            </Col>
                          )
                        })
                      }
                    </Row>
                    <br />
                    <br />
                  </Spin>
                </div>
              </TabPane>
            }
            {

              this.state.reportType === "ByDate" &&
              <TabPane tab={"Data compare"} key="2">
                <div style={{ paddingLeft: "24px", paddingRight: "24px" }}>
                  <h2>Data Compare</h2>
                  <DataCompareContainer getDataCompareCallback={this.getDataCompareHandler} />
                </div>
              </TabPane>
            }
            {
              this.state.reportType === "ByGame" &&
              <TabPane tab={"Data graph"} key="1">
                <div>
                  <h2>Graphical representation of selected time period</h2>
                  <Spin spinning={this.state.isLoadingCharts}>
                    <Row gutter={24}>
                      {
                        chartsList && !isEmpty(chartsList) &&
                        chartsList.map((chart, index) => {
                          return (
                            <Col xl={{ span: 8, offset: 4 }} key={"chart" + index}>
                              <DonutChartComponent id={"chart" + index}
                                height={400}
                                responsiveHeight={400}
                                chartData={chart}
                                showLegend={true}
                                isVerticalLegend={false} />
                              <ChartTabularLegendsComponent chartData={chart} id={"chartTabularLegend" + index} />
                            </Col>
                          );
                        })
                      }
                      {
                        gameLocationSummaryChart &&
                        <Col xl={{ span: 8, offset: 0 }} key="breakdownByGame">
                          <DonutChartComponent id="breakdownByGame"
                            height={400}
                            responsiveHeight={400}
                            chartData={gameLocationSummaryChart}
                            showLegend={true}
                            isVerticalLegend={true}
                          />
                          <RadioGroup onChange={this.typeFilterChange} value={this.state.gameType}>
                            {
                              radioFilter && !isEmpty(radioFilter) &&
                              radioFilter.map((obj) => {
                                return (
                                  <RadioButton key={obj.value} value={obj.value}>{obj.key}</RadioButton>
                                );
                              })
                            }
                          </RadioGroup>
                        </Col>
                      }
                    </Row>
                    <br />
                    <br />
                  </Spin>
                </div>
              </TabPane>
            }
          </Tabs>
        }
        <div style={{ padding: "24px" }}>
          {
            this.props.showSummary &&
            <React.Fragment>
              <ReportSummaryCardComponent summaryData={summaryData} isLoading={this.state.isLoadingSummaryInfo} />
              <br />
            </React.Fragment>
          }
          <Spin spinning={this.state.isLoadingList || this.state.isLoadingChildList}>
            <div>
              <DataGridComponent
                ref={ref => (this.gridContainerRef = ref)}
                toolbarContent={<h2>Detailed view of selected time period:</h2>}
                dataSource={this.state.reconSummaryList && this.state.reconSummaryList.summaryList}
                childDataSource={this.state.reconSummaryByDateChildList && this.state.reconSummaryByDateChildList.summaryList}
                dataSrcColumns={devExColumnData && !isEmpty(devExColumnData.columns) && devExColumnData.columns}
                childDataSrcColumns={devExColumnData && !isEmpty(devExColumnData.childColumns) && devExColumnData.childColumns}
                summaryAvgColumns={devExColumnData && !isEmpty(excludedSummaryColumns) && excludedSummaryColumns}
                summarySumColumns={devExColumnData && !isEmpty(excludedSummaryColumns) && excludedSummaryColumns}
                childSummaryAvgColumns={devExColumnData && !isEmpty(excludedChildSummaryColumns) && excludedChildSummaryColumns}
                childSummarySumColumns={devExColumnData && !isEmpty(excludedChildSummaryColumns) && excludedChildSummaryColumns}
                isLoading={this.state.isLoadingList}
                isLoadingChild={this.state.isLoadingChildList}
                onRowExpandingCallback={this.handleOnRowExpanding}
                storeIdentifier={`ReconSummaryReport-${devExColumnData.reportType}`}
                showPageSizeSelector={true}
              />
            </div>
          </Spin>
        </div>
      </Spin>
    )
  }

  /**
   * Create summary array to pass as props to ReportSummaryCardComponent
   */
  renderSummary = (): any => {
    var summaryData = [];
    var { reconSummaryInfo, reconSummaryList } = this.state;
    if (reconSummaryInfo && reconSummaryList) {
      summaryData.push(
        { key: "Distributor: ", value: reconSummaryInfo.distributors, colCount: 24 },
        { key: "Charity: ", value: reconSummaryInfo.charities, colCount: 24 },
        { key: "From: ", value: AppUtilityService.formatDate(this.state.apiParams.dateFrom), colCount: 8 },
        { key: "To: ", value: AppUtilityService.formatDate(this.state.apiParams.dateTo), colCount: 8 },
      );
      return summaryData;
    }
  }

  getChartColOffset = (chartList, index): number => {
    let offset = 0;
    switch (chartList.length) {
      case 1:
        if (index === 0) {
          offset = 8
        }
        break;
      case 2:
        if (index === 0) {
          offset = 4
        }
        break;
      default: break;
    }
    return offset;
  }

  renderCharts = (): any => {
    var chartsList = [];
    if (this.state.reconSummaryChart) {
      switch (this.state.reportType) {
        case "ByDate":
          var cashInCashOutChartData = this.buildChartData(
            "currency",
            this.state.reconSummaryChart.cashIO,
            `${AppUtilityService.shortenLargeNumber(this.state.reconSummaryChart.pCashIO || 0, 2)}%`,
            "Hold Percentage"
          );
          chartsList.push(cashInCashOutChartData)
          break;
        default: break;
      }
      var moneyPlayedWonChartData = this.buildChartData(
        "currency",
        this.state.reconSummaryChart.moneyPW,
        `${AppUtilityService.shortenLargeNumber(this.state.reconSummaryChart.pMoneyPW || 0, 2)}%`,
        "Payout Percentage"
      );
      chartsList.push(moneyPlayedWonChartData)
    }
    return chartsList;
  }

  buildChartData = (units, dataset, titleString, subtitleString): any => {
    if (dataset && !isEmpty(dataset)) {
      var title: any = {
        html: '<div class="bold text-center font-18 t-grey-dark">' + titleString + '</div>',
        css: {
          color: '#A6ADC9',
          fontSize: 18,
        }
      }
      var subTitle: any = {
        html: '<div class="font-12">' + subtitleString + '</div>',
        css: {
          color: '#4572A7',
          fontSize: 12,
        }
      }

      return AppUtilityService.buildDonutChartData(units, dataset, title, subTitle);
    } else {
      return AppUtilityService.buildDonutChartData("", [], "", "");
    }
  }

  loadReconSummaryChart = (apiParams) => {
    this.setState({ isLoadingCharts: true })
    apiParams = { ...apiParams, summaryType: apiParams.reportType }
    if (isEqual(apiParams.reportType, "ByGame")) {
      apiParams.type = this.state.gameType;
      this.props.actions.loadLocationSummaryChart(apiParams)
    }
    this.props.actions.loadReconSummaryChart(apiParams)
  }

  typeFilterChange = (e) => {
    this.setState({ gameType: e.target.value }, () => {
      var { apiParams } = this.state;
      apiParams.type = e.target.value;
      this.props.actions.loadLocationSummaryChart(apiParams)
    })
  }

  loadReconSummaryList = (apiParams) => {
    this.setState({ isLoadingList: true, reconSummaryList: null })
    apiParams = { ...apiParams, summaryType: apiParams.reportType }
    this.props.actions.loadReconSummaryList(apiParams)
  }

  loadReconSummaryInfo = (apiParams) => {
    this.setState({ isLoadingSummaryInfo: true })
    apiParams = { ...apiParams, summaryType: apiParams.reportType }
    this.props.actions.loadReconSummaryInfo(apiParams)
  }

  getDownloadData = (downloadType) => {
    this.setState({ isLoading: true, downloadType })
    var { reportType } = this.state;
    var apiParams = { ...this.state.apiParams };
    apiParams.excelType = reportType;
    apiParams.isExcel = downloadType === "excel" ? true : false;
    this.props.actions.exportDataToExcel(apiParams);
  }

  downloadExcel = (exportListData) => {
    const devExColumnData: any = filter(AppConstants.reconSummaryReportColumns, { "reportType": this.state.reportType })[0];
    let reconSummaryInfo = { ...this.state.reconSummaryList, ...this.state.reconSummaryInfo }
    var dateRange = [this.state.apiParams.dateFrom, this.state.apiParams.dateTo]
    ReportsUtilityService.downloadReconSummaryExcel(this.state.reportType, exportListData, devExColumnData, reconSummaryInfo, dateRange)
  }

  downloadPDF = (exportListData) => {
    const summaryData = this.renderSummary();
    const chartsList = this.renderCharts();
    const devExColumnData: any = filter(AppConstants.reconSummaryReportColumns, { "reportType": this.state.reportType })[0];
    ReportsUtilityService.downloadReconSummaryPDF(this.state.reportType, exportListData, devExColumnData, summaryData, chartsList, this.state.dataCompare || this.state.gameLocationSummaryChart)
  }

  getByDateChildData = (summaryId) => {
    this.setState({ isLoadingChildList: true, reconSummaryByDateChildList: null })
    var { reportType } = this.state;
    var apiParams = { ...this.state.apiParams };
    apiParams.summaryType = reportType;
    apiParams.summaryId = (reportType === "ByDate" || reportType === "ByGame") ? summaryId : null;
    apiParams.locationId = (reportType === "ByDate") ? summaryId : null;
    apiParams.cName = (reportType === "ByGT") ? summaryId : null;
    this.props.actions.loadReconSummaryChildList(apiParams);
  }

  handleOnRowExpanding = (selectedRowsData) => {
    this.setState({ reconSummaryByDateChildList: null })
    switch (this.state.reportType) {
      case "ByDate":
        this.getByDateChildData(selectedRowsData.key.lId);
        break;
      case "ByGame":
        this.getByDateChildData(selectedRowsData.key.gId);
        break;
      case "ByGT":
        this.getByDateChildData(selectedRowsData.key.cName);
        break;
      default: break;
    }
  }
  getDataCompareHandler = (dataCompare) => {
    this.setState({ dataCompare })
  }

}
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as CharityActions from '../../actions/charities/CharityActions';
import { CharityListTableComponent } from '../../components/charities/CharityListTableComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    charityListFilters: state.CharityReducer.charityListFilters,

    loadCharityListSuccess: state.CharityReducer.loadCharityListSuccess,
    loadCharityListError: state.CharityReducer.loadCharityListError,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(CharityActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CharityListTableComponent)

import * as React from "react";
import { isEqual } from "lodash";
import moment from "moment";
import AppUtilityService from "../../services/AppUtilityService";
import appConstants from "../../AppConstants";

import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

export class RangepickerComponent extends React.Component<IRangepickerComponentProps, IRangepickerComponentState> {
    constructor(props: IRangepickerComponentProps) {
        super(props);
        this.state = {
            dateRange: this.props.dateRange || [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            width: this.props.width || 'auto'
        }
    }


    componentDidMount() {
        var value = this.props.hasOwnProperty('value') && this.props.value;
        if (value) {
            this.setState({ dateRange: this.props.value })
        }
    }

    componentWillReceiveProps(nextProps: IRangepickerComponentProps) {
        if (nextProps.hasOwnProperty('value') && nextProps.value !== this.props.value) {
            this.setState({ dateRange: nextProps.value })
        }

        if (nextProps.dateRange && !isEqual(this.props.dateRange, nextProps.dateRange)) {
            this.setState({ dateRange: nextProps.dateRange })
        }
    }
    render() {
        return (
            <RangePicker
                style={{ width: this.state.width }}
                allowClear={false}
                ranges={AppUtilityService.getPredefinedDateRanges()}
                format={appConstants.dateFormat}
                value={this.state.dateRange}
                onChange={this.dateRangeFilterChange}
                disabledDate={AppUtilityService.disableDatesGreaterThanToday}
            // onCalendarChange={this.onCalendarChange}
            // onOpenChange={this.onOpenChange}
            />
        );
    }

    dateRangeFilterChange = (date, dateString) => {
        this.setState({ dateRange: date }, () => {
            this.props.onChange(date);
        });
    }
    // onOpenChange = (status) => {
    //     var { dateRange } = this.state;
    //     if (!status) {
    //         this.props.onChange(dateRange);
    //     }
    // }
    /**
     * Manipulating the selection of 30 days if single date is selected
     */
    // onCalendarChange = (dates: any, dateStrings: any) => {
    //     console.log("on calendar change")
    //     var { dateRange } = this.state;
    //     var startDate = dates[0];
    //     var endDate = moment(startDate).add(29, 'days')
    //     if (endDate > moment()) {
    //         endDate = moment()
    //     }
    //     dateRange = [startDate, endDate];
    //     this.setState({ dateRange })
    // }
}
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as BreadcrumbActions from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import * as UserRoleActions from '../../actions/userRole/UserRoleActions';
import { UserRoleDetailsPage } from '../../components/userRole/UserRoleDetailsPage';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadSelectedRoleDetailsSuccess: state.UserRoleReducer.loadSelectedRoleDetailsSuccess,
    loadSelectedRoleDetailsError: state.UserRoleReducer.loadSelectedRoleDetailsError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({...BreadcrumbActions, ...UserRoleActions}, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRoleDetailsPage)

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ZoneActions from '../../actions/zones/ZoneActions';
import { ZoneDescriptionComponent } from '../../components/zones/ZoneDescriptionComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadZoneDetailsSuccess: state.ZoneReducer.loadZoneDetailsSuccess,
    loadZoneDetailsError: state.ZoneReducer.loadZoneDetailsError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ZoneActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ZoneDescriptionComponent)

import { createApiCaller } from '../../../common/services/ApiService';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { fetchDataWithActionCreator, LoadState } from '../../../common/store/fetched';
import { InvoiceStatus, IUpdateBulkInvoicesStatusRequest, IUpdateBulkInvoiceStatusContext, IUpdateBulkInvoiceResult } from '../../reducers/billing/billing.models';
import { updateBulkStatusOperationUpdated } from './slice-actions';
import { processFailureReason } from '../../../common/store/failure';
import { message } from 'antd';
import { getInvoices } from './getInvoices';

export const updateBulkInvoiceStatus = (invoiceIds: number[], targetStatus: InvoiceStatus) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {

    const request: IUpdateBulkInvoicesStatusRequest = { invoiceIds, targetStatus };
    const context: IUpdateBulkInvoiceStatusContext = { invoiceIds, request };

    await fetchDataWithActionCreator(
      updateBulkStatusOperationUpdated,
      dispatch,
      createApiCaller(api => api.updateData<IUpdateBulkInvoiceResult[]>(
        `/invoices/status`,
        undefined,
        request)),
      processFailureReason,
      false,
      context,
    );

    const operation = getState().BillingReducer.updateBulkStatusOperation;
    if (operation.loadState === LoadState.Failed) {
      message.error(
        `There was a problem with approving/sending invoices. Please try again.`,
        5,
      );
    } else if (operation.loadState === LoadState.Success) {
      await dispatch(getInvoices())
    }
  }
}
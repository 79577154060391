export type AdminAutocompleteState = Partial<{
    loadRoleUsersAvailableError: any;
    loadUsersLinkedCharityError: any;
    loadUsersLinkedLocationsError: any;
    loadUsersLinkedZonesSuccess: any;
    loadZoneLinkedLocationsSuccess: any;
    loadZoneLinkedUsersError: any;
    loadZoneLinkedUsersSuccess: any;
    loadRoleUsersAvailableSuccess: any;
    loadUsersLinkedCharitySuccess: any;
    loadUsersLinkedLocationsSuccess: any;
    loadUsersLinkedZonesError: any;
    loadZoneLinkedLocationsError: any;
    loadSelectLocationsSuccess: any;
    loadSelectLocationsError: any;
    loadDistributorUsersAvailableSuccess: any;
    loadDistributorUsersAvailableError: any;
}>;

const initialState: AdminAutocompleteState = {};

const AdminAutocompleteReducer = (state:AdminAutocompleteState = initialState, action): AdminAutocompleteState => {
    switch (action.type) {
        case "LOAD_ROLE_USERS_AVAILABLE_AUTOCOMPLETE_DATA_REQUEST":
            return { ...state, loadRoleUsersAvailableSuccess: null, loadRoleUsersAvailableError: null };
        case "LOAD_ROLE_USERS_AVAILABLE_AUTOCOMPLETE_DATA_SUCCESS":
            return { ...state, loadRoleUsersAvailableSuccess: action.payload, loadRoleUsersAvailableError: null };
        case "LOAD_ROLE_USERS_AVAILABLE_AUTOCOMPLETE_DATA_FAILURE":
            return { ...state, loadRoleUsersAvailableSuccess: null, loadRoleUsersAvailableError: action.payload };

        case "LOAD_USERS_LINKED_CHARITY_AUTOCOMPLETE_DATA_REQUEST":
            return { ...state, loadUsersLinkedCharitySuccess: null, loadUsersLinkedCharityError: null };
        case "LOAD_USERS_LINKED_CHARITY_AUTOCOMPLETE_DATA_SUCCESS":
            return { ...state, loadUsersLinkedCharitySuccess: action.payload, loadUsersLinkedCharityError: null };
        case "LOAD_USERS_LINKED_CHARITY_AUTOCOMPLETE_DATA_FAILURE":
            return { ...state, loadUsersLinkedCharitySuccess: null, loadUsersLinkedCharityError: action.payload };

        case "LOAD_USERS_LINKED_LOCATIONS_AUTOCOMPLETE_DATA_REQUEST":
            return { ...state, loadUsersLinkedLocationsSuccess: null, loadUsersLinkedLocationsError: null };
        case "LOAD_USERS_LINKED_LOCATIONS_AUTOCOMPLETE_DATA_SUCCESS":
            return { ...state, loadUsersLinkedLocationsSuccess: action.payload, loadUsersLinkedLocationsError: null };
        case "LOAD_USERS_LINKED_LOCATIONS_AUTOCOMPLETE_DATA_FAILURE":
            return { ...state, loadUsersLinkedLocationsSuccess: null, loadUsersLinkedLocationsError: action.payload };

        case "LOAD_USERS_LINKED_ZONES_AUTOCOMPLETE_DATA_REQUEST":
            return { ...state, loadUsersLinkedZonesSuccess: null, loadUsersLinkedZonesError: null };
        case "LOAD_USERS_LINKED_ZONES_AUTOCOMPLETE_DATA_SUCCESS":
            return { ...state, loadUsersLinkedZonesSuccess: action.payload, loadUsersLinkedZonesError: null };
        case "LOAD_USERS_LINKED_ZONES_AUTOCOMPLETE_DATA_FAILURE":
            return { ...state, loadUsersLinkedZonesSuccess: null, loadUsersLinkedZonesError: action.payload };

        case "LOAD_ZONE_LINKED_LOCATIONS_AUTOCOMPLETE_DATA_REQUEST":
            return { ...state, loadZoneLinkedLocationsSuccess: null, loadZoneLinkedLocationsError: null };
        case "LOAD_ZONE_LINKED_LOCATIONS_AUTOCOMPLETE_DATA_SUCCESS":
            return { ...state, loadZoneLinkedLocationsSuccess: action.payload, loadZoneLinkedLocationsError: null };
        case "LOAD_ZONE_LINKED_LOCATIONS_AUTOCOMPLETE_DATA_FAILURE":
            return { ...state, loadZoneLinkedLocationsSuccess: null, loadZoneLinkedLocationsError: action.payload };

        case "LOAD_ZONE_LINKED_USERS_AUTOCOMPLETE_DATA_REQUEST":
            return { ...state, loadZoneLinkedUsersSuccess: null, loadZoneLinkedUsersError: null };
        case "LOAD_ZONE_LINKED_USERS_AUTOCOMPLETE_DATA_SUCCESS":
            return { ...state, loadZoneLinkedUsersSuccess: action.payload, loadZoneLinkedUsersError: null };
        case "LOAD_ZONE_LINKED_USERS_AUTOCOMPLETE_DATA_FAILURE":
            return { ...state, loadZoneLinkedUsersSuccess: null, loadZoneLinkedUsersError: action.payload };

        case "LOAD_GAMESELECTIONWINDOW_SELECT_LOCATIONS_AUTOCOMPLETE_DATA_REQUEST":
            return { ...state, loadSelectLocationsSuccess: null, loadSelectLocationsError: null };
        case "LOAD_GAMESELECTIONWINDOW_SELECT_LOCATIONS_AUTOCOMPLETE_DATA_SUCCESS":
            return { ...state, loadSelectLocationsSuccess: action.payload, loadSelectLocationsError: null };
        case "LOAD_GAMESELECTIONWINDOW_SELECT_LOCATIONS_AUTOCOMPLETE_DATA_FAILURE":
            return { ...state, loadSelectLocationsSuccess: null, loadSelectLocationsError: action.payload };

        case "LOAD_DISTRIBUTOR_USERS_AVAILABLE_AUTOCOMPLETE_DATA_REQUEST":
            return { ...state, loadDistributorUsersAvailableSuccess: null, loadDistributorUsersAvailableError: null };
        case "LOAD_DISTRIBUTOR_USERS_AVAILABLE_AUTOCOMPLETE_DATA_SUCCESS":
            return { ...state, loadDistributorUsersAvailableSuccess: action.payload, loadDistributorUsersAvailableError: null };
        case "LOAD_DISTRIBUTOR_USERS_AVAILABLE_AUTOCOMPLETE_DATA_FAILURE":
            return { ...state, loadDistributorUsersAvailableSuccess: null, loadDistributorUsersAvailableError: action.payload };
  
        default:
            return state;
    }
}

export default AdminAutocompleteReducer;

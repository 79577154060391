
import * as React from "react";
import { isEqual } from "lodash";
import { Link } from "react-router";
import { FormComponentProps } from 'antd/lib/form/Form'
import AppUtilityService from "../../../common/services/AppUtilityService";
import RoleUsersAvailableAutocompleteContainer from "../../containers/autocomplete/RoleUsersAvailableAutocompleteContainer";
import { Card, Form, Modal, Row, Col, List, Avatar } from 'antd';
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";
const FormItem = Form.Item;
const confirm = Modal.confirm;


class RoleUsersListCardComponent extends React.Component<IRoleUsersListProps & FormComponentProps, IRoleUsersListState> {

  private autocompleteRef: any;

  constructor(props: IRoleUsersListProps & FormComponentProps) {
    super(props);
    this.state = {
      roleDetails: this.props.roleDetails || null,
      isLoading: this.props.roleDetails ? false : true
    }
  }

  componentWillReceiveProps(nextProps: IRoleUsersListProps & FormComponentProps) {
    if (nextProps.roleDetails && !isEqual(this.props.roleDetails, nextProps.roleDetails)) {
      this.setState({ roleDetails: nextProps.roleDetails, isLoading: false })
    }
    /**
     * Assign user to role success/error handle
     */
    if (nextProps.assignRoleToUserSuccess && !isEqual(this.props.assignRoleToUserSuccess, nextProps.assignRoleToUserSuccess)) {
      var roleDetails = { ...this.state.roleDetails };
      roleDetails.users = nextProps.assignRoleToUserSuccess;
      AppUtilityService.showMessageToast("User added to role", "success");
      this.props.form.resetFields();
      this.autocompleteRef.resetDatasource();
      this.setState({ roleDetails, isLoading: false })
    }
    if (nextProps.assignRoleToUserError && !isEqual(this.props.assignRoleToUserError, nextProps.assignRoleToUserError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.assignRoleToUserError);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Card className="no-header-border no-body-padding" bordered={false}
        title="Users"
      >
        <Form>
          <Row>
            <Col span={20}>
              <FormItem>
                {getFieldDecorator('userId', {
                  rules: [{ required: true, message: 'Please select a user to add!' }],
                  validateTrigger: ['onSubmit']
                })(
                  <RoleUsersAvailableAutocompleteContainer
                    placeholder="Type here to add a user"
                    apiUrlId={this.state.roleDetails && this.state.roleDetails.roleId}
                    onRef={ref => (this.autocompleteRef = ref)} />
                )}
              </FormItem>
            </Col>
            <Col span={4}>
              <div className="text-right">
                <FormItem>
                  <span className="link-primary" onClick={this.assignUser}>Add</span>
                </FormItem>
              </div>
            </Col>
          </Row>
        </Form>
        <List
          loading={this.state.isLoading}
          itemLayout="horizontal"
          dataSource={this.state.roleDetails ? this.state.roleDetails.users : []}
          locale={
            {
              emptyText: <EmptyStateComponent title="No Linked Users" />
            }
          }
          renderItem={(item: RoleUsersDetail) => (
            <List.Item>
              <List.Item.Meta
                avatar= {AppUtilityService.getContactAvatar(item.image)}
                title={<Link to={"/admin/users/" + item.userId} className="link-primary">{item.name}</Link>}
                description={item.userName}
              />
            </List.Item>
          )}
        />
      </Card>
    )
  }

  /**
   * Assign selected user to role
   */
  assignUser = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ isLoading: true })
        var { roleDetails } = this.state;
        var formData = values;
        this.props.actions.assignRoleToUser(roleDetails.roleId, formData.userId);
      }
    });
  }
} export default Form.create<IRoleUsersListProps & FormComponentProps>()(RoleUsersListCardComponent);

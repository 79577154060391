import * as React from "react";
import { Card, Row, Col } from 'antd';
import appUtilityService from "../../services/AppUtilityService";

export class PageNotFoundComponent extends React.Component<IPageNotFoundComponentProps, {}> {
    constructor(props: IPageNotFoundComponentProps) {
        super(props);
        this.state = {

        }
    }

    componentWillMount() {
        this.breadcrumbsBuild();
    }

    render() {
        return (
            <div className="pg-container page-container">
                {/* <Card className="no-header-border" bordered={false}
                    title="404 Page not found"
                >
                    404 Page not found
                </Card> */}
            </div>
        );
    }

    breadcrumbsBuild = () => {
        let breadcrumbsList = [
            { name: "404 Page not found", link: null }
        ]
        var breadcrumbs = appUtilityService.generateBreadcrumbs(breadcrumbsList);
        this.props.actions.updateBreadcrumbs(breadcrumbs);
    }
}
import { Icon, Pagination, Table } from 'antd';
import { ColumnProps, PaginationConfig, SorterResult, TableRowSelection } from 'antd/lib/table';
import { EmptyStateComponent } from '../../../common/components/layouts/EmptyStateComponent';
import { FailedStateComponent } from '../../../common/components/layouts/FailedStateComponent';
import { DataOrSummaryRow, createColumn, CreateColumnProps } from '../../../common/helpers/tableWithSummaryHelpers';
import AppUtilityService from '../../../common/services/AppUtilityService';
import { LoadState } from '../../../common/store/fetched';
import { IInvoiceListItem, InvoiceStatus, InvoiceSortField } from '../../reducers/billing/billing.models';
import { useAppSelector, useAppDispatch } from '../../../common/hooks/storeHooks';
import { useCallback, useState, useEffect } from 'react';
import { dismissInvoiceList, setInvoiceListParams } from '../../actions/billing/slice-actions';
import WebAccessService from '../../../common/services/WebAccessService';
import _ from 'lodash';
import { usePrevious } from '../../../common/hooks/usePrevious';
import InvoiceBulkChangeModal from './InvoiceBulkChangeModal';
import createLinkColumn from '../../../common/helpers/createLinkColumn';


const { formatDate } = AppUtilityService;

export function InvoiceListTableComponent() {
  const invoiceListParams = useAppSelector(state => state.BillingReducer.invoiceListParams);
  const invoiceList = useAppSelector(state => state.BillingReducer.invoiceList);
  const bulkUpdateOperation = useAppSelector(state => state.BillingReducer.updateBulkStatusOperation);
  const updateLoadState = useAppSelector(state => state.BillingReducer.updateBulkStatusOperation.loadState);
  const prevUpdateLoadState = usePrevious(updateLoadState);
  const dispatch = useAppDispatch();
  const reloadInvoices = useCallback(() => {
    dispatch(dismissInvoiceList());
  }, [dispatch]);

  const canViewLocation = WebAccessService.hasPermissionToAccess("Location", "View");
  const canModify = WebAccessService.hasPermissionToAccess("Location", "Modify");

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  useEffect(() => {
    if (prevUpdateLoadState === LoadState.InProgress && updateLoadState === LoadState.Success) {
      setSelectedRowKeys([]);
    } else {
      const visibleRows = _.intersection(selectedRowKeys, invoiceList.data?.invoices.map(p => p.invoiceId.toString()))
      setSelectedRowKeys(visibleRows as string[]);
    }

  }, [dispatch, invoiceList, updateLoadState]);

  const onTableChange = useCallback((
    _pagination: PaginationConfig,
    _filters: Partial<Record<keyof IInvoiceListItem, string[]>>,
    sorter: SorterResult<IInvoiceListItem>,
  ) => {
    dispatch(setInvoiceListParams({
      ...invoiceListParams,
      pageNo: 1,
      sortBy: sorter.columnKey as InvoiceSortField,
      sortAsc: sorter.order !== 'descend',
    }));
  }, [dispatch, invoiceListParams]);

  const onPageChange = useCallback((pageNo: number, pageSize: number) => {
    pageNo = invoiceListParams.pageSize !== pageSize ? 1 : pageNo; // set page to 1 when switching page size
    dispatch(setInvoiceListParams({ ...invoiceListParams, pageNo, pageSize }));
  }, [dispatch, invoiceListParams]);

  const otherProps = { sortBy: invoiceListParams.sortBy, sortAsc: invoiceListParams.sortAsc };
  const tableColumns: ColumnProps<DataOrSummaryRow<IInvoiceListItem>>[] = [
    createLinkColumn('Invoice #', 'invoiceNo', record => `/billing/invoice/${record.invoiceId}`, otherProps),
    createColumn(
      'Period',
      'invoicePeriodFrom',
      record => `${formatDate(record.invoicePeriodFrom)} - ${formatDate(record.invoicePeriodTo)}`,
      otherProps),
    createLinkColumn('Location', 'locationName', record => canViewLocation ? `/locations/${record.locationId}` : null, {
      summaryRender: () => <div className='text-right'>TOTALS</div>,
      ...otherProps,
    }),
    createCurrencyColumn('Cash In', 'itemsTotalCashIn', otherProps),
    createCurrencyColumn('Cash Out', 'itemsTotalCashOut', otherProps),
    createCurrencyColumn('Cash Net', 'itemsTotalCashNet', otherProps),
    createCurrencyColumn('Invoice Amount', 'invoiceAmountDue', otherProps),
    createCurrencyColumn('Adjustments', 'totalAdjustmentsAmount', otherProps),
    createColumn<IInvoiceListItem>('Status', 'invoiceStatusId', record => {
      switch (record.invoiceStatusId) {
        case InvoiceStatus.Approved: return <span className='text-positive'>Approved</span>;
        case InvoiceStatus.Sent: return <span className='text-positive'>Sent</span>;
        case InvoiceStatus.PendingReview:
          return record.hasOpenValidationIssues
            ? <span className="text-negative text-nowrap"><Icon type="warning" theme="outlined" /> Pending</span>
            : 'Pending';
        default: return null;
      }
    }, otherProps),
  ];

  const invoices = invoiceList.loadState !== LoadState.Failed
    ? (invoiceList.data?.invoices ?? [])
    : [];
  const invoicesWithSummary: DataOrSummaryRow<IInvoiceListItem>[] =
    invoices.length === 0
      ? []
      : [
        ...invoices,
        {
          isSummaryRow: true,
          itemsTotalCashIn: invoices.reduce((sum, x) => sum + x.itemsTotalCashIn, 0),
          itemsTotalCashOut: invoices.reduce((sum, x) => sum + x.itemsTotalCashOut, 0),
          itemsTotalCashNet: invoices.reduce((sum, x) => sum + x.itemsTotalCashNet, 0),
          invoiceAmountDue: invoices.reduce((sum, x) => sum + x.invoiceAmountDue, 0),
          totalAdjustmentsAmount: invoices.reduce((sum, x) => sum + x.totalAdjustmentsAmount, 0),
        },
      ];


  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRows.map(p => p.invoiceId.toString()));
  };

  const rowSelection: TableRowSelection<DataOrSummaryRow<IInvoiceListItem>> = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: record => ({
      disabled: record.invoiceId == null,
    }),
  };

  return (
    <>
       <div className="pg-billing-table">
        <Table<DataOrSummaryRow<IInvoiceListItem>>
          rowKey={record => record.invoiceId?.toString() ?? 'summary'}
          loading={invoiceList.loadState === LoadState.InProgress || bulkUpdateOperation.loadState === LoadState.InProgress}
          dataSource={invoicesWithSummary}
          columns={tableColumns}
          pagination={false}
          onChange={onTableChange}
          rowSelection={rowSelection}

          locale={
            {
              emptyText: invoiceList.loadState !== LoadState.Failed
                ? <EmptyStateComponent title="No invoices" />
                : <FailedStateComponent tryAgain={reloadInvoices}>Failed to load invoices.</FailedStateComponent>
            }
          }
        />

      </div>
      <div className="pg-billing-footer">
        {canModify && <InvoiceBulkChangeModal
          items={invoiceList.data?.invoices ?? []}
          selected={selectedRowKeys}
        />}
        <Pagination
          disabled={invoiceList.loadState !== LoadState.Success}
          style={{marginLeft:'auto'}}
          current={invoiceListParams.pageNo}
          pageSize={invoiceListParams.pageSize}
          total={invoiceList.data?.total ?? 0}
          onChange={onPageChange}
          showSizeChanger={true}
          onShowSizeChange={onPageChange}
          pageSizeOptions={['10', '20', '50', '1000']}
        />
      </div>
    </>
  );
}

function createCurrencyColumn(
  title: string, key: keyof IInvoiceListItem, otherProps?: CreateColumnProps<IInvoiceListItem>,
) {
  const render = (record: DataOrSummaryRow<IInvoiceListItem>) => {
    const value = record[key];
    return value == null ? '' : (
      <span className={Math.round(+value * 100) < 0 ? 'text-negative' : ''}>
        {AppUtilityService.formatAmount(value)}
      </span>
    );
  };

  return createColumn<IInvoiceListItem>(title, key, render, { align: 'right', summaryRender: render, ...otherProps });
}

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as UserActions from '../../actions/user/UserActions';
import { UserListTableComponent } from '../../components/user/UserListTableComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    userListFilters: state.UserReducer.userListFilters,

    loadUsersListSuccess: state.UserReducer.loadUsersListSuccess,
    loadUsersListError: state.UserReducer.loadUsersListError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(UserActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserListTableComponent)

import ApiService from "../../../common/services/ApiService";

export function updateUserRoleListFilters(params) {
    return {
        type: 'USER_ROLE_LIST_FILTER_UPDATE',
        payload: params
    }
}

/**
 * Load all roles
 */
export function loadAllUserRoles(params) {
    return function (dispatch) {
        dispatch({ type: `LOAD_ALL_USER_ROLES_REQUEST` });
        return ApiService.getData("/admin/roles", params)
            .then((successResult) => {
                dispatch({ type: `LOAD_ALL_USER_ROLES_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `LOAD_ALL_USER_ROLES_FAILURE`, payload: errorResult });
            });
    }
}
/**
 * Delete multiple user roles
 */
export function deleteMultipleUserRoles(selectedKeys) {
    return function (dispatch) {
        dispatch({ type: `DELETE_MULTIPLE_USER_ROLES_REQUEST` });
        return ApiService.updateData("/admin/roles/multiple", null, selectedKeys)
            .then((successResult) => {
                dispatch({ type: `DELETE_MULTIPLE_USER_ROLES_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `DELETE_MULTIPLE_USER_ROLES_FAILURE`, payload: errorResult });
            });
    }
}
/**
 * Delete single user roles
 */
export function deleteSelectedUserRole(roleKey) {
    return function (dispatch) {
        dispatch({ type: `DELETE_SELECTED_USER_ROLE_REQUEST` });
        return ApiService.deleteData("/admin/roles/" + roleKey, null, null)
            .then((successResult) => {
                dispatch({ type: `DELETE_SELECTED_USER_ROLE_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `DELETE_SELECTED_USER_ROLE_FAILURE`, payload: errorResult });
            });
    }
}
// ================================================================================
/**
 * Load selected role details
 */
export function loadSelectedRoleDetails(roleKey) {
    return function (dispatch) {
        dispatch({ type: `LOAD_SELECTED_ROLE_DETAILS_REQUEST` });
        return ApiService.getData("/admin/roles/" + roleKey, null)
            .then((successResult) => {
                dispatch({ type: `LOAD_SELECTED_ROLE_DETAILS_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `LOAD_SELECTED_ROLE_DETAILS_FAILURE`, payload: errorResult });
            });
    }
}
/**
 * add new role
 */
export function addNewUserRole(formData) {
    return function (dispatch) {
        dispatch({ type: `ADD_NEW_USER_ROLE_REQUEST` });
        return ApiService.postData("/admin/roles", formData)
            .then((successResult) => {
                dispatch({ type: `ADD_NEW_USER_ROLE_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `ADD_NEW_USER_ROLE_FAILURE`, payload: errorResult });
            });
    }
}
/**
 * Update selected role details
 */
export function updateSelectedRoleDetails(roleId, formData) {
    return function (dispatch) {
        dispatch({ type: `UPDATE_SELECTED_ROLE_DETAILS_REQUEST` });
        return ApiService.updateData("/admin/roles/" + roleId, null, formData)
            .then((successResult) => {
                dispatch({ type: `UPDATE_SELECTED_ROLE_DETAILS_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `UPDATE_SELECTED_ROLE_DETAILS_FAILURE`, payload: errorResult });
            });
    }
}
/**
 * Update selected role permissions
 */
export function updateSelectedRolePermissions(roleId, formData) {
    return function (dispatch) {
        dispatch({ type: `UPDATE_SELECTED_ROLE_PERMISSIONS_REQUEST` });
        return ApiService.updateData('/admin/roles/' + roleId + '/permissions', null, formData)
            .then((successResult) => {
                dispatch({ type: `UPDATE_SELECTED_ROLE_PERMISSIONS_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `UPDATE_SELECTED_ROLE_PERMISSIONS_FAILURE`, payload: errorResult });
            });
    }
}
// ================================================================================
/**
 * Add users to role
 */
export function assignRoleToUser(roleId, userId) {
    return function (dispatch) {
        dispatch({ type: `ASSIGN_ROLE_TO_USER_REQUEST` });
        return ApiService.updateData('/admin/roles/' + roleId + '/users/' + userId, null, null)
            .then((successResult) => {
                dispatch({ type: `ASSIGN_ROLE_TO_USER_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `ASSIGN_ROLE_TO_USER_FAILURE`, payload: errorResult });
            });
    }
}

// ================================================================================
/**
 * Add users to role
 */
export function unarchiveRoles(roleId) {
    return function (dispatch) {
        dispatch({ type: `UNARCHIVE_ROLES_REQUEST` });
        return ApiService.getData('/admin/roles/unarchive/' + roleId)
            .then((successResult) => {
                dispatch({ type: `UNARCHIVE_ROLES_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `UNARCHIVE_ROLES_FAILURE`, payload: errorResult });
            });
    }
}
import { arrowJurisdictionsSitesOrganizationsUpdated } from './slice-actions';
import apiService from '../../../common/services/ApiService';
import { createStandardFetchedAction } from '../../../common/store/fetched.slices';

export const getJurisdictionsSitesOrganizations = (args: {
  jurisdictionId?: number,
  siteId?: number,
  organizationId?: number,
}) => createStandardFetchedAction(
  arrowJurisdictionsSitesOrganizationsUpdated,
  async () => {
    const result = await apiService.getData(`/reports/arrow/jurisdictions-sites-organizations-arrow`, args);
    return result.data;
  },
  false,
);

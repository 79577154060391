import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  loadInvoicingServicesDropdown,
} from '../../actions/dropdown/DropdownActions';
import { DropdownComponent } from '../../components/dropdown/DropdownComponent';
import { RootState } from '../../store/ConfigureStore';

const mapStateToProps = (state: RootState) => ({
  datasource: state.DropdownReducer.loadInvoicingServicesDropdownDataSuccess,
  datasourceError: state.DropdownReducer.loadInvoicingServicesDropdownDataError,
});

const mapDispatchToProps = (dispatch) => ({
  loadData: bindActionCreators(loadInvoicingServicesDropdown, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DropdownComponent);

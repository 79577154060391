import ApiService from "../../../common/services/ApiService";

export function updateDashboardFilters(params) {
  return {
    type: 'DASHBOARD_FILTER_UPDATE',
    payload: params
  }
}
// ================================================================================
export function loadDashboardMiniStats(params) {
  return function (dispatch) {
    dispatch({ type: `LOAD_DASHBOARD_MINI_STATS_REQUEST` });
    return ApiService.getData("/dashboard/cardStats", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_DASHBOARD_MINI_STATS_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_DASHBOARD_MINI_STATS_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
export function loadDashboardChartStats(params) {
  return function (dispatch) {
    dispatch({ type: `LOAD_DASHBOARD_CHART_STATS_REQUEST` });
    return ApiService.getData("/dashboard/chartStats", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_DASHBOARD_CHART_STATS_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_DASHBOARD_CHART_STATS_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
export function loadDashboardMapStats(params) {
  return function (dispatch) {
    dispatch({ type: `LOAD_DASHBOARD_MAP_STATS_REQUEST` });
    return ApiService.getData("/dashboard/mapStats", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_DASHBOARD_MAP_STATS_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_DASHBOARD_MAP_STATS_FAILURE`, payload: errorResult });
      });
  }
}

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as LocationTypesActions from '../../actions/locationTypes/LocationTypesActions';
import SaveLocationTypesModal from '../../components/locationTypes/SaveLocationTypesModal';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    addLocationTypeSuccess: state.LocationTypesReducer.addLocationTypeSuccess,
    addLocationTypeError: state.LocationTypesReducer.addLocationTypeError,

    updateLocationTypeSuccess: state.LocationTypesReducer.updateLocationTypeSuccess,
    updateLocationTypeError: state.LocationTypesReducer.updateLocationTypeError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(LocationTypesActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveLocationTypesModal)

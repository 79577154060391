import ApiService from "../../../common/services/ApiService";

export function clearStore() {
    return function (dispatch) {
        dispatch({ type: `USER_LIST_FILTER_UPDATE` });
        dispatch({ type: `USER_ROLE_LIST_FILTER_UPDATE` });
        dispatch({ type: `CHARITY_LIST_FILTER_UPDATE` });
        dispatch({ type: `CONTACT_LIST_FILTER_UPDATE` });
        dispatch({ type: `DASHBOARD_FILTER_UPDATE` });
        dispatch({ type: `LOCATION_LIST_FILTER_UPDATE` });
        dispatch({ type: `LOCATION_DETAIL_DATE_FILTER_UPDATE` });
        dispatch({ type: `LOCATION_REPORT_FILTER_UPDATE` });
    }
}
/**
 * User login
 */

export function userLogin(formData) {
    return function (dispatch) {
        dispatch({ type: `USER_LOGIN_REQUEST` });
        return ApiService.postData("/account/login", formData)
            .then((successResult) => {
                dispatch({ type: `USER_LOGIN_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `USER_LOGIN_FAILURE`, payload: errorResult });
            });
    }
}
// ================================================================================
/**
 * User regster
 */

export function userRegister(formData) {
    return function (dispatch) {
        dispatch({ type: `USER_REGISTER_REQUEST` });
        return ApiService.postData("/account/register", formData)
            .then((successResult) => {
                dispatch({ type: `USER_REGISTER_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `USER_REGISTER_FAILURE`, payload: errorResult });
            });
    }
}
// ================================================================================
/**
 * User reset password
 */
export function resetPassword(formData) {
    return function (dispatch) {
        dispatch({ type: `USER_RESET_PASSWORD_REQUEST` });
        return ApiService.postData("/account/resetPassword", formData)
            .then((successResult) => {
                dispatch({ type: `USER_RESET_PASSWORD_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `USER_RESET_PASSWORD_FAILURE`, payload: errorResult });
            });
    }
}
// ================================================================================
/**
 * User change password
 */
export function changePassword(formData) {
    return function (dispatch) {
        dispatch({ type: `USER_CHANGE_PASSWORD_REQUEST` });
        return ApiService.postData("/account/changePassword", formData)
            .then((successResult) => {
                dispatch({ type: `USER_CHANGE_PASSWORD_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `USER_CHANGE_PASSWORD_FAILURE`, payload: errorResult });
            });
    }
}
// ================================================================================
/**
 * User reset password
 */
export function forgotPassword(formData) {
    return function (dispatch) {
        dispatch({ type: `USER_FORGOT_PASSWORD_REQUEST` });
        return ApiService.postData("/account/forgotPassword", formData)
            .then((successResult) => {
                dispatch({ type: `USER_FORGOT_PASSWORD_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `USER_FORGOT_PASSWORD_FAILURE`, payload: errorResult });
            });
    }
}

import * as React from "react"
import { Card, Table, Input, Select, Switch, Typography } from "antd"
import { IQuarterCloseLocationDetails } from "../../../reducers/reports/ReportsReducer"
import { EmptyStateComponent } from "../../../../common/components/layouts/EmptyStateComponent"
import moment from "moment";
import AppUtilityService from "../../../../common/services/AppUtilityService"
import WebAccessService from "../../../../common/services/WebAccessService"
import { hashHistory } from "react-router"
import sessionService from "../../../../common/services/SessionService"
const { Title } = Typography

export interface IQuarterCloseLocationStatusPageProps {
    locations: IQuarterCloseLocationDetails[],
    loadingLocations: boolean,
    showDemo: boolean,
    showDecommissioned: boolean,

    actions?: {
        updateBreadcrumbs(Breadcrumbs): void,
        loadLocationDetails(showDecommisioned: boolean, showDemo: boolean): void
        QuarterCloseSetDemo(newValue: boolean): void
        QuarterCloseSetDecommisshioned(newValue: boolean): void
    },
}

export interface IQuarterCloseLocationStatusPageState {
    locationFilter: string,
    charityFilter: string[],
    distributorFilter: string[],
}


export default class QuarterCloseLocationStatusPage extends React.Component<IQuarterCloseLocationStatusPageProps, IQuarterCloseLocationStatusPageState> {

    constructor(props) {
        super(props);
        this.state = {
            locationFilter: '',
            charityFilter: [],
            distributorFilter: [],
        }
    }

    componentDidMount() {
        this.props.actions.loadLocationDetails(this.props.showDecommissioned, this.props.showDemo)
        this.breadcrumbsBuild()
        this.checkPermissions()
    }

     checkPermissions = () => {
         let hasPermissions = WebAccessService.hasPermissionToAccess("Reports", "Quarter_Close_Status")
        if (!hasPermissions) {
            hashHistory.push("/unauthorized-access");
        }
    }

    breadcrumbsBuild = () => {
        let breadcrumbsList = [
            { name: "Reports", link: '/reports' },
            { name: "Quarter Close Status", link: null }
        ]
        var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList, null);
        this.props.actions.updateBreadcrumbs(breadcrumbs);
    }

    render() {
        const { Option } = Select;

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        const tableColumns = [
            {
                title: () =>
                    <div>Location</div>
                ,
                dataIndex: 'location',
                key: 'name',
                render: (text, record) => {
                    return (
                        <div>{`${record.name} (${record.id})`}</div>
                    )
                }
            },
            {
                title:
                    <div>Quarter Close Status</div>
                ,
                dataIndex: 'status',
                key: 'status',
                render: (text) => {
                    return (
                        <div className={"locationStatusText" + (text === "COMPLETED" ? " locationStatusTextCompleteColor" : "")}>{text}</div>
                    )
                }
            },
            {
                title: 'Game Selection Date',
                dataIndex: 'details.earliestStartDate',
                key: 'earliestStartDate',
                render: (text) => {
                    return (
                        <div>{text ?  moment(new Date(text)).format('LL') : ''}</div>
                    )
                }
            }
        ];

        const subTableColumns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => {
                    return (
                        <div>{`${text}`}</div>
                    )
                }
            },
            {
                title: 'Form Type',
                dataIndex: 'formType',
                key: 'formType',
                render: (text) => {
                    return (
                        <div>{text}</div>
                    )
                }
            },
            {
                title: 'Bets',
                dataIndex: 'bets',
                key: 'bets',
                render: (text, record) => {

                    return (
                        <> {
                            <div>{record.bets.map((bet) => {
                                return formatter.format(bet)
                            }).join(', ')}</div>
                            }
                        </>                        
                    )
                }
            }
        ];

        let filteredLocations = this.props.locations
        let charities = this.props.locations.map(p => p.charities).filter(p => p).flat().sort((a, b) => { if (a.name > b.name) return 1; return -1 }) //distinct call
        let distinctCharities = charities.filter((elem, index) => charities.findIndex(obj => obj.name === elem.name) === index)
        let distributors = this.props.locations.map(p => p.distributorName).sort((a, b) => { if (a > b) return 1; return -1 })
        let distinctDistributors = distributors.filter((elem, index) => distributors.findIndex(obj => obj === elem) === index)

        if (this.state.locationFilter !== '')
            filteredLocations = filteredLocations.filter(p => p.name.toLowerCase().indexOf(this.state.locationFilter.toLowerCase()) > -1 || p.id.toString().indexOf(this.state.locationFilter) > -1)

        if (this.state.charityFilter.length > 0)
            filteredLocations = filteredLocations.filter(p => p.charities && p.charities.map(x => x.name).filter(x => this.state.charityFilter.includes(x)).length > 0)

        if (this.state.distributorFilter.length > 0)
            filteredLocations = filteredLocations.filter(p => this.state.distributorFilter.includes(p.distributorName))

        return (
            <div className="pg-container page-container">
                
            
                <Card className="no-header-border" bordered={false}
                    title={
                        <Title level={3}>QUARTER CLOSE LOCATION STATUS
                            {
                                sessionService.getRole() === "Admin" &&
                                <span style={{ float: 'right' }}>
                                    <span className="font-14 regular">Show demo types</span>
                                    <Switch size="small" checked={this.props.showDemo} onChange={() => {
                                        const showDemo = !this.props.showDemo
                                        this.props.actions.loadLocationDetails(this.props.showDecommissioned, showDemo)
                                        this.props.actions.QuarterCloseSetDemo(showDemo)

                                    }} />
                                </span>
                            }
                            {
                                sessionService.getRole() === "Admin" &&
                                <span style={{ float: 'right' }}  className="ml-8">
                                    <span className="font-14 regular">Show decommissioned types</span>
                                    <Switch size="small" checked={this.props.showDecommissioned}
                                        onChange={() => {
                                            const showDecommissioned = !this.props.showDecommissioned
                                            this.props.actions.loadLocationDetails(showDecommissioned, this.props.showDemo)
                                            this.props.actions.QuarterCloseSetDecommisshioned(showDecommissioned)

                                        }} />
                                </span>
                            }
                        </Title>
                    }>
                    <div style={{ paddingBottom: '16px' }}>
                        <Input
                            placeholder={'Location Name'}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => { this.setState({ locationFilter: e.currentTarget.value }) }}
                            style={{ width: '400px', paddingLeft: '16px' }}
                        />
                        <Select
                            mode="multiple"
                            allowClear
                            placeholder="Select Charities"
                            style={{ width: '400px', paddingLeft: '16px' }}
                            loading={this.props.loadingLocations}
                            onChange={(value: string[]) => this.setState({ charityFilter: value })}
                        >
                            {distinctCharities.map(p => <Option key={p.name} value={p.name}> {p.name} </Option>)}
                        </Select>
                        <Select
                            mode="multiple"
                            allowClear
                            placeholder="Select Distributors"
                            style={{ width: '400px', paddingLeft: '16px' }}
                            loading={this.props.loadingLocations}
                            onChange={(value: string[]) => this.setState({ distributorFilter: value })}
                        >
                            {distinctDistributors.map(p => <Option key={p} value={p}> {p} </Option>)}
                        </Select>
                    </div>
                    <Table
                        rowKey={(record, index) => { return (record.id).toString() }}
                        loading={this.props.loadingLocations}
                        dataSource={filteredLocations}
                        columns={tableColumns}
                        locale={
                            {
                                emptyText: <EmptyStateComponent title="No Locations" />
                            }
                        }
                        expandedRowRender={(record) => {
                            return (
                                record.details && 
                                <Table
                                    rowKey={(record, index) => { return (record.gameID).toString() }}
                                    dataSource={record.details.games}
                                    pagination={false}
                                    columns={subTableColumns}
                                    locale={
                                        {
                                            emptyText: <EmptyStateComponent title="No Locations" />
                                        }
                                    }
                                />
                            )
                        }}
                        rowClassName={(record) => !record.details ? "antdTableExpandHidden" : ""}
                    />
                </Card>
            </div>
        )
    }
}

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as LocationsActions from '../../actions/locations/LocationsActions';
import { SaveLocationContactModal } from '../../components/locations/SaveLocationContactModal';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    addLocationContactSuccess: state.LocationsReducer.addLocationContactSuccess,
    addLocationContactError: state.LocationsReducer.addLocationContactError,
    updateLocationContactSuccess: state.LocationsReducer.updateLocationContactSuccess,
    updateLocationContactError: state.LocationsReducer.updateLocationContactError,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(LocationsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveLocationContactModal)

import { AppDispatch } from '../../../common/store/ConfigureStore';
import { fetchDataWithActionCreator } from '../../../common/store/fetched';
import { authenticatedUserUpdated } from './slice-actions';
import { processFailureReason } from '../../../common/store/failure';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { AuthenticatedUser, CustomCognitoAttribute } from '../../reducers/auth/auth.models';
import WebAccessService from '../../../common/services/WebAccessService';
import SessionService from '../../../common/services/SessionService';

const getAuthenticatedUser =
  () =>
    async (dispatch: AppDispatch) =>
      await fetchDataWithActionCreator(
        authenticatedUserUpdated,
        dispatch,
        async () => {
          const user = await tryGetCurrentUser();
          if (user) {
            const session = await fetchAuthSession();
            const authUser: AuthenticatedUser = {
              userName: user?.signInDetails?.loginId,
              userId: user?.userId,
              role: session.tokens?.idToken?.payload?.[CustomCognitoAttribute.RoleName] as string,
              token: session.tokens?.idToken?.toString(),
              tokenExpiration: session.tokens?.idToken?.payload.exp,
            }
            SessionService.setSessionData(authUser);
            await WebAccessService.loadUserPermissions();
            return authUser;
          }
          SessionService.setSessionData(null);
          WebAccessService.setUserPermissions(null);
          return null;
        },
        processFailureReason,
        false,
      );

async function tryGetCurrentUser() {
  try {
    return await getCurrentUser()
  } catch {
    return null;
  }
}

export default getAuthenticatedUser;

import * as React from "react";
import { isEqual } from "lodash";
import { Card, Layout } from 'antd';
import { hashHistory } from "react-router";
import AppUtilityService from "../../../../common/services/AppUtilityService";
import LocationReportsFilterContainer from "../../../containers/reports/locationStatisticsReports/LocationReportsFilterContainer";
import WebAccessService from "../../../../common/services/WebAccessService";
import { ISessionsReportPageProps, SessionsReportPageState } from '../Reports';
import SessionsReportContainer from '../../../containers/reports/locationStatisticsReports/SessionsReportContainer';
import { LocationReportsFilter } from './LocationReportsFilterComponent';
import OutsidePermittedHoursOnlyFilter from './OutsidePermittedHoursOnlyFilter';
import { filters } from './commonFiltersConfiguration';

const customFilters: LocationReportsFilter[] = [
  { name: 'outsidePermittedHoursOnly', component: OutsidePermittedHoursOnlyFilter },
  ...filters,
];

export class SessionsReportPage extends React.Component<ISessionsReportPageProps, SessionsReportPageState> {

  private dealsReportRef: any;

  constructor(props: ISessionsReportPageProps) {
    super(props);
    this.state = {
      zoneName: null,
      locationName: null,
      isLoadingList: false,
      apiParams: null,
    }
  }

  componentDidMount() {
    this.breadcrumbsBuild();
    this.checkPermissions();
  }

  componentWillReceiveProps(nextProps: ISessionsReportPageProps) {
    if (nextProps.loadSessionsListSuccess && !isEqual(this.props.loadSessionsListSuccess, nextProps.loadSessionsListSuccess)) {
      this.setState({ isLoadingList: false });
    }

  }

  render() {
    let { zoneName, locationName } = this.state;
    let filterString = AppUtilityService.displayStringFromArray([zoneName, locationName]);

    let cardTitle = "Sessions" + (filterString && (" | " + filterString))

    const extraActions = null;

    return (
      <>
        <Card style={{ paddingTop: "12px" }} className="reports-filter-card content-card no-header-border" bordered={false} title={cardTitle}
          extra={WebAccessService.hasPermissionToAccess("Reports", "Download") ? extraActions : null}
        >
          <LocationReportsFilterContainer
            getSelectedZoneNameCallback={this.handleSelectedZone}
            getSelectedLocationNameCallback={this.handleSelectedLocation}
            disableCharityFilter={true}
            disableDistributorFilter={true}
            applyFiltersCallback={this.applyFilters}
            filters={customFilters}
          />
        </Card>
        <div className="pg-reports-container">
          <Layout>
            <div className="container" style={{ background: '#fff' }}>
              <SessionsReportContainer
                apiParams={this.state.apiParams}
                onRef={ref => (this.dealsReportRef = ref)}
                loadSessionsListSuccess={this.props.loadSessionsListSuccess}
                loadSessionsListError={this.props.loadSessionsListError}
              />
            </div>
          </Layout>
        </div>
      </>
    )
  }

  breadcrumbsBuild = () => {
    let breadcrumbsList = [
      { name: "Reports", link: '/reports' },
      { name: "Sessions", link: null }
    ]
    var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList, null);
    this.props.actions.updateBreadcrumbs(breadcrumbs);
  }

  handleSelectedZone = (e: string) => {
    this.setState({ zoneName: e })
  }

  handleSelectedLocation = (e: string) => {
    this.setState({ locationName: e })
  }

  applyFilters = ({ filterValues: { dateRange, ...filterValues }, ...apiParams }) => {
    this.setState({
      apiParams: {
        ...apiParams,
        ...filterValues,
        dateFrom: AppUtilityService.formatAPIDateFilter(dateRange?.[0]),
        dateTo: AppUtilityService.formatAPIDateFilter(dateRange?.[1]),
      },
      isLoadingList: true,
    })
  }

  checkPermissions = () => {
    let hasPermissions = WebAccessService.hasPermissionToAccess("Reports", "Sessions");
    if (!hasPermissions) {
      hashHistory.push("/unauthorized-access");
    }
  }
}

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ReportsActions from '../../actions/reports/ReportsActions';
import DataCompareComponent from '../../components/reports/DataCompareComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    reportMetricsDataCompareSuccess: state.ReportsReducer.reportMetricsDataCompareSuccess,
    reportMetricsDataCompareError: state.ReportsReducer.reportMetricsDataCompareError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ReportsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataCompareComponent)

import React, { useCallback, useMemo, useState } from 'react';
import { Button, Pagination, Table } from 'antd';
import { createColumn, DataOrSummaryRow } from '../../../common/helpers/tableWithSummaryHelpers';
import { LoadState } from '../../../common/store/fetched';
import { EmptyStateComponent } from '../../../common/components/layouts/EmptyStateComponent';
import { FailedStateComponent } from '../../../common/components/layouts/FailedStateComponent';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { ColumnProps, PaginationConfig, SorterResult } from 'antd/lib/table';
import createLinkColumn from '../../../common/helpers/createLinkColumn';
import {
  LocationInvoicingConfigItem,
  ILocationInvoicingConfigSearchParams, LocationInvoicingConfigSortField, IDefaultLocationInvoicingConfigItem,
} from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';
import { getLocationInvoicingConfigs } from '../../actions/locationInvoicingConfig/getLocationInvoicingConfigs';
import {
  setLocationInvoicingConfigSearchParams,
} from '../../actions/locationInvoicingConfig/slice-actions';
import InvoiceGenerationModal from '../billing/InvoiceGenerationModal';
import { Link } from 'react-router';

function createTableColumns(
  params: ILocationInvoicingConfigSearchParams,
) {
  const otherProps = { sortBy: params.sortBy, sortAsc: params.sortAsc };
  const tableColumns: ColumnProps<DataOrSummaryRow<LocationInvoicingConfigItem>>[] = [
    createColumn(
      '',
      'edit',
      record => <Link to={`/billing/configuration/edit/${record.locationId}`}>Edit</Link>),
    createColumn(
      'Location ID',
      'locationId',
      record => (
        <>
          <Link to={`/locations/${record.locationId}`}>{record.locationId}</Link>
          {record.localCharityId == null ? null : <span> / {record.localCharityId}</span>}
        </>
      ),
      otherProps),
    createLinkColumn(
      'Location Name',
      'locationName',
      record => `/locations/${record.locationId}`,
      otherProps),
    createColumn<LocationInvoicingConfigItem>(
      'Invoicing Enabled',
      'invoicingEnabled',
      record => record.invoicingEnabled
        ? <span className="text-positive">Yes</span>
        : <span className="text-negative">No</span>,
      otherProps),
    createColumn<IDefaultLocationInvoicingConfigItem>(
      'Next Invoice No.',
      'nextInvoiceNoSuffix',
      record => record.nextInvoiceNoSuffix ?? '-',
      otherProps),
    createColumn<IDefaultLocationInvoicingConfigItem>(
      'Due in days',
      'dueInDays',
      record => record.dueInDays ?? '-',
      otherProps),
    createColumn<LocationInvoicingConfigItem>(
      'Distributor',
      'invoicingDistributorName',
      record => record.invoicingDistributorName,
      otherProps),
    createLinkColumn<IDefaultLocationInvoicingConfigItem>(
      'Distributor contact',
      'distributorContactName',
      record => `/contacts/${record.distributorContactId}`,
      otherProps),
    createColumn<IDefaultLocationInvoicingConfigItem>(
      'Additional recipients',
      'additionalInvoiceRecipients',
      record => record.additionalInvoiceRecipients?.replaceAll(';', '; '),
      otherProps),
  ];
  return tableColumns;
}

const BillingConfigurationTable = () => {
  const dispatch = useAppDispatch();
  const params = useAppSelector(state => state.LocationInvoicingConfigReducer.locationInvoicingConfigSearchParams);
  const loadState = useAppSelector(
    state => state.LocationInvoicingConfigReducer.locationInvoicingConfigList.loadState);
  const total = useAppSelector(
    state => state.LocationInvoicingConfigReducer.locationInvoicingConfigList.data?.total ?? 0);
  const items = useAppSelector(
    state => state.LocationInvoicingConfigReducer.locationInvoicingConfigList.data?.locationInvoicingConfigs ?? []);

  const [isInvoiceGenerationModalVisible, setIsInvoiceGenerationModalVisible] = useState<boolean>(false);

  const reloadInvoices = useCallback(() => {
    dispatch(getLocationInvoicingConfigs());
  }, [dispatch]);

  const tableColumns = useMemo(
    () => createTableColumns(params),
    [params]);

  const onTableChange = useCallback((
    _pagination: PaginationConfig,
    _filters: Partial<Record<keyof LocationInvoicingConfigItem, string[]>>,
    sorter: SorterResult<LocationInvoicingConfigItem>,
  ) => {
    dispatch(setLocationInvoicingConfigSearchParams({
      ...params,
      pageNo: 1,
      sortBy: sorter.columnKey as LocationInvoicingConfigSortField,
      sortAsc: sorter.order !== 'descend',
    }));
  }, [dispatch, params]);

  const onPageChange = useCallback((pageNo: number, pageSize: number) => {
    pageNo = params.pageSize !== pageSize ? 1 : pageNo; // set page to 1 when switching page size
    dispatch(setLocationInvoicingConfigSearchParams({ ...params, pageNo, pageSize }));
  }, [dispatch, params]);

  const generateInvoices = async () => {
    setIsInvoiceGenerationModalVisible(true);
  }

  return (
    <>
      <div className="pg-billing-table">
        <Table<LocationInvoicingConfigItem>
          rowKey={record => `${record.locationId}-${record.localCharityId}`}
          loading={loadState === LoadState.InProgress}
          dataSource={items}
          columns={tableColumns}
          pagination={false}
          onChange={onTableChange}
          locale={
            {
              emptyText: loadState !== LoadState.Failed
                ? <EmptyStateComponent title="No location invoicing configs" />
                : (
                  <FailedStateComponent tryAgain={reloadInvoices}>
                    Failed to load invoicing configuration.
                  </FailedStateComponent>
                ),
            }
          }
        />
      </div>
      <div className="pg-billing-footer">
        <Button className={'mr-8'} onClick={generateInvoices} >Generate Invoices</Button>
        <InvoiceGenerationModal
          isVisible={isInvoiceGenerationModalVisible}
          onCancel={() => setIsInvoiceGenerationModalVisible(false)} />
        <Pagination
          disabled={loadState !== LoadState.Success}
          style={{ marginLeft: 'auto' }}
          current={params.pageNo}
          pageSize={params.pageSize}
          total={total}
          onChange={onPageChange}
          showSizeChanger={true}
          onShowSizeChange={onPageChange}
          pageSizeOptions={['10', '20', '50', '1000']}
        />
      </div>
    </>
  );
}

export default BillingConfigurationTable;

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { loadUserRolesDropdown } from '../../actions/dropdown/DropdownActions';
import { DropdownComponent } from '../../components/dropdown/DropdownComponent';
import { RootState } from '../../store/ConfigureStore';


const mapStateToProps = (state: RootState) => {
    return {
        datasource: state.DropdownReducer.loadUserRolesDropdownDataSuccess,
        datasourceError: state.DropdownReducer.loadUserRolesDropdownDataError,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadData: bindActionCreators(loadUserRolesDropdown, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DropdownComponent)

import { downloadOhioQuarterlyOperationUpdated } from './slice-actions';
import { DownloadedFile } from '../../../common/store/fileDownload';
import apiService from '../../../common/services/ApiService';
import { createStandardFetchedAction } from '../../../common/store/fetched.slices';
import { Moment } from 'moment';
import AppConstants from '../../../common/AppConstants';

export const downloadOhioQuarterly = (dateFrom: Moment, dateTo: Moment) => createStandardFetchedAction(
  downloadOhioQuarterlyOperationUpdated,
  async () => {
    const result = await apiService.downloadFile(
      `/reports/invoicing/ohio-quarterly/excel/${dateFrom.format(AppConstants.apiDateFormat)}/${dateTo.format(AppConstants.apiDateFormat)}`);

    const fileName = result.headers['x-filename'];
    return {
      data: result.data,
      fileName,
    } as DownloadedFile;
  },
  false,
);

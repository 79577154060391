import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as QuarterCloseActions from '../../../app/actions/quarterClose/QuarterCloseActions'
import { QuarterCloseSelectGamesPage } from '../../components/quarterClose/QuarterCloseSelectGamesPage'
import { RootState } from '../../../common/store/ConfigureStore';


const mapStateToProps = (state: RootState) => {
    return {
        games: state.QuarterCloseReducer.games,
        loadingGames: state.QuarterCloseReducer.loadingGames,
        featuredGames: state.QuarterCloseReducer.featuredGames,
        loadingFeaturedGames: state.QuarterCloseReducer.loadingFeaturedGames,
        selectedSetup: state.QuarterCloseReducer.selectedSetup,
        gameSelectionWindowId: state.QuarterCloseReducer.gameSelectionWindowId,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators( QuarterCloseActions, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(QuarterCloseSelectGamesPage)

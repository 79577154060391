import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { loadContactsAutocomplete } from '../../actions/dropdown/AutocompleteActions';
import { DropdownComponent } from '../../components/dropdown/DropdownComponent';
import { RootState } from '../../store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
    return {
        datasource: state.AutocompleteReducer.loadContactsAutocompleteDataSuccess,
        datasourceError: state.AutocompleteReducer.loadContactsAutocompleteDataError,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadData: bindActionCreators(loadContactsAutocomplete, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DropdownComponent)

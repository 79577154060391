import React, { useCallback } from 'react';
import { Button, Form, Modal, Row, Col, InputNumber } from 'antd';
import CharitiesAutocompleteContainer from "../../../common/containers/dropdown/CharitiesAutocompleteContainer";
import { useAppDispatch } from '../../../common/hooks/storeHooks';
import { addLocationLinkedCharity } from '../../actions/locations/LocationsActions';
import { FormComponentProps, ValidationRule } from 'antd/lib/form/Form';

interface AssignCharityToLocationFormFields {
  charityId: number | null;
  localCharityId: number | null;
}

export type Props = FormComponentProps<AssignCharityToLocationFormFields> & {
  locationId: number;
  assignedCharities?: Charity[];
  onCancel: () => void;
};

const AssignCharityToLocationModal = (
  {
    locationId,
    assignedCharities,
    onCancel,
    form: { getFieldDecorator, validateFields },
  }: Props,
) => {
  const dispatch = useAppDispatch();

  const handleSubmit = useCallback(
    () => {
      validateFields((errors, values) => {
        if (errors) return;
        dispatch(addLocationLinkedCharity(locationId, values.charityId, values.localCharityId));
      });
    },
    [validateFields]);

  const localCharityIdValidator: ValidationRule['validator'] = (rule, value, callback) => {
    if (assignedCharities?.find(c => c.localCharityId == value)) {
      callback('Local Charity ID must be unique');
    }
    callback();
  };

  return (
    <Modal
      visible
      title="Add Charity"
      onOk={() => {
      }}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>Cancel</Button>,
        <Button key="submit" type="primary" htmlType="submit" onClick={handleSubmit}>Add</Button>,
      ]}
    >
      <Form>
        <Row>
          <Col>
            <Form.Item label="Charity">
              {getFieldDecorator('charityId', {
                rules: [{ required: true, message: 'Please select a charity' }],
                validateTrigger: ['onSubmit'],
              })(
                <CharitiesAutocompleteContainer
                  placeholder="Select charity"
                  customParams={{
                    locationId,
                    loadIdRelated: false,
                  }}
                  showAllResultsWhenNothingAssigned={true}
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item label="Local Charity ID">
              {getFieldDecorator('localCharityId', {
                rules:[{ validator: localCharityIdValidator }],
                validateTrigger: ['onSubmit'],
              })(
                <InputNumber precision={0}/>,
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Form.create<Props>()(AssignCharityToLocationModal);

import * as React from "react";
import { isEqual } from "lodash";

import AppUtilityService from "../../../../common/services/AppUtilityService";
import { DataGridComponent } from "../../../../common/components/table/DataGridComponent";
import AppConstants from "../../../../common/AppConstants";

import { Spin } from 'antd';
import { ISessionsReportComponentProps, ISessionsReportComponentState } from '../Reports';
import { ISessionsListRequestParams } from '../../../reducers/reports/ReportsReducer';

export class SessionsReportComponent extends React.Component<ISessionsReportComponentProps, ISessionsReportComponentState> {

  private gridContainerRef: any;

  constructor(props: ISessionsReportComponentProps) {
    super(props);
    this.state = {
      sessionsList: null,
      isLoading: false,
      downloadType: "pdf",
      apiParams: this.props.apiParams,
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillReceiveProps(nextProps: ISessionsReportComponentProps) {
    if (nextProps.apiParams && !isEqual(this.props.apiParams, nextProps.apiParams)) {
      this.gridContainerRef && this.gridContainerRef.resetDataGrid();
      this.loadSessionsList({
        ...nextProps.apiParams,
        pageSize: 1000, // temporarily paging done on the client
      });
      this.setState({ apiParams: nextProps.apiParams })
    }

    if (nextProps.loadSessionsListSuccess && !isEqual(this.props.loadSessionsListSuccess, nextProps.loadSessionsListSuccess)) {
      this.setState({ sessionsList: nextProps.loadSessionsListSuccess.items, isLoading: false });
    }

    if (nextProps.loadSessionsListError && !isEqual(this.props.loadSessionsListError, nextProps.loadSessionsListError)) {
      this.setState({ isLoading: false, sessionsList: null })
      AppUtilityService.handleApiError(nextProps.loadSessionsListError);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }
  render() {
    return (
      <Spin spinning={this.state.isLoading}>
        <div style={{ padding: "24px" }}>
          <div>
            <DataGridComponent
              ref={ref => (this.gridContainerRef = ref)}
              toolbarContent={<h2>Detailed view of selected time period:</h2>}
              dataSource={this.state.sessionsList}
              dataSrcColumns={AppConstants.sessionsReportColumns}
              isLoading={this.state.isLoading}
              summaryAvgColumns={undefined}
              summarySumColumns={undefined}
              showPageSizeSelector={true}
              storeIdentifier="SessionsReport"
            />
            </div>
        </div>
      </Spin>
    )
  }

  loadSessionsList = (apiParams: ISessionsListRequestParams) => {
    this.setState({ isLoading: true, sessionsList: null })
    this.props.actions.loadSessionsList(apiParams);
  }

}
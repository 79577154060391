import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as LocationTypesActions from '../../actions/locationTypes/LocationTypesActions';
import { LocationTypeListTableComponent } from '../../components/locationTypes/LocationTypeListTableComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    locationTypesListFilters: state.LocationTypesReducer.locationTypesListFilters,

    loadLocationTypesListSuccess: state.LocationTypesReducer.loadLocationTypesListSuccess,
    loadLocationTypesListError: state.LocationTypesReducer.loadLocationTypesListError,

    deleteLocationTypeSuccess: state.LocationTypesReducer.deleteLocationTypeSuccess,
    deleteLocationTypeError: state.LocationTypesReducer.deleteLocationTypeError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(LocationTypesActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationTypeListTableComponent)

import React, { useMemo } from 'react';
import { debounce } from 'lodash';
import { FilterProps } from '../reports/locationStatisticsReports/LocationReportsFilterComponent';
import { SearchComponent } from '../../../common/components/inputElements/SearchComponent';

const TextFilter = ({ filterValue, onFilterValueChange, customPlaceHolderText }: FilterProps) => {
  const handleSearch = useMemo(
    () => debounce(onFilterValueChange, 500),
    [onFilterValueChange]);
  return (
    <SearchComponent
      placeholder={customPlaceHolderText || 'Search'}
      handleSearchCallback={handleSearch}
      width={200}
      searchTerm={(filterValue ?? '').toString()}
    />
  );
}

export default TextFilter;


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { loadUsersLinkedLocationsAutocomplete } from '../../actions/autocomplete/AutocompleteActions';
import { DropdownComponent } from '../../../common/components/dropdown/DropdownComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
    return {
        datasource: state.AdminAutocompleteReducer.loadUsersLinkedLocationsSuccess,
        datasourceError: state.AdminAutocompleteReducer.loadUsersLinkedLocationsError,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadData: bindActionCreators(loadUsersLinkedLocationsAutocomplete, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DropdownComponent)

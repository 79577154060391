import * as React from 'react';
import { debounce, isEqual, isEmpty } from 'lodash';
import { Link } from 'react-router';
import AppUtilityService from '../../../common/services/AppUtilityService';
import { EmptyStateComponent } from '../../../common/components/layouts/EmptyStateComponent';

import { Table, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

export class ContactsListTableComponent extends React.Component<IContactsListProps, IContactsListState> {
  constructor(props: IContactsListProps) {
    super(props);
    this.state = {
      pagination: {
        page: 1,
        pageSize: 30,
        total: 5,
      },
      sortInfo: null,
      searchText: null,
      isLoading: true,
      contactsList: [],
    };
    this.loadContactsList = debounce(this.loadContactsList, 500);
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    if (this.props.contactListFilters) {
      this.handlePresetFilters(this.props.contactListFilters, true);
    } else {
      this.loadContactsList();
    }
  }

  componentWillReceiveProps(nextProps: IContactsListProps) {
    if (nextProps.contactsListSuccess && !isEqual(this.props.contactsListSuccess, nextProps.contactsListSuccess)) {
      let pagination = { ...this.state.pagination };
      var responseData: any = nextProps.contactsListSuccess;
      pagination.total = responseData.total;
      this.props.getTotalContactsCallback(pagination.total);
      this.setState({ contactsList: responseData.contacts, pagination, isLoading: false });
    }

    if (nextProps.contactsListError && !isEqual(this.props.contactsListError, nextProps.contactsListError)) {
      this.setState({ isLoading: false });
      AppUtilityService.handleApiError(nextProps.contactsListError);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  render() {
    const { isLoading, pagination } = this.state;
    const tableColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        sortOrder: AppUtilityService.getTableColumnSortOrder(this.state.sortInfo, 'name'),
        render: (text, record) => {
          return (
            <div>
              {AppUtilityService.getContactAvatar(record.image)}
              <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '6px' }}>
                <Link to={'/contacts/' + record.id}>{text}</Link>
                <div className="font-12 t-grey-darker">{record.email}</div>
              </div>
            </div>
          );
        },
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        sorter: true,
        sortOrder: AppUtilityService.getTableColumnSortOrder(this.state.sortInfo, 'location'),
        className: 'hidden-sm',
        render: (text, record) => {
          return text == '' ? '-' : text;
        },
      },
      {
        title: 'Created Date',
        dataIndex: 'created',
        key: 'created',
        sorter: true,
        sortOrder: AppUtilityService.getTableColumnSortOrder(this.state.sortInfo, 'created'),
        className: 'hidden-sm',
        render: (text, record) => {
          return AppUtilityService.formatDate(text);
        },
      },
    ];

    return (
      <Table
        rowKey={(record, index) => {
          return record.id.toString();
        }}
        loading={isLoading}
        dataSource={this.state.contactsList}
        columns={tableColumns}
        pagination={{ current: this.state.pagination.page, pageSize: pagination.pageSize, total: pagination.total }}
        onChange={this.handleTableChange}
        locale={{
          emptyText: <EmptyStateComponent title="No Contacts" />,
        }}
      />
    );
  }

  handleSearchChange = (value) => {
    let pagination = { ...this.state.pagination };
    pagination.page = 1;
    this.setState({ pagination, searchText: value }, () => {
      this.loadContactsList();
    });
  };

  /**
   * Triggered when table pagination, sortInfo or filter changes
   */
  handleTableChange = (updatedPagination, filters, sorter) => {
    let pagination = { ...this.state.pagination };
    let sortInfo = { ...this.state.sortInfo };
    pagination.page = updatedPagination.current;
    if (sorter && !isEmpty(sorter)) {
      sortInfo = {
        sortDir: sorter.order,
        propertyName: sorter.field,
      };
    } else {
      sortInfo = null;
    }
    this.setState({ pagination, sortInfo }, () => {
      this.loadContactsList();
    });
  };

  loadContactsList = () => {
    this.setState({ isLoading: true });
    let { pagination, sortInfo, searchText } = this.state;
    let params = {
      page: pagination.page,
      pageSize: pagination.pageSize,
      sortDir: (sortInfo && sortInfo.sortDir) || null,
      propertyName: (sortInfo && sortInfo.propertyName) || null,
      searchText: searchText,
    };
    this.props.actions.loadContactsListData(params);
    this.props.actions.updateContactListFilters(params);
  };

  handlePresetFilters = (filterData, isFirstMount) => {
    let pagination = { ...this.state.pagination };
    pagination.page = filterData.page;
    pagination.pageSize = filterData.pageSize;
    var sortInfo = null;
    if (filterData.sortDir && filterData.propertyName) {
      sortInfo = {
        sortDir: filterData.sortDir,
        propertyName: filterData.propertyName,
      };
    }
    var searchText = filterData.searchText;
    this.setState({ pagination, sortInfo, searchText }, () => {
      if (isFirstMount) {
        this.loadContactsList();
      }
    });
  };
  resetPageFilters = () => {
    let pagination = { ...this.state.pagination };
    pagination.page = 1;
    this.setState({ pagination, searchText: '' }, () => {
      this.loadContactsList();
    });
  };
}

import * as React from "react";
import {isEqual} from "lodash";
import ContactDetailsContainer from "../../containers/contacts/ContactDetailsContainer";
import ContactNotesCardContainer from "../../containers/contacts/ContactNotesCardContainer";
import AppUtilityService from "../../../common/services/AppUtilityService";

import { Layout } from 'antd';

export class ContactDetailsPage extends React.Component<IContactDetailsPageProps, IContactDetailsPageState> {

  constructor(props: IContactDetailsPageProps) {
    super(props);

    this.state = {
      contactId: parseInt(this.props.params.contactId) || null,
      contactDetails: null
    }
  }

  componentWillReceiveProps(nextProps: IContactDetailsPageProps) {
    if (nextProps.contactDetails && !isEqual(this.props.contactDetails, nextProps.contactDetails)) {
      var responseData: any = nextProps.contactDetails;
      this.setState({ contactDetails: responseData }, () => {
        this.breadcrumbsBuild()
      })
    }

  }

  render() {
    return (
      <div className="pg-container no-padding-page-container">
        <Layout>
          <ContactDetailsContainer contactId={this.state.contactId} />
        </Layout>
      </div>
    )
  }

  breadcrumbsBuild = () => {
    let breadcrumbsList = [
      { name: "Contacts", link: "/contacts" },
      { name: this.state.contactDetails.name, link: null }
    ]
    var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
    this.props.actions.updateBreadcrumbs(breadcrumbs);
  }


}

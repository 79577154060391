import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { loadZonesDropdown } from '../../actions/dropdown/DropdownActions';
import { ZonesDropdownComponent } from '../../components/dropdown/ZonesDropdownComponent';
import { RootState } from '../../store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
    return {
        dataSource: state.DropdownReducer.loadZonesDropdownDataSuccess,
        dataSourceError: state.DropdownReducer.loadZonesDropdownDataError,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadData: bindActionCreators(loadZonesDropdown, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ZonesDropdownComponent)

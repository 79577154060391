import { downloadNhLucky7MonthlyFinancialReportOperationUpdated } from './slice-actions';
import { DownloadedFile, saveFile } from '../../../common/store/fileDownload';
import apiService from '../../../common/services/ApiService';
import { createStandardFetchedAction } from '../../../common/store/fetched.slices';

export const downloadNhLucky7MonthlyFinancialReport =
  (year: number, month: number, locationId: number) => createStandardFetchedAction(
    downloadNhLucky7MonthlyFinancialReportOperationUpdated,
    async () => {
      const result = await apiService.downloadFile(
        `/reports/nh-lucky7-monthly-financial-report/excel/${year}/${month}/${locationId}`);

      const fileName = result.headers['x-filename'];
      const file: DownloadedFile = {
        data: result.data,
        fileName,
      };
      saveFile(file);
    },
    false,
  );

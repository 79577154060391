import { invoicingReportsSlice } from '../../reducers/invoicingReports/InvoicingReportsReducer';

export const {
  downloadOhioWeeklyNumbersOperationUpdated,
  downloadOhioQuarterlyOperationUpdated,
  ohioQuarterlyReportUpdated,
  weeklyNumbersReportUpdated,
  dimissWeeklyNumbersReport,
  ohioElectronicInstantBingoReportUpdated,
  downloadOhioElectronicInstantBingoReportOperationUpdated,
  downloadNorthDakotaInvoicesReportOperationUpdated,
  downloadDistributorDealsDeliveredReportOperationUpdated,
  downloadNorthDakotaGamesOperationUpdated,
  downloadVirginiaSalesTaxOperationUpdated,
  downloadNhLucky7MonthlyFinancialReportOperationUpdated,
  downloadKYWS06bReportOperationUpdated,
} = invoicingReportsSlice.actions;


import * as React from "react";
import { isEqual } from "lodash";
import { FormComponentProps } from 'antd/lib/form/Form'
import AppUtilityService from "../../../common/services/AppUtilityService";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";
import { Link } from "react-router";

import { Card, Form, Modal, Row, Col, List } from 'antd';
import WebAccessService from "../../../common/services/WebAccessService";
import AssignCharityToLocationModal from './AssignCharityToLocationModal';
const confirm = Modal.confirm;


class LocationLinkedCharitiesComponent extends React.Component<ILocationCharitiesProps & FormComponentProps, ILocationCharitiesState> {

  constructor(props: ILocationCharitiesProps & FormComponentProps) {
    super(props);
    this.state = {
      locationDetails: null,
      showAddForm: false,
      isLoading: true
    }
  }


  componentWillReceiveProps(nextProps: ILocationCharitiesProps & FormComponentProps) {
    if (nextProps.loadBasicDetailsSuccess && !isEqual(this.props.loadBasicDetailsSuccess, nextProps.loadBasicDetailsSuccess)) {
      this.setState({ locationDetails: nextProps.loadBasicDetailsSuccess, isLoading: false })
    }
    /**
     * add location charity success/error handler
     */
    if (nextProps.addLocationCharitySuccess && !isEqual(this.props.addLocationCharitySuccess, nextProps.addLocationCharitySuccess)) {
      var locationDetails = { ...this.state.locationDetails };
      locationDetails.charities = nextProps.addLocationCharitySuccess;
      AppUtilityService.showMessageToast("Charity added", "success");
      this.props.form.resetFields();
      this.setState({ locationDetails, showAddForm: false, isLoading: false })
    }
    if (nextProps.addLocationCharityError && !isEqual(this.props.addLocationCharityError, nextProps.addLocationCharityError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.addLocationCharityError)
    }
    /**
     * Delete location charity success/error handler
     */
    if (nextProps.deleteLocationCharitySuccess && !isEqual(this.props.deleteLocationCharitySuccess, nextProps.deleteLocationCharitySuccess)) {
      var locationDetails = { ...this.state.locationDetails };
      var responseData = nextProps.deleteLocationCharitySuccess;
      locationDetails.charities = responseData;
      AppUtilityService.showMessageToast("Charity removed", "success");
      this.setState({ locationDetails, isLoading: false })
    }
    if (nextProps.deleteLocationCharityError && !isEqual(this.props.deleteLocationCharityError, nextProps.deleteLocationCharityError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.deleteLocationCharityError)
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    var extraActions = WebAccessService.hasPermissionToAccess("Location", "AddCharity") &&
      <span className="link-primary" onClick={this.toggleAddForm}><i className={this.state.showAddForm ? "icon-minus" : "icon-add"} /></span>

    return (
      <Card className={"no-body-padding show-border"} bordered={false}
        title="Charities" extra={extraActions}
      >
        <List
          loading={this.state.isLoading}
          itemLayout="horizontal"
          dataSource={this.state.locationDetails ? this.state.locationDetails.charities : []}
          locale={
            {
              emptyText: <EmptyStateComponent title="No Linked Charities" />
            }
          }
          renderItem={(item: Charity) => (
            <List.Item actions={this.generateActions(item)}>
              <List.Item.Meta
                title={<span><i style={{ fontSize: '18px', verticalAlign: "sub" }} className="icon-charity-filled mr-8 t-grey-dark" />
                  <Link to={"/charities/" + item.id} className="link-primary">{item.name}</Link>
                </span>}
                description={
                  <ul className="mini-card-details" style={{ paddingLeft: "44px" }}>
                    {
                      item.licenseNumber &&
                      <li>License no: {item.licenseNumber}</li>
                    }
                    {
                      item.localCharityId &&
                      <li>Local Charity ID: {item.localCharityId}</li>
                    }
                    {
                      (item.address1 || item.address2 || item.city || item.zipcode || item.addressState) &&
                      <li>
                        {
                          AppUtilityService.displayStringFromArray([item.address1, item.address2, item.city, item.addressState, item.zipcode])
                        }
                      </li>
                    }
                  </ul>
                }
              />

            </List.Item>
          )}
        />
        {
          this.state.showAddForm
          &&
          <AssignCharityToLocationModal
            locationId={this.state.locationDetails.id}
            assignedCharities={this.state.locationDetails?.charities}
            onCancel={this.toggleAddForm}
          />
        }
      </Card>
    )
  }

  generateActions = (item: Charity): Array<any> => {
    var actions = [];
    if (WebAccessService.hasPermissionToAccess("Location", "DeleteCharity")) {
      actions.push(<i className="icon-remove link-danger" onClick={() => this.deleteCharity(item.id)} />)
    }
    return actions;
  }

  toggleAddForm = () => {
    let { showAddForm } = this.state;
    if (showAddForm) {
      showAddForm = false;
    } else {
      showAddForm = true;
    }
    this.setState({ showAddForm })
  }

  deleteCharity = (charityId: number) => {
    var { locationDetails } = this.state;
    confirm({
      title: 'Confirm dialog',
      content: 'Are you sure you want to remove the selected charity linked to this location?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        this.setState({ isLoading: true })
        this.props.actions.deleteLocationLinkedCharity(locationDetails.id, charityId);
      },
    });
  }

} export default Form.create<ILocationCharitiesProps & FormComponentProps>()(LocationLinkedCharitiesComponent);

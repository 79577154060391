import { Button, Dropdown, Icon, Menu, Modal, Popconfirm } from 'antd';
import WebAccessService from '../../../common/services/WebAccessService';
import { IInvoice, InvoiceAttachmentType, InvoiceStatus } from '../../reducers/billing/billing.models';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { updateInvoiceStatus } from '../../actions/billing/updateInvoiceStatus';
import { LoadState } from '../../../common/store/fetched';
import { downloadInvoiceAttachmentAndSave } from '../../actions/billing/downloadInvoiceAttachmentAndSave';
import ButtonGroup from 'antd/lib/button/button-group';
import { resendInvoice } from '../../actions/billing/resendInvoice';
import { regenerateInvoice } from '../../actions/billing/regenerateInvoice';

interface IInvoiceDetailsProps {
  invoice: IInvoice;
}

export function InvoiceActions(props: IInvoiceDetailsProps) {
  const { invoiceId, invoiceStatusId, deliveredDeals } = props.invoice;
  const canModify = WebAccessService.hasPermissionToAccess("Invoices", "Modify");
  const dispatch = useAppDispatch();

  const downloadInvoicePdf = useCallback(
    () => dispatch(downloadInvoiceAttachmentAndSave(invoiceId, InvoiceAttachmentType.InvoicePdf)),
    [invoiceId, dispatch]
  );
  const downloadDeliveredDeals = useCallback(
    () => dispatch(downloadInvoiceAttachmentAndSave(invoiceId, InvoiceAttachmentType.DeliveredDeals)),
    [invoiceId, dispatch]
  );

  const changeStatus = useCallback((targetStatus: InvoiceStatus) => {
    dispatch(updateInvoiceStatus(props.invoice, targetStatus));
  }, [props.invoice, dispatch]);

  const toApproved = useCallback(() => changeStatus(InvoiceStatus.Approved), [changeStatus]);
  const toSent = useCallback(() => changeStatus(InvoiceStatus.Sent), [changeStatus]);
  const toPending = useCallback(() => changeStatus(InvoiceStatus.PendingReview), [changeStatus]);
  const resend = useCallback(() => dispatch(resendInvoice(props.invoice)), [dispatch, props.invoice]);
  const regenerate = useCallback(() => dispatch(regenerateInvoice(invoiceId)), [dispatch, invoiceId]);
  const confirmRegenerate = useCallback(() => {
    Modal.confirm({
      content: (<>
        <p className="bold">Regenerate invoice?</p>
        <p>
          Regenerating invoice will refresh all invoice data from source keeping existing adjustments and notes.<br/>
          It may affect rolled over credit in future invoices for the location.
        </p>
      </>),
      okText: 'Regenerate',
      cancelText: 'Cancel',
      autoFocusButton: 'cancel',
      onOk: regenerate,
    });
  }, [regenerate]);

  const statusChangeInProgress = useAppSelector(state => {
    const { updateStatusOperation: operation } = state.BillingReducer;
    return operation.loadState === LoadState.InProgress ? operation.context.request.targetStatus : null;
  });

  const additionalDownloads: { title: string, action: () => void }[] = [];

  if (deliveredDeals.length > 0) {
    additionalDownloads.push({ title: "Delivered deals sheet", action: downloadDeliveredDeals });
  }

  const canRegenerate = invoiceStatusId === InvoiceStatus.PendingReview && canModify;
  const extraMenuItems = [
    canRegenerate ? { title: 'Regenerate invoice', onClick: confirmRegenerate } : null,
  ].filter(x => x != null);

  const extraMenu = extraMenuItems.length > 0 ? (
    <Menu>
      {extraMenuItems.map(x => (
        <Menu.Item key={x.title} onClick={x.onClick}>
          {x.title}
        </Menu.Item>
      ))}
    </Menu>
  ) : null;

  return (
    <>
      {additionalDownloads.length === 0 && (
        <Button icon="download" onClick={downloadInvoicePdf}>Invoice PDF</Button>
      )}
      {additionalDownloads.length > 0 && (
        <ButtonGroup>
          <Button icon="download" onClick={downloadInvoicePdf}>Invoice PDF</Button>
          <Dropdown placement="bottomRight" overlay={(
            <Menu>
              {additionalDownloads.map(x => (<Menu.Item key={x.title} onClick={x.action}>{x.title}</Menu.Item>))}
            </Menu>
          )}>
            <Button icon="down" />
          </Dropdown>
        </ButtonGroup>
      )}
      {invoiceStatusId === InvoiceStatus.PendingReview && canModify && (
        <>
          <Button
            type="primary"
            className="ml-8"
            onClick={toApproved}
            loading={statusChangeInProgress === InvoiceStatus.Approved}
          >
            Approve
          </Button>
          <Button
            type="primary"
            className="ml-8"
            onClick={toSent}
            loading={statusChangeInProgress === InvoiceStatus.Sent}
          >
            Approve &amp; Send
          </Button>
        </>
      )}
      {invoiceStatusId === InvoiceStatus.Approved && canModify && (
        <>
          <Popconfirm title="Cancel approval?" okType="primary" onConfirm={toPending}>
            <Button
              type="danger"
              className="ml-8"
              loading={statusChangeInProgress === InvoiceStatus.PendingReview}
            >
              Cancel approval
            </Button>
          </Popconfirm>
          <Button
            type="primary"
            className="ml-8"
            onClick={toSent}
            loading={statusChangeInProgress === InvoiceStatus.Sent}
          >
            Send
          </Button>
        </>
      )}
      {invoiceStatusId === InvoiceStatus.Sent && canModify && (
        <>
          <Popconfirm title="Mark As Not Sent?" okType="primary" onConfirm={toApproved}>
            <Button
              type="danger"
              className="ml-8"
              loading={statusChangeInProgress === InvoiceStatus.Approved}
            >
              Mark As Not Sent
            </Button>
          </Popconfirm>
          <Button className="ml-8" onClick={resend}>Resend</Button>
        </>
      )}
      {extraMenu && (
        <Dropdown overlay={extraMenu} className="ml-8">
          <Button type="default"><Icon type="ellipsis" /></Button>
        </Dropdown>
      )}
    </>
  )
}

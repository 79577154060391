
import { useState, useEffect } from "react"
import { Row, Col, Card, Table, Button, DatePicker, Select, Checkbox, Modal, Spin } from "antd"
import { IGameSelectionWindow, IAddGameSelectionWindow, IGameSelectionWindowLocation, IAddGameSelectionWindowLocation, ISelectLocations, IWindowGame } from "../../reducers/gameSelectionWindow/GameSelectionWindowReducer"
import moment from "moment"
import AppUtilityService from "../../../common/services/AppUtilityService"
import { hashHistory } from "react-router";
const confirm = Modal.confirm;

export interface IGameSelectionWindowPageProps {
    actions?: {
        loadGameSelectionWindows(): void,
        loadSelectLocations(): void,
        deleteGameSelectionWindows(gameSelectionWindowId: number): void,
        createGameSelectionWindows(gameSelectionWindow: IAddGameSelectionWindow): void,
        updateGameSelectionWindows(gameSelectionWindow: IGameSelectionWindow): void,
        updateBreadcrumbs(Breadcrumbs): void,
    },  
    isLoadingGameSelectionWindows: boolean,
    isLoadingSelectLocations: boolean,
    isLoadingGameSelectionWindowGames: boolean,
    postingGameSelectionwindow: boolean,
    loadGameSelectionWindowsSuccess: IGameSelectionWindow[],
    loadGameSelectionWindowsError: any,
    loadSelectLocationsSuccess: ISelectLocations[],
    loadSelectLocationsError: any,
}

export interface IGameSelectionWindowPageState {
    isLoadingGameSelectionWindows: boolean,
    isLoadingSelectLocations: boolean,
    isLoadingGameSelectionWindowGames: boolean,
    loadGameSelectionwindowsSuccess: IGameSelectionWindow[],
    newGameSelectionWindow: IAddGameSelectionWindow,
    editGameSelectionWindows: IGameSelectionWindow[],
    editGameSelectionWindow: IGameSelectionWindow,
    initialGameSelectionWindows: IGameSelectionWindow[],
    window: IGameSelectionWindow | IAddGameSelectionWindow,
}

const GameSelectionWindowPage = (props) => {
    useEffect(() => {
      breadcrumbsBuild();
      props.actions.loadGameSelectionWindows();
      props.actions.loadSelectLocations();
    }, [])

    
    const [editGameSelectionWindows, setEditGameSelectionWindows] = useState<IGameSelectionWindow[]>([])

    let { loadGameSelectionWindowsSuccess, loadSelectLocationsSuccess } = props;
            
    const initialGameSelectionWindow = {gameSelectionWindowId:null, windowStartDate: null, allLocations: false, userId: null, createdDate: null, updatedDate: null, deletedDate: null, locations: [], selectedAndAvailableGames: [], windowGames: [], window: null}
        
    let [newGameSelectionWindow, setNewGameSelectionWindow] = useState<IAddGameSelectionWindow>({gameSelectionWindowId:null, windowStartDate: null, allLocations: false, userId: null, createdDate: null
      , updatedDate: null, deletedDate: null, locations: [], selectedAndAvailableGames: []
      , windowGames: [], window: null})
    
      const isValidDates = (fromDate):boolean => {
        const mfromDate = moment(fromDate)
        let valid = mfromDate.isValid()
        return valid
    }

    const isValidLocations = (allLocations: boolean, locations: IAddGameSelectionWindowLocation[]): boolean => {
      if (allLocations == false && (locations?.length == 0 || locations?.length == undefined)) {
        return false;
      }
      else {
        return true;
      }
    } 
    
    const selectGames = (gameSelectionWindowId: number | void, isViewOnly: boolean) => {
      let isNewWindow = gameSelectionWindowId === undefined || gameSelectionWindowId === null ? true : false;
      
      if (!isNewWindow && !isViewOnly) {
      //edit window
      let editGameSelectionWindow = editGameSelectionWindows.find(w => w.gameSelectionWindowId === gameSelectionWindowId)
      hashHistory.push({
        pathname: `/admin/gameSelectionWindow/gameSelectionWindowSelectGames/${gameSelectionWindowId}`,
        state: {editGameSelectionWindow, gameSelectionWindowId, isNewWindow, isViewOnly}
      });
      } if (isNewWindow) {
        //new window, pass in last all locations window that was defined
        let newWindow = {...newGameSelectionWindow}
        newWindow.windowGames = props.loadGameSelectionWindowsSuccess.find(w => w.isLatestAllLocationWindow).windowGames
        newWindow.selectedAndAvailableGames = props.loadGameSelectionWindowsSuccess.find(w => w.isLatestAllLocationWindow).selectedAndAvailableGames
        
        hashHistory.push({
          pathname: `/admin/gameSelectionWindow/gameSelectionWindowSelectGames/`,
          state: {newWindow, gameSelectionWindowId, isNewWindow, isViewOnly}        
        });
      } if (!isNewWindow && isViewOnly) {
        //view Only
        let editGameSelectionWindow = editGameSelectionWindows.find(w => w.gameSelectionWindowId === gameSelectionWindowId && w.selectedAndAvailableGames.find
          (g => g.selected === true));
        hashHistory.push({
        pathname: `/admin/gameSelectionWindow/gameSelectionWindowSelectGames/${gameSelectionWindowId}`,
        state: {editGameSelectionWindow, gameSelectionWindowId, isNewWindow, isViewOnly}
      });
      }  
    }

    const IsInEditMode = (gameSelectionWindowId: number): boolean => {
      let result = editGameSelectionWindows?.filter(w => w.gameSelectionWindowId == gameSelectionWindowId).length > 0
      return result
    }

    const hasGames = (gameSelectionWindowId: number): boolean => {
      let result = loadGameSelectionWindowsSuccess.find(g => g.gameSelectionWindowId === gameSelectionWindowId).selectedAndAvailableGames?.filter(w => w.selected === true).length > 0;
      return result;
    }

    const GetStartDate = (gameSelectionWindowId: number) => {
        return editGameSelectionWindows.find(p => p.gameSelectionWindowId === gameSelectionWindowId).windowStartDate
    }

    const breadcrumbsBuild = () => {
      let breadcrumbsList = [
        { name: "Game Selection Window", link: null }
      ]

      var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
      props.actions.updateBreadcrumbs(breadcrumbs);
    }

    const addWindowChangeLocations = (values) => {
      let added = newGameSelectionWindow
      let locations: IAddGameSelectionWindowLocation[] = []
      values.forEach(element => {
        let emptyLocation: IAddGameSelectionWindowLocation = { gameSelectionWindowId: null, locationId: null, id: null};
        emptyLocation.locationId = parseInt(element);
        locations.push(emptyLocation);
      });

      added.locations = locations.map(item => {
        return item
      })
      setNewGameSelectionWindow(added)
    }

    const editWindowChangeLocations = (values, locationIds, gameSelectionWindowId) => {
      const updated = editGameSelectionWindows
      let window = updated.find(w => w.gameSelectionWindowId == gameSelectionWindowId)
      let locations: IGameSelectionWindowLocation[] = []
      
      values.forEach(element => {
        let emptyLocation: IGameSelectionWindowLocation = { gameSelectionWindowId: null, locationId: null, id: null, name: null};
        if (isNaN(element)) {
          var start = element.slice(element.indexOf("["))
          let id = start.replace(/[^0-9]/g,"")
          emptyLocation.locationId = parseInt(id)
        } else {
          emptyLocation.locationId = parseInt(element); 
        }
        if (!isNaN(emptyLocation.locationId)) {
          locations.push(emptyLocation);
        }        
      });

      window.locations = locations.map(item => {
        return item
      })
      setEditGameSelectionWindows([...editGameSelectionWindows, updated.find(w => w.gameSelectionWindowId == gameSelectionWindowId)])
    }

    const dateFormat = 'MM/DD/YYYY'
  
    const {Option} = Select;

    let children = loadSelectLocationsSuccess?.map(location => {
      return <Option key={location.id} label={location.name}> {location.name} </Option>
    }) ?? [];

    const tableColumns = [
        {
            title: 'Window Start Date',
            dataIndex: 'windowStartDate',
            key: 'windowStartDate',
            render: (text, record: IGameSelectionWindow) => {
                return (
                    <>
                        {!IsInEditMode(record.gameSelectionWindowId) &&
                            <div>{text ? moment.utc(new Date(text)).format('l') : ''}</div>
                        }
                        {IsInEditMode(record.gameSelectionWindowId) &&
                            <DatePicker onChange={(date) => {
                                const updated = editGameSelectionWindows
                                let window = updated.find(w => w.gameSelectionWindowId == record.gameSelectionWindowId)
                                window.windowStartDate = date.toDate()
                              setEditGameSelectionWindows([...editGameSelectionWindows, updated.find(w => w.gameSelectionWindowId == record.gameSelectionWindowId)])
                            }}
                                value={moment(GetStartDate(record.gameSelectionWindowId))}
                                format={dateFormat}
                            />
                        }
                    </>
                )
            },
        },
        {
          title: 'All Locations',
          dataIndex: 'allLocations',
          render: (text, record: IGameSelectionWindow) => {
            return (
              <>
                {!IsInEditMode(record.gameSelectionWindowId) &&
                <Checkbox 
                defaultChecked={loadGameSelectionWindowsSuccess.find(w => w.gameSelectionWindowId == record.gameSelectionWindowId).allLocations}
                disabled                
                >
                  {record.allLocations}
                </Checkbox>}
                {IsInEditMode(record.gameSelectionWindowId) &&
                  <Checkbox 
                  defaultChecked={loadGameSelectionWindowsSuccess.find(w => w.gameSelectionWindowId == record.gameSelectionWindowId).allLocations}
                  onChange={(e) => {
                    const updated = editGameSelectionWindows
                    let window = updated.find(w => w.gameSelectionWindowId == record.gameSelectionWindowId)
                    window.allLocations = e.target.checked
                    const loaded = loadGameSelectionWindowsSuccess
                    let loadedWindow = loaded.find(w => w.gameSelectionWindowId == record.gameSelectionWindowId)
                    loadedWindow.allLocations = e.target.checked
                    setEditGameSelectionWindows([...editGameSelectionWindows, updated.find(w => w.gameSelectionWindowId == record.gameSelectionWindowId)])
                  }}
                  >
                    {record.allLocations}
                  </Checkbox>
                }
              </>
            )
          }
        },
        {
          title: 'Locations',
          dataIndex: 'locations',
          key: 'locationId',
          render: (text, record: IGameSelectionWindow) => {
            return (
              <>
                  {!IsInEditMode(record.gameSelectionWindowId) &&
                    <div style={{width: "850px"}}>
                      {loadGameSelectionWindowsSuccess?.find(w => w.gameSelectionWindowId == record.gameSelectionWindowId).allLocations == true ?
                    "--ALL--" : loadGameSelectionWindowsSuccess?.find(w => w.gameSelectionWindowId == record.gameSelectionWindowId).locationNames.join(' / ')}
                    </div>
                  }
                  {IsInEditMode(record.gameSelectionWindowId) &&
                    <Select 
                    mode="multiple" 
                    style={{ width: '500px'}} 
                    placeholder='select location(s)' 
                    size="small"
                    showSearch
                    allowClear
                    disabled = {!IsInEditMode(record.gameSelectionWindowId) || 
                      editGameSelectionWindows.find(w => w.gameSelectionWindowId == record.gameSelectionWindowId).allLocations == true}
                    filterOption={(input, option) => (option.props.label ?? '').toString().toLowerCase().includes(input.toLowerCase())}
                    defaultValue={loadGameSelectionWindowsSuccess?.find(w => w.gameSelectionWindowId == record.gameSelectionWindowId).allLocations == true ?
                    [] : loadGameSelectionWindowsSuccess?.find(w => w.gameSelectionWindowId == record.gameSelectionWindowId).locationNames}
                    onChange={(e) => {
                      editWindowChangeLocations(e, record.locationIds, record.gameSelectionWindowId)
                    }}
                    >
                      {children}
                    </Select>
                  }
              </>
            )
          }
        },            
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <>
                    {
                        !IsInEditMode(record.gameSelectionWindowId) &&
                        <> 
                            <Button 
                            disabled= {!hasGames(record.gameSelectionWindowId)}
                            onClick={() => {
                                let newState = editGameSelectionWindows
                                newState.push({
                                  windowStartDate: record.windowStartDate,
                                  gameSelectionWindowId: record.gameSelectionWindowId,
                                  allLocations: record.allLocations,
                                  userId: record.userId,
                                  createdDate: record.createdDate,
                                  updatedDate: record.updatedDate,
                                  deletedDate: record.deletedDate,
                                  locationIds: record.locationIds,
                                  locationNames: record.locationNames,
                                  isLatestAllLocationWindow: record.isLatestAllLocationWindow,
                                  hasLocationSetups: record.hasLocationSetups,
                                  locations: record.locations,
                                  windowGames: record.windowGames,
                                  selectedAndAvailableGames: record.selectedAndAvailableGames,
                                  window: record.window,
                              })
                              setEditGameSelectionWindows([...editGameSelectionWindows, newState.find(w => w.gameSelectionWindowId == record.gameSelectionWindowId)])
                              selectGames(record.gameSelectionWindowId, true);
                            }}
                            > View Games</Button>         
                            <Button onClick={() => {                              
                                let newState = editGameSelectionWindows
                                newState.push({
                                  windowStartDate: record.windowStartDate,
                                  gameSelectionWindowId: record.gameSelectionWindowId,
                                  allLocations: record.allLocations,
                                  userId: record.userId,
                                  createdDate: record.createdDate,
                                  updatedDate: record.updatedDate,
                                  deletedDate: record.deletedDate,
                                  locationIds: record.locationIds,
                                  locationNames: record.locationNames,
                                  isLatestAllLocationWindow: record.isLatestAllLocationWindow,
                                  hasLocationSetups: record.hasLocationSetups,
                                  locations: record.locations,
                                  windowGames: record.windowGames,
                                  selectedAndAvailableGames: record.selectedAndAvailableGames,
                                  window: record.window,
                              })
                              setEditGameSelectionWindows([...editGameSelectionWindows, newState.find(w => w.gameSelectionWindowId == record.gameSelectionWindowId)])                              
                            }}
                            disabled={record.hasLocationSetups}
                            style={{ marginLeft:  '24px' }}
                            > Edit </Button>
                            <Button onClick={() => 
                            { 
                              confirm({
                                title: 'Confirm Delete',
                                content: 'Are you sure you want to delete this game selection window?',
                                okText: 'Yes',
                                okType: 'danger',
                                cancelText: 'Cancel',
                                onOk: () => {
                                  props.actions.deleteGameSelectionWindows(record.gameSelectionWindowId)
                                }
                              });                               
                            }}
                                disabled={record.hasLocationSetups}
                                style={{ marginLeft:  '24px' }}>
                                Delete
                            </Button>
                        </>
                    }
                    {
                        IsInEditMode(record.gameSelectionWindowId) &&
                        <>
                            <Button onClick={() => {                              
                                let newState = editGameSelectionWindows
                                newState.push({
                                  windowStartDate: record.windowStartDate,
                                  gameSelectionWindowId: record.gameSelectionWindowId,
                                  allLocations: record.allLocations,
                                  userId: record.userId,
                                  createdDate: record.createdDate,
                                  updatedDate: record.updatedDate,
                                  deletedDate: record.deletedDate,
                                  locationIds: record.locationIds,
                                  locationNames: record.locationNames,
                                  isLatestAllLocationWindow: record.isLatestAllLocationWindow,
                                  hasLocationSetups: record.hasLocationSetups,
                                  locations: record.locations,
                                  windowGames: record.windowGames,
                                  selectedAndAvailableGames: record.selectedAndAvailableGames,
                                  window: record.window,
                              })
                                setEditGameSelectionWindows([...editGameSelectionWindows, newState.find(w => w.gameSelectionWindowId == record.gameSelectionWindowId)])
                                selectGames(record.gameSelectionWindowId, false)
                            }}> Next </Button>
                            <Button onClick={() => { 
                              const cancelItem = editGameSelectionWindows.filter(p => p.gameSelectionWindowId !== record.gameSelectionWindowId)
                              setEditGameSelectionWindows(cancelItem)
                            }}
                                style={{ marginLeft: '24px' }}> 
                                Cancel
                                </Button>                            
                        </>
                    }
                </>
            ),
        },
    ];

    return (
        <div className="pg-container page-container">
            <Card className="no-header-border" bordered={false} 
                title={'Game Selection Windows'}
            >         
                <Card bordered={true} title="Add New">                
                      <Row>
                          <Col span={24}>
                              <Col span={5}>                                    
                                  <DatePicker 
                                  value={newGameSelectionWindow.windowStartDate == null ? null : moment(newGameSelectionWindow.windowStartDate)}
                                  onChange={(date) => {
                                    setNewGameSelectionWindow({...newGameSelectionWindow, windowStartDate: moment.utc(date.toDate().setHours(0,0,0,0)).toDate()})
                                  }}
                                    placeholder={'Select Start Date'}
                                    format={dateFormat}
                                    disabled={props.isLoadingGameSelectionWindows}
                                  />
                              </Col>
                              <Col span={1}>
                                  <Checkbox
                                    onChange={(e) => {
                                      setNewGameSelectionWindow({...newGameSelectionWindow, allLocations: e.target.checked})
                                    }}
                                    checked={newGameSelectionWindow.allLocations}
                                    disabled={newGameSelectionWindow.windowStartDate == null || props.isLoadingGameSelectionWindows}
                                  >
                                  </Checkbox>
                              </Col>
                              <Col span={12}>
                                  <Select
                                  mode="multiple"
                                  style={{ width: '500px'}} 
                                  placeholder='select location(s)' 
                                  size="small"
                                  showSearch
                                  disabled = {newGameSelectionWindow.allLocations || props.isLoadingGameSelectionWindows || newGameSelectionWindow.windowStartDate == null}
                                  allowClear
                                  value={newGameSelectionWindow.locations?.map(location => {
                                    return location.locationId
                                  })}
                                  filterOption={(input, option) => (option.props.label ?? '').toString().toLowerCase().includes(input.toLowerCase())} 
                                  onChange={(value) => {
                                    addWindowChangeLocations(value)
                                    setNewGameSelectionWindow({...newGameSelectionWindow, locations: newGameSelectionWindow.locations})
                                  }}               
                                  >
                                    {children}
                                  </Select>
                              </Col>
                              <Col span={3}>
                                  <Button
                                      disabled={!isValidDates(newGameSelectionWindow.windowStartDate) ||
                                        !isValidLocations(newGameSelectionWindow.allLocations, newGameSelectionWindow.locations)}
                                      onClick={() => {
                                        selectGames(null, false)
                                      }}
                                      style={{ paddingLeft: '16px' }}
                                  > Next </Button>
                              </Col>
                              <Col span={3}>
                              </Col>
                          </Col>
                      </Row>
                </Card>
                <Table
                    rowKey={(record, index) => { return (record.gameSelectionWindowId).toString() }}
                    loading={props.isLoadingGameSelectionWindows}
                    dataSource={props.loadGameSelectionWindowsSuccess}
                    columns={tableColumns}                        
                />
            </Card>
        </div>
    )    
}
export default GameSelectionWindowPage
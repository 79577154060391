import { Card, Popconfirm, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useCallback, useState } from 'react';
import { createColumn, CreateColumnProps, DataOrSummaryRow } from '../../../common/helpers/tableWithSummaryHelpers';
import { useAppDispatch } from '../../../common/hooks/storeHooks';
import AppUtilityService from '../../../common/services/AppUtilityService';
import WebAccessService from '../../../common/services/WebAccessService';
import { deleteInvoiceCustomItem } from '../../actions/billing/deleteInvoiceCustomItem';
import { IInvoice, IInvoiceItem, InvoiceStatus } from '../../reducers/billing/billing.models';
import EditCustomInvoiceModal from './EditCustomInvoiceModal';

const { formatDate, formatAmount } = AppUtilityService;

interface InvoiceItemsProps {
  invoice: IInvoice;
}

export function InvoiceItems({ invoice }: InvoiceItemsProps) {

  const dispatch = useAppDispatch();

  const deleteCustomItem = useCallback((item: IInvoiceItem) => {
    dispatch(deleteInvoiceCustomItem(item));
  }, [dispatch]);

  const canEdit = WebAccessService.hasPermissionToAccess("Invoices", "Modify")
    && invoice.invoiceStatusId === InvoiceStatus.PendingReview;

  const [editedCustomItemId, setCustomItemId] = useState<number | 'new' | null>(null);

  const editColum = createColumn('', undefined, (x: IInvoiceItem) => canEdit && x.isCustom && (<>
    <span className="link-primary" onClick={() => setCustomItemId(x.invoiceItemId)}>
      <i className="icon-edit" />
    </span>
    <Popconfirm
      title={<>Delete item "<b>{formatAmount(x.itemDate)}"</b></>}
      okText="Delete"
      okType="danger"
      cancelText="Cancel"
      onConfirm={() => deleteCustomItem(x)}
    >
      <span className="link-primary ml-8"><i className="icon-remove" /></span>
    </Popconfirm>
  </>), { align: 'right' })

  const extraActions = canEdit && (
    <span className="link-primary" onClick={() => setCustomItemId('new')} > <i className="icon-add" /></ span>
  );

  const otherSystemColumns: ColumnProps<DataOrSummaryRow<IInvoiceItem>>[] = [
    createColumn('Date', 'itemDate', x => formatCustomItemDate(x), { align: 'right', summaryRender: x => 'TOTALS' }),
    createCurrencyColumn('Cash In', 'cashIn'),
    createCurrencyColumn('Cash Out', 'cashOut'),
    createCurrencyColumn('Cash Net', 'cashNet'),
    createCurrencyColumn(`Amount Due (${invoice.amountDuePercentage}%)`, 'amountDue'),
    editColum
  ];

  const ohioKentuckySystemColumns: ColumnProps<DataOrSummaryRow<IInvoiceItem>>[] = [
    createColumn('Date', 'itemDate', x => formatCustomItemDate(x), { align: 'right', summaryRender: x => 'TOTALS' }),
    createCurrencyColumn('Cash In', 'cashIn'),
    createCurrencyColumn('Cash Out', 'cashOut'),
    createCurrencyColumn('Cash Net', 'cashNet'),
    createCurrencyColumn('Tickets Played', 'ticketsPlayed'),
    createColumn('Tickets Played (count)', 'ticketsPlayedCount', x => x.ticketsPlayedCount, { align: 'right', summaryRender: x => x.ticketsPlayedCount }),
    createCurrencyColumn('Prizes Won', 'prizesWon'),
    editColum
  ];

  const invoicingSystemColumnSet = (invoice.invoicingSystemId === 2 || invoice.invoicingSystemId === 4) ? ohioKentuckySystemColumns : otherSystemColumns

  const dataSource: DataOrSummaryRow<IInvoiceItem>[] = [
    ...invoice.items.map(item => { return { ...item, isCustom: false } }),
    ...invoice.customItems?.map(customItem => {
      return {
        invoiceItemId: customItem.invoiceCustomItemId,
        invoiceId: customItem.invoiceId,
        invoicingSystemId: invoice.invoicingSystemId,
        itemDate: customItem.name,
        modified: customItem.modified,
        cashNet: customItem.value,
        amountDue: customItem.amountDue,
        created: customItem.created,
        createdByUserId: customItem.createdByUserId,
        isCustom: true,
        cashIn: null,
        cashOut: null,
        modifiedByUserId: null,
        ticketsPlayed: null,
        ticketsPlayedCount: null,
        prizesWon: null,
      }
    }),
    {
      isSummaryRow: true,
      cashIn: invoice.itemsTotalCashIn,
      cashOut: invoice.itemsTotalCashOut,
      cashNet: invoice.itemsTotalCashNet,
      amountDue: invoice.itemsTotalAmountDue,
      ticketsPlayed: invoice.itemsTotalTicketsPlayed,
      ticketsPlayedCount: invoice.itemsTotalTicketsPlayedCount,
      prizesWon: invoice.itemsTotalPrizesWon
    },
  ];

  const formatCustomItemDate = (item: IInvoiceItem) => {
    return item.isCustom ? item.itemDate : formatDate(item.itemDate, 'full');
  }

  const editedCustomItem = editedCustomItemId === 'new'
    ? null
    : (invoice.customItems.find(x => x.invoiceCustomItemId === editedCustomItemId)) ?? null;

  return (
    <>
      <Card title="Items" className="content-card no-header-border no-card-padding" bordered={false} extra={extraActions}>
        <Table<DataOrSummaryRow<IInvoiceItem>>
          size="small"
          rowKey={x => x.invoiceItemId?.toString() ?? 'summary'}
          dataSource={dataSource}
          columns={invoicingSystemColumnSet}
          pagination={false}
        />
      </Card>
      {
        editedCustomItemId != null &&
        <EditCustomInvoiceModal
          invoiceId={invoice.invoiceId}
          invoiceCustomItem={editedCustomItem}
          onClose={() => setCustomItemId(null)}
        />
      }</>
  );
}

function createCurrencyColumn(title: string, key: keyof IInvoiceItem, otherProps?: CreateColumnProps<IInvoiceItem>) {
  const render = (record: DataOrSummaryRow<IInvoiceItem>) => {
    const value = record[key];
    return value == null ? '' : (
      <span className={Math.round(+value * 100) < 0 ? 'text-negative' : ''}>
        {formatAmount(value)}
      </span>
    );
  };

  return createColumn<IInvoiceItem>(title, key, render, { align: 'right', summaryRender: render, ...otherProps });
}

import * as React from "react";
import { isEqual } from "lodash";
import AppUtilityService from "../../services/AppUtilityService";
var Highcharts = require('highcharts');
const hcnd = require('highcharts/modules/no-data-to-display');
hcnd(Highcharts);

export class DualAxesChartComponent extends React.Component<IDualAxesChartComponentProps, {}> {
    constructor(props: IDualAxesChartComponentProps) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        if (this.props.chartData) {
            this.renderChart(this.props.chartData);
        }
    }

    componentWillReceiveProps(nextProps: IDualAxesChartComponentProps) {
        if (nextProps.chartData && !isEqual(this.props.chartData, nextProps.chartData)) {
            this.renderChart(nextProps.chartData);
        }
    }

    render() {
        return (
            <div id="chart_container"></div>
        );
    }

    renderChart = (chartData) => {
        var _self = this;
        var xAxis = [{
            categories: chartData.xAxis1 && chartData.xAxis1.categories,            
        }];
        if (chartData.xAxis2) {
            xAxis.push({
                categories: chartData.xAxis2 && chartData.xAxis2.categories,
                // opposite: true
            })
        }
        var chart = Highcharts.chart('chart_container', {
            chart: {
                type: 'line'
            },
            title: {
                text: ""
            },
            xAxis: xAxis,
            yAxis: [{
                title: {
                    text: chartData.yAxis1 && chartData.yAxis1.title,
                    style: {
                        color: Highcharts.getOptions().colors[0]
                    }
                },
                labels: {
                    style: {
                        color: Highcharts.getOptions().colors[0]
                    },
                    formatter: function () {
                        return _self.formatY(this.value, chartData.yAxis1.units);
                    },
                },
            }, {
                labels: {
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    },
                    formatter: function () {
                        return _self.formatY(this.value, chartData.yAxis2.units);
                    }
                },
                title: {
                    text: chartData.yAxis2 && chartData.yAxis2.title,
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    },
                },
                opposite: true
            }],
            tooltip: {
                useHTML: true,
                formatter: function () {
                    var text = '';
                    if (this.series.index == 0) {
                        text = '<div><p>' + this.series.name + "</p>" + this.x + ' : ' + _self.formatY(this.y, chartData.yAxis1.units) + '</div>';
                    } else {
                        text = '<div><p>' + this.series.name + "</p>" + this.x + ' : ' + _self.formatY(this.y, chartData.yAxis2.units) + '</div>';
                    }
                    return text;
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.08,
                    borderWidth: 0
                }
            },
            legend: {
                align: 'right',
                verticalAlign: 'top',
                layout: 'horizontal',
                symbolRadius: 0,
            },
            credits: {
                enabled: false
            },
            series: chartData && chartData.series,
        });
    }

    formatY = (value, units): string => {
        var val = "";
        switch (units) {
            case "currency":
                val = AppUtilityService.formatAmountAndShorten(value, 2);
                break;
            case "percent":
                val = AppUtilityService.shortenLargeNumber(value, 2) + "%";
                break;
            default:
                val = AppUtilityService.shortenLargeNumber(value, 2);
                break;
        }
        return val;
    }
}
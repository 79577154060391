import React from 'react';
import { TicketSalesByGameReportItem } from '../../reducers/reporting/reporting.models';
import { Col, Row, Tabs } from 'antd';
import TicketSalesBreakdownByGameChart from './TicketSalesBreakdownByGameChart';

export type Props = {
  data: TicketSalesByGameReportItem[];
};

const TicketSalesByGameCharts = ({ data }: Props) => (
  <Tabs className="card-tabs" defaultActiveKey="1">
    <Tabs.TabPane tab="Data graph" key="1">
      <div>
        <h2>Graphical representation of selected time period</h2>
        <Row gutter={24}>
          <Col xl={{ span: 8, offset: 4 }}>
            <TicketSalesBreakdownByGameChart
              data={data}
              chartId="breakdownByGameChart1"
              defaultView="grossRevenue" />
          </Col>
          <Col xl={{ span: 8, offset: 0 }}>
            <TicketSalesBreakdownByGameChart
              data={data}
              chartId="breakdownByGameChart2"
              defaultView="soldTicketCount" />
          </Col>
        </Row>
        <br/>
        <br/>
      </div>
    </Tabs.TabPane>
  </Tabs>
)

export default TicketSalesByGameCharts;

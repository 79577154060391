
import * as React from "react"
import { Row, Col, Collapse, Table, Typography, Spin, Checkbox, Modal, Carousel } from "antd"
import { IGame, ITab, IQuarterCloseSelectedBet, ITabDetail, IGameMedia } from "../../reducers/quarterClose/QuarterCloseReducer"
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent"
import { hashHistory } from "react-router";
import AppUtility from '../../../common/services/AppUtilityService'
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align"
import * as Constants from '../../../common/AppConstants'
import { IQuarterCloseLocationSetupDetails } from '../../reducers/reports/ReportsReducer';
const syles = require('../../../common/styles/GameSelectStyles.css')
const confirmButton = require("../../../images/Button_Confirm.png")
const confirmDisabledButton = require("../../../images/Button_Confirm_Disabled.png")
const leftButton = require("../../../images/Button_Arrow_Left.png")
const rightButton = require("../../../images/Button_Arrow_Right.png")
const gamesButton = require("../../../images/Button_Games.png")

const { Text } = Typography;
const { Panel } = Collapse;

const SERVER_PATH = (process.env.REACT_APP_API_URL || "").replace(/\/api\/?$/, '');

export interface IQuarterCloseGameDetailsPageProps {
    actions?: {
        loadFlares(gameID: number): void;
        updateSelectedSetup(selectedBets: IQuarterCloseSelectedBet[]): void;
        loadGameMedia(gameID: number): void;
    };
    selectedGame: IGame;
    selectedGameID: number;
    gameFlares: ITab[];
    loadingGameFlares: boolean;
    selectedSetup: IQuarterCloseSelectedBet[];
    payoutInMultiplesOf25: boolean;
    gameMedia: IGameMedia[];
    latestSetup?: IQuarterCloseLocationSetupDetails;
}

export interface IQuarterCloseGameDetailsPageState {
    selections: IQuarterCloseSelectedBet[];
    showMaxGameModal: boolean;
    showMediaModal: boolean;
    mediaModalUrl: string;
    mediaModalIsVideo: boolean;
}

export class QuarterCloseGameDetailsPage extends React.Component<IQuarterCloseGameDetailsPageProps, IQuarterCloseGameDetailsPageState> {
    private carouselRef;

    constructor(props: IQuarterCloseGameDetailsPageProps) {
        super(props);
        this.carouselRef = React.createRef();
        this.state = {
            selections: [],
            showMaxGameModal: false,
            showMediaModal: false,
            mediaModalUrl: '',
            mediaModalIsVideo: false,
        }
    }

    componentDidMount() {
        this.props.actions.loadFlares(this.props.selectedGameID)
        this.props.actions.loadGameMedia(this.props.selectedGameID)
        this.setState({ selections: this.props.selectedSetup })
        this.setState({ showMaxGameModal: this.props.selectedSetup.length >= Constants.default.ndMaxGameSelectionsNumber && this.props.selectedSetup.findIndex(p => p.gameID === this.props.selectedGameID) === -1 })
    }

    render() {

        const hasMaxGamesSelected = this.props.selectedSetup.length >= Constants.default.ndMaxGameSelectionsNumber
        const hasThisGameSelected = this.props.selectedSetup.findIndex(p => p.gameID === this.props.selectedGameID) > -1
        const maxGameSelectionsStringLower = Constants.default.ndMaxGameSelectionsString.toLowerCase()
        const maxGamesAreSelected = Constants.default.ndMaxGameSelectionsString + " Games Already Selected"

        const onSelectChange = (selectedRowKeys: string[], formType: string, payoutInMultiplesOf25: boolean, payout: number, jackpot: number) => {
            let gameID = this.props.selectedGame.gameID
            let newState = [...this.state.selections].filter(p => p.gameID !== gameID || (p.gameID === gameID && p.formType !== formType))
            const readonlyBets = this.props.latestSetup?.games.find(x => x.gameID === gameID && x.formType === formType)?.bets.map(x => x.toString()) ?? [];
            const bets = Array.from(new Set([...readonlyBets, ...selectedRowKeys]));
            newState.push({
                formType: formType,
                gameID: gameID,
                bets: bets,
                jackpot: jackpot,
                payout: payout,
                payoutOptions: `All Wins Multiple of ${payoutInMultiplesOf25 ? '$0.25' : '$0.05'}`,
            })

            this.setState({ selections: newState })
        };

        const flareColumns = [
            {
                title: 'Denomination',
                dataIndex: 'denomination',
                key: 'denomination',
                render: ((amount: number) => AppUtility.formatAmount(amount)),
            },
            {
                title: 'Ideal Gross Proceeds',
                dataIndex: 'idealGrossProceeds',
                key: 'idealGrossProceeds',
                render: ((amount: number) => AppUtility.formatAmount(amount)),
            },
            {
                title: 'Ideal Prizes',
                dataIndex: 'idealPrizes',
                key: 'idealPrizes',
                render: ((amount: number) => AppUtility.formatAmount(amount)),
            },
            {
                title: 'Ideal Net Proceeds',
                dataIndex: 'idealNetProceeds',
                key: 'idealNetProceeds',
                render: ((amount: number) => AppUtility.formatAmount(amount)),
            },

            {
                title: 'Payout Structure',
                dataIndex: 'payoutStructure',
                key: 'payoutStructure',
            },
        ]

        const onFormTypeChecked = (gameID: number, formType: string, payoutInMultiplesOf25: boolean, payout: number, jackpot: number) => {
            const flareBets = this.props.gameFlares.find(p => p.formType === formType && p.gameID === gameID).tabDetails.map(p => p.denomination)
            let betCount = 0;

            if (this.state.selections.findIndex(p => p.gameID === this.props.selectedGameID && p.formType === formType) > -1)
                betCount = this.state.selections.find(p => p.gameID === this.props.selectedGameID && p.formType === formType).bets.length

            //if they are all checked, uncheck it
            if (flareBets.length === betCount) {
                onSelectChange([], formType, payoutInMultiplesOf25, payout, jackpot)
            }
            else {
                //if they are not all checked, check them all
                onSelectChange(flareBets.map(p => p.toString()), formType, payoutInMultiplesOf25, payout, jackpot)
            }
        }

        const onImageClicked = (url: string, isVideo: boolean) => {
            this.setState({ showMediaModal: true, mediaModalUrl: url, mediaModalIsVideo: isVideo });
        }

        return (
            <div className="pg-container page-container gameSelectBackground">
                <div className="gameDetailsMainDiv gameDetailsText">
                    <Row>
                        <Col span={6}>
                            <Row>
                                <Col>
                                    <Carousel
                                        arrows
                                        dots={false}
                                        prevArrow={<img src={leftButton} />}
                                        nextArrow={<img src={rightButton} />}
                                        ref={this.carouselRef}
                                        afterChange={(currentSlide: number) => {
                                            if (currentSlide == 0)
                                                this.setState({ mediaModalUrl: this.props.selectedGame.iconUrl, mediaModalIsVideo: false });
                                            else
                                                this.setState({ mediaModalUrl: this.props.gameMedia[currentSlide - 1].url, mediaModalIsVideo: this.props.gameMedia[currentSlide - 1].isVideo });
                                        }}
                                    >                                        
                                        <div>
                                            <Row type="flex" justify="center" align="middle">
                                                <Col>
                                                    <img className="gameDetailsIcon" src={this.props.selectedGame.iconUrl} onClick={() => { onImageClicked(this.props.selectedGame.iconUrl, false) }} />
                                                </Col>
                                            </Row>
                                        </div>
                                        {
                                            this.props.gameMedia.map(gameMedia =>
                                            <div key={gameMedia.gameID}>
                                                <Row type="flex" justify="center" align="middle">
                                                    <Col>
                                                        {!gameMedia.isVideo &&
                                                            <img className="gameDetailsIcon" src={gameMedia.url} onClick={() => { onImageClicked(gameMedia.url, false) }} />
                                                        }
                                                        {gameMedia.isVideo &&
                                                            <video className="gameDetailsIcon" controls src={gameMedia.url} />
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>)
                                        }
                                    </Carousel>
                                    <Modal
                                        title=""
                                        visible={this.state.showMediaModal}
                                        footer={null}                                        
                                        width={918}
                                        onCancel={() => { this.setState({ showMediaModal: false }) }}
                                    >
                                        <section style={{ position: "relative" }}>
                                            <img src={leftButton} className={"modalCarouselLeft"} onClick={() => {
                                                this.carouselRef.current.prev()
                                            }} />
                                            <img src={rightButton} className={"modalCarouselRight"} onClick={() => {
                                                this.carouselRef.current.next()
                                            }} />
                                            <Row type="flex" justify="center" align="middle">                                            
                                                <Col>
                                                    {!this.state.mediaModalIsVideo &&
                                                        <img style={{ maxWidth: '870px' }} src={this.state.mediaModalUrl} />}
                                                    {this.state.mediaModalIsVideo && <video controls src={this.state.mediaModalUrl} />}
                                                </Col>                                            
                                            </Row>
                                        </section>
                                    </Modal>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: "25px", paddingBottom: "5px" }}>
                                <Col>
                                    <Text strong>Title:</Text> {this.props.selectedGame.displayName}
                                </Col>
                            </Row>
                            <Row className="pad5TopBottom">
                                <Col>
                                    <div style={{}}>Theme:</div> {this.props.selectedGame.theme}
                                </Col>
                            </Row>
                            <Row className="pad5TopBottom">
                                <Col>
                                    <Text strong>Extended Play Features:</Text>
                                    <div>{this.props.selectedGame.extendedPlayFeatures}</div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={17}>
                            <Row>
                                <Col className="gameDetailsHeader">{this.props.selectedGame.displayName}</Col>
                            </Row>
                            <Row>
                                <Col className="gameSelectOptionsText">Select the payout options from the available selections below by clicking the check box next to your preferred choice. Click "View Flare" to view more details.</Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.props.loadingGameFlares && <Spin size="large" />}
                                    {!this.props.loadingGameFlares &&
                                        <Collapse defaultActiveKey={this.props.selectedSetup.findIndex(p => p.gameID === this.props.selectedGameID) > -1 ?
                                            this.props.selectedSetup.find(p => p.gameID === this.props.selectedGameID).formType
                                            : ''}
                                            accordion expandIconPosition="right">
                                            {
                                                this.props.gameFlares.filter(a => a.gameID === this.props.selectedGameID).sort((a, b) => (a.payout < b.payout ? 1 : (a.payout === b.payout) ? (a.jackpot > b.jackpot ? 1 : -1) : -1))
                                                    .map(flare => {
                                                        const selection = this.state.selections.find(p => p.formType === flare.formType && p.gameID === this.props.selectedGameID && p.bets.length > 0);
                                                        const sameGameDifferentFormSelection = this.state.selections.find(p => p.formType !== flare.formType && p.gameID === this.props.selectedGameID && p.bets.length > 0);
                                                        return (!this.props.payoutInMultiplesOf25 || (this.props.payoutInMultiplesOf25 && flare.winsMultipleOf25) || selection != null) && (
                                                          <Panel className="collapsePanel" key={flare.formType} header={
                                                              <div className="gameDetailsPanelHeader">
                                                                  <table className="gameDetailsFormHeader">
                                                                      <thead>
                                                                          <tr>
                                                                              <th>
                                                                                  <Checkbox
                                                                                      onClick={() => { onFormTypeChecked(flare.gameID, flare.formType, flare.winsMultipleOf25, flare.payout, flare.jackpot) }}
                                                                                      checked={selection != null}
                                                                                      disabled={sameGameDifferentFormSelection != null} />
                                                                              </th>
                                                                              <th>{flare.formType}</th>
                                                                              <th>Payout: {flare.payout}% </th>
                                                                              <th>Jackpot: ${flare.jackpot}</th>
                                                                          <th>All Wins Multiple of {flare.winsMultipleOf25 ? '$0.25' : '$0.05'}</th>
                                                                          <th><a href={`${SERVER_PATH}/flare/${this.props.selectedGameID}/${flare.gameType}/${flare.formType}`} target="_blank">VIEW FLARE</a></th>
                                                                          </tr>
                                                                      </thead>
                                                                      <tbody>
                                                                      </tbody>
                                                                  </table>
                                                              </div>}
                                                          >
                                                              <Table
                                                                  rowSelection={{
                                                                      type: 'checkbox',
                                                                      onChange: (selectedRowKeys: string[]) => { onSelectChange(selectedRowKeys, flare.formType, flare.winsMultipleOf25, flare.payout, flare.jackpot) },
                                                                      selectedRowKeys: this.state.selections.find(p => p.formType === flare.formType && p.gameID === this.props.selectedGameID)
                                                                          ? this.state.selections.find(p => p.formType === flare.formType && p.gameID === this.props.selectedGameID).bets
                                                                          : [],
                                                                      getCheckboxProps: (record: ITabDetail) => {
                                                                          const hasOtherFormSelection = this.state.selections
                                                                            .find(p => p.formType !== flare.formType && p.gameID === this.props.selectedGameID && p.bets.length > 0)
                                                                            ?? false;
                                                                          const isPreselectedBet = this.props.latestSetup?.games
                                                                              .find(x => x.gameID === this.props.selectedGameID && x.formType === flare.formType)?.bets
                                                                              .some(x => x === record.denomination) ?? false;
                                                                          return {
                                                                              disabled: hasOtherFormSelection || isPreselectedBet,
                                                                          };
                                                                      },
                                                                  }}
                                                                  bordered
                                                                  rowKey={(record, index) => { return (record.denomination).toString() }}
                                                                  loading={this.props.loadingGameFlares}
                                                                  dataSource={flare.tabDetails}
                                                                  columns={flareColumns}
                                                                  pagination={false}
                                                                  locale={
                                                                      {
                                                                          emptyText: <EmptyStateComponent title="No Details" />
                                                                      }
                                                                  }
                                                              />
                                                          </Panel>
                                                          );
                                                    })
                                            }
                                        </Collapse>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "10px" }}>
                        <Col span={12}>
                            <img className="navImageButton" onClick={() => { hashHistory.push('/quarterClose/selectGames') }} src={gamesButton} alt="View Games" />
                        </Col>
                        <Col span={12}>
                            {(!hasMaxGamesSelected || hasThisGameSelected) &&
                                <img className="navImageButton floatRight" onClick={() => {
                                    this.props.actions.updateSelectedSetup(this.state.selections.filter(p => p.bets.length > 0))
                                    hashHistory.push('/quarterClose/selectGames')
                                }} src={confirmButton} alt="Confirm Selection" />
                            }
                            {
                                hasMaxGamesSelected && !hasThisGameSelected &&
                                <img className="navImageButton floatRight" onClick={() => { }} src={confirmDisabledButton} alt="Confirm Selection" />
                            }
                        </Col>
                    </Row>
                    <Modal
                        cancelButtonProps={{ style: { display: 'none' } }}
                        title= {maxGamesAreSelected}
                        visible={this.state.showMaxGameModal}
                        onOk={() => { this.setState({ showMaxGameModal: false }); { hashHistory.push('/quarterClose/selectGames') } }}
                        onCancel={() => { }}
                        okText=""
                        cancelText=""
                    >
                        <p>You already have {maxGameSelectionsStringLower} games selected.  Click Okay to continue and view details for this game.  Go back to Games menu to modify your selections for this quarter.</p>
                    </Modal>
                </div>
            </div>
        )
    }
}

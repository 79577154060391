import { createColumn, CreateColumnProps } from './tableWithSummaryHelpers';
import { Link } from 'react-router';

export default function createLinkColumn<TItem extends { [key in string]: any }>(
  title: string,
  key: Extract<keyof TItem, string>,
  getPath: (record: TItem) => string | null,
  otherProps?: CreateColumnProps<TItem>,
) {
  return createColumn<TItem>(title, key, record => {
    const path = getPath(record);
    return path
      ? <Link to={getPath(record)}>{record[key]}</Link>
      : record[key];
  }, otherProps);
}

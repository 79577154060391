import React from 'react';
import { FilterProps } from '../reports/locationStatisticsReports/LocationReportsFilterComponent';
import { Switch } from 'antd';

const switchFilterFactory = (label: string) =>
  ({ filterValue, onFilterValueChange }: FilterProps) => (
    <span>
      <span className="font-14 regular">{label} </span>
      <Switch
        size="small"
        checked={!!filterValue}
        onChange={onFilterValueChange}
      />
    </span>
  );

export default switchFilterFactory;

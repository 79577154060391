
import * as React from "react"
import { Row, Col } from "antd"
import { hashHistory } from "react-router";
import ReviewGameComponent from '../quarterClose/QuarterCloseReviewGameComponent'
import { IGame, IQuarterCloseSelectedBet } from '../../reducers/quarterClose/QuarterCloseReducer'
const locationButton = require("../../../images/Button_Locations.png")
const gamesButton = require("../../../images/Button_Games.png")

export interface IQuarterCloseConfirmationPageProps {
    actions?: {
        clearSelections(): void,
    },
    games: IGame[],
    selectedSetup: IQuarterCloseSelectedBet[],
    locationIds: number[],
}

export class QuarterCloseConfirmationPage extends React.Component<IQuarterCloseConfirmationPageProps> {

    constructor(props: IQuarterCloseConfirmationPageProps) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    
  }

    render() {

        const GetGame = (index: number): IGame | null => {
            const gameIds: number[] = Array.from(new Set(this.props.selectedSetup.map(p => p.gameID)));

            if (gameIds.length >= index + 1) {
                const game = this.props.games.find(p => p.gameID === gameIds[index])
                return game
            }
            return null
        }

        const GetSelectedSetup = (index: number): IQuarterCloseSelectedBet | null => {
            if (index <= this.props.selectedSetup.length - 1)
                return this.props.selectedSetup[index]

            return null
        }

    return (
        <div className="pg-container page-container gameSelectBackground">
            <Row>
                <Row type="flex" justify="center" align="middle">
                    <Col>
                        <span style={{ textAlign: 'center' }} className="gameSelectHeader">CONFIRMATION RECEIPT</span>
                    </Col>
                </Row>
            </Row>
            <Row type="flex" justify="center" align="middle" style={{ paddingBottom: "40px" }}>
                <Col>
                    <span className="gameSelectText"></span>
                </Col>
            </Row>
            <Row style={{ paddingBottom: "5px" }}>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={true} updateSelectedGameID={() => { }} editable={false} onRemoveGame={() => { }} game={GetGame(0)} quarterCloseSelectedBet={GetSelectedSetup(0)} />
                </Col>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={false} updateSelectedGameID={() => { }} editable={false} onRemoveGame={() => { }} game={GetGame(1)} quarterCloseSelectedBet={GetSelectedSetup(1)}/>
                </Col>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={true} updateSelectedGameID={() => { }} editable={false} onRemoveGame={() => { }} game={GetGame(2)} quarterCloseSelectedBet={GetSelectedSetup(2)}/>
                </Col>
            </Row>
            <Row style={{ paddingBottom: "5px" }}>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={true} updateSelectedGameID={() => { }} editable={false} onRemoveGame={() => { }} game={GetGame(3)} quarterCloseSelectedBet={GetSelectedSetup(3)}/>
                </Col>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={false} updateSelectedGameID={() => { }} editable={false} onRemoveGame={() => { }} game={GetGame(4)} quarterCloseSelectedBet={GetSelectedSetup(4)}/>
                </Col>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={false} updateSelectedGameID={() => { }} editable={false} onRemoveGame={() => { }} game={GetGame(5)} quarterCloseSelectedBet={GetSelectedSetup(5)}/>
                </Col>
            </Row>
            <Row style={{ paddingBottom: "5px" }}>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={true} updateSelectedGameID={() => { }} editable={false} onRemoveGame={() => { }} game={GetGame(6)} quarterCloseSelectedBet={GetSelectedSetup(6)}/>
                </Col>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={true} updateSelectedGameID={() => { }} editable={false} onRemoveGame={() => { }} game={GetGame(7)} quarterCloseSelectedBet={GetSelectedSetup(7)}/>
                </Col>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={false} updateSelectedGameID={() => { }} editable={false} onRemoveGame={() => { }} game={GetGame(8)} quarterCloseSelectedBet={GetSelectedSetup(8)}/>
                </Col>
            </Row>
            <Row style={{ paddingBottom: "5px" }}>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={true} updateSelectedGameID={() => { }} editable={false} onRemoveGame={() => { }} game={GetGame(9)} quarterCloseSelectedBet={GetSelectedSetup(9)}/>
                </Col>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={true} updateSelectedGameID={() => { }} editable={false} onRemoveGame={() => { }} game={GetGame(10)} quarterCloseSelectedBet={GetSelectedSetup(10)}/>
                </Col>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={false} updateSelectedGameID={() => { }} editable={false} onRemoveGame={() => { }} game={GetGame(11)} quarterCloseSelectedBet={GetSelectedSetup(11)}/>
                </Col>
            </Row>
            <Row style={{ paddingTop: "10px" }}>
                <Col>
                    <img className="navImageButton floatRight " onClick={() => {
                        this.props.actions.clearSelections()
                        hashHistory.push('/quarterClose/locations')
                    }} src={locationButton} alt="Locations" />
                </Col>
            </Row>
      </div>
    )
  }
}

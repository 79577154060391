import * as React from "react";
import { isEqual }from "lodash";
import { Card, Row, Col } from 'antd';
import appUtilityService from "../../services/AppUtilityService";

export class EmptyStateComponent extends React.Component<IEmptyStateComponentProps, IEmptyStateComponentState> {
    constructor(props: IEmptyStateComponentProps) {
        super(props);
        this.state = {
            title: this.props.title || "No Data"
        }
    }

    componentWillReceiveProps(nextProps: IEmptyStateComponentProps) {
        if (nextProps.title && !isEqual(this.props.title, nextProps.title)) {
            this.setState({ title: nextProps.title })
        }

    }

    render() {
        return (
            <div className="empty-state">
                {/* <i className="icon-no-data" /> */}
                {this.state.title}
            </div>
        );
    }

}
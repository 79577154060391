import { Card } from 'antd';
import WebAccessService from '../../../common/services/WebAccessService';
import { IInvoice } from '../../reducers/billing/billing.models';
import { useState } from 'react';
import EditInvoiceNotesModal from './EditInvoiceNotesModal';

interface IInvoiceNotesProps {
  invoice: IInvoice;
}

export function InvoiceNotes({ invoice }: IInvoiceNotesProps) {
  const [isEditNoteModalVisible, setIsEditNoteModalVisible] = useState(false);

  const extraActions = WebAccessService.hasPermissionToAccess("Invoices", "Modify") && (
    <span className="link-primary" onClick={() => setIsEditNoteModalVisible(true)}>
      <i className="icon-edit"/>
    </span>
  );

  return (
    <>
      {
        isEditNoteModalVisible
        &&
        <EditInvoiceNotesModal
          invoice={invoice}
          onClose={() => setIsEditNoteModalVisible(false)}
        />
      }
      <Card
        title="Notes"
        extra={extraActions}
        className="content-card no-header-border no-card-padding"
        bordered={false}
      >
        <div style={{ whiteSpace: 'pre-line' }}>
          {invoice.comment}
        </div>
      </Card>
    </>
  );
}

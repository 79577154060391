import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as UserActions from '../../actions/user/UserActions';
import { SaveUserModal } from '../../components/user/SaveUserModal';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    addNewUserSuccess: state.UserReducer.addNewUserSuccess,
    addNewUserError: state.UserReducer.addNewUserError,

    // updateUserDetailsSuccess: state.UserReducer.updateUserDetailsSuccess,
    // updateUserDetailsError: state.UserReducer.updateUserDetailsError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(UserActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveUserModal)

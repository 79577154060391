import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as UserRoleActions from '../../actions/userRole/UserRoleActions';
import { RoleDetailsDescriptionCardComponent } from '../../components/userRole/RoleDetailsDescriptionCardComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    updateSelectedUserRoleSuccess: state.UserRoleReducer.updateSelectedUserRoleSuccess,
    updateSelectedUserRoleError: state.UserRoleReducer.updateSelectedUserRoleError,

    deleteSelectedUserRoleSuccess: state.UserRoleReducer.deleteSelectedUserRoleSuccess,
    deleteSelectedUserRoleError: state.UserRoleReducer.deleteSelectedUserRoleError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(UserRoleActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleDetailsDescriptionCardComponent)

import * as React from "react";
import { isEqual } from "lodash";

import AppUtilityService from "../../../common/services/AppUtilityService";
import WebAccessService from "../../../common/services/WebAccessService";
import { Col, Layout, Row } from "antd";
import DistributorBasicDetailsContainer from "../../containers/distributors/DistributorBasicDetailsContainer";
import DistributorLinkedUsersContainer from "../../containers/distributors/DistributorLinkedUsersContainer";

export class DistributorDetailsPage extends React.Component<IDistributorDetailsPageProps, IDistributorDetailsPageState> {
  constructor(props: IDistributorDetailsPageProps) {
    super(props);

    this.state = {
      distributorId: parseInt(this.props.params.distributorId) || null,
      distributorDetail: null
    }
  }
  componentWillReceiveProps(nextProps: IDistributorDetailsPageProps) {
    if (nextProps.loadDistributorDetailsSuccess && !isEqual(this.props.loadDistributorDetailsSuccess, nextProps.loadDistributorDetailsSuccess)) {
      var responseData: any = nextProps.loadDistributorDetailsSuccess;
      this.setState({ distributorDetail: responseData }, () => {
        this.breadcrumbsBuild()
      })
    }

  }

  render() {
      return (
        <div className="pg-container no-padding-page-container">
          <Layout>
            <DistributorBasicDetailsContainer distributorId={this.state.distributorId} />
            <Row type="flex" align="top" gutter={24} className="distributor-details">
            {
              WebAccessService.hasPermissionToAccess("Distributor", "View") &&
              <Col xl={12} lg={12} md={12} sm={24} xs={24} >
                <DistributorLinkedUsersContainer distributorId={parseInt(this.props.params.distributorId)} distributorDetail={this.state.distributorDetail}/>
              </Col>
            }              
            </Row>
          </Layout>

        </div>
      )
    }
  
    breadcrumbsBuild = () => {
      let breadcrumbsList = [
        { name: "Distributors", link: "/distributors" },
        { name: this.state.distributorDetail.name, link: null }
      ]
      var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
      this.props.actions.updateBreadcrumbs(breadcrumbs);
    }
}

async function copyRichTextToClipboard(innerHtml: string) {
  function listener(e) {
    e.clipboardData.setData("text/html", innerHtml);
    e.clipboardData.setData("text/plain", innerHtml);
    e.preventDefault();
  }
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
}
export default copyRichTextToClipboard;
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ReportsActions from '../../actions/reports/ReportsActions';
import { LocationDetailsReportTabComponent } from '../../components/locations/LocationDetailsReportTabComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    locationDetailsDateFilter: state.LocationsReducer.locationDetailsDateFilter,

    loadReconSummaryListSuccess: state.ReportsReducer.loadReconSummaryListSuccess,
    loadReconSummaryListError: state.ReportsReducer.loadReconSummaryListError,

    loadReconSummaryInfoSuccess: state.ReportsReducer.loadReconSummaryInfoSuccess,
    loadReconSummaryInfoError: state.ReportsReducer.loadReconSummaryInfoError,

  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ReportsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationDetailsReportTabComponent)

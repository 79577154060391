import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as LocationsActions from '../../actions/locations/LocationsActions';
import { AddNewLocationModal } from '../../components/locations/AddNewLocationModal';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    addNewLocationSuccess: state.LocationsReducer.addNewLocationSuccess,
    addNewLocationError: state.LocationsReducer.addNewLocationError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(LocationsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewLocationModal)

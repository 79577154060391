import * as React from "react";
import { isEqual, startCase } from "lodash";
import { Card, Layout, Menu, Dropdown, Icon } from 'antd';
import { IReconSummaryIPRReportPageProps, IReconSummaryIPRReportPageState } from '../Reports';
import AppUtilityService from "../../../../common/services/AppUtilityService";
import LocationIPRReportsFilterContainer from "../../../containers/reports/locationStatisticsReports/LocationIPRReportsFilterContainer";
import ReconSummaryIPRReportContainer from "../../../containers/reports/locationStatisticsReports/ReconSummaryIPRReportContainer";
import WebAccessService from "../../../../common/services/WebAccessService";
import { hashHistory } from "react-router";

export class ReconSummaryIPRReportPage extends React.Component<IReconSummaryIPRReportPageProps, IReconSummaryIPRReportPageState> {

  private reconSummaryIPRReportRef: any;

  constructor(props: IReconSummaryIPRReportPageProps) {
    super(props);
    this.state = {
      reportType: this.props.params && this.props.params.type || null,
      locationName: null,
      isLoadingList: false,
      apiParams: null,
    }
  }

  componentDidMount() {
    this.breadcrumbsBuild();
    this.checkPermissions(this.state.reportType);
  }

  componentWillReceiveProps(nextProps: IReconSummaryIPRReportPageProps) {
    if (nextProps.loadReconSummaryIPRReportListSuccess && !isEqual(this.props.loadReconSummaryIPRReportListSuccess, nextProps.loadReconSummaryIPRReportListSuccess)) {
      this.setState({ isLoadingList: false });
    }

  };

  render() {

    let { locationName } = this.state;
    let cardTitle = "Recon Summary - " + startCase(this.state.reportType);

    const extraActions = <div>
    <Dropdown.Button type="primary" onClick={this.downloadData}
        //disabled={this.state.isLoadingList}
        overlay={
            <Menu onClick={this.downloadList}>
                <Menu.Item key="1">PDF</Menu.Item>
                <Menu.Item key="2">Excel</Menu.Item>
            </Menu>
        }>
        <Icon type="download" style={{ fontSize: "16px" }} /> Download as PDF
    </Dropdown.Button></div>;


    return (
              <>
                <Card style={{ paddingTop: "12px" }} className="reports-filter-card content-card no-header-border" bordered={false} title={cardTitle}
                  extra={WebAccessService.hasPermissionToAccess("Reports", "Download") ? extraActions : null}>
                  <LocationIPRReportsFilterContainer
                    getSelectedLocationNameCallback={this.handleSelectedLocation}
                    applyFiltersCallback={this.applyFilters}
                  />
                </Card>

                <div className="pg-reports-container">
                  <Layout>
                  <div className="container" style={{ background: '#fff' }}>
                            <ReconSummaryIPRReportContainer
                                apiParams={this.state.apiParams}
                                onRef={ref => (this.reconSummaryIPRReportRef = ref)}
                            />
                        </div>
                  </Layout>
                </div>
              </>

    )
  }

  breadcrumbsBuild = () => {
    let breadcrumbsList = [
      { name: "Reports", link: '/reports' },
      { name: "Recon Summary for Location Since Last IPR", link: null }
    ]
    var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList, null);
    this.props.actions.updateBreadcrumbs(breadcrumbs);
  }

  //-----------------------------------------------------------------------

  handleSelectedLocation = (e) => {
    this.setState({ locationName: e })
  }

  applyFilters = (apiParams) => {
    this.setState({
        apiParams,
        isLoadingList: true,
    })
  }

  checkPermissions = (reportType) => {
    let hasPermissions = false;
    switch (reportType) {
        case "ByDateSinceLastIPR":
            hasPermissions = WebAccessService.hasPermissionToAccess("Reports", "ByDateSinceLastIPR");
            break;
        default: break;
    }
    if (!hasPermissions) {
        hashHistory.push("/unauthorized-access");
    }
  }

  downloadData = (e) => {
    e.key = "1";
    this.downloadList(e)
  }

  downloadList = (e) => {
    var downloadType = "pdf";
    switch (e.key) {
        case "1":
            downloadType = "pdf";
            break;
        case "2":
            downloadType = "excel";
            break;
        default: break;
    }
    this.reconSummaryIPRReportRef.getDownloadData(downloadType);
}

}




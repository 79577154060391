import * as React from "react";
import { isEqual, isEmpty } from "lodash";
import AppUtilityService from "../../services/AppUtilityService";
var Highcharts = require('highcharts');
const hcnd = require('highcharts/modules/no-data-to-display');
hcnd(Highcharts);
export class DonutChartComponent extends React.Component<IDonutChartProps, {}> {

    private chart: any;

    constructor(props: any) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        if (this.props.chartData) {
            this.renderChart(this.props.chartData);
        }
        window.addEventListener('resize', this.handleResize);
    }

    componentWillReceiveProps(nextProps: IDonutChartProps) {
        if (nextProps.chartData && !isEqual(this.props.chartData, nextProps.chartData)) {
            this.renderChart(nextProps.chartData);
        }
    }

    render() {
        return (
            <div id={this.props.id}></div>
        );
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    renderChart = (chartData) => {
        var _self = this;
        var legend = this.buildLegend()
        this.chart = Highcharts.chart(this.props.id, {
            chart: {
                minHeight: this.props.height,
                type: 'pie',
                events: {
                    load: function () {
                        let r = this.renderer
                        let x = this.series[0].center[0] + this.plotLeft;
                        if (this.title) {
                            this.title.destroy();
                        }
                        var yTitle = this.series[0].center[1] + this.plotTop;
                        const yLineAdjustment = chartData.title && chartData.title.html.length > 75 ? -35 : 0
                        const xLineAdjustment = chartData.title && chartData.title.html.length > 75 ? 36 : 0
                        this.title = r.text(chartData.data && !isEmpty(chartData.data) && chartData.title ? chartData.title.html : "", 0, 0, true)
                            .css({
                                color: chartData.title.css && chartData.title.css.color || "",
                                textAlign: 'center',
                                width: 220,
                                fontSize: chartData.title.css && chartData.title.css.fontSize || "",
                                whiteSpace: 'normal',
                            }, { ...chartData.title.css }).hide().add();

                        var bbox = this.title.getBBox();
                        this.title.attr({
                            x: x - (bbox.width / 2) + xLineAdjustment,
                            y: yTitle + yLineAdjustment
                        }).show()

                        if (this.subTitle) {
                            this.subTitle.destroy();
                        }

                        var ySubTitle = this.series[0].center[1] + this.plotTop + 20;
                        this.subTitle = r.text(chartData.data && !isEmpty(chartData.data) && chartData.subTitle ? chartData.subTitle.html : "", 0, 0, true)
                            .css({
                                color: chartData.subTitle.css && chartData.subTitle.css.color || "",
                                textAlign: 'center',
                                width: 220,
                                fontSize: chartData.subTitle.css && chartData.subTitle.css.fontSize || "",
                                whiteSpace: 'normal',
                            }, { ...chartData.subTitle.css }).hide().add();

                        var bbox = this.subTitle.getBBox();
                        this.subTitle.attr({
                            x: x - (bbox.width / 2),
                            y: ySubTitle
                        }).show()
                    },
                }
            },
            title: {
                text: ''
            },
            plotOptions: {
                pie: {
                    size: "100%",
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: this.props.showLegend,
                    innerSize: '75%',
                    point: {
                        events: {
                            legendItemClick: function (e) {
                                e.preventDefault();
                            }
                        }
                    }
                }
            },
            legend: legend,
            // legend: {
            //     align: 'right',
            //     verticalAlign: 'middle',
            //     layout: 'horizontal',
            //     itemMarginTop: 2,
            //     itemMarginBottom: 2,
            //     itemWidth: 150,
            //     itemStyle: {
            //         fontSize: 12,
            //         font: 'Arial, Helvetica, sans-serif',
            //         color: '#333333',
            //         fontWeight: 'normal'
            //     }
            // },
            tooltip: {
                useHTML: true,
                formatter() {
                    return this.point.name + ' : ' + _self.formatY(this.y, chartData.units);
                }
            },
            series: [{
                data: chartData && chartData.data,
            }],
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        chart: {
                            minHeight: this.props.responsiveHeight,
                        },
                        title: {
                            align: 'center',
                            floating: false
                        },
                        legend: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            maxHeight: 100,
                        }

                    }

                }]
            },
            credits: {
                enabled: false
            },
            lang: {
                noData: 'No data'
            },
            noData: {
                style: {
                    fontWeight: 'regular'
                }
            },
        }, (chart) => {

        });

    }
    handleResize = () => {
        this.renderChart(this.props.chartData)
    };

    formatY = (value, units): string => {
        var val = "";
        switch (units) {
            case "currency":
                val = AppUtilityService.formatAmountAndShorten(value, 2)
                break;
            case "percent":
                val = AppUtilityService.shortenLargeNumber(value, 2) + "%"
                break;
            default:
                val = AppUtilityService.shortenLargeNumber(value, 2)
                break;
        }
        return val;
    }

    buildLegend = () => {
        var legend = {};
        if (this.props.isVerticalLegend) {
            legend = {
                align: 'right',
                layout: 'vertical',
                verticalAlign: 'middle',
                useHTML: true,
                labelFormatter: function () {
                    return '<div class="vertical-chart-legend"><span>' + this.name + '</span><span> ' + AppUtilityService.shortenLargeNumber(this.percentage, 2) + '%</span></div>';
                }
            }
        }
        return legend;
    }
}
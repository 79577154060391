import { downloadInvoiceAttachmentOperationUpdated } from './slice-actions';
import { DownloadedFile } from '../../../common/store/fileDownload';
import apiService from '../../../common/services/ApiService';
import { createStandardFetchedAction } from '../../../common/store/fetched.slices';

export const exportInvoicesToExcel = (invoiceIds: number[]) => createStandardFetchedAction(
  downloadInvoiceAttachmentOperationUpdated,
  async () => {
    const result = await apiService.downloadFile(`/invoices/export/excel`, null, { invoiceIds: invoiceIds });
    const fileName = result.headers['x-filename'];
    return {
      data: result.data,
      fileName,
    } as DownloadedFile;
  },
  false,
);
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as BreadcrumbActions from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import { DistributorDetailsPage } from '../../components/distributors/DistributorDetailsPage';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadDistributorDetailsSuccess: state.DistributorsReducer.loadDistributorDataSuccess,
    loadDistributorDetailsError: state.DistributorsReducer.loadDistributorDataError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(BreadcrumbActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DistributorDetailsPage)
import { createApiCaller } from '../../../common/services/ApiService';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { fetchDataWithActionCreator, LoadState } from '../../../common/store/fetched';
import { updateInvoiceEmailTemplateOperationUpdated } from './slice-actions';
import { processFailureReason } from '../../../common/store/failure';
import { message } from 'antd';
import { getInvoice } from './getInvoice';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { Buffer } from 'buffer';

export const updateInvoiceTemplate = (invoiceId: number, emailSubjectTemplate: string, emailBodyTemplate: string, newAttachments: UploadFile[], deletedAttachments: number[]) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {

    const attachments = [];

    for (let index = 0; index < newAttachments.length; index++) {
      const element = newAttachments[index];
      const rcFile = element as RcFile
      const content = await Promise.resolve(rcFile.arrayBuffer())
      attachments.push({
        content: Buffer.from(content).toString('base64'),
        fileName: element.name,
        contentType: 'application/octet-stream'
      });
    }

    await fetchDataWithActionCreator(
      updateInvoiceEmailTemplateOperationUpdated,
      dispatch,
      createApiCaller(api => api.updateData<void>(
        `/invoices/${invoiceId.toFixed(0)}/emailTemplate`,
        undefined,
        {
          emailBodyTemplate,
          emailSubjectTemplate,
          customAttachments: attachments,
          deletedAttachments
        })),
      processFailureReason,
      false,
    );

    const operation = getState().BillingReducer.updateInvoiceEmailTemplateOperation;
    if (operation.loadState === LoadState.Failed) {
      message.error(
        `There was a problem with saving the template.
         Please try again or refresh the page.`,
        5,
      );
    } else if (operation.loadState === LoadState.Success) {
      message.success(`Email template has been saved.`);
      await dispatch(getInvoice(invoiceId, true));
    }
  };
}
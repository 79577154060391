import * as React from "react";
import { isEqual } from "lodash";
import { Card, Col, Row, Icon, Spin, Avatar } from 'antd';
import AppUtilityService from "../../../common/services/AppUtilityService";

export class GamesMiniCardComponent extends React.Component<IDashboardMiniStatCardProps, IDashboardMiniStatCardState>{
  constructor(props: IDashboardMiniStatCardProps) {
    super(props);
    this.state = {
      gameStats: null,
      isLoading: this.props.isLoading
    }
  }

  componentWillReceiveProps(nextProps: IDashboardMiniStatCardProps) {
    if (nextProps.loadDashboardMiniStatsSuccess && !isEqual(this.props.loadDashboardMiniStatsSuccess, nextProps.loadDashboardMiniStatsSuccess)) {
      var responseData: any = nextProps.loadDashboardMiniStatsSuccess;
      this.setState({ gameStats: responseData })
    }

    if (nextProps.loadDashboardMiniStatsError && !isEqual(this.props.loadDashboardMiniStatsError, nextProps.loadDashboardMiniStatsError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.loadDashboardMiniStatsError);
    }

    if (!isEqual(this.props.isLoading, nextProps.isLoading)) {
      this.setState({ isLoading: nextProps.isLoading })
    }
  }

  render() {
    var cardTitle = <span>
      <Avatar size="small" className="mr-8 avatar-mini-icon" style={{ backgroundColor: '#8DC5F3' }}>
        <i className="icon-games" />
      </Avatar> Play
    </span>;
    return (
      <Card className="no-header-border" bordered={false} title={cardTitle}>
        <Spin spinning={this.state.isLoading}>
          <Row gutter={16} style={{ paddingLeft: '38px' }}>
            <Col sm={24} md={12} lg={24} xl={12} xxl={6}>
              <div className="t-grey-dark">Play Count</div>
              <div>
                <span className="font-22 bold">{this.state.gameStats && AppUtilityService.shortenLargeNumber(this.state.gameStats.playCount, 2)}</span>
              </div>
            </Col>
            <Col sm={24} md={12} lg={24} xl={12} xxl={6}>
              <div className="t-grey-dark">Gross Play</div>
              <div>
                <span className="t-grey-dark font-18 stat-unit-left">$</span>
                <span className="font-22 bold">{this.state.gameStats && AppUtilityService.shortenLargeNumber(this.state.gameStats.played, 2)}</span>
              </div>
            </Col>
            <Col sm={24} md={12} lg={24} xl={12} xxl={6}>
              <div className="t-grey-dark">Net Play</div>
              <div>
                <span className="t-grey-dark font-18 stat-unit-left">$</span>
                <span className="font-22 bold">{this.state.gameStats && AppUtilityService.shortenLargeNumber(this.state.gameStats.pwNet, 2)}</span>
              </div>
            </Col>
            <Col sm={24} md={12} lg={24} xl={12} xxl={6}>
              <div className="t-grey-dark">Payout</div>
              <div>
                <span className="font-22 bold">{this.state.gameStats && AppUtilityService.shortenLargeNumber(this.state.gameStats.pwRatio, 2)}</span>
                <span className="t-grey-dark font-18 stat-unit-right">%</span>
              </div>
            </Col>
            {/* <Col span={3}>

            </Col> */}
          </Row>
        </Spin>
      </Card>
    )
  }
}

import { createApiCaller } from '../../../common/services/ApiService';
import { fetchDataWithActionCreator } from '../../../common/store/fetched';
import { AppDispatch } from '../../../common/store/ConfigureStore';
import { processFailureReason } from '../../../common/store/failure';
import { TaxRate } from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';
import { saveTaxRateOperationUpdated, taxRatesUpdated } from './slice-actions';

export const saveTaxRate = (taxRate: TaxRate) =>
  async (dispatch: AppDispatch) =>
    await fetchDataWithActionCreator(
      saveTaxRateOperationUpdated,
      dispatch,
      createApiCaller(api => api.postData('/taxRates', taxRate)),
      processFailureReason,
      true,
    )

import React, { useEffect, useState } from 'react';
import useBreadcrumbs from '../../../common/hooks/useBreadcrumbs';
import { Button, Card, Icon, message } from 'antd';
import LocationsAutocompleteContainer from '../../../common/containers/dropdown/LocationsAutocompleteContainer';
import { zoneIds } from '../../../common/zones';
import MonthYearPicker, { useMonthYearPicker } from '../../../common/components/monthYearPicker/MonthYearPicker';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import {
  downloadNhLucky7MonthlyFinancialReport,
} from '../../actions/invoicingReports/downloadNhLucky7MonthlyFinancialReport';
import { LoadState } from '../../../common/store/fetched';
import moment from 'moment';

const NhLucky7MonthlyFinancialReportPage = () => {
  const [locationId, setLocationId] = useState<number | null>(null);
  const { year, month, setYear, setMonth, resetMonthYear } = useMonthYearPicker(
    () => moment().subtract(1, 'months').month() + 1,
    () => moment().subtract(1, 'months').year());

  const isDownloadingInProgress = useAppSelector(state =>
    state.InvoicingReportsReducer.downloadNhLucky7MonthlyFinancialReportOperation.loadState === LoadState.InProgress);
  const hasDownloadingFailed = useAppSelector(state =>
    state.InvoicingReportsReducer.downloadNhLucky7MonthlyFinancialReportOperation.loadState === LoadState.Failed);

  const dispatch = useAppDispatch();

  useBreadcrumbs([
    { name: 'Reports', link: '/Reports' },
    { name: 'NH Lucky 7 Monthly Financial Report', link: null },
  ]);

  useEffect(
    () => {
      if (hasDownloadingFailed) {
        message.error('Failed to download NH Lucky 7 Monthly Financial report');
      }
    },
    [hasDownloadingFailed]);

  const downloadExcel = () => {
    dispatch(downloadNhLucky7MonthlyFinancialReport(year, month, locationId));
  };

  const resetFilters = () => {
    setLocationId(null);
    resetMonthYear();
  };

  return (
    <div className="pg-container page-container">
      <Card
        className="no-header-border"
        bordered={false}
        title="NH Lucky 7 Monthly Financial Report">
        <LocationsAutocompleteContainer
          placeholder="Select location"
          value={locationId}
          customParams={{ zoneId: zoneIds.NH }}
          onChange={setLocationId}
          className="mr-8"
        />
        <MonthYearPicker
          month={month}
          setMonth={setMonth}
          setYear={setYear}
          year={year}
        />
        <Button
          onClick={resetFilters}
          type="default"
          className="mr-8"
        >
          ↻
        </Button>
        <Button
          type="primary"
          className="ml-8"
          onClick={downloadExcel}
          loading={isDownloadingInProgress}
          disabled={locationId == null}
        >
          <Icon type="download" style={{ fontSize: "16px" }}/> Download as Excel
        </Button>
      </Card>
    </div>
  );
}

export default NhLucky7MonthlyFinancialReportPage;

import React, { ReactNode } from 'react';
import webAccessService from '../../../../common/services/WebAccessService';
import { Avatar, Card, Col, Row } from 'antd';
import { Link } from 'react-router';
import { useAppSelector } from '../../../../common/hooks/storeHooks';
import { REPORTS_MAPPING } from '../ReportsMapping';

export interface Props {
  header: ReactNode;
  reportType: string;
  permissionsModuleName?: string;
}

const ReportsModule = (
  {
    header,
    reportType,
    permissionsModuleName,
  }: Props,
) => {
  const availableReports = useAppSelector(state =>
    (state.ReportsReducer.reportsListSuccess ?? [])
      .filter(r =>
        r.reportType === reportType
        &&
        (
          permissionsModuleName == null
          ||
          webAccessService.hasPermissionToAccess(permissionsModuleName, r.name)
        ),
      ))
      .map(r => ({
        name: r.name,
        displayName: r.displayName,
        description: r.description,
        path: REPORTS_MAPPING[r.name] ?? `/reports/ssrs/${r.name}`,
      }));
  if (availableReports.length === 0) return null;
  return (
    <Card id="invoicingCard" className="no-header-border, hidden" bordered={false} title={
      <span>
        <Avatar size="small" className="mr-8 avatar-mini-icon" style={{ backgroundColor: '#8DC5F3' }}>
          <i className="icon-location"/>
        </Avatar> {header}
      </span>
    }>
      <div style={{ padding: "0 38px" }}>
        <Row gutter={16}>
          {
            availableReports.map(r => (
              <Col span={12} key={r.name}>
                <div className="font-16 bold">
                  <Link className="primary" to={r.path}>{r.displayName}</Link>
                </div>
                {
                  r.description
                  &&
                  <p className="font-14">{r.description}</p>
                }
              </Col>
            ))
          }
        </Row>
      </div>
    </Card>
  );
}

export default ReportsModule;

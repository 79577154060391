import ApiService from "../../../common/services/ApiService";

export function updateUserListFilters(params) {
    return {
        type: 'USER_LIST_FILTER_UPDATE',
        payload: params
    }
}

/**
 * Load users list
 */
export function loadUsersList(params) {
    return function (dispatch) {
        dispatch({ type: `LOAD_USERS_LIST_REQUEST` });
        return ApiService.getData("/admin/users", params)
            .then((successResult) => {
                dispatch({ type: `LOAD_USERS_LIST_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `LOAD_USERS_LIST_FAILURE`, payload: errorResult });
            });
    }
}

/**
 * view user
 */
export function getUserDetails(userId) {
    return function (dispatch) {
        dispatch({ type: `GET_USER_DETAILS_REQUEST` });
        return ApiService.getData('/admin/users/' + userId)
            .then((successResult) => {
                dispatch({ type: `GET_USER_DETAILS_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `GET_USER_DETAILS_FAILURE`, payload: errorResult });
            });
    }
}

/**
 * add new user
 */
export function addNewUser(formData) {
    return function (dispatch) {
        dispatch({ type: `ADD_NEW_USER_REQUEST` });
        return ApiService.postData("/admin/users", formData)
            .then((successResult) => {
                dispatch({ type: `ADD_NEW_USER_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `ADD_NEW_USER_FAILURE`, payload: errorResult });
            });
    }
}
// ================================================================================

export function updateUserDetails(userId, formData) {
    return function (dispatch) {
        dispatch({ type: `UPDATE_USER_DETAILS_REQUEST` });
        return ApiService.updateData("/admin/users/" + userId, null, formData)
            .then((successResult) => {
                dispatch({ type: `UPDATE_USER_DETAILS_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `UPDATE_USER_DETAILS_FAILURE`, payload: errorResult });
            });
    }
}
// ================================================================================

export function deleteUser(userId) {
    return function (dispatch) {
        dispatch({ type: `DELETE_USER_REQUEST` });
        return ApiService.deleteData('/admin/users/' + userId, null, null)
            .then((successResult) => {
                dispatch({ type: `DELETE_USER_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `DELETE_USER_FAILURE`, payload: errorResult });
            });
    }
}
// ================================================================================

export function enableUser(userId) {
    return function (dispatch) {
        dispatch({ type: `ENABLE_USER_REQUEST` });
        return ApiService.updateData('/admin/users/' + userId + '/enable', null, null)
            .then((successResult) => {
                dispatch({ type: `ENABLE_USER_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `ENABLE_USER_FAILURE`, payload: errorResult });
            });
    }
}
// ================================================================================

export function uploadUserImage(userId, files) {
    return function (dispatch) {
        dispatch({ type: `UPLOAD_USER_IMAGE_REQUEST` });
        return ApiService.postData("/admin/users/" + userId + "/image", files, true)
            .then((successResult) => {
                dispatch({ type: `UPLOAD_USER_IMAGE_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `UPLOAD_USER_IMAGE_FAILURE`, payload: errorResult });
            });
    }
}
// ================================================================================

export function removeUserImage(userId, pictureId) {
    return function (dispatch) {
        dispatch({ type: `REMOVE_USER_IMAGE_REQUEST` });
        return ApiService.deleteData('/admin/users/' + userId + "/" + pictureId, null, null)
            .then((successResult) => {
                dispatch({ type: `REMOVE_USER_IMAGE_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `REMOVE_USER_IMAGE_FAILURE`, payload: errorResult });
            });
    }
}
// ================================================================================
/**
 * User linked charity related
 */
export function assignCharityToUsers(userId, charityId) {
    return function (dispatch) {
        dispatch({ type: `ASSIGN_CHARITY_TO_USERS_REQUEST` });
        return ApiService.updateData('/admin/users/' + userId + '/charities/' + charityId, null, null)
            .then((successResult) => {
                dispatch({ type: `ASSIGN_CHARITY_TO_USERS_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `ASSIGN_CHARITY_TO_USERS_FAILURE`, payload: errorResult });
            });
    }
}

export function unassignCharityFromUsers(userId, charityId) {
    return function (dispatch) {
        dispatch({ type: `UNASSIGN_CHARITY_FROM_USERS_REQUEST` });
        return ApiService.deleteData('/admin/users/' + userId + '/charities/' + charityId, null, null)
            .then((successResult) => {
                dispatch({ type: `UNASSIGN_CHARITY_FROM_USERS_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `UNASSIGN_CHARITY_FROM_USERS_FAILURE`, payload: errorResult });
            });
    }
}
/**
 * User linked location related
 */

export function assignLocationToUsers(userId, locationId) {
    return function (dispatch) {
        dispatch({ type: `ASSIGN_LOCATION_TO_USERS_REQUEST` });
        return ApiService.updateData('/admin/users/' + userId + '/locations/' + locationId, null, null)
            .then((successResult) => {
                dispatch({ type: `ASSIGN_LOCATION_TO_USERS_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `ASSIGN_LOCATION_TO_USERS_FAILURE`, payload: errorResult });
            });
    }
}

export function unassignLocationFromUsers(userId, locationId) {
    return function (dispatch) {
        dispatch({ type: `UNASSIGN_LOCATION_FROM_USERS_REQUEST` });
        return ApiService.deleteData('/admin/users/' + userId + '/locations/' + locationId, null, null)
            .then((successResult) => {
                dispatch({ type: `UNASSIGN_LOCATION_FROM_USERS_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `UNASSIGN_LOCATION_FROM_USERS_FAILURE`, payload: errorResult });
            });
    }
}
/**
 * User linked zones related
 */
export function assignZoneToUsers(userId, zoneId) {
    return function (dispatch) {
        dispatch({ type: `ASSIGN_ZONE_TO_USERS_REQUEST` });
        return ApiService.updateData('/admin/users/' + userId + '/zones/' + zoneId, null, null)
            .then((successResult) => {
                dispatch({ type: `ASSIGN_ZONE_TO_USERS_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `ASSIGN_ZONE_TO_USERS_FAILURE`, payload: errorResult });
            });
    }
}

export function unassignZoneFromUsers(userId, zoneId) {
    return function (dispatch) {
        dispatch({ type: `UNASSIGN_ZONE_FROM_USERS_REQUEST` });
        return ApiService.deleteData('/admin/users/' + userId + '/zones/' + zoneId, null, null)
            .then((successResult) => {
                dispatch({ type: `UNASSIGN_ZONE_FROM_USERS_SUCCESS`, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: `UNASSIGN_ZONE_FROM_USERS_FAILURE`, payload: errorResult });
            });
    }
}
// ================================================================================

import React from 'react';
import {
  LocationCharity,
  LocationInvoicingConfigItem,
} from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';
import { Typography } from 'antd';
import { Link } from 'react-router';

export type Props = {
  item: LocationInvoicingConfigItem;
  locationCharities?: LocationCharity[] | null;
};

const LocationCharityHeader = ({ item, locationCharities }: Props) => {
  if (item.localCharityId == null)
    return (
      <Typography.Title level={4}>
          <Link to={'/locations/' + item.locationId} style={{ paddingTop: '3px' }}>{item.locationName}</Link> - No Charity  
      </Typography.Title>
    );
  const charity = locationCharities?.find(c => c.localCharityId === item.localCharityId);
  if (charity?.name)
    return (
      <Typography.Title level={4}>
          <Link to={'/charities/' + charity.id} style={{ paddingTop: '3px' }}>{charity.name}</Link>
      </Typography.Title>
    );
  return (
    <Typography.Title level={4} disabled>
      No Liked Charity with Local Charity ID {item.localCharityId}
    </Typography.Title>
  );
}

export default LocationCharityHeader;

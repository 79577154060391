import { Button, Collapse, List, Modal, Select, Spin, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/storeHooks";
import { regenerateInvoices } from "../../actions/billing/regenerateInvoices";
import { invoicingSystems } from "../../../common/models/InvoicingSystems";
import { LoadState } from '../../../common/store/fetched';
import { CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons';
import { IInvoiceGenerationResult } from '../../reducers/billing/billing.models';
import appUtilityService from '../../../common/services/AppUtilityService';

const Option = Select.Option;
const { Text } = Typography;
const { Panel } = Collapse;

interface InvoiceGenerationModalProps {
  isVisible: boolean;
  onCancel(): void;
}

const InvoiceGenerationModal = (props: InvoiceGenerationModalProps) => {

  const [systemId, setSystemId] = useState<number>(1);
  const dispatch = useAppDispatch();
  const generateInvoicesOperation = useAppSelector(state => state.BillingReducer.generateInvoicesOperation);
  const [generationRequested, setGenerationRequested] = useState<boolean>(false);

  const isGenerationInProgress = generationRequested && generateInvoicesOperation.loadState === LoadState.InProgress;
  const invoices = generateInvoicesOperation?.data;

  const onCancel = useCallback(() => {
    setGenerationRequested(false);
    props.onCancel();
  }, [props]);

  useEffect(() => {
    if (generationRequested
      && generateInvoicesOperation.loadState === LoadState.Success
      && invoices?.invoices?.every(p => p.errorMessage == null)) {
      onCancel();
    }
  }, [generateInvoicesOperation, generationRequested, invoices, onCancel]);

  const onGenerate = () => {
    dispatch(regenerateInvoices(systemId));
    setGenerationRequested(true);
  };

  const onChange = (key) => {
    setSystemId(key);
  };

  const getArrowIcon = (isActive: boolean) => isActive ? <CaretDownOutlined /> : <CaretRightOutlined />;
  const getItemDescription = (item: IInvoiceGenerationResult) => {
    let text = 'Invoice';
    if (item.invoiceId != null) {
      text = `${text} ${item.invoiceId}`;
    }
    if (item.periodStart != null) {
      text = `${text} from ${appUtilityService.formatDate(item.periodStart)} to ${appUtilityService.formatDate(item.periodEnd)}`;
    }
    if (item.locationName != null) {
      text = `${text} for ${item.locationName}`;
    } else if (item.locationId != null) {
      text = `${text} for location ID ${item.locationId}`;
    }
    return <>
      <Text strong>{text}</Text>
      {item.errorMessage == null
        ? <span className="text-positive ml-8">Success</span>
        : <span className="text-negative ml-8">Error</span>}
    </>;
  };

  const renderForm = () => {
    if (!generationRequested) {
      return (
        <>
          Jurisdiction
          <Select
            defaultValue="New Hampshire"
            style={{ width: 200, marginLeft: '16px' }}
            onChange={onChange}
          >
            {invoicingSystems.map(p => { return <Option key={p.id} >{p.name}</Option> })}
          </Select>
        </>
      );
    }
  };

  const renderResults = () => {
    if (generationRequested && invoices?.invoices?.length > 0) {
      const success = invoices?.invoices?.filter(i => i.errorMessage == null)?.length;
      const failure = invoices?.invoices?.filter(i => i.errorMessage != null)?.length;
      return (<>
        <div className="text-center w-100 pb-8">
          <Text strong>Invoices generated:
            {" "}<span className={success > 0 ? "text-positive" : "text-negative"}>{success}</span>
            {" "}Invoices failed:
            {" "}<span className={failure > 0 ? "text-negative" : "text-positive"}>{failure}</span>
          </Text>
        </div>
        {renderError()}

        <div style={{ maxHeight: '400px', overflow: 'auto' }}>
          {failure > 0 && <>
            <div className="mt-16"><Text strong>Failed invoices:</Text></div>
            <List
              itemLayout="vertical"
              className="generate-invoices-results pt-8"
              dataSource={invoices.invoices}
              renderItem={(item, index) => (
                <>
                  {item.errorMessage != null && (
                    <Collapse
                      bordered={false}
                      expandIconPosition="right"
                      expandIcon={({ isActive }) => getArrowIcon(isActive)}
                    >
                      <Panel header={getItemDescription(item)} key={index} className="item-content">
                        <span className="text-negative">{item.errorMessage}</span>
                      </Panel>
                    </Collapse>
                  )}
                </>
              )}
            />
          </>}
          {success > 0 && <>
            <div className="mt-16"><Text strong>Generated invoices:</Text></div>
            <List
              itemLayout="vertical"
              className="generate-invoices-results pt-8"
              dataSource={invoices.invoices}
              renderItem={(item, index) => (
                <>
                  {item.errorMessage == null && (
                    <Panel header={getItemDescription(item)} key={index}>
                    </Panel>
                  )}
                </>
              )}
            />
          </>}
        </div>
      </>);
    } else {
      return renderError();
    }
  };

  const renderError = () => {
    if (generateInvoicesOperation.loadState === LoadState.Failed
      && (generateInvoicesOperation?.failureReason?.reason != null
        || generateInvoicesOperation?.failureReason?.error != null)
    ) {
      return (
        <div className="text-center w-100">
          <Text strong className="text-negative">{generateInvoicesOperation?.failureReason?.reason ?? generateInvoicesOperation?.failureReason?.error}</Text>
        </div>
      );
    }
    if (generationRequested && invoices?.errorMessage != null) {
      return (
        <div className="text-center w-100">
          <Text strong className="text-negative">{invoices.errorMessage}</Text>
        </div>
      );
    }
  };

  const renderFormFooter = () => {
    if (!generationRequested) {
      return [<Button key="generate" type="primary" onClick={onGenerate} disabled={isGenerationInProgress} >
        Generate
      </Button>,
      <Button key="back" onClick={onCancel} disabled={isGenerationInProgress} >
        Cancel
      </Button>,
      ];
    }
    return [];
  }

  const renderResultsFooter = () => {
    if (generationRequested) {
      return [<Button
        key="generate"
        onClick={onGenerate}
        disabled={isGenerationInProgress
          || invoices?.invoices?.length === 0
          || invoices?.invoices?.every(i => i.errorMessage == null)} >
        Try again
      </Button>,
      <Button
        key="back"
        type="primary"
        onClick={onCancel}
        disabled={isGenerationInProgress} >
        Close
      </Button>,
      ];
    }
    return [];
  }

  return (<Modal
    width="900px"
    visible={props.isVisible}
    title={`Generate invoices for recent period`}
    closable={false}
    footer={[...renderFormFooter(), ...renderResultsFooter()]}
  >
    <Spin tip="Generating invoices. This may take a while." spinning={isGenerationInProgress}>
      {renderForm()}
      {renderResults()}
    </Spin>
  </Modal>);
}

export default InvoiceGenerationModal;
import { createApiCaller } from '../../../common/services/ApiService';
import { fetchDataWithActionCreator } from '../../../common/store/fetched';
import { gamesListUpdated } from '../../actions/games/slice-actions'
import {IGame} from '../../reducers/games/games.models';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { processFailureReason } from '../../../common/store/failure';

export const getGames = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const params = getState().GamesReducer.liveGamesParam;
    return await fetchDataWithActionCreator(
      gamesListUpdated,
      dispatch,
      createApiCaller(api => api.getData<IGame[]>('/games', params)),
      processFailureReason,
      true,
    );
  };
}
import { useCallback, useEffect } from 'react';
import { IAddUpdateInvoiceCustomItemRequest, IInvoiceCustomItem } from '../../reducers/billing/billing.models';
import { Button, Form, Input, Modal, Row } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { LoadState } from '../../../common/store/fetched';
import { usePrevious } from '../../../common/hooks/usePrevious';
import { FormComponentProps } from 'antd/lib/form';
import { addInvoiceCustomItem } from '../../actions/billing/addInvoiceCustomItem';
import { updateInvoiceCustomItem } from '../../actions/billing/updateInvoiceCustomItem';

function hasErrors(fieldsError: Record<string, string[]>) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

function parseAmount(value: string) {
  if (value.length === 0) {
    return null;
  }
  return /^-?[\d,]+(\.\d{0,2})?$/.test(value) ? parseFloat(value) : NaN;
}

async function validateMaxAmount(_rule: unknown, value: number | null) {
  if (value == null || isNaN(value)) return;

  if (Math.abs(value) > 99999.99) {
    throw new Error('Max amount exceeded.');
  }
}

export type Props = FormComponentProps & {
  onClose: () => void;
  invoiceCustomItem: IInvoiceCustomItem;
  invoiceId: number;
};

const EditCustomInvoiceModalComponent = ({ invoiceCustomItem, onClose, form, invoiceId }: Props) => {
  const dispatch = useAppDispatch();

  const isAdd = invoiceCustomItem == null;

  const { getFieldDecorator, getFieldsError } = form;

  const saveState = useAppSelector(state => isAdd
    ? state.BillingReducer.addInvoiceCustomItemOperation.loadState
    : state.BillingReducer.updateInvoiceCustomItemOperation.loadState);
  const isSaving = saveState === LoadState.InProgress;

  const prevSaveState = usePrevious(saveState);

  useEffect(() => {
    if (prevSaveState === LoadState.InProgress && saveState === LoadState.Success) {
      onClose();
    }
  }, [saveState, prevSaveState, onClose]);

  const handleSave = useCallback(() => {
    form.validateFields(async (err, values) => {
      if (err) return;

      const request: IAddUpdateInvoiceCustomItemRequest = {
        value: parseAmount(values.value),
        name: values.name,
      }

      if (isAdd) {
        await dispatch(addInvoiceCustomItem(invoiceId, request));
      } else {
        await dispatch(updateInvoiceCustomItem(invoiceId, invoiceCustomItem.invoiceCustomItemId, request));
      }
    });
  }, [invoiceCustomItem, dispatch, form, invoiceId, isAdd]);

  const handleCancel = useCallback(() => {
    if (!isSaving) {
      onClose();
    }
  }, [isSaving, onClose]);

  return (
    <Modal
      visible
      title={isAdd ? 'Add custom item' : 'Edit custom item'}
      onCancel={handleCancel}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={onClose} disabled={isSaving}>Cancel</Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          onClick={handleSave}
          loading={isSaving}
          disabled={hasErrors(getFieldsError())}
        >
          {isAdd ? 'Add' : 'Save'}
        </Button>,
      ]}
    >
      <Form>
        <Row >
          <Form.Item label="Name">
            {getFieldDecorator('name', {
              initialValue: invoiceCustomItem?.name,
              rules: [
                { max: 100, message: 'Maximum length is 100.' },
                { required: true, message: 'Field is required.' },
              ]
            })(
              <Input placeholder="" disabled={isSaving} />
            )}
          </Form.Item>
          <Form.Item label="Value (pre-split)">
            {getFieldDecorator('value', {
              initialValue: invoiceCustomItem?.value.toFixed(2) ?? '',
              rules: [
                { required: true, message: 'Field is required.' },
                { type: 'number', message: 'Invalid amount.', transform: parseAmount },
                { validator: validateMaxAmount, message: 'Max amount is $99,999.99.' }
              ],
            })(
              <Input placeholder="" disabled={isSaving} />
            )}
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
}

const EditCustomInvoiceModal =
  Form.create<Props>()(EditCustomInvoiceModalComponent);

export default EditCustomInvoiceModal;
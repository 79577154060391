import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ContactsActions from '../../actions/contacts/ContactsActions';
import { AddNewContactModal } from '../../components/contacts/AddNewContactModal';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    addContactSuccess: state.ContactsReducer.addContactSuccess,
    addContactError: state.ContactsReducer.addContactError,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ContactsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewContactModal)

import { Row } from 'antd';
import { Authenticator } from '@aws-amplify/ui-react';
import authenticatorComponents from './AuthenticatorComponents';

const ForgotPasswordComponent = () =>
  (
    <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Authenticator hideSignUp initialState="forgotPassword" components={authenticatorComponents} />
    </Row>
  );

export default ForgotPasswordComponent;

import * as React from "react";
import { isEqual, isEmpty, filter } from "lodash";
import * as L from 'leaflet'
import AppUtilityService from "../../../common/services/AppUtilityService";
import MapService from "../../../common/services/MapService";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";

import { Card, Row, Col, List, Spin, Progress, Radio, Avatar, Badge } from 'antd';
import { Link } from "react-router";
import AppConstants from "../../../common/AppConstants";
import sessionService from "../../../common/services/SessionService";
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

let map: any = {};
let statesLayer: any = {};
let markersLayer: any = new L.FeatureGroup();
let mapMarkers: any = [];

export class MapStatsCardComponent extends React.Component<IMapStatsCardProps, IMapStatsCardState> {

  constructor(props: IMapStatsCardProps) {
    super(props);
    this.state = {
      isLoading: this.props.isLoading,
      mapData: [],
      typeFilter: AppConstants.dashboardMapStatsMetrics && AppConstants.dashboardMapStatsMetrics[0].value,
      selectedItem: null,
      selectedZoneName: this.props.selectedZoneName || null,
      selectedLocationName: this.props.selectedLocationName || null,
      pinType: "zone",
      listTitle: "",
      zonesList: []
    }
  }

  componentDidMount() {
    this.changeListTitle()
    this.initializeMapOptions();
  }

  componentWillReceiveProps(nextProps: IMapStatsCardProps) {
    if (nextProps.loadDashboardMapStatsSuccess && !isEqual(this.props.loadDashboardMapStatsSuccess, nextProps.loadDashboardMapStatsSuccess)) {
      this.handleMapDataChange(nextProps.loadDashboardMapStatsSuccess)
    }

    if (nextProps.loadDashboardMapStatsError && !isEqual(this.props.loadDashboardMapStatsError, nextProps.loadDashboardMapStatsError)) {
      AppUtilityService.handleApiError(nextProps.loadDashboardMapStatsError);
    }

    if (!isEqual(this.props.selectedZoneName, nextProps.selectedZoneName)) {
      this.setState({ selectedZoneName: nextProps.selectedZoneName }, () => {
        map.setView([37.8, -96], 4);
        this.changeListTitle()
      })
    }

    if (!isEqual(this.props.selectedLocationName, nextProps.selectedLocationName)) {
      this.setState({ selectedLocationName: nextProps.selectedLocationName }, () => {
        this.changeListTitle()
      })
    }
    if (!isEqual(this.props.zonesListSuccess, nextProps.zonesListSuccess)) {
      this.setState({ zonesList: nextProps.zonesListSuccess }, () => {
        this.plotMapStateLayer();
      })
    }

    if (!isEqual(this.props.isLoading, nextProps.isLoading)) {
      this.setState({ isLoading: nextProps.isLoading })
    }

  }
  render() {
    const title = (
      <>
        <span>
          <Avatar size="small" className="mr-8 avatar-mini-icon" style={{ backgroundColor: '#8DC5F3' }}>
            <i className="icon-map"/>
          </Avatar> Map
        </span>
        <span className="ml-8 mt-8 font-14">
          Pin Color: <RadioGroup value={this.state.pinType} size="small" onChange={this.changePinTypeFilter}>
            <RadioButton value="zone">Zone</RadioButton>
            <RadioButton value="location">Location</RadioButton>
            <RadioButton value="locationType">Location Type</RadioButton>
          </RadioGroup>
        </span>
      </>
    );

    const extraActions = (
      <RadioGroup onChange={this.changeTypeFilter} value={this.state.typeFilter}>
        {
          AppConstants.dashboardMapStatsMetrics && !isEmpty(AppConstants.dashboardMapStatsMetrics) &&
          AppConstants.dashboardMapStatsMetrics.map((obj) => {
            return (
              <RadioButton key={obj.value} value={obj.value}>{obj.key}</RadioButton>
            )
          })
        }
      </RadioGroup>
    );

    var { listTitle } = this.state;

    return (
      <Card
        className="no-body-padding no-header-border card-wrap-header"
        bordered={false}
        title={title}
        extra={extraActions}
      >
        <Spin spinning={this.state.isLoading}>
          <Row>
            <Col xxl={15} xl={12} lg={9} md={24}>
              <div id="map"></div>
            </Col>
            <Col xxl={9} xl={12} lg={15} md={24}>
              <div style={{ padding: '0 24px' }}>
                <h3 style={{ marginBottom: 0 }}>{listTitle}</h3>
              </div>
              <div className="dashboard-map-stats-container">
                <List
                  locale={
                    {
                      emptyText: !this.state.isLoading && <EmptyStateComponent title="No Data" />
                    }
                  }
                  key={new Date().toString()}
                  dataSource={this.state.mapData}
                  renderItem={(item: MapStats, index) => (
                    <List.Item key={index} className={this.state.selectedItem && this.state.selectedItem.id === item.id ? "active" : ''}>
                      <List.Item.Meta title={<Link to={"/locations/" + item.id} className="link-primary">{item.location}</Link>}
                        description={
                          <Row gutter={[16, 8]} onClick={() => this.handleListItemClick(item)}>
                            <Col md={6} sm={12} xs={24} className="bor-grey-med">
                              <div>
                                <span className="t-grey-dark">$</span>
                                <span className="t-black">{AppUtilityService.shortenLargeNumber(item.cashIn, 2)}</span>
                              </div>
                              <div>
                                <span className="t-grey-dark">
                                  {

                                    this.state.typeFilter === "cash_in" &&
                                    <Badge dot className="mr-8" style={{ height: '8px', width: '8px', background: (item.color || "#656C8A") }} />
                                  }
                                  Cash In
                                </span>
                              </div>
                            </Col>
                            <Col md={6} sm={12} xs={24} className="bor-grey-med">
                              <div>
                                <span className="t-black">{AppUtilityService.shortenLargeNumber(item.playCount, 2)}</span>
                              </div>
                              <div>
                                <span className="t-grey-dark">
                                  {

                                    this.state.typeFilter === "play_count" &&
                                    <Badge dot className="mr-8" style={{ height: '8px', width: '8px', background: (item.color || "#656C8A") }} />
                                  }
                                  Play count
                                  </span>
                              </div>
                            </Col>
                            <Col md={6} sm={12} xs={24}>
                              <div>
                                <span className="t-grey-dark">$</span>
                                <span className="t-black">{AppUtilityService.shortenLargeNumber(item.played, 2)}</span>
                              </div>
                              <div>
                                <span className="t-grey-dark">
                                  {

                                    this.state.typeFilter === "played" &&
                                    <Badge dot className="mr-8" style={{ height: '8px', width: '8px', background: (item.color || "#656C8A") }} />
                                  }
                                  Gross Play</span>
                              </div>
                            </Col>
                            {
                              this.state.typeFilter !== "play_count" &&
                              <Col md={6} sm={12} xs={24}>
                                <Progress type="circle" strokeLinecap="square" strokeColor={item.color || "#656C8A"}
                                  percent={item.percent}
                                  width={50} strokeWidth={12}
                                  format={percent => `${percent}%`} />
                              </Col>
                            }
                          </Row>}
                      />
                    </List.Item>
                  )}
                >
                </List>
              </div>
            </Col>
          </Row>
        </Spin>
      </Card>
    )
  }

  /**
   * Data loading related
   */
  handleMapDataChange = (mapData) => {
    if (mapData) {
      if (!isEmpty(mapData)) {
        // map.setView([37.8, -96], 4); // Reset map view and zoom level to US
        // mapData = slice(mapData, 0, 5)
        this.setState({ mapData }, () => {
          this.changeListTitle();
          this.plotMarkers();
        })
      } else {
        this.setState({ mapData: [] })
        this.changeListTitle();
        this.clearMapMarkerLayer();
      }
    }
  }

  changePinTypeFilter = (e) => {
    this.setState({ pinType: e.target.value }, () => {
      this.loadMapStats();
    })
  }

  changeTypeFilter = (e) => {
    this.setState({ typeFilter: e.target.value }, () => {
      this.loadMapStats();
    })
  }

  loadMapStats = () => {
    this.setState({ selectedItem: null });
    var { typeFilter, pinType } = this.state;
    var params = {
      type: typeFilter,
      pinType
    }
    this.props.loadDashboardMapStatsCallback(params)
  }

  /**
   * List - Map interaction (Pan to marker)
   */
  handleListItemClick = (selectedItem) => {
    this.panToMarker(selectedItem.lat, selectedItem.long);
    var selectedMarker = filter(mapMarkers, (obj: any) => { return obj.id === selectedItem.id })[0] || null;
    if (selectedMarker) {
      selectedMarker.openPopup()
    }
    this.setState({ selectedItem })
  }

  markerOnClick = (e) => {
    var id = e.sourceTarget.id;
    var selectedItem = filter(this.state.mapData, (obj: MapStats) => { return obj.id === id })[0] || null;
    this.setState({ selectedItem })
  }

  /**
   * Map init function
   */
  initializeMapOptions = () => {
    map = L.map('map', {
      maxZoom: 10,
      minZoom: 4,
      scrollWheelZoom: true
    }).setView([37.8, -96], 4);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    this.plotMapStateLayer();

    markersLayer.addTo(map);

    map.on('zoomstart', () => {
      this.handleZoomStartChange();
    });
    map.on('zoomend', () => {
      this.handleZoomEndChange();
    });
  }

  handleZoomStartChange = () => {
  }

  handleZoomEndChange = () => {
    if (map.getZoom() > 5) {
      this.removeMapStateLayer();
      // this.addMapMarkerLayer(false);
    } else {
      this.addMapStateLayer();
      // this.removeMapMarkerLayer();
    }
    // if ((zoomStart > zoomEnd) && zoomEnd <= 5 ) {
    //   console.log("handleZoomEndChange")
    //   this.changeListTitle(this.state.selectedZoneName, this.state.selectedLocationName)
    //   this.setState({ typeFilter: AppConstants.gamePlayCashInMetrics && AppConstants.gamePlayCashInMetrics[0].value })
    //   this.loadMapStats()
    // }
  }
  /**
   * Map state layers related
   */
  plotMapStateLayer = () => {
    var geoFeatures_Layers = this.state.zonesList && MapService.getGeoFeatures_States().features.filter(o => this.state.zonesList.some((obj) => o.id === obj.zoneCaption));
    statesLayer = L.geoJson(geoFeatures_Layers, {
      style: function (feature) {
        return { color: "lightblue" };
      },
      onEachFeature: (feature, layer) => {
        layer.on('click', (e) => {
          if (feature && feature.properties) {
            var mapSelectedZone = {
              id: feature.id,
              name: feature.properties.name
            }
            var selectedZone = filter(this.state.zonesList, (obj) => { return obj.zoneCaption === mapSelectedZone.id })[0];

            this.changeListTitle()
            if (selectedZone) {
              this.props.selectedZoneLayerCallback(selectedZone.key)
              this.props.selectedZoneNameChangeCallback(selectedZone.title)
            }
          }
          map.fitBounds(layer.getBounds());
        });
        layer.on("mouseover", (e) => {
          layer.setStyle({
            weight: 2,
            color: '#666',
            fillColor: 'grey'
          });
        });
        layer.on("mouseout", (e) => {
          layer.setStyle({
            color: feature.properties.color,
            fillColor: 'lightblue'
          });
        });
      },
    }).addTo(map);
  }

  removeMapStateLayer = () => {
    map.removeLayer(statesLayer);
  }

  addMapStateLayer = () => {
    map.addLayer(statesLayer);
  }

  /**
   * Map markers related
   */
  plotMarkers = () => {
    var { mapData } = this.state;
    if (mapData && !isEmpty(mapData)) {
      this.clearMapMarkerLayer();
      mapData.forEach((obj: MapStats) => {
        const customPopup = "<div><p>" + obj.location + "<a href='#/locations/" + obj.id + "'>" + "View" + "</a></p><p>" + (obj.locationType &&
          "<Tag><span class='t-black'>" + obj.locationType + "</span></Tag>") + "</p></div>";
        if (obj.lat && obj.long) {
          var marker = new L.marker([obj.lat, obj.long], {
            icon: L.divIcon({
              className: 'map-marker',
              html: '<span class="icon-location-filled" style="color: ' + (obj.color || "#656C8A") + '"></span>',
              iconAnchor: [20, 38],
              popupAnchor: [0, -24]
            })
          })
            .on('click', this.markerOnClick)
            .bindPopup(customPopup);
          marker["id"] = obj.id
          marker.addTo(markersLayer);
          mapMarkers.push(marker);
        }
      })
      if (this.state.selectedZoneName || this.state.selectedLocationName) {
        this.addMapMarkerLayer(true);
      }
    }
  }

  addMapMarkerLayer = (shouldFitBounds: boolean) => {
    if (mapMarkers && !isEmpty(mapMarkers)) {
      map.addLayer(markersLayer);
      if (shouldFitBounds) {
        map.flyToBounds(markersLayer.getBounds());
      }
    }
  }

  removeMapMarkerLayer = () => {
    map.removeLayer(markersLayer);
  }

  clearMapMarkerLayer = () => {
    mapMarkers = [];
    markersLayer.clearLayers();
  }

  panToMarker = (lat, long) => {
    if (lat && long) {
      map.flyTo(new L.LatLng(lat, long), 10);
    }
  }

  changeListTitle = () => {
    var { selectedZoneName, selectedLocationName } = this.state;
    if (selectedZoneName) {
      this.setState({ listTitle: "Locations in " + selectedZoneName })
    }
    if (selectedLocationName) {
      // this.setState({ listTitle: selectedLocationFilter })
      this.setState({ listTitle: "" })
    }
    if (!selectedZoneName && !selectedLocationName && sessionService.getRole() === "Admin") {
      this.setState({ listTitle: "Top 5 locations in Country" })
    }

    if (!selectedZoneName && !selectedLocationName && sessionService.getRole() !== "Admin") {
      this.setState({ listTitle: "" })
    }
  }
}


import * as React from "react";
import { isEqual } from "lodash";
import { FormComponentProps } from 'antd/lib/form/Form'
import AppUtilityService from "../../../common/services/AppUtilityService";
import ZoneLinkedLocationsAutocompleteContainer from "../../containers/autocomplete/ZoneLinkedLocationsAutocompleteContainer";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";

import { Card, Form, Modal, Row, Col, List, Tag } from 'antd';
const FormItem = Form.Item;
const confirm = Modal.confirm;

class ZoneLinkedLocationsComponent extends React.Component<IZoneLinkedLocationsProps & FormComponentProps, IZoneLinkedLocationsState> {

  private autocompleteRef: any;

  constructor(props: IZoneLinkedLocationsProps & FormComponentProps) {
    super(props);
    this.state = {
      selectedZone: this.props.selectedZone || null
    }
  }

  componentWillReceiveProps(nextProps: IZoneLinkedLocationsProps & FormComponentProps) {
    if (nextProps.selectedZone && !isEqual(this.props.selectedZone, nextProps.selectedZone)) {
      this.setState({ selectedZone: nextProps.selectedZone })
    }

    /**
     * Handle assign user to zone success/error
     */
    if (nextProps.assignLocationToZoneSuccess && !isEqual(this.props.assignLocationToZoneSuccess, nextProps.assignLocationToZoneSuccess)) {
      var selectedZone = { ...this.state.selectedZone };
      selectedZone.locations = nextProps.assignLocationToZoneSuccess;
      AppUtilityService.showMessageToast("Location added", "success");
      this.props.form.resetFields();
      this.autocompleteRef.resetDatasource();
      this.setState({ selectedZone })
    }
    if (nextProps.assignLocationToZoneError && !isEqual(this.props.assignLocationToZoneError, nextProps.assignLocationToZoneError)) {
      AppUtilityService.handleApiError(nextProps.assignLocationToZoneError);
    }

    /**
     * Handle unassign user from zone success/error
     */
    if (nextProps.unassignLocationFromZoneSuccess && !isEqual(this.props.unassignLocationFromZoneSuccess, nextProps.unassignLocationFromZoneSuccess)) {
      var selectedZone = { ...this.state.selectedZone };
      selectedZone.locations = nextProps.unassignLocationFromZoneSuccess;
      AppUtilityService.showMessageToast("Location removed", "success");
      this.autocompleteRef.resetDatasource();
      this.setState({ selectedZone })
    }
    if (nextProps.unassignLocationFromZoneError && !isEqual(this.props.unassignLocationFromZoneError, nextProps.unassignLocationFromZoneError)) {
      AppUtilityService.handleApiError(nextProps.unassignLocationFromZoneError);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Card className="no-header-border" bordered={false}
        title="Locations"
      >
        <Form>
          <Row>
            <Col span={20}>
              <FormItem>
                {getFieldDecorator('locationId', {
                  rules: [{ required: true, message: 'Please select a location to add!' }],
                  validateTrigger: ['onSubmit']
                })(
                  <ZoneLinkedLocationsAutocompleteContainer
                    placeholder="Type here to add a location"
                    apiUrlId={this.state.selectedZone && this.state.selectedZone.zoneId}
                    onRef={ref => (this.autocompleteRef = ref)}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={4}>
              <div className="text-right">
                <FormItem>
                  <span className="link-primary" onClick={this.assignLocation}>Add Location</span>
                </FormItem>
              </div>
            </Col>
          </Row>
        </Form>
        {
          this.state.selectedZone && this.state.selectedZone.locations &&
          <List
            className="zone-desc-linked-list"
            itemLayout="horizontal"
            dataSource={this.state.selectedZone.locations}
            locale={
              {
                emptyText: <EmptyStateComponent title="No Linked Locations" />
              }
            }
            renderItem={(item: ZoneLocationDetail) => (
              <List.Item actions={[<i className="icon-remove link-danger" onClick={() => this.unassignLocation(item.id)} />]}>
                    <List.Item.Meta
                        title={<span>{`${item.location} (${item.id})`}</span>}
                  description={
                    <div>
                      <span>Account ID: </span>
                      <span className="t-black bold">{item.accountID}</span>
                      {
                        item.type &&
                        <Tag>{item.type}</Tag>
                      }
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        }
      </Card>
    )
  }

  /**
   * Assign selected location to zone
   */
  assignLocation = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        var { selectedZone } = this.state;
        var formData = values;
        this.props.actions.assignLocationToZone(selectedZone.zoneId, formData.locationId);
      }
    });
  }

  /**
   * Unassign selected location from zone
   */
  unassignLocation = (locationId: number) => {
    var { selectedZone } = this.state;
    confirm({
      title: 'Confirm dialog',
      content: 'Are you sure you want to remove the selected location linked to this zone?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        this.props.actions.unassignLocationFromZone(selectedZone.zoneId, locationId);
      },
    });
  }

} export default Form.create<IZoneLinkedLocationsProps & FormComponentProps>()(ZoneLinkedLocationsComponent);

import * as React from "react";
import { Moment } from "moment";
import { Button } from 'antd';
import { ComponentType } from "react";
import { LocationIPRReportFilters } from '../Reports';
import LocationsAutocompleteWithDemoOnlyToggleContainer from "../../../../common/containers/dropdown/LocationsAutocompleteWithDemoOnlyToggleContainer";

export interface ILocationIPRReportsFilterProps {
  filterValues?: LocationIPRReportFilters;
  filters?: LocationIPRReportsFilter[];
  getSelectedLocationNameCallback?(name: string): void;
  applyFiltersCallback?(params: Object): void;
  actions?: {
      updateFilterValues(params: Object): void;
  }
}

export type FilterValue = number | null;

export type LocationIPRReportsFilter = {
    name: string;
    component: ComponentType<FilterProps>;
    defaultValue?: FilterValue;
};

export type FilterProps = {
  filterValue: FilterValue;
  onFilterValueChange: (value: FilterValue) => void;
};

export interface ILocationIPRReportsFilterState {
  locationId: number;
  selectedLocationName: string;
  filterValues: Record<string, FilterValue>;
}

export class LocationIPRReportsFilterComponent extends React.Component<ILocationIPRReportsFilterProps, ILocationIPRReportsFilterState> {
  private locationsDropdownRef: any;

  constructor(props: ILocationIPRReportsFilterProps) {
    super(props);
    this.state = {
        locationId: 0,
        selectedLocationName: '',
        filterValues: this.getDefaultFilterValues(),
      }
    }

    componentDidMount() {
      if (this.props.filterValues) {
          this.handlePresetFilters(this.props.filterValues)
        }

        this.applyFilters();
      };


    getDefaultFilterValues = () =>
    this.props.filters
      ?.filter(f => f.defaultValue !== undefined)
      ?.reduce((r, c) => ({ ...r, [c.name]: c.defaultValue }), {})
    ?? {};

    render(){
      return(
        <div className="reports-filter">
                <span className="mr-8">
                    <LocationsAutocompleteWithDemoOnlyToggleContainer
                        placeholder="Select location"
                        onRef={ref => (this.locationsDropdownRef = ref)}
                        value={this.state.locationId}
                        customParams={{ zoneId: 2}}
                        onChange={this.handleLocationChange}
                        getSelectedLabel={this.handleSelectedLocation}
                        preselectedSearchTerm={this.state.selectedLocationName}
                    />
                </span>
                <span>
                    <Button type="default" onClick={this.resetFilters}>Reset</Button>
                </span>                        
        </div>
      )
    }
    

    //-----------------------------------------------------------------------
    handleLocationChange = (e) => {
      this.setState({ locationId: e }, () => {
          this.applyFilters()
      })
    }
    handleSelectedLocation = (e) => {
        this.setState({ selectedLocationName: e })
        if (this.props.getSelectedLocationNameCallback) {
            this.props.getSelectedLocationNameCallback(e)
        }
    }

    handlePresetFilters = (filterData) => {
      this.setState({
          locationId: filterData.locationId,
          selectedLocationName: filterData.selectedLocationName,
          filterValues: filterData.filterValues,
      }, () => {
          this.handleSelectedLocation(filterData.selectedLocationName)
          Object.entries(filterData.filterValues).forEach(([filterName, value]) => {
              this.handleFilterChange(filterName, value as FilterValue);
          });
          this.applyFilters();
      })
    }
    //-----------------------------------------------------------------------

    handleFilterChange = (filterName: string, newValue: FilterValue) => {
      this.setState(
        { filterValues: { ...this.state.filterValues, [filterName]: newValue } },
        this.applyFilters);
    }

    applyFilters = () => {
        const {
            locationId,
            selectedLocationName,
            filterValues,
        } = this.state;
        var params: any = {
            locationId: locationId || 0,
            filterValues,
        };
        this.props.applyFiltersCallback(params)
        params.selectedLocationName = selectedLocationName;
        this.props.actions.updateFilterValues(params)
      }

    resetFilters = () => {
        this.setState({
            locationId: 0,
            filterValues: this.getDefaultFilterValues(),
        }, () => {
            this.locationsDropdownRef.resetDatasource();
            this.handleSelectedLocation(null);
            this.applyFilters()
        })
    }

}

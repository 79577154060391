import * as React from "react";
import AppUtilityService from "../../../common/services/AppUtilityService";
import { Card, Button, Badge, Switch } from 'antd';
import GetInvoicesContainer from "../../containers/invoices/GetInvoicesContainer";

export class InvoicesPage extends React.Component<IInvoicesProps, IInvoicesState> {

    constructor(props: IInvoicesProps) {
        super(props);
        this.state = {
            showInvoiceSearch: true
        };
    }

    componentDidMount() {
        this.breadcrumbsBuild();
    }

    render() {
        return (
            <div className="pg-container page-container">
                <Card className="no-header-border" bordered={false}
                    title='Ohio Invoices'>
                </Card>
                <GetInvoicesContainer />
            </div>
            )
    }

    breadcrumbsBuild = () => {
        let breadcrumbsList = [
            { name: "Invoices", link: null }
        ]

        var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
        this.props.actions.updateBreadcrumbs(breadcrumbs);
    }
}
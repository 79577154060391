import { Flex, Heading, Text } from '@aws-amplify/ui-react';

const authenticatorComponents = {
  ConfirmSignIn: {
    Header() {
      return (
        <Flex direction="column">
          <Heading level={3}>Confirm with the auth code</Heading>
          <Text variation="primary" as="p">
            To finish signing in, enter the code from your authenticator device below.
          </Text>
        </Flex>
      );
    },
  },
  ConfirmResetPassword: {
    Header() {
      return (
        <Flex direction="column">
          <Heading level={3}>Reset Your Password</Heading>
          <Text variation="primary" as="p">
            A verification code was just sent to your email. Please enter it below.
          </Text>
        </Flex>
      );
    },
  },
};

export default authenticatorComponents;

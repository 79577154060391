export const GET_GAME_SELECTION_WINDOW_REQUEST = 'GET_GAME_SELECTION_WINDOW_REQUEST'
export const GET_GAME_SELECTION_WINDOW_SUCCESS = `GET_GAME_SELECTION_WINDOW_SUCCESS`
export const GET_GAME_SELECTION_WINDOW_ERROR = `GET_GAME_SELECTION_WINDOW_ERROR`

export const DELETE_GAME_SELECTION_WINDOW_REQUEST = 'DELETE_GAME_SELECTION_WINDOW_REQUEST'
export const DELETE_GAME_SELECTION_WINDOW_SUCCESS = `DELETE_GAME_SELECTION_WINDOW_SUCCESS`
export const DELETE_GAME_SELECTION_WINDOW_ERROR = `DELETE_GAME_SELECTION_WINDOW_ERROR`

export const POST_GAME_SELECTION_WINDOW_REQUEST = 'POST_GAME_SELECTION_WINDOW_REQUEST'
export const POST_GAME_SELECTION_WINDOW_SUCCESS = `POST_GAME_SELECTION_WINDOW_SUCCESS`
export const POST_GAME_SELECTION_WINDOW_ERROR = `POST_GAME_SELECTION_WINDOW_ERROR`

export const UPDATE_GAME_SELECTION_WINDOW_REQUEST = 'UPDATE_GAME_SELECTION_WINDOW_REQUEST'
export const UPDATE_GAME_SELECTION_WINDOW_SUCCESS = `UPDATE_GAME_SELECTION_WINDOW_SUCCESS`
export const UPDATE_GAME_SELECTION_WINDOW_ERROR = `UPDATE_GAME_SELECTION_WINDOW_ERROR`

export const GET_SELECT_LOCATIONS_DATA_REQUEST = 'GET_SELECT_LOCATIONS_DATA_REQUEST'
export const GET_SELECT_LOCATIONS_DATA_SUCCESS = 'GET_SELECT_LOCATIONS_DATA_SUCCESS'
export const GET_SELECT_LOCATIONS_DATA_ERROR = 'GET_SELECT_LOCATIONS_DATA_ERROR'

export interface IGameSelectionWindow {
    gameSelectionWindowId: number,
    windowStartDate: Date,
    allLocations: boolean,
    userId: number,
    createdDate: Date,
    updatedDate: Date,
    deletedDate: Date,
    locationIds: number[],
    locationNames: string[],
    isLatestAllLocationWindow: boolean,
    hasLocationSetups: boolean,
    locations: IGameSelectionWindowLocation[],
    selectedAndAvailableGames: ISelectedAndAvailableGame[],
    windowGames: IWindowGame[],
    window: IGameSelectionWindow | IAddGameSelectionWindow,
}

export interface IGameSelectionWindowLocation {
  id: number,  
  gameSelectionWindowId: number,
  locationId: number | null,
  name: string | null,
}

export interface ISelectedAndAvailableGame {
  gameSelectionWindowId: number,
  gameID: number | null,
  displayName: string | null,
  s3GameContentUri: string | null,
  iconUrl: string | null,
  selected: boolean,
  featured: string,
}

export interface IWindowGame {
  id: number,
  gameSelectionWindowId: number,
  gameID: number,
  featured: string
}

export interface IAddGameSelectionWindow {
    gameSelectionWindowId: number | null,
    windowStartDate: Date,
    allLocations: boolean | false,
    userId: number,
    createdDate: Date,
    updatedDate: Date,
    deletedDate: Date | null,
    locations: IAddGameSelectionWindowLocation[],
    selectedAndAvailableGames: ISelectedAndAvailableGame[],
    windowGames: IWindowGame[],
    window: IGameSelectionWindow | IAddGameSelectionWindow,
}

export interface IAddGameSelectionWindowLocation { 
  id: number | null, 
  gameSelectionWindowId: number | null,
  locationId: number | null,
}

export interface ISelectLocations {
    id: number,
    name: string | null
}

export interface ILoadGameSelectionWindowGame {
  gameSelectionWindowId: number | null,
  gameID: number | null,
}

export interface IGameSelectionWindowState {
    isLoadingGameSelectionWindows: boolean,
    isLoadingSelectLocations: boolean,
    loadGameSelectionWindowsSuccess: IGameSelectionWindow[],
    postingGameSelectionWindow: boolean,
    loadSelectLocations: boolean,
    loadSelectLocationsSuccess: ISelectLocations[],
    window: IGameSelectionWindow | IAddGameSelectionWindow,
    selectedAndAvailableGames: ISelectedAndAvailableGame[],
    windowGames: IWindowGame[],
}

const gameSelectionWindowInitialState: IGameSelectionWindowState =
{
    isLoadingGameSelectionWindows: true,
    isLoadingSelectLocations: true,
    loadGameSelectionWindowsSuccess: [],
    postingGameSelectionWindow: false,
    loadSelectLocations: true,
    loadSelectLocationsSuccess: [],
    window: null,
    selectedAndAvailableGames: [],
    windowGames: [],
}

const GameSelectionWindowReducer = (state: IGameSelectionWindowState = gameSelectionWindowInitialState, action) => {
    switch (action.type) {

        case GET_GAME_SELECTION_WINDOW_REQUEST:
            return { ...state, loadGameSelectionWindowsSuccess: null, loadGameSelectionWindowsError: null, isLoadingGameSelectionWindows: true }

        case GET_GAME_SELECTION_WINDOW_SUCCESS:
            return { ...state, loadGameSelectionWindowsSuccess: action.payload, loadGameSelectionWindowsError: null, isLoadingGameSelectionWindows: false }

        case GET_GAME_SELECTION_WINDOW_ERROR:
          return { ...state, loadGameSelectionWindowsSuccess: null, loadGameSelectionWindowsError: action.payload, isLoadingGameSelectionWindows: false }

        case DELETE_GAME_SELECTION_WINDOW_SUCCESS:
            return { ...state, loadGameSelectionWindowsSuccess: state.loadGameSelectionWindowsSuccess.filter(p => p.gameSelectionWindowId !== action.payload) }

        case POST_GAME_SELECTION_WINDOW_REQUEST:
            return { ...state, postingGameSelectionWindow: true }

        case POST_GAME_SELECTION_WINDOW_SUCCESS:
            return { ...state, loadGameSelectionWindowsSuccess: state.loadGameSelectionWindowsSuccess.concat(action.payload), postingGameSelectionWindow: false }

        case UPDATE_GAME_SELECTION_WINDOW_SUCCESS:
            const newGameSelectionWindow = state.loadGameSelectionWindowsSuccess.map(item => { if (item.gameSelectionWindowId === action.payload.gameSelectionWindowId) { return action.payload } return item })
            return { ...state, loadGameSelectionWindowsSuccess: newGameSelectionWindow } 
            
        case GET_SELECT_LOCATIONS_DATA_REQUEST:
            return { ...state, loadSelectLocationsSuccess: null, loadSelectLocationsError: null}

        case GET_SELECT_LOCATIONS_DATA_SUCCESS:
            return { ...state, loadSelectLocationsSuccess: action.payload, loadSelectLocationsError: null, isLoadingSelectLocations: false}

        case GET_SELECT_LOCATIONS_DATA_ERROR:
            return { ...state, loadSelectLocationsSuccess: null, loadSelectLocationsError: action.payload, isLoadingSelectLocations: false}
        
        default:
            return state
    }
}

export default GameSelectionWindowReducer;
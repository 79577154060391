import { FormComponentProps } from 'antd/lib/form/Form';
import { LocationInvoicingConfigEditFormFields } from './locationInvoicingConfigEditFormFields';
import { IDefaultLocationInvoicingConfigItem, IUpdateDefaultLocationInvoicingConfigRequest } from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';
import { Col, Form, Input, InputNumber, Row, Switch, Typography } from 'antd';
import InvoicingDistributorsAutocompleteContainer from '../../../common/containers/dropdown/InvoicingDistributorsAutocompleteContainer';
import ContactsAutocompleteContainer from '../../../common/containers/dropdown/ContactsAutocompleteContainer';
import { KYv1 } from '../../../common/models/InvoicingSystems';
import InvoiceRecipientOverrideFormFields from './InvoiceRecipientOverrideFormFields';
import { InvoicingContactsComponent } from './InvoicingContactsComponent';
import TaxRatesSelector from './TaxRatesSelector';
import BillingAddress from './BillingAddress';

export type Props = FormComponentProps<LocationInvoicingConfigEditFormFields> & {
  item: IDefaultLocationInvoicingConfigItem;
  locationIndex?: number;
  invoicingSystemId: number;
};

const W100 = { width: '100%' };

const DefaultInvoicingConfigEditForm = ({ item, locationIndex, invoicingSystemId, form }: Props) => {
  const { getFieldDecorator, getFieldValue } = form;

  const nextInvoiceNoSuffixFieldName = `nextInvoiceNoSuffix_${locationIndex}`;
  const dueInDaysFieldName = `dueInDays_${locationIndex}`;
  const amountDuePercentageFieldName = `amountDuePercentage_${locationIndex}`;
  const invoicingDistributorIdFieldName = `invoicingDistributorId_${locationIndex}`;
  const distributorContactIdFieldName = `distributorContactId_${locationIndex}`;
  const additionalInvoiceRecipientsFieldName = `additionalInvoiceRecipients_${locationIndex}`;
  const ticketsPlayedFeeDiscountFieldName = `ticketsPlayedFeeDiscount_${locationIndex}`;
  const hasOverrideBillingAddressFieldName = `hasOverrideBillingAddress_${locationIndex}`;
  const taxRatesFieldName = `taxRateIds_${locationIndex}`;
  const billingContactsFieldName = `billingContacts_${locationIndex}`;
  const localCharityIdFieldName = `localCharityId_${locationIndex}`;

  const localCharityId = getFieldValue(localCharityIdFieldName);

  const validateBillingContacts = (a: any, billingContacts: IUpdateDefaultLocationInvoicingConfigRequest['billingContacts'], callback: (error?: string) => any) => {
    if (!billingContacts || billingContacts.length === 0) {
      return callback('There must be at least one contact in the recipients list');
    }
    return callback();
  };

  return (
    <>
      <Row gutter={16}>
        <Col lg={6} sm={12}>
          <Form.Item label="Next Invoice No">
            {getFieldDecorator(nextInvoiceNoSuffixFieldName, {
              initialValue: item.nextInvoiceNoSuffix ?? 1,
              rules: [{ required: true, message: 'Please input Next Invoice No' }],
            })(<InputNumber min={1} className="w-100" style={W100} />)}
          </Form.Item>
        </Col>
        <Col lg={6} sm={12}>
          <Form.Item label="Due In Days">
            {getFieldDecorator(dueInDaysFieldName, {
              initialValue: item.dueInDays,
            })(<InputNumber min={0} style={W100} />)}
          </Form.Item>
        </Col>
        <Col lg={6} sm={12}>
          <Form.Item label="Amount Due %">
            <div style={{ ...W100, display: 'flex', alignItems: 'center' }}>
            {getFieldDecorator(amountDuePercentageFieldName, {
              initialValue: item.amountDuePercentage,
            })(<InputNumber min={0} max={100} style={{ flexGrow: 1 }}/>)}{' '}
            &#160;%
            </div>
          </Form.Item>
        </Col>
        {invoicingSystemId === KYv1 && (
          <Col sm={6} xs={12}>
            <Form.Item label="Charitable Gaming Fee Discount Override">
              {getFieldDecorator(ticketsPlayedFeeDiscountFieldName, {
                initialValue: item.ticketsPlayedFeeDiscount,
                rules: [{ required: false }],
              })(<InputNumber min={0} max={0.99999} step={0.001} style={W100} />)}
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={16}>
        <Col>
          <Typography.Text type="secondary">
            Amount Due will be the configured percentage of cash net/net win.
            <br />
            Must be a value between 0 and 100. Leave blank to keep the default.
          </Typography.Text>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: '24px' }}>
        <Col span={12}>
          <Form.Item label="Invoicing distributor">
            {getFieldDecorator(invoicingDistributorIdFieldName, {
              initialValue: item.invoicingDistributorId,
              rules: [{ required: true, message: 'Please select invoicing distributor' }],
            })(<InvoicingDistributorsAutocompleteContainer placeholder="Select invoicing distributor" />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Distributor contact">
            {getFieldDecorator(distributorContactIdFieldName, {
              initialValue: item.distributorContactId,
            })(<ContactsAutocompleteContainer customParams={{ locationId: item.locationId }} placeholder="Search contacts" />)}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col>
          <Form.Item label="Taxes and fees">
            {getFieldDecorator(taxRatesFieldName, {
              initialValue: item.taxRateIds,
              rules: [{ required: false }],
            })(<TaxRatesSelector locationId={item.locationId} />)}
          </Form.Item>
        </Col>
      </Row>
      <BillingAddress localCharityId={localCharityId} item={item}/>
      <Row gutter={16}>
        <Col>
          <Form.Item label="Billing Address Override">
            {getFieldDecorator(hasOverrideBillingAddressFieldName, {
              initialValue: item.overrideBillingName || item.overrideBillingAddress != null,
            })(<Switch checked={!!getFieldValue(hasOverrideBillingAddressFieldName)} />)}
          </Form.Item>
        </Col>
      </Row>
      {getFieldValue(hasOverrideBillingAddressFieldName) && <InvoiceRecipientOverrideFormFields item={item} locationIndex={locationIndex} form={form} />}
      <Row gutter={16}></Row>
      <Row>
        <Form.Item>
          {getFieldDecorator(billingContactsFieldName, {
            initialValue: item.invoicingContacts ?? [],
            rules: [
              {
                required: true,
                validator: (_, value, callback) => validateBillingContacts(_, value, callback),
              },
            ],
          })(<InvoicingContactsComponent locationId={item.locationId} locationIndex={locationIndex} form={form} />)}
        </Form.Item>
      </Row>
      <Row gutter={16}>
        <Col>
          <Form.Item label="Additional e-mail recipients" extra="A semicolon separated list of e-mail addresses" style={{ marginTop: 10 }}>
            {getFieldDecorator(additionalInvoiceRecipientsFieldName, {
              initialValue: item.additionalInvoiceRecipients,
              rules: [
                {
                  pattern: /^\W*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]+\W*;\W*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]+)\W*$/,
                  message: 'Please input a semicolon separated list of e-mail addresses',
                },
              ],
            })(<Input />)}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default DefaultInvoicingConfigEditForm;

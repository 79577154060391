import { ticketSalesByGameReportUpdated } from './slice-actions';
import apiService from '../../../common/services/ApiService';
import { createStandardFetchedAction } from '../../../common/store/fetched.slices';
import { Moment } from 'moment';
import AppConstants from '../../../common/AppConstants';

export const getTicketSalesByGameReport =(
  dateFrom: Moment,
  dateTo: Moment,
  jurisdictionId?: number,
  siteId?: number,
  organizationId?: number,
) => createStandardFetchedAction(
  ticketSalesByGameReportUpdated,
  async () => {
    const result = await apiService.getData(
      `/reports/arrow/ticket-sales-by-game-report/${dateFrom.format(AppConstants.apiDateFormat)}/${dateTo.format(AppConstants.apiDateFormat)}`,
      { jurisdictionId, siteId, organizationId });
    return result.data;
  },
  false,
);

import * as React from "react";
import { useEffect, useState } from "react";
import { Card, Table, Row, Col, Input, Button } from 'antd';
import { useAppDispatch, useAppSelector } from "../../../common/hooks/storeHooks";
import { updateBreadcrumbs } from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import { LoadState } from '../../../common/store/fetched';
import AppUtilityService from "../../../common/services/AppUtilityService";
import { IGame, IGameTable } from '../../reducers/games/games.models';
import { usePrevious } from "../../../common/hooks/usePrevious";
import { hashHistory } from "react-router";
import { getGames } from "../../actions/games/getGames";
import { saveGame } from "../../actions/games/saveGame";
import { saveEditGame } from "../../actions/games/saveEditGame";
import { deleteGame } from "../../actions/games/deleteGame";


export const GamesPage = () => {
const dispatch = useAppDispatch();
const gamesList = useAppSelector(state => state.GamesReducer.gamesList);
const gamesListLoadState = useAppSelector(state => state.GamesReducer.gamesList.loadState);
const prevGamesListLoadState = usePrevious(gamesListLoadState);
const saveGameLoadState = useAppSelector(state => state.GamesReducer.saveGame.loadState);
const prevSaveGameLoadState = usePrevious(saveGameLoadState);
const deleteGameLoadState = useAppSelector(state => state.GamesReducer.deleteGame.loadState);
const prevDeleteGameLoadState = usePrevious(deleteGameLoadState);
const saveEditGameLoadState = useAppSelector(state => state.GamesReducer.saveEditGame.loadState);
const prevSaveEditGameLoadState = usePrevious(saveEditGameLoadState);

const [newGameId, setNewGameId] = useState<string | null>(null);
const [newGameName, setNewGameName] = useState<string | null>(null);
const [newGameAbbreviation, setNewGameAbbreviation] = useState<string | null>(null);
const [newGameDisplayName, setNewGameDisplayName] = useState<string | null>(null);
const [newGameTheme, setNewGameTheme] = useState<string | null>(null);
const [newGameExtendedPlayFeatures, setNewGameExtendedPlayFeatures] = useState<string | null>(null);
const [newGameS3GameContentUri, setNewGameS3GameContentUri] = useState<string | null>(null);
const [resetEditGame, setResetEditGame] = useState<IGameTable | null>(null);
const [editMode, setEditMode] = useState<boolean>(false);


const { TextArea } = Input;

const isAbbreviationValid = (abbreviation: string, edit: boolean) : boolean => {
  const allowedRegex = /^[A-Za-z0-9]*$/;
  var result = allowedRegex.test(abbreviation.toUpperCase());
  if (result && !edit) {
    setNewGameAbbreviation(abbreviation.toUpperCase());
  }
  return result;
}

const isGameIdValid = (inputGameID: string, edit: boolean | null) : boolean => {
  const allowedRegex = /^[0-9]*$/;
  var result = allowedRegex.test(inputGameID);
  if (result && !edit) {
    setNewGameId(inputGameID);
  }
  console.log("isGameIdValid: " + result);
  return result;
}

useEffect(() => {
  dispatch(getGames())
}, [dispatch]);

useEffect(() => {
  if (prevSaveGameLoadState === LoadState.InProgress && saveGameLoadState === LoadState.Success) {
    setNewGameId('');
    setNewGameName('');
    setNewGameDisplayName('');
    setNewGameAbbreviation('');
    setNewGameTheme('');
    setNewGameExtendedPlayFeatures('');
    setNewGameS3GameContentUri('');   
    dispatch(getGames()); 
  }
}, [dispatch, prevSaveGameLoadState, saveGameLoadState])

useEffect(() => {
  if (prevDeleteGameLoadState === LoadState.InProgress && deleteGameLoadState === LoadState.Success) {
    dispatch(getGames());
  }
}, [dispatch, prevDeleteGameLoadState, deleteGameLoadState])

useEffect(() => {
  if (prevSaveEditGameLoadState === LoadState.InProgress && saveEditGameLoadState === LoadState.Success) {
    dispatch(getGames());
  }
}, [dispatch, prevSaveEditGameLoadState, saveEditGameLoadState])


useEffect(
  () => {
    const breadcrumbsList = [
      { name: 'Games', link: null }
    ]
    const breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
    dispatch(updateBreadcrumbs(breadcrumbs));
  });

  const [tableData, setTableData] = useState<IGameTable[]>(null);

  useEffect(() => {
    if (prevGamesListLoadState === LoadState.InProgress && gamesListLoadState === LoadState.Success) {
      setTableData(gamesList.data?.map((item) => {
        return {
          id: item.gameID,
          gameID: item.gameID,
          name: item.name,
          displayName: item.displayName,
          abbreviation: item.abbreviation,
          theme: item.theme,
          extendedPlayFeatures: item.extendedPlayFeatures,
          s3GameContentUri: item.s3GameContentUri,
        }
      }));
    }
  }, [dispatch, prevGamesListLoadState, gamesListLoadState])

  const tableColumns = [
    {title: 'Game ID', dataIndex: 'gameID', key: 'gameID', 
      render: (text, record: IGameTable) => {
        return (
          <>
            {record !== undefined && resetEditGame?.id !== record.id &&
              <Button style={{backgroundColor: "dodgerblue", color: "white", height: 45, fontWeight: "bold"}} color="blue"
                onClick={() => {
                  hashHistory.push({                 
                    pathname: `/admin/viewGame/${record.gameID}`,
                    state: {}
                  });
                }}
              >view<div></div>{record.gameID.toString()}</Button>
            }
            {record !== undefined && resetEditGame?.id === record.id &&
              <Input style={{width: 50}} type="text" value={record.gameID} title="numeric only" disabled={resetEditGame?.id === record.id}
              onChange={
                (e) => {
                  console.log("target" + e.target.value);
                  if(isGameIdValid(e.target.value, true)) {
                    console.log("value: " + e.target.value + " tabledata gamid id: " + tableData.find(t => t.id === record.id).gameID + " tabledata id: " + tableData.find(t => t.id  === record.id).id);
                    let updated = tableData.find(t => t.id === record.id);
                    updated.gameID = parseInt(e.target.value);
                    setTableData([...tableData, updated]);
                    console.log("value: " + e.target.value + " tabledata gamid id: " + tableData.find(t => t.id === record.id).gameID + " tabledata id: " + tableData.find(t => t.id  === record.id).id);
                  }
                }                
              }></Input>
            }
          </>
        )
      }},
    {title: 'Game Name', dataIndex: 'name', key: 'name',
      render: (text, record: IGameTable) => {
        return (
          <>
            {record !== undefined && resetEditGame?.id !== record.id &&
              <div style={{width: 55}}>{text ? record.name : ''}</div>
            }
            {record !== undefined && resetEditGame?.id === record.id &&
              <TextArea style={{width: 80}} autoSize={{ minRows: 1, maxRows: 3 }} maxLength={100} value={record.name} title="max 100 chars"
              onChange={
                (e) => {
                  let updated = tableData.find(g => g.id === record.id);
                  updated.name = e.target.value;
                  setTableData([...tableData, updated]);
                }
              }></TextArea>
            }
          </>
        )
      }},
    {title: 'Display Name', dataIndex: 'displayName', key: 'displayName',
    render: (text, record: IGameTable) => {
      return (
        <>
          {record !== undefined && resetEditGame?.id !== record.id &&
            <div style={{width: 55}}>{text ? record.displayName : ''}</div>
          }
          {record !== undefined && resetEditGame?.id === record.id &&
            <TextArea style={{width: 80}} autoSize={{ minRows: 1, maxRows: 3 }} value={record.displayName} maxLength={100} title="max 100 chars"
            onChange={
              (e) => {
                let updated = tableData.find(g => g.id === record.id);
                updated.displayName = e.target.value;
                setTableData([...tableData, updated]);
              }
            }></TextArea>
          }
        </>
      )
    }},
    {title: 'Game Abbreviation', dataIndex: 'abbreviation', key: 'abbreviation', 
      render: (text, record: IGameTable) => {
        return (
          <>
            {record !== undefined && resetEditGame?.id !== record.id &&
              <div style={{width: 20}}>{text ? record.abbreviation : ''}</div>
            }
            {record !== undefined && resetEditGame?.id === record.id &&
              <Input style={{width: 50}} type="text" value={record.abbreviation} maxLength={5} title="abbreviation - max 5 chars, alphanumeric only"
              onChange={
                (e) => {
                  if(isAbbreviationValid(e.target.value, true)) {
                    let updated = tableData.find(g => g.id === record.id);
                    updated.abbreviation = e.target.value;
                    setTableData([...tableData, updated]);
                  }
                }                
              }></Input>
            }
          </>
        )
      }},
    {title: 'Theme', dataIndex: 'theme', key: 'theme',
    render: (text, record: IGameTable) => {
      return (
        <>
          {record !== undefined && resetEditGame?.id !== record.id &&
            <div style={{width: 400}}>{text ? record.theme : ''}</div>
          }
          {record !== undefined && resetEditGame?.id === record.id &&
            <TextArea style={{width: 400}} autoSize={{ minRows: 1, maxRows: 3 }} value={record.theme} maxLength={600} title="max 600 chars"
            onChange={
              (e) => {
                let updated = tableData.find(g => g.id === record.id);
                updated.theme = e.target.value;
                setTableData([...tableData, updated]);
              }
            }></TextArea>
          }
        </>
      )
    }},
    {title: 'Extended Play Features', dataIndex: 'extendedPlayFeatures', key: 'extendedPlayFeatures',
    render: (text, record: IGameTable) => {
      return (
        <>
          {record !== undefined && resetEditGame?.id !== record.id &&
            <div style={{width: 200}}>{text ? record.extendedPlayFeatures : ''}</div>
          }
          {record !== undefined && resetEditGame?.id === record.id &&
            <TextArea style={{width: 200}} autoSize={{ minRows: 1, maxRows: 3 }} value={record.extendedPlayFeatures} maxLength={300} title="max 300 chars"
            onChange={
              (e) => {
                let updated = tableData.find(g => g.id === record.id);
                updated.extendedPlayFeatures = e.target.value;
                setTableData([...tableData, updated]);
              }
            }></TextArea>
          }
        </>
      )
    }},
    {title: 'S3 Game Content Uri', dataIndex: 's3GameContentUri', key: 's3GameContentUri',
    render: (text, record: IGameTable) => {
      return (
        <>
          {record !== undefined && resetEditGame?.id !== record.id &&
            <div style={{width: 200}}>{text ? record.s3GameContentUri : ''}</div>
          }
          {record !== undefined && resetEditGame?.id === record.id &&
            <TextArea style={{width: 200}} autoSize={{ minRows: 1, maxRows: 3 }} value={record.s3GameContentUri} maxLength={140} title="max 140 chars"
            onChange={
              (e) => {
                let updated = tableData.find(g => g.id === record.id);
                updated.s3GameContentUri = e.target.value;
                setTableData([...tableData, updated]);
              }
            }></TextArea>
          }
        </>
      )
    }},
    {title: '', key: 'action', dataIndex: 'gameID', render: (text, record) => 
    (
      <>
      {
        <Button style={{ marginLeft:  '12px' }}
          hidden={editMode}
          onClick={() => {
            setResetEditGame(record);
            setEditMode(true);
            console.log("editRecord: " + resetEditGame?.id?.toString())   
          }        
          }
        >EDIT</Button>
      }
      {
        <Button style={{ marginLeft:  '12px' }}
          hidden={editMode}
          onClick={() => dispatch(deleteGame(record.gameID))}
        >DELETE</Button>
      }
      {
        <Button style={{ marginLeft:  '12px' }}
          hidden={!editMode || resetEditGame?.id !== record.id}
          onClick={() => {
            setResetEditGame(null);
            setEditMode(false);
          }}
        >CANCEL</Button>
      }
      {
        <Button style={{ marginLeft:  '12px' }}
          hidden={!editMode || resetEditGame?.id !== record.id}
          onClick={
            () => {
              let updated = tableData.find(g => g.id === record.id);
              setResetEditGame(null);
              setEditMode(false);
              dispatch(saveEditGame(updated));
            }
          }
        >UPDATE</Button>
      }
      </>
    )}
  ]

  return (
    <div className="pg-container page-container">
      <Card className="no-header-border" bordered={false}>
        <Card type="inner" title="Add New" bordered={false}>
          <Row>
            <Col span={24}>
            <Col span={1}>
              <Input id="gameIdInput" type="Text" placeholder="ID"  value={newGameId} title="numeric only"
              onChange={e => isGameIdValid(e.target.value, false)}
              ></Input>
            </Col>
            <Col span={3}>
              <TextArea autoSize={{ minRows: 1, maxRows: 3 }} placeholder="Name" value={newGameName} maxLength={100} title="max 100 chars"
              onChange={e => {setNewGameName(e.target.value)}}
              ></TextArea>
            </Col>
            <Col span={3}>
              <TextArea autoSize={{ minRows: 1, maxRows: 3 }} placeholder="Display Name" value={newGameDisplayName} maxLength={100} title="max 100 chars"
              onChange={e => {setNewGameDisplayName(e.target.value)}}
              ></TextArea>
            </Col>
            <Col span={1}>
              <Input  type="Text" placeholder="Abbr" value={newGameAbbreviation} maxLength={5} title="abbreviation - max 5 chars, alphanumeric only"
                onChange={e => isAbbreviationValid(e.target.value, false)}
                >                
              </Input>
            </Col>
            <Col span={7}>
              <TextArea autoSize={{ minRows: 1, maxRows: 16 }} placeholder="Theme" value={newGameTheme} maxLength={600} title="max 600 chars"
              onChange={e => {setNewGameTheme(e.target.value)}}
              ></TextArea>
            </Col>
            <Col span={4}>
              <TextArea autoSize={{ minRows: 1, maxRows: 8 }} placeholder="Extended Play" value={newGameExtendedPlayFeatures} maxLength={300} title="max 300 chars"
              onChange={e => {setNewGameExtendedPlayFeatures(e.target.value)}}
              ></TextArea>
            </Col>
            <Col span={4}>
              <TextArea autoSize={{ minRows: 1, maxRows: 3 }} placeholder="S3 Content Uri" value={newGameS3GameContentUri} maxLength={140} title="max 140 chars"
              onChange={e => {setNewGameS3GameContentUri(e.target.value)}}
              ></TextArea>
            </Col>
            <Button
              //disabled if other fields null or not valid
            disabled={newGameId === '' || newGameName === '' || newGameDisplayName === '' || newGameAbbreviation === '' || newGameS3GameContentUri === '' ||
                      newGameId === null || newGameName === null || newGameDisplayName === null || newGameAbbreviation === null || newGameS3GameContentUri === null }
            onClick={() => {
              const newGame: IGame = {
                gameID: parseInt(newGameId),
                name: newGameName,
                displayName: newGameDisplayName,
                abbreviation: newGameAbbreviation,
                theme: newGameTheme,
                extendedPlayFeatures: newGameExtendedPlayFeatures,
                s3GameContentUri: newGameS3GameContentUri,
              };
              dispatch(saveGame(newGame));
            }
          }
            >ADD</Button>
          </Col>
          </Row>
        </Card>
        <Table
          rowKey={record => record.id.toString()}
          dataSource={tableData}
          columns={tableColumns}
          >
        </Table>
        </Card>
        </div>
  );
}
export default GamesPage;
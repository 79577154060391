
import * as React from "react";
import { isEqual, isEmpty } from "lodash";
import { FormComponentProps } from 'antd/lib/form/Form'
import AppUtilityService from "../../../common/services/AppUtilityService";
import UsersLinkedZonesAutocompleteContainer from "../../containers/autocomplete/UsersLinkedZonesAutocompleteContainer";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";

import { Card, Form, Modal, Row, Col, Tree, Spin } from 'antd';
const FormItem = Form.Item;
const confirm = Modal.confirm;
const TreeNode = Tree.TreeNode;

class UsersLinkedZonesComponent extends React.Component<IUserLinkedZonesProps & FormComponentProps, IUserLinkedZonesState> {

  private autocompleteRef: any;

  constructor(props: IUserLinkedZonesProps & FormComponentProps) {
    super(props);
    this.state = {
      userDetails: this.props.userDetails || null,
      expandedKeys: [],
      isLoading: this.props.userDetails ? false : true
    }
  }

  componentWillReceiveProps(nextProps: IUserLinkedZonesProps & FormComponentProps) {
    if (nextProps.userDetails && !isEqual(this.props.userDetails, nextProps.userDetails)) {
      this.setState({ userDetails: nextProps.userDetails, isLoading: false })
    }

    /**
     * Handle success/error on assign zone action
     */
    if (nextProps.assignZoneToUsersSuccess && !isEqual(this.props.assignZoneToUsersSuccess, nextProps.assignZoneToUsersSuccess)) {
      var userDetails = { ...this.state.userDetails };
      userDetails.linkedZones = nextProps.assignZoneToUsersSuccess;
      AppUtilityService.showMessageToast("Zone added", "success");
      this.props.form.resetFields();
      this.autocompleteRef.resetDatasource();
      this.setState({ userDetails, isLoading: false })
    }
    if (nextProps.assignZoneToUsersError && !isEqual(this.props.assignZoneToUsersError, nextProps.assignZoneToUsersError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.assignZoneToUsersError);
    }

    /**
     * Handle success/error on unassign zone action
     */
    if (nextProps.unassignZoneFromUsersSuccess && !isEqual(this.props.unassignZoneFromUsersSuccess, nextProps.unassignZoneFromUsersSuccess)) {
      var userDetails = { ...this.state.userDetails };
      var responseData = nextProps.unassignZoneFromUsersSuccess;
      userDetails.linkedZones = responseData;
      AppUtilityService.showMessageToast("Zone removed", "success");
      this.autocompleteRef.resetDatasource();
      this.setState({ userDetails, isLoading: false })

    }
    if (nextProps.unassignZoneFromUsersError && !isEqual(this.props.unassignZoneFromUsersError, nextProps.unassignZoneFromUsersError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.unassignZoneFromUsersError);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Card className="no-body-padding no-header-border" bordered={false}
        title="Zones"
      >
        {
          this.state.userDetails && this.state.userDetails.role !== 'Admin' &&
          <Form>
            <Row>
              <Col span={20}>
                <FormItem>
                  {getFieldDecorator('zoneId', {
                    rules: [{ required: true, message: 'Please select a zone to add!' }],
                    validateTrigger: ['onSubmit']
                  })(
                    <UsersLinkedZonesAutocompleteContainer
                      placeholder="Select zone"
                      apiUrlId={this.state.userDetails && this.state.userDetails.userId}
                      onRef={ref => (this.autocompleteRef = ref)} />
                  )}
                </FormItem>
              </Col>
              <Col span={4}>
                <div className="text-right">
                  <FormItem>
                    <span className="link-primary" onClick={this.assignZone}>Add</span>
                  </FormItem>
                </div>
              </Col>
            </Row>
          </Form>
        }
        <Spin spinning={this.state.isLoading}>
          {
            this.state.userDetails && this.state.userDetails.linkedZones && !isEmpty(this.state.userDetails.linkedZones) ?
              <Tree
                className="block-children"
                defaultExpandAll={false}
                showIcon
                expandedKeys={this.state.expandedKeys}
                onExpand={this.onExpand}
              >
                {this.renderTreeNodes(this.state.userDetails.linkedZones)}
              </Tree>
              :
              <EmptyStateComponent title="No Linked Zones" />
          }
        </Spin>
      </Card>
    )
  }

  /**
   * Generate actions for each item in the list
   */
  // generateActions = (item: BasicZoneDetails): Array<any> => {
  //   var actions = [];
  //   actions.push(<i className="icon-remove link-danger" onClick={() => this.unassignZone(item.zoneId)} />);
  //   return actions;
  // }

  renderTreeNodes = (allZones: Array<BasicZoneDetails>) => {
    return allZones.map((item: BasicZoneDetails) => {
      return (
        <TreeNode selectable={false} icon={<i className="icon-zone t-grey-dark" />}
          title={<span>{item.name}{this.generateActions(item)}</span>}
          key={item.zoneId.toString()}
        >
          {
            item.childZones && !isEmpty(item.childZones) &&
            this.renderTreeNodes(item.childZones)
          }
        </TreeNode>
      );
    });
  }

  generateActions = (item: BasicZoneDetails): Array<any> => {
    var actions = [];
    if (this.state.userDetails && this.state.userDetails.role !== 'Admin' && item.isSetZone) {
      actions.push(<i className="icon-remove link-danger" onClick={() => this.unassignZone(item.zoneId)} />);
    }
    return actions;
  }


  /**
   * Assign selected zone to user
   */
  assignZone = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ isLoading: true })
        var { userDetails } = this.state;
        var formData = values;
        this.props.actions.assignZoneToUsers(userDetails.userId, formData.zoneId);
      }
    });
  }

  /**
   * Unassign selected zone from user
   */
  unassignZone = (zoneId: number) => {
    var { userDetails } = this.state;
    confirm({
      title: 'Confirm dialog',
      content: 'Are you sure you want to remove the selected zone linked to this user?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        this.setState({ isLoading: true })
        this.props.actions.unassignZoneFromUsers(userDetails.userId, zoneId)
      },
    });
  }

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
    });
  }

} export default Form.create<IUserLinkedZonesProps & FormComponentProps>()(UsersLinkedZonesComponent);

import React from 'react';
import { Col, Form, Input, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { LocationInvoicingConfigEditFormFields } from './locationInvoicingConfigEditFormFields';
import {
  IDefaultLocationInvoicingConfigItem,
} from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';
import StateAutocompleteContainer from '../../../common/containers/dropdown/StateAutocompleteContainer';

export type Props = FormComponentProps<LocationInvoicingConfigEditFormFields> & {
  item: IDefaultLocationInvoicingConfigItem;
  locationIndex?: number;
};

const InvoiceRecipientOverrideFormFields = (
  {
    item,
    locationIndex,
    form: {
      getFieldDecorator,
    },
  }: Props,
) => (
  <>
    <Row gutter={16}>
      <Col>
        <Form.Item label="Name">
          {getFieldDecorator(`overrideBillingName_${locationIndex}`, {
            initialValue: item.overrideBillingName,
            rules: [{ required: true, message: 'Please input Billing Address Override Name' }],
          })(
            <Input placeholder=""/>,
          )}
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col>
        <Form.Item label="Address line 1">
          {getFieldDecorator(`overrideBillingAddressLine1_${locationIndex}`, {
            initialValue: item.overrideBillingAddress?.address1,
          })(
            <Input placeholder=""/>,
          )}
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col>
        <Form.Item label="Address line 2">
          {getFieldDecorator(`overrideBillingAddressLine2_${locationIndex}`, {
            initialValue: item.overrideBillingAddress?.address2,
          })(
            <Input placeholder=""/>,
          )}
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item label="City">
          {getFieldDecorator(`overrideBillingAddressCity_${locationIndex}`, {
            initialValue: item.overrideBillingAddress?.city,
          })(
            <Input placeholder=""/>,
          )}
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="State">
          {getFieldDecorator(`overrideBillingAddressState_${locationIndex}`, {
            initialValue: item.overrideBillingAddress?.addressState,
          })(
            <StateAutocompleteContainer
              ignoreId
              disableMinWidth
              preselectedSearchTerm={item.overrideBillingAddress?.addressState}
            />,
          )}
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="Zipcode">
          {getFieldDecorator(`overrideBillingAddressZipcode_${locationIndex}`, {
            initialValue: item.overrideBillingAddress?.zipcode,
          })(
            <Input placeholder=""/>,
          )}
        </Form.Item>
      </Col>
    </Row>
  </>
)

export default InvoiceRecipientOverrideFormFields;

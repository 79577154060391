import { DownloadedFile, saveFile } from '../../../common/store/fileDownload';
import apiService from '../../../common/services/ApiService';
import { createStandardFetchedAction } from '../../../common/store/fetched.slices';
import { downloadVirginiaSalesTaxOperationUpdated } from './slice-actions';

interface IVirginiaSalesTaxReportParams {
  dateFrom: string;
  dateTo: string;
  zoneId: number;
}

export const downloadVirginiaSalesTaxReport = (params: IVirginiaSalesTaxReportParams) =>
  createStandardFetchedAction(
    downloadVirginiaSalesTaxOperationUpdated,
    async () => {
      const result = await apiService.downloadFile('/reports/invoicing/sales-tax/excel', params);
      const fileName = result.headers['x-filename'];
      const file: DownloadedFile = {
        data: result.data,
        fileName,
      };
      saveFile(file);
    },
    false,
  );

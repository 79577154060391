export type ContactsState = Partial<{
  addContactError: any;
  updateContactImageSuccess: any;
  removeContactImageSuccess: any;
  deleteContactError: any;
  updateContactImageError: any;
  loadListDataSuccess: any;
  updateContactBasicDetailsError: any;
  loadListDataError: any;
  loadDetailsError: any;
  updateContactBasicDetailsSuccess: any;
  addContactSuccess: any;
  loadDetailsSuccess: any;
  addNewContactUserSuccess: any;
  contactListFilters: any;
  addNewContactUserError: any;
  removeContactImageError: any;
  deleteContactSuccess: any;
  addContactNoteSuccess: any;
  addContactNoteError: any;
  updateContactNoteSuccess: any;
  updateContactNoteError: any;
  deleteContactNoteSuccess: any;
  deleteContactNoteError: any;
}>;

const initialState: ContactsState = {};

const ContactsReducer = (state = initialState, action): ContactsState => {
  switch (action.type) {
    case "CONTACT_LIST_FILTER_UPDATE":
      return { ...state, contactListFilters: action.payload };
    // Contacts List related
    case "LOAD_CONTACTS_LIST_DATA_REQUEST":
      return { ...state, loadListDataSuccess: null, loadListDataError: null };
    case "LOAD_CONTACTS_LIST_DATA_SUCCESS":
      return { ...state, loadListDataSuccess: action.payload, loadListDataError: null };
    case "LOAD_CONTACTS_LIST_DATA_FAILURE":
      return { ...state, loadListDataSuccess: null, loadListDataError: action.payload };

    // Contact basic details load
    case "LOAD_CONTACT_DETAILS_REQUEST":
      return { ...state, loadDetailsSuccess: null, loadDetailsError: null };
    case "LOAD_CONTACT_DETAILS_SUCCESS":
      return { ...state, loadDetailsSuccess: action.payload, loadDetailsError: null };
    case "LOAD_CONTACT_DETAILS_FAILURE":
      return { ...state, loadDetailsSuccess: null, loadDetailsError: action.payload };

    // Add new contact
    case "ADD_NEW_CONTACT_REQUEST":
      return { ...state, addContactSuccess: null, addContactError: null };
    case "ADD_NEW_CONTACT_SUCCESS":
      return { ...state, addContactSuccess: action.payload, addContactError: null };
    case "ADD_NEW_CONTACT_FAILURE":
      return { ...state, addContactSuccess: null, addContactError: action.payload };

    // Contact basic details update
    case "UDPATE_CONTACT_BASIC_DETAILS_REQUEST":
      return { ...state, updateContactBasicDetailsSuccess: null, updateContactBasicDetailsError: null };
    case "UDPATE_CONTACT_BASIC_DETAILS_SUCCESS":
      return { ...state, updateContactBasicDetailsSuccess: action.payload, updateContactBasicDetailsError: null };
    case "UDPATE_CONTACT_BASIC_DETAILS_FAILURE":
      return { ...state, updateContactBasicDetailsSuccess: null, updateContactBasicDetailsError: action.payload };

      // Contact details delete
    case "DELETE_CONTACT_REQUEST":
      return { ...state, deleteContactSuccess: null, deleteContactError: null };
    case "DELETE_CONTACT_SUCCESS":
      return { ...state, deleteContactSuccess: action.payload, deleteContactError: null };
    case "DELETE_CONTACT_FAILURE":
      return { ...state, deleteContactSuccess: null, deleteContactError: action.payload };

    // Contact image related
    case "UPDATE_CONTACT_IMAGE_REQUEST":
      return { ...state, updateContactImageSuccess: null, updateContactImageError: null };
    case "UPDATE_CONTACT_IMAGE_SUCCESS":
      return { ...state, updateContactImageSuccess: action.payload, updateContactImageError: null };
    case "UPDATE_CONTACT_IMAGE_FAILURE":
      return { ...state, updateContactImageSuccess: null, updateContactImageError: action.payload };

    // Contact image related
    case "REMOVE_CONTACT_IMAGE_REQUEST":
      return { ...state, removeContactImageSuccess: null, removeContactImageError: null };
    case "REMOVE_CONTACT_IMAGE_SUCCESS":
      return { ...state, removeContactImageSuccess: action.payload, removeContactImageError: null };
    case "REMOVE_CONTACT_IMAGE_FAILURE":
      return { ...state, removeContactImageSuccess: null, removeContactImageError: action.payload };
    // Contact image related
    case "ADD_NEW_CONTACT_USER_REQUEST":
      return { ...state, addNewContactUserSuccess: null, addNewContactUserError: null };
    case "ADD_NEW_CONTACT_USER_SUCCESS":
      return { ...state, addNewContactUserSuccess: action.payload, addNewContactUserError: null };
    case "ADD_NEW_CONTACT_USER_FAILURE":
      return { ...state, addNewContactUserSuccess: null, addNewContactUserError: action.payload };

    // Contact Notes
    case "ADD_NEW_CONTACTNOTE_REQUEST":
      return { ...state, addContactNoteSuccess: null, addContactNoteError: null};
    case "ADD_NEW_CONTACTNOTE_SUCCESS":
      return { ...state, addContactNoteSuccess: action.payload, addContactNoteError: null};
    case "ADD_NEW_CONTACTNOTE_FAILURE":
      return { ...state, addContactNoteSuccess: null, addContactNoteError: action.payload};
    // update Contact Note
    case "UPDATE_CONTACTNOTE_REQUEST":
      return { ...state, updateContactNoteSuccess: null, updateContactNoteError: null};
    case "UPDATE_CONTACTNOTE_SUCCESS":
      return { ...state, updateContactNoteSuccess: action.payload, updateContactNoteError: null};
    case "UPDATE_CONTACTNOTE_FAILURE":
      return { ...state, updateContactNoteSuccess: null, updateContactNoteError: action.payload};
    // delete Contact Note
    case "DELETE_CONTACTNOTE_REQUEST":
      return { ...state, deleteContactNoteSuccess: null, deleteContactNoteError: null};
    case "DELETE_CONTACTNOTE_SUCCESS":
      return { ...state, deleteContactNoteSuccess: action.payload, deleteContactNoteError: null};
    case "DELETE_CONTACTNOTE_FAILURE":
      return { ...state, deleteContactNoteSuccess: null, deleteContactNoteError: action.payload};

    default:
      return state;
  }
}

export default ContactsReducer;

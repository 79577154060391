import { Moment } from 'moment';
import { InvoicingSystemID, NDv1 } from '../../../common/models/InvoicingSystems';

export interface InvoicingContact {
  locationInvoicingConfigContactId: number;
  invoicingConfigId: number;
  contactId: number;
  isPrimary: boolean;
  contact: Contacts
}

export interface INDLocationInvoicingConfigItem {
  locationId: number;
  invoicingSystemId: typeof NDv1;
  locationName: string;
  invoicingEnabled: boolean | null;
  invoicingDistributorId: number | null;
  invoicingDistributorName: string | null;
  localCharityId: number | null;
  charityId: number | null;
  charityName: string | null;
  locationCharityDistributorInvoicingConfigsId: number | null;
}

export interface IDefaultLocationInvoicingConfigItem {
  locationId: number;
  invoicingSystemId: Omit<InvoicingSystemID, typeof NDv1> | null;
  locationName: string;
  invoicingEnabled: boolean | null;
  nextInvoiceNoSuffix: number | null;
  dueInDays: number | null;
  additionalInvoiceRecipients: string | null;
  invoicingDistributorId: number | null;
  invoicingDistributorName: string | null;
  localCharityId: number | null;
  distributorContactId: number | null;
  distributorContactName: string | null;
  amountDuePercentage: number | null;
  ticketsPlayedFeeDiscount: number | null;
  overrideBillingName: string | null;
  overrideBillingAddress: Address | null;
  salesTaxId : number | null;
  invoicingContacts: InvoicingContact[];
  primaryContact : Contacts;
  taxRateIds: number[];
}

export type LocationInvoicingConfigItem = IDefaultLocationInvoicingConfigItem | INDLocationInvoicingConfigItem;

export interface IPagedLocationInvoicingConfig {
  total: number;
  locationInvoicingConfigs: LocationInvoicingConfigItem[];
}

export type LocationCharityDistributorDiscount = {
  startDate: Moment;
  discount: number;
};

export type LocationInvoicingConfigSortField = 'LocationId' | 'LocationName' | 'InvoicingEnabled' | 'NextInvoiceNo' | 'DueInDays' | 'Distributor' | 'DistributorContact';

export interface ILocationInvoicingConfigSearchParams {
  zoneId?: number;
  locationId?: number;
  locationIdOrName?: string;
  isInvoicingEnabled?: boolean;
  showDemoLocations?: boolean;
  showDecommissioned?: boolean;
  pageNo?: number;
  pageSize?: number;
  sortBy: LocationInvoicingConfigSortField;
  sortAsc: boolean;
}

export interface IUpdateNDLocationInvoicingConfigRequest {
  invoicingSystemId: typeof NDv1;
  invoicingEnabled: boolean;
  invoicingDistributorId: number;
  localCharityId: number;
  charityId: number;
  discounts: LocationCharityDistributorDiscount[];

}

export interface IUpdateDefaultLocationInvoicingConfigRequest {
  invoicingSystemId: Omit<InvoicingSystemID, typeof NDv1>;
  localCharityId: number;
  invoicingEnabled: boolean;
  nextInvoiceNoSuffix: number | null;
  dueInDays: number | null;
  additionalInvoiceRecipients: string | null;
  invoicingDistributorId: number | null;
  distributorContactId: number | null;
  amountDuePercentage: number | null;
  overrideBillingName: string | null;
  salesTaxId : number | null;
  billingContacts: InvoicingContact[];
  taxRateIds: number[];
}

export type UpdateLocationInvoicingConfigRequest = Array<IUpdateDefaultLocationInvoicingConfigRequest | IUpdateNDLocationInvoicingConfigRequest>;

export type LocationCharity = {
  id: number;
  name: string;
  licenseNumber: string;
  email: string;
  addressId: number | null;
  addressType: string;
  address1: string;
  address2: string;
  city: string;
  addressState: string;
  zipcode: string;
  localCharityId: number | null;
};

export type SalesTax = {
  salesTaxId : number;
  invoicingSystemId : number;
  fips: string;
  localityName : string;
  salesTaxValue: number;
}

export type TaxRate = {
  taxRateId : number | null;
  name : string;
  value: number;
  zoneId: number;
  calculationMethod: TaxRateCalculationMethod;
}

export enum TaxRateCalculationMethod {
  Net = 1,
  Gross = 2,
}

export const CalculationMethodMapping = {
  [TaxRateCalculationMethod.Net]: "Net",
  [TaxRateCalculationMethod.Gross]: "Sales Tax",
};


import React from 'react';
import { FilterProps } from '../reports/locationStatisticsReports/LocationReportsFilterComponent';
import { Radio } from 'antd';
import { InvoiceStatus } from '../../reducers/billing/billing.models';

const StatusFilter = ({ filterValue, onFilterValueChange }: FilterProps) => (
  <span>
    <span className="font-14 regular">Status </span>
    <Radio.Group value={filterValue ?? ''} size="small" onChange={e => onFilterValueChange(e.target.value)}>
      <Radio.Button value={''}>All</Radio.Button>
      <Radio.Button value={InvoiceStatus.PendingReview}>Pending</Radio.Button>
      <Radio.Button value={InvoiceStatus.Approved}>Approved</Radio.Button>
      <Radio.Button value={InvoiceStatus.Sent}>Sent</Radio.Button>
    </Radio.Group>
  </span>
);

export default StatusFilter;

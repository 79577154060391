import React from 'react';
import { Select } from 'antd';
import moment, { Moment } from 'moment';

export type Period = {
  start: Moment,
  end: Moment,
}

export type Props = {
  value?: Period | null;
  onChange: (value: Period | null) => void;
};

export function getCurrentWeekPeriod(): Period {
  const endSunday = moment().subtract(moment().weekday(), 'day');
  const startMonday = moment(endSunday).subtract(6, 'day');
  return { start: startMonday, end: endSunday };
}

function getLastYearPeriods(): Period[] {
  const periods: Period[] = [];
  let { start: startMonday, end: endSunday } = getCurrentWeekPeriod();
  for (let i = 0; i < 52; i++) {
    periods.push({ start: startMonday, end: endSunday });
    endSunday = moment(startMonday).subtract(1, 'day');
    startMonday = moment(startMonday).subtract(7, 'day');
  }
  return periods;
}

const WeeklyDateRangeFilter = ({ onChange, value }: Props) => {
  const weeks = getLastYearPeriods();
  return (
    <Select
      placeholder="Date ranges"
      value={value ? weeks.findIndex(w => w.start.isSame(value.start, 'day')) : undefined}
      style={{ width: 200, marginRight: 10 }}
      onChange={index => onChange(weeks[index])}
    >
      {
        weeks.map((p, index) => (
          <Select.Option
            key={index}
            value={index}
            title={`${p.start.format('l')} - ${p.end.format('l')}`}
          >
            {`${p.start.format('l')} - ${p.end.format('l')}`}
          </Select.Option>
        ))
      }
    </Select>
  );
}

export default WeeklyDateRangeFilter;

export const CustomCognitoAttribute = {
  RoleName: 'custom:powertab-roleName',
};

export interface AuthenticatedUser {
  userName: string;
  userId: string;
  role: string;
  token: string;
  tokenExpiration: number;
}

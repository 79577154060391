import * as React from "react";
import { useEffect, useState } from "react";
import { Card, Table, Row, Col, Input, Button } from 'antd';
import { useAppDispatch, useAppSelector } from "../../../common/hooks/storeHooks";
import { updateBreadcrumbs } from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import { LoadState } from '../../../common/store/fetched';
import AppUtilityService from "../../../common/services/AppUtilityService";
import { IGame, IGameTable, IGameView } from '../../reducers/games/games.models';
import { usePrevious } from "../../../common/hooks/usePrevious";
import { getGameView } from "../../actions/games/getGameView";
import ViewGameIcon from '../games/viewGameIcon';
import ViewGameFeaturedImages from "./viewGameFeaturedImages";
import ViewFlare from "./viewFlare";
import ViewGameCarousel from "./viewGameCarousel";
const styles = require('../../../common/styles/ViewGameStyles.css');

export const ViewGamePage = (props) => {
  const dispatch = useAppDispatch();

  const currentGameView = useAppSelector(state => state.GamesReducer.gameView);
  const gameViewLoadState = useAppSelector(state => state.GamesReducer.gameView.loadState);
  const prevGameViewLoadState = usePrevious(gameViewLoadState);

  const currentGame = parseInt(props.routeParams.gameId); //use this gameId to call the api for the rest of the game data

  useEffect(() => {
    dispatch(getGameView(currentGame))
  }, [dispatch]);

  const [game, setGame] = useState<IGameView>(null);

  useEffect(() => {
    if (prevGameViewLoadState === LoadState.InProgress && gameViewLoadState === LoadState.Success) {
      if (currentGameView.data !== null) {
        setGame(currentGameView.data);
      }
    }
  }, [dispatch, prevGameViewLoadState, gameViewLoadState])

  useEffect(
    () => {
      const breadcrumbsList = [
        { name: 'GameView', link: null }
      ]
      const breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
      dispatch(updateBreadcrumbs(breadcrumbs));
    });

  return (
    <div className="pg-container page-container gameViewBackground">
      {game !== undefined && game !== null &&
        <div>
          <Row>
            <Row style={{ textAlign: "center" }}>
              <span className="gameViewHeader">{game.name} (GameID {game.gameID})</span>
            </Row>
          </Row>
          <Row type="flex" justify="center" align="middle">
            <Col span={6}>
              <ViewGameCarousel iconUrl={game.iconUrl} gameId={game.gameID} carouselMedia={game.carouselMedia} theme={game.theme} extendedPlayFeatures={game.extendedPlayFeatures}>
              </ViewGameCarousel>
            </Col>
            <Col span={12}>
              <ViewGameIcon icon={game.iconUrl} name={game.name} >
              </ViewGameIcon>

              <Row>
                <ViewFlare gameID={game.gameID} gameType={game.gameType} firstFormType={game.firstFormType}>
                </ViewFlare>
              </Row>
            </Col>
          </Row>
          <Row>
            <ViewGameFeaturedImages leftImage={game.leftFeatureUrl} rightImage={game.rightFeatureUrl} name={game.name}>
            </ViewGameFeaturedImages>
          </Row>
        </div>
      }
    </div>
  );
}
export default ViewGamePage;
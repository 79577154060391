import ApiService from "../../../common/services/ApiService";

export function loadDistributorsListData(params) {
  return function (dispatch) {
    dispatch({ type: `LOAD_DISTRIBUTOR_LIST_DATA_REQUEST` });
    return ApiService.getData("/distributors", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_DISTRIBUTOR_LIST_DATA_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_DISTRIBUTOR_LIST_DATA_FAILURE`, payload: errorResult });
      });
  }
}

export function loadDistributorBasicDetails(distributorId) {
  return function (dispatch) {
    dispatch({ type: `LOAD_DISTRIBUTOR_DATA_REQUEST` });
    return ApiService.getData("/distributors/" + distributorId)
      .then((successResult) => {
        dispatch({ type: `LOAD_DISTRIBUTOR_DATA_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_DISTRIBUTOR_DATA_FAILURE`, payload: errorResult });
      });
  }
}

export function loadDistributorUsersBasicDetails(distributorId) {
  return function (dispatch) {
    dispatch({ type: `LOAD_DISTRIBUTOR_USERS_DATA_REQUEST` });
    return ApiService.getData("/distributors/" + distributorId)
      .then((successResult) => {
        dispatch({ type: `LOAD_DISTRIBUTOR_USERS_DATA_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_DISTRIBUTOR_USERS_DATA_FAILURE`, payload: errorResult });
      });
  }
}

export function addDistributorLinkedUser(distributorId, userId) {
  return function (dispatch) {
    dispatch({ type: `DISTRIBUTOR_LINKED_USER_ADD_REQUEST` });
    return ApiService.updateData("/distributors/" + distributorId + "/users/" + userId)
      .then((successResult) => {
        dispatch({ type: `DISTRIBUTOR_LINKED_USER_ADD_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `DISTRIBUTOR_LINKED_USER_ADD_FAILURE`, payload: errorResult });
      });
  }
}

export function deleteDistributorLinkedUser(distributorId, userId) {
  return function (dispatch) {
    dispatch({ type: `DISTRIBUTOR_LINKED_USER_DELETE_REQUEST` });
    return ApiService.deleteData("/distributors/" + distributorId + "/users/" + userId)
      .then((successResult) => {
        dispatch({ type: `DISTRIBUTOR_LINKED_USER_DELETE_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `DISTRIBUTOR_LINKED_USER_DELETE_FAILURE`, payload: errorResult });
      });
  }
}
import ApiService from "../../../common/services/ApiService";

export function updateLocationListFilters(params) {
  return {
    type: 'LOCATION_LIST_FILTER_UPDATE',
    payload: params
  }
}

// ================================================================================
// Location List
// ================================================================================

export function loadLocationsListData(params) {
  return function (dispatch) {
    dispatch({ type: `LOAD_LOCATIONS_LIST_DATA_REQUEST` });
    return ApiService.getData("/locations", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_LOCATIONS_LIST_DATA_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_LOCATIONS_LIST_DATA_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
// Location Details + Summary Stats
// ================================================================================
/**
 * Add new location
 */
export function addNewLocation(data) {
  return function (dispatch) {
    dispatch({ type: `ADD_NEW_LOCATION_REQUEST` });
    return ApiService.postData("/locations", data)
      .then((successResult) => {
        dispatch({ type: `ADD_NEW_LOCATION_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `ADD_NEW_LOCATION_FAILURE`, payload: errorResult });
      });
  }
}
/**
 * Update location basic details
 */
export function updateLocationBasicDetails(locationId, data) {
  return function (dispatch) {
    dispatch({ type: `UPDATE_LOCATION_BASIC_DETAILS_REQUEST` });
    return ApiService.updateData("/locations/" + locationId, null, data)
      .then((successResult) => {
        dispatch({ type: `UPDATE_LOCATION_BASIC_DETAILS_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `UPDATE_LOCATION_BASIC_DETAILS_FAILURE`, payload: errorResult });
      });
  }
}
/**
 * Delete location
 */
export function deleteLocationDetails(locationId) {
  return function (dispatch) {
    dispatch({ type: `DELETE_LOCATION_REQUEST` });
    return ApiService.deleteData("/locations/" + locationId, null, null)
      .then((successResult) => {
        dispatch({ type: `DELETE_LOCATION_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `DELETE_LOCATION_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
export function updateLocationDateFilters(date) {
  return {
    type: 'LOCATION_DETAIL_DATE_FILTER_UPDATE',
    payload: date
  }
}

// ================================================================================
export function loadLocationBasicDetails(locationId) {
  return function (dispatch) {
    dispatch({ type: `LOAD_LOCATION_BASIC_DETAILS_REQUEST` });
    return ApiService.getData("/locations/" + locationId)
      .then((successResult) => {
        dispatch({ type: `LOAD_LOCATION_BASIC_DETAILS_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_LOCATION_BASIC_DETAILS_FAILURE`, payload: errorResult });
      });
  }
}

// ================================================================================
export function loadLocationStats(locationId, params) {
  return function (dispatch) {
    dispatch({ type: `LOAD_LOCATION_STATS_REQUEST` });
    return ApiService.getData("/locations/" + locationId + "/stats", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_LOCATION_STATS_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_LOCATION_STATS_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
// Location Summary by Game Cahrt
// ================================================================================
export function loadLocationGameSummary(params) {
  return function (dispatch) {
    dispatch({ type: `LOAD_LOCATION_GAME_SUMMARY_REQUEST` });
    return ApiService.getData("/locations/" + params.locationId + "/summary", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_LOCATION_GAME_SUMMARY_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_LOCATION_GAME_SUMMARY_FAILURE`, payload: errorResult });
      });
  }
}

export function loadLocationDateSummary(params) {
  return function (dispatch) {
    dispatch({ type: `LOAD_LOCATION_DATE_SUMMARY_REQUEST` });
    return ApiService.getData("/locations/" + params.locationId + "/summary", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_LOCATION_DATE_SUMMARY_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_LOCATION_DATE_SUMMARY_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
// Location Recent Activity
// ================================================================================
export function loadLocationRecentActivity(params) {
  return function (dispatch) {
    dispatch({ type: `LOAD_LOCATION_RECENT_ACTIVITY_REQUEST` });
    return ApiService.getData("/locations/" + params.locationId + "/summary", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_LOCATION_RECENT_ACTIVITY_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_LOCATION_RECENT_ACTIVITY_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
// Location Linked Contact
// ================================================================================
export function deleteLocationLinkedContact(params) {
  return function (dispatch) {
    dispatch({ type: `LOCATION_LINKED_CONTACT_DELETE_REQUEST` });
    return ApiService.deleteData("/locations/" + params.locationId + "/contact/" + params.contactId)
      .then((successResult) => {
        dispatch({ type: `LOCATION_LINKED_CONTACT_DELETE_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOCATION_LINKED_CONTACT_DELETE_FAILURE`, payload: errorResult });
      });
  }
}

// ================================================================================
export function addLocationLinkedContact(params, data) {
  return function (dispatch) {
    dispatch({ type: `LOCATION_LINKED_CONTACT_ADD_REQUEST` });
    return ApiService.postData("/locations/" + params.locationId + "/contact", data)
      .then((successResult) => {
        dispatch({ type: `LOCATION_LINKED_CONTACT_ADD_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOCATION_LINKED_CONTACT_ADD_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
export function updateLocationLinkedContact(params, data) {
  return function (dispatch) {
    dispatch({ type: `LOCATION_LINKED_CONTACT_UPDATE_REQUEST` });
    return ApiService.updateData("/locations/" + params.locationId + "/contact/" + params.contactId, null, data)
      .then((successResult) => {
        dispatch({ type: `LOCATION_LINKED_CONTACT_UPDATE_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOCATION_LINKED_CONTACT_UPDATE_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
export function addLocationLinkedCharity(locationId: number, charityId: number, localCharityId?: number | null) {
  return function (dispatch) {
    dispatch({ type: `LOCATION_LINKED_CHARITY_ADD_REQUEST` });
    const params = localCharityId ? { localCharityId } : undefined;
    return ApiService.updateData("/locations/" + locationId + "/charities/" + charityId, params)
      .then((successResult) => {
        dispatch({ type: `LOCATION_LINKED_CHARITY_ADD_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOCATION_LINKED_CHARITY_ADD_FAILURE`, payload: errorResult });
      });
  }
}

export function deleteLocationLinkedCharity(locationId, charityId) {
  return function (dispatch) {
    dispatch({ type: `LOCATION_LINKED_CHARITY_DELETE_REQUEST` });
    return ApiService.deleteData("/locations/" + locationId + "/charities/" + charityId)
      .then((successResult) => {
        dispatch({ type: `LOCATION_LINKED_CHARITY_DELETE_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOCATION_LINKED_CHARITY_DELETE_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
export function addLocationLinkedZone(locationId, zoneId) {
  return function (dispatch) {
    dispatch({ type: `LOCATION_LINKED_ZONE_ADD_REQUEST` });
    return ApiService.updateData("/locations/" + locationId + "/zones/" + zoneId)
      .then((successResult) => {
        dispatch({ type: `LOCATION_LINKED_ZONE_ADD_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOCATION_LINKED_ZONE_ADD_FAILURE`, payload: errorResult });
      });
  }
}
export function deleteLocationLinkedZone(locationId, zoneId) {
  return function (dispatch) {
    dispatch({ type: `LOCATION_LINKED_ZONE_DELETE_REQUEST` });
    return ApiService.deleteData("/locations/" + locationId + "/zones/" + zoneId)
      .then((successResult) => {
        dispatch({ type: `LOCATION_LINKED_ZONE_DELETE_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOCATION_LINKED_ZONE_DELETE_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
// Location Invoices
// ================================================================================
export function loadLocationInvoicesList(locationId, params) {
  return function (dispatch) {
    dispatch({ type: `LOAD_LOCATION_INVOICES_LIST_REQUEST` });
    return ApiService.getData("/locations/" + locationId + "/invoices", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_LOCATION_INVOICES_LIST_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_LOCATION_INVOICES_LIST_FAILURE`, payload: errorResult });
      });
  }
}

// ================================================================================
// DistributorUser stamps
// ================================================================================

export function loadDistributorUserStampData() {
  return function (dispatch) {
    dispatch({ type: `LOAD_DISTRIBUTOR_USER_STAMP_LIST_DATA_REQUEST` });
    return ApiService.getData("/distributors/userremainingstamps")
      .then((successResult) => {
        dispatch({ type: `LOAD_DISTRIBUTOR_USER_STAMP_LIST_DATA_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_DISTRIBUTOR_USER_STAMP_LIST_DATA_FAILURE`, payload: errorResult });
      });
  }
}


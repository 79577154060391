import * as React from "react";
import { isEqual } from "lodash";
import { Card, Col, Row, Spin, Avatar } from 'antd';
import AppUtilityService from "../../../common/services/AppUtilityService";

export class CashMiniCardComponent extends React.Component<IDashboardMiniStatCardProps, IDashboardMiniStatCardState>{
  constructor(props: IDashboardMiniStatCardProps) {
    super(props);
    this.state = {
      cashStats: null,
      isLoading: this.props.isLoading
    }
  }

  componentWillReceiveProps(nextProps: IDashboardMiniStatCardProps) {

    if (nextProps.loadDashboardMiniStatsSuccess && !isEqual(this.props.loadDashboardMiniStatsSuccess, nextProps.loadDashboardMiniStatsSuccess)) {
      var responseData: any = nextProps.loadDashboardMiniStatsSuccess;
      this.setState({ cashStats: responseData })
    }

    if (nextProps.loadDashboardMiniStatsError && !isEqual(this.props.loadDashboardMiniStatsError, nextProps.loadDashboardMiniStatsError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.loadDashboardMiniStatsError);
    }

    if (!isEqual(this.props.isLoading, nextProps.isLoading)) {
      this.setState({ isLoading: nextProps.isLoading })
    }
  }

  render() {
    var cardTitle = <span>
      <Avatar size="small" className="mr-8 avatar-mini-icon" style={{ backgroundColor: '#8DC5F3' }}>
        <i className="icon-cash" />
      </Avatar> Cash
      </span>;
    return (
      <Card className="no-header-border" bordered={false} title={cardTitle}>
        <Spin spinning={this.state.isLoading}>
            <Row gutter={16} style={{ paddingLeft: '38px' }}>
            <Col sm={24} md={12} lg={24} xl={12} xxl={6}>
              <div className="t-grey-dark">Cash In</div>
              <div>
                <span className="stat-unit-left">$</span>
                <span className="font-22 bold">{this.state.cashStats && this.state.cashStats.cashIn && AppUtilityService.shortenLargeNumber(this.state.cashStats.cashIn, 2)}</span>
              </div>
            </Col>
            <Col sm={24} md={12} lg={24} xl={12} xxl={6}>
                <div className="t-grey-dark">Cash Out</div>
                <div>
                    <span className="stat-unit-left">$</span>
                    <span className="font-22 bold">{this.state.cashStats && this.state.cashStats.cashOut && AppUtilityService.shortenLargeNumber(this.state.cashStats.cashOut, 2)}</span>
                </div>
            </Col>
            <Col sm={24} md={12} lg={24} xl={12} xxl={6}>
              <div className="t-grey-dark">Cash Net</div>
              <div>
                <span className="stat-unit-left">$</span>
                <span className="font-22 bold">{this.state.cashStats && this.state.cashStats.net && AppUtilityService.shortenLargeNumber(this.state.cashStats.net, 2)}</span>
              </div>
            </Col>
            <Col sm={24} md={12} lg={24} xl={12} xxl={6}>
              <div className="t-grey-dark">Hold Percentage</div>
              <div>
                <span className="font-22 bold">{this.state.cashStats && this.state.cashStats.holdPercentage && AppUtilityService.shortenLargeNumber(this.state.cashStats.holdPercentage, 2)}</span>
                <span className="stat-unit-right">%</span>
              </div>
            </Col>
            {/* OWEB-243
            <Col xs={12} xxl={6}>
              <div className="t-grey-dark">ADNW</div>
              <div>
                <span className="stat-unit-left">$</span>
                <span className="font-22 bold">{this.state.cashStats && this.state.cashStats.adnw && AppUtilityService.shortenLargeNumber(this.state.cashStats.adnw, 2)}</span>
              </div>
            </Col>
            */}
            {/* <Col span={3}>

            </Col> */}
          </Row>
        </Spin>
      </Card>
    )
  }
}

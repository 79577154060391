
import * as React from "react";
import { isEqual } from "lodash";
import { Layout, Row, Col } from 'antd';
import RoleDetailsDescriptionCardContainer from "../../containers/userRole/RoleDetailsDescriptionCardContainer";
import RolePermissionsCardContainer from "../../containers/userRole/RolePermissionsCardContainer";
import RoleUsersListCardContainer from "../../containers/userRole/RoleUsersListCardContainer";
import AppUtilityService from "../../../common/services/AppUtilityService";

export class UserRoleDetailsPage extends React.Component<IUserRoleDetailsProps, IUserRoleDetailsState> {

  constructor(props: IUserRoleDetailsProps) {
    super(props);

    this.state = {
      roleId: parseInt(this.props.params.roleId) || null,
      roleDetails: null
    }
  }

  componentWillMount() {
    if (this.state.roleId) {
      this.getRoleDetails()
    }
  }

  componentWillReceiveProps(nextProps: IUserRoleDetailsProps) {
    /**
     * User role load list success/error handle
     */
    if (nextProps.loadSelectedRoleDetailsSuccess && !isEqual(this.props.loadSelectedRoleDetailsSuccess, nextProps.loadSelectedRoleDetailsSuccess)) {
      this.setState({ roleDetails: nextProps.loadSelectedRoleDetailsSuccess }, () => {
        this.breadcrumbsBuild();
      })
    }
    if (nextProps.loadSelectedRoleDetailsError && !isEqual(this.props.loadSelectedRoleDetailsError, nextProps.loadSelectedRoleDetailsError)) {
      AppUtilityService.handleApiError(nextProps.loadSelectedRoleDetailsError);
    }
  }

  render() {
    return (
      <div className="pg-container page-container">
        <Layout>
          <RoleDetailsDescriptionCardContainer roleDetails={this.state.roleDetails} />
          <div>
            <br />
          </div>
          <Row gutter={24}>
            <Col xl={16} lg={14} xs={24} className="sm-row-b-margin md-row-b-margin">
              <RolePermissionsCardContainer roleDetails={this.state.roleDetails} />
            </Col>
            <Col xl={8} lg={10} xs={24}>
              <RoleUsersListCardContainer roleDetails={this.state.roleDetails} />
            </Col>
          </Row>
        </Layout>
      </div>
    )
  }

  breadcrumbsBuild = () => {
    let breadcrumbsList = [
      { name: "User Role", link: "/admin/user-role" },
      { name: this.state.roleDetails.name, link: null }
    ]
    var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
    this.props.actions.updateBreadcrumbs(breadcrumbs);
  }

  getRoleDetails = () => {
    var { roleId } = this.state;
    this.props.actions.loadSelectedRoleDetails(roleId);
  }
}

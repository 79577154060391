export type UserAccountState = Partial<{
    userChangePasswordSuccess: any;
    userForgotPasswordError: any;
    userLoginSuccess: any;
    userRegisterError: any;
    userResetPasswordSuccess: any;
    userChangePasswordError: any;
    userForgotPasswordSuccess: any;
    userLoginError: any;
    userRegisterSuccess: any;
    userResetPasswordError: any;
}>;

const initialState: UserAccountState = {};

const UserAccountReducer = (state = initialState, action): UserAccountState => {
    switch (action.type) {
        case "USER_LOGIN_REQUEST":
            return { ...state, userLoginSuccess: null, userLoginError: null };
        case "USER_LOGIN_SUCCESS":
            return { ...state, userLoginSuccess: action.payload, userLoginError: null };
        case "USER_LOGIN_FAILURE":
            return { ...state, userLoginSuccess: null, userLoginError: action.payload };

        case "USER_REGISTER_REQUEST":
            return { ...state, userRegisterSuccess: null, userRegisterError: null };
        case "USER_REGISTER_SUCCESS":
            return { ...state, userRegisterSuccess: action.payload, userRegisterError: null };
        case "USER_REGISTER_FAILURE":
            return { ...state, userRegisterSuccess: null, userRegisterError: action.payload };

        case "USER_RESET_PASSWORD_REQUEST":
            return { ...state, userResetPasswordSuccess: null, userResetPasswordError: null };
        case "USER_RESET_PASSWORD_SUCCESS":
            return { ...state, userResetPasswordSuccess: action.payload, userResetPasswordError: null };
        case "USER_RESET_PASSWORD_FAILURE":
            return { ...state, userResetPasswordSuccess: null, userResetPasswordError: action.payload };

        case "USER_CHANGE_PASSWORD_REQUEST":
            return { ...state, userChangePasswordSuccess: null, userChangePasswordError: null };
        case "USER_CHANGE_PASSWORD_SUCCESS":
            return { ...state, userChangePasswordSuccess: action.payload, userChangePasswordError: null };
        case "USER_CHANGE_PASSWORD_FAILURE":
            return { ...state, userChangePasswordSuccess: null, userChangePasswordError: action.payload };

        case "USER_FORGOT_PASSWORD_REQUEST":
            return { ...state, userForgotPasswordSuccess: null, userForgotPasswordError: null };
        case "USER_FORGOT_PASSWORD_SUCCESS":
            return { ...state, userForgotPasswordSuccess: action.payload, userForgotPasswordError: null };
        case "USER_FORGOT_PASSWORD_FAILURE":
            return { ...state, userForgotPasswordSuccess: null, userForgotPasswordError: action.payload };

        default:
            return state;
    }
}

export default UserAccountReducer;

import { createFetched, Fetched } from '../../../common/store/fetched';
import { DownloadedFile } from '../../../common/store/fileDownload';
import { createSlice } from '@reduxjs/toolkit';
import { createFetchedDismisser, createFetchedSetter } from '../../../common/store/fetched.slices';
import { IOhioElectronicInstantBingoReportItem, IOhioQuarterlyReportItem, IWeeklyNumbersReportItem } from './invoicingReports.models';

export interface InvoicingReportsState {
  downloadOhioWeeklyNumbersOperation: Fetched<DownloadedFile>;
  downloadOhioQuarterlyOperation: Fetched<DownloadedFile>;
  ohioQuarterlyReport: Fetched<IOhioQuarterlyReportItem[]>;
  weeklyNumbersReport: Fetched<IWeeklyNumbersReportItem[]>;
  ohioElectronicInstantBingoReport: Fetched<IOhioElectronicInstantBingoReportItem[]>;
  downloadOhioElectronicInstantBingoReportOperation: Fetched<void>;
  downloadNorthDakotaInvoicesReportOperation: Fetched<void>;
  downloadDistributorDealsDeliveredReportOperation: Fetched<void>;
  downloadNorthDakotaGamesOperationUpdated: Fetched<void>;
  downloadVirginiaSalesTaxOperation: Fetched<void>;
  downloadNhLucky7MonthlyFinancialReportOperation: Fetched<void>;
  downloadKYWS06bReportOperation: Fetched<void>;
}

const initialState: InvoicingReportsState = {
  downloadOhioWeeklyNumbersOperation: createFetched(),
  downloadOhioQuarterlyOperation: createFetched(),
  ohioQuarterlyReport: createFetched(),
  weeklyNumbersReport: createFetched(),
  ohioElectronicInstantBingoReport: createFetched(),
  downloadOhioElectronicInstantBingoReportOperation: createFetched(),
  downloadNorthDakotaInvoicesReportOperation: createFetched(),
  downloadDistributorDealsDeliveredReportOperation: createFetched(),
  downloadNorthDakotaGamesOperationUpdated: createFetched(),
  downloadVirginiaSalesTaxOperation: createFetched(),
  downloadNhLucky7MonthlyFinancialReportOperation: createFetched(),
  downloadKYWS06bReportOperation: createFetched(),
};

export const invoicingReportsSlice = createSlice({
  name: 'invoicingReports',
  initialState,
  reducers: {
    downloadOhioWeeklyNumbersOperationUpdated: createFetchedSetter('downloadOhioWeeklyNumbersOperation'),
    downloadOhioQuarterlyOperationUpdated: createFetchedSetter('downloadOhioQuarterlyOperation'),
    ohioQuarterlyReportUpdated: createFetchedSetter('ohioQuarterlyReport'),
    weeklyNumbersReportUpdated: createFetchedSetter('weeklyNumbersReport'),
    dimissWeeklyNumbersReport: createFetchedDismisser('weeklyNumbersReport'),
    ohioElectronicInstantBingoReportUpdated: createFetchedSetter('ohioElectronicInstantBingoReport'),
    downloadOhioElectronicInstantBingoReportOperationUpdated: createFetchedSetter('downloadOhioElectronicInstantBingoReportOperation'),
    downloadNorthDakotaInvoicesReportOperationUpdated: createFetchedSetter('downloadNorthDakotaInvoicesReportOperation'),
    downloadDistributorDealsDeliveredReportOperationUpdated: createFetchedSetter('downloadDistributorDealsDeliveredReportOperation'),
    downloadNorthDakotaGamesOperationUpdated: createFetchedSetter('downloadNorthDakotaGamesOperationUpdated'),
    downloadVirginiaSalesTaxOperationUpdated: createFetchedSetter('downloadVirginiaSalesTaxOperation'),
    downloadNhLucky7MonthlyFinancialReportOperationUpdated: createFetchedSetter('downloadNhLucky7MonthlyFinancialReportOperation'),
    downloadKYWS06bReportOperationUpdated: createFetchedSetter('downloadKYWS06bReportOperation'),
  },
});

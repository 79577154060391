import { Button, Card, Icon, Select, message } from 'antd';
import { useEffect } from 'react';
import { updateBreadcrumbs } from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import AppUtilityService from '../../../common/services/AppUtilityService';
import { LoadState } from '../../../common/store/fetched';
import { downloadNorthDakotaGames } from '../../actions/invoicingReports/downloadJurisdictionGames';

function NorthDakotaGamesReportPage() {
  const dispatch = useAppDispatch();
  const isDownloadingInProgress = useAppSelector(
    (state) =>
      state.InvoicingReportsReducer.downloadNorthDakotaInvoicesReportOperation.loadState === LoadState.InProgress
  );
  const hasDownloadingFailed = useAppSelector(
    (state) => state.InvoicingReportsReducer.downloadNorthDakotaInvoicesReportOperation.loadState === LoadState.Failed
  );

  useEffect(() => {
    const breadcrumbsList = [
      { name: 'Reports', link: '/Reports' },
      { name: 'North Dakota Games List', link: null },
    ];
    const breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
    dispatch(updateBreadcrumbs(breadcrumbs));
  }, [dispatch]);

  useEffect(() => {
    if (hasDownloadingFailed) {
      message.error('Failed to download report');
    }
  }, [hasDownloadingFailed]);

  const downloadExcel = () => {
    dispatch(downloadNorthDakotaGames());
  };

  return (
    <div className="pg-container page-container">
      <Card className="no-header-border" bordered={false} title="North Dakota Games List">
        <Button type="primary" className="ml-8" onClick={downloadExcel} loading={isDownloadingInProgress}>
          <Icon type="download" style={{ fontSize: '16px' }} /> Download as Excel
        </Button>
      </Card>
    </div>
  );
}

export default NorthDakotaGamesReportPage;

import { createApiCaller } from '../../../common/services/ApiService';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { fetchDataWithActionCreator, LoadState } from '../../../common/store/fetched';
import { IInvoice } from '../../reducers/billing/billing.models';
import { resendInvoiceOperationUpdated } from './slice-actions';
import { processFailureReason } from '../../../common/store/failure';
import { message, Modal } from 'antd';
import { getInvoice } from './getInvoice';

export const resendInvoice = (invoice: IInvoice) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {

    if (!(invoice.invoicingContact && invoice.invoicingContact.email)) {
      Modal.warn({
        title: `Contact information missing.`,
        content: 'Invoice contact is missing or doesn’t have an e-mail address set.'
      })
      return;
    }

    await fetchDataWithActionCreator(
      resendInvoiceOperationUpdated,
      dispatch,
      createApiCaller(api => api.postData<void>(
        `/invoices/${invoice.invoiceId.toFixed(0)}/resend`,
        undefined)),
      processFailureReason,
      false,
    );

    const operation = getState().BillingReducer.resendInvoiceOperation;
    if (operation.loadState === LoadState.Failed) {
      message.error(
        `There was a problem with resending the invoice.
           Please try again or refresh the page.`,
        5,
      );
    } else if (operation.loadState === LoadState.Success) {
      message.success(`Sending invoice e-mail`);
    }
    dispatch(getInvoice(invoice.invoiceId, true));
  };
}

import React from 'react';
import { LocationInvoicingConfigItem } from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';
import { FormComponentProps } from 'antd/lib/form/Form';
import { LocationInvoicingConfigEditFormFields } from './locationInvoicingConfigEditFormFields';
import { Col, Form, Row, Switch } from 'antd';

export type Props = FormComponentProps<LocationInvoicingConfigEditFormFields> & {
  item: LocationInvoicingConfigItem;
  locationIndex?: number;
  children?: React.ReactNode | React.ReactNodeArray;
};

const InvoicingEnabledEditor = ({ item, locationIndex, children, form }: Props) => {
  const {
    getFieldDecorator,
    getFieldValue,
    setFieldsValue,
  } = form;
  const invoicingEnabledFieldName = locationIndex == null ? 'invoicingEnabled' : `invoicingEnabled_${locationIndex}`;

  return (
    <>
      <Row gutter={16}>
        <Col>
          <Form.Item label="Invoicing Enabled">
            {getFieldDecorator(invoicingEnabledFieldName, {
              initialValue: item.invoicingEnabled,
              valuePropName: 'checked',
            })(
              <Switch onChange={value => setFieldsValue({ [invoicingEnabledFieldName]: value })}/>,
            )}
          </Form.Item>
        </Col>
      </Row>
      {
        getFieldValue(invoicingEnabledFieldName)
        &&
        children
      }
    </>
  );
}

export default InvoicingEnabledEditor;

import { createApiCaller } from '../../../common/services/ApiService';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { fetchDataWithActionCreator, LoadState } from '../../../common/store/fetched';
import { IAddUpdateInvoiceAdjustmentRequest } from '../../reducers/billing/billing.models';
import { addAdjustmentOperationUpdated } from './slice-actions';
import { processFailureReason } from '../../../common/store/failure';
import { message } from 'antd';
import { getInvoice } from './getInvoice';

export const addInvoiceAdjustment = (invoiceId: number, request: IAddUpdateInvoiceAdjustmentRequest) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    await fetchDataWithActionCreator(
      addAdjustmentOperationUpdated,
      dispatch,
      createApiCaller(api => api.postData<void>(
        `/invoices/${invoiceId.toFixed(0)}/adjustments`,
        request,
      )),
      processFailureReason,
      false,
    );

    const operation = getState().BillingReducer.addAdjustmentOperation;
    if (operation.loadState === LoadState.Failed) {
      if (
        operation.failureReason.statusCode === 400
        && operation.failureReason.response?.data?.errorCode === 'InvalidStatus'
      ) {
        message.warning('The invoice is already approved and cannot be modified.');
        dispatch(getInvoice(invoiceId, true));
      } else {
        message.error(
          `There was a problem with adding the invoice adjustment. Please try again or refresh the page.`,
          5,
        );
      }
    } else if (operation.loadState === LoadState.Success) {
      message.success(`Successfully added invoice adjustment.`);
      dispatch(getInvoice(invoiceId, true));
    }
  };
}

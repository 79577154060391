import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { loadLocationsDropdown } from '../../actions/dropdown/DropdownActions';
import { DropdownComponent } from '../../components/dropdown/LocationDropdownComponent';
import { RootState } from '../../store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
    return {
        datasource: state.DropdownReducer.loadLocationsDropdownDataSuccess,
        datasourceError: state.DropdownReducer.loadLocationsDropdownDataError,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadData: bindActionCreators(loadLocationsDropdown, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DropdownComponent)

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Fetched, createFetched } from '../../../common/store/fetched';
import {
  IGame,
  IGetLiveGamesParam,
  IGameView,
} from '../games/games.models'
import { createFetchedSetter } from '../../../common/store/fetched.slices';
import { FailureData } from '../../../common/store/failure';

export interface IGamesState {
  liveGamesParam: IGetLiveGamesParam;
  gamesList: Fetched<IGame[]>;
  saveGame: Fetched<IGame>;
  saveEditGame: Fetched<IGame>;
  deleteGame: Fetched<boolean, FailureData>;
  gameView: Fetched<IGameView>;
}

export const defaultLiveGameParam: IGetLiveGamesParam = {
  isLive: true,
}

const initialState: IGamesState = {
  liveGamesParam: defaultLiveGameParam,
  gamesList: createFetched(),
  saveGame: createFetched(),
  saveEditGame: createFetched(),
  deleteGame: createFetched(),
  gameView: createFetched(),
};

export const gamesSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setLiveGamesParam: (state, action: PayloadAction<IGetLiveGamesParam>) => {
      state.liveGamesParam = action.payload;
    },
    gamesListUpdated: createFetchedSetter('gamesList'),
    saveGamePayload: createFetchedSetter('saveGame'),
    saveEditGamePayload: createFetchedSetter('saveEditGame'),
    deleteGamePayload: createFetchedSetter('deleteGame'),
    gameViewUpdated: createFetchedSetter('gameView'),
  },  
});
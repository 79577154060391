
import * as React from "react"
import { Row, Col, Spin, Modal } from "antd"
import { IGame, IFeaturedGame, IQuarterCloseSelectedBet } from '../../reducers/quarterClose/QuarterCloseReducer'
import { hashHistory } from "react-router"
import * as Constants from '../../../common/AppConstants'
const styles = require('../../../common/styles/GameSelectStyles.css')
const checkoutButton = require("../../../images/Button_Checkout.png")
const checkoutDisabledButton = require("../../../images/Button_Checkout_Disabled.png")
const checkmarkImage = require("../../../images/Checkmark.png")

export interface IQuarterCloseSelectGamesPageProps {
    actions?: {
        loadGames(gameSelectionWindowId: number): void;
        updateSelectedGameID(gameID: number): void;
        updateSelectedRemoveGame(gameID: number): void;
    },
    games: IGame[];
    loadingGames: boolean;
    loadingFeaturedGames: boolean;
    featuredGames: IFeaturedGame[];
    selectedSetup: IQuarterCloseSelectedBet[];
    gameSelectionWindowId: number,
}

export interface IQuarterCloseSelectGamesPageState {
    modalVisible: boolean;
}

export class QuarterCloseSelectGamesPage extends React.Component<IQuarterCloseSelectGamesPageProps, IQuarterCloseSelectGamesPageState> {

    constructor(props: IQuarterCloseSelectGamesPageProps) {
        super(props);
        this.state = {
            modalVisible: false,
        }
    }

    componentDidMount() {
      this.props.actions.loadGames(this.props.gameSelectionWindowId);
    }

    render() {

        const onGameSelected = (gameID: number) => {
            this.props.actions.updateSelectedGameID(gameID)
            hashHistory.push('/quarterClose/gameDetails')
        }

        const isGameSelected = (gameID: number): boolean => this.props.selectedSetup.filter(p => p.gameID === gameID).length > 0

        const gameCount = new Set(this.props.selectedSetup.map(p => p.gameID)).size
        const maxGameSelectionsNumber = Constants.default.ndMaxGameSelectionsNumber
        const maxGameSelectionsStringLower = Constants.default.ndMaxGameSelectionsString.toLowerCase()

        return (
            <div className="pg-container page-container gameSelectBackground">
                <Row type="flex" justify="center" align="middle">
                    <Col >
                        <span style={{ textAlign: 'center' }} className="gameSelectHeader">FEATURED GAMES</span>
                    </Col>
                </Row>
                <Row type="flex" justify="center" align="middle">
                    <Col>
                        {this.props.games.filter(g => g.featuredPosition === "left").map((game) => (
                            <img key={game.gameID} style={{ cursor: 'pointer' }} width="722" height="521" src={game.featureUrl} alt={game.name} onClick={() => { onGameSelected(game.gameID) }} />
                        ))}
                    </Col>
                    <Col>
                        {this.props.games.filter(g => g.featuredPosition === "right").map((game) => (
                            <img key={game.gameID} style={{ cursor: 'pointer' }} width="722" height="521" src={game.featureUrl} alt={game.name} onClick={() => { onGameSelected(game.gameID) }} />
                        ))}
                    </Col>
                </Row>
                <Row type="flex" justify="center" align="middle">
                    <Col>
                        <span className="gameSelectText" style={{ paddingBottom: "20px" }}>SELECT A GAME TO VIEW DETAILS</span>
                    </Col>
                </Row>
                <Row type="flex" justify="center" align="middle">
                    <Col>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {this.props.games.length === 0 && <Spin size="large" />}
                            {this.props.games.map((game) => (
                                <section style={{ position: "relative" }} key={game.gameID}>
                                    {
                                        isGameSelected(game.gameID) &&
                                        <img className="gameSelectCheckMark" onClick={() => { this.props.actions.updateSelectedRemoveGame(game.gameID) }} src={checkmarkImage} />
                                    }
                                    <img className={"gameIcon"} src={game.iconUrl} alt={game.displayName} onClick={() => { onGameSelected(game.gameID) }} />
                                </section>

                            ))}
                        </div>
                    </Col>
                </Row>
                <Row type="flex" justify="center" align="middle">
                    <Col>
                        <Row type="flex" justify="end" align="middle">
                            <Col>
                                <span className="gameSelectNumberText"> {gameCount} of {maxGameSelectionsNumber} &nbsp;</span>
                                <span className="gameSelectText"> GAMES SELECTED</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row type="flex" justify="end" align="middle">
                    <Col>
                        {this.props.selectedSetup.length > 0 &&
                            <>
                                <img className="navImageButton" onClick={() => {
                                    if (this.props.selectedSetup.length < maxGameSelectionsNumber)
                                        this.setState({ modalVisible: true })
                                    else
                                        hashHistory.push('/quarterClose/selectionReview')
                                }} src={checkoutButton} alt="Checkout" />
                                <Modal
                                    title="More Games Can Be Selected"
                                    visible={this.state.modalVisible}
                                    onOk={() => { hashHistory.push('/quarterClose/selectionReview') }}
                                    onCancel={() => { this.setState({ modalVisible: false }) }}
                                    okText="Checkout"
                                    cancelText="Cancel"
                                >
                                    <p>Warning, you have less than {maxGameSelectionsStringLower} games chosen, are you sure you want to continue?</p>
                                </Modal>
                            </>
                        }
                        {this.props.selectedSetup.length === 0 &&
                            <img className="navImageButton" src={checkoutDisabledButton} alt="Checkout" />
                        }
                    </Col>
                </Row>
            </div>
        )
    }
}

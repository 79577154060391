
import * as React from "react";
import { isEqual, isEmpty, get } from "lodash";
import { Link } from "react-router";
import AppUtilityService from "../../../common/services/AppUtilityService";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";

import { Table } from "antd";

export class LocationsListTableComponent extends React.Component<ILocationsListProps, ILocationsListState> {

  constructor(props: ILocationsListProps) {
    super(props);
    this.state = {
      pagination: {
        page: 1,
        pageSize: 30,
        total: 5
      },
      sortInfo: null,
      isLoading: true,
      locationsList: [],
      filterParams: this.props.filterParams || null
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }

    if (!this.props.locationListFilters) {  // Check if preset filters exist, if not load list, else preset filters set in parent component
      this.loadLocationsList();
    }
  }

  componentWillReceiveProps(nextProps: ILocationsListProps) {
    if (nextProps.locationsListSuccess && !isEqual(this.props.locationsListSuccess, nextProps.locationsListSuccess)) {
      let pagination = { ...this.state.pagination };
      var responseData: any = nextProps.locationsListSuccess;
      pagination.total = responseData.total;
      this.props.getTotalLocationsCallback(pagination.total);
      this.setState({ locationsList: responseData.locations, pagination, isLoading: false })
    }

    if (nextProps.locationsListError && !isEqual(this.props.locationsListError, nextProps.locationsListError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.locationsListError);
    }

    if (!isEqual(this.props.filterParams, nextProps.filterParams)) {
      let pagination = { ...this.state.pagination };
      pagination.page = nextProps.filterParams ? nextProps.filterParams.page : 1;
      this.setState({ pagination, filterParams: nextProps.filterParams }, () => {
        this.loadLocationsList();
      })
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }


  render() {
    const { isLoading, pagination } = this.state;

    const tableColumns = [
      {
        title: 'Location ID',
        dataIndex: 'id',
        key: 'id',
        sorter: true,
        sortOrder: AppUtilityService.getTableColumnSortOrder(this.state.sortInfo, 'id'),
      }, {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        sorter: true,
        sortOrder: AppUtilityService.getTableColumnSortOrder(this.state.sortInfo, 'location'),
        render: (text, record) => {
          return (
            <Link to={"/locations/" + record.id}>{text}</Link>
          )
        }
      }, {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        sorter: true,
        sortOrder: AppUtilityService.getTableColumnSortOrder(this.state.sortInfo, 'type'),
        className: "hidden-sm",
      }, {
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        sorter: true,
        sortOrder: AppUtilityService.getTableColumnSortOrder(this.state.sortInfo, 'startDate'),
        className: "hidden-sm",
        render: (text, record) => {
          return AppUtilityService.formatDate(text)
        }
      }
    ];

    return (
      <Table
        rowKey={(record, index) => { return (record.id).toString() }}
        loading={isLoading}
        dataSource={this.state.locationsList}
        columns={tableColumns}
        pagination={{ current: this.state.pagination.page, pageSize: pagination.pageSize, total: pagination.total }}
        onChange={this.handleTableChange}
        locale={
          {
            emptyText: <EmptyStateComponent title="No Locations" />
          }
        }
      />
    )
  }

  /**
   * Triggered when table pagination, sortInfo or filter changes
   */
  handleTableChange = (updatedPagination, filters, sorter) => {
    let pagination = { ...this.state.pagination };
    let sortInfo = { ...this.state.sortInfo };
    pagination.page = updatedPagination.current
    if (sorter && !isEmpty(sorter)) {
      sortInfo = {
        sortDir: sorter.order,
        propertyName: sorter.field
      }
    } else {
      sortInfo = null;
    }
    this.setState({ pagination, sortInfo }, () => {
      this.loadLocationsList()
    })
  }

  loadLocationsList = () => {
    this.setState({ isLoading: true });
    let { pagination, sortInfo, filterParams } = this.state;
    let params = {
      page: pagination.page,
      pageSize: pagination.pageSize,
      sortDir: sortInfo && sortInfo.sortDir || null,
      propertyName: sortInfo && sortInfo.propertyName || null,
      searchText: get(filterParams, 'searchText') || "",
      type: get(filterParams, 'type') || 'ALL',
      zoneId: get(filterParams, 'zoneId') || null,
      showDemoLocations: get(filterParams, 'showDemoLocations'),
      showDecommissioned: get(filterParams, 'showDecommissioned'),
    }
    this.props.actions.loadLocationsListData(params);
    this.props.actions.updateLocationListFilters(params);
  }



}

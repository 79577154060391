import { locationInvoicingConfigSlice } from '../../reducers/locationInvoicingConfig/LocationInvoicingConfigReducer';

export const {
  dismissBillingConfigurationList,
  locationInvoicingConfigListUpdated,
  setLocationInvoicingConfigSearchParams,
  updateLocationInvoicingConfigOperation,
  locationCharityDistributorDiscountsUpdated,
  dismissLocationCharityDistributorDiscounts,
  locationCharitiesUpdated,
  dismissGetLocationCharities,
  locationInvoicingConfigDetailsUpdated,
  dismissLocationInvoicingConfigDetails,
  taxRatesUpdated,
  dismissTaxRates,
  saveTaxRateOperationUpdated,
  dismissSaveTaxRateOperation,
} = locationInvoicingConfigSlice.actions;

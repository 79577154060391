import { createApiCaller } from '../../../common/services/ApiService';
import { invoiceListUpdated } from './slice-actions';
import { IPagedInvoice } from '../../reducers/billing/billing.models';
import { fetchDataWithActionCreator } from '../../../common/store/fetched';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { processFailureReason } from '../../../common/store/failure';

export const getInvoices = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const params = getState().BillingReducer.invoiceListParams;
    return await fetchDataWithActionCreator(
      invoiceListUpdated,
      dispatch,
      createApiCaller(api => api.getData<IPagedInvoice>('/invoices/search', params)),
      processFailureReason,
      true,
    );
  };
}

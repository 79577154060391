import React, { useCallback, useEffect } from 'react';
import { Badge, Card } from 'antd';
import AppUtilityService from '../../../common/services/AppUtilityService';
import { updateBreadcrumbs } from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import LocationReportsFilterContainer
  from '../../containers/reports/locationStatisticsReports/LocationReportsFilterContainer';
import BillingConfigurationTable from './BillingConfigurationTable';
import { getLocationInvoicingConfigs } from '../../actions/locationInvoicingConfig/getLocationInvoicingConfigs';
import { LocationReportsFilter } from '../reports/locationStatisticsReports/LocationReportsFilterComponent';
import TextFilter from '../billing/TextFilter';
import InvoicingEnabledFilter from './InvoicingEnabledFilter';
import switchFilterFactory from '../billing/SwitchFilterFactory';
import { LocationReportFilters } from '../reports/Reports';
import { setLocationInvoicingConfigSearchParams } from '../../actions/locationInvoicingConfig/slice-actions';
import { LoadState } from '../../../common/store/fetched';
import { Link } from 'react-router';

const filters: LocationReportsFilter[] = [
  { name: 'locationIdOrName', component: TextFilter },
  { name: 'isInvoicingEnabled', component: InvoicingEnabledFilter, defaultValue: '' },
  { name: 'showDemoLocations', component: switchFilterFactory('Demo') },
  { name: 'showDecommissioned', component: switchFilterFactory('Decommissioned') },
];

const BillingConfiguration = () => {
  const dispatch = useAppDispatch();
  const params = useAppSelector(state => state.LocationInvoicingConfigReducer.locationInvoicingConfigSearchParams);
  const locationInvoicingConfigList = useAppSelector(
    state => state.LocationInvoicingConfigReducer.locationInvoicingConfigList);

  useEffect(
    () => {
      dispatch(getLocationInvoicingConfigs());
    },
    [dispatch, params]);

  useEffect(
    () => {
      const breadcrumbsList = [
        { name: 'Billing', link: '/billing' },
        { name: 'Configuration', link: null },
      ];
      const extraContent = locationInvoicingConfigList.loadState === LoadState.Success
        ? <>
            <span className="font-12 t-grey-dark ml-8 mr-8">
              <Badge dot className="mr-8" style={{ top: '-1px', background: '#A6ADC9' }} />
              {locationInvoicingConfigList.data.total} Total
            </span>
            <span className="font-12 ml-8">
              <Link className="link-primary" to="/billing/configuration/taxes">Taxes and Fees</Link>
            </span>
          </>
        : null;
      const breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList, extraContent);
      dispatch(updateBreadcrumbs(breadcrumbs));
    },
    [dispatch, locationInvoicingConfigList]);

  const applyFilters = useCallback(
    (newParams: any) => {
      const filterValues = (newParams as LocationReportFilters).filterValues;
      dispatch(setLocationInvoicingConfigSearchParams({
        ...params,
        pageNo: 1,
        zoneId: newParams.zoneId === 0 ? undefined : newParams.zoneId,
        locationId: newParams.locationId === 0 ? undefined : newParams.locationId,
        locationIdOrName: filterValues.locationIdOrName ? filterValues.locationIdOrName.toString() : undefined,
        isInvoicingEnabled: filterValues.isInvoicingEnabled === '' ? undefined : !!filterValues.isInvoicingEnabled,
        showDemoLocations: !!filterValues.showDemoLocations,
        showDecommissioned: !!filterValues.showDecommissioned,
      }));
    },
    [dispatch, params]);

  return (
    <>
      <Card
        style={{ paddingTop: '12px' }}
        className="reports-filter-card content-card no-header-border"
        bordered={false}
      >
        <LocationReportsFilterContainer
          filters={filters}
          disableCharityFilter
          disableDistributorFilter
          applyFiltersCallback={applyFilters}
        />
      </Card>
      <BillingConfigurationTable />
    </>
  );
}

export default BillingConfiguration;

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as CharityActions from '../../actions/charities/CharityActions';
import { CharityDetailsComponent } from '../../components/charities/CharityDetailsComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadCharityDetailsSuccess: state.CharityReducer.loadCharityDetailsSuccess,
    loadCharityDetailsError: state.CharityReducer.loadCharityDetailsError,

    updateCharityDetailsSuccess: state.CharityReducer.updateCharityDetailsSuccess,
    updateCharityDetailsError: state.CharityReducer.updateCharityDetailsError,

    deleteCharitySuccess: state.CharityReducer.deleteCharitySuccess,
    deleteCharityError: state.CharityReducer.deleteCharityError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(CharityActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CharityDetailsComponent)

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { loadZoneLinkedLocationsAutocomplete } from '../../actions/autocomplete/AutocompleteActions';
import { DropdownComponent } from '../../../common/components/dropdown/DropdownComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
    return {
        datasource: state.AdminAutocompleteReducer.loadZoneLinkedLocationsSuccess,
        datasourceError: state.AdminAutocompleteReducer.loadZoneLinkedLocationsError,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadData: bindActionCreators(loadZoneLinkedLocationsAutocomplete, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DropdownComponent)

import { Card, Col, Row } from 'antd';
import AppUtilityService from '../../../common/services/AppUtilityService';
import { IInvoice } from '../../reducers/billing/billing.models';

interface IInvoiceSummaryProps {
  invoice: IInvoice;
}

const renderSummary = (invoice: IInvoice) => {
  return (
    <Card
      title="Summary"
      className="content-card no-header-border no-card-padding summary-list"
      bordered={false}
    >
      <Row>
        <Col span={12}>
          Items Amount Due
          <small> ({invoice.amountDuePercentage}% of Cash&nbsp;Net)</small>
        </Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.itemsTotalAmountDue)}</Col>
      </Row>
      {invoice.totalNetTaxRateValue !== null &&
      <Row>
        <Col span={12}>
          Net Tax
        </Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.totalNetTaxRateValue)}</Col>
      </Row>
      }
      <Row>
        <Col span={12}>Adjustments</Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.totalAdjustmentsAmount)}</Col>
      </Row>
      <Row className='mt-8'>
        <Col span={12}>Invoice Amount Due</Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.invoiceAmountDue)}</Col>
      </Row>
      <Row>
        <Col span={12}>State Fee</Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.stateFeeDue)}</Col>
      </Row>
      {invoice.totalGrossTaxRateValue !== null &&
      <Row>
        <Col span={12}>Sales Tax</Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.totalGrossTaxRateValue)}</Col>
      </Row>
      }
      <Row style={{ fontWeight: 'bold' }}>
        <Col span={12}>Total Amount Due</Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.totalAmountDue)}</Col>
      </Row>
      <Row>
        <Col span={12}>Forward Credit Amount</Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.forwardCreditAmount)}</Col>
      </Row>
    </Card>
  );
}

const renderKYSpecificSummary = (invoice: IInvoice) => {
  return (
    <Card
      title="Summary"
      className="content-card no-header-border no-card-padding summary-list"
      bordered={false}
    >
      <Row>
        <Col span={12}>Cash Net</Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.itemsTotalCashNet)}</Col>
      </Row>
      <Row>
        <Col span={12}>Charitable Gaming Fee Discount</Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.totalCashNetDeduction)}</Col>
      </Row>
      <Row>
        <Col span={12}>Net Win</Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.itemsTotalCashNet - invoice.totalCashNetDeduction)}</Col>
      </Row>
      <Row>
        <Col span={12}>
          Subtotal
          <small> ({invoice.amountDuePercentage}% of Net&nbsp;Win)</small>
        </Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.itemsTotalAmountDue)}</Col>
      </Row> 
      {invoice.totalNetTaxRateValue !== null &&
      <Row>
        <Col span={12}>
          Net Tax
        </Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.totalNetTaxRateValue)}</Col>
      </Row>
      }
      <Row>
        <Col span={12}>Adjustments</Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.totalAdjustmentsAmount)}</Col>
      </Row>
      <Row className='mt-8'>
        <Col span={12}>Invoice Amount Due</Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.invoiceAmountDue)}</Col>
      </Row>
      <Row>
        <Col span={12}>Sale of Tickets</Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.ticketsSoldFee)}</Col>
      </Row>
      <Row>
        <Col span={12}>Sales Tax on Tickets</Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.ticketsSoldFeeSalesTax)}</Col>
      </Row>
      <Row>
        <Col span={12}>Fee Discount</Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.feeDiscount)}</Col>
      </Row>
      {invoice.totalGrossTaxRateValue !== null &&
      <Row>
        <Col span={12}>Sales Tax</Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.totalGrossTaxRateValue)}</Col>
      </Row>
      }
      <Row style={{ fontWeight: 'bold' }}>
        <Col span={12}>Total Amount Due</Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.totalAmountDue)}</Col>
      </Row>
      <Row>
        <Col span={12}>Forward Credit Amount</Col>
        <Col span={6} className='text-right'>{formatAmount(invoice.forwardCreditAmount)}</Col>
      </Row>
    </Card>
  )
}

export function InvoiceSummary({ invoice }: IInvoiceSummaryProps) {
  return invoice.invoicingSystemId === 4 ? renderKYSpecificSummary(invoice) : renderSummary(invoice);
}

function formatAmount(value: number) {
  return (
    <span className={Math.round(+value * 100) < 0 ? 'text-negative' : ''}>
      {AppUtilityService.formatAmount(value)}
    </span>
  );
}

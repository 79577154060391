import { Fetched, createFetched, updateFetched } from '../../../common/store/fetched';
import { IQuarterCloseLocationSetupDetails } from '../reports/ReportsReducer'

export const QUARTERCLOSE_SELECTED_LOCATIONS_UPDATE = 'QUARTERCLOSE_SELECTED_LOCATIONS_UPDATE'
export const QUARTERCLOSE_SELECTED_GAMESELECTIONWINDOWID_UPDATE = 'QUARTERCLOSE_SELECTED_GAMESELECTIONWINDOWID_UPDATE'
export const QUARTERCLOSE_SELECTED_WINDOWGAMES_UPDATE = 'QUARTERCLOSE_SELECTED_WINDOWGAMES_UPDATE'
export const QUARTERCLOSE_SELECTED_SETUP_UPDATE = 'QUARTERCLOSE_SELECTED_SETUP_UPDATE'
export const QUARTERCLOSE_SELECTED_SETUP_REMOVE_GAME = 'QUARTERCLOSE_SELECTED_SETUP_REMOVE_GAME'
export const QUARTERCLOSE_PAYOUT_IN_MULTIPLES_OF_25_UPDATE = 'QUARTERCLOSE_PAYOUT_IN_MULTIPLES_OF_25_UPDATE'
export const QUARTERCLOSE_SELECTED_GAME_UPDATE = 'QUARTERCLOSE_SELECTED_GAME_UPDATE'
export const QUARTERCLOSE_LOAD_LOCATIONS_REQUEST = 'QUARTERCLOSE_LOAD_LOCATIONS_REQUEST'
export const QUARTERCLOSE_LOAD_LOCATIONS_SUCCESS = `QUARTERCLOSE_LOAD_LOCATIONS_SUCCESS`
export const QUARTERCLOSE_LOAD_LOCATIONS_FAILURE = `QUARTERCLOSE_LOAD_LOCATIONS_FAILURE`
export const QUARTERCLOSE_LOAD_GAMES_REQUEST = 'QUARTERCLOSE_LOAD_GAMES_REQUEST'
export const QUARTERCLOSE_LOAD_GAMES_SUCCESS = `QUARTERCLOSE_LOAD_GAMES_SUCCESS`
export const QUARTERCLOSE_LOAD_GAMES_FAILURE = `QUARTERCLOSE_LOAD_GAMES_FAILURE`
export const QUARTERCLOSE_LOAD_FEATUREDGAMES_FAILURE = `QUARTERCLOSE_LOAD_FEATUREDGAMES_FAILURE`
export const QUARTERCLOSE_LOAD_FEATUREDGAMES_REQUEST = `QUARTERCLOSE_LOAD_FEATUREDGAMES_REQUEST`
export const QUARTERCLOSE_LOAD_FEATUREDGAMES_SUCCESS = `QUARTERCLOSE_LOAD_FEATUREDGAMES_SUCCESS`
export const QUARTERCLOSE_LOAD_GAME_FLARES_FAILURE = `QUARTERCLOSE_LOAD_GAME_FLARES_FAILURE`
export const QUARTERCLOSE_LOAD_GAME_FLARES_REQUEST = `QUARTERCLOSE_LOAD_GAME_FLARES_REQUEST`
export const QUARTERCLOSE_LOAD_GAME_FLARES_SUCCESS = `QUARTERCLOSE_LOAD_GAME_FLARES_SUCCESS`
export const QUARTERCLOSE_POST_LOCATION_SETUP_FAILURE = `QUARTERCLOSE_POST_LOCATION_SETUP_FAILURE`
export const QUARTERCLOSE_POST_LOCATION_SETUP_REQUEST = `QUARTERCLOSE_POST_LOCATION_SETUP_REQUEST`
export const QUARTERCLOSE_POST_LOCATION_SETUP_SUCCESS = `QUARTERCLOSE_POST_LOCATION_SETUP_SUCCESS`
export const QUARTERCLOSE_CLEAR_SELECTIONS = `QUARTERCLOSE_CLEAR_SELECTIONS`
export const QUARTERCLOSE_GET_GAME_MEDIA_FAILURE = `QUARTERCLOSE_GET_GAME_MEDIA_FAILURE`
export const QUARTERCLOSE_GET_GAME_MEDIA_REQUEST = `QUARTERCLOSE_GET_GAME_MEDIA_REQUEST`
export const QUARTERCLOSE_GET_GAME_MEDIA_SUCCESS = `QUARTERCLOSE_GET_GAME_MEDIA_SUCCESS`
export const QUARTERCLOSE_GET_LATEST_SETUP_UPDATED = `QUARTERCLOSE_GET_LATEST_SETUP_UPDATED`
export const QUARTERCLOSE_SELECTED_LOCATIONSWITHOUTSETTINGS_REQUEST = 'QUARTERCLOSE_SELECTED_LOCATIONSWITHOUTSETTINGS_REQUEST'
export const QUARTERCLOSE_SELECTED_LOCATIONSWITHOUTSETTINGS_SUCCESS = 'QUARTERCLOSE_SELECTED_LOCATIONSWITHOUTSETTINGS_SUCCESS'
export const QUARTERCLOSE_SELECTED_LOCATIONSWITHOUTSETTINGS_ERROR = 'QUARTERCLOSE_SELECTED_LOCATIONSWITHOUTSETTINGS_ERROR'
export const QUARTERCLOSE_SELECTED_LOCATIONSIMPLEDETAILS_REQUEST = 'QUARTERCLOSE_SELECTED_LOCATIONSIMPLEDETAILS_REQUEST'
export const QUARTERCLOSE_SELECTED_LOCATIONSIMPLEDETAILS_SUCCESS = 'QUARTERCLOSE_SELECTED_LOCATIONSIMPLEDETAILS_SUCCESS'
export const QUARTERCLOSE_SELECTED_LOCATIONSIMPLEDETAILS_ERROR = 'QUARTERCLOSE_SELECTED_LOCATIONSIMPLEDETAILS_ERROR'
export const QUARTERCLOSE_UPDATEBULKLOCATIONSETTINGS_REQUEST = 'QUARTERCLOSE_UPDATEBULKLOCATIONSETTINGS_REQUEST'
export const QUARTERCLOSE_UPDATEBULKLOCATIONSETTINGS_SUCCESS = 'QUARTERCLOSE_UPDATEBULKLOCATIONSETTINGS_SUCCESS'
export const QUARTERCLOSE_UPDATEBULKLOCATIONSETTINGS_ERROR = 'QUARTERCLOSE_UPDATEBULKLOCATIONSETTINGS_ERROR'

export interface ICharity {
    id: number,
    name: string,
}

export interface IPagedQuarterCloseLocation {
    total: number,
    filteredTotal: number,
    locations: IQuarterCloseLocation[],
    locationsMissingWindowGames: string[],
}

export interface IFeaturedGame {
    gameID: number,
    name: string,
    url: string,
}

export interface IGameMedia {
    gameMediaID: number,
    gameID: number, 
    url: string,
    isVideo: boolean,
}

export interface IQuarterCloseGameDetails {
    gameID: number;
    name: string;
    formType: string;
    bets: number[];
    gameDetails: IQuarterCloseGameAdditionalDetails | null;
}

export interface IQuarterCloseGameAdditionalDetails {
    gameType: string;
    jackpot: number;
    payout: number;
    winsMultipleOf25: boolean;
}

export interface IGame {
    gameID: number,
    name: string,
    iconUrl: string,
    theme: string,
    displayName: string,
    extendedPlayFeatures: string,
    featuredPosition: string,
    featureUrl: string,
}

export interface ITab {
    gameID: number,
    formType: string,
    gameType: string,
    payout: number,
    jackpot: number,
    winsMultipleOf25: boolean,
    tabDetails: ITabDetail[],
}

export interface ITabDetail {
    denomination: number,
    idealGrossProceeds: number,
    idealPrizes: number,
    idealNetProceeds: number,
    payoutStructure: string,
}

export interface ILocationSimpleDetail
{
  id: number;
  name: string;
}

export interface IQuarterCloseLocation {
    id: number,
    location: string,
    status: string,
    prodDemoTest: string,
    selectable: boolean,
    gameSelectionWindowId: number,
}

export interface IQuarterCloseSelectedBet {
    gameID: number,
    payoutOptions: string,
    payout: number,
    jackpot: number,
    formType: string,
    bets: string[],
}

export interface IQuarterCloseLocationSetup extends IQuarterCloseSelectedBet {
    locationIds: number[],
    startDate: Date,
}

export interface IQuarterCloseState {
    loadingPagedLocations: boolean,
    pagedLocations: IPagedQuarterCloseLocation,
    selectedLocationIds: number[],
    payoutInMultiplesOf25: boolean,
    loadingGames: boolean,
    games: IGame[],
    loadingFeaturedGames: true,
    featuredGames: IFeaturedGame[],
    selectedGameID?: number,
    loadingGameFlares: boolean,
    gameFlares: ITab[],
    selectedSetup: IQuarterCloseSelectedBet[],
    postingSelectedSetup: boolean,
    gameMedia: IGameMedia[],
    latestSetup: Fetched<IQuarterCloseLocationSetupDetails>,
    locationIdsWithoutSettings: Array<number>,
    locationListSimple: Array<ILocationSimpleDetail>,
    updateLocationSettingsSuccess: boolean,
    gameSelectionWindowId: number | null,
}

const quarterCloseInitialState: IQuarterCloseState =
{
    loadingPagedLocations: true,
    pagedLocations: { total: 0, filteredTotal: 0, locations: [], locationsMissingWindowGames: [] },
    selectedLocationIds: [],
    payoutInMultiplesOf25: false,
    loadingGames: true,
    games: [],
    loadingFeaturedGames: true,
    featuredGames: [],
    selectedGameID: null,
    loadingGameFlares: true,
    gameFlares: [],
    selectedSetup: [],
    postingSelectedSetup: false,
    gameMedia: [],
    latestSetup: createFetched(),
    locationIdsWithoutSettings: [],
    locationListSimple: [],
    updateLocationSettingsSuccess: false,
    gameSelectionWindowId: null,
}

const QuarterCloseReducer = (state: IQuarterCloseState = quarterCloseInitialState, action) => {
    switch (action.type) {

        case QUARTERCLOSE_LOAD_LOCATIONS_REQUEST:
            return { ...state, loadingPagedLocations: action.payload }

        case QUARTERCLOSE_LOAD_LOCATIONS_SUCCESS:
            return { ...state, pagedLocations: action.payload }

        case QUARTERCLOSE_LOAD_GAMES_REQUEST:
            return { ...state, loadingGames: action.payload }

        case QUARTERCLOSE_LOAD_GAMES_SUCCESS:
            return { ...state, games: action.payload }

        case QUARTERCLOSE_SELECTED_LOCATIONS_UPDATE:
            return { ...state, selectedLocationIds: action.payload }

        case QUARTERCLOSE_SELECTED_GAMESELECTIONWINDOWID_UPDATE:
            return { ...state, gameSelectionWindowId: action.payload}

        case QUARTERCLOSE_PAYOUT_IN_MULTIPLES_OF_25_UPDATE:
            return { ...state, payoutInMultiplesOf25: action.payload }

        case QUARTERCLOSE_LOAD_FEATUREDGAMES_REQUEST:
            return { ...state, loadingFeaturedGames: action.payload }

        case QUARTERCLOSE_LOAD_FEATUREDGAMES_SUCCESS:
            return { ...state, featuredGames: action.payload }

        case QUARTERCLOSE_LOAD_GAME_FLARES_REQUEST:
            return { ...state, loadingGameFlares: action.payload }

        case QUARTERCLOSE_LOAD_GAME_FLARES_SUCCESS:
            //filtering out the .75 bets
            const data: ITab[] = action.payload
            const resultSet = data.map(tab => ({...tab, tabDetails: (tab.tabDetails.filter(p => p.denomination !== .75))}))
            return { ...state, gameFlares: resultSet }

        case QUARTERCLOSE_SELECTED_GAME_UPDATE:
            return { ...state, selectedGameID: action.payload }

        case QUARTERCLOSE_SELECTED_SETUP_UPDATE:
            return { ...state, selectedSetup: action.payload }

        case QUARTERCLOSE_SELECTED_SETUP_REMOVE_GAME:
            const newSelectedSetup = [...state.selectedSetup].filter(p => p.gameID !== action.payload);
            // make sure that a game selected earlier is not removed
            const hadGameInSetup = state.latestSetup.data?.games.some(x => x.gameID === action.payload);
            if (hadGameInSetup) return state;
            return { ...state, selectedSetup: newSelectedSetup.filter(p => p.gameID !== action.payload) }

        case QUARTERCLOSE_CLEAR_SELECTIONS:
            return { ...state, selectedSetup: [], selectedLocationIds: [], gameSelectionWindowId: null }

        case QUARTERCLOSE_POST_LOCATION_SETUP_REQUEST:
            return { ...state, postingSelectedSetup: action.payload }

        case QUARTERCLOSE_GET_GAME_MEDIA_REQUEST:
            return { ...state, gameMedia: [] }

        case QUARTERCLOSE_GET_GAME_MEDIA_SUCCESS:
            return { ...state, gameMedia: action.payload }

        case QUARTERCLOSE_GET_LATEST_SETUP_UPDATED:
            return { ...state, latestSetup: updateFetched(state.latestSetup, action) };

        case QUARTERCLOSE_SELECTED_LOCATIONSWITHOUTSETTINGS_SUCCESS:
            return { ...state, locationIdsWithoutSettings: action.payload };

        case QUARTERCLOSE_SELECTED_LOCATIONSIMPLEDETAILS_REQUEST:
            return { ...state, locationListSimple: null };

        case QUARTERCLOSE_SELECTED_LOCATIONSIMPLEDETAILS_SUCCESS:
            return { ...state, locationListSimple: action.payload };

         case QUARTERCLOSE_UPDATEBULKLOCATIONSETTINGS_REQUEST:
             return { ...state, updateLocationSettingsSuccess: null };

         case QUARTERCLOSE_UPDATEBULKLOCATIONSETTINGS_SUCCESS:
             return { ...state, updateLocationSettingsSuccess: action.payload };

        default:
            return state
    }
}

export default QuarterCloseReducer;
import { Dispatch } from 'redux';
import ApiService from "../../../common/services/ApiService";
import {
  ISessionsListRequestParams,
  LOAD_SESSIONS_LIST_REQUEST,
  LOAD_SESSIONS_LIST_SUCCESS,
  LOAD_SESSIONS_LIST_FAILURE,
  IAdnwReportParams,
  LOAD_ADNW_REQUEST,
  LOAD_ADNW_SUCCESS,
  LOAD_ADNW_FAILURE,
  DOWNLOAD_ADNW_UPDATED,
  DOWNLOAD_GAME_SUGGESTIONS_UPDATED,
  LOAD_SATURDAY_SALES_UPDATED,
  DOWNLOAD_SATURDAY_SALES_UPDATED,
} from '../../reducers/reports/ReportsReducer';

import {
  QUARTERCLOSE_LOAD_LOCATION_DETAILS_FAILURE,
  QUARTERCLOSE_LOAD_LOCATION_DETAILS_REQUEST,
  QUARTERCLOSE_LOAD_LOCATION_DETAILS_SUCCESS,
  QUARTERCLOSE_SET_DECOMMISSHIONED,
  QUARTERCLOSE_SET_DEMO,
} from '../../reducers/reports/ReportsReducer'
import { saveFile } from '../../../common/store/fileDownload';
import { fetchData } from '../../../common/store/fetched';
import { processFailureReason } from '../../../common/store/failure';

export function updateLocationReportFilters(params) {
  return {
    type: 'LOCATION_REPORT_FILTER_UPDATE',
    payload: params,
  }
}

export function loadReportsList() {
  return function (dispatch) {
    dispatch({ type: `LOAD_REPORTS_LIST_REQUEST` });
    return ApiService.getData("/lookups/reports")
      .then((successResult) => {
        dispatch({ type: `LOAD_REPORTS_LIST_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_REPORTS_LIST_FAILURE`, payload: errorResult });
      });
  }
}

export function exportDataToExcel(params) {
  return function (dispatch) {
    dispatch({ type: `EXPORT_DATA_TO_EXCEL_REQUEST` });
    return ApiService.getData("/reports/export", params)
      .then((successResult) => {
        dispatch({ type: `EXPORT_DATA_TO_EXCEL_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `EXPORT_DATA_TO_EXCEL_FAILURE`, payload: errorResult });
      });
  }
}

export function exportIPRDataToExcel(params) {
  return function (dispatch) {
    dispatch({ type: `EXPORT_DATA_TO_EXCEL_IPR_REQUEST` });
    return ApiService.getData("/reports/ipr-export", params)
      .then((successResult) => {
        dispatch({ type: `EXPORT_DATA_TO_EXCEL_IPR_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `EXPORT_DATA_TO_EXCEL_IPR_FAILURE`, payload: errorResult });
      });
  }
}


export function loadReconSummaryChart(params) {
  return function (dispatch) {
    const requestId = new Date().getTime();
    dispatch({ type: `LOAD_RECON_SUMMARY_CHART_REQUEST`, payload: { requestId } });
    return ApiService.getData("/reports/summary/chart", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_RECON_SUMMARY_CHART_SUCCESS`, payload: { requestId, data: successResult.data }});
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_RECON_SUMMARY_CHART_FAILURE`, payload: { requestId, errorResult }});
      });
  }
}

export function loadLocationSummaryChart(params) {
  return function (dispatch) {
    const requestId = new Date().getTime();
    dispatch({ type: `LOAD_RECON_LOCATION_SUMMARY_CHART_REQUEST`, payload: { requestId } });
    return ApiService.getData("/reports/summary/locationSummaryChart", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_RECON_LOCATION_SUMMARY_CHART_SUCCESS`, payload: { requestId, data: successResult.data }});
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_RECON_LOCATION_SUMMARY_CHART_FAILURE`, payload: { requestId, errorResult } });
      });
  }
}

export function loadReconSummaryList(params) {
  return function (dispatch) {
    const requestId = new Date().getTime();
    dispatch({ type: `LOAD_RECON_SUMMARY_LIST_REQUEST`, payload: { requestId} });
    return ApiService.getData("/reports/summary/list", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_RECON_SUMMARY_LIST_SUCCESS`, payload: { requestId, data: successResult.data } });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_RECON_SUMMARY_LIST_FAILURE`, payload: { requestId, errorResult }});
      });
  }
}

export function loadReconSummaryInfo(params) {
  return function (dispatch) {
    const requestId = new Date().getTime();
    dispatch({ type: `LOAD_RECON_SUMMARY_INFO_REQUEST`, payload: { requestId } });
    return ApiService.getData("/reports/summaryInfo", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_RECON_SUMMARY_INFO_SUCCESS`, payload: { requestId, data: successResult.data }});
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_RECON_SUMMARY_INFO_FAILURE`, payload: { requestId, errorResult} });
      });
  }
}

export function loadReconSummaryChildList(params) {
  return function (dispatch) {
    const requestId = new Date().getTime();
    dispatch({ type: `LOAD_RECON_SUMMARY_CHILD_LIST_REQUEST`, payload: { requestId }  });
    return ApiService.getData("/reports/summary/details", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_RECON_SUMMARY_CHILD_LIST_SUCCESS`, payload: { requestId, data: successResult.data }});
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_RECON_SUMMARY_CHILD_LIST_FAILURE`, payload: { requestId, errorResult }});
      });
  }
}

export function loadReconSummaryIPRChildList(params) {
  return function (dispatch) {
    const requestId = new Date().getTime();
    dispatch({ type: `LOAD_RECON_SUMMARY_IPR_CHILD_LIST_REQUEST`, payload: { requestId }  });
    return ApiService.getData("/reports/summary/details", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_RECON_SUMMARY_IPR_CHILD_LIST_SUCCESS`, payload: { requestId, data: successResult.data }});
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_RECON_SUMMARY_IPR_CHILD_LIST_FAILURE`, payload: { requestId, errorResult }});
      });
  }
}

export function loadGameTerminalStatusChart(params) {
  return function (dispatch) {
    dispatch({ type: `LOAD_GAME_TERMINAL_STATUS_CHART_REQUEST` });
    return ApiService.getData("/reports/gameTerminalStatus/chart", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_GAME_TERMINAL_STATUS_CHART_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_GAME_TERMINAL_STATUS_CHART_FAILURE`, payload: errorResult });
      });
  }
}

export function loadGameTerminalStatusList(params) {
  return function (dispatch) {
    dispatch({ type: `LOAD_GAME_TERMINAL_STATUS_LIST_REQUEST` });
    return ApiService.getData("/reports/gameTerminalStatus/list", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_GAME_TERMINAL_STATUS_LIST_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_GAME_TERMINAL_STATUS_LIST_FAILURE`, payload: errorResult });
      });
  }
}
/**--------------------------------------------------------------------------------
 * Deals - Available, Completed, InPlay
 ---------------------------------------------------------------------------------*/
export function loadDealsChart(params) {
  return function (dispatch) {
    dispatch({ type: `LOAD_DEALS_CHART_REQUEST` });
    return ApiService.getData("/reports/deals/chart", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_DEALS_CHART_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_DEALS_CHART_FAILURE`, payload: errorResult });
      });
  }
}

export function loadDealsList(params) {
  return function (dispatch) {
    dispatch({ type: `LOAD_DEALS_LIST_REQUEST` });
    return ApiService.getData("/reports/deals/list", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_DEALS_LIST_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_DEALS_LIST_FAILURE`, payload: errorResult });
      });
  }
}

export function exportDealsDataToExcel(params) {
  return function (dispatch) {
    dispatch({ type: `EXPORT_DEALS_DATA_TO_EXCEL_REQUEST` });
    return ApiService.getData("/reports/deals/export", params)
      .then((successResult) => {
        dispatch({ type: `EXPORT_DEALS_DATA_TO_EXCEL_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `EXPORT_DEALS_DATA_TO_EXCEL_FAILURE`, payload: errorResult });
      });
  }
}

export function reportMetricsDataCompare(params) {
  return function (dispatch) {
    dispatch({ type: `REPORT_METRICS_DATA_COMPARE_REQUEST` });
    return ApiService.postData("/reports/dateCompare", params)
      .then((successResult) => {
        dispatch({ type: `REPORT_METRICS_DATA_COMPARE_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `REPORT_METRICS_DATA_COMPARE_FAILURE`, payload: errorResult });
      });
  }
}

export function loadLocationDetails(showDecommissioned: boolean, showDemo: boolean) {
  const params = { showDecommissioned: showDecommissioned, showDemo: showDemo }
  return function (dispatch) {
    dispatch({ type: QUARTERCLOSE_LOAD_LOCATION_DETAILS_REQUEST, payload: true });
    return ApiService.getData("/locations/quarterCloseStatus", params)
      .then((successResult) => {
        dispatch({ type: QUARTERCLOSE_LOAD_LOCATION_DETAILS_REQUEST, payload: false });
        dispatch({ type: QUARTERCLOSE_LOAD_LOCATION_DETAILS_SUCCESS, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: QUARTERCLOSE_LOAD_LOCATION_DETAILS_REQUEST, payload: false });
        dispatch({ type: QUARTERCLOSE_LOAD_LOCATION_DETAILS_FAILURE, payload: errorResult });
      });
  }
}

export function QuarterCloseSetDemo(newValue: boolean) {
  return function (dispatch) {
    dispatch({ type: QUARTERCLOSE_SET_DEMO, payload: newValue });
  }
}

export function QuarterCloseSetDecommisshioned(newValue: boolean) {
  return function (dispatch) {
    dispatch({ type: QUARTERCLOSE_SET_DECOMMISSHIONED, payload: newValue });
  }
}

export function loadSessionsList(params: ISessionsListRequestParams) {
  return async (dispatch: Dispatch) => {
    const requestId = new Date().getTime();
    dispatch({ type: LOAD_SESSIONS_LIST_REQUEST, payload: { requestId } });
    try {
      const result = await ApiService.getData("/reports/sessions/list", params);
      dispatch({ type: LOAD_SESSIONS_LIST_SUCCESS, payload: { requestId, data: result.data } });

    } catch (error) {
      dispatch({ type: LOAD_SESSIONS_LIST_FAILURE, payload: { requestId, error } });
    }
  }
}

export function loadReconSummaryIPRList(params) {
  return function (dispatch) {
    const requestId = new Date().getTime();
    dispatch({ type: `LOAD_RECON_SUMMARY_SINCE_LAST_IPR_LIST_REQUEST`, payload: { requestId} });
    return ApiService.getData("/reports/summary/sincelastiprlist", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_RECON_SUMMARY_SINCE_LAST_IPR_LIST_SUCCESS`, payload: { requestId, data: successResult.data } });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_RECON_SUMMARY_SINCE_LAST_IPR_LIST_FAILURE`, payload: { requestId, errorResult }});
      });
  }
}

export function loadAdnwReport(params: IAdnwReportParams) {
  return async (dispatch: Dispatch) => {
    const requestId = new Date().getTime();
    dispatch({ type: LOAD_ADNW_REQUEST, payload: { requestId } });
    try {
      const result = await ApiService.getData("/reports/adnw", params);
      dispatch({ type: LOAD_ADNW_SUCCESS, payload: { requestId, data: result.data } });

    } catch (error) {
      dispatch({ type: LOAD_ADNW_FAILURE, payload: { requestId, error } });
    }
  }
}

export const downloadAdnwReport = (params: IAdnwReportParams) =>
  async (dispatch: Dispatch) =>
    await fetchData(
      DOWNLOAD_ADNW_UPDATED,
      dispatch,
      async () => {
        const result = await ApiService.downloadFile("/reports/adnw/export", params);
        const fileName = result.headers['x-filename'];
        saveFile({ data: result.data, fileName });
      },
      err => processFailureReason(err),
    );

export const downloadGameSuggestionsReport = () =>
  async (dispatch: Dispatch) =>
   await fetchData(
    DOWNLOAD_GAME_SUGGESTIONS_UPDATED,
    dispatch,
    async () => {
      const result = await ApiService.downloadFile("reports/gameSuggestions/export", null);
      const fileName = result.headers['x-filename'];
      saveFile({ data: result.data, fileName });
    },
    err => processFailureReason(err),
   );

  export function loadSaturdaySales(params: IAdnwReportParams) {
    return async (dispatch: Dispatch) => await fetchData(
      LOAD_SATURDAY_SALES_UPDATED,
      dispatch,
      async () => await ApiService.getData('/reports/saturday-sales', params),
      processFailureReason,
    );
  }

  export const downloadSaturdaySales = (params: IAdnwReportParams) =>
    async (dispatch: Dispatch) =>
      await fetchData(
        DOWNLOAD_SATURDAY_SALES_UPDATED,
        dispatch,
        async () => {
          const result = await ApiService.downloadFile('/reports/saturday-sales/excel', params);
          const fileName = result.headers['x-filename'];
          saveFile({ data: result.data, fileName });
        },
        err => processFailureReason(err),
      );

import React from 'react';
import { TicketSalesByDateReportItem } from '../../reducers/reporting/reporting.models';
import { Col, Row, Tabs } from 'antd';
import TicketSalesPayoutPercentageChart from './TicketSalesPayoutPercentageChart';
import TicketSalesRevenueChart from './TicketSalesRevenueChart';

export type Props = {
  data: TicketSalesByDateReportItem[];
};

const TicketSalesByDateCharts = ({ data }: Props) => (
  <Tabs className="card-tabs" defaultActiveKey="1">
    <Tabs.TabPane tab="Data graph" key="1">
      <div>
        <h2>Graphical representation of selected time period</h2>
        <Row gutter={24}>
          <Col xl={{ span: 8, offset: 4 }}>
            <TicketSalesPayoutPercentageChart data={data}/>
          </Col>
          <Col xl={{ span: 8, offset: 0 }}>
            <TicketSalesRevenueChart data={data}/>
          </Col>
        </Row>
        <br/>
        <br/>
      </div>
    </Tabs.TabPane>
  </Tabs>
)

export default TicketSalesByDateCharts;

import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { LoadState } from '../../../common/store/fetched';
import { Card, Layout, Spin } from 'antd';
import AppUtilityService from '../../../common/services/AppUtilityService';
import { updateBreadcrumbs } from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import LocationInvoicingConfigurator from './LocationInvoicingConfigurator';
import { getLocationInvoicingConfigsById } from '../../actions/locationInvoicingConfig/getLocationInvoicingConfigsById';
import parseIntOrNull from '../../../common/helpers/parseIntOrNull';

export type Props = RouteComponentProps<{ locationId: string }, {}>;

const BillingConfigurationEditPage = ({ params }: Props) => {
  const locationId = parseIntOrNull(params.locationId);

  const dispatch = useAppDispatch();
  const configs = useAppSelector(
    state => state.LocationInvoicingConfigReducer.locationInvoicingConfigDetails);

  useEffect(
    () => {
      const breadcrumbsList = [
        { name: 'Billing', link: '/billing' },
        { name: 'Configuration', link: '/billing/configuration' },
        { name: configs.data?.[0]?.locationName, link: null },
      ];
      const breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
      dispatch(updateBreadcrumbs(breadcrumbs));
    },
    [dispatch, configs]);

  useEffect(
    () => {
      if (locationId !== null) {
        dispatch(getLocationInvoicingConfigsById(locationId));
      }
    },
    [dispatch, locationId]);

  return (
    <div className="pg-container no-padding-page-container">
      <Spin tip="Loading" spinning={configs.loadState === LoadState.InProgress}>
        {/*  <Spin tip="Saving" spinning={isBusy}>*/}
        <Card className="no-header-border" bordered={false} style={{ minHeight: '100px' }}>
          <Layout>
            {
              locationId
              &&
              configs.loadState === LoadState.Success
              &&
              configs.data.length > 0
              &&
              <LocationInvoicingConfigurator
                items={configs.data}
              />
            }
          </Layout>
        </Card>
      </Spin>
    </div>
  );
}

export default BillingConfigurationEditPage;

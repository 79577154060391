import { Link } from 'react-router';
import { List, Radio, Button, Form, Typography } from 'antd';
import { InvoicingContact } from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';
import ContactsAutocompleteContainer from '../../../common/containers/dropdown/ContactsAutocompleteContainer';
import { forwardRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { PrimaryContactDetailsComponent } from './PrimaryContactDetailsComponent';
import { loadContactDetails } from '../../actions/contacts/ContactsActions';
import { LocationInvoicingConfigEditFormFields } from './locationInvoicingConfigEditFormFields';
import { FormComponentProps, ValidationRule } from 'antd/lib/form';

function extractEmail(text: string) {
  return (text.match(/\[([^\]]+)\]/) || ['', ''])[1];
}

export type Props = FormComponentProps<LocationInvoicingConfigEditFormFields> & {
  value?: InvoicingContact[];
  onChange?: (value: InvoicingContact[]) => void;
  locationId: number;
  locationIndex: number;
  form: {
    getFieldDecorator;
    setFieldsValue;
  };
};

export const InvoicingContactsComponent = forwardRef(({ value, locationId, onChange, form, locationIndex }: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
  const [contacts, setContacts] = useState<InvoicingContact[]>([...(value || [])]);
  const [contactToAddId, setContactToAddId] = useState<number | null>(null);
  const [primaryContactId, setPrimaryContactId] = useState<number | null>(null);
  const autocompleteContacts = useAppSelector((state) => state.AutocompleteReducer.loadContactsAutocompleteDataSuccess);
  const contactDetailsData = useAppSelector((state) => state.ContactsReducer.loadDetailsSuccess);
  const primaryContactFieldName = `primaryContact_${locationIndex}`;
  const dispatch = useAppDispatch();

  const primaryContactRecipientValidator: ValidationRule['validator'] = (rule, value, callback) => {
    if (value == null) {
      return callback('A primary contact must be selected in the invoice recipients list');
    }
    return callback();
  };

  const updatePrimaryContactField = (contact: InvoicingContact | null) => {
    const contactValue = contact ? { contact } : null;
    form.setFieldsValue({
      [primaryContactFieldName]: contactValue,
    });
    form.validateFields([primaryContactFieldName]);
  };

  const onPrimaryContactChanged = (contactId: number | null) => {
    setPrimaryContactId(contactId);
    if (contactId) {
      dispatch(loadContactDetails(contactId));
    } else {
      updatePrimaryContactField(null);
    }
  };

  const onContactsChanged = (contacts: InvoicingContact[]) => {
    setContacts([...contacts]);
    if (onChange) onChange([...contacts]);
  };

  useEffect(() => {
    const initialPrimary = contacts.find((p) => p.isPrimary);
    onPrimaryContactChanged(initialPrimary?.contactId || null);
  }, [contacts]);

  useEffect(() => {
    if (primaryContactId && primaryContactId === contactDetailsData?.id) {
      updatePrimaryContactField(contactDetailsData);
    }
  }, [contactDetailsData, primaryContactId]);

  const handleAdd = () => {
    const contact = autocompleteContacts?.find((p) => p.id === contactToAddId);
    if (contact) {
      const newContact: InvoicingContact = {
        contactId: contact.id,
        isPrimary: false,
        contact: {
          key: contact.id,
          id: contact.id,
          contactId: contact.id,
          pictureId: 0,
          image: '',
          email: extractEmail(contact.text),
          jobTitle: '',
          typeId: null,
          contactType: null,
          location: null,
          locationId: 0,
          created: '',
          user: null,
          addressId: 0,
          address1: '',
          addressType: '',
          city: '',
          zipcode: '',
        },
        invoicingConfigId: 0,
        locationInvoicingConfigContactId: 0,
      };
      onContactsChanged([...contacts, newContact]);
      setContactToAddId(null);
    }
  };

  const handleRemoveContact = (contactId: number) => {
    const toRemove = contacts.find((p) => p.contactId === contactId);
    if (toRemove?.isPrimary) {
      onPrimaryContactChanged(null);
    }
    onContactsChanged(contacts.filter((contact) => contact.contactId !== contactId));
  };

  const handlePrimaryChange = (contactId: number) => {
    const updatedContacts = contacts.map((contact) => ({
      ...contact,
      isPrimary: contact.contactId === contactId,
    }));
    onContactsChanged(updatedContacts);
    onPrimaryContactChanged(contactId);
  };

  const newContactAdded = (contactId: number) => {
    setContactToAddId(contactId);
  };

  const canAdd = !autocompleteContacts?.map((p) => p.id).includes(contactToAddId) || contactToAddId === null;

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 10 }}>
      <div style={{ flex: '1 1 50%', minWidth: '300px', paddingRight: '20px' }} ref={ref}>
        <Form.Item label="Invoice recipients" labelAlign="left">
          <div style={{ lineHeight: '20px' }}>
            <Typography.Text type="secondary">Add email recipients, click below to select primary invoice recipient</Typography.Text>
          </div>
          <List
            className="multiline-list"
            itemLayout="horizontal"
            dataSource={contacts}
            locale={{
              emptyText: 'No invoicing contacts',
            }}
            style={{ marginTop: '10px' }}
            renderItem={(item: InvoicingContact) => (
              <List.Item>
                <Radio checked={item.isPrimary} onChange={() => handlePrimaryChange(item.contactId)} />
                <Link to={'/contacts/' + item.contactId} style={{ paddingTop: '3px' }}>
                  {item.contact.email}
                </Link>
                <i className="icon-remove link-danger" style={{ paddingLeft: '5px' }} onClick={() => handleRemoveContact(item.contactId)} />
                {item.isPrimary ? ' Primary' : ''}
              </List.Item>
            )}
          />
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center', paddingTop: '10px' }}>
            <ContactsAutocompleteContainer customParams={{ locationId }} placeholder="Add recipient" onChange={newContactAdded} value={contactToAddId} />
            <Button key="submit" type="primary" htmlType="submit" disabled={canAdd} onClick={handleAdd}>
              Add
            </Button>
          </div>
        </Form.Item>
      </div>
      <div style={{ flex: '1 1 50%', minWidth: '300px' }}>
        <Form.Item label="Primary Invoice recipient:">
          <div style={{ lineHeight: '20px' }}>
            <Typography.Text type="secondary">This person will be shown on the invoice and have their address in the email's To field</Typography.Text>
          </div>
          {form.getFieldDecorator(primaryContactFieldName, {
            initialValue: null,
            rules: [{ validator: primaryContactRecipientValidator }],
          })(<PrimaryContactDetailsComponent />)}
        </Form.Item>
      </div>
    </div>
  );
});

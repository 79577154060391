import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Fetched, createFetched } from '../../../common/store/fetched';
import {
  IGetInvoicesParams,
  IPagedInvoice,
  IInvoice,
  IUpdateValidationIssueContext,
  IUpdateInvoiceStatusContext,
  IDeleteInvoiceAdjustmentContext,
  IUpdateBulkInvoiceStatusContext,
  IUpdateBulkInvoiceResult,
  IDeleteInvoiceItemContext,
  IInvoicesGenerationResponse,
} from './billing.models';
import { createFetchedSetter, createFetchedDismisser } from '../../../common/store/fetched.slices';
import { FailureData } from '../../../common/store/failure';
import { DownloadedFile } from '../../../common/store/fileDownload';
import { LocationReportFilters } from '../../components/reports/Reports';

export interface IBillingState {
  invoiceListParams: IGetInvoicesParams;
  invoiceList: Fetched<IPagedInvoice>;
  invoice: Fetched<IInvoice>;
  downloadInvoiceAttachmentOperation: Fetched<DownloadedFile>;
  updateValidationIssueOperation: Fetched<void, FailureData, IUpdateValidationIssueContext>;
  updateStatusOperation: Fetched<void, FailureData, IUpdateInvoiceStatusContext>;
  updateBulkStatusOperation: Fetched<IUpdateBulkInvoiceResult[], FailureData, IUpdateBulkInvoiceStatusContext>;
  updateInvoiceNotesOperation: Fetched<void>;
  updateInvoiceCustomItemOperation: Fetched<void>;
  addInvoiceCustomItemOperation: Fetched<void, FailureData>;
  addAdjustmentOperation: Fetched<void, FailureData>;
  updateAdjustmentOperation: Fetched<void, FailureData>;
  deleteAdjustmentOperation: Fetched<void, FailureData, IDeleteInvoiceAdjustmentContext>;
  invoiceFilterValues?: LocationReportFilters;
  resendInvoiceOperation: Fetched<void>;
  regenerateInvoiceOperation: Fetched<IUpdateBulkInvoiceResult[]>;
  regenerateSelectedInvoicesOperation: Fetched<IUpdateBulkInvoiceResult[]>;
  deleteInvoiceItemOperation: Fetched<void, FailureData, IDeleteInvoiceItemContext>;
  generateInvoicesOperation: Fetched<IInvoicesGenerationResponse>;
  updateInvoiceEmailTemplateOperation: Fetched<void>;
}

export const defaultInvoiceListParams: IGetInvoicesParams = {
  pageNo: 1,
  pageSize: 20,
  sortBy: 'invoicePeriodFrom',
  sortAsc: false,
};

const initialState: IBillingState = {
  invoiceListParams: defaultInvoiceListParams,
  invoiceList: createFetched(),
  invoice: createFetched(),
  downloadInvoiceAttachmentOperation: createFetched(),
  updateValidationIssueOperation: createFetched(),
  updateStatusOperation: createFetched(),
  updateBulkStatusOperation: createFetched(),
  updateInvoiceNotesOperation: createFetched(),
  updateInvoiceCustomItemOperation: createFetched(),
  addInvoiceCustomItemOperation: createFetched(),
  addAdjustmentOperation: createFetched(),
  updateAdjustmentOperation: createFetched(),
  deleteAdjustmentOperation: createFetched(),
  resendInvoiceOperation: createFetched(),
  regenerateInvoiceOperation: createFetched(),
  regenerateSelectedInvoicesOperation: createFetched(),
  deleteInvoiceItemOperation: createFetched(),
  generateInvoicesOperation : createFetched(),
  updateInvoiceEmailTemplateOperation : createFetched(),
};

export const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setInvoiceListParams: (state, action: PayloadAction<IGetInvoicesParams>) => {
      state.invoiceListParams = action.payload;
    },
    invoiceListUpdated: createFetchedSetter('invoiceList'),
    dismissInvoiceList: createFetchedDismisser('invoiceList'),
    invoiceUpdated: createFetchedSetter('invoice'),
    dismissInvoice: createFetchedDismisser('invoice'),
    downloadInvoiceAttachmentOperationUpdated: createFetchedSetter('downloadInvoiceAttachmentOperation'),
    dismissDownloadInvoiceAttachmentOperation: createFetchedDismisser('downloadInvoiceAttachmentOperation'),
    updateValidationIssueOperationUpdated: createFetchedSetter('updateValidationIssueOperation'),
    dismissUpdateValidationIssueOperation: createFetchedDismisser('updateValidationIssueOperation'),
    updateStatusOperationUpdated: createFetchedSetter('updateStatusOperation'),
    dismissUpdateStatusOperation: createFetchedDismisser('updateStatusOperation'),
    updateBulkStatusOperationUpdated: createFetchedSetter('updateBulkStatusOperation'),
    dismissBulkUpdateStatusOperation: createFetchedDismisser('updateBulkStatusOperation'),
    updateInvoiceNotesOperationUpdated: createFetchedSetter('updateInvoiceNotesOperation'),
    dismissUpdateInvoiceNotesOperation: createFetchedDismisser('updateInvoiceNotesOperation'),
    updateInvoiceCustomItemOperationUpdated: createFetchedSetter('updateInvoiceCustomItemOperation'),
    dismissUpdateInvoiceCustomItemOperation: createFetchedDismisser('updateInvoiceCustomItemOperation'),
    addInvoiceCustomItemOperationUpdated: createFetchedSetter('addInvoiceCustomItemOperation'),
    dismissAddInvoiceCustomItemOperation: createFetchedDismisser('addInvoiceCustomItemOperation'),
    addAdjustmentOperationUpdated: createFetchedSetter('addAdjustmentOperation'),
    dismissAddAdjustmentOperation: createFetchedDismisser('addAdjustmentOperation'),
    updateAdjustmentOperationUpdated: createFetchedSetter('updateAdjustmentOperation'),
    dismissUpdateAdjustmentOperation: createFetchedDismisser('updateAdjustmentOperation'),
    deleteAdjustmentOperationUpdated: createFetchedSetter('deleteAdjustmentOperation'),
    dismissDeleteAdjustmentOperation: createFetchedDismisser('deleteAdjustmentOperation'),
    updateInvoiceFilterValues: (state, action: PayloadAction<LocationReportFilters>) => {
      state.invoiceFilterValues = action.payload;
    },
    resendInvoiceOperationUpdated: createFetchedSetter('resendInvoiceOperation'),
    dismissResendInvoiceOperation: createFetchedSetter('resendInvoiceOperation'),
    regenerateInvoiceOperationUpdated: createFetchedSetter('regenerateInvoiceOperation'),
    dismissRegenerateInvoiceOperation: createFetchedSetter('regenerateInvoiceOperation'),
    regenerateSelectedInvoicesOperationUpdated: createFetchedSetter('regenerateSelectedInvoicesOperation'),
    dismissRegenerateSelectedInvoicesOperation: createFetchedSetter('regenerateSelectedInvoicesOperation'),
    deleteInvoiceItemOperationUpdated: createFetchedSetter('deleteInvoiceItemOperation'),
    dismissDeleteInvoiceItemOperation: createFetchedDismisser('deleteInvoiceItemOperation'),
    generateInvoicesOperationUpdated: createFetchedSetter('generateInvoicesOperation'),
    dismissGenerateInvoicesOperationUpdated: createFetchedDismisser('generateInvoicesOperation'),
    updateInvoiceEmailTemplateOperationUpdated: createFetchedSetter('updateInvoiceEmailTemplateOperation'),
    dismissUpdateInvoiceEmailTemplateOperation: createFetchedDismisser('updateInvoiceEmailTemplateOperation'),
  },
});

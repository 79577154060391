export interface DownloadedFile {
  data: ArrayBuffer;
  fileName: string;
}

export function saveFile(file: DownloadedFile) {
  const url = URL.createObjectURL(new Blob([file.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', file.fileName);
  setTimeout(() => URL.revokeObjectURL(url), 40_000);
  setTimeout(() => link.click(), 0);
}

import { useEffect, useMemo } from "react";
import { Form, Input, Modal, Select } from "antd";
import { ZonesDropdownComponent } from "../../../common/components/dropdown/ZonesDropdownComponent";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/storeHooks";
import { loadZonesDropdown } from "../../../common/actions/dropdown/DropdownActions";
import { FormComponentProps } from "antd/lib/form";
import {
  CalculationMethodMapping,
  TaxRate,
  TaxRateCalculationMethod,
} from "../../reducers/locationInvoicingConfig/locationInvoicingConfig.models";
import { saveTaxRate } from "../../actions/locationInvoicingConfig/saveTaxRate";

interface OwnProps {
  taxRate?: TaxRate;
  onClose: () => void;
}

const NAME_MAX_LENGTH = 50;

const TaxRateModalComponent = ({ taxRate, form, onClose }: OwnProps & FormComponentProps<TaxRate>) => {
  const dispatch = useAppDispatch();
  const zonesSuccess = useAppSelector(state => state.DropdownReducer.loadZonesDropdownDataSuccess);
  const zonesError = useAppSelector(state => state.DropdownReducer.loadZonesDropdownDataError);

  useEffect(
    () => form.resetFields(),
    [taxRate],
  );



  const calculationMethodOptions = useMemo(
    () => Object.values(TaxRateCalculationMethod)
      .filter(x => typeof x === 'number')
      .map(x => <Select.Option key={x} value={x}>{CalculationMethodMapping[x]}</Select.Option>),
    [],
  );

  const getFieldDecorator = form.getFieldDecorator<TaxRate>;

  const onSave = () => {
    (form.validateFields((err, values) => {
      if (!err && taxRate) {
        dispatch(saveTaxRate({
          ...taxRate,
          ...values,
        }));
      }
    }) as unknown as Promise<any>).catch(() => console.log('caugth error'));
  }

  return <Modal
    visible={taxRate != null}
    title={taxRate?.taxRateId == null ? 'Add Tax Rate' : 'Edit Tax Rate'}
    onCancel={onClose}
    onOk={onSave}
  >
    <Form>
      <Form.Item label="Zone">
        {getFieldDecorator('zoneId', {
          rules: [{ required: true, message: 'Please select a tax rate zone.'} ],
          initialValue: taxRate?.zoneId
        })(
          <ZonesDropdownComponent
            loadData={() => dispatch(loadZonesDropdown({}))}
            dataSource={zonesSuccess}
            dataSourceError={zonesError}
            placeholder=""
          />
        )}
      </Form.Item>
      <Form.Item label="Name">
        {getFieldDecorator('name', {
          rules: [
            { required: true, message: 'Please enter a tax rate name.' },
            { max: NAME_MAX_LENGTH, message: 'Name must be shorter than 50 characters.' }
          ],
          initialValue: taxRate?.name
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Value">
        {getFieldDecorator('value', {
          rules: [{ required: true, message: 'Please provide a decimal fraction. (e.g. 0.0725 means 7.25%)' }],
          initialValue: taxRate?.value
        })(
          <Input type="number" />
        )}
      </Form.Item>
      <Form.Item label="Calculation on">
        {getFieldDecorator('calculationMethod', {
          rules: [{ required: true, message: 'Please select a tax rate calculation method.' }],
          initialValue: taxRate?.calculationMethod
        })(
          <Select children={calculationMethodOptions} />
        )}
      </Form.Item>
    </Form>
  </Modal>
}

export default Form.create<OwnProps & FormComponentProps<TaxRate>>()(TaxRateModalComponent);

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as DistributorsActions from '../../actions/distributors/DistributorsActions';
import { DistributorBasicDetailsComponent } from '../../components/distributors/DistributorBasicDetailsComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadBasicDetailsSuccess: state.DistributorsReducer.loadDistributorDataSuccess,
    loadBasicDetailsError: state.DistributorsReducer.loadDistributorDataError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(DistributorsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DistributorBasicDetailsComponent)
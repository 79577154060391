import React from "react";
import { hashHistory } from "react-router";
import { Card, Row, Col } from 'antd';
import appUtilityService from "../../services/AppUtilityService";
/**
 * Error bounderis wrapper component props & state
 */

interface IErrorBoundaryWrapperComponentProps {
    actions?: {
        updateBreadcrumbs(Breadcrumbs): void;
    }
}

interface IErrorBoundaryWrapperComponentState {
    hasError: boolean;
}

export class ErrorBoundaryWrapperComponent extends React.Component<IErrorBoundaryWrapperComponentProps, IErrorBoundaryWrapperComponentState> {
    constructor(props: IErrorBoundaryWrapperComponentProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.hasError && nextState.hasError === false) {
            return false;
        }
        return true;
    }

    render() {
        if (this.state.hasError) {
            this.setState({ hasError: false });
            this.breadcrumbsBuild()
            return <div className="pg-container page-container">
                <Card className="no-header-border" bordered={false}
                    title="Something went wrong"
                >
                    Sorry, we ran into a problem. There are one or more errors on this page.
                </Card>
            </div>
        }

        return this.props.children;
    }

    breadcrumbsBuild = () => {
        let breadcrumbsList = [
            { name: "Error", link: null }
        ]
        var breadcrumbs = appUtilityService.generateBreadcrumbs(breadcrumbsList);
        this.props.actions.updateBreadcrumbs(breadcrumbs);
    }
}
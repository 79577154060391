import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as UserAccountActions from '../../../common/actions/user/UserAccountActions';
import { HeaderComponent } from '../../components/layouts/HeaderComponent';
import { RootState } from '../../store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(UserAccountActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)

import { ohioQuarterlyReportUpdated } from './slice-actions';
import apiService from '../../../common/services/ApiService';
import { createStandardFetchedAction } from '../../../common/store/fetched.slices';
import { Moment } from 'moment';
import AppConstants from '../../../common/AppConstants';

export const getOhioQuarterlyReport = (dateFrom: Moment, dateTo: Moment) => createStandardFetchedAction(
  ohioQuarterlyReportUpdated,
  async () => {

    const result = await apiService.getData(
      `/reports/invoicing/ohio-quarterly/${dateFrom.format(AppConstants.apiDateFormat)}/${dateTo.format(AppConstants.apiDateFormat)}`);
    return result.data.reportsData;
  },
  false,
);
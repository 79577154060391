import * as React from "react";
import { isEqual, filter, isEmpty, cloneDeep } from "lodash";

import AppUtilityService from "../../../../common/services/AppUtilityService";
import { DataGridComponent } from "../../../../common/components/table/DataGridComponent";
import { ColumnDrilldownChartComponent } from "../../../../common/components/charts/ColumnDrilldownChartComponent";
import ReportsUtilityService from "../../../../common/services/ReportsUtilityService";
import AppConstants from "../../../../common/AppConstants";

import { Row, Col, Spin } from 'antd';
import { IDealsReportComponentProps, IDealsReportComponentState } from '../Reports';

export class DealsReportComponent extends React.Component<IDealsReportComponentProps, IDealsReportComponentState> {

    private gridContainerRef: any;

    constructor(props: IDealsReportComponentProps) {
        super(props);
        this.state = {
            dealsChart: null,
            dealsList: null,
            isLoading: false,
            isLoadingCharts: false,
            isLoadingList: false,
            downloadType: "pdf",
            apiParams: this.props.apiParams,
            reportType: this.props.reportType || null,
        }
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillReceiveProps(nextProps: IDealsReportComponentProps) {
        if (nextProps.apiParams && !isEqual(this.props.apiParams, nextProps.apiParams)) {
            this.gridContainerRef && this.gridContainerRef.resetDataGrid();
            this.loadDealsChart(nextProps.apiParams);
            this.loadDealsList(nextProps.apiParams);
            this.setState({ apiParams: nextProps.apiParams })
        }
        if (nextProps.reportType && !isEqual(this.props.reportType, nextProps.reportType)) {
            this.setState({ reportType: nextProps.reportType })
        }
        /**
         * Recon summary chart
         */
        if (nextProps.loadDealsChartSuccess && !isEqual(this.props.loadDealsChartSuccess, nextProps.loadDealsChartSuccess)) {
            var chartStats = nextProps.loadDealsChartSuccess && nextProps.loadDealsChartSuccess.summary;
            let chartData = {
                data: chartStats.map((obj) => { return { name: obj.name, y: obj.count, drilldown: null } }),
                drilldownData: null,
                name: "",
                yAxisTitle: "Deals",
                yAxisUnits: ""
            }
            this.setState({ dealsChart: chartData, isLoadingCharts: false })
        }
        if (nextProps.loadDealsChartError && !isEqual(this.props.loadDealsChartError, nextProps.loadDealsChartError)) {
            this.setState({ isLoadingCharts: false, dealsChart: null })
            AppUtilityService.handleApiError(nextProps.loadDealsChartError);
        }
        /**
         * Recon summary list
         */
        if (nextProps.loadDealsListSuccess && !isEqual(this.props.loadDealsListSuccess, nextProps.loadDealsListSuccess)) {
            this.setState({ dealsList: nextProps.loadDealsListSuccess, isLoadingList: false })
        }
        if (nextProps.loadDealsListError && !isEqual(this.props.loadDealsListError, nextProps.loadDealsListError)) {
            this.setState({ isLoadingList: false, dealsList: null })
            AppUtilityService.handleApiError(nextProps.loadDealsListError);
        }
        /**
         * Report download excel
         */
        if (nextProps.exportDealsDataToExcelSuccess && !isEqual(this.props.exportDealsDataToExcelSuccess, nextProps.exportDealsDataToExcelSuccess)) {
            this.setState({ isLoading: false })
            if (!isEmpty(nextProps.exportDealsDataToExcelSuccess)) {
                if (this.state.downloadType === "pdf") {
                    this.downloadPDF(nextProps.exportDealsDataToExcelSuccess.summary)
                } else {
                    this.downloadExcel(nextProps.exportDealsDataToExcelSuccess)
                }
            }
        }
        if (nextProps.exportDealsDataToExcelError && !isEqual(this.props.exportDealsDataToExcelError, nextProps.exportDealsDataToExcelError)) {
            this.setState({ isLoading: false })
            AppUtilityService.handleApiError(nextProps.exportDealsDataToExcelError);
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
    }
    render() {
        const devExColumnData: any = filter(AppConstants.dealsReportColumns, { "reportType": this.state.reportType })[0];
        let devExSummaryAvgCols = devExColumnData && devExColumnData.columns;
        let devExSummarySumCols = devExColumnData && filter(devExColumnData.columns, (o) => { return (o.dataField !== 'fpPercent' && o.dataField !== 'tpPercent') });

        return (
            <Spin spinning={this.state.isLoading}>
                <div style={{ padding: "24px" }}>
                    <h2>Graphical representation of selected time period</h2>
                    <Spin spinning={this.state.isLoadingCharts}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <ColumnDrilldownChartComponent id="deals-chart" height={400} chartData={this.state.dealsChart} type = "game"/>
                            </Col>
                        </Row>
                    </Spin>
                </div>
                <div style={{ padding: "24px" }}>
                    <Spin spinning={this.state.isLoadingList}>
                        <div>
                            <DataGridComponent
                                toolbarContent={<h2>Detailed view of selected time period:</h2>}
                                ref={ref => (this.gridContainerRef = ref)}
                                dataSource={this.state.dealsList && this.state.dealsList.summary}
                                dataSrcColumns={devExColumnData && !isEmpty(devExColumnData.columns) && devExColumnData.columns}
                                summaryAvgColumns={devExColumnData && !isEmpty(devExSummaryAvgCols) && devExSummaryAvgCols}
                                summarySumColumns={devExColumnData && !isEmpty(devExSummarySumCols) && devExSummarySumCols}
                                isLoading={this.state.isLoadingList}
                                storeIdentifier={`DealsReport-${devExColumnData.reportType}`}
                            />
                        </div>
                    </Spin>
                </div>
            </Spin>
        )
    }


    loadDealsChart = (apiParams) => {
        this.setState({ isLoadingCharts: true })
        apiParams.summaryType = apiParams.reportType;
        this.props.actions.loadDealsChart(apiParams)
    }

    loadDealsList = (apiParams) => {
        this.setState({ isLoadingList: true, dealsList: null })
        apiParams.summaryType = apiParams.reportType;
        this.props.actions.loadDealsList(apiParams)
    }

    getDownloadData = (downloadType) => {
        this.setState({ isLoading: true, downloadType })
        var { reportType } = this.state;
        var apiParams = { ...this.state.apiParams };
        apiParams.excelType = reportType;
        apiParams.isExcel = downloadType === "excel" ? true : false;
        this.props.actions.exportDealsDataToExcel(apiParams);
    }

    downloadExcel = (exportListData) => {
        const devExColumnData: any = filter(AppConstants.dealsReportColumns, { "reportType": this.state.reportType })[0];
        var { dealsList } = cloneDeep(this.state);
        var dateRange = [this.state.apiParams.dateFrom, this.state.apiParams.dateTo]
        ReportsUtilityService.downloadDealsExcel(this.state.reportType, dateRange, exportListData, devExColumnData)
    }

    downloadPDF = (dataset) => {
        const devExColumnData: any = filter(AppConstants.dealsReportColumns, { "reportType": this.state.reportType })[0];
        ReportsUtilityService.downloadDealsPDF(this.state.reportType, dataset, devExColumnData)
    }

}
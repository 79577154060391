import { createApiCaller } from '../../../common/services/ApiService';
import { fetchDataWithActionCreator } from '../../../common/store/fetched';
import { IGameTable, IGame } from '../../reducers/games/games.models';
import { saveEditGamePayload } from '../../actions/games/slice-actions';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { processFailureReason } from '../../../common/store/failure';

//IGameTable id has original GameId, in case the GameID is being changed
export const saveEditGame = (editGame: IGameTable) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    return await fetchDataWithActionCreator(
      saveEditGamePayload,
      dispatch,
      createApiCaller(api => api.updateData<IGame>('/games/', null, editGame)),
      processFailureReason,
      true,
    );
  };
}
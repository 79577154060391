
import * as React from "react";
import { isEqual } from "lodash";
import AppUtilityService from "../../../common/services/AppUtilityService";

import { Modal, Button, Radio } from 'antd';
import SaveContactFormComponent from "../contacts/SaveContactFormComponent";
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

export class SaveLocationContactModal extends React.Component<ISaveLocationContactProps, ISaveLocationContactState> {

    private saveContactFormRef: any;

    constructor(props: ISaveLocationContactProps) {
        super(props);
        this.state = {
            contactDetails: this.props.contactDetails || null,
            contactSaveApproach: "new"
        }
    }

    componentWillReceiveProps(nextProps: ISaveLocationContactProps) {
        if (!isEqual(this.props.contactDetails, nextProps.contactDetails)) {
            this.setState({ contactDetails: nextProps.contactDetails })
        }

        if (nextProps.addLocationContactSuccess && !isEqual(this.props.addLocationContactSuccess, nextProps.addLocationContactSuccess)) {
            this.props.cancelSaveContactCallback(nextProps.addLocationContactSuccess);
            AppUtilityService.showMessageToast("New contact has been added", "success");
        }
        if (nextProps.addLocationContactError && !isEqual(this.props.addLocationContactError, nextProps.addLocationContactError)) {
            AppUtilityService.handleApiError(nextProps.addLocationContactError);
        }

        if (nextProps.updateLocationContactSuccess && !isEqual(this.props.updateLocationContactSuccess, nextProps.updateLocationContactSuccess)) {
            this.props.cancelSaveContactCallback(nextProps.updateLocationContactSuccess);
            AppUtilityService.showMessageToast("Contact details have been updated", "success");
        }
        if (nextProps.updateLocationContactError && !isEqual(this.props.updateLocationContactError, nextProps.updateLocationContactError)) {
            AppUtilityService.handleApiError(nextProps.updateLocationContactError);
        }
    }
    render() {
        var labelText = this.state.contactDetails ? "Update Contact" : "Add New Contact";

        return (
            <Modal
                visible={true}
                title={labelText}
                onOk={this.handleFormSubmit}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>Cancel</Button>,
                    <Button key="submit" type="primary" htmlType="submit" onClick={this.handleFormSubmit}>{labelText}</Button>
                ]}
            >
                {
                    !this.state.contactDetails &&
                    <RadioGroup defaultValue={this.state.contactSaveApproach} onChange={this.changeApproach} style={{ marginBottom: '15px' }}>
                        <RadioButton value="new">Create new</RadioButton>
                        <RadioButton value="existing">Select from existing</RadioButton>
                    </RadioGroup>
                }
                <SaveContactFormComponent
                    locationId={this.props.locationId}
                    ref={(ref) => this.saveContactFormRef = ref}
                    contactSaveApproach={this.state.contactSaveApproach}
                    contactDetails={this.state.contactDetails}
                />
            </Modal>
        )
    }

    changeApproach = (e) => {
        this.setState({ contactSaveApproach: e.target.value })
    }

    handleFormSubmit = (e) => {
        e.preventDefault();
        this.saveContactFormRef.validateFields((err, values) => {
            if (!err) {
                let { contactDetails } = this.state;
                var formData = values;
                var params = {
                    locationId: this.props.locationId,
                    contactId: null
                }
                if (contactDetails) {
                    params.contactId = contactDetails.id;
                    formData.addressId = contactDetails.addressId;
                    formData.charityId = contactDetails.charityId;
                    this.handleUpdate(params, formData);
                } else {
                    if (formData.existingContactId) {
                        params.contactId = formData.existingContactId;
                        this.handleUpdate(params, null)
                    } else {
                        this.handleAdd(params, formData)
                    }
                }
            }
        })
    }

    handleAdd = (params, data) => {
        this.props.actions.addLocationLinkedContact(params, data)
    }

    handleUpdate = (params, data) => {
        this.props.actions.updateLocationLinkedContact(params, data)
    }

    handleCancel = () => {
        this.props.cancelSaveContactCallback();
    }

}

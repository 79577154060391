import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as DashboardActions from '../../actions/dashboard/DashboardActions';
import { GamePlayCashInCardComponent } from '../../components/dashboard/GamePlayCashInCardComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadDashboardChartStatsSuccess: state.DashboardReducer.loadDashboardChartStatsSuccess,
    loadDashboardChartStatsError: state.DashboardReducer.loadDashboardChartStatsError,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(DashboardActions, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(GamePlayCashInCardComponent)

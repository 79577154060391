
import * as React from "react";
import { isEqual } from "lodash";
import { Layout, Row, Col } from 'antd';
import AppUtilityService from "../../../common/services/AppUtilityService";
import UserDetailsDescriptionCardContainer from "../../containers/user/UserDetailsDescriptionCardContainer";
import UsersLinkedLocationsContainer from "../../containers/user/UsersLinkedLocationsContainer";
import UsersLinkedZonesContainer from "../../containers/user/UsersLinkedZonesContainer";

export class UserDetailsPage extends React.Component<IUserDetailsProps, IUserDetailsState> {

  constructor(props: IUserDetailsProps) {
    super(props);

    this.state = {
      userId: parseInt(this.props.params.userId) || null,
      userDetails: null
    }
  }

  componentWillMount() {
    if (this.state.userId) {
      this.getUserDetails()
    }
  }

  componentWillReceiveProps(nextProps: IUserDetailsProps) {
    /**
     * Get user details success/error handle
     */
    if (nextProps.loadUserDetailsSuccess && !isEqual(this.props.loadUserDetailsSuccess, nextProps.loadUserDetailsSuccess)) {
      this.setState({ userDetails: nextProps.loadUserDetailsSuccess }, () => {
        this.breadcrumbsBuild()
      })
    }
    if (nextProps.loadUserDetailsError && !isEqual(this.props.loadUserDetailsError, nextProps.loadUserDetailsError)) {
      AppUtilityService.handleApiError(nextProps.loadUserDetailsError);
    }
  }

  render() {
    return (
      <div className="pg-container page-container">
        <Layout>
          <UserDetailsDescriptionCardContainer userDetails={this.state.userDetails} updateUserDetailsSuccessCallback={this.updateUserDetailsSuccessHandler} />
          <div>
            <br />
          </div>
          <Row type="flex" align="top" gutter={24}>
            <Col xl={12} lg={18} xs={36} className="sm-row-b-margin md-row-b-margin lg-row-b-margin">
              <UsersLinkedZonesContainer userDetails={this.state.userDetails} />
            </Col>
            <Col xl={12} lg={18} xs={36} className="sm-row-b-margin md-row-b-margin lg-row-b-margin">
              <UsersLinkedLocationsContainer userDetails={this.state.userDetails} />
            </Col>            
          </Row>
        </Layout>
      </div>
    )
  }

  breadcrumbsBuild = () => {
    let breadcrumbsList = [
      { name: "User", link: "/admin/users" },
      { name: this.state.userDetails.firstName + " " + this.state.userDetails.lastName, link: null }
    ];
    var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
    this.props.actions.updateBreadcrumbs(breadcrumbs);
  }
  /**
   * Load selected user details
   */
  getUserDetails = () => {
    var { userId } = this.state;
    this.props.actions.getUserDetails(userId);
  }

  /**
   * Reload selected user details on update user success callback from UserDetailsDescriptionCard
   */
  updateUserDetailsSuccessHandler = () => {
    this.getUserDetails();
  }

}


import * as React from "react";
import { SidebarComponent } from "./SidebarComponent";
import HeaderContainer from "../../containers/layouts/HeaderContainer";
import ErrorBoundaryWrapperContainer from "../../containers/layouts/ErrorBoundaryWrapperContainer";

import { Layout } from 'antd';

const { Content } = Layout;

export class MainLayout extends React.Component<IMainLayoutProps, {}> {

  render() {

    return (
      <Layout style={{ height: '100vh' }}>
        <SidebarComponent currentRouteDetails={this.props.location} />
        <Layout className="main-layout">
          <HeaderContainer />
          <ErrorBoundaryWrapperContainer>
            <Content className="pg-layout-content">
              {this.props.children}
            </Content>
          </ErrorBoundaryWrapperContainer>
        </Layout>
      </Layout>
    )
  }
}

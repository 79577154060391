import ApiService from "../../../common/services/ApiService";

export function updateInvoiceDetail(data) {
    return {
        type: 'INVOICE_DATA_UPDATE',
        payload: data
    }
}

export function getInvoicesData(data) {
    return function (dispatch) {
        dispatch({ type: 'GET_INVOICE_REQUEST' });
        return ApiService.getData("/invoices", data)
            .then((successResult) => {
                dispatch({ type: 'GET_INVOICE_SUCCESS', payload: successResult.data })
            })
            .catch((errorResult) => {
                dispatch({ type: 'GET_INVOICE_FAILURE', payload: errorResult })
            });
    }
}

import * as React from "react";
import { isEqual, isEmpty } from "lodash";
import UserListTableContainer from "../../containers/user/UserListTableContainer";
import SaveUserModalContainer from "../../containers/user/SaveUserModalContainer";
import AppUtilityService from "../../../common/services/AppUtilityService";

import { Card, Input, Icon, Button, Modal, Badge } from 'antd';
const confirm = Modal.confirm;

export class UserListPage extends React.Component<IUserListPageProps, IUserListPageState> {

  private usersListRef: any;

  constructor(props: IUserListPageProps) {
    super(props);
    this.state = {
      selectedUserKeys: null,
      showSaveUserModal: false,
      searchText: "",
      totalUsers: null
    }
  }

  componentWillMount() {
    this.breadcrumbsBuild();
    if (this.props.userListFilters) {
      this.handlePresetFilters(this.props.userListFilters)
    }
  }

  render() {
    const title = <Input type="text"
      suffix={<Icon type="search" theme="outlined" />}
      placeholder="Search"
      onChange={this.searchChange}
      style={{ width: 200 }}
      value={this.state.searchText}
    />;

    const extraActions = <div className="user-list-filters">
      <span>
        <Button className="ml-8" type="primary" onClick={this.openSaveUserModal}>+ New User</Button>
      </span>
      <span>
        <Button className="ml-8" onClick={this.refreshList}>Refresh</Button>
      </span>
    </div>;
    return (
      <div className="pg-container page-container">
        <Card className="no-header-border" bordered={false}
          title={title}
          extra={extraActions}
        >
          <UserListTableContainer
            onRef={(ref) => this.usersListRef = ref}
            getTotalUsersCallback={this.getTotalUsersHandler}
          />

          {
            this.state.showSaveUserModal &&
            <SaveUserModalContainer userAddSuccessCallback={this.addUserSuccessHandler} closeSaveUserModal={this.closeSaveUserModal} />
          }
        </Card>
      </div>
    )
  }
  /**
   * Get total users on load list success callback from UserListTableComponent
   * (required to show total in breadcrumbs)
   */
  getTotalUsersHandler = (totalCount: number) => {
    this.setState({ totalUsers: totalCount }, () => {
      this.breadcrumbsBuild()
    })
  }

  breadcrumbsBuild = () => {
    let breadcrumbsList = [
      { name: "User", link: null }
    ]
    var extraContent = this.state.totalUsers &&
      <span className="font-12 t-grey-dark ml-8">
        <Badge dot className="mr-8" style={{ top: '-1px', background: '#A6ADC9' }} /> {this.state.totalUsers + " Total"}
      </span>;
    var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList, extraContent);
    this.props.actions.updateBreadcrumbs(breadcrumbs);
  }

  /**
   * Handle search change in UserListTableComponent using ref
   */
  searchChange = (e: any) => {
    this.setState({ searchText: e.target.value })
    this.usersListRef.handleSearchChange(e.target.value);
  }

  /**
   * Call loadUsers using ref onClick of refresh button
   */
  refreshList = () => {
    this.setState({ searchText: "" })
    this.usersListRef.resetTable();
  }

  /**
   * openSaveUserModal: Open save user modal on button click
   * closeSaveUserModal: Close save user modal onClick cancel from modal
   * addUserSuccessHandler: Reload list on successfully adding user from the modal 
   */
  openSaveUserModal = () => {
    this.setState({ showSaveUserModal: true });
  }
  closeSaveUserModal = () => {
    this.setState({ showSaveUserModal: false });
  }
  addUserSuccessHandler = (userDetails: UserDetail) => {
    this.usersListRef.loadUserList();
    this.setState({ showSaveUserModal: false });
  }

  handlePresetFilters = (filterData) => {
    var searchText = filterData.searchText;
    this.setState({ searchText })
  }
}

import * as React from "react";
import { isEqual } from "lodash";

import AppUtilityService from "../../../common/services/AppUtilityService";
import WebAccessService from "../../../common/services/WebAccessService";
import { Avatar, Card, Col, Row, Spin } from "antd";

export class DistributorBasicDetailsComponent extends React.Component<IDistributorBasicDetailsCardProps, IDistributorBasicDetailsCardState> {
  constructor(props: IDistributorBasicDetailsCardProps) {
    super(props);
    this.state = {
      distributorDetails: null,
      isLoading: false
    }
  }

  componentDidMount() {
    if (this.props.distributorId) {
      this.getDistributorDetails();
    }
  }


  componentWillReceiveProps(nextProps: IDistributorBasicDetailsCardProps) {
    if (nextProps.loadBasicDetailsSuccess && !isEqual(this.props.loadBasicDetailsSuccess, nextProps.loadBasicDetailsSuccess)) {
      var responseData: any = nextProps.loadBasicDetailsSuccess;
      this.setState({ distributorDetails: responseData, isLoading: false }, () => {
      })
    }
  }

  render() {
      return (
        <><Spin spinning={this.state.isLoading}></Spin><Card className="content-card no-header-border" bordered={false}>
          <div style={{ paddingTop: "16px" }}>
          <Row gutter={24}>
            <Col md={16} xs={24}>
            <div>
              <div style={{ display: "inline-block", verticalAlign: "top", marginTop: "10px" }}>
                <Avatar shape="square" className="mr-8 avatar-square">
                  <i className="icon-distributor" />
                </Avatar>
              </div>
              <div style={{ display: "inline-block" }}>
                          <h1 style={{ marginBottom: 0 }}>
                            {this.state.distributorDetails && this.state.distributorDetails.name}
                          </h1>
                          <div className="font-14 regular t-black-light">
                            {
                                this.state.distributorDetails && AppUtilityService.displayStringFromArray([
                                this.state.distributorDetails.address1,
                                this.state.distributorDetails.address2,
                                this.state.distributorDetails.city,
                                this.state.distributorDetails.addressState,
                                this.state.distributorDetails.zipcode,
                              ])
                            }
                          </div>
                        </div>              
            </div>
            </Col>
          </Row>
          </div>
        </Card></>
      )
    }

  getDistributorDetails = () => {
    var distributorId = this.props.distributorId;
    this.setState({ isLoading: true })
    this.props.actions.loadDistributorBasicDetails(distributorId);
  }
}

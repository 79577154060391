import { useState } from "react";
import { IGameSelectionWindow, IAddGameSelectionWindow } from "../../reducers/gameSelectionWindow/GameSelectionWindowReducer";
import { hashHistory } from "react-router";
import { Row, Col, Radio } from "antd";
import { createGameSelectionWindows, updateGameSelectionWindows } from "../../actions/gameSelectionWindow/gameSelectionWindowActions";
const buttonConfirm = require("../../../images/Button_Confirm.png")
const buttonConfirmDisabled = require("../../../images/Button_Confirm_Disabled.png")
const buttonBackToGames = require("../../../images/Button_BackToGames.png");

const GameSelectionWindowSelectGamesCheckout = (props) => {
  const [window, setWindow] = useState<IGameSelectionWindow | IAddGameSelectionWindow>({...props.location.state.window})
  const gameSelectionWindowId = props.location.state.gameSelectionWindowId;
  const isNewWindow = props.location.state.isNewWindow;
  const isViewOnly = props.location.state.isViewOnly;

  const selectedGames = window.selectedAndAvailableGames.filter(g => g.selected === true);

  const confirmGameSelections = () => {
    if (isNewWindow) {
      props.actions.createGameSelectionWindows(window);
    } else {
      props.actions.updateGameSelectionWindows(window);
    }
  }

  const goBackToGames = () => {
    if (isNewWindow) {
      var newWindow = {...window};
      hashHistory.push({
        pathname: `/admin/gameSelectionWindow/gameSelectionWindowSelectGames/`,
        state: {newWindow, gameSelectionWindowId, isNewWindow, isViewOnly}
      });
    } else {
        var editGameSelectionWindow = {...window};
      hashHistory.push({
        pathname: `/admin/gameSelectionWindow/gameSelectionWindowSelectGames/`,
        state: {editGameSelectionWindow, gameSelectionWindowId, isNewWindow, isViewOnly}
      });
    }
    
  }

  return (
    <div className="pg-container page-container gameSelectBackground">
      <Row type="flex" justify="center" align="middle">
        <Col>
            <span style={{ textAlign: 'center' }} className="gameSelectHeader">CHECKOUT</span>
        </Col>
      </Row>
      <Row type="flex" justify="center" align="middle" style={{paddingBottom: "20px", paddingLeft: "60px"}}>
        <Col>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
          {window.selectedAndAvailableGames !== undefined }
                      {window.selectedAndAvailableGames.filter(g => g.selected === true).map((game) => (
                          <section 
                          hidden={window.selectedAndAvailableGames == undefined || window.selectedAndAvailableGames.length == 0}
                          onClick={() => {}}
                          style={{ position: "relative"}} 
                          key={game.gameID}>
                                                                                                      
                              <img className={"gameIcon"} src={game.iconUrl}/>
                              <div 
                              hidden={window.selectedAndAvailableGames == undefined || window.selectedAndAvailableGames.length == 0}>
                              <Radio.Group className="radioStyle" disabled={true} value={game.featured} buttonStyle="solid"
                                  style={{border: "slategrey", borderBottomStyle: "double", color: "black", paddingRight:"20px"}}>
                                  <Radio.Button style={{backgroundColor: game.featured === "left" && game.selected === true ? "green" : game.featured === "" && game.selected === false ? "silver" : "white",                                  
                                      color: game.selected === true ? "black" : "gray"}} 
                                      value="left">left</Radio.Button>
                                  <Radio.Button style={{backgroundColor: game.featured === "not" && game.selected === true ? "yellow" : game.featured === "" && game.selected === false ? "silver" : "white", 
                                      color: game.selected === true ? "black" : "gray"}} 
                                      value="not">not</Radio.Button>
                                  <Radio.Button style={{backgroundColor: game.featured === "right" && game.selected === true ? "green": game.featured === "" && game.selected === false ? "silver" : "white", 
                                      color: game.selected === true ? "black" : "gray"}} 
                                      value="right">right</Radio.Button>                                
                              </Radio.Group> 
                          </div>
                          </section>                        
                      ))}
          </div>
        </Col>
      </Row>
      <Row justify="center" align="middle" style={{paddingBottom: "20px"}}>
        <Col> 
            {
            <>
            <img className="navImageButton" src={buttonBackToGames} onClick={() => goBackToGames()} style={{paddingRight: "10px"}} alt="Back To Games"/>
            </>
            }                 
            {
            <>
            <img className="navImageButton floatRight" src={buttonConfirm} onClick={confirmGameSelections} />
            </>
            }          
        </Col>
    </Row>
    </div>
  )
}
export default GameSelectionWindowSelectGamesCheckout;
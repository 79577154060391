import { connect } from 'react-redux'

import * as BreadcrumbActions from '../../../common/actions/breadcrumbs/BreadcrumbActions'
import { QUARTERCLOSE_SELECTED_GAMESELECTIONWINDOWID_UPDATE, QUARTERCLOSE_SELECTED_LOCATIONS_UPDATE, QUARTERCLOSE_SELECTED_WINDOWGAMES_UPDATE } from '../../reducers/quarterClose/QuarterCloseReducer'
import { QuarterCloseLocationsPage } from '../../components/quarterClose/QuarterCloseLocationsPage'
import * as QuarterCloseActions from '../../actions/quarterClose/QuarterCloseActions'
import { RootState } from '../../../common/store/ConfigureStore';


const mapStateToProps = (state: RootState) => {
    return {
        locationListFilters: state.LocationsReducer.locationListFilters,
        locationsList: state.QuarterCloseReducer.pagedLocations,
        loadingPagedLocations: state.QuarterCloseReducer.loadingPagedLocations,
        selectedLocationIds: state.QuarterCloseReducer.selectedLocationIds,
        latestSetup: state.QuarterCloseReducer.latestSetup,
        locationIdsWithoutSettings: state.QuarterCloseReducer.locationIdsWithoutSettings,
        gameSelectionWindowId: state.QuarterCloseReducer.gameSelectionWindowId,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
          updateBreadcrumbs: (breadcrumbs) => dispatch(BreadcrumbActions.updateBreadcrumbs(breadcrumbs)),
          loadLocations: (params: any) => dispatch(QuarterCloseActions.loadLocations(params)),
          getLatestLocationSetup: (locationId: number) => dispatch(QuarterCloseActions.getLatestLocationSetup(locationId)),
          dismissLatestLocationSetup: () => dispatch(QuarterCloseActions.dismissLatestLocationSetup()),
          clearSelections: () => dispatch(QuarterCloseActions.clearSelections()),
          getLocationsWithoutSettings: (locationIds: Array<number>) => dispatch(QuarterCloseActions.getLocationsWithoutSettingsList(locationIds)),
        },
        updateSelectedLocations: (locationIds: number[]) => dispatch({ type: QUARTERCLOSE_SELECTED_LOCATIONS_UPDATE, payload: locationIds }),
        updateSelectedGameSelectionWindowId: (gameSelectionWindowId: number) => dispatch({ type: QUARTERCLOSE_SELECTED_GAMESELECTIONWINDOWID_UPDATE, payload: gameSelectionWindowId}),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(QuarterCloseLocationsPage)

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as QuarterCloseActions from '../../../app/actions/quarterClose/QuarterCloseActions'
import { QuarterCloseConfirmationPage } from '../../components/quarterClose/QuarterCloseConfirmationPage'
import { RootState } from '../../../common/store/ConfigureStore';


const mapStateToProps = (state: RootState) => {
    return {
        games: state.QuarterCloseReducer.games,
        selectedSetup: state.QuarterCloseReducer.selectedSetup,
        locationIds: state.QuarterCloseReducer.selectedLocationIds,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(QuarterCloseActions, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(QuarterCloseConfirmationPage)

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { loadDistributorUsersAvailableAutocomplete } from '../../actions/autocomplete/AutocompleteActions';
import { DropdownComponent } from '../../../common/components/dropdown/DropdownComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
      datasource: state.AdminAutocompleteReducer.loadDistributorUsersAvailableSuccess,
      datasourceError: state.AdminAutocompleteReducer.loadDistributorUsersAvailableError,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
      loadData: bindActionCreators(loadDistributorUsersAvailableAutocomplete, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DropdownComponent)
import { createApiCaller } from '../../../common/services/ApiService';
import { fetchDataWithActionCreator } from '../../../common/store/fetched';
import { IGame } from '../../reducers/games/games.models';
import { saveGamePayload } from './slice-actions';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { processFailureReason } from '../../../common/store/failure';

export const saveGame = (newGame: IGame) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    return await fetchDataWithActionCreator(
      saveGamePayload,
      dispatch,
      createApiCaller(api => api.postData<IGame>('/games/', newGame)),
      processFailureReason,
      true,
    );
  };
}
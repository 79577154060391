import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as ReportsActions from '../../../actions/reports/ReportsActions';
import { SessionsReportComponent } from '../../../components/reports/locationStatisticsReports/SessionsReportComponent';
import { RootState } from '../../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {};
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators(ReportsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionsReportComponent);

export class PermissionHelper {
    private static instance: PermissionHelper;

    
    public static getInstance = () => {
        if (!PermissionHelper.instance) {
            PermissionHelper.instance = new PermissionHelper();
        }
        return PermissionHelper.instance;
    }	
    
    public userPermissions: Array<Permissions>;
    
}

import React from 'react';
import { Switch } from 'antd';
import { FilterProps } from './LocationReportsFilterComponent';

const OutsidePermittedHoursOnlyFilter = ({ filterValue, onFilterValueChange }: FilterProps) => (
  <span>
    <span className="font-14 regular">Outside permitted hours only </span>
    <Switch
      size="small"
      checked={!!filterValue}
      onChange={onFilterValueChange}
    />
  </span>
);

export default OutsidePermittedHoursOnlyFilter;

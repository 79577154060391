import React, { useEffect } from 'react';
import DateRangeFilter from '../../../common/components/filters/DateRangeFilter';
import moment from 'moment/moment';
import { Button, Select } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { getJurisdictionsSitesOrganizations } from '../../actions/reporting/getJurisdictionsSitesOrganizations';
import { LoadState } from '../../../common/store/fetched';

const Option = Select.Option;

export type Filters = {
  startDate: moment.Moment;
  endDate: moment.Moment;
  jurisdictionId?: number;
  siteId?: number;
  organizationId?: number;
};

export type Props = {
  filters: Filters;
  applyFilters: (filters: Filters) => void;
};

const TicketSalesByDateReportFilters = ({ filters, applyFilters }: Props) => {
  const dispatch = useAppDispatch();
  const filterDataFetched = useAppSelector(state => state.ReportingReducer.arrowJurisdictionsSitesOrganizations);

  const jurisdictions = filterDataFetched.data?.jurisdictions;
  const sites = filterDataFetched.data?.locations;
  const organizations = filterDataFetched.data?.organizations;

  const filterLoadState = {
    loading: filterDataFetched.loadState === LoadState.InProgress,
    notFoundContent: getNotFoundContent(filterDataFetched.loadState),
  };

  useEffect(
    () => {
      dispatch(getJurisdictionsSitesOrganizations(filters));
    },
    [dispatch, filters]);

  const resetFilters = () => {
    applyFilters({
      startDate: moment().subtract(1, 'week').startOf('isoWeek'),
    endDate: moment().subtract(1, 'week').endOf('isoWeek'),
    });
  }

  return (
    <div className="reports-filter">
      <span className="mr-8">
        <Select
          placeholder="Jurisdiction"
          value={filters.jurisdictionId}
          allowClear
          style={{ width: 200 }}
          onChange={(value: number) => applyFilters({ ...filters, jurisdictionId: value })}
          {...filterLoadState}
        >
          {
            jurisdictions?.map(p => (<Option key={p.id} title={p.name}>{p.name}</Option>))
          }
        </Select>
      </span>
      <span className="mr-8">
        <Select
          placeholder="Site"
          value={filters.siteId ? sites?.find(x => x.id === filters.siteId)?.name : undefined}
          allowClear
          style={{ width: 200 }}
          onChange={(value) => applyFilters({ ...filters, siteId: value ? +value : undefined })}
          showSearch
          optionFilterProp="children"
          {...filterLoadState}
        >
          {sites?.map(x => (<Option key={x.id} title={x.name}>{x.name}</Option>))}
        </Select>
      </span>
      <span className="mr-8">
        <Select
          placeholder="Organization"
          value={filters.organizationId ? organizations?.find(x => x.id === filters.organizationId)?.name : undefined}
          allowClear
          style={{ width: 200 }}
          onChange={(value) => applyFilters({ ...filters, organizationId: value ? +value : undefined })}
          showSearch
          optionFilterProp="children"
          {...filterLoadState}
        >
          {
            organizations
              ?.map(x => (<Option key={x.id} title={x.name}>{x.name}</Option>))
          }
        </Select>
      </span>
      <span className="mr-8">
        <DateRangeFilter
          filterValue={[filters.startDate, filters.endDate]}
          onFilterValueChange={range => applyFilters({ ...filters, startDate: range[0], endDate: range[1] })}
        />
      </span>
      <span>
        <Button type="default" onClick={resetFilters}>Reset</Button>
      </span>
    </div>
  );
}

const getNotFoundContent = (loadState: LoadState) => {
  switch (loadState) {
    case LoadState.InProgress:
    case LoadState.NotRequested:
      return 'Loading...';
    case LoadState.Failed:
      return 'Failed to load data';
    default:
      return undefined;
  }
}

export default TicketSalesByDateReportFilters;

export type ZoneState = Partial<{
  addNewZoneSuccess: any;
  assignLocationToZoneSuccess: any;
  assignUserToZoneError: any;
  deleteSelectedZoneSuccess: any;
  loadZoneDetailsError: any;
  loadZoneListDataSuccess: any;
  unassignLocationFromZoneSuccess: any;
  unassignUserFromZoneError: any;
  updateZoneDetailsError: any;
  addNewZoneError: any;
  assignLocationToZoneError: any;
  assignUserToZoneSuccess: any;
  deleteSelectedZoneError: any;
  loadZoneDetailsSuccess: any;
  loadZoneListDataError: any;
  unassignLocationFromZoneError: any;
  unassignUserFromZoneSuccess: any;
  updateZoneDetailsSuccess: any;
}>;

const initialState: ZoneState = {};

const ZoneReducer = (state = initialState, action): ZoneState => {
  switch (action.type) {
    /**
     * Load all zones
     */
    case "LOAD_ZONE_LIST_DATA_REQUEST":
      return { ...state, loadZoneListDataSuccess: null, loadZoneListDataError: null };
    case "LOAD_ZONE_LIST_DATA_SUCCESS":
      return { ...state, loadZoneListDataSuccess: action.payload, loadZoneListDataError: null };
    case "LOAD_ZONE_LIST_DATA_FAILURE":
      return { ...state, loadZoneListDataSuccess: null, loadZoneListDataError: action.payload };

    /**
     * Add new zone
     */
    case "ADD_NEW_ZONE_REQUEST":
      return { ...state, addNewZoneSuccess: null, addNewZoneError: null };
    case "ADD_NEW_ZONE_SUCCESS":
      return { ...state, addNewZoneSuccess: action.payload, addNewZoneError: null };
    case "ADD_NEW_ZONE_FAILURE":
      return { ...state, addNewZoneSuccess: null, addNewZoneError: action.payload };

    /**
     * Get selected zone details
     */
    case "LOAD_SELECTED_ZONE_DETAILS_REQUEST":
      return { ...state, loadZoneDetailsSuccess: null, loadZoneDetailsError: null };
    case "LOAD_SELECTED_ZONE_DETAILS_SUCCESS":
      return { ...state, loadZoneDetailsSuccess: action.payload, loadZoneDetailsError: null };
    case "LOAD_SELECTED_ZONE_DETAILS_FAILURE":
      return { ...state, loadZoneDetailsSuccess: null, loadZoneDetailsError: action.payload };

    /**
     * Update selected zone
     */
    case "UPDATE_ZONE_DETAILS_REQUEST":
      return { ...state, updateZoneDetailsSuccess: null, updateZoneDetailsError: null };
    case "UPDATE_ZONE_DETAILS_SUCCESS":
      return { ...state, updateZoneDetailsSuccess: action.payload, updateZoneDetailsError: null };
    case "UPDATE_ZONE_DETAILS_FAILURE":
      return { ...state, updateZoneDetailsSuccess: null, updateZoneDetailsError: action.payload };

    /**
     * Delete selected zone
     */
    case "DELETE_SELECTED_ZONE_REQUEST":
      return { ...state, deleteSelectedZoneSuccess: null, deleteSelectedZoneError: null };
    case "DELETE_SELECTED_ZONE_SUCCESS":
      return { ...state, deleteSelectedZoneSuccess: action.payload, deleteSelectedZoneError: null };
    case "DELETE_SELECTED_ZONE_FAILURE":
      return { ...state, deleteSelectedZoneSuccess: null, deleteSelectedZoneError: action.payload };

    /**
     * Assign user to zone
     */
    case "ASSIGN_USER_TO_ZONE_REQUEST":
      return { ...state, assignUserToZoneSuccess: null, assignUserToZoneError: null };
    case "ASSIGN_USER_TO_ZONE_SUCCESS":
      return { ...state, assignUserToZoneSuccess: action.payload, assignUserToZoneError: null };
    case "ASSIGN_USER_TO_ZONE_FAILURE":
      return { ...state, assignUserToZoneSuccess: null, assignUserToZoneError: action.payload };

    /**
     *  Unassign user from zone
     */
    case "UNASSIGN_USER_FROM_ZONE_REQUEST":
      return { ...state, unassignUserFromZoneSuccess: null, unassignUserFromZoneError: null };
    case "UNASSIGN_USER_FROM_ZONE_SUCCESS":
      return { ...state, unassignUserFromZoneSuccess: action.payload, unassignUserFromZoneError: null };
    case "UNASSIGN_USER_FROM_ZONE_FAILURE":
      return { ...state, unassignUserFromZoneSuccess: null, unassignUserFromZoneError: action.payload };

    /**
     * Assign location to zone
     */
    case "ASSIGN_LOCATION_TO_ZONE_REQUEST":
      return { ...state, assignLocationToZoneSuccess: null, assignLocationToZoneError: null };
    case "ASSIGN_LOCATION_TO_ZONE_SUCCESS":
      return { ...state, assignLocationToZoneSuccess: action.payload, assignLocationToZoneError: null };
    case "ASSIGN_LOCATION_TO_ZONE_FAILURE":
      return { ...state, assignLocationToZoneSuccess: null, assignLocationToZoneError: action.payload };

    /**
     *  Unassign location from zone
     */
    case "UNASSIGN_LOCATION_FROM_ZONE_REQUEST":
      return { ...state, unassignLocationFromZoneSuccess: null, unassignLocationFromZoneError: null };
    case "UNASSIGN_LOCATION_FROM_ZONE_SUCCESS":
      return { ...state, unassignLocationFromZoneSuccess: action.payload, unassignLocationFromZoneError: null };
    case "UNASSIGN_LOCATION_FROM_ZONE_FAILURE":
      return { ...state, unassignLocationFromZoneSuccess: null, unassignLocationFromZoneError: action.payload };

    default:
      return state;
  }
}

export default ZoneReducer;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row, Tabs, Typography } from 'antd';
import { FormComponentProps, ValidationRule } from 'antd/lib/form/Form';
import {
  IDefaultLocationInvoicingConfigItem,
  INDLocationInvoicingConfigItem,
  LocationInvoicingConfigItem,
} from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { LoadState } from '../../../common/store/fetched';
import { usePrevious } from '../../../common/hooks/usePrevious';
import InvoicingServicesAutocompleteContainer
  from '../../../common/containers/dropdown/InvoicingServicesAutocompleteContainer';
import { InvoicingSystemID, NDv1 } from '../../../common/models/InvoicingSystems';
import { loadLocalCharitiesAutocomplete } from '../../../common/actions/dropdown/AutocompleteActions';
import {
  LocationInvoicingConfigEditFormFields,
  LocationInvoicingConfigTabData,
  mapFormFieldsToRequest,
} from './locationInvoicingConfigEditFormFields';
import InvoicingEnabledEditor from './InvoicingEnabledEditor';
import NdInvoicingConfigEditForm from './NdInvoicingConfigEditForm';
import DefaultInvoicingConfigEditForm from './DefaultInvoicingConfigEditForm';
import AddLocationCharityConfigButton from './AddLocationCharityConfigButton';
import { every, isEmpty } from 'lodash';
import { updateLocationInvoicingConfig } from '../../actions/locationInvoicingConfig/updateLocationInvoicingConfig';
import { getLocationCharities } from '../../actions/locationInvoicingConfig/getLocationCharities';
import { dismissGetLocationCharities } from '../../actions/locationInvoicingConfig/slice-actions';
import { hashHistory } from 'react-router';
import LocationCharityHeader from './LocationCharityHeader';
import LocalCharityIDAutocompleteContainer
  from '../../../common/containers/dropdown/LocalCharityIDAutocompleteContainer';
import { loadLocationBasicDetails } from '../../actions/locations/LocationsActions';

export type Props = FormComponentProps<LocationInvoicingConfigEditFormFields> & {
  items: LocationInvoicingConfigItem[];
};

const noCharity: DropdownObject = { id: 'NONE', text: 'No Charity' };

const LocationInvoicingConfigurator = (
  props: Props,
) => {
  const {
    items,
    form: {
      getFieldDecorator,
      getFieldValue,
      getFieldsValue,
      getFieldsError,
      isFieldsTouched,
      setFieldsValue,
      validateFields,
    },
  } = props;

  const dispatch = useAppDispatch();
  const updateState = useAppSelector(
    state => state.LocationInvoicingConfigReducer.updateLocationInvoicingConfigOperation.loadState);
  const locationCharities = useAppSelector(state => state.LocationInvoicingConfigReducer.locationCharities);
  const prevUpdateState = usePrevious(updateState);
  const isSaving = updateState === LoadState.InProgress;
  const [invoicingSystemId, setInvoicingSystemId] = useState(getFieldValue('invoicingSystemId') ?? items[0].invoicingSystemId);
  const localCharities: Charity[] = useAppSelector(state => state.AutocompleteReducer.loadLocalCharitiesAutocompleteDataSuccess);
  const [tabs, setTabs] = useState<LocationInvoicingConfigTabData[]>(items.map((item, index) => ({ key: index, item })));
  const maxTabIndex = useRef(tabs.length - 1);
  const [activeTabKey, setActiveTabKey] = useState('tab0');
  const [hasTabsChanged, setHasTabsChanged] = useState(false);

  const fieldsError = getFieldsError();
  const hasErrors = Object.values(fieldsError).some(p => p?.length > 0);
  const tabsWithErrors = new Set(Object.entries(fieldsError)
    .filter(([key, value]) => value?.length > 0 && key.includes('_'))
    .map(([key]) => key.split('_', 2)[1])
    .map(p => parseInt(p, 10)));
  const takenLocalCharityIds = tabs
    .map(tab => getFieldValue(`localCharityId_${tab.key}`))
    .map(id => id === 'NONE' ? null : id);

  useEffect(
    () => {
      dispatch(loadLocationBasicDetails(items[0].locationId));
    },
    [items]);

  useEffect(() => {
    dispatch(loadLocalCharitiesAutocomplete(null, items[0].locationId));
    dispatch(getLocationCharities(items[0].locationId));
    return () => {
      dispatch(dismissGetLocationCharities());
    }
  }, [dispatch, items]);

  useEffect(() => {
    if (updateState === LoadState.Success && prevUpdateState === LoadState.InProgress) {
      hashHistory.push('/billing/configuration');
    }
  }, [updateState, prevUpdateState]);

  const showTabWithError = (errors: Record<string, string[]>) => {
    const tabWithErrorsList = new Set(Object.keys(errors)
      .filter(key => key.includes('_'))
      .map(key => key.split('_', 2)[1])
      .map(p => parseInt(p, 10)));
    const firstTabWithError = tabWithErrorsList.values().next().value;
    if (activeTabKey !== `tab${firstTabWithError}`) {
      setActiveTabKey(`tab${firstTabWithError}`);
    }
  }

  const handleCancel = () => {
    if (updateState !== LoadState.InProgress) {
      hashHistory.push('/billing/configuration');
    }
  };

  const handleSave = async () => {
    validateFields(async (errors, values) => {
      if (!every(errors, e => isEmpty(e))) {
        showTabWithError(errors);
        return;
      }
      const requestData = mapFormFieldsToRequest(invoicingSystemId, tabs, values);
      await dispatch(updateLocationInvoicingConfig(items[0].locationId, requestData));
    });
  };

  const handleInvoicingSystemChange = useCallback((value: InvoicingSystemID): void => {
    setInvoicingSystemId(value);
    tabs.forEach(tab => {
      setFieldsValue({
        [`invoicingSystemId_${tab.key}`]: value,
      });
    });
  }, [tabs, setFieldsValue]);

  const handleAddTab = (localCharityId: number | null) => {
    setTabs([
      ...tabs,
      {
        key: ++maxTabIndex.current,
        item: {
          ...items[0],
          localCharityId,
          invoicingSystemId,
          invoicingEnabled: false,
          nextInvoiceNoSuffix: 1,
          dueInDays: null,
          amountDuePercentage: null,
          invoicingDistributorId: null,
          distributorContactId: null,
          additionalInvoiceRecipients: null,
          ticketsPlayedFeeDiscount: null,
          overrideBillingName: null,
          overrideBillingAddress: null,
          invoicingContacts: [],
          primaryContact: null,
          taxRateIds: [],
        },
      }]);
    setActiveTabKey(`tab${maxTabIndex.current}`);
    setHasTabsChanged(true);
  };

  const handleDeleteTab = (keyToDelete: number) => {
    const newTabs = tabs.filter(i => i.key !== keyToDelete);
    setTabs(newTabs);
    setActiveTabKey(`tab${newTabs[0].key}`);
    setHasTabsChanged(true);
  };

  const handleLocalCharityIdChange = (newLocalCharityId?: number | 'NONE') => {
    const index = parseInt(activeTabKey.replace('tab', ''), 10);
    const localCharityId = newLocalCharityId === 'NONE' ? null : newLocalCharityId;
    setTabs(tabs.map(tab => tab.key === index
      ? { ...tab, item: { ...tab.item, localCharityId } }
      : tab));
    setHasTabsChanged(true);
  }

  const localCharityIdValidator: ValidationRule['validator'] = (rule, value, callback) => {
    const isLocalCharityIdConfigDuplicated = Object.entries(getFieldsValue())
      .find(([key, val]) => key !== rule.field && key.startsWith('localCharityId_') && val === value);
    if (isLocalCharityIdConfigDuplicated) {
      callback('There is already a configuration for this Local Charity ID');
    } else {
      callback();
    }
  };

  return (
    <Card className="content-card no-header-border" bordered={false}>
      <Row>
        <Col xl={16} lg={24}>
          <Form>
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item label="Invoicing System">
                  {getFieldDecorator('invoicingSystemId', {
                    initialValue: items[0].invoicingSystemId,
                    rules: [{ required: true, message: 'Please select invoicing system' }],
                  })(
                    <InvoicingServicesAutocompleteContainer
                      placeholder="Select invoicing system"
                      onChange={handleInvoicingSystemChange}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            {
              invoicingSystemId === NDv1
              &&
              <InvoicingEnabledEditor
                item={items[0]}
                form={props.form}
              >
                <NdInvoicingConfigEditForm
                  item={items[0] as INDLocationInvoicingConfigItem}
                  localCharities={localCharities}
                  form={props.form}
                />
              </InvoicingEnabledEditor>
            }
            {
              invoicingSystemId !== NDv1 && invoicingSystemId != null
              &&
              <Tabs
                className="card-tabs"
                activeKey={activeTabKey}
                tabBarExtraContent={
                  <AddLocationCharityConfigButton
                    takenLocalCharityIds={takenLocalCharityIds}
                    onAddTab={handleAddTab}
                  />
                }
                onChange={setActiveTabKey}
              >
                {
                  tabs.map(item => (
                    <Tabs.TabPane
                      tab={
                        <>
                          {item.item.localCharityId ?? 'No Charity'}
                          {
                            hasErrors
                            &&
                            tabsWithErrors.has(item.key)
                            &&
                            <Typography.Text type="danger"> *</Typography.Text>
                          }
                        </>
                      }
                      key={`tab${item.key}`}
                    >
                      <Row gutter={16} type="flex">
                        <Col>
                          <LocationCharityHeader item={item.item} locationCharities={locationCharities.data}/>
                        </Col>
                        <Col>
                          <Button
                            title="Delete Local Charity configuration"
                            disabled={tabs.length === 1}
                            type="primary"
                            icon="delete"
                            shape="circle"
                            onClick={() => handleDeleteTab(item.key)}
                          />
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col>
                          <Form.Item label="Local Charity ID">
                            {getFieldDecorator(`localCharityId_${item.key}`, {
                              initialValue: item.item.localCharityId === null ? noCharity.id : item.item.localCharityId,
                              rules: [
                                { required: true, message: 'Please select local charity ID' },
                                { validator: localCharityIdValidator },
                              ],
                            })(
                              <LocalCharityIDAutocompleteContainer
                                placeholder="Select local charity ID"
                                apiUrlId={item.item.locationId}
                                prependExtraItem={noCharity}
                                idSelector={c => c.localCharityId ?? `disabled_${c.id}`}
                                textSelector={c => `${c.localCharityId ?? 'No Local Charity ID'} - ${c.name}`}
                                disabledSelector={c => c.localCharityId == null}
                                onChange={handleLocalCharityIdChange}
                              />,
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <InvoicingEnabledEditor
                        item={item.item}
                        locationIndex={item.key}
                        form={props.form}
                      >
                        <DefaultInvoicingConfigEditForm
                          item={item.item as IDefaultLocationInvoicingConfigItem}
                          locationIndex={item.key}
                          invoicingSystemId={invoicingSystemId}
                          form={props.form}
                        />
                      </InvoicingEnabledEditor>
                    </Tabs.TabPane>
                  ))
                }
              </Tabs>
            }
            <Row>
              <Col>
                <Button
                  key="submit"
                  type="primary"
                  className="mr-8"
                  htmlType="submit"
                  loading={isSaving}
                  disabled={
                    invoicingSystemId == null
                    ||
                    !(isFieldsTouched() || hasTabsChanged)
                    ||
                    hasErrors
                  }
                  onClick={handleSave}
                >
                  Save
                </Button>
                <Button
                  key="back"
                  disabled={isSaving}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};

export default Form.create<Props>()(LocationInvoicingConfigurator);

import ApiService from "../../../common/services/ApiService";

export function updateContactListFilters(params) {
  return {
    type: 'CONTACT_LIST_FILTER_UPDATE',
    payload: params
  }
}
// ================================================================================
// Contacts List
// ================================================================================
export function loadContactsListData(params) {
  return function (dispatch) {
    dispatch({ type: `LOAD_CONTACTS_LIST_DATA_REQUEST` });
    return ApiService.getData("/contacts", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_CONTACTS_LIST_DATA_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_CONTACTS_LIST_DATA_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
// Contacts Details
// ================================================================================
export function loadContactDetails(contactId) {
  return function (dispatch) {
    dispatch({ type: `LOAD_CONTACT_DETAILS_REQUEST` });
    return ApiService.getData("/contacts/" + contactId)
      .then((successResult) => {
        dispatch({ type: `LOAD_CONTACT_DETAILS_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_CONTACT_DETAILS_FAILURE`, payload: errorResult });
      });
  }
}

// ================================================================================
export function updateContactBasicDetails(contactId, data) {
  return function (dispatch) {
    dispatch({ type: `UDPATE_CONTACT_BASIC_DETAILS_REQUEST` });
    return ApiService.updateData("/contacts/" + contactId, null, data)
      .then((successResult) => {
        dispatch({ type: `UDPATE_CONTACT_BASIC_DETAILS_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `UDPATE_CONTACT_BASIC_DETAILS_FAILURE`, payload: errorResult });
      });
  }
}
/**
 * Delete contact
 */
export function deleteContactDetails(contactId) {
  return function (dispatch) {
    dispatch({ type: `DELETE_CONTACT_REQUEST` });
    return ApiService.deleteData("/contacts/" + contactId, null, null)
      .then((successResult) => {
        dispatch({ type: `DELETE_CONTACT_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `DELETE_CONTACT_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
export function addNewContact(data) {
  return function (dispatch) {
    dispatch({ type: `ADD_NEW_CONTACT_REQUEST` });
    return ApiService.postData("/contacts", data)
      .then((successResult) => {
        dispatch({ type: `ADD_NEW_CONTACT_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `ADD_NEW_CONTACT_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
export function updateContactImage(contactId, data) {
  return function (dispatch) {
    dispatch({ type: `UPDATE_CONTACT_IMAGE_REQUEST` });
    return ApiService.postData("/contacts/" + contactId + "/image", data, true)
      .then((successResult) => {
        dispatch({ type: `UPDATE_CONTACT_IMAGE_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `UPDATE_CONTACT_IMAGE_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
export function removeContactImage(contactId, documentId) {
  return function (dispatch) {
    dispatch({ type: `REMOVE_CONTACT_IMAGE_REQUEST` });
    return ApiService.deleteData("/contacts/" + contactId + "/image/" + documentId, null, null)
      .then((successResult) => {
        dispatch({ type: `REMOVE_CONTACT_IMAGE_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `REMOVE_CONTACT_IMAGE_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================

/**
 * add new user
 */
export function addNewUser(formData) {
  return function (dispatch) {
    dispatch({ type: `ADD_NEW_CONTACT_USER_REQUEST` });
    return ApiService.postData("/contacts/user", formData)
      .then((successResult) => {
        dispatch({ type: `ADD_NEW_CONTACT_USER_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `ADD_NEW_CONTACT_USER_FAILURE`, payload: errorResult });
      });
  }
}

// ================================================================================
// Contacts Notes
// ================================================================================
export function addNewContactNote(data) {
  return function (dispatch) {
    dispatch({ type: `ADD_NEW_CONTACTNOTE_REQUEST` });
    return ApiService.postData("/contacts/note", data)
      .then((successResult) => {
        dispatch({ type: `ADD_NEW_CONTACTNOTE_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `ADD_NEW_CONTACTNOTE_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
export function updateContactNote(data) {
  return function (dispatch) {
    dispatch({ type: `UPDATE_CONTACTNOTE_REQUEST` });
    return ApiService.updateData("/contacts/note", null, data)
      .then((successResult) => {
        dispatch({ type: `UPDATE_CONTACTNOTE_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `UPDATE_CONTACTNOTE_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
export function deleteContactNote(contactId, noteId) {
  return function (dispatch) {
    dispatch({ type: `DELETE_CONTACTNOTE_REQUEST` });
    return ApiService.deleteData("/contacts/" + contactId + "/note/" + noteId)
      .then((successResult) => {
        dispatch({ type: `DELETE_CONTACTNOTE_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `DELETE_CONTACTNOTE_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
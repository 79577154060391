import * as React from "react";
import { Card, Button, Badge, Switch } from 'antd';
import AppUtilityService from "../../../common/services/AppUtilityService";
import DistributorsListTableContainer from "../../containers/distributors/DistributorsListTableContainer";
import WebAccessService from "../../../common/services/WebAccessService";
import sessionService from "../../../common/services/SessionService";

export class DistributorsListPage extends React.Component<IDistributorsProps, IDistributorsState> {

  constructor(props: IDistributorsProps) {
    super(props);
    this.state = {
      totalDistributors: null
    }
  }

  componentDidMount() {
    this.breadcrumbsBuild();

  }
  render() {
    return (
      <div className="pg-container page-container">
        <Card className="no-header-border" bordered={false}>
          <DistributorsListTableContainer
            getTotalDistributorsCallback={this.getTotalDistributorsHandler}
          />
        </Card>
      </div>
    )
  }

  getTotalDistributorsHandler = (totalCount: number) => {
    this.setState({ totalDistributors: totalCount }, () => {
      this.breadcrumbsBuild()
    })
  }

  breadcrumbsBuild = () => {
    let breadcrumbsList = [
      { name: "Distributors", link: null }
    ]
    var extraContent = this.state.totalDistributors &&
      <span className="font-12 t-grey-dark ml-8">
        <Badge dot className="mr-8" style={{ top: '-1px', background: '#A6ADC9' }} /> {this.state.totalDistributors + " Total"}
      </span>;
    var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList, extraContent);
    this.props.actions.updateBreadcrumbs(breadcrumbs);
  }
}
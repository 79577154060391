import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ReportsActions from '../../../actions/reports/ReportsActions';
import {
  ReconSummaryReportComponent,
} from '../../../components/reports/locationStatisticsReports/ReconSummaryReportComponent';
import { RootState } from '../../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadReconSummaryChartSuccess: state.ReportsReducer.loadReconSummaryChartSuccess,
    loadReconSummaryChartError: state.ReportsReducer.loadReconSummaryChartError,

    loadReconSummaryLocationChartSuccess: state.ReportsReducer.loadReconSummaryLocationChartSuccess,
    loadReconSummaryLocationChartError: state.ReportsReducer.loadReconSummaryLocationChartError,

    loadReconSummaryListSuccess: state.ReportsReducer.loadReconSummaryListSuccess,
    loadReconSummaryListError: state.ReportsReducer.loadReconSummaryListError,

    loadReconSummaryInfoSuccess: state.ReportsReducer.loadReconSummaryInfoSuccess,
    loadReconSummaryInfoError: state.ReportsReducer.loadReconSummaryInfoError,

    loadReconSummaryChildListSuccess: state.ReportsReducer.loadReconSummaryChildListSuccess,
    loadReconSummaryChildListError: state.ReportsReducer.loadReconSummaryChildListError,

    // loadReconSummaryByDateSuccess: state.ReportsReducer.loadReconSummaryByDateSuccess,
    // loadReconSummaryByDateError: state.ReportsReducer.loadReconSummaryByDateError,

    exportDataToExcelSuccess: state.ReportsReducer.exportDataToExcelSuccess,
    exportDataToExcelError: state.ReportsReducer.exportDataToExcelError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ReportsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReconSummaryReportComponent)

import WebAccessService from '../../services/WebAccessService';
import { Form, Switch, Select } from 'antd';
import { ILocationFilterList } from '../../../app/reducers/locationSettings/LocationSettings.models';

const LocationFilterDropDownComponent = (children) => {
  //demo only switch variables, not visible by default
  const demoOnlyVisible: boolean = children.demoOnlyVisible === null || children.demoOnlyVisible === undefined ? false : children.demoOnlyVisible;
  let demoOnlyChecked: boolean = children.demoOnlyChecked === null || children.demoOnlyChecked === undefined ? false : children.demoOnlyChecked;
  const demoAccess: boolean = WebAccessService.hasPermissionToAccess('Location', 'View_Location_Demo_Toggle');

  //decommissioned only switch variables, not visible by default
  const dcOnlyVisible: boolean = children.dcOnlyVisible === null || children.dcOnlyVisible === undefined ? false : children.dcOnlyVisible;
  let dcOnlyChecked: boolean = children.dcOnlyChecked === null || children.dcOnlyChecked === undefined ? false : children.dcOnlyChecked;
  const dcAccess: boolean = WebAccessService.hasPermissionToAccess('Location', 'View_Location_Decommissioned_Toggle');

  const showSearch: boolean = children.showSearch === null || children.showSearch === undefined ? true : children.showSearch; //defaulting to true

  //location drop down variables
  const placeHolder: string = children.locationsPlaceHolder === null || children.locationsPlaceHolder === undefined ? null : children.locationsPlaceHolder;
  const dataSource: ILocationFilterList[] = children.dataSource === null || children.dataSource === undefined ? null : children.dataSource;
  
  const data = dataSource?.map(location => {
    return <Select.Option key={location.id} label={location.name || null}> {location.name || null} </Select.Option>
  }) ?? [];

    return (
      <>
      <Form>
        <div>
        {demoOnlyVisible && demoAccess &&
          <p>Demo Only <Switch title='Demo Only' size='small' checked={demoOnlyChecked} onChange={(e) => children.demoOnlyChange(e)}></Switch></p>
        }
        {dcOnlyVisible && dcAccess &&
          <p>Decommissioned Only <Switch title='Decommissioned Only' size='small' checked={dcOnlyChecked} onChange={(e) => children.dcOnlyChange(e)}>Decommissioned Only</Switch></p>
        }
        </div>
        <Select 
          placeholder={placeHolder}
          showSearch={children.showSearch === null || children.showSearch === undefined ? true : children.showSearch}
          onChange={(record) => children.locationSelected(record)}
          filterOption={(input, option) => (option.props.label ?? '').toString().toLowerCase().includes(input.toLowerCase())}
          mode={children.selectMode}  //when multi-select, pass in 'multiple', otherwise null for single select
          allowClear={children.allowClear}
          >
            {data}
        </Select>
      </Form>
      </>
    );
}

export default LocationFilterDropDownComponent;
import { AxiosResponse } from 'axios';

export enum FailureReason {
  CommunicationError = 'CommunicationError',
  ServerError = 'ServerError',
  UnknownError = 'UnknownError',
}

export interface FailureData {
  reason: FailureReason;
  statusCode?: number;
  response?: AxiosResponse;
  error: any;
}

const COMMUNICATION_ERROR_CODES = ['ECONNABORTED', 'ECONNREFUSED', 'ENOTFOUND', 'ECONNRESET'];

export const processFailureReason = (error: any): FailureData => {
  if (typeof error !== 'object') {
    return { reason: FailureReason.UnknownError, error };
  }

  if (error.message === 'Network Error' || COMMUNICATION_ERROR_CODES.includes(error.code)) {
    return { reason: FailureReason.CommunicationError, error };
  }

  const { response } = error;
  if (response == null || typeof response !== 'object' || typeof response.status !== 'number') {
    return { reason: FailureReason.UnknownError, error };
  }

  return {
    reason: FailureReason.ServerError,
    statusCode: response.status,
    response,
    error,
  };
};

import { Button, Icon } from 'antd';
import * as React from "react";

interface FailedStateComponentProps {
  children: React.ReactNode;
  tryAgain?: () => void;
}

export function FailedStateComponent({ children, tryAgain }: FailedStateComponentProps) {
  return (
    <div className="failed-state">
      <Icon type='warning' />
      {children}
      {tryAgain && (
        <Button type='primary' onClick={tryAgain}>Try again</Button>
      )}
    </div>
  );
}
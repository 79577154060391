import { createApiCaller } from '../../../common/services/ApiService';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { fetchDataWithActionCreator, LoadState } from '../../../common/store/fetched';
import { IAddUpdateInvoiceCustomItemRequest } from '../../reducers/billing/billing.models';
import { updateInvoiceCustomItemOperationUpdated } from './slice-actions';
import { processFailureReason } from '../../../common/store/failure';
import { message } from 'antd';
import { getInvoice } from './getInvoice';

export const updateInvoiceCustomItem = (invoiceId: number, invoiceCustomId: number, request: IAddUpdateInvoiceCustomItemRequest) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    await fetchDataWithActionCreator(
      updateInvoiceCustomItemOperationUpdated,
      dispatch,
      createApiCaller(api => api.updateData<void>(
        `/invoices/${invoiceId.toFixed(0)}/custom-items/${invoiceCustomId.toFixed(0)}`,
        undefined,
        request,
      )),
      processFailureReason,
      false,
    );

    const operation = getState().BillingReducer.updateInvoiceCustomItemOperation;
    if (operation.loadState === LoadState.Failed) {
      if (
        operation.failureReason.statusCode === 400
        && operation.failureReason.response?.data?.errorCode === 'InvalidStatus'
      ) {
        message.warning('The invoice is not in pending state.');
        dispatch(getInvoice(invoiceId, true));
      } else {
        message.error(
          `There was a problem with updating custom item. Please try again or refresh the page.`,
          5,
        );
      }
    } else if (operation.loadState === LoadState.Success) {
      message.success(`Successfully updated custom item.`);
      dispatch(getInvoice(invoiceId, true));
    }
  };
}
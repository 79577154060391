import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as UserActions from '../../actions/user/UserActions';
import UsersLinkedLocationsComponent from '../../components/user/UsersLinkedLocationsComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    assignLocationToUsersSuccess: state.UserReducer.assignLocationToUsersSuccess,
    assignLocationToUsersError: state.UserReducer.assignLocationToUsersError,

    unassignLocationFromUsersSuccess: state.UserReducer.unassignLocationFromUsersSuccess,
    unassignLocationFromUsersError: state.UserReducer.unassignLocationFromUsersError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(UserActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersLinkedLocationsComponent)

export type AutocompleteState = Partial<{
  loadCharitiesAutocompleteDataError: any;
  loadCharitiesAutocompleteDataSuccess: any;
  loadContactsAutocompleteDataError: any;
  loadContactsAutocompleteDataSuccess: any;
  loadStatesAutocompleteDataError: any;
  loadStatesAutocompleteDataSuccess: any;
  loadLocalCharitiesAutocompleteDataError: any;
  loadLocalCharitiesAutocompleteDataSuccess: any;
  loadSalesTaxesAutocompleteDataError: any;
  loadSalesTaxesAutocompleteDataSuccess: any;
}>;

const initialState: AutocompleteState = {};

const AutocompleteReducer = (state = initialState, action): AutocompleteState => {
  switch (action.type) {
    case "LOAD_CONTACTS_AUTOCOMPLETE_DATA_REQUEST":
      return { ...state, loadContactsAutocompleteDataSuccess: null, loadContactsAutocompleteDataError: null };
    case "LOAD_CONTACTS_AUTOCOMPLETE_DATA_SUCCESS":
      return { ...state, loadContactsAutocompleteDataSuccess: action.payload, loadContactsAutocompleteDataError: null };
    case "LOAD_CONTACTS_AUTOCOMPLETE_DATA_FAILURE":
      return { ...state, loadContactsAutocompleteDataSuccess: null, loadContactsAutocompleteDataError: action.payload };

    case "LOAD_CHARITIES_AUTOCOMPLETE_DATA_REQUEST":
      return { ...state, loadCharitiesAutocompleteDataSuccess: null, loadCharitiesAutocompleteDataError: null };
    case "LOAD_CHARITIES_AUTOCOMPLETE_DATA_SUCCESS":
      return { ...state, loadCharitiesAutocompleteDataSuccess: action.payload, loadCharitiesAutocompleteDataError: null };
    case "LOAD_CHARITIES_AUTOCOMPLETE_DATA_FAILURE":
      return { ...state, loadCharitiesAutocompleteDataSuccess: null, loadCharitiesAutocompleteDataError: action.payload };

    case "LOAD_STATES_AUTOCOMPLETE_DATA_REQUEST":
      return { ...state, loadStatesAutocompleteDataSuccess: null, loadStatesAutocompleteDataError: null };
    case "LOAD_STATES_AUTOCOMPLETE_DATA_SUCCESS":
      return { ...state, loadStatesAutocompleteDataSuccess: action.payload, loadStatesAutocompleteDataError: null };
    case "LOAD_STATES_AUTOCOMPLETE_DATA_FAILURE":
      return { ...state, loadStatesAutocompleteDataSuccess: null, loadStatesAutocompleteDataError: action.payload };

    case "LOAD_LOCALCHARITIES_AUTOCOMPLETE_DATA_REQUEST":
      return { ...state, loadLocalCharitiesAutocompleteDataSuccess: null, loadLocalCharitiesAutocompleteDataError: null };
    case "LOAD_LOCALCHARITIES_AUTOCOMPLETE_DATA_SUCCESS":
      return { ...state, loadLocalCharitiesAutocompleteDataSuccess: action.payload, loadLocalCharitiesAutocompleteDataError: null };
    case "LOAD_LOCALCHARITIES_AUTOCOMPLETE_DATA_FAILURE":
      return { ...state, loadLocalCharitiesAutocompleteDataSuccess: null, loadLocalCharitiesAutocompleteDataError: action.payload };

    case "LOAD_SALESTAXES_AUTOCOMPLETE_DATA_REQUEST":
      return { ...state, loadSalesTaxesAutocompleteDataSuccess: null, loadSalesTaxesAutocompleteDataError: null };
    case "LOAD_SALESTAXES_AUTOCOMPLETE_DATA_SUCCESS":
      return { ...state, loadSalesTaxesAutocompleteDataSuccess: action.payload, loadSalesTaxesAutocompleteDataError: null };
    case "LOAD_SALESTAXES_AUTOCOMPLETE_DATA_FAILURE":
      return { ...state, loadSalesTaxesAutocompleteDataSuccess: null, loadSalesTaxesAutocompleteDataError: action.payload };

    default:
      return state;
  }
}

export default AutocompleteReducer;

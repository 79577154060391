
import * as React from "react"
import { Row, Col } from "antd"
import { hashHistory } from "react-router"
const yesButton = require("../../../images/Button_Denom_Yes.png")
const noButton = require("../../../images/Button_Denom_No.png")
const syles = require('../../../common/styles/GameSelectStyles.css')

export interface IQuarterClosePayoutPageProps {
    payoutInMultiplesOf25(onlyMultiplesOf25 : boolean) : void
}

export interface IQuarterClosePayoutPageState {
}

export class QuarterClosePayoutOptionsPage extends React.Component<IQuarterClosePayoutPageProps> {

  constructor(props: IQuarterClosePayoutPageProps) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    
  }

  render() {

      const onOnlyMultiplesOf25 = (onlyMultiplesOf25: boolean) => {
          this.props.payoutInMultiplesOf25(onlyMultiplesOf25)
          hashHistory.push('/quarterClose/selectGames');
      };

    return (
        <div className="pg-container page-container gameSelectBackground">
            <Row type="flex" justify="center" align="middle">
                <Col >
                    <span style={{ textAlign: 'center' }} className="gameSelectHeader">SELECT PAYOUT OPTIONS</span>
                </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
                <Col>
                    <span className="payoutText">Do you wish to limit your title choices to games that only pay out in multiples of $0.25?</span>
                </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
                <Col>
                    <span className="payoutText">By Choosing "YES", the system will only allow you to select games in which all wins are in multiples of $0.25.</span>
                </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
                <Col>
                    <span className="payoutText">By Choosing "NO", the system will allow you to select games that payout in multiples of both $0.05 and $0.25. </span>
                </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
                <Col>
                    <img className="payoutButtons" onClick={() => { onOnlyMultiplesOf25(true); }} src={yesButton} alt="Only Use Quarter Multipler" />
                    <img className="payoutButtons" onClick={() => { onOnlyMultiplesOf25(false); }} src={noButton} alt="Only Use Quarter Multipler" />
                </Col>
            </Row>
      </div>
    )
  }
}

import { connect } from 'react-redux'

import { QUARTERCLOSE_PAYOUT_IN_MULTIPLES_OF_25_UPDATE } from '../../reducers/quarterClose/QuarterCloseReducer'
import { QuarterClosePayoutOptionsPage } from '../../components/quarterClose/QuarterClosePayoutOptionsPage'
import { RootState } from '../../../common/store/ConfigureStore';


const mapStateToProps = (state: RootState) => {
    return {
        locationListFilters: state.LocationsReducer.locationListFilters,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        payoutInMultiplesOf25: (onlyMultiplesOf25: boolean) => dispatch({ type: QUARTERCLOSE_PAYOUT_IN_MULTIPLES_OF_25_UPDATE, payload: onlyMultiplesOf25 })
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(QuarterClosePayoutOptionsPage)

import { downloadOhioWeeklyNumbersOperationUpdated } from './slice-actions';
import { DownloadedFile } from '../../../common/store/fileDownload';
import apiService from '../../../common/services/ApiService';
import { createStandardFetchedAction } from '../../../common/store/fetched.slices';
import { Moment } from 'moment';
import AppConstants from '../../../common/AppConstants';

export const downloadWeeklyNumbers = (startDate: Moment, endDate: Moment, invoicingSystemId: number) => createStandardFetchedAction(
  downloadOhioWeeklyNumbersOperationUpdated,
  async () => {
    const result = await apiService.downloadFile(
      `/reports/invoicing/weekly-numbers/excel/${invoicingSystemId}/`, null, { start: startDate.format(AppConstants.apiDateFormat), end: endDate.format(AppConstants.apiDateFormat) });

    const fileName = result.headers['x-filename'];
    return {
      data: result.data,
      fileName,
    } as DownloadedFile;
  },
  false,
);

import ApiService from "../../../common/services/ApiService"
import { hashHistory } from "react-router"
import { fetchData, IFetchedUpdated, resetFetched } from '../../../common/store/fetched';
import { IQuarterCloseLocationSetupDetails } from '../../reducers/reports/ReportsReducer';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { processFailureReason } from '../../../common/store/failure';

import {
    QUARTERCLOSE_SELECTED_LOCATIONS_UPDATE,
    QUARTERCLOSE_SELECTED_GAMESELECTIONWINDOWID_UPDATE,
    QUARTERCLOSE_SELECTED_WINDOWGAMES_UPDATE,
    QUARTERCLOSE_PAYOUT_IN_MULTIPLES_OF_25_UPDATE,
    QUARTERCLOSE_LOAD_LOCATIONS_FAILURE,
    QUARTERCLOSE_LOAD_LOCATIONS_REQUEST,
    QUARTERCLOSE_LOAD_LOCATIONS_SUCCESS,
    QUARTERCLOSE_LOAD_GAMES_FAILURE,
    QUARTERCLOSE_LOAD_GAMES_REQUEST,
    QUARTERCLOSE_LOAD_GAMES_SUCCESS,
    QUARTERCLOSE_LOAD_FEATUREDGAMES_FAILURE,
    QUARTERCLOSE_LOAD_FEATUREDGAMES_REQUEST,
    QUARTERCLOSE_LOAD_FEATUREDGAMES_SUCCESS,
    QUARTERCLOSE_LOAD_GAME_FLARES_REQUEST,
    QUARTERCLOSE_LOAD_GAME_FLARES_SUCCESS,
    QUARTERCLOSE_LOAD_GAME_FLARES_FAILURE,
    QUARTERCLOSE_SELECTED_GAME_UPDATE,
    QUARTERCLOSE_SELECTED_SETUP_UPDATE,
    QUARTERCLOSE_SELECTED_SETUP_REMOVE_GAME,
    QUARTERCLOSE_POST_LOCATION_SETUP_FAILURE,
    QUARTERCLOSE_POST_LOCATION_SETUP_REQUEST,
    QUARTERCLOSE_POST_LOCATION_SETUP_SUCCESS,
    QUARTERCLOSE_CLEAR_SELECTIONS,
    IQuarterCloseSelectedBet,
    IQuarterCloseLocationSetup,
    QUARTERCLOSE_GET_GAME_MEDIA_REQUEST,
    QUARTERCLOSE_GET_GAME_MEDIA_SUCCESS,
    QUARTERCLOSE_GET_GAME_MEDIA_FAILURE,
    QUARTERCLOSE_GET_LATEST_SETUP_UPDATED,
    QUARTERCLOSE_SELECTED_LOCATIONSWITHOUTSETTINGS_REQUEST,
    QUARTERCLOSE_SELECTED_LOCATIONSWITHOUTSETTINGS_SUCCESS,
    QUARTERCLOSE_SELECTED_LOCATIONSWITHOUTSETTINGS_ERROR,
    QUARTERCLOSE_SELECTED_LOCATIONSIMPLEDETAILS_REQUEST,
    QUARTERCLOSE_SELECTED_LOCATIONSIMPLEDETAILS_SUCCESS,
    QUARTERCLOSE_SELECTED_LOCATIONSIMPLEDETAILS_ERROR,
    QUARTERCLOSE_UPDATEBULKLOCATIONSETTINGS_REQUEST,
    QUARTERCLOSE_UPDATEBULKLOCATIONSETTINGS_SUCCESS,
    QUARTERCLOSE_UPDATEBULKLOCATIONSETTINGS_ERROR,
} from '../../reducers/quarterClose/QuarterCloseReducer'

export function loadLocations(params) {
    return function (dispatch) {
        dispatch({ type: QUARTERCLOSE_LOAD_LOCATIONS_REQUEST, payload: true });
        return ApiService.getData("/locations/quarterClose",params)
            .then((successResult) => {
                dispatch({ type: QUARTERCLOSE_LOAD_LOCATIONS_REQUEST, payload: false });
                dispatch({ type: QUARTERCLOSE_LOAD_LOCATIONS_SUCCESS, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: QUARTERCLOSE_LOAD_LOCATIONS_REQUEST, payload: false });
                dispatch({ type: QUARTERCLOSE_LOAD_LOCATIONS_FAILURE, payload: errorResult });
            });
    }
}

export function loadGameMedia(gameID: number) {
    return function (dispatch) {
        dispatch({ type: QUARTERCLOSE_GET_GAME_MEDIA_REQUEST, payload: true });
        return ApiService.getData(`/games/${gameID}/media`)
            .then((successResult) => {
                dispatch({ type: QUARTERCLOSE_GET_GAME_MEDIA_REQUEST, payload: false });
                dispatch({ type: QUARTERCLOSE_GET_GAME_MEDIA_SUCCESS, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: QUARTERCLOSE_GET_GAME_MEDIA_REQUEST, payload: false });
                dispatch({ type: QUARTERCLOSE_GET_GAME_MEDIA_FAILURE, payload: errorResult });
            });
    }
}

export function loadGames(gameSelectionWindowId: number) {
    return function (dispatch) {
        dispatch({ type: QUARTERCLOSE_LOAD_GAMES_REQUEST, payload: true });
        return ApiService.getData(`/games/${gameSelectionWindowId}`)
            .then((successResult) => {
                dispatch({ type: QUARTERCLOSE_LOAD_GAMES_REQUEST, payload: false });
                dispatch({ type: QUARTERCLOSE_LOAD_GAMES_SUCCESS, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: QUARTERCLOSE_LOAD_GAMES_REQUEST, payload: false });
                dispatch({ type: QUARTERCLOSE_LOAD_GAMES_FAILURE, payload: errorResult });
            });
    }
}

export function postLocationSetup(locationSetup: IQuarterCloseLocationSetup[]) {
    return function (dispatch, getState: () => RootState) {
        dispatch({ type: QUARTERCLOSE_POST_LOCATION_SETUP_REQUEST, payload: true });
        const request = getState().QuarterCloseReducer.latestSetup.data == null
          ? ApiService.postData("/locationSetup", locationSetup)
          : ApiService.updateData("/locationSetup", undefined, locationSetup);
        return request
            .then((successResult) => {
                dispatch({ type: QUARTERCLOSE_POST_LOCATION_SETUP_REQUEST, payload: false });
                dispatch({ type: QUARTERCLOSE_POST_LOCATION_SETUP_SUCCESS, payload: successResult.data });
                hashHistory.push('/quarterClose/confirmation')
            })
            .catch((errorResult) => {
                dispatch({ type: QUARTERCLOSE_POST_LOCATION_SETUP_REQUEST, payload: false });
                dispatch({ type: QUARTERCLOSE_POST_LOCATION_SETUP_FAILURE, payload: errorResult });
            });
    }
}

export function loadFlares(gameID: number) {
    return function (dispatch) {
        dispatch({ type: QUARTERCLOSE_LOAD_GAME_FLARES_REQUEST, payload: true });
        return ApiService.getData(`/games/${gameID}/details`)
            .then((successResult) => {
                dispatch({ type: QUARTERCLOSE_LOAD_GAME_FLARES_REQUEST, payload: false });
                dispatch({ type: QUARTERCLOSE_LOAD_GAME_FLARES_SUCCESS, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: QUARTERCLOSE_LOAD_GAME_FLARES_REQUEST, payload: false });
                dispatch({ type: QUARTERCLOSE_LOAD_GAME_FLARES_FAILURE, payload: errorResult });
            });
    }
}

export function getLocationsWithoutSettingsList(locationIds: Array<number>) {
  return function (dispatch) {
      dispatch({ type: QUARTERCLOSE_SELECTED_LOCATIONSWITHOUTSETTINGS_REQUEST });
      return ApiService.updateData("/locations/getLocationsWithoutSettings/", null, locationIds)
          .then((successResult) => {
              dispatch({ type: QUARTERCLOSE_SELECTED_LOCATIONSWITHOUTSETTINGS_SUCCESS, payload: successResult.data })
          })
          .catch((errorResult) => {
              dispatch({ type: QUARTERCLOSE_SELECTED_LOCATIONSWITHOUTSETTINGS_ERROR, payload: errorResult })
          });
  }
}

export function getLocationsSimpleDetails(locationIds: Array<number>) {
    return function (dispatch) {
        dispatch({ type: QUARTERCLOSE_SELECTED_LOCATIONSIMPLEDETAILS_REQUEST });
        return ApiService.updateData("/locations/getLocationsSimpleDetails/", null, locationIds)
            .then((successResult) => {
                dispatch({ type: QUARTERCLOSE_SELECTED_LOCATIONSIMPLEDETAILS_SUCCESS, payload: successResult.data })
            })
            .catch((errorResult) => {
                dispatch({ type: QUARTERCLOSE_SELECTED_LOCATIONSIMPLEDETAILS_ERROR, payload: errorResult })
            });
    }
}

export function updateBulkLocationsSettings(parms) {
    return function (dispatch) {
        dispatch({ type: QUARTERCLOSE_UPDATEBULKLOCATIONSETTINGS_REQUEST });
        return ApiService.updateData("/locations/updateBulkLocationsSettings/", null, parms)
            .then((successResult) => {
                dispatch({ type: QUARTERCLOSE_UPDATEBULKLOCATIONSETTINGS_SUCCESS, payload: successResult.data })
            })
            .catch((errorResult) => {
                dispatch({ type: QUARTERCLOSE_UPDATEBULKLOCATIONSETTINGS_ERROR, payload: errorResult })
            });
    }
}

export function clearSelections() {
    return {
        type: QUARTERCLOSE_CLEAR_SELECTIONS,
    }
}

export function updateSelectedLocations(locationIds: number[]) {
    return {
        type: QUARTERCLOSE_SELECTED_LOCATIONS_UPDATE,
        payload: locationIds
    }
}

export function updateSelectedGameSelectionWindowId(gameSelectionWindowId: number) {
  return {
    type: QUARTERCLOSE_SELECTED_GAMESELECTIONWINDOWID_UPDATE,
    payload: gameSelectionWindowId
  }
}

export function updateSelectedSetup(selectedBets: IQuarterCloseSelectedBet[]) {
    return {
        type: QUARTERCLOSE_SELECTED_SETUP_UPDATE,
        payload: selectedBets
    }
}

export function updateSelectedGameID(gameID: number) {
    return {
        type: QUARTERCLOSE_SELECTED_GAME_UPDATE,
        payload: gameID
    }
}

export function updateSelectedRemoveGame(gameID: number) {
    return {
        type: QUARTERCLOSE_SELECTED_SETUP_REMOVE_GAME,
        payload: gameID
    }
}

export function updateOnlyMultiplesOf25(onlyMultiplesOf25: boolean) {
    return {
        type: QUARTERCLOSE_PAYOUT_IN_MULTIPLES_OF_25_UPDATE,
        payload: onlyMultiplesOf25
    }
}

export type LatestSetupUpdatedAction = IFetchedUpdated<typeof QUARTERCLOSE_GET_LATEST_SETUP_UPDATED, IQuarterCloseLocationSetupDetails>;

export const getLatestLocationSetup = (locationId: number) => {
  return async (dispatch: AppDispatch) => {
    const result = await fetchData(
      QUARTERCLOSE_GET_LATEST_SETUP_UPDATED,
      dispatch,
      async () => {
        const response = await ApiService.getData(`/locationSetup/latestInCurrentPeriod/${locationId}`);
        return response.data as IQuarterCloseLocationSetupDetails;
      },
      err => processFailureReason(err),
    );
    if (result !== undefined) {
      const newSelectedSetup: IQuarterCloseSelectedBet[] = result.games.map(game => ({
        formType: game.formType,
        gameID: game.gameID,
        bets: game.bets.map(bet => bet.toString()),
        jackpot: game.gameDetails.jackpot,
        payout: game.gameDetails.payout,
        payoutOptions: game.gameDetails.winsMultipleOf25
          ? 'All Wins Multiple of $0.25'
          : 'All Wins Multiple of $0.05',
        readonly: true,
      }));
      dispatch(updateSelectedSetup(newSelectedSetup));

      let locationIds: number[] = [locationId];
      dispatch(getLocationsWithoutSettingsList(locationIds));
    }
  };
};

export const dismissLatestLocationSetup = () => {
  return async (dispatch: AppDispatch) => {
    resetFetched(QUARTERCLOSE_GET_LATEST_SETUP_UPDATED, dispatch);
  };
};

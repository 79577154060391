import * as React from 'react';
import WebAccessService from '../../services/WebAccessService';
import { Card, Row, Col, Button } from 'antd';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { hashHistory } from 'react-router';

const UnauthorizedAccessComponent: React.FC = () => {
  const { signOut } = useAuthenticator();
  const logout = () => {
    signOut();
    hashHistory.push('/login');
  };

  return (
    <Row>
      <Col span={24}>
        <Card title="Unauthorized access">
          <h2>Access to this place or content is restricted. If you think this is a mistake, please contact your administrator or the person who directed you here.</h2>
          <Button onClick={() => WebAccessService.navigateToFirstPermittedLink()}>Back</Button>
          <Button className="ml-8" onClick={logout}>
            Sign Out
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

export { UnauthorizedAccessComponent };

import * as React from "react";
import { isEqual } from "lodash";
import CharityDetailsContainer from "../../containers/charities/CharityDetailsContainer";
import AppUtilityService from "../../../common/services/AppUtilityService";
import { CharityLinkedLocationComponent } from "./CharityLinkedLocationComponent";

import { Layout, Row, Col } from 'antd';

export class CharityDetailsPage extends React.Component<ICharityDetailsPageProps, ICharityDetailsPageState> {

  constructor(props: ICharityDetailsPageProps) {
    super(props);

    this.state = {
      charityId: parseInt(this.props.params.charityId) || null,
      charityDetails: null
    }
  }

  componentWillReceiveProps(nextProps: ICharityDetailsPageProps) {
    if (nextProps.loadCharityDetailsSuccess && !isEqual(this.props.loadCharityDetailsSuccess, nextProps.loadCharityDetailsSuccess)) {
      var responseData: any = nextProps.loadCharityDetailsSuccess;
      this.setState({ charityDetails: responseData }, () => {
        this.breadcrumbsBuild()
      })
    }

  }

  render() {
    return (
      <div className="pg-container no-padding-page-container">
        <Layout>
          <CharityDetailsContainer charityId={this.state.charityId} />
          <div className="container">
            <br />
            <Row gutter={24}>
                        <Col xxl={8} xl={12} lg={14} xs={24} className="sm-row-b-margin md-row-b-margin lg-row-b-margin">
                            <CharityLinkedLocationComponent
                                locations={this.state.charityDetails && this.state.charityDetails.locations}
                                charityId={this.state.charityId}
                                generateAPIKey={this.props.actions.addApiKey}
                                generatedAPIKeyPassword={this.props.generatedAPIKeyPassword}
                                generatedAPIKeyUserName={this.props.generatedAPIKeyUserName}
                                revokeAPIKey={this.props.actions.revokeApiKey}
                            />
              </Col>
            </Row>
          </div>
        </Layout>
      </div>
    )
  }

  breadcrumbsBuild = () => {
    let breadcrumbsList = [
      { name: "Charity", link: "/charities" },
      { name: this.state.charityDetails.name, link: null }
    ]
    var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
    this.props.actions.updateBreadcrumbs(breadcrumbs);
  }


}

import * as React from "react";
import { isEqual } from "lodash";
import AppUtilityService from "../../services/AppUtilityService";
var Highcharts = require('highcharts');

export class ColumnDrilldownChartComponent extends React.Component<IColumnDrilldownChartProps, {}> {

    constructor(props: IColumnDrilldownChartProps) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        if (this.props.chartData) {
            this.renderChart(this.props.chartData);
        }
    }


    componentWillReceiveProps(nextProps: IColumnDrilldownChartProps) {
        if (nextProps.chartData && !isEqual(this.props.chartData, nextProps.chartData)) {
            this.renderChart(nextProps.chartData);
        }
    }

    render() {
        return (
            <div id={this.props.id} style={{ minHeight: this.props.height + "px" }} className="bar-chart-container"></div>
        )
    }


    renderChart = (chartData) => {
        var _self = this;
        if (isEqual(this.props.type, "location")){
            Highcharts.chart(this.props.id, {
                chart: {
                    type: 'column',
                    minHeight: this.props.height,
                },
                title: { text: '' },
                subtitle: { text: '' },
                xAxis: {
                    type: 'category',
                    labels: {
                        formatter: function () {
                            return '<a href="#/locations/' + _self.getId(this.value, chartData.data) + '">' + this.value + '</a>';
                        }
                    }
                },
                yAxis: {
                    labels: {
                        formatter() {
                            return _self.formatY(this.value, chartData.yAxisUnits);
                        },
                    },
                    title: {
                        text: chartData.yAxisTitle || ''
                    },
                },
                legend: {
                    enabled: false
                },
                lang: {
                    drillUpText: '< Back'
                },
                tooltip: {
                    formatter() {
                        return this.point.name + ' : ' + _self.formatY(this.y, chartData.yAxisUnits);
                    },
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false
                        }
                    }
                },
                series: [
                    {
                        name: chartData.name,
                        colorByPoint: true,
                        data: chartData.data
                    }
                ],
                drilldown: {
                    series: chartData.drilldownData
                },
                credits: {
                    enabled: false
                },
            });
        }
        if (isEqual(this.props.type, "game")) {
            Highcharts.chart(this.props.id, {
                chart: {
                    type: 'column',
                    minHeight: this.props.height,
                },
                title: { text: '' },
                subtitle: { text: '' },
                xAxis: {
                    type: 'category'
                },
                yAxis: {
                    labels: {
                        formatter() {
                            return _self.formatY(this.value, chartData.yAxisUnits);
                        },
                    },
                    title: {
                        text: chartData.yAxisTitle || ''
                    },
                },
                legend: {
                    enabled: false
                },
                lang: {
                    drillUpText: '< Back'
                },
                tooltip: {
                    formatter() {
                        return this.point.name + ' : ' + _self.formatY(this.y, chartData.yAxisUnits);
                    },
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false
                        }
                    }
                },
                series: [
                    {
                        name: chartData.name,
                        colorByPoint: true,
                        data: chartData.data
                    }
                ],
                drilldown: {
                    series: chartData.drilldownData
                },
                credits: {
                    enabled: false
                },
            });
        }
    }

    formatY = (value, units): string => {
        var val = "";
        switch (units) {
            case "currency":
                val = AppUtilityService.formatAmountAndShorten(value, 2)
                break;
            case "percent":
                val = AppUtilityService.shortenLargeNumber(value, 2) + "%"
                break;
            default:
                val = AppUtilityService.shortenLargeNumber(value, 2)
                break;
        }
        return val;
    }
    getId = (value, data): string => {
        var val = "";
        val = data.find(element => element.name == value).id;
        return val;
    }

}
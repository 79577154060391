import * as React from "react";
import { isEqual } from "lodash";
import AppUtilityService from "../../../common/services/AppUtilityService";
import ReconSummaryReportContainer from "../../containers/reports/locationStatisticsReports/ReconSummaryReportContainer";
import WebAccessService from "../../../common/services/WebAccessService";

import { Card, Menu, Dropdown, Button, Icon, Radio } from 'antd';
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

export class LocationDetailsReportTabComponent extends React.Component<ILocationReportTabProps, ILocationReportTabState> {

  private reconSummaryReportRef: any;

  constructor(props: ILocationReportTabProps) {
    super(props);

    this.state = {
      dateRange: this.props.locationDetailsDateFilter || [],
      reportType: "",
      isLoadingList: false,
      isLoadingSummaryInfo: false,
      apiParams: null
    }
  }

  componentDidMount() {
    this.getReportType();
  }


  componentWillReceiveProps(nextProps: ILocationReportTabProps) {
    if (nextProps.locationDetailsDateFilter && !isEqual(this.props.locationDetailsDateFilter, nextProps.locationDetailsDateFilter)) {
      this.setState({ dateRange: nextProps.locationDetailsDateFilter }, () => {
        this.applyFilters()
      });
    }

    if (nextProps.loadReconSummaryListSuccess && !isEqual(this.props.loadReconSummaryListSuccess, nextProps.loadReconSummaryListSuccess)) {
      this.setState({ isLoadingList: false })
    }
    if (nextProps.loadReconSummaryListError && !isEqual(this.props.loadReconSummaryListError, nextProps.loadReconSummaryListError)) {
      this.setState({ isLoadingList: false })
    }

    if (nextProps.loadReconSummaryInfoSuccess && !isEqual(this.props.loadReconSummaryInfoSuccess, nextProps.loadReconSummaryInfoSuccess)) {
      this.setState({ isLoadingSummaryInfo: false })
    }
    if (nextProps.loadReconSummaryInfoError && !isEqual(this.props.loadReconSummaryInfoError, nextProps.loadReconSummaryInfoError)) {
      this.setState({ isLoadingSummaryInfo: false })
    }

  }

  render() {
    const extraActions = <div className="location-detail-filters">
      <span>
        <RadioGroup onChange={this.typeFilterChange} value={this.state.reportType}
          disabled={this.state.isLoadingList || this.state.isLoadingSummaryInfo}>
          {
            WebAccessService.hasPermissionToAccess("Reports", "ByDate") && <RadioButton key="ByDate" value="ByDate">Date</RadioButton>
          }
          {
            WebAccessService.hasPermissionToAccess("Reports", "ByGame") && <RadioButton key="ByGame" value="ByGame">Game</RadioButton>
          }
          {
            WebAccessService.hasPermissionToAccess("Reports", "ByGT") && <RadioButton key="ByGT" value="ByGT">Game Terminal</RadioButton>
          }
        </RadioGroup>
      </span>
      {
        WebAccessService.hasPermissionToAccess("Location", "Download") &&
        <span>
          <Dropdown.Button className="ml-8" type="primary" onClick={this.downloadData}
            disabled={this.state.isLoadingList || this.state.isLoadingSummaryInfo}
            overlay={
              <Menu onClick={this.downloadList}>
                <Menu.Item key="1">PDF</Menu.Item>
                <Menu.Item key="2">Excel</Menu.Item>
              </Menu>
            }>
            <Icon type="download" style={{ fontSize: "16px" }} /> Download as PDF
        </Dropdown.Button>
        </span>
      }
    </div>;

    return (
      <div className="container">
        <Card className="no-header-border no-body-padding" bordered={false}
          title="Location Statistics"
          extra={extraActions}
        >
          {
            this.state.reportType === "ByDate" && WebAccessService.hasPermissionToAccess("Reports", "ByDate") &&
            <ReconSummaryReportContainer
              showChart={false}
              showSummary={true}
              apiParams={this.state.apiParams}
              reportType={this.state.reportType}
              onRef={ref => (this.reconSummaryReportRef = ref)}
            />
          }
          {
            this.state.reportType === "ByGame" && WebAccessService.hasPermissionToAccess("Reports", "ByGame") &&
            <ReconSummaryReportContainer
              showChart={false}
              showSummary={true}
              apiParams={this.state.apiParams}
              reportType={this.state.reportType}
              onRef={ref => (this.reconSummaryReportRef = ref)}
            />
          }
          {
            this.state.reportType === "ByGT" && WebAccessService.hasPermissionToAccess("Reports", "ByGT") &&
            <ReconSummaryReportContainer
              showChart={false}
              showSummary={true}
              apiParams={this.state.apiParams}
              reportType={this.state.reportType}
              onRef={ref => (this.reconSummaryReportRef = ref)}
            />
          }
        </Card>
      </div>
    )
  }

  getReportType = () => {
    if (WebAccessService.hasPermissionToAccess("Reports", "ByDate")) {
      this.setState({ reportType: "ByDate" }, () => {
        this.applyFilters();
      })
    } else if (WebAccessService.hasPermissionToAccess("Reports", "ByGame")) {
      this.setState({ reportType: "ByGame" }, () => {
        this.applyFilters();
      })
    } else if (WebAccessService.hasPermissionToAccess("Reports", "ByGT")) {
      this.setState({ reportType: "ByGT" }, () => {
        this.applyFilters();
      })
    }
  }

  typeFilterChange = (e) => {
    this.setState({ reportType: e.target.value }, () => {
      this.applyFilters()
    })
  }

  applyFilters = () => {
    var { dateRange, reportType } = this.state;
    var apiParams = {
      reportType: reportType,
      locationId: this.props.locationId || 0,
      dateFrom: dateRange && dateRange[0] ? AppUtilityService.formatAPIDateFilter(dateRange[0]) : null,
      dateTo: dateRange && dateRange[1] ? AppUtilityService.formatAPIDateFilter(dateRange[1]) : null,
    }
    this.setState({
      apiParams,
      isLoadingList: true,
      isLoadingSummaryInfo: true,
    })
  }


  downloadData = (e) => {
    e.key = "1";
    this.downloadList(e)
  }

  downloadList = (e) => {
    var downloadType = "pdf";
    switch (e.key) {
      case "1":
        downloadType = "pdf";
        break;
      case "2":
        downloadType = "excel";
        break;
      default: break;
    }
    this.reconSummaryReportRef.getDownloadData(downloadType);
  }


}

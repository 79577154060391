export type LocationsState = Partial<{
  deleteLocationZoneSuccess: any;
  updateLocationBasicDetailsSuccess: any;
  addNewLocationError: any;
  loadLocationDateSummaryError: any;
  loadBasicDetailsError: any;
  addLocationCharityError: any;
  deleteLocationCharitySuccess: any;
  loadLocationRecentActivitySuccess: any;
  addNewLocationSuccess: any;
  addLocationContactSuccess: any;
  loadStatsSuccess: any;
  loadLocationGameSummarySuccess: any;
  addLocationZoneSuccess: any;
  loadListDataSuccess: any;
  updateLocationContactError: any;
  deleteLocationContactError: any;
  deleteLocationZoneError: any;
  loadLocationRecentActivityError: any;
  updateLocationContactSuccess: any;
  deleteLocationError: any;
  loadInvoicesListSuccess: any;
  deleteLocationContactSuccess: any;
  locationDetailsDateFilter: any;
  locationListFilters: any;
  updateLocationBasicDetailsError: any;
  addLocationZoneError: any;
  loadStatsError: any;
  loadInvoicesListError: any;
  deleteLocationCharityError: any;
  loadLocationDateSummarySuccess: any;
  loadLocationGameSummaryError: any;
  addLocationContactError: any;
  loadListDataError: any;
  addLocationCharitySuccess: any;
  deleteLocationSuccess: any;
  loadBasicDetailsSuccess: any;
  loadUserStampsSuccess: any;
  loadUserStampsError: any;
}>;

const initialState: LocationsState = {};

const LocationsReducer = (state = initialState, action): LocationsState => {
  switch (action.type) {
    case "LOCATION_LIST_FILTER_UPDATE":
      return { ...state, locationListFilters: action.payload };
    // Locations List related
    case "LOAD_LOCATIONS_LIST_DATA_REQUEST":
      return { ...state, loadListDataSuccess: null, loadListDataError: null };
    case "LOAD_LOCATIONS_LIST_DATA_SUCCESS":
      return { ...state, loadListDataSuccess: action.payload, loadListDataError: null };
    case "LOAD_LOCATIONS_LIST_DATA_FAILURE":
      return { ...state, loadListDataSuccess: null, loadListDataError: action.payload };

    // Location add new
    case "ADD_NEW_LOCATION_REQUEST":
      return { ...state, addNewLocationSuccess: null, addNewLocationError: null };
    case "ADD_NEW_LOCATION_SUCCESS":
      return { ...state, addNewLocationSuccess: action.payload, addNewLocationError: null };
    case "ADD_NEW_LOCATION_FAILURE":
      return { ...state, addNewLocationSuccess: null, addNewLocationError: action.payload };

    // Location update
    case "UPDATE_LOCATION_BASIC_DETAILS_REQUEST":
      return { ...state, updateLocationBasicDetailsSuccess: null, updateLocationBasicDetailsError: null };
    case "UPDATE_LOCATION_BASIC_DETAILS_SUCCESS":
      return { ...state, updateLocationBasicDetailsSuccess: action.payload, updateLocationBasicDetailsError: null };
    case "UPDATE_LOCATION_BASIC_DETAILS_FAILURE":
      return { ...state, updateLocationBasicDetailsSuccess: null, updateLocationBasicDetailsError: action.payload };

    // Location delete
    case "DELETE_LOCATION_REQUEST":
      return { ...state, deleteLocationSuccess: null, deleteLocationError: null };
    case "DELETE_LOCATION_SUCCESS":
      return { ...state, deleteLocationSuccess: action.payload, deleteLocationError: null };
    case "DELETE_LOCATION_FAILURE":
      return { ...state, deleteLocationSuccess: null, deleteLocationError: action.payload };

    // Location detail date filter
    case "LOCATION_DETAIL_DATE_FILTER_UPDATE":
      return { ...state, locationDetailsDateFilter: action.payload };

    // Location basic details
    case "LOAD_LOCATION_BASIC_DETAILS_REQUEST":
      return { ...state, loadBasicDetailsSuccess: null, loadBasicDetailsError: null };
    case "LOAD_LOCATION_BASIC_DETAILS_SUCCESS":
      return { ...state, loadBasicDetailsSuccess: action.payload, loadBasicDetailsError: null };
    case "LOAD_LOCATION_BASIC_DETAILS_FAILURE":
      return { ...state, loadBasicDetailsSuccess: null, loadBasicDetailsError: action.payload };

    // Location basic details stats
    case "LOAD_LOCATION_STATS_REQUEST":
      return { ...state, loadStatsSuccess: null, loadStatsError: null };
    case "LOAD_LOCATION_STATS_SUCCESS":
      return { ...state, loadStatsSuccess: action.payload, loadStatsError: null };
    case "LOAD_LOCATION_STATS_FAILURE":
      return { ...state, loadStatsSuccess: null, loadStatsError: action.payload };

    // Location game summary chart
    case "LOAD_LOCATION_GAME_SUMMARY_REQUEST":
      return { ...state, loadLocationGameSummarySuccess: null, loadLocationGameSummaryError: null };
    case "LOAD_LOCATION_GAME_SUMMARY_SUCCESS":
      return { ...state, loadLocationGameSummarySuccess: action.payload, loadLocationGameSummaryError: null };
    case "LOAD_LOCATION_GAME_SUMMARY_FAILURE":
      return { ...state, loadLocationGameSummarySuccess: null, loadLocationGameSummaryError: action.payload };
    // Location game summary chart
    case "LOAD_LOCATION_DATE_SUMMARY_REQUEST":
      return { ...state, loadLocationDateSummarySuccess: null, loadLocationDateSummaryError: null };
    case "LOAD_LOCATION_DATE_SUMMARY_SUCCESS":
      return { ...state, loadLocationDateSummarySuccess: action.payload, loadLocationDateSummaryError: null };
    case "LOAD_LOCATION_DATE_SUMMARY_FAILURE":
      return { ...state, loadLocationDateSummarySuccess: null, loadLocationDateSummaryError: action.payload };

    // Location recent activity
    case "LOAD_LOCATION_RECENT_ACTIVITY_REQUEST":
      return { ...state, loadLocationRecentActivitySuccess: null, loadLocationRecentActivityError: null };
    case "LOAD_LOCATION_RECENT_ACTIVITY_SUCCESS":
      return { ...state, loadLocationRecentActivitySuccess: action.payload, loadLocationRecentActivityError: null };
    case "LOAD_LOCATION_RECENT_ACTIVITY_FAILURE":
      return { ...state, loadLocationRecentActivitySuccess: null, loadLocationRecentActivityError: action.payload };

    // Location contact CRUD related
    case "LOCATION_LINKED_CONTACT_DELETE_REQUEST":
      return { ...state, deleteLocationContactSuccess: null, deleteLocationContactError: null };
    case "LOCATION_LINKED_CONTACT_DELETE_SUCCESS":
      return { ...state, deleteLocationContactSuccess: action.payload, deleteLocationContactError: null };
    case "LOCATION_LINKED_CONTACT_DELETE_FAILURE":
      return { ...state, deleteLocationContactSuccess: null, deleteLocationContactError: action.payload };

    case "LOCATION_LINKED_CONTACT_ADD_REQUEST":
      return { ...state, addLocationContactSuccess: null, addLocationContactError: null };
    case "LOCATION_LINKED_CONTACT_ADD_SUCCESS":
      return { ...state, addLocationContactSuccess: action.payload, addLocationContactError: null };
    case "LOCATION_LINKED_CONTACT_ADD_FAILURE":
      return { ...state, addLocationContactSuccess: null, addLocationContactError: action.payload };

    case "LOCATION_LINKED_CONTACT_UPDATE_REQUEST":
      return { ...state, updateLocationContactSuccess: null, updateLocationContactError: null };
    case "LOCATION_LINKED_CONTACT_UPDATE_SUCCESS":
      return { ...state, updateLocationContactSuccess: action.payload, updateLocationContactError: null };
    case "LOCATION_LINKED_CONTACT_UPDATE_FAILURE":
      return { ...state, updateLocationContactSuccess: null, updateLocationContactError: action.payload };

    // Location charity CRUD related
    case "LOCATION_LINKED_CHARITY_ADD_REQUEST":
      return { ...state, addLocationCharitySuccess: null, addLocationCharityError: null };
    case "LOCATION_LINKED_CHARITY_ADD_SUCCESS":
      return { ...state, addLocationCharitySuccess: action.payload, addLocationCharityError: null };
    case "LOCATION_LINKED_CHARITY_ADD_FAILURE":
      return { ...state, addLocationCharitySuccess: null, addLocationCharityError: action.payload };

    case "LOCATION_LINKED_CHARITY_DELETE_REQUEST":
      return { ...state, deleteLocationCharitySuccess: null, deleteLocationCharityError: null };
    case "LOCATION_LINKED_CHARITY_DELETE_SUCCESS":
      return { ...state, deleteLocationCharitySuccess: action.payload, deleteLocationCharityError: null };
    case "LOCATION_LINKED_CHARITY_DELETE_FAILURE":
      return { ...state, deleteLocationCharitySuccess: null, deleteLocationCharityError: action.payload };

    case "LOCATION_LINKED_ZONE_ADD_REQUEST":
      return { ...state, addLocationZoneSuccess: null, addLocationZoneError: null };
    case "LOCATION_LINKED_ZONE_ADD_SUCCESS":
      return { ...state, addLocationZoneSuccess: action.payload, addLocationZoneError: null };
    case "LOCATION_LINKED_ZONE_ADD_FAILURE":
      return { ...state, addLocationZoneSuccess: null, addLocationZoneError: action.payload };

    case "LOCATION_LINKED_ZONE_DELETE_REQUEST":
      return { ...state, deleteLocationZoneSuccess: null, deleteLocationZoneError: null };
    case "LOCATION_LINKED_ZONE_DELETE_SUCCESS":
      return { ...state, deleteLocationZoneSuccess: action.payload, deleteLocationZoneError: null };
    case "LOCATION_LINKED_ZONE_DELETE_FAILURE":
      return { ...state, deleteLocationZoneSuccess: null, deleteLocationZoneError: action.payload };

    // Location invoices list
    case "LOAD_LOCATION_INVOICES_LIST_REQUEST":
      return { ...state, loadInvoicesListSuccess: null, loadInvoicesListError: null };
    case "LOAD_LOCATION_INVOICES_LIST_SUCCESS":
      return { ...state, loadInvoicesListSuccess: action.payload, loadInvoicesListError: null };
    case "LOAD_LOCATION_INVOICES_LIST_FAILURE":
      return { ...state, loadInvoicesListSuccess: null, loadInvoicesListError: action.payload };


    case "LOAD_DISTRIBUTOR_USER_STAMP_LIST_DATA_REQUEST":
      return { ...state, loadUserStampsSuccess: null, loadUserStampsError: null };
    case "LOAD_DISTRIBUTOR_USER_STAMP_LIST_DATA_SUCCESS":
      return { ...state, loadUserStampsSuccess: action.payload, loadUserStampsError: null };
    case "LOAD_DISTRIBUTOR_USER_STAMP_LIST_DATA_FAILURE":
      return { ...state, loadUserStampsSuccess: null, loadUserStampsError: action.payload };      
    default:
      return state;
  }
}

export default LocationsReducer;

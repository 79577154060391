import { weeklyNumbersReportUpdated } from './slice-actions';
import apiService from '../../../common/services/ApiService';
import { createStandardFetchedAction } from '../../../common/store/fetched.slices';
import { Moment } from 'moment';
import AppConstants from '../../../common/AppConstants';

export const getWeeklyNumbersReport = (startDate: Moment, endDate: Moment, invoicingSystemId: number) => createStandardFetchedAction(
  weeklyNumbersReportUpdated,
  async () => {

    const result = await apiService.getData(
      `/reports/invoicing/weekly-numbers/${invoicingSystemId}/${startDate.format(AppConstants.apiDateFormat)}/${endDate.format(AppConstants.apiDateFormat)}`);
    return result.data.reportsData;
  },
  false,
);
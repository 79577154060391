import * as React from "react";
import { isEqual, merge } from "lodash";
import moment from "moment";

import ZonesDropdownContainer from "../../../common/containers/dropdown/ZonesDropdownContainer";
import CashMiniCardContainer from "../../containers/dashboard/CashMiniCardContainer";
import GamesMiniCardContainer from "../../containers/dashboard/GamesMiniCardContainer";
import LocationsAutocompleteContainer from "../../../common/containers/dropdown/LocationsAutocompleteContainer";
import GamePlayCashInCardContainer from "../../containers/dashboard/GamePlayCashInCardContainer";
import MapStatsCardContainer from "../../containers/dashboard/MapStatsCardContainer";
import AppUtilityService from "../../../common/services/AppUtilityService";
import WebAccessService from "../../../common/services/WebAccessService";
import AppConstants from "../../../common/AppConstants";
import { RangepickerComponent } from "../../../common/components/datepicker/RangepickerComponent";

import SessionService from "../../../common/services/SessionService";
import { Layout, Row, Col, Card, Button, Form } from 'antd';
const FormItem = Form.Item;

export class DashboardPage extends React.Component<IDashboardPageProps, IDashboardPageState> {

  private zonesDropdownRef: any;
  private locationsDropdownRef: any;

  constructor(props: IDashboardPageProps) {
    super(props);

    this.state = {
      dateRange: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      zoneId: null,
      autoselectFirstZone: false,
      selectedZoneName: null,
      locationId: null,
      selectedLocationName: null,
      isLoadingMiniStats: false,
      isLoadingMapStats: false,
      isLoadingChartStats: false,
      mapParams: {
        pinType: "zone",
        type: AppConstants.dashboardMapStatsMetrics && AppConstants.dashboardMapStatsMetrics[0].value,
        location: null
      },
      gameCashChartParams: {
        metricType: AppConstants.gamePlayCashInMetrics && AppConstants.gamePlayCashInMetrics[0].value
      }
    }
  }

  componentDidMount() {
    this.breadcrumbsBuild()
    if (this.props.dashboardFilters) {
      this.handlePresetFilters(this.props.dashboardFilters, true)
    } else {
      if (SessionService.getRole() !== "Admin") {
        this.setState({ autoselectFirstZone: true })
      } else {
        this.loadDashboardData();
      }
    }
  }

  componentWillReceiveProps(nextProps: IDashboardPageProps) {
    if (nextProps.loadDashboardMiniStatsSuccess && !isEqual(this.props.loadDashboardMiniStatsSuccess, nextProps.loadDashboardMiniStatsSuccess)) {
      this.setState({ isLoadingMiniStats: false })
    }
    if (nextProps.loadDashboardMapStatsSuccess && !isEqual(this.props.loadDashboardMapStatsSuccess, nextProps.loadDashboardMapStatsSuccess)) {
      this.setState({ isLoadingMapStats: false })
    }
    if (nextProps.loadDashboardChartStatsSuccess && !isEqual(this.props.loadDashboardChartStatsSuccess, nextProps.loadDashboardChartStatsSuccess)) {
      this.setState({ isLoadingChartStats: false })
    }

  }

  render() {
    const formItemLayout = {
      labelCol: { xs: { span: 24 }, sm: { span: 4 }, },
      wrapperCol: { xs: { span: 24 }, sm: { span: 20 }, },
    };
    return (
      <>
        {
          (
            WebAccessService.hasPermissionToAccess("Dashboard", "ViewCashStats") ||
            WebAccessService.hasPermissionToAccess("Dashboard", "ViewGameStats") ||
            WebAccessService.hasPermissionToAccess("Dashboard", "ViewMap") ||
            WebAccessService.hasPermissionToAccess("Dashboard", "ViewChart")
          ) &&
          <Card style={{ paddingTop: "12px" }} className="dashboard-filter-card content-card no-header-border" bordered={false}>
            <div className="dashboard-filter">
              <Form>
                <Row gutter={24} type="flex">
                  <Col xl={7} lg={7} md={12} xs={24}>
                    <FormItem
                      validateStatus={this.state.zoneId ? "success" : null}
                      label="Zones:"
                      labelCol={{ xs: { span: 24 }, xl: { span: 4 }, xxl: { span: 3 } }}
                      wrapperCol={{ xs: { span: 24 }, xl: { span: 18 }, xxl: { span: 14 } }}
                    >
                      <ZonesDropdownContainer
                        placeholder="Select zone"
                        autoselectFirstZone={this.state.autoselectFirstZone}
                        value={this.state.zoneId}
                        onChange={this.handleZoneChange}
                        getSelectedLabel={this.getSelectedZoneName}
                        onRef={ref => (this.zonesDropdownRef = ref)} />
                    </FormItem>
                  </Col>
                  <Col xl={7} lg={7} md={12} xs={24}>
                    <FormItem
                      validateStatus={this.state.locationId ? "success" : null}
                      label="Location:"
                      labelCol={{ xs: { span: 24 }, xl: { span: 6 }, xxl: { span: 4 } }}
                      wrapperCol={{ xs: { span: 24 }, xl: { span: 18 }, xxl: { span: 14 } }}
                    >
                      <LocationsAutocompleteContainer
                        placeholder="Select location"
                        preselectedSearchTerm={this.state.selectedLocationName}
                        onRef={ref => (this.locationsDropdownRef = ref)}
                        value={this.state.locationId}
                        customParams={{ zoneId: this.state.zoneId }}
                        onChange={this.handleLocationChange}
                        getSelectedLabel={this.getSelectedLocationName}
                      />
                    </FormItem>
                  </Col>
                  <Col xl={8} lg={7} md={12} xs={24}>
                    <FormItem
                      label="Date range:"
                      labelCol={{ xs: { span: 24 }, xl: { span: 6 }, xxl: { span: 5 } }}
                      wrapperCol={{ xs: { span: 24 }, xl: { span: 18 }, xxl: { span: 14 } }}
                    >
                      <RangepickerComponent
                        dateRange={this.state.dateRange}
                        onChange={this.dateRangeFilterChange} />
                    </FormItem>
                  </Col>
                  <Col xl={2} lg={3} md={12} style={{ alignSelf: 'end' }}>
                    <Button type={(this.state.zoneId || this.state.locationId) ? "primary" : "default"} onClick={this.resetMapStats}>Reset</Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card>
        }
        <div className="pg-dashboard-container">
          <Layout>
            <div className="container">
              {
                (WebAccessService.hasPermissionToAccess("Dashboard", "ViewCashStats") || WebAccessService.hasPermissionToAccess("Dashboard", "ViewGameStats")) &&
                <div>
                  <Row gutter={24}>
                    {
                      WebAccessService.hasPermissionToAccess("Dashboard", "ViewCashStats") &&
                      <Col sm={24} lg={12} className="sm-row-b-margin md-row-b-margin">
                        <CashMiniCardContainer
                          isLoading={this.state.isLoadingMiniStats} />
                      </Col>
                    }
                    {
                      WebAccessService.hasPermissionToAccess("Dashboard", "ViewGameStats") &&
                      <Col sm={24} lg={12}>
                        <GamesMiniCardContainer
                          isLoading={this.state.isLoadingMiniStats} />
                      </Col>
                    }
                  </Row>
                  <br />
                </div>
              }
              {
                WebAccessService.hasPermissionToAccess("Dashboard", "ViewMap") &&
                <div>
                  <Row>
                    <Col span={24}>
                      <MapStatsCardContainer
                        zoneId={this.state.zoneId}
                        selectedZoneName={this.state.selectedZoneName}
                        selectedLocationName={this.state.selectedLocationName}
                        locationId={this.state.locationId}
                        loadDashboardMapStatsCallback={this.handleLoadDashboardMapStats}
                        selectedZoneNameChangeCallback={this.getSelectedZoneName}
                        selectedZoneLayerCallback={this.handleZoneChange}
                        isLoading={this.state.isLoadingMapStats}
                      />
                    </Col>
                  </Row>
                  <br />
                </div>
              }
              {
                WebAccessService.hasPermissionToAccess("Dashboard", "ViewChart") &&
                <Row>
                  <Col span={24}>
                    <GamePlayCashInCardContainer
                      loadDashboardChartDataCallback={this.handleLoadDashboardChartData}
                      isLoading={this.state.isLoadingChartStats}
                    />
                  </Col>
                </Row>
              }
            </div>
            <div>
              <br />
            </div>
          </Layout>
        </div>
      </>
    )
  }

  breadcrumbsBuild = () => {
    let breadcrumbsList = [
      { name: "Dashboard", link: null }
    ]
    var breadcrumbs: Breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
    this.props.actions.updateBreadcrumbs(breadcrumbs);
  }


  handleZoneChange = (e) => {
    var mapParams = { ...this.state.mapParams };
    mapParams.location = null;
    this.setState({ zoneId: e, locationId: null, selectedLocationName: null, mapParams }, () => {
      this.loadDashboardData();
    })
  }
  getSelectedZoneName = (value) => {
    this.setState({ selectedZoneName: value })
  }
  handleLocationChange = (e) => {
    var mapParams = { ...this.state.mapParams };
    mapParams.location = null;
    this.setState({ locationId: e, mapParams }, () => {
      this.loadDashboardData();
    })
  }
  getSelectedLocationName = (value) => {
    this.setState({ selectedLocationName: value })
  }

  dateRangeFilterChange = (date) => {
    this.setState({ dateRange: date }, () => {
      this.loadDashboardData();
    });
  }
  // ----------------------------------------------------------------------------
  resetMapStats = () => {
    this.setState({
      dateRange: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      zoneId: null,
      selectedZoneName: null,
      locationId: null,
      selectedLocationName: null,
    }, () => {
      if (this.state.autoselectFirstZone) {
        this.zonesDropdownRef.autoselectZone()
      }
      this.locationsDropdownRef.resetDatasource()
      this.loadDashboardData();
      this.props.actions.updateDashboardFilters(null);
    })
  }
  // ----------------------------------------------------------------------------

  loadDashboardData = () => {
    this.setState({ isLoadingMiniStats: true, isLoadingChartStats: true, isLoadingMapStats: true })
    var { dateRange, zoneId, selectedZoneName, locationId, selectedLocationName } = this.state;
    var params = {
      zoneId: zoneId || null,
      locationId: locationId || null,
      selectedZoneName: selectedZoneName || null,
      selectedLocationName: selectedLocationName || null,
      dateFrom: dateRange && dateRange[0] ? AppUtilityService.formatAPIDateFilter(dateRange[0]) : null,
      dateTo: dateRange && dateRange[1] ? AppUtilityService.formatAPIDateFilter(dateRange[1]) : null,
    }
    this.props.actions.loadDashboardMiniStats(params);
    this.handleLoadDashboardMapStats(this.state.mapParams)
    this.handleLoadDashboardChartData(this.state.gameCashChartParams)
    this.props.actions.updateDashboardFilters(params);
  }

  handleLoadDashboardMapStats = (mapParams) => {
    this.setState({ mapParams })
    var { zoneId, locationId, dateRange } = this.state;
    var params = {
      zoneId: zoneId || null,
      locationId: locationId || null,
      dateFrom: dateRange && dateRange[0] ? AppUtilityService.formatAPIDateFilter(dateRange[0]) : null,
      dateTo: dateRange && dateRange[1] ? AppUtilityService.formatAPIDateFilter(dateRange[1]) : null,
    }
    var newParams = merge(params, mapParams)
    this.props.actions.loadDashboardMapStats(newParams)
  }

  handleLoadDashboardChartData = (gameCashChartParams) => {
    this.setState({ gameCashChartParams })
    var { zoneId, locationId, dateRange } = this.state;
    var params = {
      zoneId: zoneId || null,
      locationId: locationId || null,
      dateFrom: dateRange && dateRange[0] ? AppUtilityService.formatAPIDateFilter(dateRange[0]) : null,
      dateTo: dateRange && dateRange[1] ? AppUtilityService.formatAPIDateFilter(dateRange[1]) : null,
    }
    var newParams = merge(params, gameCashChartParams)
    this.props.actions.loadDashboardChartStats(newParams)
  }

  handlePresetFilters = (filterData, isFirstMount) => {
    var dateRange = [moment(filterData.dateFrom), moment(filterData.dateTo)];
    var zoneId = filterData.zoneId;
    var locationId = filterData.locationId;
    var selectedZoneName = filterData.selectedZoneName;
    var selectedLocationName = filterData.selectedLocationName;
    this.setState({ dateRange, zoneId, selectedZoneName, locationId, selectedLocationName }, () => {
      if (isFirstMount) {
        this.loadDashboardData()
      }
    })
  }
}

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as LocationsActions from '../../actions/locations/LocationsActions';
import { LocationsListTableComponent } from '../../components/locations/LocationsListTableComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    locationListFilters: state.LocationsReducer.locationListFilters,

    locationsListSuccess: state.LocationsReducer.loadListDataSuccess,
    locationListError: state.LocationsReducer.loadListDataError,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(LocationsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationsListTableComponent)

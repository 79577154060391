import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as LocationsActions from '../../actions/locations/LocationsActions';
import { SummaryChartCardComponent } from '../../components/locations/SummaryChartCardComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    locationDetailsDateFilter: state.LocationsReducer.locationDetailsDateFilter,

    loadLocationGameSummarySuccess: state.LocationsReducer.loadLocationGameSummarySuccess,
    loadLocationGameSummaryError: state.LocationsReducer.loadLocationGameSummaryError,

    loadLocationDateSummarySuccess: state.LocationsReducer.loadLocationDateSummarySuccess,
    loadLocationDateSummaryError: state.LocationsReducer.loadLocationDateSummaryError,

    loadBasicDetailsSuccess: state.LocationsReducer.loadBasicDetailsSuccess,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(LocationsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryChartCardComponent)

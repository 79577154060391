
import * as React from "react"
import { Row, Col, List } from "antd"
import { hashHistory } from "react-router"
import { ILocationSimpleDetail } from "../../reducers/quarterClose/QuarterCloseReducer"
import "./QuarterCloseListStyles.css";
const yesButton = require("../../../images/Button_Denom_Yes.png")
const noButton = require("../../../images/Button_Denom_No.png")
const w2gImg = require("../../../images/W2GVoucher.png")
const styles = require('../../../common/styles/GameSelectStyles.css')

export interface IQuarterCloseEnableW2GPageProps {
  locationListSuccess?: Array<ILocationSimpleDetail>;  
  actions?: {
        updateBulkLocationsSettings(params): void;
  }
  locationsListSimple: Array<ILocationSimpleDetail>;
  locationIdsWithoutSettings: number[];
  updateLocationSettingsSuccess?: boolean
}

export interface IQuarterCloseEnableW2GPageState {
    locationIdsWithoutSettings: number[]
}

export class QuarterCloseEnableW2GPage extends React.Component<IQuarterCloseEnableW2GPageProps, IQuarterCloseEnableW2GPageState> {

  constructor(props: IQuarterCloseEnableW2GPageProps) {
    super(props);
      this.state = {
          locationIdsWithoutSettings: this.props.locationIdsWithoutSettings
    }
  }

  componentDidMount() {
  }

  render() {

      const onEnableW2GForLocationsWithoutSettings = (enable: boolean) => {
          this.updateLocationsSettings(enable);
          hashHistory.push('/quarterClose/payoutOptions');
      };

      const tableColumns = [
          {
              title: 'Location ID',
              dataIndex: 'id',
              ket: 'id'
          }, {
              title: 'Location Name',
              dataIndex: 'name',
              key: 'name'
          }
      ]

    return (
        <div className="pg-container page-container gameSelectBackground">
            <Row type="flex" justify="center" align="middle">
                <Col >
                    <span style={{ textAlign: 'center' }} className="gameSelectHeader">ACCEPT W2G VOUCHERS?</span>
                </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
                <Col>
                    <span className="payoutText">Would you like to accept W2G vouchers at the following locations?</span>
                </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
              <Col>
              <div className="listing">
                <List
                  dataSource={this.props.locationsListSimple}
                  renderItem={item => (
                    <List.Item>
                      {item.name} - [{item.id}]
                    </List.Item>
                  )}
                />
                </div>                
              </Col>
            </Row>
            <Row type="flex" justify="center" align="middle" className="pad20Bottom">
                <Col>
                    <img src={w2gImg} alt="Sample W2G Voucher" />
                </Col>
            </Row>                            
            <Row type="flex" justify="center" align="middle">
                <Col>
                    <span className="payoutText">By Choosing "YES", the system will be configured to ACCEPT W2G vouchers.</span>
                </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
                <Col>
                    <span className="payoutText">By Choosing "NO", the system will be configured to REJECT W2G vouchers.</span>
                </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
                <Col>
                    <img className="payoutButtons" onClick={() => { onEnableW2GForLocationsWithoutSettings(true); }} src={yesButton} alt="Enable W2G Vouchers for play" />
                    <img className="payoutButtons" onClick={() => { onEnableW2GForLocationsWithoutSettings(false); }} src={noButton} alt="Do not enable W2G Vouchers for play" />
                </Col>
            </Row>
      </div>
    )
    }

    updateLocationsSettings = (enable) => {
        let params = {
            locationIds: this.state.locationIdsWithoutSettings,
            enableTito: true,
            enableW2G: enable
        }

        this.props.actions.updateBulkLocationsSettings(params);
    }
}

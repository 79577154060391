import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ReportsActions from '../../../actions/reports/ReportsActions';
import { DealsReportComponent } from '../../../components/reports/locationStatisticsReports/DealsReportComponent';
import { RootState } from '../../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadDealsChartSuccess: state.ReportsReducer.loadDealsChartSuccess,

    loadDealsListSuccess: state.ReportsReducer.loadDealsListSuccess,

    exportDealsDataToExcelSuccess: state.ReportsReducer.exportDealsDataToExcelSuccess,
    exportDealsDataToExcelError: state.ReportsReducer.exportDealsDataToExcelError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ReportsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DealsReportComponent)

import React from 'react';
import moment from 'moment';
import { Button, Tooltip } from 'antd';
import ZonesDropdownContainer from '../../../common/containers/dropdown/ZonesDropdownContainer';
import RadioGroup from 'antd/lib/radio/group';
import RadioButton from 'antd/lib/radio/radioButton';
import WeeklyDateRangeFilter, { getCurrentWeekPeriod } from '../../../common/components/filters/WeeklyDateRangeFilter';

export type Filters = {
  startDate: moment.Moment;
  endDate: moment.Moment;
  install: boolean;
  zoneId?: number;
};

export type Props = {
  filters: Filters;
  applyFilters: (filters: Filters) => void;
};

const LocationUninstallAlertReportFilters = ({ applyFilters, filters }: Props) => {

  const resetFilters = () => {
    const currentWeek = getCurrentWeekPeriod();
    applyFilters({
      startDate: currentWeek.start,
      endDate: currentWeek.end,
      install: true,
    });
  }

  return (
    <div className="reports-filter">
      <span className="mr-8">
        <WeeklyDateRangeFilter
          value={{ start: filters.startDate, end: filters.endDate }}
          onChange={value => applyFilters({ ...filters, startDate: value.start, endDate: value.end })}
        />
      </span>
      <span className="mr-8">
        <RadioGroup
          onChange={value => applyFilters({ ...filters, install: value.target.value })}
          value={filters.install}
        >
         <Tooltip title="Has recent non-zero gaming activity but invoicing is not enabled"><RadioButton value={true} >Install</RadioButton></Tooltip>
         <Tooltip title="Has no recent activity (no entries or zero values) but invoicing is enabled"><RadioButton value={false}>Uninstall</RadioButton></Tooltip>
        </RadioGroup>
      </span>
      <span className="mr-8">
        <ZonesDropdownContainer
          placeholder="Select zone"
          value={filters.zoneId}
          onChange={value => applyFilters({ ...filters, zoneId: value })}
        />
      </span>
      <span>
        <Button type="default" onClick={resetFilters}>Reset</Button>
      </span>
    </div>
);
}

export default LocationUninstallAlertReportFilters;

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as LocationsActions from '../../actions/locations/LocationsActions';
import { LocationLinkedContactsComponent } from '../../components/locations/LocationLinkedContactsComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadBasicDetailsSuccess: state.LocationsReducer.loadBasicDetailsSuccess,
    deleteLocationContactSuccess: state.LocationsReducer.deleteLocationContactSuccess,
    deleteLocationContactError: state.LocationsReducer.deleteLocationContactError,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(LocationsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationLinkedContactsComponent)

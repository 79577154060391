export type DashboardState = Partial<{
   dashboardFilters: any ;
   loadDashboardChartStatsError: any;
   loadDashboardChartStatsSuccess: any;
   loadDashboardMapStatsError: any;
   loadDashboardMapStatsSuccess: any;
   loadDashboardMiniStatsError: any;
   loadDashboardMiniStatsSuccess: any;
}>;

const initialState: DashboardState = {};

const DashboardReducer = (state = initialState, action): DashboardState => {
  switch (action.type) {
    case "DASHBOARD_FILTER_UPDATE":
      return { ...state, dashboardFilters: action.payload };

    case "LOAD_DASHBOARD_MINI_STATS_REQUEST":
      return { ...state, loadDashboardMiniStatsSuccess: null, loadDashboardMiniStatsError: null };
    case "LOAD_DASHBOARD_MINI_STATS_SUCCESS":
      return { ...state, loadDashboardMiniStatsSuccess: action.payload, loadDashboardMiniStatsError: null };
    case "LOAD_DASHBOARD_MINI_STATS_FAILURE":
      return { ...state, loadDashboardMiniStatsSuccess: null, loadDashboardMiniStatsError: action.payload };

    case "LOAD_DASHBOARD_CHART_STATS_REQUEST":
      return { ...state, loadDashboardChartStatsSuccess: null, loadDashboardChartStatsError: null };
    case "LOAD_DASHBOARD_CHART_STATS_SUCCESS":
      return { ...state, loadDashboardChartStatsSuccess: action.payload, loadDashboardChartStatsError: null };
    case "LOAD_DASHBOARD_CHART_STATS_FAILURE":
      return { ...state, loadDashboardChartStatsSuccess: null, loadDashboardChartStatsError: action.payload };

    case "LOAD_DASHBOARD_MAP_STATS_REQUEST":
      return { ...state, loadDashboardMapStatsSuccess: null, loadDashboardMapStatsError: null };
    case "LOAD_DASHBOARD_MAP_STATS_SUCCESS":
      return { ...state, loadDashboardMapStatsSuccess: action.payload, loadDashboardMapStatsError: null };
    case "LOAD_DASHBOARD_MAP_STATS_FAILURE":
      return { ...state, loadDashboardMapStatsSuccess: null, loadDashboardMapStatsError: action.payload };
    default:
      return state;
  }
}

export default DashboardReducer;

import { Row } from 'antd';
import { Authenticator } from '@aws-amplify/ui-react';
import { SignInInput, signIn } from 'aws-amplify/auth';
import authenticatorComponents from './AuthenticatorComponents';

const authServices = {
  async handleSignIn(input: SignInInput) {
    return signIn({
      username: input.username,
      password: input.password,
      options: { authFlowType: 'USER_PASSWORD_AUTH' },
    });
  },
};

const LoginComponent = () =>
  (
    <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Authenticator hideSignUp services={authServices} components={authenticatorComponents}/>
    </Row>
  );

export default LoginComponent;

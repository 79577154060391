import moment from 'moment';
import { isEmpty, keys, startCase } from 'lodash';
import AppConstants from '../AppConstants';
import { notification, message, Avatar } from 'antd';
import { hashHistory } from 'react-router';
import SessionService from './SessionService';
import { RouteHistoryHelper } from './RouteHistoryHelper';
import { UserOutlined } from '@ant-design/icons';
import { TerminalType } from '../../app/reducers/reporting/reporting.models';
import { signOut } from 'aws-amplify/auth'

let routeInstance = RouteHistoryHelper.getInstance();

type MessageType = 'success' | 'error' | 'warning';

class AppUtilityService {
  generateBreadcrumbs = (breadcrumbsList: Array<BreadcrumbsList>, extraContent?: any): Breadcrumbs => {
    let breadcrumbs = {
      breadcrumbsList: breadcrumbsList,
      extraContent: extraContent || null,
    };
    return breadcrumbs;
  };

  showMessageToast = (messageText: string, messageType: MessageType) => {
    switch (messageType) {
      case 'success':
        message.success(messageText, 5);
        break;
      case 'error':
        message.error(messageText, 5);
        break;
      case 'warning':
        message.warning(messageText, 5);
        break;
      default:
        break;
    }
  };

  showNotificationToast = (title: string, description: string, messageType: MessageType) => {
    notification[messageType]({
      message: title,
      description: description,
    });
  };

  /**
   * Generate sort string for API using property and order from table columns
   */
  getTableColumnSortOrder = (sortInfo: SortObject, propertyName: string): any => {
    return sortInfo && sortInfo.propertyName === propertyName ? sortInfo.sortDir : false;
  };

  handleApiError = (error: any) => {
    let title = error.response?.status ? 'Error code: unknown' : 'Error code: ' + error.response?.status;
    let description = error.response?.statusText ? 'Error message: unknown' : 'Error message: ' + error.response?.statusText;
    if (error.response?.status === 403) {
      if (window.location.hash.indexOf('/admin') !== -1) {
        hashHistory.push('/admin/unauthorized-access');
      } else {
        hashHistory.push('/unauthorized-access');
      }
    } else if (error.response?.status === 401) {
      if (SessionService.loggedIn()) {
        signOut();
      }
      hashHistory.push('/login');
    } else if (error.response?.data && error.response.data?.error) {
      title = 'Error';
      description = error.response.data.error;
      this.showNotificationToast(title, description, 'error');
    } else if (error.response.data && error.response.data.errorCode) {
      title = 'Error';
      description = error.response.data.errorCode;
      this.showNotificationToast(title, description, 'error');
    } else {
      this.showNotificationToast(title, description, 'error');
    }
  };

  formatDate = (date: string, type: 'short' | 'full' = 'short'): string => {
    return moment(date).format(type === 'short' ? AppConstants.dateFormat : AppConstants.fullDateFormat);
  };

  formatDateReturnEmptyForMinDate = (date: string): string => {
    var formattedDate = moment(date).format(AppConstants.dateFormat);

    if (formattedDate === '01/01/0001') {
      return '';
    } else {
      return formattedDate;
    }
  };

  formatAPIDateFilter = (date: string | null | undefined): string | null => {
    return date ? moment(date).format(AppConstants.apiDateFormat) : null;
  };

  formatDevexColumnDate = (date: string): string => {
    return moment(date).format(AppConstants.devexDate);
  };

  formatTimeStampColumn = (date: string): string => {
    return moment(date).format(AppConstants.timeStampFormat);
  };

  getPredefinedDateRanges = (): any => {
    return {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      Lifetime: [moment('01-01-1900', 'MM-DD-YYYY'), moment()],
    };
  };

  getAppType = (): string => {
    if (window.location.href.indexOf('admin') !== -1) {
      return 'admin';
    } else {
      return 'app';
    }
  };

  getTerminalType = (terminalType?: number): string => {
    if (terminalType) {
      return TerminalType[terminalType];
    }
    return null;
  };

  /**
   * Shorten and append K...to numbers > 1000
   */
  shortenLargeNumber = (num, digits) => {
    return num.toLocaleString('en', { minimumFractionDigits: digits, maximumFractionDigits: digits });
  };

  roundNumber = (num) => {
    return Math.round(num);
  };

  formatAmount = (amount) => {
    return amount.toLocaleString('en', { minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
  };

  formatAmountAndShorten = (amount, digits) => {
    if (amount >= 0) {
      return '$' + this.shortenLargeNumber(amount, digits);
    } else {
      return '-' + '$' + this.shortenLargeNumber(Math.abs(amount), digits);
    }
  };
  /**
   * ANTD datepicker disabling functions
   */
  disableDatesGreaterThanToday = (current) => {
    return current && current > moment().endOf('day');
  };

  getImageFromByteArray(byteArr) {
    return `data:image/jpeg;base64,${byteArr}`;
  }

  displayStringFromArray = (stringArray) => {
    var trimmedStringArray = stringArray.filter(function (e) {
      if (e) {
        return String(e).trim();
      }
    });
    return trimmedStringArray.join(', ');
  };

  buildTableColumnsFromDataset = (dataset, sortInfo): any => {
    var columns = [];
    if (dataset && !isEmpty(dataset)) {
      var columnKeys = keys(dataset[0]);
      columnKeys.forEach((obj) => {
        columns.push({
          title: startCase(obj),
          dataIndex: obj,
          key: obj,
          sorter: true,
          sortOrder: this.getTableColumnSortOrder(sortInfo, obj),
          className: 'hidden-sm',
          render: (text, record) => {
            return text;
          },
        });
      });
      return columns;
    }
  };

  buildDonutChartData = (units, dataset, title, subtitle): any => {
    var data: Array<DonutChartData> = dataset.map(function (item) {
      return { name: item.name, y: item.count };
    });
    return {
      title: title,
      subTitle: subtitle,
      data: data,
      units: units,
    };
  };

  setRouteHistory = (routeHistory) => {
    routeInstance.routeHistory = routeHistory;
  };

  getRouteHistory = () => {
    return routeInstance.routeHistory;
  };

  getContactAvatar = (image) => {
    if (image) {
      return <Avatar size="large" src={this.getImageFromByteArray(image)} />;
    } else {
      return <Avatar size="large" icon={<UserOutlined />} />;
    }
  };
}

const appUtilityService = new AppUtilityService();
export default appUtilityService;

import { reportingSlice } from '../../reducers/reporting/reportingReducer';

export const {
  downloadNorthDakotaGameSuggestionsReportUpdated,
  downloadExcelTicketSalesByGameReportUpdated,
  downloadPdfTicketSalesByGameReportUpdated,
  ticketSalesByGameReportUpdated,
  ticketSalesByGameReportChildUpdated,
  ticketSalesByDateReportUpdated,
  ticketSalesByDateReportChildUpdated,
  arrowJurisdictionsSitesOrganizationsUpdated,
  locationUninstallAlertReportUpdated,
  downloadExcelLocationUninstallAlertReportUpdated,
} = reportingSlice.actions;

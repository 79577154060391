import { createFetched, Fetched } from '../../../common/store/fetched';
import { AuthenticatedUser } from './auth.models';
import { createSlice } from '@reduxjs/toolkit';
import { createFetchedDismisser, createFetchedSetter } from '../../../common/store/fetched.slices';
import { FailureData } from '../../../common/store/failure';

export interface IAuthState {
  authenticatedUser: Fetched<AuthenticatedUser | null>;
  otpSetup: Fetched<URL | null>;
  verifyAndSaveMFA: Fetched<void, FailureData, string>;
}

const initialState: IAuthState = {
  authenticatedUser: createFetched(),
  otpSetup: createFetched(),
  verifyAndSaveMFA: createFetched(),
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticatedUserUpdated: createFetchedSetter('authenticatedUser'),
    otpSetupUpdated: createFetchedSetter('otpSetup'),
    verifyAndSaveMFAUpdated: createFetchedSetter('verifyAndSaveMFA'),
    dismissVerifyAndSaveMFA: createFetchedDismisser('verifyAndSaveMFA'),
  },
});

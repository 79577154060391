import { hashHistory, Router } from 'react-router';
import routes from './routes';
import AppUtilityService from './common/services/AppUtilityService';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { useAppDispatch, useAppSelector } from './common/hooks/storeHooks';
import { Row, Spin } from 'antd';
import { LoadState } from './common/store/fetched';
import getAuthenticatedUser from './app/actions/auth/getAuthenticatedUser';
import { useEffect } from 'react';
import { LoginLayout } from './common/components/layouts/LoginLayout';
import useAuthHubEvents from './common/hooks/useAuthHubEvents';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID,
    },
  },
});

export const App = () => {
  const dispatch = useAppDispatch();
  const authenticatedUserLoadState = useAppSelector(state => state.AuthReducer.authenticatedUser.loadState);

  useAuthHubEvents();

  useEffect(
    () => {
      dispatch(getAuthenticatedUser());
    },
    [dispatch]);

  return authenticatedUserLoadState === LoadState.NotRequested || authenticatedUserLoadState === LoadState.InProgress
    ? (
      <LoginLayout>
        <Row type="flex" justify="center" align="middle" style={{ minHeight: '50vh' }}>
          <Spin size="large"/>
        </Row>
      </LoginLayout>
    )
    : <Router history={hashHistory} routes={routes}/>;
};

hashHistory.listen((location) => {
  let routeData = AppUtilityService.getRouteHistory();
  var tmpPrev = routeData.currentPath;
  if (location.key) {
    if (!routeData.currentPath) {
      routeData.currentPath = location.pathname;
    } else {
      routeData.previousPath = tmpPrev;
      routeData.currentPath = location.pathname;
    }
    AppUtilityService.setRouteHistory(routeData);
  }
});

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as DashboardActions from '../../actions/dashboard/DashboardActions';
import { CashMiniCardComponent } from '../../components/dashboard/CashMiniCardComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadDashboardMiniStatsSuccess: state.DashboardReducer.loadDashboardMiniStatsSuccess,
    loadDashboardMiniStatsError: state.DashboardReducer.loadDashboardMiniStatsError,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(DashboardActions, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(CashMiniCardComponent)

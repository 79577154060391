import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as GameSelectionWindowActions from '../../actions/gameSelectionWindow/gameSelectionWindowActions';
import * as UserActions from '../../actions/user/UserActions';
import * as BreadcrumbActions from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import GameSelectionWindowPage from '../../components/gameSelectionWindow/GameSelectionWindowPage';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    isLoadingGameSelectionWindows: state.AppConfigurationReducer.isLoadingGameSelectionWindows,
    isLoadingSelectLocations: state.AppConfigurationReducer.isLoadingSelectLocations,
    loadGameSelectionWindowsSuccess: state.AppConfigurationReducer.loadGameSelectionWindowsSuccess,
    loadSelectLocationsSuccess: state.AppConfigurationReducer.loadSelectLocationsSuccess,
    window: state.AppConfigurationReducer.window,
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...GameSelectionWindowActions, ...UserActions, ...BreadcrumbActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GameSelectionWindowPage)

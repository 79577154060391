export class RouteHistoryHelper {
    private static instance: RouteHistoryHelper;


    public static getInstance = () => {
        if (!RouteHistoryHelper.instance) {
            RouteHistoryHelper.instance = new RouteHistoryHelper();
        }
        return RouteHistoryHelper.instance;
    }

    public routeHistory: RouteHistory = {
        currentPath: null,
        previousPath: null
    };

}


import * as React from "react";
import AppUtilityService from "../../../common/services/AppUtilityService";
import LocationsListTableContainer from "../../containers/locations/LocationsListTableContainer";

import { Card, Button, Badge, Switch } from 'antd';
import LocationTypesDropdownContainer from "../../../common/containers/dropdown/LocationTypesDropdownContainer";
import WebAccessService from "../../../common/services/WebAccessService";
import AddNewLocationModalContainer from "../../containers/locations/AddNewLocationModalContainer";
import ZonesDropdownContainer from "../../../common/containers/dropdown/ZonesDropdownContainer";
import sessionService from "../../../common/services/SessionService";
import { get, debounce } from "lodash";
import { SearchComponent } from "../../../common/components/inputElements/SearchComponent";
import LocationUserStampsContainer from "../../containers/locations/LocationUserStampsContainer";

export class LocationsListPage extends React.Component<ILocationsProps, ILocationsState> {

  private locationsListRef: any;

  constructor(props: ILocationsProps) {
    super(props);
    this.state = {
      showAddLocationModal: false,
      totalLocations: null,
      filterParams: null
    }
    this.handleSearch = debounce(this.handleSearch, 500);
  }

  componentDidMount() {
    this.breadcrumbsBuild();
    if (this.props.locationListFilters) {
      this.handlePresetFilters(this.props.locationListFilters)
    }
  }

  render() {
    const title =
      WebAccessService.hasPermissionToAccess("Location", "Add") &&
      <Button type="primary" onClick={this.openAddNewLocationModal}>+ New Location</Button>;

    const extraActions = <div className="location-list-filters">

      <span className="ml-8">
        <span className="font-14 regular">Location type: </span>
        <LocationTypesDropdownContainer
          placeholder="Select location type"
          prependExtraItem={{ id: "ALL", text: "All" }}
          onChange={(value) => this.onChangeDropdown(value, "type")}
          value={get(this.state, 'filterParams.type') || "ALL"} />
      </span>
      <span className="ml-8">
        <ZonesDropdownContainer
          placeholder="Select zone"
          customParams={{ filterByUser: true }}
          value={get(this.state, 'filterParams.zoneId') || null}
          onChange={(value) => this.onChangeDropdown(value, "zoneId")}
        />
      </span>
      <span>
        <SearchComponent
          placeholder="Search"
          handleSearchCallback={this.handleSearch}
          width={200}
          searchTerm={get(this.state, 'filterParams.searchText') || ""}
          className="ml-8"
        />
      </span>
      <span className="ml-8">
        <Button type="default" onClick={this.resetPageFilters}>Reset</Button>
      </span>
      <div>
      {
        WebAccessService.hasPermissionToAccess('Location', 'View_Location_Demo_Toggle') &&
        <span className="ml-8">
          <span className="font-14 regular">Show demo types </span>
          <Switch size="small" checked={get(this.state, 'filterParams.showDemoLocations')} onChange={this.changeDemoLocationTypes} />
        </span>
      }
      {
        WebAccessService.hasPermissionToAccess('Location', 'View_Location_Decommissioned_Toggle') &&
        <span className="ml-8">
          <span className="font-14 regular">Show decommissioned types </span>
          <Switch size="small" checked={get(this.state, 'filterParams.showDecommissioned')} onChange={this.changeDecommissionedTypes} />
        </span>
      }
      </div>
    </div>;

    return (
      <div className="pg-container page-container">
        <LocationUserStampsContainer />
        <Card className="no-header-border" bordered={false}
          title={title}
          extra={extraActions}
        >
          <LocationsListTableContainer
            onRef={(ref) => this.locationsListRef = ref}
            filterParams={this.state.filterParams}
            getTotalLocationsCallback={this.getTotalLocationsHandler}
          />
        </Card>
        {
          this.state.showAddLocationModal &&
          <AddNewLocationModalContainer cancelSaveLocationCallback={this.handleCancelSaveLocation} />
        }
      </div>
    )
  }

  getTotalLocationsHandler = (totalCount: number) => {
    this.setState({ totalLocations: totalCount }, () => {
      this.breadcrumbsBuild()
    })
  }

  breadcrumbsBuild = () => {
    let breadcrumbsList = [
      { name: "Locations", link: null }
    ]
    var extraContent = this.state.totalLocations &&
      <span className="font-12 t-grey-dark ml-8">
        <Badge dot className="mr-8" style={{ top: '-1px', background: '#A6ADC9' }} /> {this.state.totalLocations + " Total"}
      </span>;
    var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList, extraContent);
    this.props.actions.updateBreadcrumbs(breadcrumbs);
  }

  changeDemoLocationTypes = (checked) => {
    let filterParams = { ...this.state.filterParams }
    filterParams.page = 1;
    filterParams["showDemoLocations"] = checked
    if (filterParams["showDecommissioned"] == true)
    {
      if (checked)
      {
        filterParams["showDecommissioned"] = !checked
      }
    }

    this.setState({ filterParams })
  }

  changeDecommissionedTypes = (checked) => {
    let filterParams = { ...this.state.filterParams }
    filterParams.page = 1;
    filterParams["showDecommissioned"] = checked
    if (filterParams["showDemoLocations"] == true)
    {
      if (checked)
      {
        filterParams["showDemoLocations"] = !checked
      }
    }
    this.setState({ filterParams })
  }

  onChangeDropdown = (value, type) => {
    let filterParams = { ...this.state.filterParams }
    filterParams.page = 1;
    filterParams[type] = value;
    this.setState({ filterParams })
  }

  handleSearch = (value: string) => {
    let filterParams = { ...this.state.filterParams }
    filterParams.page = 1;
    filterParams["searchText"] = value;
    this.setState({ filterParams })
  }

  openAddNewLocationModal = () => {
    this.setState({ showAddLocationModal: true })
  }

  handleCancelSaveLocation = (successObj) => {
    if (successObj) {
      this.locationsListRef.loadLocationsList();
    }
    this.setState({ showAddLocationModal: false })
  }

  handlePresetFilters = (filterData) => {
    let filterParams = { ...this.state.filterParams, ...filterData }
    filterParams.type = filterData.type || 'ALL';
    this.setState({ filterParams })
  }

  resetPageFilters = () => {
    let filterParams = null;
    this.setState({ filterParams });
  }
}


import { createStore, applyMiddleware, AnyAction } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import RootReducer from './RootReducer';
import logger from 'redux-logger';

let middleware = [
	thunk,
	logger
]

export const store = createStore(RootReducer, undefined, applyMiddleware(...middleware));

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch & ThunkDispatch<RootState, unknown, AnyAction>;

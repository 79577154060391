
import * as React from "react";
import { isEqual } from "lodash";
import AppUtilityService from "../../../common/services/AppUtilityService";

import { Card, Row, Col, Spin } from 'antd';
import { IReportSummaryProps, IReportSummaryState } from './Reports';

export class ReportSummaryCardComponent extends React.Component<IReportSummaryProps, IReportSummaryState> {

  constructor(props: IReportSummaryProps) {
    super(props);
    this.state = {
      summaryData: this.props.summaryData || null,
    }
  }

  componentWillReceiveProps(nextProps: IReportSummaryProps) {
    if (nextProps.summaryData && !isEqual(this.props.summaryData, nextProps.summaryData)) {
      this.setState({ summaryData: nextProps.summaryData })
    }
  }

  render() {

    return (
      <Card className="no-header-border no-body-padding" bordered={false} title={""} extra={""}>
        <h2>Summary</h2>
        <Spin spinning={this.props.isLoading}>
          {
            this.state.summaryData &&
            <Row type="flex" align="top" gutter={24} style={{ paddingTop: '12px' }}>
              {
                this.state.summaryData.map((obj, index) => {
                  return (
                    <Col sm={obj.colCount} key={index}>
                      <p><span className="bold">{obj.key} </span><span>{obj.value}</span></p>
                    </Col>
                  )
                })
              }
            </Row>
          }
        </Spin>
      </Card>
    )
  }
} 

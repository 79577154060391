import * as React from "react";
import { isEqual } from "lodash";

import AppUtilityService from "../../../../common/services/AppUtilityService";
import LocationReportsFilterContainer from "../../../containers/reports/locationStatisticsReports/LocationReportsFilterContainer";
import GameTerminalStatusContainer from "../../../containers/reports/locationStatisticsReports/GameTerminalStatusContainer";
import WebAccessService from "../../../../common/services/WebAccessService";

import { Card, Layout, Menu, Dropdown, Icon } from 'antd';
import { IGameTerminalStatusPageProps, IGameTerminalStatusPageState } from '../Reports';
import { filters } from "./commonFiltersConfiguration";

export class GameTerminalStatusPage extends React.Component<IGameTerminalStatusPageProps, IGameTerminalStatusPageState> {

    private gameTerminalStatusRef: any;

    constructor(props: IGameTerminalStatusPageProps) {
        super(props);
        this.state = {
            isLoadingChart: false,
            isLoadingList: false,
            zoneName: null,
            locationName: null,
            charityName: null,
            distributorName: null,
            apiParams: null
        }
    }

    componentDidMount() {
        this.breadcrumbsBuild();
    }

    componentWillReceiveProps(nextProps: IGameTerminalStatusPageProps) {

        /**
         * Recon summary by date
         */
        if (nextProps.loadGameTerminalStatusChartSuccess && !isEqual(this.props.loadGameTerminalStatusChartSuccess, nextProps.loadGameTerminalStatusChartSuccess)) {
            this.setState({ isLoadingChart: false })
        }
        if (nextProps.loadGameTerminalStatusListSuccess && !isEqual(this.props.loadGameTerminalStatusListSuccess, nextProps.loadGameTerminalStatusListSuccess)) {
            this.setState({ isLoadingList: false })
        }

    }

    render() {
        let { zoneName, locationName, charityName, distributorName } = this.state;
        let filterString = AppUtilityService.displayStringFromArray([zoneName, locationName, charityName, distributorName]);

        let cardTitle = "Game Terminal Status - " + (filterString && (" | " + filterString))

        const extraActions =
            <Dropdown.Button disabled={this.state.isLoadingChart || this.state.isLoadingList} type="primary" onClick={this.downloadData} overlay={<Menu onClick={this.downloadList}>
                <Menu.Item key="1">PDF</Menu.Item>
                <Menu.Item key="2">Excel</Menu.Item>
            </Menu>
            }>
                <Icon type="download" style={{ fontSize: "16px" }} /> Download as PDF
  </Dropdown.Button>;


        return (
            <>
                <Card style={{ paddingTop: "12px" }} className="reports-filter-card content-card no-header-border" bordered={false} title={cardTitle}
                    extra={WebAccessService.hasPermissionToAccess("Reports", "Download") ? extraActions : null}
                >
                    <LocationReportsFilterContainer
                        filters={filters}
                        getSelectedZoneNameCallback={this.handleSelectedZone}
                        getSelectedLocationNameCallback={this.handleSelectedLocation}
                        getSelectedCharityNameCallback={this.handleSelectedCharity}
                        getSelectedDistributorNameCallback={this.handleSelectedDistributor}
                        applyFiltersCallback={this.applyFilters}
                    />
                </Card>
                <div className="pg-reports-container">
                    <Layout>
                        <div className="container" style={{ background: '#fff' }}>
                            <GameTerminalStatusContainer
                                apiParams={this.state.apiParams}
                                onRef={ref => (this.gameTerminalStatusRef = ref)}
                            />
                        </div>
                    </Layout>
                </div>
            </>
        )
    }
    breadcrumbsBuild = () => {
        let breadcrumbsList = [
            { name: "Reports", link: '/reports' },
            { name: "Game Terminal Status", link: null }
        ]
        var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList, null);
        this.props.actions.updateBreadcrumbs(breadcrumbs);
    }
    // ----------------------------------------------------------------------------
    // FILTER FUNCTIONS

    handleSelectedZone = (e) => {
        this.setState({ zoneName: e })
    }
    //-----------------------------------------------------------------------

    handleSelectedLocation = (e) => {
        this.setState({ locationName: e })
    }
    //-----------------------------------------------------------------------

    handleSelectedCharity = (e) => {
        this.setState({ charityName: e })
    }
    //-----------------------------------------------------------------------

    handleSelectedDistributor = (e) => {
        this.setState({ distributorName: e })
    }
    //-----------------------------------------------------------------------

    applyFilters = (apiParams) => {
      apiParams.dateFrom = AppUtilityService.formatAPIDateFilter(apiParams.filterValues.dateRange?.[0]);
      apiParams.dateTo = AppUtilityService.formatAPIDateFilter(apiParams.filterValues.dateRange?.[1]);
      this.setState({
            apiParams,
            isLoadingChart: true,
            isLoadingList: true,
        })
    }

    // ----------------------------------------------------------------------------

    downloadData = (e) => {
        e.key = "1";
        this.downloadList(e)
    }

    downloadList = (e) => {
        var downloadType = "pdf";
        switch (e.key) {
            case "1":
                downloadType = "pdf";
                break;
            case "2":
                downloadType = "excel";
                break;
            default: break;
        }
        this.gameTerminalStatusRef.getDownloadData(downloadType);
    }

}

import { createApiCaller } from '../../../common/services/ApiService';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { fetchDataWithActionCreator, LoadState } from '../../../common/store/fetched';
import { IInvoicesGenerationResponse } from '../../reducers/billing/billing.models';
import { processFailureReason } from '../../../common/store/failure';
import { message } from 'antd';
import { generateInvoicesOperationUpdated } from './slice-actions';
import { plurarize } from '../../../common/helpers/pluralize';

export const regenerateInvoices = (systemId: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    await fetchDataWithActionCreator(
      generateInvoicesOperationUpdated,
      dispatch,
      createApiCaller(api => api.postData<IInvoicesGenerationResponse>(
        `/invoices/generate`,
        {
          invoicingSystemId: systemId
        },
        undefined,
        (status) => status >= 200 && status <= 500,
      )),
      processFailureReason,
      false,
    );

    const operation = getState().BillingReducer.generateInvoicesOperation;
    if (operation.loadState === LoadState.Failed) {
      message.error('An unexpected error occurred while regenerating the invoice. Please try again.', 5);
    } else if (operation.loadState === LoadState.Success) {
      const success = operation.data.invoices.filter(i => i.errorMessage == null).length;
      const failure = operation.data.invoices.filter(i => i.errorMessage != null).length;
      let messageText = '';
      if (success > 0) {
        messageText = `${success} ${plurarize('invoice', success)} were generated successfully.`;
      }
      if (failure > 0) {
        messageText = `${messageText}${messageText !== '' ? ' ' : ''}${failure} ${plurarize('invoice', failure)} failed to generate.`;
      }
      if (success === 0 && failure === 0) {
        messageText = 'No invoices were generated.';
      }

      if (failure > 0) {
        message.warning(messageText);
      } else {
        message.success(messageText);
      }
    }
  }
}
import React from 'react';
import { FilterProps } from './LocationReportsFilterComponent';
import RadioGroup from 'antd/lib/radio/group';
import RadioButton from 'antd/lib/radio/radioButton';

export interface IButtonBarFilterOption {
  value: any;
  label: string;
}

export const getButtonBarFilter = (options: IButtonBarFilterOption[], label?: string) =>
  ({ filterValue, onFilterValueChange }: FilterProps) => (
    <span>
      {label && <span className="font-14 regular">{`${label} `}</span>}
      <RadioGroup onChange={x => onFilterValueChange(x.target.value)} value={filterValue}>
        {options.map(x => <RadioButton key={x.value} value={x.value}>{x.label}</RadioButton>)}
      </RadioGroup>
    </span>
  );

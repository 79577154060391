import { current, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/types/types-external';
import {
  ExtractFetchedContextType,
  ExtractFetchedDataType,
  ExtractFetchedFailureReasonType,
  IFetchedUpdatedPayload,
  updateFetched,
  fetchDataWithActionCreator,
  createFetched,
} from './fetched';
import { AppDispatch, RootState } from './ConfigureStore';
import { processFailureReason } from './failure';

/**
 * Create a reducer function for slices that updates a field of type Fetched<T>.
 * See docs/architecture/redux/fetched-with-slices.md for more details.
 * @param fieldName Name of the field. Must be of type Fetched<T>.
 * @returns Reducer function.
 */
export function createFetchedSetter<TState, TField extends keyof TState>(fieldName: TField) {
  type DataType = ExtractFetchedDataType<TState[TField]>;
  type FailureReasonType = ExtractFetchedFailureReasonType<TState[TField]>;
  type ContextType = ExtractFetchedContextType<TState[TField]>;
  return (
    state: WritableDraft<TState>,
    action: PayloadAction<IFetchedUpdatedPayload<DataType, FailureReasonType, ContextType>>,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state[fieldName] = updateFetched(current(state)[fieldName] as any, action.payload) as any;
  }
}

/**
 * Create a reducer function for slices that dismisses the result (resets) in a field of type Fetched<T>.
 * See docs/architecture/redux/fetched-with-slices.md for more details.
 * @param fieldName Name of the field. Must be of type Fetched<T>.
 * @returns Reducer function.
 */
export function createFetchedDismisser<TState, TField extends keyof TState>(fieldName: TField) {
  return (state: WritableDraft<TState>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state[fieldName] = createFetched() as any;
  }
}

/**
 * Helper that creates a thunk action that supports Fetched flow for an async {@link operation}
 * More probably you want to use {@link createStandardFetchedAction}.
 * See docs/architecture/redux/fetched-with-slices.md for more details.
 * @param action Creates reducer action for updating fetched field in state.
 * @param operation Async operation to call (usually GraphQL query/mutation)
 * @param silentReload Keep the previous data while new data is being loaded.
 * @param processFailure Fuction processing error from {@link operation}
 * @returns Thunk action body.
 */
export function createFetchedAction<TData, TFailureReason, TContext>(
  action: (payload: IFetchedUpdatedPayload<TData, TFailureReason, TContext>) => PayloadAction<IFetchedUpdatedPayload<TData, TFailureReason, TContext>>,
  operation: () => Promise<TData> | TData,
  silentReload: boolean,
  processFailure: (error: unknown) => Promise<TFailureReason> | TFailureReason,
) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    return await fetchDataWithActionCreator(
      action,
      dispatch,
      operation,
      processFailure,
      silentReload,
    );
  };
}

/**
 * Helper that creates a thunk action that supports Fetched flow for an async {@link operation}.
 * It assumes that standard error type (FailureData) is used and no context.
 * See docs/architecture/redux/fetched-with-slices.md for more details.
 * @example
 * export const getDistributors = () => createStandardFetchedAction(distributorsUpdated, getDistributorsQuery);
 * @param action Creates reducer action for updating fetched field in state.
 * @param operation Async operation to call (usually GraphQL query/mutation)
 * @param silentReload Keep the previous data while new data is being loaded.
 * @returns Thunk action body.
 */
export function createStandardFetchedAction<TData>(
  action: (payload: IFetchedUpdatedPayload<TData>) => PayloadAction<IFetchedUpdatedPayload<TData>>,
  operation: () => Promise<TData> | TData,
  silentReload = true,
) {
  return createFetchedAction(action, operation, silentReload, processFailureReason);
}

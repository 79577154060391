import * as React from 'react';
import { render } from 'react-dom';

import { Provider } from 'react-redux';
import { store } from './common/store/ConfigureStore';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.compact.css';

import 'antd/dist/antd.css';
import 'leaflet/dist/leaflet.css';
import './index.scss';
import TagManager from 'react-gtm-module';
import { Authenticator } from '@aws-amplify/ui-react';
import { App } from './App';

renderApp();

function renderApp() {
  const tagManagerArgs = {
    gtmId: 'GTM-NKM8M8CQ',
  };
  TagManager.initialize(tagManagerArgs);
  render(
    <Authenticator.Provider>
      <Provider store={store}>
        <App />
      </Provider>
    </Authenticator.Provider>,
    document.getElementById('app')
  );
}

if (module['hot']) {
  module['hot'].accept();
}

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as BreadcrumbActions from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import * as LocationTypesActions from '../../actions/locationTypes/LocationTypesActions';
import { LocationTypesPage } from '../../components/locationTypes/LocationTypesPage';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    locationTypesListFilters: state.LocationTypesReducer.locationTypesListFilters,

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({...BreadcrumbActions, ...LocationTypesActions}, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationTypesPage)

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ContactsActions from '../../actions/contacts/ContactsActions';
import { AddContactNoteModal } from '../../components/contacts/AddContactNoteModal';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    addContactNoteSuccess: state.ContactsReducer.addContactNoteSuccess,
    addContactNoteError: state.ContactsReducer.addContactNoteError,
    updateContactNoteSuccess: state.ContactsReducer.updateContactNoteSuccess,
    updateContactNoteError: state.ContactsReducer.updateContactNoteError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ContactsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddContactNoteModal)

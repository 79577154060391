import React, { useState } from 'react';
import { Select } from 'antd';
import moment from 'moment/moment';

export type Props = {
  month: number;
  year: number;
  setMonth: (month: number) => void;
  setYear: (year: number) => void;
};

export function useMonthYearPicker(
  defaultMonth: number | (() => number) = () => moment().month() + 1,
  defaultYear: number | (() => number) = () => moment().year(),
) {
  const [month, setMonth] = useState<number>(defaultMonth);
  const [year, setYear] = useState<number>(defaultYear);

  const resetMonthYear = () => {
    setMonth(defaultMonth);
    setYear(defaultYear);
  };

  return {
    month,
    year,
    setYear,
    setMonth,
    resetMonthYear,
  };
}

export function lastYears(count: number) {
  return Array
    .from({ length: count + 1 }, (v, k) => k)
    .map(i => moment().subtract(i, 'year').year());
}

const MonthYearPicker = ({ month, setMonth, setYear, year }: Props) => {
  return (
    <>
      <Select
        style={{ minWidth: "180px" }}
        className="mr-8"
        filterOption={false}
        onChange={setMonth}
        placeholder="Select month"
        value={month}
        mode="default"
      >
        <Select.Option value={1}>January</Select.Option>
        <Select.Option value={2}>February</Select.Option>
        <Select.Option value={3}>March</Select.Option>
        <Select.Option value={4}>April</Select.Option>
        <Select.Option value={5}>May</Select.Option>
        <Select.Option value={6}>June</Select.Option>
        <Select.Option value={7}>July</Select.Option>
        <Select.Option value={8}>August</Select.Option>
        <Select.Option value={9}>September</Select.Option>
        <Select.Option value={10}>October</Select.Option>
        <Select.Option value={11}>November</Select.Option>
        <Select.Option value={12}>December</Select.Option>
      </Select>
      <Select
        style={{ minWidth: "180px" }}
        className="mr-8"
        filterOption={false}
        onChange={setYear}
        placeholder="Select year"
        value={year}
        mode="default"
      >
        {lastYears(5).map(y => <Select.Option key={y} value={y}>{y}</Select.Option>)}
      </Select>
    </>
  );
}

export default MonthYearPicker;

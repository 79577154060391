import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as BreadcrumbActions from '../../../../common/actions/breadcrumbs/BreadcrumbActions'
import QuarterCloseLocationStatusPage
    from '../../../components/reports/locationStatisticsReports/QuarterCloseLocationStatusPage'
import * as ReportActions from '../../../actions/reports/ReportsActions'
import { RootState } from '../../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
    return {
        locations: state.ReportsReducer.locationDetails,
        loadingLocations: state.ReportsReducer.loadingLocationDetails,
        showDemo: state.ReportsReducer.showDemo,
        showDecommissioned: state.ReportsReducer.showDecommissioned,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...BreadcrumbActions, ...ReportActions }, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuarterCloseLocationStatusPage)

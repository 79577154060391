import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as UserRoleActions from '../../actions/userRole/UserRoleActions';
import { RolePermissionsCardComponent } from '../../components/userRole/RolePermissionsCardComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    updateSelectedRolePermissionsSuccess: state.UserRoleReducer.updateSelectedRolePermissionsSuccess,
    updateSelectedRolePermissionsError: state.UserRoleReducer.updateSelectedRolePermissionsError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(UserRoleActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RolePermissionsCardComponent)

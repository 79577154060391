import React from 'react';
import {
  FilterProps,
  FilterValue,
} from '../../../app/components/reports/locationStatisticsReports/LocationReportsFilterComponent';
import { RangepickerComponent } from '../datepicker/RangepickerComponent';
import { isMoment, Moment } from 'moment';

const isDateRange = (value: FilterValue) => Array.isArray(value) && value.every(i => isMoment(i));

const DateRangeFilter = ({ filterValue, onFilterValueChange }: FilterProps) => {
  const dateRange = isDateRange(filterValue) ? filterValue as Moment[] : null;
  return (
    <RangepickerComponent
      dateRange={dateRange}
      onChange={newValue => onFilterValueChange(newValue as Moment[])}/>
  );
}

export default DateRangeFilter;

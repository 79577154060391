import { Card, Col, Row, Modal, Icon } from 'antd';
import { IInvoice, IInvoiceEmailRecipient, InvoiceAtachmentPreview, InvoiceAttachmentType, InvoiceEmailSendStatus, InvoiceStatus } from '../../reducers/billing/billing.models';
import ContentEditable from 'react-contenteditable'
import { Link } from 'react-router';
import { useCallback, useState } from 'react';
import { useAppDispatch } from '../../../common/hooks/storeHooks';
import { downloadInvoiceAttachmentAndSave } from '../../actions/billing/downloadInvoiceAttachmentAndSave';
import EditInvoiceEmailModal from './EditInvoiceEmailModal';
import { updateInvoiceTemplate } from '../../actions/billing/updateInvoiceEmailTemplate';
const confirm = Modal.confirm;

interface IInvoiceEmailDetailsProps {
  invoice: IInvoice;
}

export function InvoiceEmailDetails({ invoice }: IInvoiceEmailDetailsProps) {

  const dispatch = useAppDispatch();

  const downloadInvoiceAttachment = useCallback(
    (attachment: InvoiceAtachmentPreview) => dispatch(downloadInvoiceAttachmentAndSave(invoice.invoiceId, attachment.type, attachment.invoiceAttachmentId)),
    [invoice.invoiceId, dispatch]
  );

  const [editEmailMessageModalOpen, setEditEmailMessageModalOpen] = useState<boolean>(false);

  const renderRecipientName = ({ recipientName, email }: IInvoiceEmailRecipient) => {
    if (recipientName != null && recipientName.length > 0) {
      return `${recipientName} (${email})`;
    } else {
      return email;
    }
  };

  const renderEmailSubject = (invoice: IInvoice) => {
    return invoice.emailSubjectTemplate;
  };

  const renderEmailContent = (invoice: IInvoice) => {
    return <ContentEditable html={invoice.emailBodyTemplate} disabled={true} onChange={() => { }} />
  };

  const renderStatus = ({ sendStatus }: IInvoiceEmailRecipient) => {
    switch (sendStatus) {
      case InvoiceEmailSendStatus.Success: return (<> - <span className='t-green'>sent</span></>);
      case InvoiceEmailSendStatus.Failed: return (<> - <span className='t-red'>failed</span></>);
      case InvoiceEmailSendStatus.Pending: return (<> - <span>pending</span></>);
      default: return null;
    }
  };

  const openEditEmailMessage = (visible: boolean) => {
    setEditEmailMessageModalOpen(visible);
  }

  const restoreDefaultTemplate = () => confirm({
    title: 'Confirm dialog',
    content: 'Are you sure you want to restore default email template?',
    okText: 'Yes',
    okType: 'default',
    cancelText: 'Cancel',
    onOk: () => {
      const customAttachmentIds = invoice.invoiceAtachmentPreviews.filter(p=>p.type === InvoiceAttachmentType.Custom).map(p=>p.invoiceAttachmentId);
      dispatch(updateInvoiceTemplate(invoice.invoiceId, null, null, [], customAttachmentIds));
    },
  });

  return (
    <>
      <Card
        title="E-mail message"
        className="content-card no-header-border no-card-padding"
        bordered={false}
        extra={
          invoice.invoiceStatusId !== InvoiceStatus.Sent &&
          <>
            <span className="link-primary" style={{ marginRight: 5 }} onClick={() => restoreDefaultTemplate()}><Icon type="undo" /></span>
            <span className="link-primary" onClick={() => openEditEmailMessage(true)}><i className="icon-edit" /></span>
          </>
        }>
        <Col>
          <Row>
            <span className="t-black bold">Recipients:</span>
            {
              invoice.emailRecipients.length === 0
                ? "No recipients"
                : (
                  <ul>
                    {invoice.emailRecipients.map((recipient, index) => (
                      <li key={index}>
                        {renderRecipientName(recipient)}
                        {renderStatus(recipient)}
                      </li>
                    ))}
                  </ul>
                )
            }
          </Row>
          <Row style={{ marginBottom: "12px" }}>
            <span className="t-black bold">Subject: </span>
            <span className="mb-4">{renderEmailSubject(invoice)}</span>
          </Row>
          <Row>
            {renderEmailContent(invoice)}
          </Row>
          <Row>
            <span className="t-black bold">Attachments:</span>
            {
              invoice.invoiceAtachmentPreviews.length === 0
                ? "No attachments"
                : (
                  <ul>
                    {invoice.invoiceAtachmentPreviews.map((attachment, index) => (
                      <li key={index}>
                        <Link to={''} onClick={() => downloadInvoiceAttachment(attachment)}>
                          {attachment.fileName}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )
            }
          </Row>
        </Col>
      </Card>
      {editEmailMessageModalOpen && <EditInvoiceEmailModal onClose={() => setEditEmailMessageModalOpen(false)} invoice={invoice} />}
    </>
  );
}

import { downloadExcelTicketSalesByGameReportUpdated } from './slice-actions';
import apiService from '../../../common/services/ApiService';
import { createStandardFetchedAction } from '../../../common/store/fetched.slices';
import { Moment } from 'moment';
import AppConstants from '../../../common/AppConstants';
import { DownloadedFile, saveFile } from '../../../common/store/fileDownload';

export type GroupingType = "game" | "date";

export const downloadExcelTicketSalesReport = (
  dateFrom: Moment,
  dateTo: Moment,
  groupingType: GroupingType,
  jurisdictionId?: number,
  siteId?: number,
  organizationId?: number,
) => createStandardFetchedAction(
  downloadExcelTicketSalesByGameReportUpdated,
  async () => {
    const result = await apiService.downloadFile(`/reports/arrow/ticket-sales-by-${groupingType}-report/export/excel/${dateFrom.format(AppConstants.apiDateFormat)}/${dateTo.format(AppConstants.apiDateFormat)}`,
      { jurisdictionId, siteId, organizationId });

    const fileName = result.headers['x-filename'];
    const file: DownloadedFile = {
      data: result.data,
      fileName,
    };
    saveFile(file)
  },
  false,
);


import * as React from "react"
import { Row, Col, Modal } from "antd"
import { hashHistory } from "react-router";
import ReviewGameComponent from '../quarterClose/QuarterCloseReviewGameComponent'
import { IGame, IQuarterCloseSelectedBet, IQuarterCloseLocationSetup } from '../../reducers/quarterClose/QuarterCloseReducer'
import * as Constants from '../../../common/AppConstants'
import { IQuarterCloseLocationSetupDetails } from '../../reducers/reports/ReportsReducer';
const confirmButton = require("../../../images/Button_Confirm.png")
const confirmButtonDisabled = require("../../../images/Button_Confirm_Disabled.png")
const gamesButton = require("../../../images/Button_Games.png")

export interface IQuarterCloseSelectionReviewPageProps {
    games: IGame[];
    selectedSetup: IQuarterCloseSelectedBet[];
    locationIds: number[];
    postingSelectedSetup: boolean;
    latestSetup?: IQuarterCloseLocationSetupDetails;
    gameSelectionWindowId: number,
    actions?: {
        updateSelectedRemoveGame(gameID: number): void;
        updateSelectedGameID(gameID: number): void;
        postLocationSetup(locationSetup: IQuarterCloseLocationSetup[]): void;
    };
}

export interface IQuarterCloseSelectionReviewPageState {
    modalVisible: boolean;
}

export class QuarterCloseSelectionReviewPage extends React.Component<IQuarterCloseSelectionReviewPageProps, IQuarterCloseSelectionReviewPageState> {

  constructor(props: IQuarterCloseSelectionReviewPageProps) {
    super(props);
      this.state = {
          modalVisible: false,
    }
  }

  componentDidMount() {
    }

    render() {
      const maxGameSelectionsStringLower = Constants.default.ndMaxGameSelectionsString.toLowerCase()

        const onPostData = () => {
            const { latestSetup } = this.props;
            let modifiedDate = new Date();  //gets converted to UTC when converted to json and sent to the api
            modifiedDate.setDate(modifiedDate.getDate() - 1);
            const extraInfo = latestSetup != null
              ? { locationIds: [latestSetup.locationID], startDate: latestSetup.earliestStartDate, gameSelectionWindowId: latestSetup.gameSelectionWindowId }
              : { locationIds: this.props.locationIds, startDate: modifiedDate, gameSelectionWindowId: this.props.gameSelectionWindowId };
            const data: IQuarterCloseLocationSetup[] = this.props.selectedSetup.map(item => ({ ...item, ...extraInfo }))
            this.props.actions.postLocationSetup(data)
        }

        const GetGame = (index: number): IGame | null => {
            const gameIds: number[] = Array.from(new Set(this.props.selectedSetup.map(p => p.gameID)));

            if (gameIds.length >= index + 1) {
                const game = this.props.games.find(p => p.gameID === gameIds[index])
                return game
            }

            return null
        }

        const GetSelectedSetup = (index: number): IQuarterCloseSelectedBet | null => {
            if (index <= this.props.selectedSetup.length - 1)
                return this.props.selectedSetup[index]

            return null
        }

        const isEditable = (index: number): boolean => {
            const setup = GetSelectedSetup(index);
            if (setup == null) return false;
            const isPreselected = this.props.latestSetup?.games
              .some(x => x.gameID === setup.gameID && x.formType === setup.formType)
              ?? false;
            return !isPreselected;
        };

    return (
        <div className="pg-container page-container gameSelectBackground">
            <Row>
                <Row type="flex" justify="center" align="middle">
                    <Col>
                        <span style={{ textAlign: 'center' }} className="gameSelectHeader">GAME SELECTION REVIEW</span>
                    </Col>
                </Row>
            </Row>
            <Row type="flex" justify="center" align="middle" style={{ paddingBottom: "20px" }}>
                <Col>
                    <span className="gameSelectText">SELECT A GAME ICON TO RETURN TO GAME EDIT SCREEN</span>
                </Col>
            </Row>
            <Row style={{ paddingBottom: "5px" }}>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={true} updateSelectedGameID={this.props.actions.updateSelectedGameID} editable={isEditable(0)}
                    onRemoveGame={this.props.actions.updateSelectedRemoveGame} game={GetGame(0)} quarterCloseSelectedBet={GetSelectedSetup(0)} />
                </Col>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={false} updateSelectedGameID={this.props.actions.updateSelectedGameID} editable={isEditable(1)}
                    onRemoveGame={this.props.actions.updateSelectedRemoveGame} game={GetGame(1)} quarterCloseSelectedBet={GetSelectedSetup(1)} />
                </Col>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={false} updateSelectedGameID={this.props.actions.updateSelectedGameID} editable={isEditable(2)}
                    onRemoveGame={this.props.actions.updateSelectedRemoveGame} game={GetGame(2)} quarterCloseSelectedBet={GetSelectedSetup(2)} />
                </Col>
            </Row>
            <Row style={{ paddingBottom: "5px" }}>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={true} updateSelectedGameID={this.props.actions.updateSelectedGameID} editable={isEditable(3)}
                    onRemoveGame={this.props.actions.updateSelectedRemoveGame} game={GetGame(3)} quarterCloseSelectedBet={GetSelectedSetup(3)} />
                </Col>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={false} updateSelectedGameID={this.props.actions.updateSelectedGameID} editable={isEditable(4)}
                    onRemoveGame={this.props.actions.updateSelectedRemoveGame} game={GetGame(4)} quarterCloseSelectedBet={GetSelectedSetup(4)} />
                </Col>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={false} updateSelectedGameID={this.props.actions.updateSelectedGameID} editable={isEditable(5)}
                    onRemoveGame={this.props.actions.updateSelectedRemoveGame} game={GetGame(5)} quarterCloseSelectedBet={GetSelectedSetup(5)} />
                </Col>
            </Row>
            <Row style={{ paddingBottom: "5px" }}>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={true} updateSelectedGameID={this.props.actions.updateSelectedGameID} editable={isEditable(6)}
                    onRemoveGame={this.props.actions.updateSelectedRemoveGame} game={GetGame(6)} quarterCloseSelectedBet={GetSelectedSetup(6)} />
                </Col>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={false} updateSelectedGameID={this.props.actions.updateSelectedGameID} editable={isEditable(7)}
                    onRemoveGame={this.props.actions.updateSelectedRemoveGame} game={GetGame(7)} quarterCloseSelectedBet={GetSelectedSetup(7)} />
                </Col>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={false} updateSelectedGameID={this.props.actions.updateSelectedGameID} editable={isEditable(8)}
                    onRemoveGame={this.props.actions.updateSelectedRemoveGame} game={GetGame(8)} quarterCloseSelectedBet={GetSelectedSetup(8)} />
                </Col>
            </Row>
            <Row style={{ paddingBottom: "5px" }}>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={true} updateSelectedGameID={this.props.actions.updateSelectedGameID} editable={isEditable(9)}
                    onRemoveGame={this.props.actions.updateSelectedRemoveGame} game={GetGame(9)} quarterCloseSelectedBet={GetSelectedSetup(9)} />
                </Col>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={false} updateSelectedGameID={this.props.actions.updateSelectedGameID} editable={isEditable(10)}
                    onRemoveGame={this.props.actions.updateSelectedRemoveGame} game={GetGame(10)} quarterCloseSelectedBet={GetSelectedSetup(10)} />
                </Col>
                <Col span={8}>
                    <ReviewGameComponent displayRemoveOnLeft={false} updateSelectedGameID={this.props.actions.updateSelectedGameID} editable={isEditable(11)}
                    onRemoveGame={this.props.actions.updateSelectedRemoveGame} game={GetGame(11)} quarterCloseSelectedBet={GetSelectedSetup(11)} />
                </Col>
            </Row>
            <Row style={{ paddingTop: "10px" }}>
                <Col span={12}>
                    <img className="navImageButton" onClick={() => { hashHistory.push('/quarterClose/selectGames') }} src={gamesButton} alt="View Games" />
                </Col>
                <Col span={12}>
                    {this.props.postingSelectedSetup &&
                        <img className="navImageButton floatRight" src={confirmButtonDisabled} alt="Confirm Selection"/>
                    }
                    {!this.props.postingSelectedSetup &&
                        <>
                        <img className="navImageButton floatRight" onClick={() => {
                            if (this.props.selectedSetup.length < 6)
                                this.setState({ modalVisible: true })
                            else
                                onPostData()

                        }} src={confirmButton} alt="Confirm Selection" />
                         <Modal
                                title="More Games Can Be Selected"
                            visible={this.state.modalVisible}
                            onOk={() => { onPostData() }}
                                onCancel={() => { this.setState({ modalVisible: false }) }}
                                  okText="Confirm"
                                  cancelText="Cancel"
                                >
                                <p>Warning, you have less than {maxGameSelectionsStringLower} games chosen, are you sure you want to continue?</p>
                            </Modal>
                        </>

                    }
                </Col>
            </Row>
      </div>
    )
  }
}

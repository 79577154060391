import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createFetchedDismisser, createFetchedSetter } from '../../../common/store/fetched.slices';
import { createFetched, Fetched } from '../../../common/store/fetched';
import {
  ILocationInvoicingConfigSearchParams,
  IPagedLocationInvoicingConfig, LocationCharity,
  LocationCharityDistributorDiscount,
  LocationInvoicingConfigItem,
  TaxRate,
} from './locationInvoicingConfig.models';

export interface ILocationInvoicingConfigState {
  locationInvoicingConfigSearchParams: ILocationInvoicingConfigSearchParams;
  locationInvoicingConfigList: Fetched<IPagedLocationInvoicingConfig>;
  updateLocationInvoicingConfigOperation: Fetched<void>;
  locationCharityDistributorDiscounts: Fetched<LocationCharityDistributorDiscount[]>;
  locationCharities: Fetched<LocationCharity[]>;
  locationInvoicingConfigDetails: Fetched<LocationInvoicingConfigItem[]>;
  taxRates: Fetched<TaxRate[]>;
  saveTaxRateOperation: Fetched<void>;
}

export const defaultLocationInvoicingConfigListParams: ILocationInvoicingConfigSearchParams = {
  pageNo: 1,
  pageSize: 20,
  sortBy: 'LocationName',
  sortAsc: true,
};

const initialState: ILocationInvoicingConfigState = {
  locationInvoicingConfigSearchParams: defaultLocationInvoicingConfigListParams,
  locationInvoicingConfigList: createFetched(),
  locationInvoicingConfigDetails : createFetched(),
  updateLocationInvoicingConfigOperation: createFetched(),
  locationCharityDistributorDiscounts: createFetched(),
  locationCharities: createFetched(),
  taxRates: createFetched(),
  saveTaxRateOperation: createFetched(),
};

export const locationInvoicingConfigSlice = createSlice({
  name: 'locationInvoicingConfig',
  initialState,
  reducers: {
    setLocationInvoicingConfigSearchParams: (state, action: PayloadAction<ILocationInvoicingConfigSearchParams>) => {
      state.locationInvoicingConfigSearchParams = action.payload;
    },
    locationInvoicingConfigListUpdated: createFetchedSetter('locationInvoicingConfigList'),
    dismissBillingConfigurationList: createFetchedDismisser('locationInvoicingConfigList'),
    locationInvoicingConfigDetailsUpdated : createFetchedSetter('locationInvoicingConfigDetails'),
    dismissLocationInvoicingConfigDetails: createFetchedDismisser('locationInvoicingConfigDetails'),
    updateLocationInvoicingConfigOperation: createFetchedSetter('updateLocationInvoicingConfigOperation'),
    locationCharityDistributorDiscountsUpdated: createFetchedSetter('locationCharityDistributorDiscounts'),
    dismissLocationCharityDistributorDiscounts: createFetchedDismisser('locationCharityDistributorDiscounts'),
    locationCharitiesUpdated: createFetchedSetter('locationCharities'),
    dismissGetLocationCharities: createFetchedDismisser('locationCharities'),
    taxRatesUpdated: createFetchedSetter('taxRates'),
    dismissTaxRates: createFetchedDismisser('taxRates'),
    saveTaxRateOperationUpdated: createFetchedSetter('saveTaxRateOperation'),
    dismissSaveTaxRateOperation: createFetchedDismisser('saveTaxRateOperation'),
  },
});

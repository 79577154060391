export type BreadcrumbState = Partial<{
  updateBreadcrumbsSuccess: any;
}>;

const initialState: BreadcrumbState = {};

const BreadcrumbReducer = (state = initialState, action): BreadcrumbState => {
  switch (action.type) {
    case 'BREADCRUMB_UPDATE_SUCCESS':
      return { ...state, updateBreadcrumbsSuccess: action.payload };
    default:
      return state;
  }
}

export default BreadcrumbReducer;

import { createApiCaller } from '../../../common/services/ApiService';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { fetchDataWithActionCreator, LoadState } from '../../../common/store/fetched';
import { IUpdateValidationIssueRequest, IUpdateValidationIssueContext } from '../../reducers/billing/billing.models';
import { updateValidationIssueOperationUpdated } from './slice-actions';
import { processFailureReason } from '../../../common/store/failure';
import { message } from 'antd';
import { getInvoice } from './getInvoice';

export const updateValidationIssue = (
  invoiceId: number, invoiceValidationIssueId: number, request: IUpdateValidationIssueRequest,
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const context: IUpdateValidationIssueContext = { invoiceId, invoiceValidationIssueId, request };
    await fetchDataWithActionCreator(
      updateValidationIssueOperationUpdated,
      dispatch,
      createApiCaller(api => api.updateData<void>(
        `/invoices/${invoiceId.toFixed(0)}/validation-issues/${invoiceValidationIssueId.toFixed(0)}`,
        undefined,
        request)),
      processFailureReason,
      false,
      context,
    );

    const operation = getState().BillingReducer.updateValidationIssueOperation;
    if (operation.loadState === LoadState.Failed) {
      message.error(
        `There was a problem with marking the issue as ${request.isResolved ? 'resolved' : 'un-resolved'}.
         Please try again or refresh the page.`,
        5,
      );
    } else if (operation.loadState === LoadState.Success) {
      message.success(`The issue was marked as ${request.isResolved ? 'resolved' : 'un-resolved'}.`);
      await dispatch(getInvoice(invoiceId, true));
    }
  };
}

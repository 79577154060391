import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as GameSelectionWindowActions from '../../actions/gameSelectionWindow/gameSelectionWindowActions';
import * as UserActions from '../../actions/user/UserActions';
import * as BreadcrumbActions from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import GameSelectionWindowSelectGamesCheckout from '../../components/gameSelectionWindow/GameSelectionWindowSelectGamesCheckout';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...GameSelectionWindowActions, ...UserActions, ...BreadcrumbActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GameSelectionWindowSelectGamesCheckout)
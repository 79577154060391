import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as CharityActions from '../../actions/charities/CharityActions';
import * as BreadcrumbActions from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import { CharityDetailsPage } from '../../components/charities/CharityDetailsPage';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadCharityDetailsSuccess: state.CharityReducer.loadCharityDetailsSuccess,
    generatedAPIKeyUserName: state.CharityReducer.generatedAPIKeyUserName,
    generatedAPIKeyPassword: state.CharityReducer.generatedAPIKeyPassword,
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...BreadcrumbActions, ...CharityActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CharityDetailsPage)

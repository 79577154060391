import { combineReducers } from 'redux';
// Common reducers
import BreadcrumbReducer from '../reducers/breadcrumbs/BreadcrumbReducer';
import AutocompleteReducer from '../reducers/dropdown/AutocompleteReducer';
import DropdownReducer from '../reducers/dropdown/DropdownReducer';
import UserAccountReducer from '../reducers/user/UserAccountReducer';

// Admin reducers
import AdminAutocompleteReducer from '../../admin/reducers/autocomplete/AdminAutocompleteReducer';
import AppConfigurationReducer from '../../admin/reducers/gameSelectionWindow/GameSelectionWindowReducer';
import UserReducer from '../../admin/reducers/user/UserReducer';
import UserRoleReducer from '../../admin/reducers/userRole/UserRoleReducer';
import ZoneReducer from '../../admin/reducers/zones/ZoneReducer';
import LocationTypesReducer from '../../admin/reducers/locationTypes/LocationTypesReducer';
import GameSelectionWindowReducer from '../../admin/reducers/gameSelectionWindow/GameSelectionWindowReducer';
import { gamesSlice } from '../../admin/reducers/games/gamesReducer';

// App reducers
import DashboardReducer from '../../app/reducers/dashboard/DashboardReducer';
import LocationsReducer from '../../app/reducers/locations/LocationsReducer';
import DistributorsReducer from '../../app/reducers/distributors/DistributorsReducer';
import ContactsReducer from '../../app/reducers/contacts/ContactsReducer';
import CharityReducer from '../../app/reducers/charities/CharityReducer';
import ReportsReducer from '../../app/reducers/reports/ReportsReducer';
import QuarterCloseReducer from '../../app/reducers/quarterClose/QuarterCloseReducer'
import InvoicesReducer from '../../app/reducers/invoices/InvoicesReducer'
import { billingSlice } from '../../app/reducers/billing/BillingReducer';
import { locationInvoicingConfigSlice } from '../../app/reducers/locationInvoicingConfig/LocationInvoicingConfigReducer';
import { locationSettingsSlice } from '../../app/reducers/locationSettings/LocationSettingsReducer'
import { invoicingReportsSlice } from '../../app/reducers/invoicingReports/InvoicingReportsReducer';
import { reportingSlice } from '../../app/reducers/reporting/reportingReducer';
import { authSlice } from '../../app/reducers/auth/AuthReducer';

const RootReducer = combineReducers({
    // Common reducers
    BreadcrumbReducer,
    AutocompleteReducer,
    DropdownReducer,
    UserAccountReducer,
    // Admin reducers
    AdminAutocompleteReducer,
    UserReducer,
    UserRoleReducer,
    ZoneReducer,
    LocationTypesReducer,
    GameSelectionWindowReducer,
    GamesReducer: gamesSlice.reducer,
    // App reducers
    DashboardReducer,
    LocationsReducer,
    DistributorsReducer,
    ContactsReducer,
    CharityReducer,
    ReportsReducer,
    QuarterCloseReducer,
    AppConfigurationReducer,
    InvoicesReducer,
    BillingReducer: billingSlice.reducer,
    LocationInvoicingConfigReducer: locationInvoicingConfigSlice.reducer,
    LocationSettingsReducer: locationSettingsSlice.reducer,
    InvoicingReportsReducer: invoicingReportsSlice.reducer,
    ReportingReducer: reportingSlice.reducer,
    AuthReducer: authSlice.reducer,
})
export default RootReducer;

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as BreadcrumbActions from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import * as UserActions from '../../actions/user/UserActions';
import { UserDetailsPage } from '../../components/user/UserDetailsPage';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadUserDetailsSuccess: state.UserReducer.loadUserDetailsSuccess,
    loadUserDetailsError: state.UserReducer.loadUserDetailsError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({...BreadcrumbActions, ...UserActions}, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsPage)


import * as React from "react";
import { isEqual } from "lodash";
import { Link } from "react-router";
import { FormComponentProps } from 'antd/lib/form/Form';
import AppUtilityService from "../../../common/services/AppUtilityService";
import ZoneLinkedUsersAutocompleteContainer from "../../containers/autocomplete/ZoneLinkedUsersAutocompleteContainer";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";

import { Card, Form, Modal, Row, Col, List, Avatar, Tag } from 'antd';
const FormItem = Form.Item;
const confirm = Modal.confirm;

class ZoneLinkedUsersComponent extends React.Component<IZoneLinkedUsersProps & FormComponentProps, IZoneLinkedUsersState> {

  private autocompleteRef: any;

  constructor(props: IZoneLinkedUsersProps & FormComponentProps) {
    super(props);
    this.state = {
      selectedZone: this.props.selectedZone || null
    }
  }

  componentWillReceiveProps(nextProps: IZoneLinkedUsersProps & FormComponentProps) {
    if (nextProps.selectedZone && !isEqual(this.props.selectedZone, nextProps.selectedZone)) {
      this.setState({ selectedZone: nextProps.selectedZone })
    }

    /**
     * Handle assign user to zone success/error
     */
    if (nextProps.assignUserToZoneSuccess && !isEqual(this.props.assignUserToZoneSuccess, nextProps.assignUserToZoneSuccess)) {
      var selectedZone = { ...this.state.selectedZone };
      selectedZone.users = nextProps.assignUserToZoneSuccess;
      AppUtilityService.showMessageToast("User added to zone", "success");
      this.props.form.resetFields();
      this.autocompleteRef.resetDatasource();
      this.setState({ selectedZone })
    }
    if (nextProps.assignUserToZoneError && !isEqual(this.props.assignUserToZoneError, nextProps.assignUserToZoneError)) {
      AppUtilityService.handleApiError(nextProps.assignUserToZoneError);
    }

    /**
     * Handle unassign user from zone success/error
     */
    if (nextProps.unassignUserFromZoneSuccess && !isEqual(this.props.unassignUserFromZoneSuccess, nextProps.unassignUserFromZoneSuccess)) {
      var selectedZone = { ...this.state.selectedZone };
      selectedZone.users = nextProps.unassignUserFromZoneSuccess;
      AppUtilityService.showMessageToast("User removed from zone", "success");
      this.autocompleteRef.resetDatasource();
      this.setState({ selectedZone })
    }
    if (nextProps.unassignUserFromZoneError && !isEqual(this.props.unassignUserFromZoneError, nextProps.unassignUserFromZoneError)) {
      AppUtilityService.handleApiError(nextProps.unassignUserFromZoneError);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Card className="no-header-border" bordered={false}
        title="Users"
      >
        <Form>
          <Row>
            <Col span={20}>
              <FormItem>
                {getFieldDecorator('userId', {
                  rules: [{ required: true, message: 'Please select a user to add!' }],
                  validateTrigger: ['onSubmit']
                })(
                  <ZoneLinkedUsersAutocompleteContainer
                    placeholder="Type here to add a user"
                    apiUrlId={this.state.selectedZone && this.state.selectedZone.zoneId}
                    onRef={ref => (this.autocompleteRef = ref)}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={4}>
              <div className="text-right">
                <FormItem>
                  <span className="link-primary" onClick={this.assignUser}>Add Users</span>
                </FormItem>
              </div>
            </Col>
          </Row>
        </Form>
        {
          this.state.selectedZone && this.state.selectedZone.users &&
          <List
            className="zone-desc-linked-list"
            itemLayout="horizontal"
            dataSource={this.state.selectedZone.users}
            locale={
              {
                emptyText: <EmptyStateComponent title="No Linked Users" />
              }
            }
            renderItem={(item: RoleUsersDetail) => (
              <List.Item actions={[<i className="icon-remove link-danger" onClick={() => this.unassignUser(item.userId)} />]}>
                <List.Item.Meta
                  avatar= {AppUtilityService.getContactAvatar(item.image)}
                  title={<Link to={"/admin/users/" + item.userId} className="link-primary">{item.name}</Link>}
                  description={
                    <div>
                      {item.userName}
                      <Tag>{item.role}</Tag>
                    </div>}
                />
              </List.Item>
            )}
          />
        }
      </Card>
    )
  }

  /**
   * Assign selected user to zone
   */
  assignUser = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        var { selectedZone } = this.state;
        var formData = values;
        this.props.actions.assignUserToZone(selectedZone.zoneId, formData.userId);
      }
    });
  }

  /**
   * Unassign selected user from zone
   */
  unassignUser = (userId: number) => {
    var { selectedZone } = this.state;
    confirm({
      title: 'Confirm dialog',
      content: 'Are you sure you want to remove the selected user linked to this zone?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        this.props.actions.unassignUserFromZone(selectedZone.zoneId, userId);
      },
    });
  }

} export default Form.create<IZoneLinkedUsersProps & FormComponentProps>()(ZoneLinkedUsersComponent);

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import AppUtilityService from '../../../common/services/AppUtilityService';
import { updateBreadcrumbs } from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import { InvoicingSystemIds } from '../../../common/invoicingSystemIds';
import { loadLocationInvoicingDistributorsDropdown } from '../../../common/actions/dropdown/DropdownActions';
import { Button, Card, Icon, message } from 'antd';
import InvoicingDistributorsAutocompleteContainer
  from '../../../common/containers/dropdown/InvoicingDistributorsAutocompleteContainer';
import { downloadNorthDakotaInvoicesReport } from '../../actions/invoicingReports/downloadNorthDakotaInvoicesReport';
import { LoadState } from '../../../common/store/fetched';
import MonthYearPicker, { useMonthYearPicker } from '../../../common/components/monthYearPicker/MonthYearPicker';

export type Props = {};

const NorthDakotaInvoicesPage = (props: Props) => {
  const { year, month, setYear, setMonth, resetMonthYear } = useMonthYearPicker();
  const [invoicingDistributorId, setInvoicingDistributorId] = useState<number | null>(null);
  const [invoicingDistributorsRequested, setInvoicingDistributorsRequested] = useState(false);

  const invoicingDistributorsData = useAppSelector(
    state => state.DropdownReducer.loadLocationInvoicingDistributorsDropdownDataSuccess);
  const isDownloadingInProgress = useAppSelector(state =>
    state.InvoicingReportsReducer.downloadNorthDakotaInvoicesReportOperation.loadState === LoadState.InProgress);
  const hasDownloadingFailed = useAppSelector(state =>
    state.InvoicingReportsReducer.downloadNorthDakotaInvoicesReportOperation.loadState === LoadState.Failed);

  const dispatch = useAppDispatch();

  useEffect(
    () => {
      const breadcrumbsList = [
        { name: "Reports", link: "/Reports" },
        { name: 'North Dakota Invoices', link: null },
      ];
      const breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
      dispatch(updateBreadcrumbs(breadcrumbs));
    },
    [dispatch]);

  useEffect(
    () => {
      dispatch(loadLocationInvoicingDistributorsDropdown({ invoicingSystemId: InvoicingSystemIds.NDv1 }));
      setInvoicingDistributorsRequested(true);
    },
    [dispatch]);

  useEffect(
    () => {
      if (invoicingDistributorsRequested && invoicingDistributorId == null && invoicingDistributorsData?.length > 0) {
        setInvoicingDistributorId(invoicingDistributorsData[0].id);
      }
    },
    [invoicingDistributorId, invoicingDistributorsData, invoicingDistributorsRequested]);

  useEffect(
    () => {
      if (hasDownloadingFailed) {
        message.error('Failed to download report');
      }
    },
    [hasDownloadingFailed]);

  const resetFilters = () => {
    resetMonthYear();
    setInvoicingDistributorId(invoicingDistributorsData?.[0]?.id || null);
  };

  const downloadExcel = () => {
    dispatch(downloadNorthDakotaInvoicesReport(year, month, invoicingDistributorId));
  };

  return (
    <div className="pg-container page-container">
      <Card
        className="no-header-border"
        bordered={false}
        title="North Dakota Invoices">
        <MonthYearPicker
          month={month}
          setMonth={setMonth}
          setYear={setYear}
          year={year}
        />
        <InvoicingDistributorsAutocompleteContainer
          className="mr-8"
          placeholder="Select invoicing distributor"
          allowClear={false}
          value={invoicingDistributorId}
          customParams={{
            invoicingSystemId: InvoicingSystemIds.NDv1,
          }}
          onChange={setInvoicingDistributorId}
        />
        <Button
          onClick={resetFilters}
          type="default"
          className="mr-8"
        >
          ↻
        </Button>
        <Button
          type="primary"
          className="ml-8"
          onClick={downloadExcel}
          loading={isDownloadingInProgress}
        >
          <Icon type="download" style={{ fontSize: "16px" }}/> Download as Excel
        </Button>
      </Card>
    </div>
  );
}

export default NorthDakotaInvoicesPage;

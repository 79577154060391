import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as CharityActions from '../../actions/charities/CharityActions';
import { AddNewCharityModal } from '../../components/charities/AddNewCharityModal';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    addCharitySuccess: state.CharityReducer.addCharitySuccess,
    addCharityError: state.CharityReducer.addCharityError,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(CharityActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCharityModal)

import { Button, Card, Icon, message } from 'antd';
import { useEffect, useState } from 'react';
import { updateBreadcrumbs } from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import AppUtilityService from '../../../common/services/AppUtilityService';
import { LoadState } from '../../../common/store/fetched';
import { downloadVirginiaSalesTaxReport } from '../../actions/invoicingReports/downloadVirginiaSalesTaxReport';
import DateRangeFilter from '../../../common/components/filters/DateRangeFilter';
import moment from 'moment';
import { FilterValue } from '../reports/locationStatisticsReports/LocationReportsFilterComponent';
import ZonesDropdownContainer from '../../../common/containers/dropdown/ZonesDropdownContainer';

function SalesTaxReportPage() {
  const dispatch = useAppDispatch();
  const isDownloadingInProgress = useAppSelector(
    (state) =>
      state.InvoicingReportsReducer.downloadVirginiaSalesTaxOperation.loadState === LoadState.InProgress
  );
  const hasDownloadingFailed = useAppSelector(
    (state) => state.InvoicingReportsReducer.downloadVirginiaSalesTaxOperation.loadState === LoadState.Failed
  );

  const [dateRange, setDateRange] = useState<FilterValue>([
    moment().subtract(1, 'month').startOf('month'),
    moment().subtract(1, 'month').endOf('month'),
  ]);

  const [selectedZoneId, setSelectedZoneId] = useState<number>();

  useEffect(() => {
    const breadcrumbsList = [
      { name: 'Reports', link: '/Reports' },
      { name: 'Sales Tax', link: null },
    ];
    const breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
    dispatch(updateBreadcrumbs(breadcrumbs));
  }, [dispatch]);

  useEffect(() => {
    if (hasDownloadingFailed) {
      message.error('Failed to download report');
    }
  }, [hasDownloadingFailed]);

  const downloadExcel = () => {
    dispatch(downloadVirginiaSalesTaxReport({
      dateFrom: AppUtilityService.formatAPIDateFilter(dateRange?.[0]),
      dateTo: AppUtilityService.formatAPIDateFilter(dateRange?.[1]),
      zoneId: selectedZoneId,
    }));
  };

  return (
    <div className="pg-container page-container">
        <Card
          style={{ paddingTop: "12px" }}
          className="reports-filter-card content-card no-header-border"
          bordered={false}
          title="Sales and Tax Liability Report"
        >
          <div className="reports-filter">
            <span className="mr-8">
            <ZonesDropdownContainer
                placeholder="Select zone"
                onChange={setSelectedZoneId}
                value={selectedZoneId}
              />
            </span>
            <span className="mr-8">
              <DateRangeFilter
                filterValue={dateRange}
                onFilterValueChange={range => setDateRange(range)}
              />
            </span>
            <span className="mr-8">
              <Button
                type="primary"
                className="ml-8"
                onClick={downloadExcel}
                loading={isDownloadingInProgress}
              >
                <Icon type="download" style={{ fontSize: '16px' }} /> Download as Excel
              </Button>
            </span>
          </div>
        </Card>
    </div>
  );
}

export default SalesTaxReportPage;

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { QuarterCloseGameDetailsPage } from '../../components/quarterClose/QuarterCloseGameDetailsPage'
import * as QuarterCloseActions from '../../actions/quarterClose/QuarterCloseActions'
import { RootState } from '../../../common/store/ConfigureStore';


const mapStateToProps = (state: RootState) => {
    return {
        selectedGame: state.QuarterCloseReducer.games.find(p => p.gameID === state.QuarterCloseReducer.selectedGameID),
        latestSetup: state.QuarterCloseReducer.latestSetup.data,
        selectedGameID: state.QuarterCloseReducer.selectedGameID,
        gameFlares: state.QuarterCloseReducer.gameFlares,
        loadingGameFlares: state.QuarterCloseReducer.loadingGameFlares,
        selectedSetup: state.QuarterCloseReducer.selectedSetup,
        payoutInMultiplesOf25: state.QuarterCloseReducer.payoutInMultiplesOf25,
        gameMedia: state.QuarterCloseReducer.gameMedia,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...QuarterCloseActions }, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(QuarterCloseGameDetailsPage)

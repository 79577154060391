import { Alert, Button, Col, message, Modal, Row } from "antd";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { plurarize } from '../../../common/helpers/pluralize';
import { useAppDispatch, useAppSelector } from "../../../common/hooks/storeHooks";
import { usePrevious } from "../../../common/hooks/usePrevious";
import AppUtilityService from "../../../common/services/AppUtilityService";
import { LoadState } from "../../../common/store/fetched";
import { saveFile } from '../../../common/store/fileDownload';
import { exportInvoicesToExcel } from "../../actions/billing/exportInvoicesToExcel";
import { regenerateBulkInvoices } from '../../actions/billing/regenerateBulkInvoices';
import { updateBulkInvoiceStatus } from "../../actions/billing/updateBulkInvoiceStatus";
import { IInvoiceListItem, InvoiceStatus, InvoiceUpdateStatusResult, IUpdateBulkInvoiceResult } from "../../reducers/billing/billing.models";
import InvoiceBulkDownloadModal from './InvoiceBulkDownloadModal';

const bulkUpdateDisplayMap = {
  3: 'invoice not found',
  4: 'don\'t have access to the location',
  5: 'invoice has open validation issues',
  6: 'an earlier invoice for the location is still in pending status',
}

interface InvoiceBulkChnageModalProps {
  items: IInvoiceListItem[]
  selected: string[]
}

const InvoiceBulkChangeModal = (props: InvoiceBulkChnageModalProps) => {

  const bulkUpdateOperation = useAppSelector(state => state.BillingReducer.updateBulkStatusOperation);
  const updateLoadState = useAppSelector(state => state.BillingReducer.updateBulkStatusOperation.loadState);
  const prevUpdateLoadState = usePrevious(updateLoadState);
  const regenerateOperation = useAppSelector(state => state.BillingReducer.regenerateSelectedInvoicesOperation);
  const prevRegenerateLoadState = usePrevious(regenerateOperation.loadState);
  const invoiceList = useAppSelector(state => state.BillingReducer.invoiceList);
  const dispatch = useAppDispatch();
  const [targetStatus, setTargetStatus] = useState<InvoiceStatus | null>();
  const [bulkDownload, setBulkDownload] = useState<boolean>(false);

  const renderFailedProcessedItemsInfo = useCallback(
    (items: IInvoiceListItem[], actionDisplyName: string, updateResult: IUpdateBulkInvoiceResult[]) => {
      const list = renderInvoiceBulletList(items, updateResult);
      return <Row type='flex'>
        <Col>
          The following invoices failed to be {actionDisplyName}:
          {list}
        </Col>
      </Row>
    }, []);

  useEffect(
    () => {
      if (prevUpdateLoadState === LoadState.InProgress && updateLoadState === LoadState.Success) {
        const actionDisplyName = targetStatus === InvoiceStatus.Approved ? 'approved' : 'sent';
        if (_.every(bulkUpdateOperation.data, i => i.result === InvoiceUpdateStatusResult.Success || i.result === InvoiceUpdateStatusResult.Skipped)) {
          message.success(`Successfully ${actionDisplyName} invoices.`);
        } else {
          const failedUpdateInvoice = bulkUpdateOperation.data?.filter(i => i.result !== InvoiceUpdateStatusResult.Success && i.result !== InvoiceUpdateStatusResult.Skipped).map(p => p.invoiceId.toString());
          const items = invoiceList.data?.invoices.filter(i => failedUpdateInvoice.includes(i.invoiceId.toString()));

          Modal.warn({
            title: `Not all invoices could be ${actionDisplyName}`,
            width: 810,
            content: renderFailedProcessedItemsInfo(items, actionDisplyName, bulkUpdateOperation.data)
          });
        }
        setTargetStatus(null);
      }
    }, [updateLoadState, prevUpdateLoadState, bulkUpdateOperation.data, renderFailedProcessedItemsInfo, invoiceList.data, targetStatus]);

    useEffect(
      () => {
        if (prevRegenerateLoadState === LoadState.InProgress && regenerateOperation.loadState === LoadState.Success) {
          var successful = 0;
          var failed: number[] = [];
          regenerateOperation.data.forEach(x => {
            if (x.result === InvoiceUpdateStatusResult.Success) {
              successful++;
            } else {
              failed.push(x.invoiceId);
            }
          });
          const successfulMsg =
            `${successful} ${plurarize('invoice', successful)} ${plurarize('was', successful)} regenerated.`;
          if (failed.length > 0) {
            const content = [
              <br />,
              successfulMsg,
              <br />,
              `${failed.length} ${plurarize('invoice', failed.length)} failed to regenerate:`,
              renderInvoiceBulletList(
                invoiceList.data?.invoices.filter(i => failed.includes(i.invoiceId)),
                regenerateOperation.data,
              ),
            ];
            Modal.warn({
              title: `Not all invoices could be regenerated`,
              width: 810,
              content,
            });
          } else {
            message.success(successfulMsg, 5);
          }
          setTargetStatus(null);
        }
      }, [prevRegenerateLoadState, regenerateOperation, invoiceList.data]);

  const areAnySelected = () => selected().length > 0;

  const areAnyPending = () => {
    const pendingApprovalItems = invoiceList.data?.invoices?.filter(p => p.invoiceStatusId === InvoiceStatus.PendingReview).map(p => p.invoiceId.toString());
    return _.intersection(props.selected, pendingApprovalItems).length > 0;
  }
  const areAnyApproved = () => {
    const pendingApprovalItems = invoiceList.data?.invoices?.filter(p => p.invoiceStatusId === InvoiceStatus.Approved).map(p => p.invoiceId.toString());
    return _.intersection(props.selected, pendingApprovalItems).length > 0;
  }

  const selected = () => props.items.filter(p => props.selected.includes(p.invoiceId.toString()));

  const selectedWithErrors = () => {
    const pendingApprovalItems = selected().filter(p => p.hasOpenValidationIssues === true);
    return pendingApprovalItems;
  }

  const selectItems = (invoiceStatus: InvoiceStatus) => selected().filter(p => (p.invoiceStatusId === invoiceStatus));

  const readyToBeProcessed = (targetStatus: InvoiceStatus) => {
    if (targetStatus === InvoiceStatus.Sent) {
      return selected().filter(p =>
        (p.invoiceStatusId === InvoiceStatus.Approved || p.invoiceStatusId === InvoiceStatus.PendingReview) && p.hasOpenValidationIssues === false);
    } else if (targetStatus === InvoiceStatus.Approved) {
      return selected().filter(p =>
        p.invoiceStatusId === InvoiceStatus.PendingReview && p.hasOpenValidationIssues === false);
    } else if (targetStatus === InvoiceStatus.PendingReview) {
      return selected().filter(p => p.invoiceStatusId === InvoiceStatus.PendingReview);
    }
    return [];
  }

  const renderInvoiceBulletList = (items: IInvoiceListItem[], updateResult?: IUpdateBulkInvoiceResult[]) => {
    const lis = items.map(item => {
      let itemText = `${item.invoiceNo} (${AppUtilityService.formatDate(item.invoicePeriodFrom)} - ${AppUtilityService.formatDate(item.invoicePeriodTo)}) for ${item.locationName}`;
      if (updateResult) {
        const updateResultItems = _.groupBy(updateResult, 'invoiceId');
        itemText = `${itemText}:\n${bulkUpdateDisplayMap[updateResultItems[item.invoiceId][0].result]}`
      }
      return <li>{itemText}</li>;
    });
    return <ul>{lis}</ul>
  }

  const handleSave = (targetStatus: InvoiceStatus) => {
    const items = readyToBeProcessed(targetStatus);
    if (items.length > 0) {
      dispatch(updateBulkInvoiceStatus(items.map(p => p.invoiceId), targetStatus));
    }
  };

  const handleRegenerate = () => {
    const items = readyToBeProcessed(InvoiceStatus.PendingReview);
    if (items.length > 0) {
      dispatch(regenerateBulkInvoices(items.map(p => p.invoiceId)));
    }
  };

  const getStatusName = (status: InvoiceStatus) => {
    switch (status) {
      case InvoiceStatus.PendingReview: return 'regenerated';
      case InvoiceStatus.Approved: return 'approved';
      case InvoiceStatus.Sent: return 'sent';
    }
  }

  const renderReadyToBeProcessed = (targetStatus: InvoiceStatus) => {
    const items = readyToBeProcessed(targetStatus);
    const text = getStatusName(targetStatus);
    if (items.length === 0) return `None of selected invoices are ready to be ${text}.`;
    const heading = items.length + ` ${plurarize('invoice', items.length)} ${plurarize('is', items.length)} ready to be ${text}:`
    return <div>
      {heading}
      {renderInvoiceBulletList(items)}
    </div>
  }

  const renderAlreadySentApprovedtInfo = (targetStatus: InvoiceStatus) => {
    const items = selectItems(targetStatus);
    return (
      items.length > 0 && <Alert style={{ marginBottom: 15 }}
        message={items.length + ` ${plurarize('invoice', items.length)} ${plurarize('is', items.length)} already ${getStatusName(targetStatus)}:`}
        description={renderInvoiceBulletList(items)}
        type="info"
        showIcon
      />)
  }

  const renderErrorsInfo = (targetStatus: InvoiceStatus) => {
    const openValidationErrorsItems = selectedWithErrors();
    return (
      openValidationErrorsItems.length > 0 && <Alert style={{ marginBottom: 15 }}
        message={openValidationErrorsItems.length + ` ${plurarize('invoice', openValidationErrorsItems.length)} ${plurarize('has', openValidationErrorsItems.length)} validation issues that must be resolved before ${targetStatus === InvoiceStatus.Approved ? 'approving' : 'sending'}:`}
        description={renderInvoiceBulletList(openValidationErrorsItems)}
        type="warning"
        showIcon
      />)
  }

  const renderBulkSaveModal = (status: InvoiceStatus) => {
    setTargetStatus(status);
    const actionTypeText = status === InvoiceStatus.Approved ? 'Approve' : 'Send';
    const items = readyToBeProcessed(status);
    const okText = items.length === 0 ? 'Ok' : `${actionTypeText} ${items.length} ${plurarize('invoice', items.length)}`;
    Modal.confirm({
      title: `${actionTypeText} invoices`,
      onOk: () => handleSave(status),
      okText: okText,
      cancelButtonProps: items.length === 0 ? { style: { display: 'none' } } : null,
      width: 810,
      content: <Row type='flex'>
        <Col>
          {renderErrorsInfo(status)}

          {renderAlreadySentApprovedtInfo(status)}

          {renderReadyToBeProcessed(status)}
        </Col >
      </Row >
    })
  }

  const renderBulkRegenerateModal = () => {
    const items = readyToBeProcessed(InvoiceStatus.PendingReview);
    const okText = items.length === 0 ? 'Ok' : `Regenerate ${items.length} ${plurarize('invoice', items.length)}`;
    Modal.confirm({
      title: 'Regenerate invoices',
      onOk: () => handleRegenerate(),
      okText: okText,
      cancelButtonProps: items.length === 0 ? { style: { display: 'none' } } : null,
      width: 810,
      content: <Row type='flex'>
        <Col>
          {renderAlreadySentApprovedtInfo(InvoiceStatus.Approved)}

          {renderAlreadySentApprovedtInfo(InvoiceStatus.Sent)}

          {renderReadyToBeProcessed(InvoiceStatus.PendingReview)}
        </Col >
      </Row >
    })
  }

  const exportSelectedToExcel = async () => {
    const selectedItems = selected();
    const file = await dispatch(exportInvoicesToExcel(selectedItems.map(p => p.invoiceId)));
    saveFile(file);
  }

  const renderBulkDownloadModal = () => {
    if (!bulkDownload) return null;
    return (<InvoiceBulkDownloadModal
      items={selected()}
      onClose={() => { setBulkDownload(false); }}
    />);
  }

  const renderButtons = () => {
    const selectedArePending = areAnyPending();
    const selectedAreApproved = areAnyApproved();
    const selectedAny = areAnySelected();

    const buttons: JSX.Element[] = [];

    if (selectedArePending) {
      buttons.push(<Button className={'mr-8'} onClick={() => renderBulkSaveModal(InvoiceStatus.Approved)} >Approve Selected</Button>);
    }
    if (selectedAreApproved || selectedArePending) {
      buttons.push(<Button className={'mr-8'} onClick={() => renderBulkSaveModal(InvoiceStatus.Sent)} >Send Selected</Button>);
    }
    if (selectedAny) {
      buttons.push(<Button className={'mr-8'} onClick={() => { setBulkDownload(true); }} >Download Selected</Button>);
      buttons.push(<Button className={'mr-8'} onClick={() => { exportSelectedToExcel(); }} >Export Selected to Excel</Button>);
    }
    if (selectedArePending) {
      buttons.push(<Button className={'mr-8'} onClick={() => renderBulkRegenerateModal()} >Regenerate Selected</Button>);
    }
    return (<div>{buttons}</div>);
  }

  return (<>
    {renderBulkDownloadModal()}
    {renderButtons()}
  </>);
}

export default InvoiceBulkChangeModal;
import { fetchDataWithActionCreator } from '../../../common/store/fetched';
import { createApiCaller } from '../../../common/services/ApiService';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { deleteGamePayload } from '../../actions/games/slice-actions';
import { processFailureReason } from '../../../common/store/failure';

//updates deletedDate for record
export const deleteGame = (gameID: number) => {
 return async (dispatch: AppDispatch, getState: () => RootState) => {
    console.log("inside");
    return await fetchDataWithActionCreator(
      deleteGamePayload,
      dispatch,
      createApiCaller(api => api.deleteData<boolean>(`/games/${gameID}`)),
      processFailureReason,
      true,
    );
  };
}
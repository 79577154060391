import { addSummaryFieldsToColumnProps } from '../../../common/helpers/dataGridComponentHelpers';
import appUtilityService from '../../../common/services/AppUtilityService';
import { IColumnProps } from 'devextreme-react/data-grid';

const byGameColumns: IColumnProps[] = [
  { dataField: 'gameName', caption: 'Game', dataType: 'string', width: '20%' },
  { dataField: 'soldTicketCount', caption: 'Sold Ticket Count', format: '#,##0', dataType: 'number' },
  { dataField: 'grossRevenue', caption: 'Gross Revenue', format: '$#,##0.00', dataType: 'number' },
  { dataField: 'payout', caption: 'Prizes Awarded ', format: '$#,##0.00', dataType: 'number', visible: false, showInColumnChooser: true },
  { dataField: 'ticketNetRevenue', caption: 'Ticket Net Revenue', format: '$#,##0.00', dataType: 'number' },
  { dataField: 'progressivePayout', caption: 'Progressive Payout', format: '$#,##0.00', dataType: 'number' },
  { dataField: 'currentNetRevenue', caption: 'Current Net Revenue', format: '$#,##0.00', dataType: 'number' },
  { dataField: 'theoreticalNetRevenue', caption: 'Theoretical Net Revenue', format: '$#,##0.00', dataType: 'number' },
];

export const columns = byGameColumns.map(addSummaryFieldsToColumnProps);

export const childColumns: IColumnProps[] = [
  { dataField: 'formCode', caption: 'FormCode', dataType: 'string'},
  { dataField: 'gamePrice', caption: 'Game Price', format: '$#,##0.00', dataType: 'number'},
  ...byGameColumns.slice(1),
].map(addSummaryFieldsToColumnProps);
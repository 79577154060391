import React, { useCallback } from 'react';
import { Button, Form, Modal, Row, Col, InputNumber } from 'antd';
import { useAppDispatch } from '../../../common/hooks/storeHooks';
import { addDistributorLinkedUser } from '../../actions/distributors/DistributorsActions';
import { FormComponentProps } from 'antd/lib/form/Form';
import DistributorUsersAvailableAutocompleteContainer from '../../../admin/containers/autocomplete/DistributorUsersAvailableAutocompleteContainer';

interface AddUserToDistributorFormFields {
  userId: number | null;
}

export type Props = FormComponentProps<AddUserToDistributorFormFields> & {
  distributorId: number;
  onCancel: () => void;
};

const AddUserToDistributorModal = (
  {
    distributorId,
    onCancel,
    form: { getFieldDecorator, validateFields },
  }: Props,
) => {
  const dispatch = useAppDispatch();

  const handleSubmit = useCallback(
    () => {
      validateFields((errors, values) => {
        if (errors) return;
        dispatch(addDistributorLinkedUser(distributorId, values.userId));
      });
    },
    [validateFields]);

  return (
    <Modal
      visible
      title="Add User to Distributor"
      onOk={() => {
      }}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>Cancel</Button>,
        <Button key="submit" type="primary" htmlType="submit" onClick={handleSubmit}>Add</Button>,
      ]}
    >
      <Form>
        <Row>
          <Col>
            <Form.Item label="User">
              {getFieldDecorator('userId', {
                rules: [{ required: true, message: 'Please select a user' }],
                validateTrigger: ['onSubmit'],
              })(
                <DistributorUsersAvailableAutocompleteContainer
                  placeholder="Select user"
                  customParams={{
                    distributorId,
                    loadIdRelated: false,
                  }}
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Form.create<Props>()(AddUserToDistributorModal);

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ContactsActions from '../../actions/contacts/ContactsActions';
import { SaveUserModal } from '../../../admin/components/user/SaveUserModal';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    addNewUserSuccess: state.ContactsReducer.addNewContactUserSuccess,
    addNewUserError: state.ContactsReducer.addNewContactUserError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ContactsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveUserModal)


import * as React from "react";
import { isEqual } from "lodash";
import AppUtilityService from "../../../common/services/AppUtilityService";
import ZoneDetailsFormComponent from "./ZoneDetailsFormComponent";

import { Card, Button, Modal, Alert, Avatar } from 'antd';
const confirm = Modal.confirm;

export class ZoneDescriptionCardComponent extends React.Component<IZoneDescriptionDetailsProps, IZoneDescriptionDetailsState> {

    private zoneDetailsFormRef: any;

    constructor(props: IZoneDescriptionDetailsProps) {
        super(props);
        this.state = {
            selectedZone: this.props.selectedZone || null,
            editMode: this.props.editMode || false
        }
    }

    componentWillReceiveProps(nextProps: IZoneDescriptionDetailsProps) {
        if (nextProps.selectedZone && !isEqual(this.props.selectedZone, nextProps.selectedZone)) {
            this.setState({ selectedZone: nextProps.selectedZone })
        }
        if (!isEqual(this.props.editMode, nextProps.editMode)) {
            this.setState({ editMode: nextProps.editMode })
        }

        /**
         * Handle add zone success/error
         */
        if (nextProps.addNewZoneSuccess && !isEqual(this.props.addNewZoneSuccess, nextProps.addNewZoneSuccess)) {
            var addedZone = nextProps.addNewZoneSuccess
            this.props.addZoneSuccessCallback(addedZone);
            this.setState({ selectedZone: addedZone, editMode: false });
            AppUtilityService.showMessageToast("Zone has been added", "success");
        }
        if (nextProps.addNewZoneError && !isEqual(this.props.addNewZoneError, nextProps.addNewZoneError)) {
            AppUtilityService.handleApiError(nextProps.addNewZoneError);
        }

        /**
         * Handle update zone success/error
         */
        if (nextProps.updateZoneDetailsSuccess && !isEqual(this.props.updateZoneDetailsSuccess, nextProps.updateZoneDetailsSuccess)) {
            var selectedZone = nextProps.updateZoneDetailsSuccess
            this.props.updatedBasicDetailsCallback();
            this.setState({ selectedZone, editMode: false });
            AppUtilityService.showMessageToast("Zone name updated", "success");
        }
        if (nextProps.updateZoneDetailsError && !isEqual(this.props.updateZoneDetailsError, nextProps.updateZoneDetailsError)) {
            AppUtilityService.handleApiError(nextProps.updateZoneDetailsError);
        }

        /**
         * Handle update zone success/error
         */
        if (nextProps.deleteSelectedZoneSuccess !== null && !isEqual(this.props.deleteSelectedZoneSuccess, nextProps.deleteSelectedZoneSuccess)) {
            if (nextProps.deleteSelectedZoneSuccess) {
                this.props.deleteZoneSuccessCallback();
                AppUtilityService.showMessageToast("Selected zone has been deleted", "success");
            }
        }
        if (nextProps.deleteSelectedZoneError && !isEqual(this.props.deleteSelectedZoneError, nextProps.deleteSelectedZoneError)) {
            AppUtilityService.handleApiError(nextProps.deleteSelectedZoneError);
        }
    }

    render() {
        var userCount = this.state.selectedZone && this.state.selectedZone.users.length;
        var locationsCount = this.state.selectedZone && this.state.selectedZone.locations.length;

        var cardTitle: any = "";
        var cardActions: any = null;
        if (this.state.selectedZone) {
            if (this.state.selectedZone.zoneId) {
                cardTitle = (
                    this.state.selectedZone && (
                        <span>
                            {this.state.selectedZone.name}
                            {this.state.selectedZone.color && <Avatar size="small" className="ml-8" style={{ backgroundColor: this.state.selectedZone.color, verticalAlign: 'sub' }} />}
                        </span>
                    )
                )
                cardActions = <span>
                    <Button className="mr-8" type="primary" onClick={this.showEditForm}>Edit</Button>
                    <Button onClick={this.deleteZone}>Delete</Button>
                </span>
            } else {
                cardTitle = <h3>
                    {
                        this.state.selectedZone.parentZoneName ? ("Add zone under: " + this.state.selectedZone.parentZoneName) : "Add new zone"
                    }
                </h3>;
            }
        }

        return (
            <Card className="no-header-border" bordered={false}
                title={cardTitle}
                extra={cardActions}
            >
                {
                    this.state.editMode ?
                        <div>
                            <ZoneDetailsFormComponent ref={(ref) => this.zoneDetailsFormRef = ref} selectedZone={this.state.selectedZone} />
                            <br />
                            <Button className="mr-8" type="primary"
                                onClick={this.state.selectedZone && this.state.selectedZone.zoneId ? this.updateZoneDetails : this.addZone}
                            >
                                Save
                            </Button>
                            {
                                this.state.selectedZone && this.state.selectedZone.zoneId ?
                                    <Button onClick={this.cancelSaveZoneDetails}> Cancel </Button> : null
                            }
                            <br />
                        </div>
                        :
                        this.state.selectedZone && this.state.selectedZone.description &&
                        <p>{this.state.selectedZone.description}</p>
                }
                {
                    (userCount || locationsCount) ?
                        <Alert style={{ marginTop: 20 }} showIcon={false}
                            message={"Comprises of " +
                                (userCount ? (userCount + " users ") : "") +
                                (userCount && locationsCount ? "and " : "") +
                                (locationsCount ? (locationsCount + " locations") : "")} banner />
                        :
                        null
                }
            </Card>
        )
    }


    /**
     * Toggle between details view and edit form
     */
    showEditForm = () => {
        this.props.changeEditModeCallback(true);
        this.setState({ editMode: true })
    }
    cancelSaveZoneDetails = () => {
        this.props.changeEditModeCallback(false);
        this.setState({ editMode: false })
    }

    /**
     * Handle submit add zone form using ref to ZoneDetailsFormComponent
     */
    addZone = (e: any) => {
        e.preventDefault();
        this.zoneDetailsFormRef.validateFields((err, values) => {
            if (!err) {
                var { selectedZone } = this.state;
                var formData = values;
                formData.zoneId = 0;
                formData.parentZoneId = selectedZone.parentZoneId;
                this.props.actions.addNewZone(formData);
            }
        })
    }

    /**
     * Handle submit edit zone form using ref to ZoneDetailsFormComponent
     */
    updateZoneDetails = (e: any) => {
        e.preventDefault();
        this.zoneDetailsFormRef.validateFields((err, values) => {
            if (!err) {
                var { selectedZone } = this.state;
                var formData = values;
                formData.zoneId = selectedZone.zoneId;
                formData.parentZoneId = selectedZone.parentZoneId;
                this.props.actions.updateZoneDetails(selectedZone.zoneId, formData)
            }
        })
    }

    /**
     * Handle delete zone on confirming confirm prompt
     */
    deleteZone = () => {
        var { selectedZone } = this.state;
        confirm({
            title: 'Confirm dialog',
            content: 'Are you sure you want to delete this zone?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: () => {
                this.props.actions.deleteSelectedZone(selectedZone.zoneId)
            },
        });
    }

}

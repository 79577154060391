
import * as React from "react";
import { isEqual, isEmpty } from "lodash";
import AppUtilityService from "../../../common/services/AppUtilityService";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";

import { Card, Collapse, Checkbox, Row, Col, Spin } from 'antd';
const Panel = Collapse.Panel;

export class RolePermissionsCardComponent extends React.Component<IRoleDetailsPermissionProps, IRoleDetailsPermissionState> {
  constructor(props: IRoleDetailsPermissionProps) {
    super(props);
    this.state = {
      roleDetails: this.props.roleDetails || null,
      isLoading: this.props.roleDetails ? false : true
    }
  }

  componentWillReceiveProps(nextProps: IRoleDetailsPermissionProps) {
    if (nextProps.roleDetails && !isEqual(this.props.roleDetails, nextProps.roleDetails)) {
      this.setState({ roleDetails: nextProps.roleDetails, isLoading: false })
    }

    /**
     * Handle role permission update success/error
     */
    if (nextProps.updateSelectedRolePermissionsSuccess !== null &&
      !isEqual(this.props.updateSelectedRolePermissionsSuccess, nextProps.updateSelectedRolePermissionsSuccess)) {
      this.setState({ isLoading: false })
      if (nextProps.updateSelectedRolePermissionsSuccess) {
        AppUtilityService.showMessageToast("Permissions updated successfully", "success");
      }
    }
    if (nextProps.updateSelectedRolePermissionsError &&
      !isEqual(this.props.updateSelectedRolePermissionsError, nextProps.updateSelectedRolePermissionsError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.updateSelectedRolePermissionsError);
    }
  }

  render() {
    return (
      <Card className="no-header-border" bordered={false}
        title="Permissions" >
        <Spin spinning={this.state.isLoading}>
          {
            this.state.roleDetails && this.state.roleDetails.rolePermissions && !isEmpty(this.state.roleDetails.rolePermissions) ?
              <Collapse accordion className="permissions-accordian">
                {
                  this.state.roleDetails.rolePermissions.map((permissionModule: Permissions) => {
                    return (
                      <Panel header={permissionModule.module} key={permissionModule.moduleId.toString()}>
                        <Row>
                          {
                            permissionModule && permissionModule.permissions && !isEmpty(permissionModule.permissions) &&
                            permissionModule.permissions.map((permission: PermissionDetails) => {
                              return (
                                <Col key={"permission" + permission.permissionId} xs={12} xl={6}>
                                  <Checkbox defaultChecked={permission.isAllowed}
                                    disabled={this.state.roleDetails.name === "Admin" ? true : false}
                                    value={permission.permissionId}
                                    onChange={(e) => this.onChange(e, permissionModule.moduleId)}>
                                    {permission.displayName}
                                  </Checkbox>
                                </Col>
                              )
                            })
                          }
                        </Row>
                      </Panel>
                    )
                  })
                }
              </Collapse>
              :
              <EmptyStateComponent title="No Roles" />
          }
        </Spin>
      </Card>
    )
  }

  /**
   * Update user role permissions on check/uncheck
   */
  onChange = (e, moduleId) => {
    let formData = {
      moduleId: moduleId,
      permissionId: e.target.value
    }
    this.setState({ isLoading: true })
    this.props.actions.updateSelectedRolePermissions(this.state.roleDetails.roleId, formData);
  }

} 


import * as React from "react";
import { isEqual } from "lodash";
import { Link } from "react-router";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";
import SessionService from '../../../common/services/SessionService'

import { Card, List, Button, Modal, Form, Spin } from 'antd';

export class CharityLinkedLocationComponent extends React.Component<ICharityLinkedLocationsProps, ICharityLinkedLocationsState> {
  constructor(props: ICharityLinkedLocationsProps) {
    super(props);
    this.state = {
      locations: this.props.locations || [],
        isLoading: this.props.locations ? false : true,
        modalVisible: false,
        apiKeyGeneratedLocation: null
    }
  }

  componentWillReceiveProps(nextProps: ICharityLinkedLocationsProps) {
    if (nextProps.locations && !isEqual(this.props.locations, nextProps.locations)) {
      this.setState({ locations: nextProps.locations, isLoading: false })
    }
  }

  render() {
    return (
      <Card className="no-body-padding" bordered={false}
        title="Locations">
        <List
          loading={this.state.isLoading}
          itemLayout="horizontal"
          dataSource={this.state.locations || []}
          locale={
            {
              emptyText: <EmptyStateComponent title="No Linked Locations" />
            }
          }
          renderItem={(item: ListLocationDetail) => (
            <List.Item>
              <List.Item.Meta
                      title={<span><i style={{ fontSize: '18px', verticalAlign: "sub" }} className="icon-location mr-8 t-grey-dark" />
                          <Link to={"/locations/" + item.id} className="link-primary">{item.location}</Link><span style={{ paddingLeft: '16px' }}></span>
                          {
                              SessionService.getRole() === "Admin" && !item.hasAPIKey && <Button key={item.id} onClick={() => {
                                  this.setState({ apiKeyGeneratedLocation: item, modalVisible : true })
                                  this.props.generateAPIKey({ locationId: item.id, charityId: this.props.charityId })
                                }}>Generate API Key</Button>
                          }
                          {
                              SessionService.getRole() === "Admin" && item.hasAPIKey && <Button key={item.id} onClick={() => {
                                  this.props.revokeAPIKey({ locationId: item.id, charityId: this.props.charityId })
                              }} className={"ant-btn-danger"}>Revoke API Key</Button>
                          }
                </span>}
                      description={
                        <span className="ml-26">
                          {item.localCharityId && `Local Charity ID: ${item.localCharityId}`}
                        </span>
                      }
              />
            </List.Item>
          )}
            />

            <Modal
                title={`Api Key Generated for ${this.state.apiKeyGeneratedLocation ? this.state.apiKeyGeneratedLocation.location : ''}`}
                visible={this.state.modalVisible}
                onOk={() => { this.setState({ modalVisible: false }) }}
                okText={ "Close" }
            >
                <p>Warning, Once this window is closed the password will be irretrievable.  Make sure it stored before closing this window.</p>
                {!this.props.generatedAPIKeyPassword && <Spin size="large" /> }
                {this.props.generatedAPIKeyPassword &&
                    <Form>
                        <Form.Item label="Username">
                            {this.props.generatedAPIKeyUserName}
                        </Form.Item>
                        <Form.Item label="Password">
                            {this.props.generatedAPIKeyPassword}
                        </Form.Item>
                    </Form>
                }
            </Modal>
      </Card>
    )
  }

}

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as DistributorsActions from '../../actions/distributors/DistributorsActions';
import { DistributorsListTableComponent } from '../../components/distributors/DistributorsListTableComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    distributorsListSuccess: state.DistributorsReducer.loadDistributorListDataSuccess,
    distributorsListError: state.DistributorsReducer.loadDistributorListDataError,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(DistributorsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DistributorsListTableComponent)
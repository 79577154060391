import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as BreadcrumbActions from '../../../../common/actions/breadcrumbs/BreadcrumbActions';
import { GameTerminalStatusPage } from '../../../components/reports/locationStatisticsReports/GameTerminalStatusPage';
import { RootState } from '../../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadGameTerminalStatusChartSuccess: state.ReportsReducer.loadGameTerminalStatusChartSuccess,

    loadGameTerminalStatusListSuccess: state.ReportsReducer.loadGameTerminalStatusListSuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(BreadcrumbActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GameTerminalStatusPage)

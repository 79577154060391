import { useAppDispatch } from './storeHooks';
import { useEffect } from 'react';
import AppUtilityService from '../services/AppUtilityService';
import { updateBreadcrumbs } from '../actions/breadcrumbs/BreadcrumbActions';

export type Breadcrumb = {
  name: string;
  link: string | null;
};

function useBreadcrumbs(breadcrumbsList: Breadcrumb[]) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
    dispatch(updateBreadcrumbs(breadcrumbs));
  }, [dispatch]);
}

export default useBreadcrumbs;

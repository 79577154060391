import * as React from "react";
import AppUtilityService from "../../services/AppUtilityService";

export class ChartTabularLegendsComponent extends React.Component<ChartTabularLegendsProps, {}> {
    constructor(props: ChartTabularLegendsProps) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <table className="chart-tabular-legends" id={this.props.id}>
                <tbody>
                    {
                        this.props.chartData && this.props.chartData.data.map((obj, index) => {
                            return (
                                <tr key={index}>
                                    <td>{obj.name}</td>
                                    <td>{`$${AppUtilityService.shortenLargeNumber(obj.y, 2)}`}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        );
    }


}
import { createApiCaller } from '../../../common/services/ApiService';
import { fetchDataWithActionCreator } from '../../../common/store/fetched';
import { AppDispatch } from '../../../common/store/ConfigureStore';
import { processFailureReason } from '../../../common/store/failure';
import { LocationCharity } from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';
import { locationCharitiesUpdated } from './slice-actions';

export const getLocationCharities = (locationId: number) =>
  async (dispatch: AppDispatch) =>
    await fetchDataWithActionCreator(
      locationCharitiesUpdated,
      dispatch,
      createApiCaller(api => api.getData<LocationCharity[]>(`/locations/${locationId}/charities`)),
      processFailureReason,
      true,
    )

import * as React from 'react';
import { useEffect } from 'react';
import { Spin } from 'antd';
import AppUtilityService from '../../../common/services/AppUtilityService';
import webAccessService from '../../../common/services/WebAccessService';
import ReportsModule from './reportsModule/ReportsModule';
import { useAppDispatch, useAppSelector } from "../../../common/hooks/storeHooks";
import { updateBreadcrumbs } from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import { loadReportsList } from '../../actions/reports/ReportsActions';

const ReportsListPage = () => {
  const dispatch = useAppDispatch();
  const reportsListSuccess = useAppSelector(state => state.ReportsReducer.reportsListSuccess);
  const reportsListError = useAppSelector(state => state.ReportsReducer.reportsListError);
  const isLoading = reportsListSuccess == null && reportsListError == null;

  useEffect(
    () => {
      dispatch(loadReportsList());
    },
    [dispatch]);

  useEffect(
    () => {
      if (reportsListError) {
        AppUtilityService.handleApiError(reportsListError);
      }
    },
    [reportsListError]);

  useEffect(
    () => {
      let breadcrumbsList = [
        { name: "Reports", link: null },
      ]
      const breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList, null);
      dispatch(updateBreadcrumbs(breadcrumbs));
    },
    [dispatch]);

  return (
    <div className="pg-container page-container">
      <Spin spinning={isLoading}>
        <ReportsModule
          header="Arrow Reports"
          reportType="ArrowReports"
          permissionsModuleName="Reports: Arrow Reports"
        />
        {
          webAccessService.hasPermissionToAccessReports()
          &&
          <ReportsModule
            header="Location Statistics Reports"
            reportType="LocationStatistics"
          />
        }
        <ReportsModule
          header="SSRS Reports"
          reportType="SSRS"
          permissionsModuleName="Reports"
        />
        <ReportsModule
          header="State Reports"
          reportType="State"
          permissionsModuleName="Reports: State Reports"
        />
        <ReportsModule
          header="Invoicing"
          reportType="Invoicing"
          permissionsModuleName="Invoices"
        />
      </Spin>
    </div>
  );
}

export default ReportsListPage;

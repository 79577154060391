import React, { useState } from 'react';
import { TicketSalesByGameReportItem } from '../../reducers/reporting/reporting.models';
import { Radio } from 'antd';
import { DonutChartComponent } from '../../../common/components/charts/DonutChartComponent';
import AppUtilityService from '../../../common/services/AppUtilityService';

export type Props = {
  data: TicketSalesByGameReportItem[];
  chartId: string;
  defaultView: keyof TicketSalesByGameReportItem;
};

const breakdownByGameFields: Partial<Record<keyof TicketSalesByGameReportItem, string>> = {
  soldTicketCount: 'Sold Tickets',
  grossRevenue: 'Gross Revenue',
  payout: 'Prizes Awarded',
};

const TicketSalesBreakdownByGameChart = ({ data, chartId, defaultView }: Props) => {
  const [breakdownByGameField, setBreakdownByGameField] = useState<keyof TicketSalesByGameReportItem>(defaultView);

  const selectedData = data
    ?.map(i => ({ name: i.gameName, y: Number(i[breakdownByGameField]) }))
    ?.sort((a, b) => b.y - a.y);
  const total = selectedData?.reduce((acc, curr) => acc + curr.y, 0);
  const totalPrefix = breakdownByGameField === 'soldTicketCount' ? '' : '$';
  const totalFormatted = AppUtilityService.shortenLargeNumber(total, 0)
  const breakdownByGameChartData: IDonutChartProps['chartData'] = {
    title: {
      html: `<div class="bold text-center font-18 t-grey-dark">${breakdownByGameFields[breakdownByGameField]}</div>`,
      css: {
        color: '#A6ADC9',
        fontSize: 18,
      },
    },
    subTitle: {
      html: `<div class="font-12">${totalPrefix}${totalFormatted}</div>`,
      css: {
        color: '#4572A7',
        fontSize: 12,
      },
    },
    data: selectedData,
    units: 'currency',
  }
  return (
    <>
      <DonutChartComponent
        id={chartId}
        height={400}
        responsiveHeight={400}
        chartData={breakdownByGameChartData}
        showLegend
        isVerticalLegend
      />
      <Radio.Group
        value={breakdownByGameField}
        onChange={e => setBreakdownByGameField(e.target.value)}
      >
        {
          Object.entries(breakdownByGameFields).map(([key, value]) => (
            <Radio.Button key={key} value={key}>{value}</Radio.Button>
          ))
        }
      </Radio.Group>
    </>
  );
}

export default TicketSalesBreakdownByGameChart;

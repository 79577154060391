import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as DashboardActions from '../../actions/dashboard/DashboardActions';
import { MapStatsCardComponent } from '../../components/dashboard/MapStatsCardComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadDashboardMapStatsSuccess: state.DashboardReducer.loadDashboardMapStatsSuccess,
    loadDashboardMapStatsError: state.DashboardReducer.loadDashboardMapStatsError,
    zonesListSuccess: state.DropdownReducer.loadZonesDropdownDataSuccess,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(DashboardActions, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(MapStatsCardComponent)

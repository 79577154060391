
import * as React from "react";
import { isEqual } from "lodash";
import { FormComponentProps } from 'antd/lib/form/Form'
import AppUtilityService from "../../../common/services/AppUtilityService";
import UsersLinkedLocationsAutocompleteContainer from "../../containers/autocomplete/UsersLinkedLocationsAutocompleteContainer";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";
import { Link } from "react-router";

import { Card, Form, Modal, Row, Col, List } from 'antd';
const FormItem = Form.Item;
const confirm = Modal.confirm;

class UsersLinkedLocationsComponent extends React.Component<IUserLinkedLocationsProps & FormComponentProps, IUserLinkedLocationsState> {

  private autocompleteRef: any;

  constructor(props: IUserLinkedLocationsProps & FormComponentProps) {
    super(props);
    this.state = {
      userDetails: this.props.userDetails || null,
      isLoading: this.props.userDetails ? false : true
    }
  }

  componentWillReceiveProps(nextProps: IUserLinkedLocationsProps & FormComponentProps) {
    if (nextProps.userDetails && !isEqual(this.props.userDetails, nextProps.userDetails)) {
      this.setState({ userDetails: nextProps.userDetails, isLoading: false })
    }

    /**
     * Handle success/error on assign location action
     */
    if (nextProps.assignLocationToUsersSuccess && !isEqual(this.props.assignLocationToUsersSuccess, nextProps.assignLocationToUsersSuccess)) {
      let userDetails = { ...this.state.userDetails };
      userDetails.linkedLocations = nextProps.assignLocationToUsersSuccess;
      AppUtilityService.showMessageToast("Location added", "success");
      this.props.form.resetFields();
      this.autocompleteRef.resetDatasource();
      this.setState({ userDetails, isLoading: false })
    }
    if (nextProps.assignLocationToUsersError && !isEqual(this.props.assignLocationToUsersError, nextProps.assignLocationToUsersError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.assignLocationToUsersError);
    }

    /**
     * Handle success/error on unassign location action
     */
    if (nextProps.unassignLocationFromUsersSuccess && !isEqual(this.props.unassignLocationFromUsersSuccess, nextProps.unassignLocationFromUsersSuccess)) {
      var userDetails = { ...this.state.userDetails };
      var responseData = nextProps.unassignLocationFromUsersSuccess;
      userDetails.linkedLocations = responseData;
      AppUtilityService.showMessageToast("Location removed", "success");
      this.autocompleteRef.resetDatasource();
      this.setState({ userDetails, isLoading: false })
    }
    if (nextProps.unassignLocationFromUsersError && !isEqual(this.props.unassignLocationFromUsersError, nextProps.unassignLocationFromUsersError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.unassignLocationFromUsersError);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Card className="no-body-padding no-header-border" bordered={false}
        title="Locations"
      >
        {
          this.state.userDetails && this.state.userDetails.role !== 'Admin' &&
          <Form>
            <Row>
              <Col span={20}>
                <FormItem>
                  {getFieldDecorator('locationId', {
                    rules: [{ required: true, message: 'Please select a location to add!' }],
                    validateTrigger: ['onSubmit']
                  })(
                    <UsersLinkedLocationsAutocompleteContainer
                      placeholder="Type here to add a location"
                      apiUrlId={this.state.userDetails && this.state.userDetails.userId}
                      onRef={ref => (this.autocompleteRef = ref)} />
                  )}
                </FormItem>
              </Col>
              <Col span={4}>
                <div className="text-right">
                  <FormItem>
                    <span className="link-primary" onClick={this.assignLocation}>Add</span>
                  </FormItem>
                </div>
              </Col>
            </Row>
          </Form>
        }
        <List
          loading={this.state.isLoading}
          itemLayout="horizontal"
          dataSource={this.state.userDetails ? this.state.userDetails.linkedLocations : []}
          locale={
            {
              emptyText: <EmptyStateComponent title="No Linked Locations" />
            }
          }
          renderItem={(item: UserLocationDetail) => (
            <List.Item actions={this.generateActions(item)}>
              <List.Item.Meta
                title={<span><i style={{ fontSize: '18px', verticalAlign: "sub" }} className="icon-location mr-8 t-grey-dark" />
                  <Link to={"/locations/" + item.id} className="link-primary">{item.location}</Link>
                </span>}
              />
            </List.Item>
          )}
        />
      </Card>
    )
  }

  /**
   * Generate actions for each item in the list
   */
  generateActions = (item: UserLocationDetail): Array<any> => {
    var actions = [];
    if (this.state.userDetails && this.state.userDetails.role !== 'Admin') {
      actions.push(<i className="icon-remove link-danger" onClick={() => this.unassignLocation(item.id)} />);
    }
    return actions;
  }

  /**
   * Assign selected location to user
   */
  assignLocation = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ isLoading: true })
        var { userDetails } = this.state;
        var formData = values;
        this.props.actions.assignLocationToUsers(userDetails.userId, formData.locationId);
      }
    });
  }

  /**
   * Unassign selected charity from user
   */
  unassignLocation = (locationId: number) => {
    var { userDetails } = this.state;
    confirm({
      title: 'Confirm dialog',
      content: 'Are you sure you want to remove the selected location linked to this user?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        this.setState({ isLoading: true })
        this.props.actions.unassignLocationFromUsers(userDetails.userId, locationId);
      },
    });
  }

} export default Form.create<IUserLinkedLocationsProps & FormComponentProps>()(UsersLinkedLocationsComponent);

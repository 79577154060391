import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as QuarterCloseActions from '../../../app/actions/quarterClose/QuarterCloseActions'
import { QuarterCloseSelectionReviewPage } from '../../components/quarterClose/QuarterCloseSelectionReviewPage'
import { RootState } from '../../../common/store/ConfigureStore';


const mapStateToProps = (state: RootState) => {
    return {
        games: state.QuarterCloseReducer.games,
        selectedSetup: state.QuarterCloseReducer.selectedSetup,
        locationIds: state.QuarterCloseReducer.selectedLocationIds,
        postingSelectedSetup: state.QuarterCloseReducer.postingSelectedSetup,
        latestSetup: state.QuarterCloseReducer.latestSetup.data,
        gameSelectionWindowId: state.QuarterCloseReducer.gameSelectionWindowId,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(QuarterCloseActions, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(QuarterCloseSelectionReviewPage)

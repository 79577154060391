import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as UserRoleActions from '../../actions/userRole/UserRoleActions';
import { UserRoleListTableComponent } from '../../components/userRole/UserRoleListTableComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    userRoleListFilters: state.UserRoleReducer.userRoleListFilters,

    loadAllUserRolesSuccess: state.UserRoleReducer.loadAllUserRolesSuccess,
    loadAllUserRolesError: state.UserRoleReducer.loadAllUserRolesError,

    unarchiveRolesSuccess: state.UserRoleReducer.unarchiveRolesSuccess,
    unarchiveRolesError: state.UserRoleReducer.unarchiveRolesError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(UserRoleActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRoleListTableComponent)

import * as React from "react";
import {Row, Col} from 'antd';
const styles = require('../../../common/styles/ViewGameStyles.css');

export const ViewGameIcon = (props) => {
  console.log("name:" + props.name);
  
  return (
    <div className="page-container">
      {props.name !== null && props.name !== undefined &&
      <Row style={{marginLeft: 30}} type="flex" justify="center" align="middle">
        <Col>
        <img className={"gameIcon"} src={props.icon} alt="No icon image" />
        </Col>
      </Row>
      }
    </div>
  );
}
export default ViewGameIcon;
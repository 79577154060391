import * as React from "react";
import { isEqual } from "lodash";
import LocationBasicDetailsContainer from "../../containers/locations/LocationBasicDetailsContainer";
import AppUtilityService from "../../../common/services/AppUtilityService";
import WebAccessService from "../../../common/services/WebAccessService";

import { Layout, Tabs } from 'antd';
import { LocationDetailsOverviewTabComponent } from "./LocationDetailsOverviewTabComponent";
import { LocationDetailsInvoicesTabComponent } from "./LocationDetailsInvoicesTabComponent";
import LocationDetailsReportTabContainer from "../../containers/locations/LocationDetailsReportTabContainer";
const TabPane = Tabs.TabPane;

export class LocationDetailsPage extends React.Component<ILocationDetailsPageProps, ILocationDetailsPageState> {

  constructor(props: ILocationDetailsPageProps) {
    super(props);

    this.state = {
      locationId: parseInt(this.props.params.locationId) || null,
      locationDetails: null
    }
  }

  componentWillReceiveProps(nextProps: ILocationDetailsPageProps) {
    if (nextProps.loadBasicDetailsSuccess && !isEqual(this.props.loadBasicDetailsSuccess, nextProps.loadBasicDetailsSuccess)) {
      var responseData: any = nextProps.loadBasicDetailsSuccess;
      this.setState({ locationDetails: responseData }, () => {
        this.breadcrumbsBuild()
      })
    }

  }

  render() {
    return (
      <div className="pg-container no-padding-page-container">
        <Layout>
          <LocationBasicDetailsContainer locationId={this.state.locationId} />
          {
            (WebAccessService.hasPermissionToAccess("Location", "ViewSummary") ||
              WebAccessService.hasPermissionToAccess("Location", "ViewInvoices") ||
              WebAccessService.hasPermissionToAccess("Location", "ViewReports")) &&
            <Tabs className="page-tabs" defaultActiveKey="1" tabBarStyle={{ background: "#fff" }}>
              {
                WebAccessService.hasPermissionToAccess("Location", "ViewSummary") &&
                <TabPane tab="Overview" key="1">
                  <LocationDetailsOverviewTabComponent locationId={this.state.locationId} />
                </TabPane>
              }
              {
                WebAccessService.hasPermissionToAccess("Location", "ViewReports") &&
                (WebAccessService.hasPermissionToAccess("Reports", "ByDate") || WebAccessService.hasPermissionToAccess("Reports", "ByGame")
                  || WebAccessService.hasPermissionToAccess("Reports", "ByGT")) &&
                <TabPane tab="Reports" key="3">
                  <LocationDetailsReportTabContainer locationId={this.state.locationId} />
                </TabPane>
              }
            </Tabs>
          }
        </Layout>
      </div>
    )
  }

  // locationBasicDetailsSuccessHandler = (locationDetails) => {
  //   this.setState({ locationDetails }, () => {
  //     this.breadcrumbsBuild()
  //   })
  // }

  breadcrumbsBuild = () => {
    let breadcrumbsList = [
      { name: "Locations", link: "/locations" },
      { name: this.state.locationDetails.name, link: null }
    ]
    var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
    this.props.actions.updateBreadcrumbs(breadcrumbs);
  }


}


import * as React from "react";
import { isEqual } from "lodash";
import { FormComponentProps, ValidationRule } from 'antd/lib/form/Form'
import LocationTypesDropdownContainer from "../../../common/containers/dropdown/LocationTypesDropdownContainer";
import DistributorsAutocompleteContainer from "../../../common/containers/dropdown/DistributorsAutocompleteContainer";

import { ChromePicker } from 'react-color';
import { Form, Input, Row, Col, DatePicker } from 'antd';
import AddressTypesDropdownContainer from "../../../common/containers/dropdown/AddressTypesDropdownContainer";
import moment from "moment";
const FormItem = Form.Item;

class SaveLocationFormComponent extends React.Component<ISaveLocationFormProps & FormComponentProps, ISaveLocationFormState> {

  constructor(props: ISaveLocationFormProps & FormComponentProps) {
    super(props);
    this.state = {
      locationDetails: this.props.locationDetails || null,
    }
  }

  componentWillReceiveProps(nextProps: ISaveLocationFormProps) {
    if (!isEqual(this.props.locationDetails, nextProps.locationDetails)) {
      this.setState({ locationDetails: nextProps.locationDetails })
    }
  }

  private validateAddressType: ValidationRule['validator'] = (rule, value, callback) => {
    if (value) { // If an address type is selected
      // Retrieve form values
      const { address1, address2, city, addressState, zipcode } = this.props.form.getFieldsValue([
        'address1', 'address2', 'city', 'addressState', 'zipcode',
      ]);

      // Check if any address field is populated
      if (!address1 && !address2 && !city && !addressState && !zipcode) {
        callback('Please fill in at least one address field when an address type is selected.');
      } else {
        callback(); // Validation passed
      }
    } else {
      callback(); // Validation passed (no address type selected)
    }
  };

  private forceAddressTypeValidation = () => {
    this.props.form.validateFields(['addressTypeId'], { force: true });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form>
        <Row gutter={16}>
          <Col span={24}>
            <Row gutter={16}>
              <Col span={12}>
                <FormItem label="Name">
                  {getFieldDecorator('name', {
                    initialValue: this.state.locationDetails && this.state.locationDetails.name,
                    rules: [{ required: true, message: 'Please enter a location name.' }],
                  })(
                    <Input placeholder="" />
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Email">
                  {getFieldDecorator('email', {
                    initialValue: this.state.locationDetails && this.state.locationDetails.email,
                    rules: [
                      { type: 'email', message: 'The input is not valid email.' },
                    ],
                  })(
                    <Input type="email" placeholder="" />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <FormItem label="Location type">
                  {getFieldDecorator('typeId', {
                    initialValue: this.state.locationDetails && this.state.locationDetails.typeId,
                    rules: [{ required: true, message: 'Please select a location type.' }],
                  })(
                    <LocationTypesDropdownContainer placeholder="Select location type" />
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Distributor">
                  {getFieldDecorator('distributorId', {
                    initialValue: this.state.locationDetails && this.state.locationDetails.distributorId,
                  })(
                    <DistributorsAutocompleteContainer
                      placeholder="Select distributor"
                      preselectedSearchTerm={this.state.locationDetails && this.state.locationDetails.distributor}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <FormItem label="License">
                  {getFieldDecorator('license', {
                    initialValue: this.state.locationDetails && this.state.locationDetails.license
                  })(
                    <Input placeholder="" />
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="License expiry date">
                  {getFieldDecorator('licenseExpiryDate', {
                    initialValue: this.state.locationDetails && this.state.locationDetails.licenseExpiryDate ? moment(this.state.locationDetails.licenseExpiryDate) : null
                  })(
                    <DatePicker
                      placeholder={'Expiry date'}
                      format={'MM/DD/YYYY'}
                      style={{ width: '100%' }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <FormItem label="PTD">
                  {getFieldDecorator('ptd', {
                    initialValue: this.state.locationDetails && this.state.locationDetails.ptd
                  })(
                    <Input placeholder="" />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <FormItem label="Address line 1">
                  {getFieldDecorator('address1', {
                    initialValue: this.state.locationDetails && this.state.locationDetails.address1,
                  })(
                    <Input placeholder="" onBlur={this.forceAddressTypeValidation} />
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Address line 2">
                  {getFieldDecorator('address2', {
                    initialValue: this.state.locationDetails && this.state.locationDetails.address2,
                  })(
                    <Input placeholder="" onBlur={this.forceAddressTypeValidation} />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <FormItem label="City">
                  {getFieldDecorator('city', {
                    initialValue: this.state.locationDetails && this.state.locationDetails.city,
                  })(
                    <Input placeholder="" onBlur={this.forceAddressTypeValidation} />
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="State">
                  {getFieldDecorator('addressState', {
                    initialValue: this.state.locationDetails && this.state.locationDetails.addressState,
                  })(
                    <Input placeholder="" onBlur={this.forceAddressTypeValidation} />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <FormItem label="Zipcode">
                  {getFieldDecorator('zipcode', {
                    initialValue: this.state.locationDetails && this.state.locationDetails.zipcode,
                  })(
                    <Input placeholder="" onBlur={this.forceAddressTypeValidation} />
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Address Type">
                  {getFieldDecorator('addressTypeId', {
                    initialValue: this.state.locationDetails?.addressTypeId,
                    rules: [{ validator: this.validateAddressType }],
                  })(
                    <AddressTypesDropdownContainer placeholder="Select address type" />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <FormItem label="Location color">
                  {getFieldDecorator('color', {
                    initialValue: this.state.locationDetails && this.state.locationDetails.color || "#22194D"
                  })(
                    <ChromePicker
                      disableAlpha={true}
                      color={this.state.locationDetails && this.state.locationDetails.color || "#22194D"}
                      onChangeComplete={color => this.handleChangeComplete(color)}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    )
  }
  /**
   * Handle colour picker change
   */
  handleChangeComplete = (colorPalette) => {
    if (colorPalette) {
      this.props.form.setFieldsValue({ color: colorPalette.hex })
      let locationDetails = { ...this.state.locationDetails };
      locationDetails.color = colorPalette.hex;
      this.setState({ locationDetails });
    }
  }
} export default Form.create<ISaveLocationFormProps & FormComponentProps>()(SaveLocationFormComponent);

import * as React from "react";
import { AccountSettings } from '@aws-amplify/ui-react';
import { Card, Col, Row } from 'antd';
import AppUtilityService from '../../services/AppUtilityService';
import { hashHistory } from 'react-router';

const ChangePasswordComponent = () => {
  const handleSuccess = () => {
    AppUtilityService.showNotificationToast('Password changed', 'Password has been changed successfully', 'success');
    hashHistory.push("/login");
  };
  return (
    <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Col xs={{ span: 24 }} sm={{ span: 16 }} md={{ span: 16 }} lg={{ span: 10 }} xl={{ span: 6 }}>
        <Card title="Change Password" bordered={false}>
          <AccountSettings.ChangePassword onSuccess={handleSuccess}/>
        </Card>
      </Col>
    </Row>
  );
};

export default ChangePasswordComponent;

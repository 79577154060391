import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { loadRoleUsersAvailableAutocomplete } from '../../actions/autocomplete/AutocompleteActions';
import { DropdownComponent } from '../../../common/components/dropdown/DropdownComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
    return {
        datasource: state.AdminAutocompleteReducer.loadRoleUsersAvailableSuccess,
        datasourceError: state.AdminAutocompleteReducer.loadRoleUsersAvailableError,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadData: bindActionCreators(loadRoleUsersAvailableAutocomplete, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DropdownComponent)

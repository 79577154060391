import * as React from "react";
import { isEqual, findLastIndex, map } from "lodash";
import { Breadcrumb } from 'antd';
import { Link } from "react-router";

export class BreadcrumbComponent extends React.Component<IBreadcrumbsProps, IBreadcrumbsState>{
    constructor(props: any) {
        super(props);
        this.state = {
            breadcrumbs: this.props.breadcrumbs || null,
        }
    }

    componentWillReceiveProps(nextProps: IBreadcrumbsProps) {
        if (nextProps.breadcrumbs && !isEqual(this.props.breadcrumbs, nextProps.breadcrumbs)) {
            this.setState({ breadcrumbs: nextProps.breadcrumbs })
        }
    }

    render() {
        var { breadcrumbs } = this.state;
        if (breadcrumbs) {
            var lastItemIndex = findLastIndex(breadcrumbs.breadcrumbsList);
        }
        return (
            <div className="page-title">
                <Breadcrumb separator="/">
                    {
                        breadcrumbs && map(breadcrumbs.breadcrumbsList, (item: BreadcrumbsList, index) => {
                            return (
                                <Breadcrumb.Item key={index}>
                                    {
                                        item.link ? <Link to={item.link}>{item.name}</Link> : item.name
                                    }
                                    {
                                        (lastItemIndex === index && breadcrumbs.extraContent) &&
                                        breadcrumbs.extraContent
                                    }
                                </Breadcrumb.Item>
                            )
                        })
                    }
                </Breadcrumb>
            </div>
        );
    }

}
import React, { useEffect, useState } from 'react';
import AppUtilityService from '../../../common/services/AppUtilityService';
import { updateBreadcrumbs } from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { Button, Card, DatePicker, Dropdown, Icon, Menu, Row, Select, Spin } from 'antd';
import moment, { Moment } from 'moment/moment';
import { downloadOhioQuarterly } from '../../actions/invoicingReports/downloadOhioQuarterly';
import { LoadState } from '../../../common/store/fetched';
import { saveFile } from '../../../common/store/fileDownload';
import { DataGridComponent } from '../../../common/components/table/DataGridComponent';
import appConstants from '../../../common/AppConstants';
import { getOhioQuarterlyReport } from '../../actions/invoicingReports/getOhioQuarterlyReport';

const OhioQuarterlyPage = () => {

  const dispatch = useAppDispatch();

  const reportsData = useAppSelector(state => state.InvoicingReportsReducer.ohioQuarterlyReport.data);

  const isFetchingDataInProgress = useAppSelector(
    state => state.InvoicingReportsReducer.ohioQuarterlyReport.loadState === LoadState.InProgress
  );

  const isDownloadingInProgress = useAppSelector(
    state => state.InvoicingReportsReducer.downloadOhioQuarterlyOperation.loadState === LoadState.InProgress
  );

  const [dateRange, setDateRange] = useState<[Moment | null, Moment | null] | null>(([
    moment().startOf('quarter').startOf('day'),
    moment().startOf('day')
  ]));

  const handleDateChange = (dates: [Moment | null, Moment | null] | null) => {
    setDateRange(dates?.map(date => date?.startOf('day')) as [Moment, Moment] || null);
  };

  const disabledDate = (_: Moment | null) => {
    return false;
  };

  useEffect(
    () => {
      const breadcrumbsList = [
        { name: "Reports", link: "/Reports" },
        { name: 'Ohio Quarterly', link: null },
      ];
      const breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
      dispatch(updateBreadcrumbs(breadcrumbs));
    },
    [dispatch]);

  const resetRange = async () => {
    setDateRange([
      moment().startOf('quarter').startOf('day'),
      moment().startOf('day')
    ]);
  };

  const downloadExcel = async () => {
    const file = await dispatch(downloadOhioQuarterly(dateRange[0], dateRange[1]));
    saveFile(file);
  };

  const fetchData = async () => {
    await dispatch(getOhioQuarterlyReport(dateRange[0], dateRange[1]));
  };

  const validateAction =
    dateRange == null
    || dateRange.length !== 2
    || dateRange[0] == null
    || !dateRange[0].isValid()
    || dateRange[1] == null
    || !dateRange[1].isValid();

  const extraActions = <div>
  <Dropdown.Button type="primary" onClick={downloadExcel}
    overlay={
      <Menu onClick={() => { }}>
        <Menu.Item key="1">Excel</Menu.Item>
      </Menu>
    }
    disabled={validateAction || isDownloadingInProgress}>
    <Icon type="download" style={{ fontSize: "16px" }} /> Download as Excel
  </Dropdown.Button></div>;

  const filteringPanel = <div><DatePicker.RangePicker
    value={dateRange}
    onChange={handleDateChange}
    disabledDate={disabledDate}
    format="MM/DD/YYYY"
    style={{ marginRight: 5 }}
  />
    <Button
      onClick={resetRange}
      type="default"
      style={{ marginRight: 10, lineHeight: "normal", marginTop: "5px" }}
    >
      ↻
    </Button>
    <Button
      disabled={validateAction}
      loading={isFetchingDataInProgress}
      onClick={fetchData}
      type="primary"
      style={{ marginRight: 10, lineHeight: "normal", marginTop: "5px" }}
    >
      Refresh
    </Button>
  </div>;
  return (

    <div className="pg-container page-container">
      <Card
        className="no-header-border"
        bordered={false}
        extra={extraActions}
        title="Ohio Quarterly">
        <Row>
          <Spin spinning={isFetchingDataInProgress}>
            <DataGridComponent
              toolbarContent={filteringPanel}
              dataSource={reportsData || []}
              dataSrcColumns={appConstants.ohQuarterlyReportColumns}
              summaryAvgColumns={null}
              summarySumColumns={null}
              isLoading={isFetchingDataInProgress}
              storeIdentifier="OhioQuarterlyReport"
              showPageSizeSelector={true}
            />
          </Spin>
        </Row>
      </Card>
    </div>
  );
}

export default OhioQuarterlyPage;

import { createApiCaller } from '../../../common/services/ApiService';
import { locationCharityDistributorDiscountsUpdated } from './slice-actions';
import { fetchDataWithActionCreator } from '../../../common/store/fetched';
import { AppDispatch } from '../../../common/store/ConfigureStore';
import { processFailureReason } from '../../../common/store/failure';
import {
  LocationCharityDistributorDiscount,
} from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';

export const getLocationCharityDistributorDiscounts = (configId: number) =>
  async (dispatch: AppDispatch) =>
    await fetchDataWithActionCreator(
      locationCharityDistributorDiscountsUpdated,
      dispatch,
      createApiCaller(api => api.getData<LocationCharityDistributorDiscount[]>(`/locationCharityDistributorDiscount/${configId}`)),
      processFailureReason,
      true,
    )

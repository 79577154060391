
import * as React from "react";
import AppUtilityService from "../../../common/services/AppUtilityService";
import ContactsListTableContainer from "../../containers/contacts/ContactsListTableContainer";


import { Card, Input, Icon, Button, Badge } from 'antd';
import AddNewContactModalContainer from "../../containers/contacts/AddNewContactModalContainer";
import webAccessService from "../../../common/services/WebAccessService";

export class ContactsListPage extends React.Component<IContactsPageProps, IContactsPageState> {

  private contactsListRef: any;

  constructor(props: IContactsPageProps) {
    super(props);
    this.state = {
      searchText: "",
      totalContacts: null,
      showAddContactModal: false,
    }
  }

  componentDidMount() {
    this.breadcrumbsBuild();
    if (this.props.contactListFilters) {
      this.handlePresetFilters(this.props.contactListFilters)
    }
  }

  render() {
    const title = webAccessService.hasPermissionToAccess("Contact", "Add") ?
      <Button type="primary" onClick={this.openAddNewUserModal}>+ New Contact</Button> : null;

    const extraActions = <div className="contacts-filters">
      <span>
        <Input type="text"
          suffix={<Icon type="search" theme="outlined" />}
          placeholder="Search"
          onChange={this.searchChange}
          style={{ width: 200 }}
          value={this.state.searchText}
        />
      </span>
      <span className="ml-8">
        <Button type="default" onClick={this.resetPageFilters}>Reset</Button>
      </span>
    </div>;

    return (
      <div className="pg-container page-container">
        <Card className="no-header-border" bordered={false}
          title={title}
          extra={extraActions}
        >
          <ContactsListTableContainer
            onRef={(ref) => this.contactsListRef = ref}
            getTotalContactsCallback={this.getTotalContactsHandler}
          />
        </Card>
        {
          this.state.showAddContactModal &&
          <AddNewContactModalContainer cancelSaveContactCallback={this.handleCancelSaveContact} />
        }
      </div>
    )
  }

  getTotalContactsHandler = (totalCount: number) => {
    this.setState({ totalContacts: totalCount }, () => {
      this.breadcrumbsBuild()
    })
  }

  breadcrumbsBuild = () => {
    let breadcrumbsList = [
      { name: "Contacts", link: null }
    ]
    var extraContent = this.state.totalContacts &&
      <span className="font-12 t-grey-dark ml-8">
        <Badge dot className="mr-8" style={{ top: '-1px', background: '#A6ADC9' }} /> {this.state.totalContacts + " Total"}
      </span>;
    var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList, extraContent);
    this.props.actions.updateBreadcrumbs(breadcrumbs);
  }

  searchChange = (e: any) => {
    this.setState({ searchText: e.target.value })
    this.contactsListRef.handleSearchChange(e.target.value);
  }

  openAddNewUserModal = () => {
    this.setState({ showAddContactModal: true })
  }

  handleCancelSaveContact = (successObj) => {
    if (successObj) {
      this.contactsListRef.loadContactsList();

    }
    this.setState({ showAddContactModal: false })
  }
  handlePresetFilters = (filterData) => {
    var searchText = filterData.searchText;
    this.setState({ searchText })
  }
  resetPageFilters = () => {
    this.setState({ searchText: "" });
    this.contactsListRef.resetPageFilters()
  }
}

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as InvoicesActions from '../../actions/invoices/InvoicesActions';
import { GetInvoicesComponent } from '../../components/invoices/GetInvoicesComponent'
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
    return {
        getInvoiceSuccess: state.InvoicesReducer.getInvoiceDataSuccess,
        getInvoiceError: state.InvoicesReducer.getInvoiceDataError
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(InvoicesActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetInvoicesComponent)

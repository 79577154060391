import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ZoneActions from '../../actions/zones/ZoneActions';
import ZoneLinkedLocationsComponent from '../../components/zones/ZoneLinkedLocationsComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    assignLocationToZoneSuccess: state.ZoneReducer.assignLocationToZoneSuccess,
    assignLocationToZoneError: state.ZoneReducer.assignLocationToZoneError,

    unassignLocationFromZoneSuccess: state.ZoneReducer.unassignLocationFromZoneSuccess,
    unassignLocationFromZoneError: state.ZoneReducer.unassignLocationFromZoneError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ZoneActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ZoneLinkedLocationsComponent)

import ApiService from "../../../common/services/ApiService";
import SessionService from "../../services/SessionService";
import AppConstants from '../../../common/AppConstants';

/**
 * All locations dropdown
 */

export function loadUserRolesDropdownActionHandler(payload = null, TYPE) {
    return { type: `LOAD_USER_ROLES_DROPDOWN_DATA_${TYPE}`, payload }
}


export function loadUserRolesDropdown() {
    let apiUrl = "/lookups/roles";
    if (!SessionService.getToken()) {
        apiUrl = "/account/roles"
    }
    return function (dispatch) {
        dispatch(loadUserRolesDropdownActionHandler(null, 'REQUEST'));
        return ApiService.getData(apiUrl, null)
            .then((successResult) => {
                dispatch(loadUserRolesDropdownActionHandler(successResult.data, 'SUCCESS'));
            })
            .catch((errorResult) => {
                dispatch(loadUserRolesDropdownActionHandler(errorResult, 'FAILURE'));
            });
    }
}

/**
 * All locations dropdown
 */

export function loadLocationsDropdownActionHandler(payload = null, TYPE) {
    return { type: `LOAD_LOCATIONS_DROPDOWN_DATA_${TYPE}`, payload }
}


export function loadLocationsDropdown(params) {
    return function (dispatch) {
        dispatch(loadLocationsDropdownActionHandler(null, 'REQUEST'));
        return ApiService.getData("/lookups/locations", params)
            .then((successResult) => {
                dispatch(loadLocationsDropdownActionHandler(successResult.data, 'SUCCESS'));
            })
            .catch((errorResult) => {
                dispatch(loadLocationsDropdownActionHandler(errorResult, 'FAILURE'));
            });
    }
}

/**
 * All locations dropdown with demo toggle
 */

export function loadLocationsWithDemoToggleDropdownActionHandler(payload = null, TYPE) {
  return { type: `LOAD_LOCATIONS_WITH_DEMO_TOGGLE_DROPDOWN_DATA_${TYPE}`, payload }
}


export function loadLocationsWithDemoToggleDropdown(params) {
  return function (dispatch) {
      dispatch(loadLocationsDropdownActionHandler(null, 'REQUEST'));
      return ApiService.getData("/lookups/locations", params)
          .then((successResult) => {
              dispatch(loadLocationsDropdownActionHandler(successResult.data, 'SUCCESS'));
          })
          .catch((errorResult) => {
              dispatch(loadLocationsDropdownActionHandler(errorResult, 'FAILURE'));
          });
  }
}

/**
 * All location types dropdown
 */

export function loadLocationTypesDropdownActionHandler(payload = null, TYPE) {
    return { type: `LOAD_LOCATION_TYPES_DROPDOWN_DATA_${TYPE}`, payload }
}


export function loadLocationTypesDropdown() {
    return function (dispatch) {
        dispatch(loadLocationTypesDropdownActionHandler(null, 'REQUEST'));
        return ApiService.getData("/lookups/locationTypes")
            .then((successResult) => {
                dispatch(loadLocationTypesDropdownActionHandler(successResult.data, 'SUCCESS'));
            })
            .catch((errorResult) => {
                dispatch(loadLocationTypesDropdownActionHandler(errorResult, 'FAILURE'));
            });
    }
}

/**
 * All zones dropdown
 */

export function loadZonesDropdownActionHandler(payload = null, TYPE) {
    return { type: `LOAD_ZONES_DROPDOWN_DATA_${TYPE}`, payload }
}


export function loadZonesDropdown(params) {
    return function (dispatch) {
        dispatch(loadZonesDropdownActionHandler(null, 'REQUEST'));
        return ApiService.getData("/lookups/hierarchicalzones", params)
            .then((successResult) => {
                dispatch(loadZonesDropdownActionHandler(successResult.data, 'SUCCESS'));
            })
            .catch((errorResult) => {
                dispatch(loadZonesDropdownActionHandler(errorResult, 'FAILURE'));
            });
    }
}

/**
 * All distributors dropdown
 */

export function loadDistributorsDropdownActionHandler(payload = null, TYPE) {
    return { type: `LOAD_DISTRIBUTORS_DROPDOWN_DATA_${TYPE}`, payload }
}


export function loadDistributorsDropdown(params) {
    return function (dispatch) {
        dispatch(loadDistributorsDropdownActionHandler(null, 'REQUEST'));
        return ApiService.getData("/lookups/distributors", params)
            .then((successResult) => {
                dispatch(loadDistributorsDropdownActionHandler(successResult.data, 'SUCCESS'));
            })
            .catch((errorResult) => {
                dispatch(loadDistributorsDropdownActionHandler(errorResult, 'FAILURE'));
            });
    }
}

// ================================================================================
/**
 * All address types
 */

export function loadAddressTypesDropdownActionHandler(payload = null, TYPE) {
    return { type: `LOAD_ADDRESS_TYPES_DROPDOWN_DATA_${TYPE}`, payload }
}


export function loadAddressTypesDropdown(params) {
    return function (dispatch) {
        dispatch(loadAddressTypesDropdownActionHandler(null, 'REQUEST'));
        return ApiService.getData("/lookups/addressTypes", params)
            .then((successResult) => {
                dispatch(loadAddressTypesDropdownActionHandler(successResult.data, 'SUCCESS'));
            })
            .catch((errorResult) => {
                dispatch(loadAddressTypesDropdownActionHandler(errorResult, 'FAILURE'));
            });
    }
}

// ================================================================================

/**
 * All contact types dropdown
 */

 export function loadContactTypesDropdownActionHandler(payload = null, type: string) {
  return { type: `LOAD_CONTACT_TYPES_DROPDOWN_DATA_${type}`, payload }
}


export function loadContactTypesDropdown() {
  return function (dispatch) {
      dispatch(loadContactTypesDropdownActionHandler(null, 'REQUEST'));
      return ApiService.getData("/lookups/contactTypes")
          .then((successResult) => {
              dispatch(loadContactTypesDropdownActionHandler(successResult.data, 'SUCCESS'));
          })
          .catch((errorResult) => {
              dispatch(loadContactTypesDropdownActionHandler(errorResult, 'FAILURE'));
          });
  }
}

// ================================================================================
/**
 * All location invoicing distributors dropdown
 */

export function loadLocationInvoicingDistributorsDropdownActionHandler(payload = null, TYPE) {
    return { type: `LOAD_LOCATION_INVOICING_DISTRIBUTORS_DROPDOWN_DATA_${TYPE}`, payload }
}


export function loadLocationInvoicingDistributorsDropdown(params) {
    return function (dispatch) {
        dispatch(loadLocationInvoicingDistributorsDropdownActionHandler(null, 'REQUEST'));
        return ApiService.getData("/lookups/invoicingDistributors", params)
          .then((successResult) => {
              dispatch(loadLocationInvoicingDistributorsDropdownActionHandler(successResult.data, 'SUCCESS'));
          })
          .catch((errorResult) => {
              dispatch(loadLocationInvoicingDistributorsDropdownActionHandler(errorResult, 'FAILURE'));
          });
    }
}

// ================================================================================
/**
 * All invoicing services dropdown
 */

export function loadInvoicingServicesDropdownActionHandler(payload = null, TYPE) {
    return { type: `LOAD_INVOICING_SERVICES_DROPDOWN_DATA_${TYPE}`, payload }
}


export function loadInvoicingServicesDropdown(params) {
    return function (dispatch) {
        dispatch(loadInvoicingServicesDropdownActionHandler(null, 'REQUEST'));
        return ApiService.getData("/lookups/invoicingServices", params)
          .then((successResult) => {
              dispatch(loadInvoicingServicesDropdownActionHandler(successResult.data, 'SUCCESS'));
          })
          .catch((errorResult) => {
              dispatch(loadInvoicingServicesDropdownActionHandler(errorResult, 'FAILURE'));
          });
    }
}

// ================================================================================

/**
 * reports charitiesSessions dropdown
 */

export function loadCharitiesSessionsDropdownActionHandler(payload = null, TYPE) {
  return { type: `LOAD_CHARITIES_SESSIONS_DROPDOWN_DATA_${TYPE}`, payload }
}

export function loadCharitiesSessionsDropdown(params) {
  return function (dispatch) {
      dispatch(loadCharitiesSessionsDropdownActionHandler(null, 'REQUEST'));
      return ApiService.getData(`/lookups/charitiesSessions/${params.dateFrom.format(AppConstants.apiDateTimeFormat)}/${params.dateTo.format(AppConstants.apiDateTimeFormat)}`, params)
        .then((successResult) => {
            dispatch(loadCharitiesSessionsDropdownActionHandler(successResult.data, 'SUCCESS'));
        })
        .catch((errorResult) => {
            dispatch(loadCharitiesSessionsDropdownActionHandler(errorResult, 'FAILURE'));
        });
  }
}

// ================================================================================
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as BreadcrumbActions from '../../../../common/actions/breadcrumbs/BreadcrumbActions';
import { DealsReportPage } from '../../../components/reports/locationStatisticsReports/DealsReportPage';
import { RootState } from '../../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadDealsChartSuccess: state.ReportsReducer.loadDealsChartSuccess,
    loadDealsChartError: state.ReportsReducer.loadDealsChartError,
    loadDealsListSuccess: state.ReportsReducer.loadDealsListSuccess,
    loadDealsListError: state.ReportsReducer.loadDealsListError,

    exportDataToExcelSuccess: state.ReportsReducer.exportDataToExcelSuccess,
    exportDataToExcelError: state.ReportsReducer.exportDataToExcelError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(BreadcrumbActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DealsReportPage)

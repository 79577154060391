import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as BreadcrumbActions from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import { LocationDetailsPage } from '../../components/locations/LocationDetailsPage';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadBasicDetailsSuccess: state.LocationsReducer.loadBasicDetailsSuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(BreadcrumbActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationDetailsPage)


import * as React from "react";
import { FormComponentProps } from 'antd/lib/form/Form';
import { isEqual } from "lodash";

import { ChromePicker } from 'react-color';
import AppUtilityService from "../../../common/services/AppUtilityService";

import { Modal, Button, Form, Row, Col, Input } from 'antd';
const FormItem = Form.Item;

class SaveLocationTypesModal extends React.Component<ISaveLocationTypesProps & FormComponentProps, ISaveLocationTypesState> {

    constructor(props: ISaveLocationTypesProps & FormComponentProps) {
        super(props);
        this.state = {
            locationType: this.props.locationType || null,
            isLoading: false
        }
    }

    componentWillReceiveProps(nextProps: ISaveLocationTypesProps & FormComponentProps) {
        if (nextProps.locationType && !isEqual(this.props.locationType, nextProps.locationType)) {
            this.setState({ locationType: nextProps.locationType })
        }
        /**
         * Add new location type success/error handle
         */

        if (nextProps.addLocationTypeSuccess !== null && !isEqual(this.props.addLocationTypeSuccess, nextProps.addLocationTypeSuccess)) {
            this.setState({ locationType: null, isLoading: false })
            this.props.saveSuccessCallback();
            AppUtilityService.showMessageToast("New location type added successfully", "success")
        }
        if (nextProps.addLocationTypeError && !isEqual(this.props.addLocationTypeError, nextProps.addLocationTypeError)) {
            this.setState({ isLoading: false })
            AppUtilityService.handleApiError(nextProps.addLocationTypeError);
        }


        if (nextProps.updateLocationTypeSuccess !== null && !isEqual(this.props.updateLocationTypeSuccess, nextProps.updateLocationTypeSuccess)) {
            this.setState({ locationType: null, isLoading: false })
            this.props.saveSuccessCallback();
            AppUtilityService.showMessageToast("Selected location type has been updated ", "success")
        }
        if (nextProps.updateLocationTypeError && !isEqual(this.props.updateLocationTypeError, nextProps.updateLocationTypeError)) {
            this.setState({ isLoading: false })
            AppUtilityService.handleApiError(nextProps.updateLocationTypeError);
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                visible={true}
                title={`${this.state.locationType ? "Edit" : "Add"} Location Type`}
                onOk={this.handleFormSubmit}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>Cancel</Button>,
                    <Button key="submit" type="primary" htmlType="submit" onClick={this.handleFormSubmit} loading={this.state.isLoading}>Save</Button>
                ]}
            >
                <Form>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <FormItem label="Value">
                                        {getFieldDecorator('value', {
                                            initialValue: (
                                                (this.state.locationType && this.state.locationType.value)
                                            ),
                                            rules: [{ required: true, message: 'Please input value!' }],
                                        })(
                                            <Input placeholder="Value" />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <FormItem label="Location type color">
                                        {getFieldDecorator('color', {
                                            initialValue: (
                                                (this.state.locationType && this.state.locationType.color)
                                            )
                                        })(
                                            <ChromePicker
                                                disableAlpha={true}
                                                color={this.state.locationType && this.state.locationType.color || "#22194D"}
                                                onChangeComplete={color => this.handleChangeComplete(color)}
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form >
            </Modal>
        )
    }

    handleChangeComplete = (colorPalette) => {
        if (colorPalette) {
            this.props.form.setFieldsValue({ color: colorPalette.hex })
            let locationType = { ...this.state.locationType };
            locationType.color = colorPalette.hex;
            this.props.form.setFieldsValue({
                color: colorPalette.hex,
            });
            this.setState({ locationType });
        }
    }

    handleFormSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ isLoading: true })
                let { locationType } = this.state;
                var formData = values;
                formData.type = "LocationType";
                if (locationType) {
                    this.props.actions.updateLocationType(locationType.lookupId, formData);
                } else {
                    this.props.actions.addNewLocationType(formData);

                }
            }
        })
    }

    handleCancel = () => {
        this.props.closeSaveLocationTypeModal();
    }

} export default Form.create<ISaveLocationTypesProps & FormComponentProps>()(SaveLocationTypesModal);


import * as React from "react";
import { isEqual } from "lodash"
import AppUtilityService from "../../../common/services/AppUtilityService";

import { Modal, Button } from 'antd';
import SaveContactNoteFormComponent from "./SaveContactNoteFormComponent";

export class AddContactNoteModal extends React.Component<IAddContactNoteProps, IAddContactNoteState> {

    private saveContactNoteFormRef: any;

    constructor(props: IAddContactNoteProps) {
        super(props);
        this.state = {
            contactNote: {noteID: this.props.contactNote.noteID, contactId: this.props.contactId, note: this.props.contactNote.note, deleted: this.props.contactNote.deleted},
            addNoteModalMode: this.props.addNoteModalMode
        }
    }

    componentWillReceiveProps(nextProps: IAddContactNoteProps) {
        if (nextProps.addContactNoteSuccess && !isEqual(this.props.addContactNoteSuccess, nextProps.addContactNoteSuccess)) {
            this.props.cancelSaveContactNoteCallback(nextProps.addContactNoteSuccess);
            AppUtilityService.showMessageToast("New contact note has been added", "success");
        }
        if (nextProps.addContactNoteError && !isEqual(this.props.addContactNoteError, nextProps.addContactNoteError)) {
            AppUtilityService.handleApiError(nextProps.addContactNoteError);
        }

        if (nextProps.updateContactNoteSuccess && !isEqual(this.props.updateContactNoteSuccess, nextProps.updateContactNoteSuccess)) {
            this.props.cancelSaveContactNoteCallback(nextProps.updateContactNoteSuccess);
            AppUtilityService.showMessageToast("Contact note has been updated", "success");
        }
        if (nextProps.updateContactNoteError && !isEqual(this.props.updateContactNoteError, nextProps.updateContactNoteError)) {
            AppUtilityService.handleApiError(nextProps.updateContactNoteError);
        }
    }

    render() {
        return (
            <Modal
                visible={true}
                title="Add Note"
                onOk={this.handleFormSubmit}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>Cancel</Button>,
                    <Button key="submit" type="primary" htmlType="submit" onClick={this.handleFormSubmit}>Save Note</Button>
                ]}
            >
                <SaveContactNoteFormComponent ref={(ref) => this.saveContactNoteFormRef = ref} contactNote= {this.state.contactNote} contactNoteSaveApproach={this.state.addNoteModalMode} />
            </Modal>
        )
    }


    handleFormSubmit = (e) => {
        e.preventDefault();
        this.saveContactNoteFormRef.validateFields((err, values) => {
            if (!err) {
              if (this.state.addNoteModalMode == "new")
              {
                var formData = values;
                this.handleAdd(formData)
              } else {
                var formData = values;
                this.handleUpdate(formData);
              }
            }
        })
    }

    handleAdd = (data) => {
        this.state.contactNote.note = data.Note;
        this.props.actions.addNewContactNote(this.state.contactNote);
    }

    handleUpdate = (data) => {
        this.state.contactNote.note = data.Note;
        this.props.actions.updateContactNote(this.state.contactNote);
    }

    handleCancel = () => {
        this.props.cancelSaveContactNoteCallback();
    }

} 


import * as React from "react";
import { isEqual } from "lodash";
import { FormComponentProps } from 'antd/lib/form/Form'
import ContactsAutocompleteContainer from "../../../common/containers/dropdown/ContactsAutocompleteContainer";
import AppUtilityService from "../../../common/services/AppUtilityService";
import LocationsAutocompleteContainer from "../../../common/containers/dropdown/LocationsAutocompleteContainer";

import { Form, Input, Row, Col, Avatar } from 'antd';
import ContactTypesDropdownContainer from '../../../common/containers/dropdown/ContactTypesDropdownContainer';
import webAccessService from "../../../common/services/WebAccessService";
const FormItem = Form.Item;

class SaveContactFormComponent extends React.Component<ISaveContactFormProps & FormComponentProps, ISaveContactFormState> {

    constructor(props: ISaveContactFormProps & FormComponentProps) {
        super(props);
        this.state = {
            contactDetails: this.props.contactDetails || null,
            contactSaveApproach: this.props.contactSaveApproach || "new",
            isEditContact: this.props.isEditContact || false
        }
    }

    componentWillReceiveProps(nextProps: ISaveContactFormProps) {
        if (!isEqual(this.props.contactDetails, nextProps.contactDetails)) {
            this.setState({ contactDetails: nextProps.contactDetails })
        }

        if (!isEqual(this.props.contactSaveApproach, nextProps.contactSaveApproach)) {
            this.setState({ contactSaveApproach: nextProps.contactSaveApproach })
        }

        if (!isEqual(this.props.isEditContact, nextProps.isEditContact)) {
            this.setState({ isEditContact: nextProps.isEditContact })
        }
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const isAdminUser = webAccessService.isAdminUser();
        return (
            <Form>
                {
                    this.state.contactSaveApproach === "existing" ?
                        <div>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <FormItem label="Select from existing">
                                        {getFieldDecorator('existingContactId', {
                                            rules: [{ required: true, message: 'Please select a contact to add!' }],
                                        })(
                                            <ContactsAutocompleteContainer
                                                customParams={{ locationId: this.props.locationId }}
                                                placeholder="Search contacts" />
                                        )}
                                    </FormItem>

                                </Col>
                            </Row>
                        </div>
                        :
                        <div style={{ paddingTop: this.state.isEditContact ? "20px" : "0" }}>
                            <Row gutter={16}>
                                {
                                    this.state.contactDetails && this.state.isEditContact &&
                                    <Col xl={3} lg={5}>
                                        <Avatar size={130}
                                            src={this.state.contactDetails.image ? AppUtilityService.getImageFromByteArray(this.state.contactDetails.image) : null}
                                        />
                                    </Col>
                                }

                                <Col xl={this.state.isEditContact ? 21 : 24} lg={this.state.isEditContact ? 19 : 24}>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <FormItem label="First name">
                                                {getFieldDecorator('firstName', {
                                                    initialValue: this.state.contactDetails && this.state.contactDetails.firstName,
                                                    rules: [{ required: true, message: 'Please input a first name!' }],
                                                })(
                                                    <Input placeholder="" />
                                                )}
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem label="Last name">
                                                {getFieldDecorator('lastName', {
                                                    initialValue: this.state.contactDetails && this.state.contactDetails.lastName,
                                                })(
                                                    <Input placeholder="" />
                                                )}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <FormItem label="Email">
                                                {getFieldDecorator('email', {
                                                    initialValue: this.state.contactDetails && this.state.contactDetails.email,
                                                    rules: [
                                                        { required: true, message: 'Please input an email!' },
                                                        { type: 'email', message: 'The input is not valid email!' },
                                                    ],
                                                })(
                                                    <Input type="email" placeholder="" disabled={this.state.contactDetails.user?.userId != null}/>
                                                )}
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem label="Phone">
                                                {getFieldDecorator('phone', {
                                                    initialValue: this.state.contactDetails && this.state.contactDetails.phone,
                                                })(
                                                    <Input placeholder="" />
                                                )}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <FormItem label="Job title">
                                                {getFieldDecorator('jobTitle', {
                                                    initialValue: this.state.contactDetails?.jobTitle,
                                                })(
                                                    <Input type="text" placeholder="" />
                                                )}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                        <Row gutter={16}>
                                            {!this.props.locationId && (
                                              <Col span={12}>
                                                  <FormItem label="Location">
                                                      {getFieldDecorator('locationId', {
                                                          initialValue: this.state.contactDetails && this.state.contactDetails.locationId
                                                      })(
                                                          <LocationsAutocompleteContainer placeholder="Select location" />
                                                      )}
                                                  </FormItem>
                                              </Col>
                                            )}                                           
                                        </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <FormItem label="Address line 1">
                                                {getFieldDecorator('address1', {
                                                    initialValue: this.state.contactDetails && this.state.contactDetails.address1,
                                                })(
                                                    <Input placeholder="" />
                                                )}
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem label="Address line 2">
                                                {getFieldDecorator('address2', {
                                                    initialValue: this.state.contactDetails && this.state.contactDetails.address2,
                                                })(
                                                    <Input placeholder="" />
                                                )}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <FormItem label="City">
                                                {getFieldDecorator('city', {
                                                    initialValue: this.state.contactDetails && this.state.contactDetails.city,
                                                })(
                                                    <Input placeholder="" />
                                                )}
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem label="State">
                                                {getFieldDecorator('addressState', {
                                                    initialValue: this.state.contactDetails && this.state.contactDetails.addressState,
                                                })(
                                                    <Input placeholder="" />
                                                )}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <FormItem label="Zipcode">
                                                {getFieldDecorator('zipcode', {
                                                    initialValue: this.state.contactDetails && this.state.contactDetails.zipcode,
                                                })(
                                                    <Input placeholder="" />
                                                )}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </div>
                }
            </Form>
        )
    }
} export default Form.create<ISaveContactFormProps & FormComponentProps>()(SaveContactFormComponent);

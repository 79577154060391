import { useCallback, useMemo, useState } from 'react';
import { Table } from 'antd';
import { createColumn, CreateColumnProps, DataOrSummaryRow } from '../../../common/helpers/tableWithSummaryHelpers';
import { LoadState } from '../../../common/store/fetched';
import { EmptyStateComponent } from '../../../common/components/layouts/EmptyStateComponent';
import { FailedStateComponent } from '../../../common/components/layouts/FailedStateComponent';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { ColumnProps, PaginationConfig, SorterResult } from 'antd/lib/table';
import {
  CalculationMethodMapping,
  TaxRate,
} from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';
import { getLocationInvoicingConfigs } from '../../actions/locationInvoicingConfig/getLocationInvoicingConfigs';
import { Link } from 'react-router';
import { round } from 'lodash';

interface IZoneEntry {
  key: number,
  title: string,
  children: IZoneEntry[],
}

interface Props {
  editTaxRate: (taxRate: TaxRate) => void;
}

const BillingConfigurationTaxRatesTable = ({ editTaxRate }: Props) => {
  const dispatch = useAppDispatch();
  const { loadState, data: items } = useAppSelector(state => state.LocationInvoicingConfigReducer.taxRates);
  const zones = useAppSelector(state => state.DropdownReducer.loadZonesDropdownDataSuccess) as IZoneEntry[] | undefined;
  const [sortParams, setSortParams] = useState<Pick<CreateColumnProps<never>, 'sortBy' | 'sortAsc'>>({
    sortBy: '',
    sortAsc: true,
  });

  const reloadInvoices = useCallback(() => {
    dispatch(getLocationInvoicingConfigs());
  }, [dispatch]);

  const zoneMap = useMemo(() => {
    const map = new Map<number, string>();
    const flatten = (zones: IZoneEntry[]) => zones?.forEach(z => {
      map.set(z.key, z.title);
      flatten(z.children);
    });
    flatten(zones);
    return map;
  }, [zones]);

  const tableColumns = useMemo(() => {
    return [
      createColumn(
        '',
        'edit',
        record => <Link onClick={() => editTaxRate(record)} to="">Edit</Link>
      ),
      createColumn(
        'Jurisdiction',
        'zoneId',
        record => zoneMap.get(record.zoneId),
        {
          ...sortParams,
          sorter: (a, b) => zoneMap.get(a.zoneId)?.localeCompare(zoneMap.get(b.zoneId)) ?? 0,
        },
      ),
      createColumn<TaxRate>(
        'Name',
        'name',
        record => record.name,
        {
          ...sortParams,
          sorter: (a, b) => a.name.localeCompare(b.name),
        },
      ),
      createColumn<TaxRate>(
        'Rate',
        'rate',
        record => `${round(record.value * 100, 2)}%`,
        {
          ...sortParams,
          sorter: (a, b) => a.value - b.value,
        },
      ),
      createColumn<TaxRate>(
        'Calculation On',
        'type',
        record => CalculationMethodMapping[record.calculationMethod],
        {
          ...sortParams,
          sorter: (a, b) => a.calculationMethod - b.calculationMethod,
        },
      ),
    ] as ColumnProps<DataOrSummaryRow<TaxRate>>[];
  }, [sortParams, zoneMap, editTaxRate]);

  const handleTableChange = useCallback((
      _pagination: PaginationConfig,
      _filters: Partial<Record<keyof TaxRate, string[]>>,
      sorter: SorterResult<TaxRate>,
    ) => {
      setSortParams({
        sortBy: sorter.columnKey as string,
        sortAsc: sorter.order !== 'descend',
      });
    },
    []);

  return (
    <>
      <div className="pg-billing-table">
        <Table<TaxRate>
          rowKey={record => String(record.taxRateId)}
          loading={loadState === LoadState.InProgress}
          dataSource={items}
          columns={tableColumns}
          pagination={{ pageSize: 20 }}
          locale={
            {
              emptyText: loadState !== LoadState.Failed
                ? <EmptyStateComponent title="No tax rates found" />
                : (
                  <FailedStateComponent tryAgain={reloadInvoices}>
                    Failed to load tax rates
                  </FailedStateComponent>
                ),
            }
          }
          onChange={handleTableChange}
        />
      </div>
    </>
  );
}

export default BillingConfigurationTaxRatesTable;

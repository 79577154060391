
import * as React from "react";
import { debounce, isEqual, isEmpty } from "lodash";
import moment from "moment";
import { Link } from "react-router";
import AppUtilityService from "../../../common/services/AppUtilityService";
import AppConstants from "../../../common/AppConstants";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";

import { Table, Tag } from "antd";

export class UserListTableComponent extends React.Component<IUserListTableProps, IUserListTableState> {

  constructor(props: IUserListTableProps) {
    super(props);
    this.state = {
      pagination: {
        page: 1,
        pageSize: 10,
        total: 5
      },
      sortInfo: null,
      searchText: null,
      isLoading: true,
      userList: [],
    }
    this.loadUserList = debounce(this.loadUserList, 500);
  }


  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    if (this.props.userListFilters) {
      this.handlePresetFilters(this.props.userListFilters, true)
    } else {
      this.loadUserList();
    }
  }

  componentWillReceiveProps(nextProps: IUserListTableProps) {
    /**
     * Get user details success/error handle
     */
    if (nextProps.loadUsersListSuccess && !isEqual(this.props.loadUsersListSuccess, nextProps.loadUsersListSuccess)) {
      let pagination = { ...this.state.pagination };
      var responseData: any = nextProps.loadUsersListSuccess;
      pagination.total = responseData.total;
      this.props.getTotalUsersCallback(pagination.total);
      this.setState({ userList: responseData.data, pagination, isLoading: false })
    }
    if (nextProps.loadUsersListError && !isEqual(this.props.loadUsersListError, nextProps.loadUsersListError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.loadUsersListError);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  render() {
    const { isLoading, pagination } = this.state;
    const tableColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        sortOrder: AppUtilityService.getTableColumnSortOrder(this.state.sortInfo, 'name'),
        render: (text, record) => {
          return (
            <div>
               {AppUtilityService.getContactAvatar(record.image)}
              <div style={{ display: "inline-block", verticalAlign: "middle", marginLeft: "6px" }}>
                <Link to={"/admin/users/" + record.userId}>{text}</Link>
                <div>{record.userName}</div>
              </div>
            </div>
          )
        }
      }, {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        sorter: true,
        sortOrder: AppUtilityService.getTableColumnSortOrder(this.state.sortInfo, 'role'),
        className: "hidden-sm",
      }, 
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'active',
        sorter: true,
        sortOrder: AppUtilityService.getTableColumnSortOrder(this.state.sortInfo, 'active'),
        render: (value, record) => {
          return record.active ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>
        }
      },
      {
        title: 'Created Date',
        dataIndex: 'created',
        key: 'created',
        sorter: true,
        sortOrder: AppUtilityService.getTableColumnSortOrder(this.state.sortInfo, 'created'),
        className: "hidden-sm",
        render: (text, record) => {
          return moment(text).format(AppConstants.dateFormat)
        }
      }
    ];

    return (
      <Table
        rowKey={(record) => { return (record.userId).toString() }}
        loading={isLoading}
        dataSource={this.state.userList}
        columns={tableColumns}
        pagination={{ current: this.state.pagination.page, pageSize: pagination.pageSize, total: pagination.total }}
        onChange={this.handleTableChange}
        locale={
          {
            emptyText: <EmptyStateComponent title="No Users" />
          }
        }
      />
    )
  }

  /**
   * Handling of search text change from parent component using ref to current component
   */
  handleSearchChange = (value) => {
    let pagination = { ...this.state.pagination };
    pagination.page = 1;
    this.setState({ pagination, searchText: value }, () => {
      this.loadUserList();
    })
  }

  /**
   * Triggered when table pagination, sortInfo or filter changes
   */
  handleTableChange = (updatedPagination, filters, sorter) => {
    let pagination = { ...this.state.pagination };
    let sortInfo = { ...this.state.sortInfo };
    pagination.page = updatedPagination.current
    if (sorter && !isEmpty(sorter)) {
      sortInfo = {
        sortDir: sorter.order,
        propertyName: sorter.field
      }
    } else {
      sortInfo = null;
    }
    this.setState({ pagination, sortInfo }, () => {
      this.loadUserList()
    })
  }

  /**
   * Reset table from parent component using ref to current component
   */
  resetTable = () => {
    let pagination = { ...this.state.pagination };
    pagination.page = 1;
    this.setState({ pagination, sortInfo: null, searchText: "" }, () => {
      this.loadUserList()
    })
  }

  /**
   * Load table data
   */
  loadUserList = () => {
    this.setState({ isLoading: true });
    let { pagination, sortInfo, searchText } = this.state;
    let params = {
      page: pagination.page,
      pageSize: pagination.pageSize,
      sortDir: sortInfo && sortInfo.sortDir || null,
      propertyName: sortInfo && sortInfo.propertyName || null,
      searchText: searchText
    }
    this.props.actions.loadUsersList(params);
    this.props.actions.updateUserListFilters(params);
  }

  handlePresetFilters = (filterData, isFirstMount) => {
    var { pagination } = this.state;
    pagination.page = filterData.page;
    pagination.pageSize = filterData.pageSize;
    var sortInfo = null
    if (filterData.sortDir && filterData.propertyName) {
      sortInfo = {
        sortDir: filterData.sortDir,
        propertyName: filterData.propertyName,
      }
    }
    var searchText = filterData.searchText;
    this.setState({ pagination, sortInfo, searchText }, () => {
      if (isFirstMount) {
        this.loadUserList()
      }
    })
  }
}

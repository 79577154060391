import { createApiCaller } from '../../../common/services/ApiService';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { fetchDataWithActionCreator, LoadState } from '../../../common/store/fetched';
import { IDeleteInvoiceItemContext, IInvoiceItem } from '../../reducers/billing/billing.models';
import { deleteInvoiceItemOperationUpdated } from './slice-actions';
import { processFailureReason } from '../../../common/store/failure';
import { message } from 'antd';
import { getInvoice } from './getInvoice';

export const deleteInvoiceCustomItem = (invoiceItem: IInvoiceItem) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { invoiceItemId, invoiceId, itemDate: itemName } = invoiceItem;
    const context: IDeleteInvoiceItemContext = { invoiceId, invoiceItemId };
    await fetchDataWithActionCreator(
      deleteInvoiceItemOperationUpdated,
      dispatch,
      createApiCaller(api => api.deleteData<void>(
        `/invoices/${invoiceId.toFixed(0)}/custom-items/${invoiceItemId.toFixed(0)}`
      )),
      processFailureReason,
      false,
      context,
    );

    const operation = getState().BillingReducer.deleteInvoiceItemOperation;
    if (operation.loadState === LoadState.Failed) {
      if (
        operation.failureReason.statusCode === 400
        && operation.failureReason.response?.data?.errorCode === 'InvalidStatus'
      ) {
        message.warning('The invoice is not in pending state.');
        await dispatch(getInvoice(invoiceId, true));
      } else {
        message.error(
          `There was a problem with deleting invoice custom item: ${itemName}.
           Please try again or refresh the page.`,
          5,
        );
      }
    } else if (operation.loadState === LoadState.Success) {
      message.success(`Successfully deleted invoice custom item: ${itemName}.`);
      await dispatch(getInvoice(invoiceId, true));
    }
  };
}
import { downloadNorthDakotaInvoicesReportOperationUpdated } from './slice-actions';
import { DownloadedFile, saveFile } from '../../../common/store/fileDownload';
import apiService from '../../../common/services/ApiService';
import { createStandardFetchedAction } from '../../../common/store/fetched.slices';

export const downloadNorthDakotaInvoicesReport =
  (year: number, month: number, invoicingDistributorId: number) => createStandardFetchedAction(
    downloadNorthDakotaInvoicesReportOperationUpdated,
    async () => {
      const result = await apiService.downloadFile(
        `/reports/invoicing/north-dakota-invoices/excel/${year}/${month}/${invoicingDistributorId}`);

      const fileName = result.headers['x-filename'];
      const file: DownloadedFile = {
        data: result.data,
        fileName,
      };
      saveFile(file);
    },
    false,
  );

import { Alert, Card, Popconfirm, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { createColumn, CreateColumnProps, DataOrSummaryRow } from '../../../common/helpers/tableWithSummaryHelpers';
import AppUtilityService from '../../../common/services/AppUtilityService';
import WebAccessService from '../../../common/services/WebAccessService';
import { IInvoice, IInvoiceAdjustment, InvoiceAdjustmentType, InvoiceStatus } from '../../reducers/billing/billing.models';
import { useAppDispatch } from '../../../common/hooks/storeHooks';
import { useCallback, useState } from 'react';
import { deleteInvoiceAdjustment } from '../../actions/billing/deleteInvoiceAdjustment';
import EditInvoiceAdjustmentModal from './EditInvoiceAdjustmentModal';
import Paragraph from 'antd/lib/typography/Paragraph';

const { formatAmount } = AppUtilityService;

interface InvoiceAdjustmentsProps {
  invoice: IInvoice;
}

export function InvoiceAdjustments({ invoice }: InvoiceAdjustmentsProps) {

  const canEdit = WebAccessService.hasPermissionToAccess("Invoices", "Modify")
    && invoice.invoiceStatusId === InvoiceStatus.PendingReview;
  const dispatch = useAppDispatch();

  const [editedAdjustmentId, setEditedAdjustmentId] = useState<number | 'new' | null>(null);

  const deleteAdjustment = useCallback((adjustment: IInvoiceAdjustment) => {
    dispatch(deleteInvoiceAdjustment(adjustment));
  }, [dispatch]);

  const tableColumns: ColumnProps<DataOrSummaryRow<IInvoiceAdjustment>>[] = [
    createColumn(
      'Type',
      'invoiceAdjustmentTypeId',
      x => formatAdjustmentType(x.invoiceAdjustmentTypeId),
      { summaryRender: x => 'TOTALS' }
    ),
    createColumn('Name', 'comment', x => x.comment),
    createCurrencyColumn('Amount', 'adjustmentAmount'),
    createColumn('', undefined, x => canEdit && !x.isPendingCreditApplication && (<>
      <span className="link-primary" onClick={() => setEditedAdjustmentId(x.invoiceAdjustmentId)}>
        <i className="icon-edit" />
      </span>
      <Popconfirm
        title={<>Delete <b>{formatAmount(x.adjustmentAmount)}</b> adjustment?</>}
        okText="Delete"
        okType="danger"
        cancelText="Cancel"
        onConfirm={() => deleteAdjustment(x)}
      >
        <span className="link-primary ml-8"><i className="icon-remove" /></span>
      </Popconfirm>
    </>), { align: 'right' }),
  ];

  const dataSource: DataOrSummaryRow<IInvoiceAdjustment>[] = [
    ...invoice.invoiceAdjustments,
    {
      isSummaryRow: true,
      adjustmentAmount: invoice.totalAdjustmentsAmount,
    },
  ];

  const extraActions = canEdit && (
    <span className="link-primary" onClick={() => setEditedAdjustmentId('new')}><i className="icon-add" /></span>
  );

  const editedAdjustment = editedAdjustmentId === 'new'
    ? null
    : (invoice.invoiceAdjustments.find(x => x.invoiceAdjustmentId === editedAdjustmentId)) ?? null;

  const countAdjustmentsOfType =
    (type: InvoiceAdjustmentType) => invoice.invoiceAdjustments.filter(x => x.invoiceAdjustmentTypeId === type).length;

  const duplicateAdjustmentTypes: string[] = [];
  if (countAdjustmentsOfType(InvoiceAdjustmentType.Credit) > 1) { duplicateAdjustmentTypes.push('Credit'); }
  if (countAdjustmentsOfType(InvoiceAdjustmentType.Contractual) > 1) { duplicateAdjustmentTypes.push('Contractual'); }
  if (countAdjustmentsOfType(InvoiceAdjustmentType.Promo) > 1) { duplicateAdjustmentTypes.push('Promo'); }

  return (
    <>
      <Card
        title="Adjustments"
        className="content-card no-header-border no-card-padding"
        bordered={false}
        extra={extraActions}
      >
        <Table<DataOrSummaryRow<IInvoiceAdjustment>>
          size="small"
          rowKey={x => x.invoiceAdjustmentId?.toString() ?? 'summary'}
          dataSource={dataSource}
          columns={tableColumns}
          pagination={false}
        />
        {duplicateAdjustmentTypes.map(x => (
          <Alert
            className="mt-8"
            type="warning"
            showIcon
            message={`This invoice has more than one ${x.toLowerCase()} adjustment. In generated PDF we'll group them under “${x} adjustments“.`} 
          />
        ))}
      </Card>
      {editedAdjustmentId != null &&
        <EditInvoiceAdjustmentModal
          invoiceId={invoice.invoiceId}
          adjustment={editedAdjustment}
          onClose={() => setEditedAdjustmentId(null)}
        />
      }
    </>
  );
}

function createCurrencyColumn(title: string, key: keyof IInvoiceAdjustment | undefined, otherProps?: CreateColumnProps<IInvoiceAdjustment>) {
  const render = (record: DataOrSummaryRow<IInvoiceAdjustment>) => {
    const value = record[key];
    return value == null ? '' : (
      <span className={Math.round(+value * 100) < 0 ? 'text-negative' : ''}>
        {formatAmount(value)}
      </span>
    );
  };

  return createColumn<IInvoiceAdjustment>(title, key, render, { align: 'right', summaryRender: render, ...otherProps });
}

function formatAdjustmentType(type: InvoiceAdjustmentType) {
  switch (type) {
    case InvoiceAdjustmentType.Credit: return 'Credit';
    case InvoiceAdjustmentType.Contractual: return 'Contractual';
    case InvoiceAdjustmentType.Promo: return 'Promo';
    default: return null;
  }
}
import * as React from "react";
import { isEqual, includes } from "lodash";
import moment from "moment";
import AppUtilityService from "../../../common/services/AppUtilityService";

import { Card, Button, Modal, Row, Col, Avatar, Spin, Tag } from 'antd';
import WebAccessService from "../../../common/services/WebAccessService";
import SaveLocationFormComponent from "./SaveLocationFormComponent";
import { hashHistory } from "react-router";
import { RangepickerComponent } from "../../../common/components/datepicker/RangepickerComponent";
import AppConstants from "../../../common/AppConstants";
const confirm = Modal.confirm;

export class LocationBasicDetailsComponent extends React.Component<ILocationBasicDetailsCardProps, ILocationBasicDetailsCardState> {
  private saveLocationFormRef: any;
  constructor(props: ILocationBasicDetailsCardProps) {
    super(props);
    this.state = {
      locationDetails: null,
      locationStats: null,
      isLoading: false,
      isLoadingStats: false,
      isEditMore: false,
      dateRange: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    }
  }


  componentDidMount() {
    if (this.props.locationId) {
      this.getLocationDetails();
    }

    var previousRoute = AppUtilityService.getRouteHistory().previousPath;
    if (includes(previousRoute, "dashboard") && this.props.dashboardFilters) {
      this.dateRangeFilterChange([moment(this.props.dashboardFilters.dateFrom), moment(this.props.dashboardFilters.dateTo)])
    } else {
      this.dateRangeFilterChange(this.state.dateRange)
    }
  }

  componentWillReceiveProps(nextProps: ILocationBasicDetailsCardProps) {
    /**
     * Load location basic details success/error handle
     */
    if (nextProps.loadBasicDetailsSuccess && !isEqual(this.props.loadBasicDetailsSuccess, nextProps.loadBasicDetailsSuccess)) {
      var responseData: any = nextProps.loadBasicDetailsSuccess;
      this.setState({ locationDetails: responseData, isLoading: false })
    }

    if (nextProps.loadBasicDetailsError && !isEqual(this.props.loadBasicDetailsError, nextProps.loadBasicDetailsError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.loadBasicDetailsError);
    }

    /**
     * Load location stats success/error handle
     */
    if (nextProps.locationStatsSuccess && !isEqual(this.props.locationStatsSuccess, nextProps.locationStatsSuccess)) {
      var responseData: any = nextProps.locationStatsSuccess;
      this.setState({ locationStats: responseData, isLoadingStats: false })
    }

    if (nextProps.locationStatsError && !isEqual(this.props.locationStatsError, nextProps.locationStatsError)) {
      this.setState({ isLoadingStats: false })
      AppUtilityService.handleApiError(nextProps.locationStatsError);
    }

    /**
     * Update location details success/error handle
     */
    if (nextProps.updateLocationBasicDetailsSuccess && !isEqual(this.props.updateLocationBasicDetailsSuccess, nextProps.updateLocationBasicDetailsSuccess)) {
      var responseData: any = nextProps.updateLocationBasicDetailsSuccess;
      this.setState({ locationDetails: responseData, isEditMore: false, isLoading: false });
      AppUtilityService.showMessageToast("Updated location details", "success");
    }

    if (nextProps.updateLocationBasicDetailsError && !isEqual(this.props.updateLocationBasicDetailsError, nextProps.updateLocationBasicDetailsError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.updateLocationBasicDetailsError);
    }

    /**
     * Delete location success/error handle
     */
    if (nextProps.deleteLocationSuccess !== null && !isEqual(this.props.deleteLocationSuccess, nextProps.deleteLocationSuccess)) {
      if (nextProps.deleteLocationSuccess) {
        AppUtilityService.showMessageToast("Selected location has been deleted", "success");
        hashHistory.push("/locations");
      }
    }

    if (nextProps.deleteLocationError && !isEqual(this.props.deleteLocationError, nextProps.deleteLocationError)) {
      AppUtilityService.handleApiError(nextProps.deleteLocationError);
    }

  }

  render() {
    var cardActions = <div className="location-detail-filters">
      <span>
        <RangepickerComponent
          width={"230px"}
          dateRange={this.state.dateRange}
          onChange={this.dateRangeFilterChange} />
      </span>
      <p className="t-grey-dark" style={{ verticalAlign: "super" }}>Date Range Selection</p>

      <div>
        <span>
          {
            WebAccessService.hasPermissionToAccess("Location", "Modify") &&
            <span className="ml-8"><Button className="ml-8" type="primary" onClick={this.editDetails}>Edit</Button></span>
          }</span>
      </div>

      {
        // WebAccessService.hasPermissionToAccess("Location", "Delete") &&
        // <Button className="ml-8" type="danger" onClick={this.deleteLocation}>Delete</Button>
      }
    </div>;

    return (
      <Spin spinning={this.state.isLoading || this.state.isLoadingStats}>
        <Card className="content-card no-header-border" bordered={false}>
          <div style={{ paddingTop: "16px" }}>
            {
              !this.state.isEditMore ?
                <div>
                  <Row gutter={24}>
                    <Col md={16} xs={24}>
                      <div>
                        <div style={{ display: "inline-block", verticalAlign: "top", marginTop: "10px" }}>
                          <Avatar shape="square" className="mr-8 avatar-square">
                            <i className="icon-location" />
                          </Avatar>
                        </div>
                        <div style={{ display: "inline-block" }}>
                          <h1 style={{ marginBottom: 0 }}>
                            {this.state.locationDetails && this.state.locationDetails.name}
                            {this.state.locationDetails && this.state.locationDetails.color && <Avatar size="small" className="ml-8" style={{ backgroundColor: this.state.locationDetails.color, verticalAlign: 'sub' }} />}
                          </h1>
                          <div className="font-14 regular t-black-light">
                            {
                              this.state.locationDetails && AppUtilityService.displayStringFromArray([
                                this.state.locationDetails.address1,
                                this.state.locationDetails.address2,
                                this.state.locationDetails.city,
                                this.state.locationDetails.addressState,
                                this.state.locationDetails.zipcode,
                              ])
                            }
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} md={8} className="text-right">
                      {cardActions}
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={24}>
                    {
                      this.state.locationDetails &&
                      <Col xxl={12} xl={10} lg={12} className="location-basic-details-padded">
                        <Row gutter={24}>
                          <Col span={12}>
                            <div>
                              <span className="t-black bold">Location Type: </span>
                              <span className="t-black-light">
                                {
                                  this.state.locationDetails.locationType &&
                                  <Tag color="#E8F5FF">
                                    <span className="t-black font-14">{this.state.locationDetails.locationType}</span>
                                  </Tag>
                                }
                              </span>
                            </div>
                          </Col>
                          <Col span={12}>
                            <p>
                              <span className="t-black bold">Start Date: </span>
                              <span className="t-black-light">{AppUtilityService.formatDate(this.state.locationDetails.startDate)}</span>
                            </p>
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Col span={12}>
                            <p>
                              <span className="t-black bold">Distributor: </span>
                              <span className="t-black-light">{this.state.locationDetails.distributor}</span>
                            </p>
                          </Col>
                            <Col span={12}>
                              <p>
                                <span className="t-black bold">PTD: </span>
                                <span className="t-black-light">{this.state.locationDetails.ptd}</span>
                              </p>
                            </Col>
                        </Row>
                        <Row gutter={24}>                   
                          <Col span={12}>
                            <p>
                              <span className="t-black bold">Location ID: </span>
                              <span className="t-black-light">{this.state.locationDetails.id}</span>
                            </p>
                          </Col>
                            <Col span={12}>
                              <p>
                                <span className="t-black bold">License: </span>
                                <span className="t-black-light">{this.state.locationDetails.license}{this.state.locationDetails.licenseExpiryDate ? ` (valid to ${AppUtilityService.formatDate(this.state.locationDetails.licenseExpiryDate)})` : ''}</span>
                              </p>
                            </Col>
                        </Row>
                        {/* <Row gutter={24}>
                                                     <Col span={12}>
                                                        <p>
                                                            <span className="t-black bold">Status: </span>
                                                            <span className="t-black-light">
                                                                {
                                                                    this.state.locationDetails.status &&
                                                                    <span>
                                                                        <Badge dot className="mr-8" style={{ height: '8px', width: '8px', background: this.state.locationDetails.status === "active" ? "#52C41A" : "#F5222D" }} />
                                                                        {this.state.locationDetails.status}
                                                                    </span>
                                                                }
                                                            </span>
                                                        </p>
                                                    </Col> 
                                                    <Col span={12}>
                                                        <p>
                                                            <span className="t-black bold">Email: </span>
                                                            <span className="t-black-light">{this.state.locationDetails.email}</span>
                                                        </p>
                                                    </Col>
                                                </Row>*/}
                      </Col>
                    }

                    {
                      this.state.locationStats &&
                      <Col xxl={12} xl={14} lg={12} className="location-details-stats">
                        <Row gutter={24}>
                          <Col xl={6} xs={12}>
                            <div>
                              <span className="stat-unit-left">$</span>
                              <span className="font-22 bold mr-8">{AppUtilityService.shortenLargeNumber(this.state.locationStats.played, 2)}</span>
                              <p className="t-grey-dark" style={{ verticalAlign: "super" }}>Gross Play</p>
                            </div>
                          </Col>
                          <Col xl={6} xs={12}>
                            <div>
                              <span className="stat-unit-left">$</span>
                              <span className="font-22 bold mr-8">{AppUtilityService.shortenLargeNumber(this.state.locationStats.won, 2)}</span>
                              <p className="t-grey-dark" style={{ verticalAlign: "super" }}>Won</p>
                            </div>
                          </Col>
                          <Col xl={6} xs={12}>
                            <div>
                              <span className="stat-unit-left">{this.state.locationStats.pwNet < 0 ? "-$" : "$"}</span>
                              <span className="font-22 bold mr-8">{AppUtilityService.shortenLargeNumber(Math.abs(this.state.locationStats.pwNet), 2)}</span>
                              <p className="t-grey-dark" style={{ verticalAlign: "super" }}>Net Play</p>
                            </div>
                          </Col>
                          <Col xl={6} xs={12}>
                            <div>
                              <span className="font-22 bold">{AppUtilityService.shortenLargeNumber(this.state.locationStats.pwRatio, 2)}</span>
                              <span className="stat-unit-right mr-8">%</span>
                              <p className="t-grey-dark" style={{ verticalAlign: "super" }}>Payout</p>
                            </div>
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Col xl={6} xs={12}>
                            <div>
                              <span className="stat-unit-left">$</span>
                              <span className="font-22 bold mr-8">{AppUtilityService.shortenLargeNumber(this.state.locationStats.cashIn, 2)}</span>
                              <p className="t-grey-dark" style={{ verticalAlign: "super" }}>Cash In</p>
                            </div>
                          </Col>
                          <Col xl={6} xs={12}>
                            <div>
                              <span className="stat-unit-left">$</span>
                              <span className="font-22 bold mr-8">{AppUtilityService.shortenLargeNumber(this.state.locationStats.cashOut, 2)}</span>
                              <p className="t-grey-dark" style={{ verticalAlign: "super" }}>Cash Out</p>
                            </div>
                          </Col>
                          <Col xl={6} xs={12}>
                            <div>
                              <span className="stat-unit-left">{this.state.locationStats.net < 0 ? "-$" : "$"}</span>
                              <span className="font-22 bold mr-8">{AppUtilityService.shortenLargeNumber(Math.abs(this.state.locationStats.net), 2)}</span>
                              <p className="t-grey-dark" style={{ verticalAlign: "super" }}>Cash Net</p>
                            </div>
                          </Col>
                          <Col xl={6} xs={12}>
                            <div>
                              <span className="font-22 bold">{AppUtilityService.shortenLargeNumber(this.state.locationStats.holdPercentage, 2)}</span>
                              <span className="stat-unit-right mr-8">%</span>
                              <p className="t-grey-dark" style={{ verticalAlign: "super" }}>Hold Percentage</p>
                            </div>
                          </Col>
                          {/* OWEB-243
                                                    <Col xl={6} xs={12}>
                                                        <div>
                                                            <span className="stat-unit-left">$</span>
                                                            <span className="font-22 bold mr-8">{AppUtilityService.shortenLargeNumber(Math.abs(this.state.locationStats.adnw), 2)}</span>
                                                            <p className="t-grey-dark" style={{ verticalAlign: "super" }}>ADNW</p>
                                                        </div>
                                                    </Col>
                                                    */}
                        </Row>
                      </Col>
                    }
                  </Row>
                </div>
                :
                <Row>
                  <Col lg={16} xs={24}>
                    <SaveLocationFormComponent ref={(ref) => this.saveLocationFormRef = ref} locationDetails={this.state.locationDetails} />
                    <Row>
                      <Col span={21}>
                        <Button className="mr-8" type="primary" htmlType="submit" onClick={this.handleUpdateLocationSubmit}>Save</Button>
                        <Button onClick={this.cancelEditDetails}> Cancel </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
            }
          </div>
        </Card>
      </Spin >
    )
  }
  editDetails = () => {
    this.setState({ isEditMore: true })
  }
  cancelEditDetails = () => {
    this.setState({ isEditMore: false })
  }
  handleUpdateLocationSubmit = (e) => {
    e.preventDefault();
    this.saveLocationFormRef.validateFields((err, values) => {
      if (!err) {
        this.setState({ isLoading: true })
        var formData = values;
        formData.addressId = this.state.locationDetails.addressId || null;
        formData.licenseExpiryDate = formData.licenseExpiryDate ? formData.licenseExpiryDate.format(AppConstants.apiDateFormat) : null;
        this.props.actions.updateLocationBasicDetails(this.state.locationDetails.id, formData)
      }
    })
  }

  dateRangeFilterChange = (date) => {
    this.setState({ dateRange: date }, () => {
      this.getLocationStats()
      this.props.actions.updateLocationDateFilters(date)
    });
  }

  getLocationDetails = () => {
    var locationId = this.props.locationId;
    this.setState({ isLoading: true })
    this.props.actions.loadLocationBasicDetails(locationId);
  }

  getLocationStats = () => {
    var locationId = this.props.locationId;
    this.setState({ isLoadingStats: true })
    var params = {
      dateFrom: AppUtilityService.formatAPIDateFilter(this.state.dateRange[0]) || null,
      dateTo: AppUtilityService.formatAPIDateFilter(this.state.dateRange[1]) || null,
      type: "ByDate"
    }
    this.props.actions.loadLocationStats(locationId, params);
  }

  deleteLocation = () => {
    var { locationDetails } = this.state;
    confirm({
      title: 'Confirm dialog',
      content: 'Are you sure you want to delete the selected location?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        this.props.actions.deleteLocationDetails(locationDetails.id)
      },
    });
  }
}

import React from 'react';
import { FilterProps } from '../reports/locationStatisticsReports/LocationReportsFilterComponent';
import { Radio } from 'antd';

const InvoicingEnabledFilter = ({ filterValue, onFilterValueChange }: FilterProps) => (
  <span>
    <span className="font-14 regular">Invoicing Enabled </span>
    <Radio.Group value={filterValue ?? ''} size="small" onChange={e => onFilterValueChange(e.target.value)}>
      <Radio.Button value={''}>All</Radio.Button>
      <Radio.Button value={true}>Enabled</Radio.Button>
      <Radio.Button value={false}>Disabled</Radio.Button>
    </Radio.Group>
  </span>
);

export default InvoicingEnabledFilter;

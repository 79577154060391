import { ColumnProps, SortOrder } from 'antd/lib/table';

export type SummaryRow<T> = (Partial<T> & { isSummaryRow: true; });
export type DataOrSummaryRow<T> = T | SummaryRow<T>;

export function isSummaryRow<T>(row: DataOrSummaryRow<T>): row is SummaryRow<T> {
  return (row as any).isSummaryRow === true;
}

export type CreateColumnProps<T> = Partial<ColumnProps<DataOrSummaryRow<T>>> & {
  withSummary?: boolean;
  summaryRender?: (summary: SummaryRow<T>) => React.ReactNode;
  sortBy?: string;
  sortAsc?: boolean;
};

export function createColumn<T extends Record<string, any>>(
  title: string,
  key?: string | Extract<keyof T, string>,
  dataRender?: (record: T) => React.ReactNode,
  props?: CreateColumnProps<T>,
): ColumnProps<DataOrSummaryRow<T>> {
  const { withSummary, summaryRender, sortBy, sortAsc, ...otherProps } = props ?? {};
  const sortProps = sortBy != null && sortAsc != null
    ? {
      sortOrder: sortBy === key ? (sortAsc ? 'ascend' : 'descend') as SortOrder : undefined,
      sorter: true
    }
    : undefined;
  return {
    title,
    dataIndex: key,
    key,
    ...sortProps,
    render: (text, record) => {
      if (isSummaryRow(record)) {
        if (!withSummary && summaryRender == null) return null;
        return {
          children: summaryRender != null
            ? summaryRender(record)
            : text,
          props: {
            style: { fontWeight: 'bold' } // setting "class" doesn't work here as it's lost when sorting is applied
          }
        };
      } else {
        return dataRender != null ? dataRender(record) : text;
      }
    },
    ...otherProps,
  };
}

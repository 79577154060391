import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as LocationsActions from '../../actions/locations/LocationsActions';
import { LocationBasicDetailsComponent } from '../../components/locations/LocationBasicDetailsComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    dashboardFilters: state.DashboardReducer.dashboardFilters,

    loadBasicDetailsSuccess: state.LocationsReducer.loadBasicDetailsSuccess,
    loadBasicDetailsError: state.LocationsReducer.loadBasicDetailsError,

    locationStatsSuccess: state.LocationsReducer.loadStatsSuccess,
    locationStatsError: state.LocationsReducer.loadStatsError,

    updateLocationBasicDetailsSuccess: state.LocationsReducer.updateLocationBasicDetailsSuccess,
    updateLocationBasicDetailsError: state.LocationsReducer.updateLocationBasicDetailsError,

    deleteLocationSuccess: state.LocationsReducer.deleteLocationSuccess,
    deleteLocationError: state.LocationsReducer.deleteLocationError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(LocationsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationBasicDetailsComponent)

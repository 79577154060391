import AppUtilityService from '../../../common/services/AppUtilityService';
import { InvoicingContact } from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';

export type Props = {
  value?: InvoicingContact;
};

export const PrimaryContactDetailsComponent = ({ value }: Props) => {
  const contact = value?.contact;
  if (!contact) return null;
  return (
    <ul className="mini-card-details" style={{ paddingLeft: 40, marginTop: '10px' }}>
      {contact.email && <li>{contact.email}</li>}
      {contact.phone && <li>{contact.phone}</li>}
      {(contact.address1 || contact.address2 || contact.city || contact.zipcode || contact.addressState) && (
        <li>{AppUtilityService.displayStringFromArray([contact.address1, contact.address2, contact.city, contact.addressState, contact.zipcode])}</li>
      )}
    </ul>
  );
};

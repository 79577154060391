import { useEffect, useState } from 'react';
import { Button, Card } from 'antd';
import AppUtilityService from '../../../common/services/AppUtilityService';
import { updateBreadcrumbs } from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import BillingConfigurationTaxRatesTable from './BillingConfigurationTaxRatesTable';
import { getTaxRates } from '../../actions/locationInvoicingConfig/getTaxRates';
import TaxRateModal from './TaxRateModal';
import { TaxRate } from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';
import ZonesDropdownContainer from '../../../common/containers/dropdown/ZonesDropdownContainer';
import { LoadState } from '../../../common/store/fetched';
import appUtilityService from '../../../common/services/AppUtilityService';
import { dismissSaveTaxRateOperation } from '../../actions/locationInvoicingConfig/slice-actions';

const EMPTY_TAX_RATE: TaxRate = {
  taxRateId: null,
  calculationMethod: null,
  name: null,
  value: null,
  zoneId: null,
};

const BillingConfigurationTaxRates = () => {
  const dispatch = useAppDispatch();
  const taxRates = useAppSelector(state => state.LocationInvoicingConfigReducer.taxRates);
  const saveTaxRateOperation = useAppSelector(state => state.LocationInvoicingConfigReducer.saveTaxRateOperation);
  const [selectedZoneId, setSelectedZoneId] = useState<number>(null);
  const [modifiedTaxRate, setModifiedTaxRate] = useState<TaxRate>();

  useEffect(() => {
    dispatch(getTaxRates({ zoneId: selectedZoneId }));
  }, [dispatch, selectedZoneId]);

  useEffect(() => {
    const breadcrumbsList = [
      { name: 'Billing', link: '/billing' },
      { name: 'Configuration', link: '/billing/configuration' },
      { name: 'Taxes and Fees', link: null },
    ];
    const breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
    dispatch(updateBreadcrumbs(breadcrumbs));
  }, [dispatch, taxRates]);

  useEffect(
    () => {
      switch (saveTaxRateOperation.loadState) {
        case LoadState.Failed:
          appUtilityService.handleApiError(saveTaxRateOperation.failureReason);
          break;
        case LoadState.Success:
          appUtilityService.showMessageToast('Tax rate saved', 'success');
          setModifiedTaxRate(undefined);
          dispatch(dismissSaveTaxRateOperation());
          dispatch(getTaxRates({ zoneId: selectedZoneId }));
          break;
        default:
          return;
      }
      dispatch(dismissSaveTaxRateOperation());
    },
    [dispatch, saveTaxRateOperation, selectedZoneId],
  );

  return (
    <>
      <Card
        style={{ paddingTop: '12px' }}
        className="reports-filter-card content-card no-header-border"
        bordered={false}
      >
        <div className="reports-filter">
          <span className="mr-8">
            <ZonesDropdownContainer
              placeholder="Select zone"
              value={selectedZoneId}
              onChange={setSelectedZoneId}
            />
          </span>
          <span className="mr-8">
            <Button
              type="primary"
              onClick={() => setModifiedTaxRate({ ...EMPTY_TAX_RATE })}
            >
              + Add Tax Rate
            </Button>
          </span>
        </div>
      </Card>
      <BillingConfigurationTaxRatesTable
        editTaxRate={setModifiedTaxRate}
      />
      <TaxRateModal
        taxRate={modifiedTaxRate}
        onClose={() => setModifiedTaxRate(undefined)}
      />
    </>
  );
}

export default BillingConfigurationTaxRates;

import jsPDF from 'jspdf';
import Highcharts from 'highcharts';
import canvg from 'canvg';
import { IColumnProps } from 'devextreme-react/data-grid';

export type Column = {
  dataField: string;
  caption: string;
  format?: string | ((value: any) => string);
  dataType: string;
};

export const margin = 30;

export function createPdfReport() {
  const doc = new jsPDF('l', 'pt');  //l- landscape orientation of first page, pt-points measurement unit to be used when coordinates are specified
  doc.setFontSize(16);
  doc.setTextColor("#656C8A");
  doc.setFontStyle('normal');
  return doc;
}

export function addChart(doc, header: string, chartId: string, horizontalPosition: number, verticalPosition: number, pageWidth: number) {
  doc.text(header, margin, margin);
  doc.text("Graphical representation of selected time period", margin, margin + 16);
  const chartDOM = document.getElementById(chartId);
  const chart = Highcharts.charts[Highcharts.attr(chartDOM, 'data-highcharts-chart')];
  const chartSVG = chart.getSVG();
  const chartCanvas = document.createElement('canvas');
  canvg(chartCanvas, chartSVG);
  const chartImgData = chartCanvas.toDataURL('image/png');
  doc.addImage(chartImgData, 'PNG', horizontalPosition, verticalPosition);
  //Chart legend autotable
  doc.autoTable({
    // html: '#chartTabularLegend' + index,
    margin: { horizontal: horizontalPosition + 70 },
    startY: (verticalPosition + chartCanvas.height) - 80,
    theme: "plain",
    tableWidth: 300,
    bodyStyles: { valign: 'top' },
    didParseCell: function (cell, opts) {
      cell.cell.styles.textColor = "#A6ADC9";
    },
  });
}

export function addAutoTablePage<TData>(doc, header: string, columns: IColumnProps[], data: TData[]) {
  doc.addPage();
  doc.text(header, margin, margin);
  const visibleColumns = columns.filter(c => c.visible !== false);
  const tableColumns = visibleColumns.map(obj => obj.caption);

  const totals = Array.from({ length: visibleColumns.length }, () => null);
  totals[0] = 'Total';

  const body = data.map(item => visibleColumns.map((c, index) => {
    if (c.dataType === 'number') {
      totals[index] = (totals[index] || 0) + item[c.dataField];
    }
    return formatAutoTableCell(item[c.dataField], c);
  }));

  const averagesFormated: Array<string | number> = visibleColumns.map((c, index) => {
    if (c.dataType === 'number') {
      return formatAutoTableCell(data.length > 0 ? totals[index] / data.length : 0, c);
    }
    return totals[index];
  });
  averagesFormated[0] = 'Average';

  const totalsFormated = totals.map((total, index) => {
    if (visibleColumns[index].dataType === 'number') {
      return formatAutoTableCell(total, visibleColumns[index]);
    }
    return total;
  });

  doc.autoTable({
    columns: tableColumns,
    body,
    foot: [averagesFormated, totalsFormated],
    theme: 'striped',
    margin: { left: margin, right: margin, bottom: margin },
    styles: { overflow: 'linebreak' },
    bodyStyles: { valign: 'top' },
    footStyles: { textColor: '#6E6E6E', fillColor: '#E8E8E8' },

    didDrawPage: function (data) {
      if (data.pageNumber !== 0) {
        data.settings.margin = margin;
      }
    },
  });
}

function formatAutoTableCell(value: any, column: IColumnProps) {
  try {
    if (value == null) {
      return '';
    }
    if (typeof column.format === 'function') {
      return column.format(value);
    }
    if (typeof column.format === 'string' && column.dataType === 'number') {
      const format = column.format;
      const options: Intl.NumberFormatOptions = {};

      if (format.includes('$')) {
        options.style = 'currency';
        options.currency = 'USD';
      }
      if (format.includes('.00')) {
        options.minimumFractionDigits = 2;
        options.maximumFractionDigits = 2;
      }
      if (format === '#,##0') {
        options.minimumFractionDigits = 0;
        options.maximumFractionDigits = 0;
      }

      return new Intl.NumberFormat('en-US', options).format(value);
    }
    return value;
  } catch (e) {
    return value;
  }
}

import * as React from "react";
import { isEqual, startCase, isEmpty, get, filter, cloneDeep } from "lodash";
import { DonutChartComponent } from "../../../common/components/charts/DonutChartComponent";
import AppUtilityService from "../../../common/services/AppUtilityService";
import AppConstants from "../../../common/AppConstants";
import { LineChartComponent } from "../../../common/components/charts/LineChartComponent";

import { Card, Spin, Radio, Tabs, Button } from 'antd';
import appUtilityService from "../../../common/services/AppUtilityService";
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

var jsPDF = require('jspdf');
var Highcharts = require('highcharts');
var exporting = require('highcharts/modules/exporting.src');
exporting(Highcharts);
var canvg = require('canvg');

export class SummaryChartCardComponent extends React.Component<ISummaryByGameCardProps, ISummaryByGameCardState> {

  private radioFilter;

  constructor(props: ISummaryByGameCardProps) {
    super(props);

    this.state = {
      summaryByGame: null,
      summaryByDate: null,
      isLoading: false,
      dateRange: this.props.locationDetailsDateFilter || [],
      summaryType: this.props.cardType === "ByGame" ? AppConstants.locationSummaryByGameMetrics[0].value : AppConstants.locationSummaryByDateMetrics[0].value,
      cardType: this.props.cardType || "ByGame",
      locationDetails: this.props.loadBasicDetailsSuccess || null
    }
  }


  componentDidMount() {
    this.radioFilter = this.state.cardType === "ByGame" ? AppConstants.locationSummaryByGameMetrics : AppConstants.locationSummaryByDateMetrics;
    this.loadSummaryData()
  }

  componentWillReceiveProps(nextProps: ISummaryByGameCardProps) {
    if (nextProps.cardType && !isEqual(this.props.cardType, nextProps.cardType)) {
      this.radioFilter = nextProps.cardType === "ByGame" ? AppConstants.locationSummaryByGameMetrics : AppConstants.locationSummaryByDateMetrics;
      this.setState({ cardType: nextProps.cardType })
    }

    if (nextProps.locationDetailsDateFilter && !isEqual(this.props.locationDetailsDateFilter, nextProps.locationDetailsDateFilter)) {
      this.setState({ dateRange: nextProps.locationDetailsDateFilter }, () => {
        this.loadSummaryData()
      })
    }

    if (nextProps.loadBasicDetailsSuccess && !isEqual(this.props.loadBasicDetailsSuccess, nextProps.loadBasicDetailsSuccess)) {
      var responseData: any = nextProps.loadBasicDetailsSuccess;
      this.setState({ locationDetails: responseData })
    }

    if (nextProps.loadLocationGameSummarySuccess && !isEqual(this.props.loadLocationGameSummarySuccess, nextProps.loadLocationGameSummarySuccess)) {
      var responseData: any = nextProps.loadLocationGameSummarySuccess;
      var data: Array<DonutChartData> = responseData.gameData.map(function (item) { return { name: item.name, y: item.count } });
      var title: any = {
        html: '<div class="font-12" style="color: #4572A7">Total ' + startCase(this.state.summaryType) + '</div>',
        css: {
          color: '#4572A7',
          fontSize: 12,
        }
      }

      var subTitle: any = {
        html: '<div class="font-18 t-grey-dark">' + (this.state.summaryType === 'played' ? '$' : '') + AppUtilityService.shortenLargeNumber(responseData.total, 0) + '</div>',
        css: {
          color: '#A6ADC9',
          fontSize: 18,
        }
      }

      var summaryByGame = {
        title: title,
        subTitle: subTitle,
        data: data,
        units: this.state.summaryType === "played" ? "currency" : null,
      }
      this.setState({ summaryByGame, isLoading: false })
    }

    if (nextProps.loadLocationGameSummaryError && !isEqual(this.props.loadLocationGameSummaryError, nextProps.loadLocationGameSummaryError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.loadLocationGameSummaryError);
    }

    if (nextProps.loadLocationDateSummarySuccess && !isEqual(this.props.loadLocationDateSummarySuccess, nextProps.loadLocationDateSummarySuccess)) {
      var responseData: any = nextProps.loadLocationDateSummarySuccess;
      var xAxis: Array<string> = responseData.gameData.map(function (item) { return item.name });
      var yAxis: Array<number> = responseData.gameData.map(function (item) { return item.count });

      var title: any = '<div class="font-12">Total ' + startCase(this.state.summaryType) +
        '<div class="bold">' + (this.state.summaryType !== 'holdPercentage' ? `<span class="t-grey-dark">${responseData.total < 0 ? "-$" : "$"}</span>` : "") +
        AppUtilityService.shortenLargeNumber(Math.abs(responseData.total), 2) + (this.state.summaryType === 'holdPercentage' ? '<span class="t-grey-dark">%</span>' : '') +
        '</div></div>';

      var data: Array<DonutChartData> = responseData.gameData.map(function (item) { return { name: item.name, y: item.count } });
      var summaryByDate = {
        title: title,
        data: {
          xAxisTitle: "Date",
          xAxis: xAxis,
          yAxisTitle: startCase(this.state.summaryType),
          yAxis: yAxis,
          data: data,
          yAxisUnits: this.state.summaryType !== "holdPercentage" ? "currency" : "percent",
        }
      }
      this.setState({ summaryByDate, isLoading: false })
    }

    if (nextProps.loadLocationDateSummaryError && !isEqual(this.props.loadLocationDateSummaryError, nextProps.loadLocationDateSummaryError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.loadLocationDateSummaryError);
    }
  }

  render() {

    const extraActions = <div className="location-detail-filters">
      <span>
        <Button type="primary" onClick={this.downloadPDF}>Download as PDF</Button>
      </span>
    </div>

    return (
      <Card className="content-card" bordered={false}
        title={startCase(this.state.cardType)}
        extra={extraActions}>
        <Spin spinning={this.state.isLoading}>
          {
            this.state.cardType === "ByGame" ?
              <DonutChartComponent id={this.state.cardType} height={300} responsiveHeight={300} chartData={this.state.summaryByGame} showLegend={true} isVerticalLegend={true} />
              :
              <LineChartComponent id={this.state.cardType} height={350} responsiveHeight={350} chartData={this.state.summaryByDate} />
          }
          <br />
          <RadioGroup onChange={this.typeFilterChange} value={this.state.summaryType}>
            {
              this.radioFilter && !isEmpty(this.radioFilter) &&
              this.radioFilter.map((obj) => {
                return (
                  <RadioButton key={obj.value} value={obj.value}>{obj.key}</RadioButton>
                )
              })
            }
          </RadioGroup>
        </Spin>
      </Card>

    )
  }

  onTabChange = (key) => {
    this.setState({ cardType: key }, () => {
      this.loadSummaryData()
    });
  }

  typeFilterChange = (e) => {
    this.setState({ summaryType: e.target.value }, () => {
      this.loadSummaryData()
    })
  }

  loadSummaryData = () => {
    this.setState({ isLoading: true })
    var params = {
      locationId: this.props.locationId,
      dataType: this.state.cardType,
      type: this.state.summaryType,
      dateFrom: this.state.dateRange && AppUtilityService.formatAPIDateFilter(this.state.dateRange[0]) || null,
      dateTo: this.state.dateRange && AppUtilityService.formatAPIDateFilter(this.state.dateRange[1]) || null,
    }
    if (this.state.cardType === "ByGame") {
      this.props.actions.loadLocationGameSummary(params)
    } else {
      this.props.actions.loadLocationDateSummary(params)
    }
  }

  downloadPDF = (dataset) => {
    var margin = 30;
    var doc = new jsPDF('l', 'pt');
    doc.setFontSize(16);
    doc.setTextColor("#656C8A");
    doc.setFontStyle('normal');

    doc.text(startCase(this.state.summaryType) + " - " + startCase(this.state.cardType), margin, margin)
    doc.setFontSize(12);
    doc.setTextColor("#000");
    doc.text("Location: " + this.state.locationDetails.name, margin, 50)
    doc.text("From: " + AppUtilityService.formatDate(this.state.dateRange[0]), margin, 70)
    doc.text("To: " + AppUtilityService.formatDate(this.state.dateRange[1]), 200, 70)

    var verticalPosition = 70;
    var horizontalPosition = 30;
    let chartDOM = document.getElementById(this.state.cardType);
    let chart = Highcharts.charts[Highcharts.attr(chartDOM, 'data-highcharts-chart')];

    var chartSVG = chart.getSVG({
      chart: {
         height: 700,
         width: 700,
      },
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'horizontal',
        itemMarginTop: 5,
        itemMarginBottom: 5,
        itemStyle: {
          width: 200,
          font: 'Helvetica Neue'
        },
      },
    });
    var chartCanvas = document.createElement('canvas');
    canvg(chartCanvas, chartSVG);
    var chartImgData = chartCanvas.toDataURL('image/svg');
    doc.addImage(chartImgData, 'SVG', horizontalPosition, verticalPosition);
    doc.addPage();

    let chartData: any = [];
      let tableColumnData: any = [];
    if (this.state.summaryType === "playCountByDenomination") {
        chartData = cloneDeep(get(this.state, 'summaryByGame.data')) || [];
        tableColumnData = [
            { title: "By Denomination", dataKey: "name" }
        ];
    }
    else if (this.state.cardType === "ByGame") {
      chartData = cloneDeep(get(this.state, 'summaryByGame.data')) || [];
      tableColumnData = [
        { title: "Game", dataKey: "name" }
      ];
    } else {
      chartData = cloneDeep(get(this.state, 'summaryByDate.data.data')) || [];
      tableColumnData = [
        { title: "Date", dataKey: "name" }
      ];
    }
    chartData = filter(chartData, (obj) => {
      switch (this.state.summaryType) {
        case "playCount":
          obj.y = appUtilityService.shortenLargeNumber(obj.y, 0);
          break;
        case "holdPercentage":
          obj.y = (appUtilityService.shortenLargeNumber(obj.y, 2) + "%");
              break;
        case "playCountByDenomination":
            obj.y = appUtilityService.shortenLargeNumber(obj.y, 2);
                break;
        default:
          obj.y = appUtilityService.formatAmount(obj.y);
          break;
      }
      return obj.y;
    })

    var summaryTypeHeader = filter(this.radioFilter, (obj) => { return this.state.summaryType === obj.value })[0].key
    tableColumnData.push({ title: startCase(summaryTypeHeader), dataKey: "y" })
    doc.autoTable(tableColumnData, chartData, {
      theme: "striped",
      margin: { horizontal: margin },
      styles: { overflow: 'linebreak' },
      bodyStyles: { valign: 'top' },
      tableWidth: 400,
    });
    //Getting SVG of the chart
      var filename = this.state.locationDetails.name + ' - ' + startCase(this.state.cardType) + ' - ' + startCase(this.state.summaryType) + '.pdf'
    doc.save(filename);
  }

}

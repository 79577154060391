import { downloadPdfTicketSalesByGameReportUpdated } from './slice-actions';
import apiService from '../../../common/services/ApiService';
import { Moment } from 'moment';
import AppConstants from '../../../common/AppConstants';
import { addAutoTablePage, addChart, createPdfReport } from '../../../common/services/PdfReportUtils';
import { childColumns, columns } from '../../components/ticketSalesByGameReport/TicketSalesByGameReportColumns';
import { fetchDataWithActionCreator } from '../../../common/store/fetched';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { processFailureReason } from '../../../common/store/failure';
import { TicketSalesByGameReportChildItem } from '../../reducers/reporting/reporting.models';

export const downloadPdfTicketSalesByGameReport = (
  chartIds: string[],
  dateFrom: Moment,
  dateTo: Moment,
  jurisdictionId?: number,
  siteId?: number,
  organizationId?: number,
) =>
  async (dispatch: AppDispatch, getState: () => RootState) =>
    await fetchDataWithActionCreator(
      downloadPdfTicketSalesByGameReportUpdated,
      dispatch,
      async () => {
        const doc = createPdfReport();

        const pageWidth = doc.internal.pageSize.getWidth();
        const horizontalPosition = 30;
        const verticalPosition = 50;

        chartIds.forEach((chartId, index) => {
          addChart(doc, 'Ticket Sales By Game', chartId, horizontalPosition, verticalPosition, pageWidth);
          if (index < chartIds.length - 1) {
            doc.addPage();
          }
        });

        const state = getState();
        addAutoTablePage(
          doc,
          'Detailed view of selected time period',
          columns,
          state.ReportingReducer.ticketSalesByGameReportUpdated.data);

        const childDataResult = await apiService.getData<TicketSalesByGameReportChildItem[]>(`/reports/arrow/ticket-sales-by-game-report/all/${dateFrom.format(AppConstants.apiDateFormat)}/${dateTo.format(AppConstants.apiDateFormat)}`,
          { jurisdictionId, siteId, organizationId });
        const childDataGroupedByGameName = childDataResult.data.reduce((acc, item) => {
          if (!acc[item.gameName]) {
            acc[item.gameName] = [];
          }
          acc[item.gameName].push(item);
          return acc;
        }, {});
        childView(doc, childDataGroupedByGameName);

        const fileName = `TicketSalesByGame-${dateFrom.format(AppConstants.apiDateFormat)}-${dateTo.format(AppConstants.apiDateFormat)}.pdf`;
        doc.save(fileName);
      },
      processFailureReason,
      false,
    );

function childView(doc, childDataGroupedByGameName: Record<string, TicketSalesByGameReportChildItem[]>) {
  Object.entries(childDataGroupedByGameName)
    .sort(([gameNameA], [gameNameB]) => gameNameA.localeCompare(gameNameB))
    .forEach(([gameName, childData]) => {
      childData.sort((a, b) => b.gamePrice);
      addAutoTablePage(doc,`Game: ${gameName}`, childColumns, childData);
    });
}

import * as React from "react";
import { useEffect, useCallback } from 'react';
import AppUtilityService from "../../../common/services/AppUtilityService";
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { getInvoice } from '../../actions/billing/getInvoice';
import { updateBreadcrumbs } from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import { RouteComponentProps } from 'react-router';
import { LoadState } from '../../../common/store/fetched';
import { Card, Layout, Spin } from 'antd';
import { InvoiceDetails } from './InvoiceDetails';
import { FailedStateComponent } from '../../../common/components/layouts/FailedStateComponent';
import parseIntOrNull from "../../../common/helpers/parseIntOrNull";

type BillingDetailsProps = RouteComponentProps<{ invoiceId: string }, {}>;

export function InvoiceDetailsPage(props: BillingDetailsProps) {

  const invoiceId = parseIntOrNull(props.params.invoiceId);
  const invoice = useAppSelector(state => state.BillingReducer.invoice);
  const invoiceNo = invoice.data?.invoiceNo ?? null;

  const isUpdatingValidationIssues = useAppSelector(
    state => state.BillingReducer.updateValidationIssueOperation.loadState === LoadState.InProgress
  );
  const isUpdatingStatus = useAppSelector(
    state => state.BillingReducer.updateStatusOperation.loadState === LoadState.InProgress
  );
  const isDeletingAdjustment = useAppSelector(
    state => state.BillingReducer.deleteAdjustmentOperation.loadState === LoadState.InProgress
  );
  const isRegenerating = useAppSelector(
    state => state.BillingReducer.regenerateInvoiceOperation.loadState === LoadState.InProgress
  );
  const isDeletingCustomItem = useAppSelector(
    state => state.BillingReducer.deleteInvoiceItemOperation.loadState === LoadState.InProgress
  );

  const isUpdatingEmailTemplate= useAppSelector(
    state => state.BillingReducer.updateInvoiceEmailTemplateOperation.loadState === LoadState.InProgress
  );

  const isBusy = isUpdatingValidationIssues || isUpdatingStatus || isDeletingAdjustment || isRegenerating || isDeletingCustomItem || isUpdatingEmailTemplate;

  const dispatch = useAppDispatch();
  const loadInvoice = useCallback(() => {
    if (invoiceId != null) {
      dispatch(getInvoice(invoiceId, false));
    }
  }, [dispatch, invoiceId]);

  // load invoice when invoiceId changes
  useEffect(loadInvoice, [dispatch, loadInvoice]);

  // build breadcrumb when invoiceNo is loaded/changed
  useEffect(() => {
    const breadcrumbsList = [
      { name: "Billing", link: "/billing" },
      { name: invoiceNo ?? '-', link: null }
    ];
    const breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
    dispatch(updateBreadcrumbs(breadcrumbs));
  }, [invoiceNo, dispatch]);

  // invoice not found or lack of permissions
  const statusCode = invoice.failureReason?.statusCode;
  if (invoiceId == null || statusCode === 404 || statusCode === 403) {
    props.router.push('/billing');
    return null;
  }

  const renderContent = () => {
    if (invoice.loadState === LoadState.Failed) {
      return (
        <FailedStateComponent tryAgain={loadInvoice}>Failed to load invoice</FailedStateComponent>
      )
    }

    if (invoice.data != null) {
      return <InvoiceDetails invoice={invoice.data} />;
    }

    return null;
  };

  return (
    <div className="pg-container no-padding-page-container">
      <Spin tip="Loading invoice" spinning={invoice.loadState === LoadState.InProgress}>
        <Spin tip="Saving" spinning={isBusy}>
          <Card className="no-header-border" bordered={false} style={{ minHeight: '100px' }}>
            <Layout>
              {renderContent()}
            </Layout>
          </Card>
        </Spin>
      </Spin>
    </div>
  )
}

import { createApiCaller } from '../../../common/services/ApiService';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { fetchDataWithActionCreator, LoadState } from '../../../common/store/fetched';
import { IInvoiceRegenerationRequest, InvoiceUpdateStatusResult, IUpdateBulkInvoiceResult } from '../../reducers/billing/billing.models';
import { regenerateInvoiceOperationUpdated } from './slice-actions';
import { processFailureReason } from '../../../common/store/failure';
import { message } from 'antd';
import { hashHistory } from 'react-router';
import { getInvoice } from './getInvoice';
import { errorNotification } from '../../../common/helpers/errorNotification';

export const regenerateInvoice = (invoiceId: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {

    const request: IInvoiceRegenerationRequest = { invoiceIds: [invoiceId] };

    await fetchDataWithActionCreator(
      regenerateInvoiceOperationUpdated,
      dispatch,
      createApiCaller(api => api.postData<IUpdateBulkInvoiceResult[]>(
        `/invoices/regenerate`,
        request
      )),
      processFailureReason,
      false,
    );

    const operation = getState().BillingReducer.regenerateInvoiceOperation;

    if (operation.loadState === LoadState.Failed) {
      errorNotification(
        'Invoice regeneration failed',
        'An unexpected error occurred while regenerating the invoice. Please try again.');
      dispatch(getInvoice(invoiceId, false));
    } else if (operation.loadState === LoadState.Success) {
      const result = operation.data[0];
      switch (result.result) {
        case InvoiceUpdateStatusResult.Success:
          message.success('Invoice was regenerated successfully.');
          dispatch(getInvoice(invoiceId, false));
          break;
        case InvoiceUpdateStatusResult.Forbidden:
          message.error(`You don't have permissions to regenerate this invoice.`, 5);
          break;
        case InvoiceUpdateStatusResult.NotFound:
          message.error(`Invoice to regenerate was not found. Possibly it has already been regenerated.`, 5);
          hashHistory.push('/billing');
          break;
        case InvoiceUpdateStatusResult.InvalidStatus:
          message.error(`Invoice can't be regenerated because its status has changed.`, 5);
          dispatch(getInvoice(invoiceId, false));
          break;
      }
    }
  }
}

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ZoneActions from '../../actions/zones/ZoneActions';
import ZoneLinkedUsersComponent from '../../components/zones/ZoneLinkedUsersComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    assignUserToZoneSuccess: state.ZoneReducer.assignUserToZoneSuccess,
    assignUserToZoneError: state.ZoneReducer.assignUserToZoneError,

    unassignUserFromZoneSuccess: state.ZoneReducer.unassignUserFromZoneSuccess,
    unassignUserFromZoneError: state.ZoneReducer.unassignUserFromZoneError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ZoneActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ZoneLinkedUsersComponent)

import { dismissDownloadInvoiceAttachmentOperation } from './slice-actions';
import { saveFile } from '../../../common/store/fileDownload';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { message } from 'antd';
import { downloadInvoiceAttachment } from './downloadInvoiceAttachment';
import { InvoiceAttachmentType } from '../../reducers/billing/billing.models';

export const downloadInvoiceAttachmentAndSave = (invoiceId: number, type: InvoiceAttachmentType, invoiceAttachmentId?: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const file = await dispatch(downloadInvoiceAttachment(invoiceId, type, invoiceAttachmentId));
    if (!file) {
      message.error('Failed to download invoice file.');
    } else {
      saveFile(file);
    }
    dispatch(dismissDownloadInvoiceAttachmentOperation());
  };
};

import * as React from "react";
import { isEqual } from "lodash";
import { Input, Icon } from "antd";


export class SearchComponent extends React.Component<ISearchComponentProps, ISearchComponentState> {
    constructor(props: ISearchComponentProps) {
        super(props);
        this.state = {
            searchTerm: null,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (!isEqual(this.props.searchTerm, nextProps.searchTerm)) {
            this.setState({ searchTerm: nextProps.searchTerm })
        }
    }

    render() {
        return (
            <Input type="text"
                suffix={<Icon type="search" theme="outlined" />}
                placeholder={this.props.placeholder}
                style={{ width: this.props.width || 200 }}
                value={this.state.searchTerm}
                onChange={this.handleSearch}
                className={this.props.className}
            />
        )
    }

    handleSearch = (e) => {
        this.setState({ searchTerm: e.target.value })
        this.props.handleSearchCallback(e.target.value)
    }

}
import * as React from "react";
import { isEqual, isEmpty, filter } from "lodash";
import { Card, Icon, Spin, Avatar, Radio } from 'antd';
import AppConstants from "../../../common/AppConstants";
import AppUtilityService from "../../../common/services/AppUtilityService";
import { ColumnDrilldownChartComponent } from "../../../common/components/charts/ColumnDrilldownChartComponent";
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

export class GamePlayCashInCardComponent extends React.Component<IGamePlayCashInCardProps, IGamePlayCashInCardState>{
    constructor(props: IGamePlayCashInCardProps) {
        super(props);
        this.state = {
            isLoading: this.props.isLoading,
            chartStats: null,
            chartData: null,
            typeFilter: AppConstants.gamePlayCashInMetrics && AppConstants.gamePlayCashInMetrics[0].value,
        }
    }

    componentWillReceiveProps(nextProps: IGamePlayCashInCardProps) {
        if (nextProps.loadDashboardChartStatsSuccess && !isEqual(this.props.loadDashboardChartStatsSuccess, nextProps.loadDashboardChartStatsSuccess)) {
            this.setState({ chartStats: nextProps.loadDashboardChartStatsSuccess }, () => {
                this.createChartData(nextProps.loadDashboardChartStatsSuccess);
            })
        }

        if (nextProps.loadDashboardChartStatsError && !isEqual(this.props.loadDashboardChartStatsError, nextProps.loadDashboardChartStatsError)) {
            this.setState({ isLoading: false })
            AppUtilityService.handleApiError(nextProps.loadDashboardChartStatsError);
        }

        if (!isEqual(this.props.isLoading, nextProps.isLoading)) {
            this.setState({ isLoading: nextProps.isLoading })
        }
    }

    render() {
        var title = <span>
            <Avatar size="small" className="mr-8 avatar-mini-icon" style={{ backgroundColor: '#8DC5F3' }}>
                <i className="icon-graph" />
            </Avatar> Game play & Cash In </span>;
        var extraActions = <RadioGroup onChange={this.changeTypeFilter} value={this.state.typeFilter}>
            {
                AppConstants.gamePlayCashInMetrics && !isEmpty(AppConstants.gamePlayCashInMetrics) &&
                AppConstants.gamePlayCashInMetrics.map((obj) => {
                    return (
                        <RadioButton key={obj.value} value={obj.value}>{obj.key}</RadioButton>
                    )
                })
            }
        </RadioGroup>;

        return (
            <Card
              className="no-header-border card-wrap-header"
              bordered={false}
              title={title}
              extra={extraActions}
            >
                <Spin spinning={this.state.isLoading}>
                    <br />
                    <ColumnDrilldownChartComponent id="game-play-cash-in" height={400} chartData={this.state.chartData} type="location" />
                </Spin>
            </Card>
        )
    }

    createChartData = (chartStatsAll) => {
        let maxBars = AppConstants.gamePlayCashInMaxData;
        if (chartStatsAll.length > 0 && chartStatsAll.length < maxBars) {
            maxBars = chartStatsAll.length;
        }
        var chartStats = chartStatsAll.slice(0, maxBars);
        let chartData = {
            data: chartStats.map((obj) => { return { name: obj.category, y: obj.value, id: obj.id, drilldown: null } }),
            drilldownData: null,
            name: "",
            yAxisTitle: filter(AppConstants.gamePlayCashInMetrics, (obj) => { return obj.value === this.state.typeFilter })[0].key,
            yAxisUnits: "currency"
        }
        this.setState({ chartData, isLoading: false })
    }

    changeTypeFilter = (e) => {
        this.setState({ typeFilter: e.target.value }, () => {
            this.loadChartData();
        })
    }

    loadChartData = () => {
        this.setState({ isLoading: true });
        var { typeFilter } = this.state;
        var params = {
            metricType: typeFilter,
        }
        this.props.loadDashboardChartDataCallback(params)
    }

}

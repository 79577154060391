import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ReportsActions from '../../../actions/reports/ReportsActions';
import {
  GameTerminalStatusComponent,
} from '../../../components/reports/locationStatisticsReports/GameTerminalStatusComponent';
import { RootState } from '../../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadGameTerminalStatusChartSuccess: state.ReportsReducer.loadGameTerminalStatusChartSuccess,
    loadGameTerminalStatusChartError: state.ReportsReducer.loadGameTerminalStatusChartError,

    loadGameTerminalStatusListSuccess: state.ReportsReducer.loadGameTerminalStatusListSuccess,
    loadGameTerminalStatusListError: state.ReportsReducer.loadGameTerminalStatusListError,

    exportDataToExcelSuccess: state.ReportsReducer.exportDataToExcelSuccess,
    exportDataToExcelError: state.ReportsReducer.exportDataToExcelError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ReportsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GameTerminalStatusComponent)

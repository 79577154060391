export type InvoicesState = Partial<{
    getInvoiceDataSuccess: any;
    invoice: any;
    getInvoiceDataError: any;
}>;

const initialState: InvoicesState = {};

const InvoicesReducer = (state = initialState, action): InvoicesState => {
    switch (action.type) {
        case "INVOICE_DATA_UPDATE":
            return { ...state, invoice: action.payload };
        case "GET_INVOICE_REQUEST":
            return { ...state, getInvoiceDataSuccess: null, getInvoiceDataError: null };
        case "GET_INVOICE_SUCCESS":
            return { ...state, getInvoiceDataSuccess: action.payload, getInvoiceDataError: null };
        case "GET_INVOICE_FAILURE":
            return { ...state, getInvoiceDataSuccess: null, getInvoiceDataError: action.payload };
        default:
            return state;
    }
}
export default InvoicesReducer;

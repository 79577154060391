import * as React from "react";
import {Row, Col, Button} from 'antd';
const styles = require('../../../common/styles/ViewGameStyles.css');

export const ViewGameFeaturedImages = (props) => {
  const SERVER_PATH = (process.env.REACT_APP_API_URL || "").replace(/\/api\/?$/, '');

  return (
    <div className="page-container ">
      <Row type="flex" justify="center" align="middle">
      <Button style={{marginLeft: 30}} type="primary" size="large" href={`${SERVER_PATH}/flare/${props.gameID}/${props.gameType}/${props.firstFormType}`} target="_blank">VIEW FLARE</Button>
      </Row>
    </div>
  );
}
export default ViewGameFeaturedImages;
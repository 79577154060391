import * as React from "react";
import {Row, Col} from 'antd';
const styles = require('../../../common/styles/ViewGameStyles.css');

export const ViewGameFeaturedImages = (props) => {

  return (
    <div className="page-container ">
      <Row type="flex" justify="center" align="middle">
          <Col>
            <img style={{ cursor: 'pointer' }} width="722" height="521" src={props.leftImage} alt="No left image"  />
          </Col>
          <Col>
          <img style={{ cursor: 'pointer' }} width="722" height="521" src={props.rightImage} alt="No right image"  />
          </Col>
      </Row>
    </div>
  );
}
export default ViewGameFeaturedImages;
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as BreadcrumbActions from '../../../../common/actions/breadcrumbs/BreadcrumbActions';
import { ReconSummaryIPRReportPage } from '../../../components/reports/locationStatisticsReports/ReconSummaryIPRReportPage';
import { RootState } from '../../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadReconSummaryListSuccess: state.ReportsReducer.loadReconSummaryListSuccess,

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(BreadcrumbActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReconSummaryIPRReportPage)
import React, { useEffect, useState } from 'react';
import AppUtilityService from '../../../common/services/AppUtilityService';
import { updateBreadcrumbs } from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { Button, Card, Dropdown, Icon, Menu, Row, Select, Spin } from 'antd';
import moment, { Moment } from 'moment/moment';
import { downloadWeeklyNumbers } from '../../actions/invoicingReports/downloadWeeklyNumbers';
import { LoadState } from '../../../common/store/fetched';
import { saveFile } from '../../../common/store/fileDownload';
import { invoicingSystems } from '../../../common/models/InvoicingSystems';
import { DataGridComponent } from '../../../common/components/table/DataGridComponent';
import appConstants from '../../../common/AppConstants';
import { getWeeklyNumbersReport } from '../../actions/invoicingReports/getWeeklyNumbersReport';
import { dimissWeeklyNumbersReport } from '../../actions/invoicingReports/slice-actions';
import WeeklyDateRangeFilter, { Period } from '../../../common/components/filters/WeeklyDateRangeFilter';

const Option = Select.Option;

const WeeklyNumbersPage = () => {
  const dispatch = useAppDispatch();
  const reportsData = useAppSelector(state => state.InvoicingReportsReducer.weeklyNumbersReport.data);
  const [selectedDate, setSelectedDate] = useState<Period | null>(null);
  const [systemId, setSystemId] = useState<number>(1);
  const isDownloadOperationInProgress = useAppSelector(
    state => state.InvoicingReportsReducer.downloadOhioWeeklyNumbersOperation.loadState) === LoadState.InProgress;
  const isFetchingDataInProgress = useAppSelector(
    state => state.InvoicingReportsReducer.weeklyNumbersReport.loadState) === LoadState.InProgress;

  useEffect(
    () => {
      const breadcrumbsList = [
        { name: "Reports", link: "/Reports" },
        { name: 'Weekly Numbers', link: null },
      ];
      const breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
      dispatch(dimissWeeklyNumbersReport());
      dispatch(updateBreadcrumbs(breadcrumbs));
    },
    [dispatch]);

  const downloadExcel = async () => {
    const file = await dispatch(downloadWeeklyNumbers(selectedDate.start, selectedDate.end, systemId));
    saveFile(file);
  };

  const fetchData = async () => {
    await dispatch(getWeeklyNumbersReport(selectedDate.start, selectedDate.end, systemId));
  };

  const onChange = (key) => {
    setSystemId(key);
  }

  const extraActions = <div>
    <Dropdown.Button type="primary" onClick={downloadExcel}
      overlay={
        <Menu onClick={() => { }}>
          <Menu.Item key="1">Excel</Menu.Item>
        </Menu>
      }
      disabled={selectedDate === null || (isFetchingDataInProgress || isDownloadOperationInProgress)}>
      <Icon type="download" style={{ fontSize: "16px" }} /> Download as Excel
    </Dropdown.Button></div>;

  const filteringPanel = <div>
    <WeeklyDateRangeFilter value={selectedDate} onChange={setSelectedDate} />
    <Select
      loading={isFetchingDataInProgress}
      defaultValue="New Hampshire"
      style={{ width: 200, marginRight: 10 }}
      onChange={onChange}
    >
      {invoicingSystems.map(p => { return <Option key={p.id} >{p.name}</Option> })}
    </Select>
    <Button
      disabled={selectedDate === null}
      loading={isFetchingDataInProgress || isDownloadOperationInProgress}
      onClick={fetchData}
      type="primary"
      style={{ marginRight: 10, lineHeight: "normal", marginTop: "5px" }}
    >
      Refresh
    </Button>
  </div>;

  return (
    <div className="pg-container page-container">
      <Card
        className="no-header-border"
        bordered={false}
        title="Weekly Numbers"
        extra={extraActions}
      >
        <Row>
          <Spin spinning={isFetchingDataInProgress}>
            <DataGridComponent
              toolbarContent={filteringPanel}
              dataSource={reportsData || []}
              dataSrcColumns={appConstants.weeklyNumbersReportColumns}
              summaryAvgColumns={null}
              summarySumColumns={null}
              isLoading={isFetchingDataInProgress}
              storeIdentifier="weeklyNumbersReport"
            />
          </Spin>
        </Row>
      </Card>
    </div>
  );
}

export default WeeklyNumbersPage;

import * as React from "react";
import { Moment } from "moment";
import { Button } from 'antd';

import ZonesDropdownContainer from "../../../../common/containers/dropdown/ZonesDropdownContainer";
import LocationsAutocompleteWithDemoOnlyToggleContainer from "../../../../common/containers/dropdown/LocationsAutocompleteWithDemoOnlyToggleContainer";
import CharitiesAutocompleteContainer from "../../../../common/containers/dropdown/CharitiesAutocompleteContainer";
import DistributorsAutocompleteContainer
    from "../../../../common/containers/dropdown/DistributorsAutocompleteContainer";
import SessionService from "../../../../common/services/SessionService";
import { LocationReportFilters } from '../Reports';
import { ComponentType } from "react";

export type FilterValue = string | number | boolean | Moment[] | null;

export type LocationReportsFilter = {
    name: string;
    component: ComponentType<FilterProps>;
    defaultValue?: FilterValue;
};

export type FilterProps = {
    filterValue: FilterValue;
    onFilterValueChange: (value: FilterValue) => void;
};

export interface ILocationReportsFilterProps {
    filterValues?: LocationReportFilters;
    disableCharityFilter?: boolean;
    disableDistributorFilter?: boolean;
    filters?: LocationReportsFilter[];
    getSelectedZoneNameCallback?(name: string): void;
    getSelectedLocationNameCallback?(name: string): void;
    getSelectedCharityNameCallback?(name: string): void;
    getSelectedDistributorNameCallback?(name: string): void;
    applyFiltersCallback?(params: Object): void;
    actions?: {
        updateFilterValues(params: Object): void;
    }
}

export interface ILocationReportsFilterState {
    zoneId: number;
    selectedZoneName: string;
    locationId: number;
    selectedLocationName: string;
    charityId: number;
    selectedCharityName: string;
    distributorId: number;
    selectedDistributorName: string;
    autoselectFirstZone: boolean;
    filterValues: Record<string, FilterValue>;
}

export class LocationReconReportsFilterComponent extends React.Component<ILocationReportsFilterProps, ILocationReportsFilterState> {
    private locationsDropdownRef: any;

    constructor(props: ILocationReportsFilterProps) {
        super(props);
        this.state = {
            zoneId: null,
            selectedZoneName: null,
            locationId: null,
            selectedLocationName: null,
            charityId: null,
            selectedCharityName: null,
            distributorId: null,
            selectedDistributorName: null,
            autoselectFirstZone: false,
            filterValues: this.getDefaultFilterValues(),
        }
    }

    componentDidMount() {
        if (this.props.filterValues) {
            this.handlePresetFilters(this.props.filterValues)
        } else {
            if (SessionService.getRole() !== "Admin") {
                this.setState({ autoselectFirstZone: true })
            } else {
                this.applyFilters()
            }
        }
    }

    getDefaultFilterValues = () =>
      this.props.filters
        ?.filter(f => f.defaultValue !== undefined)
        ?.reduce((r, c) => ({ ...r, [c.name]: c.defaultValue }), {})
      ?? {};

    render() {
        return (
            <div className="reports-filter">
                <span className="mr-8">
                    <ZonesDropdownContainer
                        placeholder="Select zone"
                        autoselectFirstZone={this.state.autoselectFirstZone}
                        value={this.state.zoneId}
                        onChange={this.handleZoneChange}
                        getSelectedLabel={this.handleSelectedZone}
                    />
                </span>
                <span className="mr-8">
                    <LocationsAutocompleteWithDemoOnlyToggleContainer
                        placeholder="Select location"
                        onRef={ref => (this.locationsDropdownRef = ref)}
                        value={this.state.locationId}
                        customParams={{ zoneId: this.state.zoneId }}
                        onChange={this.handleLocationChange}
                        getSelectedLabel={this.handleSelectedLocation}
                        preselectedSearchTerm={this.state.selectedLocationName}
                    />
                </span>
                {this.props.disableCharityFilter || (
                  <span className="mr-8">
                      <CharitiesAutocompleteContainer
                          placeholder="Select charity"
                          customParams={{
                              zoneId: this.state.zoneId,
                              locationId: this.state.locationId,
                              loadIdRelated: true,
                              prodDemoTestCsvString: 'P,DC'
                          }}
                          value={this.state.charityId}
                          onChange={this.handleCharityChange}
                          getSelectedLabel={this.handleSelectedCharity}
                          preselectedSearchTerm={this.state.selectedCharityName}
                      />
                  </span>
                )}
                {this.props.disableDistributorFilter || (
                    <span className="mr-8">
                        <DistributorsAutocompleteContainer
                            placeholder="Select distributor"
                            customParams={{
                                zoneId: this.state.zoneId,
                                locationId: this.state.locationId,
                                charityId: this.state.charityId,
                                prodDemoTestCsvString: 'P,DC'
                            }}
                            value={this.state.distributorId}
                            onChange={this.handleDistributorChange}
                            getSelectedLabel={this.handleSelectedDistributor}
                            preselectedSearchTerm={this.state.selectedDistributorName}
                        />
                    </span>
                )}
                {
                    this.props.filters?.map(({ name: filterName, component: FilterComponent }) => (
                        <span key={filterName} className="mr-8">
                            <FilterComponent
                              filterValue={this.state.filterValues[filterName]}
                              onFilterValueChange={v => this.handleFilterChange(filterName, v)}
                            />
                        </span>
                    ))
                }
                <span>
                    <Button type="default" onClick={this.resetFilters}>Reset</Button>
                </span>
            </div>
        )
    }


    /**
     * Load location basic details success/error handle
     */
    handleZoneChange = (e) => {
        this.setState({ zoneId: e, locationId: null, charityId: null, distributorId: null }, () => {
            this.applyFilters()
        })
        if (this.props.getSelectedLocationNameCallback) {
            this.props.getSelectedLocationNameCallback(null)
        }
        if (this.props.getSelectedCharityNameCallback) {
            this.props.getSelectedCharityNameCallback(null)
        }
        if (this.props.getSelectedDistributorNameCallback) {
            this.props.getSelectedDistributorNameCallback(null)
        }
    }
    handleSelectedZone = (e) => {
        this.setState({ selectedZoneName: e })
        if (this.props.getSelectedZoneNameCallback) {
            this.props.getSelectedZoneNameCallback(e)
        }
    }
    //-----------------------------------------------------------------------
    handleLocationChange = (e) => {
        this.setState({ locationId: e, charityId: null, distributorId: null }, () => {
            this.applyFilters()
        })
        if (this.props.getSelectedCharityNameCallback) {
            this.props.getSelectedCharityNameCallback(null)
        }
        if (this.props.getSelectedDistributorNameCallback) {
            this.props.getSelectedDistributorNameCallback(null)
        }
    }
    handleSelectedLocation = (e) => {
        this.setState({ selectedLocationName: e })
        if (this.props.getSelectedLocationNameCallback) {
            this.props.getSelectedLocationNameCallback(e)
        }
    }
    //-----------------------------------------------------------------------
    handleCharityChange = (e) => {
        this.setState({ charityId: e, distributorId: null }, () => {
            this.applyFilters()
        })
        if (this.props.getSelectedDistributorNameCallback) {
            this.props.getSelectedDistributorNameCallback(null)
        }
    }
    handleSelectedCharity = (e) => {
        this.setState({ selectedCharityName: e })
        if (this.props.getSelectedCharityNameCallback) {
            this.props.getSelectedCharityNameCallback(e)
        }
    }
    //-----------------------------------------------------------------------
    handleDistributorChange = (e) => {
        this.setState({ distributorId: e }, () => {
            this.applyFilters()
        })
    }
    handleSelectedDistributor = (e) => {
        this.setState({ selectedDistributorName: e })
        if (this.props.getSelectedDistributorNameCallback) {
            this.props.getSelectedDistributorNameCallback(e)
        }
    }
    //-----------------------------------------------------------------------

    handleFilterChange = (filterName: string, newValue: FilterValue) => {
        this.setState(
          { filterValues: { ...this.state.filterValues, [filterName]: newValue } },
          this.applyFilters);
    }

    applyFilters = () => {
        const {
            zoneId,
            locationId,
            charityId,
            distributorId,
            selectedLocationName,
            selectedCharityName,
            selectedDistributorName,
            filterValues,
        } = this.state;
        var params: any = {
            zoneId: zoneId || 0,
            locationId: locationId || 0,
            charityId: charityId || 0,
            distributorId: distributorId || 0,
            filterValues,
        };
        this.props.applyFiltersCallback(params)
        params.selectedLocationName = selectedLocationName;
        params.selectedCharityName = selectedCharityName;
        params.selectedDistributorName = selectedDistributorName;
        this.props.actions.updateFilterValues(params)
    }

    resetFilters = () => {
        this.setState({
            zoneId: null,
            locationId: null,
            charityId: null,
            distributorId: null,
            filterValues: this.getDefaultFilterValues(),
        }, () => {
            this.locationsDropdownRef.resetDatasource();
            this.handleSelectedZone(null);
            this.handleSelectedLocation(null);
            this.handleSelectedCharity(null);
            this.handleSelectedDistributor(null);
            this.applyFilters()
        })
    }

    handlePresetFilters = (filterData) => {
        this.setState({
            zoneId: filterData.zoneId,
            locationId: filterData.locationId,
            charityId: filterData.charityId,
            distributorId: filterData.distributorId,
            selectedLocationName: filterData.selectedLocationName,
            selectedCharityName: filterData.selectedCharityName,
            selectedDistributorName: filterData.selectedDistributorName,
            filterValues: filterData.filterValues,
        }, () => {
            this.handleSelectedZone(filterData.selectedZoneName)
            this.handleSelectedLocation(filterData.selectedLocationName)
            this.handleSelectedCharity(filterData.selectedCharityName)
            this.handleSelectedDistributor(filterData.selectedDistributorName)
            Object.entries(filterData.filterValues).forEach(([filterName, value]) => {
                this.handleFilterChange(filterName, value as FilterValue);
            });
            this.applyFilters();
        })
    }
}

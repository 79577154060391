import { createApiCaller } from '../../../common/services/ApiService';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { fetchDataWithActionCreator, LoadState } from '../../../common/store/fetched';
import { IDeleteInvoiceAdjustmentContext, IInvoiceAdjustment } from '../../reducers/billing/billing.models';
import { deleteAdjustmentOperationUpdated } from './slice-actions';
import { processFailureReason } from '../../../common/store/failure';
import { message } from 'antd';
import { getInvoice } from './getInvoice';
import AppUtilityService from '../../../common/services/AppUtilityService';

const { formatAmount } = AppUtilityService;

export const deleteInvoiceAdjustment = (adjustment: IInvoiceAdjustment) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { invoiceAdjustmentId, invoiceId, adjustmentAmount, comment } = adjustment;
    const context: IDeleteInvoiceAdjustmentContext = { invoiceId, invoiceAdjustmentId };
    await fetchDataWithActionCreator(
      deleteAdjustmentOperationUpdated,
      dispatch,
      createApiCaller(api => api.deleteData<void>(
        `/invoices/${invoiceId.toFixed(0)}/adjustments/${invoiceAdjustmentId.toFixed(0)}`
      )),
      processFailureReason,
      false,
      context,
    );

    const adjustmentName = `${formatAmount(adjustmentAmount)}${comment ? ` (${comment})` : ''}`;

    const operation = getState().BillingReducer.deleteAdjustmentOperation;
    if (operation.loadState === LoadState.Failed) {
      if (
        operation.failureReason.statusCode === 400
        && operation.failureReason.response?.data?.errorCode === 'InvalidStatus'
      ) {
        message.warning('The invoice is already approved and cannot be modified.');
        await dispatch(getInvoice(invoiceId, true));
      } else {
        message.error(
          `There was a problem with deleting invoice adjustment for ${adjustmentName}.
           Please try again or refresh the page.`,
          5,
        );
      }
    } else if (operation.loadState === LoadState.Success) {
      message.success(`Successfully deleted invoice adjustment for ${adjustmentName}.`);
      await dispatch(getInvoice(invoiceId, true));
    }
  };
}

import * as React from "react";
import { isEqual, isEmpty, get } from "lodash";
import { Table, Card, Form } from "antd";

export class LocationUserStampsComponent extends React.Component<ILocationListUserStampsProps, ILocationListUserStampsState> {

  constructor(props: ILocationListUserStampsProps) {
    super(props);
    this.state = {
      userRemainingStamps: []
    }
  }

  componentDidMount() {
      this.loadUserStampData();
  }

  componentWillReceiveProps(nextProps: ILocationListUserStampsProps) {
    if (nextProps.userStampSuccess && !isEqual(this.props.userStampSuccess, nextProps.userStampSuccess)) {
      var responseData: any = nextProps.userStampSuccess;
      this.setState({ userRemainingStamps: responseData })
    }
  }

  render() {

    const { userRemainingStamps } = this.state;

    const tableColumns = [
      {
        title: 'Distributor Name',
        dataIndex: 'distributorName',
        key: 'distributorName',
        sorter: false,
        width: 200
      }, {
        title: 'Stamp Count',
        dataIndex: 'remainingStamps',
        key: 'remainingStamps',
        sorter: false,
        width: 50
      }
    ];

  return (
    <div>
      <span>
        { this.state.userRemainingStamps.length > 0 &&
          <>
          <Card className="remaining-stamps-header" title="Remaining Stamps" bordered={false}>
          <Table className="remaining-stamps" bordered={false} size="small" pagination={false} showHeader={false} style={{maxWidth: 300}}
            rowKey={(record, index) => { return (record.distributorId).toString(); } }
            dataSource={this.state.userRemainingStamps}
            columns={tableColumns} />
          </Card>
          </>
        }
      </span>
    </div>
    )
  }
  

  loadUserStampData = () => {
    this.props.actions.loadDistributorUserStampData();
  }
}


import * as React from "react";
import { debounce, isEqual, isEmpty } from "lodash";
import AppUtilityService from "../../../common/services/AppUtilityService";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";

import { Table, Avatar, Modal } from "antd";
import SaveLocationTypesModalContainer from "../../containers/locationTypes/SaveLocationTypesModalContainer";
const confirm = Modal.confirm;

export class LocationTypeListTableComponent extends React.Component<ILocationTypeListTableProps, ILocationTypeListTableState> {

  constructor(props: ILocationTypeListTableProps) {
    super(props);
    this.state = {
      pagination: {
        page: 1,
        pageSize: 10,
        total: 5
      },
      sortInfo: null,
      searchText: null,
      isLoading: true,
      locationTypesList: [],
      selectedType: null,
      showSaveTypesModal: false
    }
    this.loadLocationTypesList = debounce(this.loadLocationTypesList, 500);
  }


  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    if (this.props.locationTypesListFilters) {
      this.handlePresetFilters(this.props.locationTypesListFilters, true)
    } else {
      this.loadLocationTypesList();
    }
  }

  componentWillReceiveProps(nextProps: ILocationTypeListTableProps) {
    /**
     * Get location types success/error handle
     */
    if (nextProps.loadLocationTypesListSuccess && !isEqual(this.props.loadLocationTypesListSuccess, nextProps.loadLocationTypesListSuccess)) {
      let pagination = { ...this.state.pagination };
      var responseData: any = nextProps.loadLocationTypesListSuccess;
      pagination.total = responseData.total;
      this.props.getTotalTypesCallback(pagination.total);
      this.setState({ locationTypesList: responseData.lookups, pagination, isLoading: false })
    }
    if (nextProps.loadLocationTypesListError && !isEqual(this.props.loadLocationTypesListError, nextProps.loadLocationTypesListError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.loadLocationTypesListError);
    }


    if (nextProps.deleteLocationTypeSuccess !== null && nextProps.deleteLocationTypeSuccess !== undefined && !isEqual(this.props.deleteLocationTypeSuccess, nextProps.deleteLocationTypeSuccess)) {
      AppUtilityService.showMessageToast("Selected location type has been deleted ", "success")
      this.loadLocationTypesList();
    }
    if (nextProps.deleteLocationTypeError && !isEqual(this.props.deleteLocationTypeError, nextProps.deleteLocationTypeError)) {
      AppUtilityService.handleApiError(nextProps.deleteLocationTypeError);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  render() {
    const { isLoading, pagination } = this.state;

    const tableColumns = [
      {
        title: 'Type',
        dataIndex: 'value',
        key: 'value',
        sorter: true,
        sortOrder: AppUtilityService.getTableColumnSortOrder(this.state.sortInfo, 'value'),
        render: (text, record) => {
          return (
            <div>
              {text}
              {record && record.color && <Avatar size="small" className="ml-8" style={{ backgroundColor: record.color, verticalAlign: 'sub' }} />}
            </div>
          )
        }
      }, {
        title: '',
        dataIndex: 'options',
        key: 'options',
        className: "text-right",
        render: (text, record) => {
          return (
            <div><span className="link-primary" onClick={() => { this.openSaveTypesModal(record) }}>Edit</span> | <span className="link-danger" onClick={() => this.deleteSelectedTypes(record)}>Delete</span></div>
          )
        }
      }
    ];

    return (
      <div>
        <Table
          rowKey={(record, index) => { return (record.lookupId).toString() }}
          loading={isLoading}
          dataSource={this.state.locationTypesList}
          columns={tableColumns}
          pagination={{ current: this.state.pagination.page, pageSize: pagination.pageSize, total: pagination.total }}
          onChange={this.handleTableChange}
          locale={
            {
              emptyText: <EmptyStateComponent title="No Location Types" />
            }
          }
        />
        {
          this.state.showSaveTypesModal && this.state.selectedType &&
          <SaveLocationTypesModalContainer locationType={this.state.selectedType} saveSuccessCallback={this.addLocationTypeSaveSuccessHandler}
            closeSaveLocationTypeModal={this.handleCloseSaveModal} />
        }
      </div>
    )
  }

  /**
   * Handling of search text change from parent component using ref to current component
   */
  handleSearchChange = (value) => {
    let pagination = { ...this.state.pagination };
    pagination.page = 1;
    this.setState({ pagination, searchText: value }, () => {
      this.loadLocationTypesList();
    })
  }

  /**
   * Triggered when table pagination, sortInfo or filter changes
   */
  handleTableChange = (updatedPagination, filters, sorter) => {
    let pagination = { ...this.state.pagination };
    let sortInfo = { ...this.state.sortInfo };
    pagination.page = updatedPagination.current
    if (sorter && !isEmpty(sorter)) {
      sortInfo = {
        sortDir: sorter.order,
        propertyName: sorter.field
      }
    } else {
      sortInfo = null;
    }
    this.setState({ pagination, sortInfo }, () => {
      this.loadLocationTypesList()
    })
  }

  /**
   * Reset table from parent component using ref to current component
   */
  resetTable = () => {
    let pagination = { ...this.state.pagination };
    pagination.page = 1;
    this.setState({ pagination, sortInfo: null, searchText: "" }, () => {
      this.loadLocationTypesList()
    })
  }

  /**
   * Load table data
   */
  loadLocationTypesList = () => {
    this.setState({ isLoading: true });
    let { pagination, sortInfo, searchText } = this.state;
    let params = {
      page: pagination.page,
      pageSize: pagination.pageSize,
      sortDir: (sortInfo && sortInfo.sortDir) || null,
      propertyName: (sortInfo && sortInfo.propertyName) || null,
      searchText: searchText
    }
    this.props.actions.loadLocationTypes(params);
    this.props.actions.updateLocationTypeListFilters(params);
  }

  /**
   * Send selected location type keys on checkbox selection to parent component using callback
   */
  handlePresetFilters = (filterData, isFirstMount) => {
    var { pagination } = this.state;
    pagination.page = filterData.page;
    pagination.pageSize = filterData.pageSize;
    var sortInfo = null
    if (filterData.sortDir && filterData.propertyName) {
      sortInfo = {
        sortDir: filterData.sortDir,
        propertyName: filterData.propertyName,
      }
    }
    var searchText = filterData.searchText;
    this.setState({ pagination, sortInfo, searchText }, () => {
      if (isFirstMount) {
        this.loadLocationTypesList()
      }
    })
  }
  /**
   * openSaveTypesModal: Open save location types modal on button click
   * handleCloseSaveModal: Close save location types modal onClick cancel from modal
   * addLocationTypeSaveSuccessHandler: Reload list on successfully adding location types from the modal 
   */
  openSaveTypesModal = (selectedType) => {
    this.setState({ showSaveTypesModal: true, selectedType });
  }
  handleCloseSaveModal = () => {
    this.setState({ showSaveTypesModal: false, selectedType: null });
  }
  addLocationTypeSaveSuccessHandler = () => {
    this.loadLocationTypesList();
    this.setState({ showSaveTypesModal: false, selectedType: null });
  }
  /**
   * deleteSelectedTypes  make API call to delete location type
   */
  deleteSelectedTypes = (selectedType: LocationType) => {
    confirm({
      title: 'Confirm delete',
      content: 'Are you sure you want to delete the selected location type?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        this.props.actions.deleteLocationType(selectedType.lookupId)
      },
      onCancel() {

      },
    });
  }
}

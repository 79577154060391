import { useEffect } from 'react';
import { useAppDispatch } from './storeHooks';
import { Hub } from 'aws-amplify/utils';
import getAuthenticatedUser from '../../app/actions/auth/getAuthenticatedUser';

function useAuthHubEvents() {
  const dispatch = useAppDispatch();

  useEffect(
    () =>
      Hub.listen('auth', ({ payload }) => {
        switch (payload.event) {
          case 'signedIn':
          case 'signedOut':
            dispatch(getAuthenticatedUser());
            break;
        }
      }),
    [dispatch]);
}

export default useAuthHubEvents;


import * as React from "react";
import { isEqual } from "lodash";

import { Layout, Spin } from 'antd';
import ZoneDescriptionCardContainer from "../../containers/zones/ZoneDescriptionCardContainer";
import ZoneLinkedUsersCardContainer from "../../containers/zones/ZoneLinkedUsersCardContainer";
import ZoneLinkedLocationsCardContainer from "../../containers/zones/ZoneLinkedLocationsCardContainer";
import AppUtilityService from "../../../common/services/AppUtilityService";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";


export class ZoneDescriptionComponent extends React.Component<IZoneDescriptionProps, IZoneDescriptionState> {

    constructor(props: IZoneDescriptionProps) {
        super(props);
        this.state = {
            selectedZoneId: this.props.selectedZoneId || null,
            selectedZone: null,
            selectedZoneTemp: null,
            isLoading: true,
            editMode: false
        }
    }

    componentWillMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        this.loadZoneData();
    }

    componentWillReceiveProps(nextProps: IZoneDescriptionProps) {
        if (!isEqual(this.props.selectedZoneId, nextProps.selectedZoneId)) {
            if (nextProps.selectedZoneId) {
                this.setState({ selectedZoneId: nextProps.selectedZoneId }, () => {
                    this.loadZoneData();
                })
            } else {
                this.setState({ selectedZone: null })
            }
        }

        /**
         * Handle load zone details success/error
         */
        if (nextProps.loadZoneDetailsSuccess && !isEqual(this.props.loadZoneDetailsSuccess, nextProps.loadZoneDetailsSuccess)) {
            var responseData = nextProps.loadZoneDetailsSuccess;
            this.setState({ selectedZone: responseData, isLoading: false, editMode: false })
        }
        if (nextProps.loadZoneDetailsError && !isEqual(this.props.loadZoneDetailsError, nextProps.loadZoneDetailsError)) {
            AppUtilityService.handleApiError(nextProps.loadZoneDetailsError);
        }

    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
    }

    render() {
        return (
            <Layout>
                <Spin spinning={this.state.isLoading}>
                    {
                        this.state.selectedZone ?
                            <div>
                                <ZoneDescriptionCardContainer
                                    selectedZone={this.state.selectedZone}
                                    editMode={this.state.editMode}
                                    changeEditModeCallback={this.changeEditModeHandler}
                                    addZoneSuccessCallback={this.addZoneSuccessHandler}
                                    updatedBasicDetailsCallback={this.updatedBasicDetailsHandler}
                                    deleteZoneSuccessCallback={this.deleteZoneSuccessHandler}
                                />
                                {
                                    (this.state.selectedZone && this.state.selectedZone.zoneId) ?
                                        <ZoneLinkedUsersCardContainer selectedZone={this.state.selectedZone} /> : null
                                }
                                {
                                    (this.state.selectedZone && this.state.selectedZone.zoneId) ?
                                        <ZoneLinkedLocationsCardContainer selectedZone={this.state.selectedZone} /> : null
                                }
                            </div>
                            :
                            <EmptyStateComponent title="No Zones" />
                    }
                </Spin>
            </Layout>
        )
    }

    loadZoneData = () => {
        var { selectedZoneId } = this.state;
        if (selectedZoneId) {
            this.setState({ isLoading: true });
            this.props.actions.loadSelectedZoneDetails(selectedZoneId)
        }
    }

    /**
     * Store selectedZone in a temp selectedZoneTemp variable so original node data is not lost when we reset selectedZone to the new zone object
     * Set selectedZone to new zone object based on nodeType and pass to ZoneDescriptionCardComponent, ZoneLinkedUsersComponent, ZoneLinkedLocationsComponent
     * components
     */
    addZone = (nodeType: string) => {
        var selectedZone = { ...this.state.selectedZone };
        var selectedZoneTemp = { ...this.state.selectedZoneTemp };
        if (selectedZone) {
            if (!selectedZone.zoneId) {
                selectedZone = selectedZoneTemp;
            } else {
                selectedZoneTemp = this.state.selectedZone;
            }
        }
        var newZone: ZoneDetails = {
            created: null,
            zoneId: 0,
            parentZoneId: 0,
            name: "",
            description: "",
            users: [],
            locations: [],
        }

        switch (nodeType) {
            case "child_zone":
                newZone.parentZoneId = this.state.selectedZoneId
                newZone.parentZoneName = selectedZone.name;
                break;
            case "zone":
                newZone.parentZoneId = null
                break;
            default: break;
        }
        this.setState({ selectedZone: newZone, selectedZoneTemp, editMode: true });
    }


    /**
     * Pass add/update/delete success callbacks from ZoneDescriptionComponent to ZonePage to handle in ZoneTreeComponent 
     */
    addZoneSuccessHandler = (addedZone: any) => {
        this.setState({ editMode: false })
        this.props.addZoneSuccessCallback(addedZone);
    }
    updatedBasicDetailsHandler = () => {
        this.setState({ editMode: false })
        this.props.updatedBasicDetailsCallback();
    }
    deleteZoneSuccessHandler = () => {
        this.props.deleteZoneSuccessCallback();
    }
    changeEditModeHandler = (editMode: boolean) => {
        this.setState({ editMode })
    }

} 

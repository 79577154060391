import ApiService from "../../../common/services/ApiService";

/**
 * Load all zones
 */
export function loadZonesListData(params) {
  return function (dispatch) {
    dispatch({ type: `LOAD_ZONE_LIST_DATA_REQUEST` });
    return ApiService.getData("/admin/zones", params)
      .then((successResult) => {
        dispatch({ type: `LOAD_ZONE_LIST_DATA_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_ZONE_LIST_DATA_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================

/**
 * Add new zone
 */
export function addNewZone(formData) {
  return function (dispatch) {
    dispatch({ type: `ADD_NEW_ZONE_REQUEST` });
    return ApiService.postData("/admin/zones", formData)
      .then((successResult) => {
        dispatch({ type: `ADD_NEW_ZONE_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `ADD_NEW_ZONE_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================

/**
 * Get selected zone details
 */
export function loadSelectedZoneDetails(selectedZoneId) {
  return function (dispatch) {
    dispatch({ type: `LOAD_SELECTED_ZONE_DETAILS_REQUEST` });
    return ApiService.getData("/admin/zones/" + selectedZoneId, null)
      .then((successResult) => {
        dispatch({ type: `LOAD_SELECTED_ZONE_DETAILS_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `LOAD_SELECTED_ZONE_DETAILS_FAILURE`, payload: errorResult });
      });
  }
}
/**
 * Update selected zone
 */
export function updateZoneDetails(selectedZoneId, formData) {
  return function (dispatch) {
    dispatch({ type: `UPDATE_ZONE_DETAILS_REQUEST` });
    return ApiService.updateData("/admin/zones/" + selectedZoneId, null, formData)
      .then((successResult) => {
        dispatch({ type: `UPDATE_ZONE_DETAILS_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `UPDATE_ZONE_DETAILS_FAILURE`, payload: errorResult });
      });
  }
}
/**
 * Delete selected zone
 */
export function deleteSelectedZone(selectedZoneId) {
  return function (dispatch) {
    dispatch({ type: `DELETE_SELECTED_ZONE_REQUEST` });
    return ApiService.deleteData("/admin/zones/" + selectedZoneId, null, null)
      .then((successResult) => {
        dispatch({ type: `DELETE_SELECTED_ZONE_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `DELETE_SELECTED_ZONE_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
/**
 * Assign user to zone 
 */
export function assignUserToZone(zoneId, userId) {
  return function (dispatch) {
    dispatch({ type: `ASSIGN_USER_TO_ZONE_REQUEST` });
    return ApiService.updateData('/admin/zones/' + zoneId + '/users/' + userId, null, null)
      .then((successResult) => {
        dispatch({ type: `ASSIGN_USER_TO_ZONE_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `ASSIGN_USER_TO_ZONE_FAILURE`, payload: errorResult });
      });
  }
}
/**
 * Unassign user from zone 
 */
export function unassignUserFromZone(zoneId, userId) {
  return function (dispatch) {
    dispatch({ type: `UNASSIGN_USER_FROM_ZONE_REQUEST` });
    return ApiService.deleteData('/admin/zones/' + zoneId + '/users/' + userId, null, null)
      .then((successResult) => {
        dispatch({ type: `UNASSIGN_USER_FROM_ZONE_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `UNASSIGN_USER_FROM_ZONE_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================
/**
 * Assign location to zone 
 */
export function assignLocationToZone(zoneId, locationId) {
  return function (dispatch) {
    dispatch({ type: `ASSIGN_LOCATION_TO_ZONE_REQUEST` });
    return ApiService.updateData('/admin/zones/' + zoneId + '/locations/' + locationId, null, null)
      .then((successResult) => {
        dispatch({ type: `ASSIGN_LOCATION_TO_ZONE_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `ASSIGN_LOCATION_TO_ZONE_FAILURE`, payload: errorResult });
      });
  }
}
/**
 * Unassign user from zone 
 */
export function unassignLocationFromZone(zoneId, locationId) {
  return function (dispatch) {
    dispatch({ type: `UNASSIGN_LOCATION_FROM_ZONE_REQUEST` });
    return ApiService.deleteData('/admin/zones/' + zoneId + '/locations/' + locationId, null, null)
      .then((successResult) => {
        dispatch({ type: `UNASSIGN_LOCATION_FROM_ZONE_SUCCESS`, payload: successResult.data });
      })
      .catch((errorResult) => {
        dispatch({ type: `UNASSIGN_LOCATION_FROM_ZONE_FAILURE`, payload: errorResult });
      });
  }
}
// ================================================================================

import { ohioElectronicInstantBingoReportUpdated } from './slice-actions';
import apiService from '../../../common/services/ApiService';
import { createStandardFetchedAction } from '../../../common/store/fetched.slices';
import { Moment } from 'moment';
import AppConstants from '../../../common/AppConstants';

export const getOhioElectronicInstantBingoReport =
  (dateFrom: Moment, dateTo: Moment, locationId: number | null) => createStandardFetchedAction(
    ohioElectronicInstantBingoReportUpdated,
    async () => {

      const result = await apiService.getData(
        `/reports/invoicing/ohio-electronic-instant-bingo/${dateFrom.format(AppConstants.apiDateFormat)}/${dateTo.format(AppConstants.apiDateFormat)}`,
        { locationId });
      return result.data;
    },
    false,
  );

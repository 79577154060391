import { hashHistory } from "react-router"
import ApiService from "../../../common/services/ApiService";
import {
    DELETE_GAME_SELECTION_WINDOW_ERROR,
    DELETE_GAME_SELECTION_WINDOW_REQUEST,
    DELETE_GAME_SELECTION_WINDOW_SUCCESS,
    GET_GAME_SELECTION_WINDOW_ERROR,
    GET_GAME_SELECTION_WINDOW_REQUEST,
    GET_GAME_SELECTION_WINDOW_SUCCESS,
    POST_GAME_SELECTION_WINDOW_ERROR,
    POST_GAME_SELECTION_WINDOW_REQUEST,
    POST_GAME_SELECTION_WINDOW_SUCCESS,
    UPDATE_GAME_SELECTION_WINDOW_ERROR,
    UPDATE_GAME_SELECTION_WINDOW_SUCCESS,
    GET_SELECT_LOCATIONS_DATA_REQUEST,
    GET_SELECT_LOCATIONS_DATA_SUCCESS,
    GET_SELECT_LOCATIONS_DATA_ERROR,
    IAddGameSelectionWindow,
    IGameSelectionWindow,

} from "../../reducers/gameSelectionWindow/GameSelectionWindowReducer";

export function loadSelectLocations() {
  return function (dispatch) {
      dispatch({ type: GET_SELECT_LOCATIONS_DATA_REQUEST });
      return ApiService.getData("/gameSelectionWindow/selectLocations/")
          .then((successResult) => {
              dispatch({ type: GET_SELECT_LOCATIONS_DATA_SUCCESS, payload: successResult.data });
          })
          .catch((errorResult) => {
              dispatch({ type: GET_SELECT_LOCATIONS_DATA_ERROR, payload: errorResult });
          });
  }
}

export function loadGameSelectionWindows() {
    return function (dispatch) {
        dispatch({ type: GET_GAME_SELECTION_WINDOW_REQUEST });
        return ApiService.getData("/gameSelectionWindow/")
            .then((successResult) => {
                dispatch({ type: GET_GAME_SELECTION_WINDOW_SUCCESS, payload: successResult.data });
            })
            .catch((errorResult) => {
                dispatch({ type: GET_GAME_SELECTION_WINDOW_ERROR, payload: errorResult });
            });
    }
}

export function deleteGameSelectionWindows(gameSelectionWindowId: number) {
    return function (dispatch) {
        dispatch({ type: DELETE_GAME_SELECTION_WINDOW_REQUEST });
        return ApiService.deleteData(`/gameSelectionWindow/${gameSelectionWindowId}`)
            .then((successResult) => {
                dispatch({ type: DELETE_GAME_SELECTION_WINDOW_SUCCESS, payload: gameSelectionWindowId});
            })
            .catch((errorResult) => {
                dispatch({ type: DELETE_GAME_SELECTION_WINDOW_ERROR, payload: errorResult });
            });
    }
}

export function createGameSelectionWindows(gameSelection: IAddGameSelectionWindow) {
    return function (dispatch) {
        dispatch({ type: POST_GAME_SELECTION_WINDOW_REQUEST });
        return ApiService.postData("/gameSelectionWindow/", gameSelection)
            .then((successResult) => {
                dispatch({ type: POST_GAME_SELECTION_WINDOW_SUCCESS, payload: successResult.data });
            }).then(() => {       
              hashHistory.push({
                pathname: `/admin/gameSelectionWindow`
              })
          })
            .catch((errorResult) => {
                dispatch({ type: POST_GAME_SELECTION_WINDOW_ERROR, payload: errorResult });
            });
    }
}

export function updateGameSelectionWindows(gameSelection: IAddGameSelectionWindow) {

    return function (dispatch) {
        return ApiService.updateData("/gameSelectionWindow/", null, gameSelection)
            .then((successResult) => {
                dispatch({ type: UPDATE_GAME_SELECTION_WINDOW_SUCCESS, payload: successResult.data });
            }).then(() => {       
                hashHistory.push({
                  pathname: `/admin/gameSelectionWindow`
                })
            })
            .catch((errorResult) => {
                dispatch({ type: UPDATE_GAME_SELECTION_WINDOW_ERROR, payload: errorResult });
            });
    }
}
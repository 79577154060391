import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as BreadcrumbActions from '../../../../common/actions/breadcrumbs/BreadcrumbActions';
import { SessionsReportPage } from '../../../components/reports/locationStatisticsReports/SessionsReportPage';
import { RootState } from '../../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadSessionsListSuccess: state.ReportsReducer.loadSessionsListSuccess,
    loadSessionsListError: state.ReportsReducer.loadSessionsListError,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators(BreadcrumbActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionsReportPage);

import React, { useCallback, useEffect, useState } from 'react';
import { IInvoice } from '../../reducers/billing/billing.models';
import { Button, Form, Input, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { updateInvoiceNotes } from '../../actions/billing/updateInvoiceNotes';
import { LoadState } from '../../../common/store/fetched';
import { usePrevious } from '../../../common/hooks/usePrevious';

export type Props = {
  invoice: IInvoice;
  onClose: () => void;
};

const EditInvoiceNotesModal = ({ invoice, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const updateLoadState = useAppSelector(state => state.BillingReducer.updateInvoiceNotesOperation.loadState);
  const isSavingNotes = updateLoadState === LoadState.InProgress;
  const prevUpdateLoadState = usePrevious(updateLoadState);
  const [notes, setNotes] = useState(invoice.comment);

  useEffect(
    () => {
      if (prevUpdateLoadState === LoadState.InProgress && updateLoadState === LoadState.Success) {
        onClose();
      }
    },
    [updateLoadState, prevUpdateLoadState, onClose]);

  const handleSave = useCallback(
    () => {
      dispatch(updateInvoiceNotes(invoice.invoiceId, notes || null));
    },
    [dispatch, invoice.invoiceId, notes]);

  const handleCancel = useCallback(() => {
    if (!isSavingNotes) {
      onClose();
    }
  }, [isSavingNotes, onClose]);

  return (
    <Modal
      visible
      title="Edit notes"
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={onClose} disabled={isSavingNotes}>Cancel</Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          onClick={handleSave}
          loading={updateLoadState === LoadState.InProgress}
        >
          Save note
        </Button>,
      ]}
    >
      <Form>
        <Form.Item label="Notes">
          <Input.TextArea
            value={notes}
            rows={4}
            placeholder=""
            onChange={e => setNotes(e.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditInvoiceNotesModal;

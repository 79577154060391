import React, { useCallback, useEffect, useState } from 'react';
import { IInvoice, InvoiceAtachmentPreview, InvoiceAttachmentType } from '../../reducers/billing/billing.models';
import { Button, Card, Col, Form, Icon, Input, Modal, Row, Upload } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { LoadState } from '../../../common/store/fetched';
import { usePrevious } from '../../../common/hooks/usePrevious';
import { FormComponentProps } from 'antd/lib/form';
import ContentEditable from 'react-contenteditable';
import { downloadInvoiceAttachmentAndSave } from '../../actions/billing/downloadInvoiceAttachmentAndSave';
import { Link } from 'react-router';
import { updateInvoiceTemplate } from '../../actions/billing/updateInvoiceEmailTemplate';
import appUtilityService from '../../../common/services/AppUtilityService';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';

type Props = FormComponentProps & {
  invoice: IInvoice;
  onClose: () => void;
};

const EditInvoiceEmailModalComponent = ({ invoice, onClose, form }: Props) => {

  const recipientName = invoice.emailRecipients.length > 0 && invoice.emailRecipients[0].recipientName ? invoice.emailRecipients[0].recipientName : '';
 
  const replaceTokens = (body: string): string => {
    body = body.replaceAll('{DueDate}', invoice.dueDate ? appUtilityService.formatDate(invoice.dueDate) : '-');
    body = body.replaceAll('{ContactName}', recipientName ?? '-');
    body = body.replaceAll('{InvoiceNo}', invoice.invoiceNo ?? '-');
    body = body.replaceAll('{LocationName}', invoice.location.name ?? '-');
    return body;
  }

  const dispatch = useAppDispatch();

  const [newAttachmentsList, setFileList] = useState<UploadFile[]>([]);
  const [deletedAttachemnts, setDeletedAttachemnts] = useState<number[]>([]);

  const { getFieldDecorator } = form;
  const saveState = useAppSelector(state => state.BillingReducer.updateInvoiceEmailTemplateOperation.loadState);
  const isSaving = saveState === LoadState.InProgress;
  const prevSaveState = usePrevious(saveState);

  const [bodyHtml, setBodyHtml] = useState<string>(invoice.emailBodyTemplate);
  const [subjectHtml, setSubjectHtml] = useState<string>(invoice.emailSubjectTemplate);
  const [previewBody, setPreviewBody] = useState<string>(replaceTokens(invoice.emailBodyTemplate));
  const [previewSubject, setPreviewSubject] = useState<string>(replaceTokens(invoice.emailSubjectTemplate));

  

  const uploadProps: UploadProps = {
    onRemove: (file) => {
      const index = newAttachmentsList.indexOf(file);
      const newFileList = newAttachmentsList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...newAttachmentsList, file]);
      return false;
    },
    fileList: []
  };

  const removeCustomAttachment = (attachmentId: number) => {
    setDeletedAttachemnts([...deletedAttachemnts, attachmentId])
  }

  const downloadInvoiceAttachment = useCallback(
    (attachment: InvoiceAtachmentPreview) => {
      dispatch(downloadInvoiceAttachmentAndSave(invoice.invoiceId, attachment.type, attachment.invoiceAttachmentId))
    },
    [invoice.invoiceId]
  );

  useEffect(() => {
    if (prevSaveState === LoadState.InProgress && saveState === LoadState.Success) {
      onClose();
    }
  }, [saveState, prevSaveState, onClose]);

  const handleSave = useCallback(() => {
    form.validateFields(async (err, values) => {
      if (err) return;
      dispatch(updateInvoiceTemplate(invoice.invoiceId, subjectHtml, bodyHtml, newAttachmentsList, deletedAttachemnts));
    });
  }, [subjectHtml, dispatch, form, bodyHtml, newAttachmentsList, deletedAttachemnts]);

  const handleCancel = () => {
    onClose();
  };

  const insertMarker = (param, marker: string) => {
    param.preventDefault();
    document.execCommand("insertText", false, marker)
  }

  const onBodyChange = (param) => {
    const html = param.target.value;
    setBodyHtml(html);
    setPreviewBody(replaceTokens(html));
  }

  const onSubjectChange = (param) => {
    const html = param.target.value;
    setSubjectHtml(html);
    setPreviewSubject(replaceTokens(html));
  }

  const renderAttachmentsList = () => {
    const newCustomAttachemntsList = newAttachmentsList.map(p => {
      return (
        <><li key={p.fileName}>{p.name} <span className="link-primary" onClick={() => uploadProps.onRemove(p)}><i className="icon-remove" /></span></li></>
      )
    });

    const customAttachments = invoice.invoiceAtachmentPreviews.map((attachment, index) => {
      if (deletedAttachemnts.includes(attachment.invoiceAttachmentId)) return;
      return (
        <li key={attachment.invoiceAttachmentId}>
          <Link to={''} onClick={() => downloadInvoiceAttachment(attachment)}>
            {attachment.fileName}
          </Link>
          {attachment.type === InvoiceAttachmentType.Custom && (<span className="link-primary" onClick={() => { removeCustomAttachment(attachment.invoiceAttachmentId) }}> <i className="icon-remove" /></span>)}
        </li>
      )
    })
    return [...customAttachments, ...newCustomAttachemntsList]
  }

  return (
    <Modal
      visible
      title={'Edit e-mail message'}
      onCancel={handleCancel}
      width={900}
      footer={[
        <Button key="back" onClick={onClose}>Cancel</Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          onClick={handleSave}
          loading={isSaving}
        >
          Save
        </Button>,
      ]}
    >
      <Row>
        <Col span={12} style={{ paddingRight: 20 }}>
          <div style={{ overflowY: "auto", minHeight: 500, maxHeight: 600 }}>
            <Form>
              <Row >
                <Form.Item label="Subject">
                  {getFieldDecorator('subject', {
                    initialValue: subjectHtml,
                    rules: [
                      { max: 100, message: 'Maximum length is 100.' },
                      { required: true, message: 'Field is required.' },
                    ]
                  })(
                    <Input onChange={(param) => onSubjectChange(param)} />
                  )}
                </Form.Item>
                <Form.Item label="Body">
                  {getFieldDecorator('body', {
                    initialValue: bodyHtml,
                    rules: [
                      { max: 1000, message: 'Maximum length is 1000.' },
                      { required: true, message: 'Field is required.' },
                    ]
                  })(
                    <ContentEditable style={{ borderWidth: '1px', borderStyle: "solid", borderColor: '#d9d9d9', padding: '5px', borderRadius: '4px', lineHeight: '20px', minHeight: '200px' }}
                      html={bodyHtml} disabled={false} onChange={(param) => onBodyChange(param)} />
                  )}
                </Form.Item>
              </Row>
            </Form>

            <Row>
              <Col>
                <Row>
                  You can add these automatically filled fields to your e-mail message subject or body.
                </Row>
                <Row style={{ paddingBottom: 10 }}>
                  <Button style={{ marginRight: 10, marginTop: 10 }} onMouseDown={(evt) => insertMarker(evt, "{ContactName}")}><Icon type="plus" />  Contact Name</Button>
                  <Button style={{ marginRight: 10, marginTop: 10 }} onClick={(evt) => insertMarker(evt, "{DueDate}")}><Icon type="plus" /> Due Date</Button>
                  <Button style={{ marginRight: 10, marginTop: 10 }} onClick={(evt) => insertMarker(evt, "{InvoiceNo}")}><Icon type="plus" /> Invoice Number</Button>
                  <Button style={{ marginRight: 10, marginTop: 10 }} onClick={(evt) => insertMarker(evt, "{LocationName}")}><Icon type="plus" /> Location Name</Button>
                </Row>
              </Col>
            </Row>
            <Row>
              <Card
                title={<span style={{ fontSize: 14 }} className="t-black bold">Attachments</span>}
                className="content-card no-header-border no-card-padding"
                bordered={false}
                extra={
                  <>
                    <Upload {...uploadProps}>
                      <span className="link-primary" onClick={() => { }}><i className="icon-add" /></span>
                    </Upload>
                  </>
                }>
                {
                  invoice.invoiceAtachmentPreviews.length === 0
                    ? "No attachments"
                    : (<ul>{renderAttachmentsList()}</ul>)
                }
              </Card>
            </Row>
          </div>
        </Col>

        <Col span={12}>
          <Row style={{ paddingBottom: 10 }}>
            <span className="t-black bold">Message preview </span>
          </Row>
          <Row style={{ paddingBottom: 35 }}>
            <span className="t-black bold">Subject: </span>{previewSubject}
          </Row>
          <Row>
            <ContentEditable style={{ borderWidth: '0px' }}
              html={previewBody} disabled={true} onChange={() => { }} />
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}

const EditInvoiceEmailModal =
  Form.create<Props>()(EditInvoiceEmailModalComponent);

export default EditInvoiceEmailModal;
import * as React from "react";
import { isEqual, startCase } from "lodash";

import AppUtilityService from "../../../../common/services/AppUtilityService";
import LocationReportsFilterContainer from "../../../containers/reports/locationStatisticsReports/LocationReportsFilterContainer";
import DealsReportContainer from "../../../containers/reports/locationStatisticsReports/DealsReportContainer";
import WebAccessService from "../../../../common/services/WebAccessService";

import { Card, Menu, Dropdown, Icon, Layout } from 'antd';
import { hashHistory } from "react-router";
import { IDealsReportPageProps, IDealsReportPageState } from '../Reports';
import { filters } from "./commonFiltersConfiguration";

export class DealsReportPage extends React.Component<IDealsReportPageProps, IDealsReportPageState> {

    private dealsReportRef: any;

    constructor(props: IDealsReportPageProps) {
        super(props);
        this.state = {
            reportType: this.props.params && this.props.params.type || null,
            zoneName: null,
            locationName: null,
            locationSearchTerm: null,
            charityName: null,
            charitySearchTerm: null,
            distributorName: null,
            distributorSearchTerm: null,
            isLoadingCharts: false,
            isLoadingList: false,
            apiParams: null
        }
    }

    componentDidMount() {
        this.breadcrumbsBuild();
        this.checkPermissions(this.state.reportType)
    }

    componentWillReceiveProps(nextProps: IDealsReportPageProps) {
        /**
         * Deals chart
         */
        if (nextProps.loadDealsChartSuccess && !isEqual(this.props.loadDealsChartSuccess, nextProps.loadDealsChartSuccess)) {
            this.setState({ isLoadingCharts: false })
        }
        /**
         * Deals list
         */
        if (nextProps.loadDealsListSuccess && !isEqual(this.props.loadDealsListSuccess, nextProps.loadDealsListSuccess)) {
            this.setState({ isLoadingList: false })
        }

    }

    render() {
        let { zoneName, locationName, locationSearchTerm, charityName, charitySearchTerm, distributorName } = this.state;
        let filterString = AppUtilityService.displayStringFromArray([zoneName, locationName, charityName, distributorName]);

        let cardTitle = "Deals - " + startCase(this.state.reportType) + (filterString && (" | " + filterString))

        const extraActions =
            <Dropdown.Button disabled={this.state.isLoadingCharts || this.state.isLoadingList} type="primary" onClick={this.downloadData} overlay={<Menu onClick={this.downloadList}>
                <Menu.Item key="1">PDF</Menu.Item>
                <Menu.Item key="2">Excel</Menu.Item>
            </Menu>
            }>
                <Icon type="download" style={{ fontSize: "16px" }} /> Download as PDF
  </Dropdown.Button>;


        return (
            <>
                <Card style={{ paddingTop: "12px" }} className="reports-filter-card content-card no-header-border" bordered={false} title={cardTitle}
                    extra={WebAccessService.hasPermissionToAccess("Reports", "Download") ? extraActions : null}
                >
                    <LocationReportsFilterContainer
                        filters={filters}
                        getSelectedZoneNameCallback={this.handleSelectedZone}
                        getSelectedLocationNameCallback={this.handleSelectedLocation}
                        getSelectedLocationSearchTermCallback={this.handleSelectedLocationSearchTerm}
                        getSelectedCharityNameCallback={this.handleSelectedCharity}
                        getSelectedCharitySearchTermCallback={this.handleSelectedCharitySearchTerm}
                        getSelectedDistributorNameCallback={this.handleSelectedDistributor}
                        getSelectedDistributorSearchTermCallback={this.handleSelectedDistributorSearchTerm}
                        applyFiltersCallback={this.applyFilters}
                    />
                </Card>
                <div className="pg-reports-container">
                    <Layout>
                        <div className="container" style={{ background: '#fff' }}>
                            <DealsReportContainer
                                apiParams={this.state.apiParams}
                                reportType={this.state.reportType}
                                onRef={ref => (this.dealsReportRef = ref)}
                            />
                        </div>
                    </Layout>
                </div>
            </>
        )
    }
    breadcrumbsBuild = () => {
        let breadcrumbsList = [
            { name: "Reports", link: '/reports' },
            { name: "Deals - " + startCase(this.state.reportType), link: null }
        ]
        var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList, null);
        this.props.actions.updateBreadcrumbs(breadcrumbs);
    }
    /**
    * Load location basic details success/error handle
    */

    handleSelectedZone = (e) => {
        this.setState({ zoneName: e })
    }
    //-----------------------------------------------------------------------

    handleSelectedLocation = (e) => {
        this.setState({ locationName: e })
    }

    handleSelectedLocationSearchTerm = (e) => {
      this.setState({ locationSearchTerm: e })
    }
    //-----------------------------------------------------------------------

    handleSelectedCharity = (e) => {
        this.setState({ charityName: e })
    }

    handleSelectedCharitySearchTerm = (e) => {
      this.setState({ charitySearchTerm: e })
    }
    //-----------------------------------------------------------------------

    handleSelectedDistributor = (e) => {
        this.setState({ distributorName: e })
    }

    handleSelectedDistributorSearchTerm = (e) => {
      this.setState({ distributorSearchTerm: e })
    }
    //-----------------------------------------------------------------------

    applyFilters = (apiParams) => {
        apiParams.reportType = this.state.reportType;
        apiParams.dateFrom = AppUtilityService.formatAPIDateFilter(apiParams.filterValues.dateRange?.[0]);
        apiParams.dateTo = AppUtilityService.formatAPIDateFilter(apiParams.filterValues.dateRange?.[1]);
        this.setState({
            apiParams,
            isLoadingCharts: true,
            isLoadingList: true,
        })
    }

    downloadData = (e) => {
        e.key = "1";
        this.downloadList(e)
    }

    downloadList = (e) => {
        var downloadType = "pdf";
        switch (e.key) {
            case "1":
                downloadType = "pdf";
                break;
            case "2":
                downloadType = "excel";
                break;
            default: break;
        }
        this.dealsReportRef.getDownloadData(downloadType);
    }

    checkPermissions = (reportType) => {
        let hasPermissions = false;
        switch (reportType) {
            case "DealsAvailable":
                hasPermissions = WebAccessService.hasPermissionToAccess("Reports", "DealsAvailable")
                break;
            case "DealsCompleted":
                hasPermissions = WebAccessService.hasPermissionToAccess("Reports", "DealsCompleted")
                break;
            case "DealsInPlay":
                hasPermissions = WebAccessService.hasPermissionToAccess("Reports", "DealsInPlay")
                break;
            default: break;
        }
        if (!hasPermissions) {
            hashHistory.push("/unauthorized-access");
        }
    }
}

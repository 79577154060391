import { message } from 'antd';
import { pickBy } from 'lodash';
import moment from 'moment';
import { createApiCaller } from '../../../common/services/ApiService';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { processFailureReason } from '../../../common/store/failure';
import { LoadState, fetchDataWithActionCreator } from '../../../common/store/fetched';
import { IUpdateNDLocationInvoicingConfigRequest, UpdateLocationInvoicingConfigRequest } from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';
import { updateLocationInvoicingConfigOperation } from './slice-actions';

export const updateLocationInvoicingConfig = (locationId: number, request: UpdateLocationInvoicingConfigRequest) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const data = request.map((item) =>
    pickBy(
      {
        ...item,
        discounts:
          item['discounts']?.map((discount: IUpdateNDLocationInvoicingConfigRequest['discounts'][number]) => ({
            startDate: discount?.startDate != null ? moment(discount.startDate).format('YYYY-MM-DD') : undefined,
            discount: discount?.discount ?? undefined,
          })) ?? [],
      },
      (x) => x != null
    )
  );
  await fetchDataWithActionCreator(
    updateLocationInvoicingConfigOperation,
    dispatch,
    createApiCaller((api) => api.updateData<void>(`/locationInvoicingConfig/${locationId.toFixed(0)}`, undefined, data)),
    processFailureReason,
    false
  );

  const operation = getState().LocationInvoicingConfigReducer.updateLocationInvoicingConfigOperation;
  if (operation.loadState === LoadState.Failed) {
    message.error(
      `There was a problem with saving location invoicing config.
         Please try again or refresh the page.`,
      5
    );
  } else if (operation.loadState === LoadState.Success) {
    message.success(`Location invoicing config has been saved.`);
  }
};

import * as React from "react";
import { isEqual, isEmpty } from "lodash";
import AppUtilityService from "../../services/AppUtilityService";
var Highcharts = require('highcharts');
const hcnd = require('highcharts/modules/no-data-to-display');
hcnd(Highcharts);

export class LineChartComponent extends React.Component<ILineChartProps, {}> {
    constructor(props: any) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        if (this.props.chartData) {
            this.renderChart(this.props.chartData);
        }
    }

    componentWillReceiveProps(nextProps: ILineChartProps) {
        if (nextProps.chartData && !isEqual(this.props.chartData, nextProps.chartData)) {
            this.renderChart(nextProps.chartData);
        }
    }

    render() {
        return (
            <div id={this.props.id}></div>
        );
    }

    renderChart = (chartData) => {
        var _self = this;
        Highcharts.chart(this.props.id, {
            chart: {
                height: this.props.height,
                type: 'line',
                // marginLeft: 60
                // margin: [10, 10, 70, 40]
            },
            title: {
                useHTML: true,
                text: chartData.data && !isEmpty(chartData.data) ? chartData.title : null,
                align: 'right',
                floating: false,
            },
            tooltip: {
                useHTML: true,
                formatter: function () {
                    return _self.formatY(this.y, chartData.data.yAxisUnits);
                }
            },
            xAxis: {
                title: {
                    text: chartData.data.xAxisTitle
                },
                categories: chartData.data.xAxis,
                // plotLines: [{
                //     color: '#FF0000',
                //     width: 2,
                //     value: 5.5,
                //     label: {
                //         text: 'Plot line',
                //         rotation: 0
                //     }
                // }]
            },
            yAxis: {
                title: {
                    text: chartData.data.yAxisTitle
                },
                labels: {
                    style: {
                        color: Highcharts.getOptions().colors[0]
                    },
                    formatter: function () {
                        return _self.formatY(this.value, chartData.data.yAxisUnits);
                    },
                },
            },
            plotOptions: {
                series: {
                    events: {
                        legendItemClick: function () {
                            return false;
                        }
                    }
                },
            },
            series: [{
                data: chartData.data.yAxis,
                showInLegend: false,
            }],
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        chart: {
                            height: this.props.responsiveHeight,
                        },
                        title: {
                            align: 'center',
                            floating: false
                        },
                        legend: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            layout: 'horizontal',
                            maxHeight: 300,
                        }
                    }
                }]
            },
            credits: {
                enabled: false
            },
            lang: {
                noData: 'No data'
            },
            noData: {
                style: {
                    fontWeight: 'regular'
                }
            }
        });
    }

    formatY = (value, units): string => {
        var val = "";
        switch (units) {
            case "currency":
                val = AppUtilityService.formatAmountAndShorten(value, 2)
                break;
            case "percent":
                val = AppUtilityService.shortenLargeNumber(value, 2) + "%"
                break;
            default:
                val = AppUtilityService.shortenLargeNumber(value, 2)
                break;
        }
        return val;
    }
}
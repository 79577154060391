import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as BreadcrumbActions from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import * as UserRoleActions from '../../actions/userRole/UserRoleActions';
import { UserRoleListPage } from '../../components/userRole/UserRoleListPage';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    userRoleListFilters: state.UserRoleReducer.userRoleListFilters,

    deleteMultipleUserRolesSuccess: state.UserRoleReducer.deleteMultipleUserRolesSuccess,
    deleteMultipleUserRolesError: state.UserRoleReducer.deleteMultipleUserRolesError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...BreadcrumbActions, ...UserRoleActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRoleListPage)


import * as React from "react";
import { isEqual, isEmpty } from "lodash";
import { FormComponentProps } from 'antd/lib/form/Form'
import AppUtilityService from "../../../common/services/AppUtilityService";
import ZonesDropdownContainer from "../../../common/containers/dropdown/ZonesDropdownContainer";
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent";

import { Card, Form, Modal, Row, Col, Tree, Spin } from 'antd';
import WebAccessService from "../../../common/services/WebAccessService";
const FormItem = Form.Item;
const confirm = Modal.confirm;
const TreeNode = Tree.TreeNode;


class LocationLinkedZonesComponent extends React.Component<ILocationZonesProps & FormComponentProps, ILocationZonesState> {
  private autocompleteRef: any;

  constructor(props: ILocationZonesProps & FormComponentProps) {
    super(props);
    this.state = {
      locationDetails: null,
      expandedKeys: [],
      showAddForm: false,
      isLoading: true
    }
  }

  componentWillReceiveProps(nextProps: ILocationZonesProps & FormComponentProps) {
    if (nextProps.loadBasicDetailsSuccess && !isEqual(this.props.loadBasicDetailsSuccess, nextProps.loadBasicDetailsSuccess)) {
      this.setState({ locationDetails: nextProps.loadBasicDetailsSuccess, isLoading: false })
    }
    /**
     * add location zone success/error handler
     */
    if (nextProps.addLocationZoneSuccess && !isEqual(this.props.addLocationZoneSuccess, nextProps.addLocationZoneSuccess)) {
      var locationDetails = { ...this.state.locationDetails };
      locationDetails.zones = nextProps.addLocationZoneSuccess;
      AppUtilityService.showMessageToast("Zone added", "success");
      this.props.form.resetFields();
      this.autocompleteRef.resetDatasource();
      this.setState({ locationDetails, showAddForm: false, isLoading: false })
    }
    if (nextProps.addLocationZoneError && !isEqual(this.props.addLocationZoneError, nextProps.addLocationZoneError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.addLocationZoneError)
    }
    /**
     * Delete location zone success/error handler
     */
    if (nextProps.deleteLocationZoneSuccess && !isEqual(this.props.deleteLocationZoneSuccess, nextProps.deleteLocationZoneSuccess)) {
      var locationDetails = { ...this.state.locationDetails };
      var responseData = nextProps.deleteLocationZoneSuccess;
      locationDetails.zones = responseData;
      AppUtilityService.showMessageToast("Zone removed", "success");
      this.autocompleteRef.resetDatasource();
      this.setState({ locationDetails, isLoading: false })
    }
    if (nextProps.deleteLocationZoneError && !isEqual(this.props.deleteLocationZoneError, nextProps.deleteLocationZoneError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.deleteLocationZoneError)
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    var extraActions = WebAccessService.hasPermissionToAccess("Location", "AddZone") &&
      <span className="link-primary" onClick={this.toggleAddForm}><i className={this.state.showAddForm ? "icon-minus" : "icon-add"} /></span>

    return (
      <Card className={"no-body-padding " + (this.state.showAddForm ? "hide-border" : "show-border")} bordered={false}
        title="Zones" extra={extraActions}
      >
        <div className={"slide-card-form " + (this.state.showAddForm ? "slidedown" : "")}>
          <Form>
            <Row>
              <Col span={20}>
                <FormItem>
                  {getFieldDecorator('zoneId', {
                    rules: [{ required: true, message: 'Please select a zone to add!' }],
                    validateTrigger: ['onSubmit']
                  })(
                    <ZonesDropdownContainer
                      placeholder="Select zone"
                      customParams={{
                        locationId: this.state.locationDetails && this.state.locationDetails.id
                      }}
                      onRef={ref => (this.autocompleteRef = ref)} />
                  )}
                </FormItem>
              </Col>
              <Col span={4}>
                <div className="text-right">
                  <FormItem>
                    <span className="link-primary" onClick={this.handleZoneAdd}>Add</span>
                  </FormItem>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
        <Spin spinning={this.state.isLoading}>
          {
            this.state.locationDetails && this.state.locationDetails.zones && !isEmpty(this.state.locationDetails.zones) ?
              <Tree
                className="block-children"
                defaultExpandAll={true}
                showIcon
                expandedKeys={this.state.expandedKeys}
                onExpand={this.onExpand}
              >
                {this.renderTreeNodes(this.state.locationDetails.zones)}
              </Tree>
              :
              <EmptyStateComponent title="No Linked Zones" />
          }
        </Spin>
      </Card >
    )
  }

  // generateActions = (item: BasicZoneDetails): Array<any> => {
  //   var actions = [];
  //   if (WebAccessService.hasPermissionToAccess("Location", "DeleteZone")) {
  //     actions.push(<i className="icon-remove link-danger" onClick={() => this.deleteZone(item.zoneId)} />)
  //   }
  //   return actions;
  // }

  toggleAddForm = () => {
    let { showAddForm } = this.state;
    if (showAddForm) {
      showAddForm = false;
    } else {
      showAddForm = true;
    }
    this.setState({ showAddForm })
  }

  renderTreeNodes = (allZones: Array<BasicZoneDetails>) => {
    return allZones.map((item: BasicZoneDetails) => {
      return (
        <TreeNode selectable={false} icon={<i className="icon-zone t-grey-dark" />}
          title={<span>{item.name}{(item.isSetZone && WebAccessService.hasPermissionToAccess("Location", "DeleteZone")) ? <i className="icon-remove link-danger" onClick={() => this.deleteZone(item.zoneId)} /> : ''}</span>}
          key={item.zoneId.toString()}
        >
          {
            item.childZones && !isEmpty(item.childZones) &&
            this.renderTreeNodes(item.childZones)
          }
        </TreeNode>
      );
    });
  }


  handleZoneAdd = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ isLoading: true })
        var { locationDetails } = this.state;
        var formData = values;
        this.props.actions.addLocationLinkedZone(locationDetails.id, formData.zoneId);
      }
    });
  }

  deleteZone = (zoneId: number) => {
    var { locationDetails } = this.state;
    confirm({
      title: 'Confirm dialog',
      content: 'Are you sure you want to remove the selected zone linked to this location?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        this.setState({ isLoading: true })
        this.props.actions.deleteLocationLinkedZone(locationDetails.id, zoneId);
      },
    });
  }

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
    });
  }

} export default Form.create<ILocationZonesProps & FormComponentProps>()(LocationLinkedZonesComponent);

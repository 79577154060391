
import * as React from "react";
import { Layout, Row, Col, Card, Button, Input, Icon } from 'antd';
import ZoneTreeContainer from "../../containers/zones/ZoneTreeContainer";
import ZoneDescriptionContainer from "../../containers/zones/ZoneDescriptionContainer";
import AppUtilityService from "../../../common/services/AppUtilityService";

export class ZonesPage extends React.Component<IZonesProps, IZonesState> {

  private zonesListRef: any;
  private zoneDescriptionRef: any;

  constructor(props: IZonesProps) {
    super(props);
    this.state = {
      selectedZoneId: null
    }
  }

  componentWillMount() {
    this.breadcrumbsBuild();
  }

  render() {
    var cardActions = <div className="zone-list-filters">
      <span>
        <Button onClick={this.addNewZone}>Add Root Zone</Button>
      </span>
      <span>
        <Button className="ml-8" type="primary" onClick={this.addChildZone} disabled={!this.state.selectedZoneId}>Add Child Zone</Button>
      </span>
    </div>;

    return (
      <div className="pg-container page-container">
        <Layout>
          <Row gutter={24}>
            <Col xl={8} lg={10} xs={24} className="sm-row-b-margin md-row-b-margin">
              <Card extra={cardActions} className="no-header-border" bordered={false}>
                <div>
                  <Input type="text"
                    suffix={<Icon type="search" theme="outlined" />}
                    placeholder="Search"
                    onChange={this.searchChange}
                  />
                </div>
              </Card>
              <div className="zone-tree-container">
                <ZoneTreeContainer
                  onRef={ref => (this.zonesListRef = ref)}
                  zoneSelectedCallback={this.zoneSelectedHandler}
                />
              </div>
            </Col>
            <Col xl={16} lg={14} xs={24}>
              <ZoneDescriptionContainer
                onRef={ref => (this.zoneDescriptionRef = ref)}
                selectedZoneId={this.state.selectedZoneId}
                addZoneSuccessCallback={this.addZoneSuccessHandler}
                updatedBasicDetailsCallback={this.updatedBasicDetailsHandler}
                deleteZoneSuccessCallback={this.deleteZoneSuccessHandler}
              />
            </Col>
          </Row>
        </Layout>
      </div>
    )
  }

  breadcrumbsBuild = () => {
    let breadcrumbsList = [
      { name: "Zone", link: null }
    ]
    var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList);
    this.props.actions.updateBreadcrumbs(breadcrumbs);
  }

  /**
   * Handle search change in ZoneTreeComponent using ref
   */
  searchChange = (e: any) => {
    this.zonesListRef.handleSearchChange(e.target.value);
  }

  /**
   * Set selectedZoneId from ZoneTreeComponent on selecting a node from tree list
   */
  zoneSelectedHandler = (selectedZoneId: number): void => {
    this.setState({ selectedZoneId })
  }

  /**
   * Call AddChildZone function in ZoneDescription with type of zone as params
   */
  addChildZone = () => {
    this.zoneDescriptionRef.addZone("child_zone");
  }
  addNewZone = () => {
    this.zoneDescriptionRef.addZone("zone");
  }

  /**
   * Handle add/update/delete success scenarios in ZoneTreeComponent from ZoneDescriptionComponent
   */
  addZoneSuccessHandler = (addedZone: any) => {
    this.zonesListRef.addNewZoneHandler(addedZone);
  }
  updatedBasicDetailsHandler = () => {
    this.zonesListRef.loadAllZones();
  }
  deleteZoneSuccessHandler = () => {
    this.zonesListRef.deleteZoneSuccessHandler();
  }

}


import * as React from "react";
import AppUtilityService from "../../../common/services/AppUtilityService";

import { Card, Input, Icon, Button, Badge } from 'antd';
import LocationTypeListTableContainer from "../../containers/locationTypes/LocationTypeListTableContainer";
import SaveLocationTypesModalContainer from "../../containers/locationTypes/SaveLocationTypesModalContainer";

export class LocationTypesPage extends React.Component<ILocationTypesPageProps, ILocationTypesPageState> {

  private locationTypeListRef: any;

  constructor(props: ILocationTypesPageProps) {
    super(props);
    this.state = {
      showSaveTypesModal: false,
      searchText: "",
      totalTypes: null
    }
  }

  componentWillMount() {
    this.breadcrumbsBuild();
    if (this.props.locationTypesListFilters) {
      this.handlePresetFilters(this.props.locationTypesListFilters)
    }
  }

  componentWillReceiveProps(nextProps: ILocationTypesPageProps) {
    /**
     * Get Location types details success/error handle
     */

  }

  render() {
    const title = <Input type="text"
      suffix={<Icon type="search" theme="outlined" />}
      placeholder="Search"
      onChange={this.searchChange}
      style={{ width: 200 }}
      value={this.state.searchText}
    />;

    const extraActions = <div className="user-list-filters">
      <span>
        <Button className="ml-8" type="primary" onClick={this.openSaveTypesModal}>+ New Location Type</Button>
      </span>
    </div>;
    return (
      <div className="pg-container page-container">
        <Card className="no-header-border" bordered={false}
          title={title}
          extra={extraActions}
        >
          <LocationTypeListTableContainer
            onRef={(ref) => this.locationTypeListRef = ref}
            getTotalTypesCallback={this.getTotalTypesHandler}
          />

          {
            this.state.showSaveTypesModal &&
            <SaveLocationTypesModalContainer locationType={null} saveSuccessCallback={this.addLocationTypeSaveSuccessHandler}
              closeSaveLocationTypeModal={this.handleCloseSaveModal} />
          }
        </Card>
      </div>
    )
  }
  /**
   * Get total users on load list success callback from UserListTableComponent
   * (required to show total in breadcrumbs)
   */
  getTotalTypesHandler = (totalCount: number) => {
    this.setState({ totalTypes: totalCount }, () => {
      this.breadcrumbsBuild()
    })
  }

  breadcrumbsBuild = () => {
    let breadcrumbsList = [
      { name: "Location Types", link: null }
    ]
    var extraContent = this.state.totalTypes &&
      <span className="font-12 t-grey-dark ml-8">
        <Badge dot className="mr-8" style={{ top: '-1px', background: '#A6ADC9' }} /> {this.state.totalTypes + " Total"}
      </span>;
    var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList, extraContent);
    this.props.actions.updateBreadcrumbs(breadcrumbs);
  }

  /**
   * Handle search change in UserListTableComponent using ref
   */
  searchChange = (e: any) => {
    this.setState({ searchText: e.target.value })
    this.locationTypeListRef.handleSearchChange(e.target.value);
  }


  /**
   * openSaveTypesModal: Open save types modal on button click
   * handleCloseSaveModal: Close save types modal onClick cancel from modal
   * addLocationTypeSaveSuccessHandler: Reload list on successfully adding types from the modal 
   */
  openSaveTypesModal = () => {
    this.setState({ showSaveTypesModal: true });
  }
  handleCloseSaveModal = () => {
    this.setState({ showSaveTypesModal: false });
  }
  addLocationTypeSaveSuccessHandler = () => {
    this.locationTypeListRef.loadLocationTypesList();
    this.setState({ showSaveTypesModal: false });
  }

  handlePresetFilters = (filterData) => {
    var searchText = filterData.searchText;
    this.setState({ searchText })
  }
}

import { createApiCaller } from '../../../common/services/ApiService';
import { fetchDataWithActionCreator } from '../../../common/store/fetched';
import { gameViewUpdated } from '../../actions/games/slice-actions'
import {IGameView} from '../../reducers/games/games.models';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { processFailureReason } from '../../../common/store/failure';

export const getGameView = (gameId: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    return await fetchDataWithActionCreator(
      gameViewUpdated,
      dispatch,
      createApiCaller(api => api.getData<IGameView>(`/games/view/${gameId}`)),
      processFailureReason,
      true,
    );
  };
}
import * as React from "react";
import { useEffect, useState } from "react";
import { Row, Col, Modal, Carousel } from "antd";
import { IMedia } from "../../reducers/games/games.models";
const styles = require('../../../common/styles/ViewGameStyles.css');
const leftButton = require("../../../images/Button_Arrow_Left.png")
const rightButton = require("../../../images/Button_Arrow_Right.png")

export const ViewGameCarousel = (props) => {
  let carouselRef;
  carouselRef = React.createRef();
  const [currentMedia, setCurrentMedia] = useState<IMedia>({ gameID: props.gameId, url: "", isVideo: false});
  const [showLargeModal, setShowLargeModal] = useState<boolean>(false);

  console.log("props: " + props.iconUrl);

  const onImageClicked = (url: string, isVideo: boolean) => {
    setShowLargeModal(true);
    setCurrentMedia({ gameID: props.gameId, url: url, isVideo: isVideo });
}

  return (
    <div className="page-container ">
      {props.carouselMedia !== null && props.carouselMedia !== undefined &&
      <Col>
                            <Row>
                                <Col>
                                    <Carousel
                                        arrows
                                        dots={false}
                                        prevArrow={<img src={leftButton} />}
                                        nextArrow={<img src={rightButton} />}
                                        ref={carouselRef}
                                        afterChange={(currentSlide: number) => {
                                            if (currentSlide == 0)
                                                setCurrentMedia({ gameID: props.gameId, url: props.iconUrl, isVideo: false });
                                            else
                                                setCurrentMedia({ gameID: props.gameId, url: props.carouselMedia[currentSlide - 1].url, isVideo: props.carouselMedia[currentSlide - 1].isVideo });
                                        }}
                                    >                                        
                                        <div>
                                            <Row type="flex" justify="center" align="middle">
                                                <Col>
                                                    <img className="gameDetailsIcon" src={props.iconUrl} onClick={() => { onImageClicked(props.iconUrl, false) }} />
                                                </Col>
                                            </Row>
                                        </div>
                                        {
                                            props.carouselMedia.map(gameMedia =>
                                            <div key={gameMedia.gameID}>
                                                <Row type="flex" justify="center" align="middle">
                                                    <Col>
                                                        {!gameMedia.isVideo &&
                                                            <img className="gameDetailsIcon" src={gameMedia.url} onClick={() => { onImageClicked(gameMedia.url, false) }} />
                                                        }
                                                        {gameMedia.isVideo &&
                                                            <video className="gameDetailsIcon" controls src={gameMedia.url} />
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>)
                                        }
                                    </Carousel>
                                    <Row className="pad5TopBottom">
                                <Col style={{marginLeft: "30"}}>
                                    <div style={{fontWeight: "bolder"}}>Theme:</div>
                                    <div >{props.theme}</div> 
                                </Col>
                                <Col style={{marginLeft: "30"}}>
                                    <div style={{fontWeight: "bolder"}}>Extended Play Features:</div>
                                    <div>{props.extendedPlayFeatures}</div>
                                </Col>
                            </Row>
                                    <Modal
                                        title=""
                                        visible={showLargeModal}
                                        footer={null}                                        
                                        width={918}
                                        onCancel={() => { setShowLargeModal(false) }}
                                    >
                                        <section style={{ position: "relative" }}>
                                            <img src={leftButton} className={"modalCarouselLeft"} onClick={() => {
                                                carouselRef.current.prev()
                                                setCurrentMedia({ gameID: props.gameId, url: currentMedia.url, isVideo: currentMedia.isVideo })
                                            }} />
                                            <img src={rightButton} className={"modalCarouselRight"} onClick={() => {
                                                carouselRef.current.next()
                                                setCurrentMedia({ gameID: props.gameId, url: currentMedia.url, isVideo: currentMedia.isVideo })
                                            }} />
                                            <Row type="flex" justify="center" align="middle">                                            
                                                <Col>
                                                    {!currentMedia.isVideo &&
                                                        <img style={{ maxWidth: '870px' }} src={currentMedia.url} />}
                                                    {currentMedia.isVideo && <video controls src={currentMedia.url} />}
                                                </Col>                                            
                                            </Row>
                                        </section>
                                    </Modal>
                                </Col>
                            </Row>
                            
                        </Col>
      }
    </div>
  );
}
export default ViewGameCarousel;
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as BreadcrumbActions from '../../../common/actions/breadcrumbs/BreadcrumbActions';
import * as UserActions from '../../actions/user/UserActions';
import { UserListPage } from '../../components/user/UserListPage';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    userListFilters: state.UserReducer.userListFilters,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({...BreadcrumbActions, ...UserActions}, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserListPage)

import {
  IDefaultLocationInvoicingConfigItem,
  INDLocationInvoicingConfigItem,
  IUpdateDefaultLocationInvoicingConfigRequest,
  IUpdateNDLocationInvoicingConfigRequest,
  LocationInvoicingConfigItem,
  UpdateLocationInvoicingConfigRequest,
} from '../../reducers/locationInvoicingConfig/locationInvoicingConfig.models';
import { Moment } from 'moment';
import { NDv1 } from '../../../common/models/InvoicingSystems';

export type LocationInvoicingConfigEditFormFields = Pick<
  IDefaultLocationInvoicingConfigItem,
  | 'invoicingSystemId'
  | 'invoicingEnabled'
  | 'nextInvoiceNoSuffix'
  | 'dueInDays'
  | 'invoicingDistributorId'
  | 'distributorContactId'
  | 'additionalInvoiceRecipients'
  | 'amountDuePercentage'
  | 'invoicingContacts'
  | 'primaryContact'
  | 'taxRateIds'
> &
  Pick<INDLocationInvoicingConfigItem, 'invoicingSystemId' | 'invoicingEnabled' | 'invoicingDistributorId' | 'localCharityId' | 'charityId'> & {
    discounts:
      | {
          startDate: Moment;
          discount: number;
        }[]
      | null;
    billingContacts:
      | {
          locationInvoicingConfigContactId: number;
          invoicingConfigId: number;
          contactId: number;
          isPrimary: boolean;
          contact: Contacts;
        }[]
      | null;
    hasOverrideBillingAddress: boolean;
    overrideBillingName: string | null;
    overrideBillingAddressLine1: string | null;
    overrideBillingAddressLine2: string | null;
    overrideBillingAddressCity: string | null;
    overrideBillingAddressState: string | null;
    overrideBillingAddressZipcode: string | null;
  };

export type LocationInvoicingConfigTabData = {
  key: number;
  item: LocationInvoicingConfigItem;
}

export function mapFormFieldsToRequest(
  invoicingSystemId: number,
  tabsBefore: LocationInvoicingConfigTabData[],
  updated: LocationInvoicingConfigEditFormFields
): UpdateLocationInvoicingConfigRequest {
  if (invoicingSystemId === NDv1) {
    return [mapNdFormFieldsToRequest(tabsBefore[0].item as INDLocationInvoicingConfigItem, updated)];
  } else {
    const groupedByTabIndex = Object.entries(updated)
      .reduce((acc, [key, value]) => {
        const match = key.match(/(\w+)_(\d+)/);
        if (match != null) {
          const [, field, index] = match;
          if (acc[index] == null) {
            acc[index] = {} as LocationInvoicingConfigEditFormFields;
          }
          if ((field === 'localCharityId' || field === 'salesTaxId') && value === 'NONE') {
            acc[index][field] = null;
          } else {
            acc[index][field] = value;
          }
        }
        return acc;
      }, {} as { [index: string]: LocationInvoicingConfigEditFormFields });
    return Object.values(tabsBefore)
      .map(before =>
        mapDefaultFormFieldsToRequest(before.item, groupedByTabIndex[before.key], invoicingSystemId));
  }
}

function mapDefaultFormFieldsToRequest(
  before: LocationInvoicingConfigItem,
  updated: LocationInvoicingConfigEditFormFields | null,
  invoicingSystemId: number
): IUpdateDefaultLocationInvoicingConfigRequest {
  return {
    ...before,

    ...(updated ?? {}),
    invoicingSystemId,
    ...(updated == null
      ? {}
      : {
          overrideBillingName: updated.overrideBillingName,
          overrideBillingAddress: updated.hasOverrideBillingAddress
            ? {
                address1: updated.overrideBillingAddressLine1,
                address2: updated.overrideBillingAddressLine2,
                city: updated.overrideBillingAddressCity,
                addressState: updated.overrideBillingAddressState,
                zipcode: updated.overrideBillingAddressZipcode,
              }
            : null,
          billingContacts: updated.billingContacts ?? (before as IDefaultLocationInvoicingConfigItem).invoicingContacts,
        }),
  } as IUpdateDefaultLocationInvoicingConfigRequest;
}

function mapNdFormFieldsToRequest(before: INDLocationInvoicingConfigItem, updated: LocationInvoicingConfigEditFormFields | null): IUpdateNDLocationInvoicingConfigRequest {
  return {
    ...before,
    ...(updated ?? {}),
    invoicingSystemId: NDv1,
    discounts: updated.discounts ?? [],
  };
}

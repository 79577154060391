import React from "react"
import { hashHistory } from "react-router";
import AppUtilityService from "../../../common/services/AppUtilityService"
import { EmptyStateComponent } from "../../../common/components/layouts/EmptyStateComponent"
import { Card, Table, Badge, Row, Col, Modal, Switch, Button, Spin, message } from "antd"
import { IPagedQuarterCloseLocation, IQuarterCloseLocation } from '../../reducers/quarterClose/QuarterCloseReducer'
import sessionService from "../../../common/services/SessionService";
import { IQuarterCloseLocationSetupDetails } from '../../reducers/reports/ReportsReducer';
import { Fetched, LoadState } from '../../../common/store/fetched';
const confirmButton = require("../../../images/Button_Confirm.png")
const confirmDisabledButton = require("../../../images/Button_Confirm_Disabled.png")

interface QCLocationListFilterParams {
  page?: number;
  showDemoLocations?: boolean;
}

export interface IQuarterCloseLocationsPageProps {
  actions?: {
    updateBreadcrumbs(Breadcrumbs): void;
    loadLocations(params?: any): Promise<void>;
    getLatestLocationSetup(locationId: number): Promise<void>;
    dismissLatestLocationSetup();
    clearSelections(): void;
    getLocationsWithoutSettings(locationIds: Array<number>): void;
  };
  loadingPagedLocations: boolean;
  updateSelectedLocations(locationIds: number[]): void;
  updateSelectedGameSelectionWindowId(gameSelectionWindowId: number): void;
  locationsList: IPagedQuarterCloseLocation;
  selectedLocationIds: number[];
  latestSetup: Fetched<IQuarterCloseLocationSetupDetails>;
  locationIdsWithoutSettings: number[];
  gameSelectionWindowId: number;
}

export interface IQuarterCloseLocationsPageState {
  isLoading: boolean;
  sortInfo: SortObject;
  modalVisible: boolean;
  filteredLocations: IQuarterCloseLocation[];
  filterParams: QCLocationListFilterParams;
  filteredLocationsCount: number;
}

export class QuarterCloseLocationsPage extends React.Component<IQuarterCloseLocationsPageProps, IQuarterCloseLocationsPageState> {

  constructor(props: IQuarterCloseLocationsPageProps) {
    super(props);
    this.state = {
      sortInfo: null,
      isLoading: false,
      modalVisible: false,
      filteredLocations: [],
      filterParams: { showDemoLocations: false },
      filteredLocationsCount: null,
    }
  }

  componentDidMount() {
    this.props.actions.loadLocations()
    this.breadcrumbsBuild()
    this.loadLocationsFilter()
    this.props.actions.clearSelections();
  }

  componentDidUpdate(prevProps: Readonly<IQuarterCloseLocationsPageProps>, prevState: Readonly<IQuarterCloseLocationsPageState>, snapshot?: any): void {
    if (this.props.locationsList !== prevProps.locationsList) {
      this.loadLocationsFilter()
    }

    if (this.props.latestSetup !== prevProps.latestSetup) {
      if (this.props.latestSetup.loadState === LoadState.Failed) {
        message.error('Failed to load already selected games. Please try again.');
      }

      if (this.props.latestSetup.loadState === LoadState.Success) {
        let locationIds: number[] = [this.props.latestSetup.data.locationID];
        
        this.getLocationsWithoutSettings(locationIds);
        
        if (this.props.locationIdsWithoutSettings.length > 0) {
          hashHistory.push('/quarterClose/enableTito');
        } 
        else {
          hashHistory.push('/quarterClose/payoutOptions');
        } 
      }
    }
  }

  render() {
    const extraActions = <div className="location-list-filters">
      {
        sessionService.getRole() === "Admin" &&
        <span>
          <span className="font-14 regular">Show demo types</span>
          <Switch size="small" checked={this.state.filterParams.showDemoLocations} onChange={this.changeDemoLocationTypes} />
        </span>
      }</div>;
    let tableColumns = [
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        render: (text, record) => {
          return (
            <div>{`${text} (${record.id})`}</div>
          )
        }
      }, {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => {
          return (
            <>
              <span className={"locationStatusText" + (text === "COMPLETED" ? " locationStatusTextCompleteColor" : "")}>{text}</span>
              {record.canAddGames && (
                <Button
                  className="addGamesBtn"
                  onClick={() => this.addGames(record.id)}>
                  Add games
                </Button>
              )}
            </>
          );
        }
      },
    ];

    const onSelectChange = (selectedRowKeys: number[]) => {
      //verify that the locations exist in the data source
      const keysInDataSource = this.state.filteredLocations.map(p => p.id).filter(p => selectedRowKeys.includes(p))
      this.props.updateSelectedLocations(keysInDataSource);      
      this.props.updateSelectedGameSelectionWindowId(this.state.filteredLocations.find(l => l.id === keysInDataSource.find(l => l === selectedRowKeys[0]))?.gameSelectionWindowId)
      this.getLocationsWithoutSettings(keysInDataSource);
    };

    return (
      <div className="pg-container page-container">
        <Spin
          spinning={this.props.latestSetup.loadState === LoadState.InProgress}
          tip="Loading selected games"
          delay={500}
        >
          <Card className="no-header-border" bordered={true}

          >
            {extraActions}
          </Card>
          <Card className="no-header-border" bordered={false}
            title={'SELECT ONE OR MORE LOCATIONS TO EDIT'}

          >
            <Table
              rowSelection={{
                type: 'checkbox',
                onChange: onSelectChange,
                selectedRowKeys: this.props.selectedLocationIds,
                getCheckboxProps: (record) => ({
                  disabled: !record.isSelectable || ( this.props.gameSelectionWindowId !== null && this.props.gameSelectionWindowId !== undefined && record.gameSelectionWindowId !== this.props.gameSelectionWindowId)
                })
              }}
              rowKey={(record, index) => { return record.id }}
              loading={this.props.loadingPagedLocations}
              dataSource={this.state.filteredLocations}
              columns={tableColumns}
              locale={
                {
                  emptyText: <EmptyStateComponent title="No Locations" />
                }
              }
            />
          </Card>

          <Row>
            <Col>
              {this.props.selectedLocationIds.length > 0 &&
                <img className="navImageButton floatRight" onClick={() => {
                  this.props.actions.dismissLatestLocationSetup();
                  const nonSelectedLocations = this.props.locationsList.locations.filter(p => p.status === "NOT COMPLETE").map(p => p.id)
                  const difference = nonSelectedLocations.filter(p => !this.props.selectedLocationIds.includes(p))

                  if (difference.length > 0) {
                    this.setState({ modalVisible: true })
                  }
                  else {
                    if (this.props.locationIdsWithoutSettings.length > 0) {
                      hashHistory.push('/quarterClose/enableTito');
                    } 
                    else {
                      hashHistory.push('/quarterClose/payoutOptions');
                    } 
                  }
                }} src={confirmButton} alt="Checkout" />
              }
              {this.props.selectedLocationIds.length === 0 &&
                <img className="navImageButton floatRight" src={confirmDisabledButton} alt="Checkout Disabled" />
              }
            </Col>
          </Row>

          <Modal
            title="Would you like to select more locations?"
            visible={this.state.modalVisible}
            onOk={() => this.setState({ modalVisible: false })}
            onCancel={() => this.onModalCancel() }
            okText="Yes"
            cancelText="No"
          >
            <p>You have not selected all of your locations.  You can select multiple locations simultaneously if you choose.</p>
          </Modal>
        </Spin>
      </div>
    )
  }

  onModalCancel = () => {
    if (this.props.locationIdsWithoutSettings.length > 0) {
      hashHistory.push('/quarterClose/enableTito');
    } 
    else {
      hashHistory.push('/quarterClose/payoutOptions');
    } 
  }

  breadcrumbsBuild = () => {
    const breadcrumbsList = [
      { name: "QUARTER CLOSE", link: '/quarterClose/locations' },
    ]

    const numLocations = !this.state.filterParams?.showDemoLocations ? this.props.locationsList.filteredTotal : this.props.locationsList.total

    const extraContent = this.props.locationsList.locations.length &&
      <span className="font-12 t-grey-dark ml-8">
        <Badge dot className="mr-8" style={{ top: '-1px', background: '#A6ADC9' }} /> {numLocations + " Total"}
      </span>

    const breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList, extraContent)
    this.props.actions.updateBreadcrumbs(breadcrumbs)
  }

  loadLocationsFilter = () => {
    let filteredLocations = this.props.locationsList.locations;
    if (!this.state.filterParams?.showDemoLocations && sessionService.getRole() === "Admin") {
      filteredLocations = filteredLocations.filter(loc => loc.prodDemoTest !== "ZD");
    }
    this.setState({ filteredLocations });
    var numLocations = this.state.filteredLocations.length;
    this.setState({filteredLocationsCount: numLocations})
    this.breadcrumbsBuild()
  }

  changeDemoLocationTypes = (checked) => {
    let filterParams = { ...this.state.filterParams }
    filterParams.page = 1;
    filterParams["showDemoLocations"] = checked
    this.setState({ filterParams }, () => this.loadLocationsFilter())
  }

  getLocationsWithoutSettings = (ids) => {
    this.props.actions.getLocationsWithoutSettings(ids);
  }

  addGames = (id) => {
    this.props.updateSelectedGameSelectionWindowId(this.state.filteredLocations.find(l => l.id === id)?.gameSelectionWindowId)
    this.props.actions.getLatestLocationSetup(id)
  }

}

import { loadCharityDetails } from "../../actions/charities/CharityActions"


export const ADD_CHARITY_LOCATION_APIKEY_REQUEST = `ADD_CHARITY_LOCATION_APIKEY_REQUEST`
export const ADD_CHARITY_LOCATION_APIKEY_RESPONSE = `ADD_CHARITY_LOCATION_APIKEY_RESPONSE`
export const ADD_CHARITY_LOCATION_APIKEY_FAILURE = `ADD_CHARITY_LOCATION_APIKEY_FAILURE`
export const REVOKE_CHARITY_LOCATION_APIKEY_REQUEST = `REVOKE_CHARITY_LOCATION_APIKEY_REQUEST`
export const REVOKE_CHARITY_LOCATION_APIKEY_RESPONSE = `REVOKE_CHARITY_LOCATION_APIKEY_RESPONSE`
export const REVOKE_CHARITY_LOCATION_APIKEY_FAILURE = `REVOKE_CHARITY_LOCATION_APIKEY_FAILURE`
export const CLEAR_CHARITY_LOCATION_APIKEY = 'CLEAR_CHARITY_LOCATION_APIKEY'


export interface ICharityState {
    loadCharityDetailsSuccess?: Charity;
    addCharityError?: any;
    addCharitySuccess?: any;
    deleteCharityError?: any;
    generatedAPIKeyPassword?: any;
    loadCharityListError?: any;
    updateCharityDetailsSuccess?: any;
    charityListFilters?: any;
    deleteCharitySuccess?: any;
    generatedAPIKeyUserName?: any;
    loadCharityDetailsError?: any;
    loadCharityListSuccess?: any;
    updateCharityDetailsError?: any;
}

const charityInitalState: ICharityState =
{
    loadCharityDetailsSuccess: undefined
}

const CharityReducer = (state: ICharityState = charityInitalState , action) => {
    switch (action.type) {
        case "CHARITY_LIST_FILTER_UPDATE":
            return { ...state, charityListFilters: action.payload };
        // Contacts List related
        case "LOAD_CHARITY_LIST_DATA_REQUEST":
            return { ...state, loadCharityListSuccess: null, loadCharityListError: null };
        case "LOAD_CHARITY_LIST_DATA_SUCCESS":
            return { ...state, loadCharityListSuccess: action.payload, loadCharityListError: null };
        case "LOAD_CHARITY_LIST_DATA_FAILURE":
            return { ...state, loadCharityListSuccess: null, loadCharityListError: action.payload };

        case "ADD_NEW_CHARITY_REQUEST":
            return { ...state, addCharitySuccess: null, addCharityError: null };
        case "ADD_NEW_CHARITY_SUCCESS":
            return { ...state, addCharitySuccess: action.payload, addCharityError: null };
        case "ADD_NEW_CHARITY_FAILURE":
            return { ...state, addCharitySuccess: null, addCharityError: action.payload };

        case "LOAD_CHARITY_DETAILS_REQUEST":
            return { ...state, loadCharityDetailsSuccess: null, loadCharityDetailsError: null };
        case "LOAD_CHARITY_DETAILS_SUCCESS":
            return { ...state, loadCharityDetailsSuccess: action.payload, loadCharityDetailsError: null };
        case "LOAD_CHARITY_DETAILS_FAILURE":
            return { ...state, loadCharityDetailsSuccess: null, loadCharityDetailsError: action.payload };

        case "UPDATE_CHARITY_DETAILS_REQUEST":
            return { ...state, updateCharityDetailsSuccess: null, updateCharityDetailsError: null };
        case "UPDATE_CHARITY_DETAILS_SUCCESS":
            return { ...state, updateCharityDetailsSuccess: action.payload, updateCharityDetailsError: null };
        case "UPDATE_CHARITY_DETAILS_FAILURE":
            return { ...state, updateCharityDetailsSuccess: null, updateCharityDetailsError: action.payload };

        // charity details delete
        case "DELETE_CHARITY_REQUEST":
            return { ...state, deleteCharitySuccess: null, deleteCharityError: null };
        case "DELETE_CHARITY_SUCCESS":
            return { ...state, deleteCharitySuccess: action.payload, deleteCharityError: null };
        case "DELETE_CHARITY_FAILURE":
            return { ...state, deleteCharitySuccess: null, deleteCharityError: action.payload };

        case ADD_CHARITY_LOCATION_APIKEY_RESPONSE:
            return { ...state, generatedAPIKeyUserName: action.payload.userName, generatedAPIKeyPassword: action.payload.password }

        case REVOKE_CHARITY_LOCATION_APIKEY_RESPONSE:

            let result = { ...state.loadCharityDetailsSuccess }.locations.map(p => {
                if (p.id === action.payload.locationId)
                    p.hasAPIKey = false;
                return p;
            })

            return { ...state, loadCharityDetailsSuccess : result}

        case CLEAR_CHARITY_LOCATION_APIKEY:
            return { ...state, generatedAPIKeyUserName: null, generatedAPIKeyPassword: null }

        default:
            return state;
    }
}

export default CharityReducer;

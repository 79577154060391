import { downloadDistributorDealsDeliveredReportOperationUpdated } from './slice-actions';
import { DownloadedFile, saveFile } from '../../../common/store/fileDownload';
import apiService from '../../../common/services/ApiService';
import { createStandardFetchedAction } from '../../../common/store/fetched.slices';

export const downloadDistributorDealsDeliveredReport = (
  year: number,
  quarter: number,
  invoicingDistributorId: number
) =>
  createStandardFetchedAction(
    downloadDistributorDealsDeliveredReportOperationUpdated,
    async () => {
      const result = await apiService.downloadFile(
        `/reports/invoicing/distributor-deals-delivered/excel/${year}/${quarter}/${invoicingDistributorId}`
      );

      const fileName = result.headers['x-filename'];
      const file: DownloadedFile = {
        data: result.data,
        fileName,
      };
      saveFile(file);
    },
    false
  );


import * as React from "react";
import { isEqual } from "lodash"
import AppUtilityService from "../../../common/services/AppUtilityService";

import { Modal, Button } from 'antd';
import SaveContactFormComponent from "./SaveContactFormComponent";

export class AddNewContactModal extends React.Component<IAddNewContactProps, IAddNewContactState> {

    private saveContactFormRef: any;

    constructor(props: IAddNewContactProps) {
        super(props);
        this.state = {

        }
    }

    componentWillReceiveProps(nextProps: IAddNewContactProps) {
        if (nextProps.addContactSuccess && !isEqual(this.props.addContactSuccess, nextProps.addContactSuccess)) {
            this.props.cancelSaveContactCallback(nextProps.addContactSuccess);
            AppUtilityService.showMessageToast("New contact has been added", "success");
        }
        if (nextProps.addContactError && !isEqual(this.props.addContactError, nextProps.addContactError)) {
            AppUtilityService.handleApiError(nextProps.addContactError);
        }
    }

    render() {
        return (
            <Modal
                visible={true}
                title="Add New Contact"
                onOk={this.handleFormSubmit}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>Cancel</Button>,
                    <Button key="submit" type="primary" htmlType="submit" onClick={this.handleFormSubmit}>Add New Contact</Button>
                ]}
            >
                <SaveContactFormComponent ref={(ref) => this.saveContactFormRef = ref} contactSaveApproach="new" />
            </Modal>
        )
    }


    handleFormSubmit = (e) => {
        e.preventDefault();
        this.saveContactFormRef.validateFields((err, values) => {
            if (!err) {
                var formData = values;
                this.handleAdd(formData)
            }
        })
    }

    handleAdd = (data) => {
        this.props.actions.addNewContact(data)
    }

    handleCancel = () => {
        this.props.cancelSaveContactCallback();
    }

} 

import { Button, Card, List, Popconfirm } from 'antd';
import WebAccessService from '../../../common/services/WebAccessService';
import { IInvoice, IInvoiceValidationIssue, InvoiceStatus } from '../../reducers/billing/billing.models';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { updateValidationIssue } from '../../actions/billing/updateValidationIssue';
import { updateAllValidationIssues } from '../../actions/billing/updateAllValidationIssues';
import { LoadState } from '../../../common/store/fetched';

interface IInvoiceValidationIssuesProps {
  invoice: IInvoice;
}

export function InvoiceValidationIssues({ invoice }: IInvoiceValidationIssuesProps) {

  const updatedValidationIssueId = useAppSelector(state =>
    state.BillingReducer.updateValidationIssueOperation.loadState === LoadState.InProgress
      ? (state.BillingReducer.updateValidationIssueOperation.context?.invoiceValidationIssueId ?? null)
      : undefined);
  const dispatch = useAppDispatch();

  const updateIssue = (isResolved: boolean, validationIssueId?: number) => {
    if (validationIssueId != null) {
      dispatch(updateValidationIssue(invoice.invoiceId, validationIssueId, { isResolved }));
    } else {
      dispatch(updateAllValidationIssues(invoice.invoiceId, { isResolved }));
    }
  }

  const canModify = WebAccessService.hasPermissionToAccess("Invoices", "Modify")
    && invoice.invoiceStatusId === InvoiceStatus.PendingReview;
  const hasUnresolvedIssues = invoice.invoiceValidationIssues.some(x => x.isResolved === false);
  const extraActions = canModify && hasUnresolvedIssues && (
    <Popconfirm
      title="Mark all validation issues as resolved?"
      okText="Yes"
      okType="primary"
      onConfirm={() => updateIssue(true)}
      cancelText="No"
    >
      <Button loading={updatedValidationIssueId === null}>Resolve all</Button>
    </Popconfirm>
  );

  const getItemActions = (item: IInvoiceValidationIssue) => {
    if (!canModify) return [];
    const loading = updatedValidationIssueId === item.invoiceValidationIssueId;
    return [
      item.isResolved
        ? (
          <Button type="danger" loading={loading} onClick={() => updateIssue(false, item.invoiceValidationIssueId)}>
            Un-resolve
          </Button>
        )
        : (
          <Button loading={loading} onClick={() => updateIssue(true, item.invoiceValidationIssueId)}>Resolve</Button>
        )
    ];
  }

  return invoice.invoiceValidationIssues.length === 0 ? null : (
    <Card
      title="Validation issues"
      extra={extraActions}
      className="content-card no-header-border no-card-padding"
      bordered={false}
    >
      <List
        itemLayout="horizontal"
        dataSource={invoice.invoiceValidationIssues}
        renderItem={item => (
          <List.Item actions={getItemActions(item)}>
            <List.Item.Meta
              avatar={<i className={'icon-warning font-24 ' + (item.isResolved ? 't-grey-dark' : 'text-negative')} />}
              description={<span className={item.isResolved ? 't-grey-dark' : 't-black'}>{item.message}</span>}
            />
          </List.Item>
        )}
      />
    </Card>
  );
}

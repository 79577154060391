import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as BreadcrumbActions from '../../../../common/actions/breadcrumbs/BreadcrumbActions';
import { ReconSummaryReportPage } from '../../../components/reports/locationStatisticsReports/ReconSummaryReportPage';
import { RootState } from '../../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadReconSummaryChartSuccess: state.ReportsReducer.loadReconSummaryChartSuccess,

    loadReconSummaryListSuccess: state.ReportsReducer.loadReconSummaryListSuccess,

    loadReconSummaryInfoSuccess: state.ReportsReducer.loadReconSummaryInfoSuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(BreadcrumbActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReconSummaryReportPage)

import { createApiCaller } from '../../../common/services/ApiService';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { fetchDataWithActionCreator, LoadState } from '../../../common/store/fetched';
import { updateInvoiceNotesOperationUpdated } from './slice-actions';
import { processFailureReason } from '../../../common/store/failure';
import { message } from 'antd';
import { getInvoice } from './getInvoice';

export const updateInvoiceNotes = (
  invoiceId: number, notes: string,
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    await fetchDataWithActionCreator(
      updateInvoiceNotesOperationUpdated,
      dispatch,
      createApiCaller(api => api.updateData<void>(
        `/invoices/${invoiceId.toFixed(0)}/comment`,
        undefined,
        { comment: notes })),
      processFailureReason,
      false,
    );

    const operation = getState().BillingReducer.updateInvoiceNotesOperation;
    if (operation.loadState === LoadState.Failed) {
      message.error(
        `There was a problem with saving notes.
         Please try again or refresh the page.`,
        5,
      );
    } else if (operation.loadState === LoadState.Success) {
      message.success(`Notes have been saved.`);
      await dispatch(getInvoice(invoiceId, true));
    }
  };
}


import * as React from "react";
import AppUtilityService from "../../../common/services/AppUtilityService";
import CharityListTableContainer from "../../containers/charities/CharityListTableContainer";
import AddNewCharityModalContainer from "../../containers/charities/AddNewCharityModalContainer";
import ZonesDropdownContainer from "../../../common/containers/dropdown/ZonesDropdownContainer";
import WebAccessService from "../../../common/services/WebAccessService";

import { Card, Input, Icon, Button, Badge } from 'antd';

export class CharityListPage extends React.Component<ICharityListProps, ICharityListState> {

  private charityListRef: any;

  constructor(props: ICharityListProps) {
    super(props);
    this.state = {
      searchText: "",
      totalCharities: null,
      showAddCharityModal: false,
      zoneId: null
    }
  }

  componentDidMount() {
    this.breadcrumbsBuild();
    if (this.props.charityListFilters) {
      this.handlePresetFilters(this.props.charityListFilters)
    }
  }

  render() {
    const title = WebAccessService.hasPermissionToAccess("Charity", "Add") ?
      <Button type="primary" onClick={this.openAddNewCharityModal}>+ New Charity</Button> : null;

    const extraActions =
      <div className="charity-filters">
        <span>
          <ZonesDropdownContainer
            placeholder="Select zone"
            value={this.state.zoneId}
            onChange={this.handleZoneChange} />
        </span>
        <span className="ml-8">
          <Input type="text"
            suffix={<Icon type="search" theme="outlined" />}
            placeholder="Search"
            onChange={this.searchChange}
            style={{ width: 200 }}
            value={this.state.searchText}
          />
        </span>
        <span className="ml-8">
          <Button type="default" onClick={this.resetPageFilters}>Reset</Button>
        </span>
      </div>

    return (
      <div className="pg-container page-container">
        <Card className="no-header-border" bordered={false}
          title={title}
          extra={extraActions}
        >
          <CharityListTableContainer
            onRef={(ref) => this.charityListRef = ref}
            getTotalCharitiesCallback={this.getTotalCharitiesHandler}
          />
        </Card>
        {
          this.state.showAddCharityModal &&
          <AddNewCharityModalContainer cancelSaveCharityCallback={this.handleCancelSaveCharity} />
        }
      </div>
    )
  }

  getTotalCharitiesHandler = (totalCount: number) => {
    this.setState({ totalCharities: totalCount }, () => {
      this.breadcrumbsBuild()
    })
  }

  breadcrumbsBuild = () => {
    let breadcrumbsList = [
      { name: "Charity", link: null }
    ]
    var extraContent = this.state.totalCharities &&
      <span className="font-12 t-grey-dark ml-8">
        <Badge dot className="mr-8" style={{ top: '-1px', background: '#A6ADC9' }} /> {this.state.totalCharities + " Total"}
      </span>;
    var breadcrumbs = AppUtilityService.generateBreadcrumbs(breadcrumbsList, extraContent);
    this.props.actions.updateBreadcrumbs(breadcrumbs);
  }

  searchChange = (e: any) => {
    this.setState({ searchText: e.target.value })
    this.charityListRef.handleSearchChange(e.target.value);
  }
  handleZoneChange = (e) => {
    this.setState({ zoneId: e })
    this.charityListRef.handleZoneChange(e);
  }

  openAddNewCharityModal = () => {
    this.setState({ showAddCharityModal: true })
  }

  handleCancelSaveCharity = (successObj) => {
    if (successObj) {
      this.charityListRef.loadCharityList();

    }
    this.setState({ showAddCharityModal: false })
  }

  handlePresetFilters = (filterData) => {
    var searchText = filterData.searchText;
    var zoneId = filterData.zoneId;
    this.setState({ searchText, zoneId })
  }

  resetPageFilters = () => {
    this.setState({ searchText: "", zoneId: null });
    this.charityListRef.resetPageFilters()
  }
}

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ZoneActions from '../../actions/zones/ZoneActions';
import { ZoneTreeComponent } from '../../components/zones/ZoneTreeComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    loadZoneListDataSuccess: state.ZoneReducer.loadZoneListDataSuccess,
    loadZoneListDataError: state.ZoneReducer.loadZoneListDataError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ZoneActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ZoneTreeComponent)

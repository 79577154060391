import { createApiCaller } from '../../../common/services/ApiService';
import { AppDispatch, RootState } from '../../../common/store/ConfigureStore';
import { fetchDataWithActionCreator, LoadState } from '../../../common/store/fetched';
import { IUpdateBulkInvoiceResult } from '../../reducers/billing/billing.models';
import { regenerateSelectedInvoicesOperationUpdated } from './slice-actions';
import { processFailureReason } from '../../../common/store/failure';
import { getInvoices } from './getInvoices';
import { errorNotification } from '../../../common/helpers/errorNotification';

export const regenerateBulkInvoices = (invoiceIds: number[]) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {

    await fetchDataWithActionCreator(
      regenerateSelectedInvoicesOperationUpdated,
      dispatch,
      createApiCaller(api => api.postData<IUpdateBulkInvoiceResult[]>(
        `/invoices/regenerate`,
        { invoiceIds },
      )),
      processFailureReason,
      false,
    );

    const operation = getState().BillingReducer.regenerateSelectedInvoicesOperation;
    if (operation.loadState === LoadState.Failed) {
      errorNotification(
        'Invoices regeneration failed',
        'There was a problem with regenerating invoices. Please try again.');
    } else if (operation.loadState === LoadState.Success) {
      await dispatch(getInvoices());
    }
  }
}
